export const legendTailwindString: string[] = [
  "bg-warning-safe",
  "bg-blue",
  "bg-green",
  "bg-yellow",
  "bg-fire",
  "bg-red",
  "bg-support-teal",
  "bg-support-purple",
  "bg-support-pink",
  "bg-support-blue",
];

export const LEGEND_COLOR_COUNT = legendTailwindString.length;

export const getTagColorNumber = (tagName: string) => {
  // get the hash value from tag name
  const hash = Array.from(tagName).reduce(
    (prev, cur) => prev + cur.charCodeAt(0),
    0
  );

  return hash % LEGEND_COLOR_COUNT;
};
