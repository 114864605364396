// Libraries
import { useTranslation } from "react-i18next";
import { useEffect } from "react";
import {
  generatePath,
  matchPath,
  useMatches,
  useNavigate,
  useParams,
} from "react-router-dom";

// Resources
import { ReactComponent as UserLine } from "#src/assets/images/icons/user-line.svg";

// General
import { useAppDispatch, useAppSelector } from "#src/store/hooks";
import { updateUserInfo } from "#src/store/slices/auth";
import { helperPathname } from "#src/config/pathname";

// Components
import { ObjectImage } from "#src/components/common/system/Object";
import { apiErrorHandler } from "#src/utils/apiErrorHandler";

// API-related
import authServices from "#src/services/auth";

// Children
import WorkspaceSelect from "./WorkspaceSelect";
import { accountMenuRoutes, workspaceMenuRoutes } from "./routeList";
import { accountSettingsPaths } from "../pathConfig";

const LayoutSidebarAccountMenu = () => {
  const { t } = useTranslation("general", { keyPrefix: "sidebar" });

  const dispatch = useAppDispatch();
  const userInfo = useAppSelector((state) => state.auth.userInfo);

  const navigate = useNavigate();

  const pathMatches = useMatches();

  const { workspace: workspaceId } = useParams<{ workspace: string }>();

  const isAccountSettings = pathMatches.some((match) =>
    accountSettingsPaths.some((path) => matchPath(path, match.pathname))
  );

  const accountPageActive = pathMatches.some((match) =>
    matchPath(helperPathname.ACCOUNT, match.pathname)
  );

  useEffect(() => {
    const updateUser = async () => {
      const authInfo = await authServices.get_account().catch(apiErrorHandler);
      dispatch(updateUserInfo(authInfo));
    };

    if (!userInfo) {
      updateUser();
    }
  }, [userInfo, dispatch]);

  return (
    <div
      className={`${
        isAccountSettings ? "flex flex-col flex-1" : "hidden"
      } h-full px-3 py-6 gap-6 overflow-hidden`}
    >
      <span
        className={`relative flex px-3 py-1 items-center gap-3 rounded-md duration-150 ${
          accountPageActive ? "bg-bright-blue" : ""
        }`}
      >
        <span
          className={`absolute left-0 top-1/2 -translate-y-1/2 h-7 min-w-[0.25rem] duration-150 ${
            accountPageActive ? "bg-subcard-blue" : "bg-transparent"
          } rounded-r-full`}
        />
        <div className="p-1 flex items-center justify-center">
          <ObjectImage data={userInfo?.avatar} className="h-8 w-8 rounded-full">
            <div className="w-full h-full flex items-center justify-center">
              <UserLine className="h-6 w-6 fill-medium-grey" />
            </div>
          </ObjectImage>
        </div>
        <span className="font-medium-16 whitespace-nowrap overflow-hidden text-ellipsis">
          {userInfo?.full_name}
        </span>
      </span>
      <div className="flex flex-col gap-3">
        {accountMenuRoutes.map((route) => {
          const active = pathMatches.some((match) =>
            matchPath(route.pathname, match.pathname)
          );

          return (
            <button
              key={`sidebar-menu-workspace_page-${route.pathname}`}
              className={`ml-11 py-0.5 ${
                active
                  ? "text-primary font-bold-14 pointer-events-none"
                  : "text-medium-grey font-regular-14"
              } text-left duration-300 hover:duration-150 overflow-hidden whitespace-nowrap text-ellipsis no-underline`}
              onClick={() => {
                if (workspaceId) {
                  navigate(
                    generatePath(route.pathname, {
                      workspace: workspaceId,
                    })
                  );
                } else {
                  console.log("No workspace param. This should not happen.");
                }
              }}
            >
              {route.getLabel()}
            </button>
          );
        })}
      </div>
      <div className="flex flex-col gap-3">
        <h4 className="pl-3 overflow-hidden whitespace-nowrap text-ellipsis">
          {t("menu.workspaceSettings.label")}
        </h4>
        <WorkspaceSelect lightMode />
        <div className="flex flex-col gap-3">
          {workspaceMenuRoutes.map((route) => {
            const active = pathMatches.some((match) =>
              matchPath(route.pathname, match.pathname)
            );

            return (
              <button
                key={`sidebar-menu-workspace_page-${route.pathname}`}
                className={`ml-11 py-0.5 ${
                  active
                    ? "text-primary font-bold-14 pointer-events-none"
                    : "text-medium-grey font-regular-14"
                } text-left duration-300 hover:duration-150 overflow-hidden whitespace-nowrap text-ellipsis no-underline`}
                onClick={() => {
                  if (workspaceId) {
                    navigate(
                      generatePath(route.pathname, {
                        workspace: workspaceId,
                      })
                    );
                  } else {
                    console.log("No workspace param. This should not happen.");
                  }
                }}
              >
                {route.getLabel()}
              </button>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default LayoutSidebarAccountMenu;
