export enum BugBountyMemberRoleEnum {
  WORKSPACE_OWNER = "BUG_BOUNTY_MEMBER_ROLE_ENUM_WORKSPACE_OWNER",
  PROJECT_OWNER = "BUG_BOUNTY_MEMBER_ROLE_ENUM_PROJECT_OWNER",
  VIEWER = "BUG_BOUNTY_MEMBER_ROLE_ENUM_VIEWER",
  DEVELOPER = "BUG_BOUNTY_MEMBER_ROLE_ENUM_DEVELOPER",
  RESEARCHER = "BUG_BOUNTY_MEMBER_ROLE_ENUM_RESEARCHER",
}

export enum MsspBugBountyMemberRoleEnum {
  SECURITY_MANAGER = "BUG_BOUNTY_MEMBER_ROLE_ENUM_SECURITY_MANAGER",
  PENTESTER = "BUG_BOUNTY_MEMBER_ROLE_ENUM_PENTESTER",
}

export const BugBountyStatusEnum = {
  DRAFT: "BUG_BOUNTY_STATUS_ENUM_DRAFT",
  REQUESTED: "BUG_BOUNTY_STATUS_ENUM_REQUESTED",
  OPEN: "BUG_BOUNTY_STATUS_ENUM_OPEN",
  CLOSED: "BUG_BOUNTY_STATUS_ENUM_CLOSED",
  LOCKED: "BUG_BOUNTY_STATUS_ENUM_LOCKED",
  ALL: "BUG_BOUNTY_STATUS_ENUM_ALL",
};
