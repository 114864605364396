// Libraries
import { useTranslation } from "react-i18next";
import type { ReactNode } from "react";
import { Switch } from "@lockerpm/design";
import dayjs from "dayjs";

// Resources
import { ReactComponent as ArrowRightUpLine } from "#src/assets/images/icons/arrow-right-up-line.svg";

// Components
import { Button } from "#src/components/common/system/Button";

// API-related
import type { IHostDetailItem } from "#src/services/devices";

const colorByHostStatus: { [status: string]: string } = {
  Online: "bg-warning-safe",
  Offline: "bg-warning",
};

const TextItemLabel = ({ name, value }: { name: string; value: ReactNode }) => {
  return (
    <div className="text-hard-grey whitespace-nowrap flex gap-2">
      {name}
      <div className="text-dark-blue font-medium-16 flex items-center gap-2">
        {value}
      </div>
    </div>
  );
};

interface IHostDetailsBasicInfoSectionProps {
  details: IHostDetailItem;
  isRemoting: boolean;
  onRemoteToDevice: () => void;
  onShowDeviceInfo: () => void;
}

const HostDetailsBasicInfoSection = ({
  details,
  isRemoting,
  onRemoteToDevice,
  onShowDeviceInfo,
}: IHostDetailsBasicInfoSectionProps) => {
  const { t } = useTranslation("devices", {
    keyPrefix: "hosts.page.details.basicInfo",
  });

  // 300s is the time tolerance, the condition means that the host has been seen in the last 5 minutes (300s)
  const hostStatus =
    details.last_seen &&
    dayjs().diff(dayjs.unix(details.last_seen), "second") < 300
      ? "Online"
      : "Offline";

  return (
    <section className="p-6 pb-4 border border-grey rounded-md flex justify-between items-end gap-x-10">
      <div className="flex flex-wrap gap-x-10 gap-y-3 flex-1 pb-2">
        <TextItemLabel
          name={t("status")}
          value={
            <>
              <div
                className={`h-2.5 w-2.5 rounded-full ${colorByHostStatus[hostStatus]}`}
              />
              {hostStatus}
            </>
          }
        />
        <TextItemLabel name={t("owner")} value={details.owner || "---"} />
        <TextItemLabel name={t("internalIp")} value={details.internal_ip} />
        <TextItemLabel
          name={t("operatingSystem")}
          value={details.os.name ? details.os.name : "---"}
        />
        <TextItemLabel
          name={t("osquery")}
          value={
            details.system.osquery_version
              ? details.system.osquery_version
              : "---"
          }
        />
        <TextItemLabel
          name={t("diskSpace")}
          value={
            typeof details.system.gigs_disk_space_available === "number"
              ? t("gigsAvailable", {
                  count: details.system.gigs_disk_space_available,
                })
              : "---"
          }
        />
        <TextItemLabel
          name={t("diskEncryption.label")}
          value={
            typeof details.system.disk_encryption_enabled === "boolean"
              ? details.system.disk_encryption_enabled
                ? t("diskEncryption.on")
                : t("diskEncryption.off")
              : "---"
          }
        />
        <TextItemLabel
          name={t("memory")}
          value={
            typeof details.system.memory === "number"
              ? details.system.memory / 1024 / 1024 / 1024 + " GB"
              : "---"
          }
        />
        <TextItemLabel
          name={t("processorType")}
          value={details.system.cpu_type ? details.system.cpu_type : "---"}
        />
      </div>
      <div className="flex flex-wrap items-center gap-x-1 gap-y-3">
        <div className="flex gap-3">
          {t("passport")} <Switch disabled />
        </div>
        {isRemoting ? (
          <Button variant="text" onClick={onShowDeviceInfo}>
            {t("showDeviceInfo")}
            <ArrowRightUpLine height={"1rem"} width={"1rem"} />
          </Button>
        ) : (
          <Button variant="text" onClick={onRemoteToDevice}>
            {t("remoteToThisDevice")}
            <ArrowRightUpLine height={"1rem"} width={"1rem"} />
          </Button>
        )}
      </div>
    </section>
  );
};

export default HostDetailsBasicInfoSection;
