import { useTranslation } from "react-i18next";
import { ReactComponent as FailedIllustration } from "#src/assets/images/illustrations/failed.svg";
import { Button } from "#src/components/common/system/Button";
import { Modal } from "#src/components/common/system/Modal";

interface PaymentFailureModalProps {
  open: boolean;
  onChangeOpen: (value: boolean) => void;
}

const PaymentFailureModal = ({
  open,
  onChangeOpen,
}: PaymentFailureModalProps) => {
  const { t } = useTranslation("trustCenter", {
    keyPrefix: "registerDomain.payment.failureModal",
  });

  return (
    <Modal open={open} onChangeOpen={onChangeOpen}>
      <div className="flex flex-col gap-6 items-center">
        <div className="flex flex-col gap-3 items-center text-center">
          <FailedIllustration />
          <h2>{t("paymentFailed")}</h2>
          <span className="font-regular-16-body">{t("instruction")}</span>
        </div>
        <Button
          size="large"
          className="w-fit"
          onClick={() => onChangeOpen(false)}
        >
          {t("button.retry")}
        </Button>
      </div>
    </Modal>
  );
};

export default PaymentFailureModal;
