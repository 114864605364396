// Libraries
import { useTranslation } from "react-i18next";
import i18next from "i18next";

// General
import { forceNormalCase, TAILWIND_COLORS } from "#src/utils/common";
import type { IChartPlotProps } from "#src/@types/chart";

// Components
import DonutChart from "#src/components/common/charts/Donut";
import LoadingState from "#src/components/common/system/LoadingState";
import ExperimentalPercentStackedBarChart from "#src/components/common/charts/experimental/PercentStackedBar";
import ChartLegends from "#src/components/common/helper/charts/legends";
import { getChartTooltip } from "#src/components/common/helper/charts/tooltip";

// API-related
import type { ITrustCenterControlOverviewStats } from "#src/services/trustCenter/control";

interface ITrustCenterControlOverview {
  overviewStats: ITrustCenterControlOverviewStats | null;
  isLoading: boolean;
}

const TrustCenterControlOverview = ({
  overviewStats,
  isLoading,
}: ITrustCenterControlOverview) => {
  const { t } = useTranslation("trustCenter", { keyPrefix: "control" });

  const locale = i18next.language;

  const donutChartData: IChartPlotProps[] = [
    {
      id: "compliance",
      name: t("compliance"),
      value: overviewStats ? overviewStats.status.compliance : 0,
      fill: TAILWIND_COLORS["warning-safe"],
    },
    {
      id: "unknown",
      name: t("unknown"),
      value: overviewStats
        ? overviewStats.status.total -
          (overviewStats.status.compliance +
            overviewStats.status.non_compliance)
        : 0,
      fill: TAILWIND_COLORS["status-rejected"],
    },
    {
      id: "non-compliance",
      name: t("nonCompliance"),
      value: overviewStats ? overviewStats.status.non_compliance : 0,
      fill: TAILWIND_COLORS["red"],
    },
  ];

  return overviewStats === null || isLoading ? (
    <LoadingState />
  ) : (
    <div className="flex gap-6">
      <div className="rounded-md bg-bright-grey flex flex-col items-center gap-6 p-6 w-80">
        <h2 className="text-center">{t("standardControl")}</h2>
        <div className="p-2">
          <DonutChart
            data={donutChartData}
            tooltip={getChartTooltip}
            height={"10.25rem"}
            width={"10.25rem"}
          />
        </div>
        <div className="flex flex-col items-center gap-3">
          <ChartLegends
            data={donutChartData}
            keyTemplate="trustCenter-control-overviewChart"
            showPercentage
          />
        </div>
      </div>
      <div className="rounded-md bg-bright-grey p-6 flex gap-12 flex-1">
        <div className="flex flex-col gap-6 flex-1">
          {overviewStats?.domain
            .filter((_, index) => index % 2 === 0)
            .map((item) => {
              const barChartData: IChartPlotProps[] = [
                {
                  id: "compliance",
                  name: t("compliance"),
                  value: item.compliance,
                  fill: TAILWIND_COLORS["warning-safe"],
                },
                {
                  id: "unknown",
                  name: t("unknown"),
                  value: item.total - (item.compliance + item.non_compliance),
                  fill: TAILWIND_COLORS["status-rejected"],
                },
                {
                  id: "non-compliance",
                  name: t("nonCompliance"),
                  value: item.non_compliance,
                  fill: TAILWIND_COLORS["red"],
                },
              ];

              return (
                <div
                  key={`trustCenter-control-category-${item.id}`}
                  className="flex flex-col gap-4"
                >
                  <div className="font-medium-18 whitespace-nowrap overflow">
                    {forceNormalCase(
                      locale === "vi" ? item.name.vi : item.name.en
                    )}
                  </div>
                  <ExperimentalPercentStackedBarChart data={barChartData} />
                </div>
              );
            })}
        </div>
        <hr className="border-l border-light-grey" />
        <div className="flex flex-col gap-6 flex-1">
          {overviewStats?.domain
            .filter((_, index) => index % 2 === 1)
            .map((item) => {
              const barChartData: IChartPlotProps[] = [
                {
                  id: "compliance",
                  name: t("compliance"),
                  value: item.compliance,
                  fill: TAILWIND_COLORS["warning-safe"],
                },
                {
                  id: "unknown",
                  name: t("unknown"),
                  value: item.total - (item.compliance + item.non_compliance),
                  fill: TAILWIND_COLORS["status-rejected"],
                },
                {
                  id: "non-compliance",
                  name: t("nonCompliance"),
                  value: item.non_compliance,
                  fill: TAILWIND_COLORS["red"],
                },
              ];

              return (
                <div
                  key={`trustCenter-control-category-${item.id}`}
                  className="flex flex-col gap-4"
                >
                  <div className="font-medium-18 whitespace-nowrap overflow">
                    {forceNormalCase(
                      locale === "vi" ? item.name.vi : item.name.en
                    )}
                  </div>
                  <ExperimentalPercentStackedBarChart data={barChartData} />
                </div>
              );
            })}
        </div>
      </div>
    </div>
  );
};

export default TrustCenterControlOverview;
