import type { IPaginateParams, IPaginatedResponse } from "#src/@types/api";
import endpoint from "#src/config/endpoint";
import request from "#src/utils/request";

export interface IPolicyDetailItem {
  id: number;
  name: string;
  query: string;
  critical: boolean;
  description: string;
  author_id: number;
  author_name: string;
  author_email: string;
  team_id: any | null;
  resolution: string;
  platform: string;
  created_at: string;
  updated_at: string;
  response: string;
}

export function list_host_policies(
  workspace_id: string,
  host_id: number,
  params: {
    policy_compliance_status: string | undefined;
  } & IPaginateParams
) {
  return request.get<never, IPaginatedResponse<IPolicyDetailItem>>(
    `${endpoint.WORKSPACES}/${workspace_id}/hosts/${host_id}/policy_compliance`,
    { params }
  );
}

export interface IPolicyInfoItem {
  id: number;
  query: string;
  name: { en: string; vi: string };
  description: { en: string; vi: string };
  resolution: { en: string; vi: string };
  critical: boolean;
  platform: string;
  created_time: number;
  updated_time: number;
}

export interface IPolicyItem {
  id: number;
  enabled: boolean;
  created_time: number;
  updated_time: number;
  platform: string;
  policy: IPolicyInfoItem;
  hosts_tags: { id: number; name: string; type: string }[];
}

export function list_workspace_policies_shortly(
  workspace_id: string,
  params: IPaginateParams
) {
  return request.get<never, IPolicyItem[]>(
    `${endpoint.WORKSPACES}/${workspace_id}/host_policies?paging=0`,
    { params }
  );
}

/** @param platform included but not limited by ["windows", "linux", "darwin", "chrome"]. Can be multiple strings joined with "," */
export function list_workspace_policies(
  workspace_id: string,
  params: {
    platform: string | undefined;
    host_ids: string | undefined; // this is actually an array of numbers being joined with ","
    tag_ids: string | undefined; // this is actually an array of numbers being joined with ","
  } & IPaginateParams
) {
  return request.get<never, IPaginatedResponse<IPolicyItem>>(
    `${endpoint.WORKSPACES}/${workspace_id}/host_policies`,
    { params }
  );
}

export function get_policy_details(workspace_id: string, policy_id: number) {
  return request.get<never, IPolicyItem>(
    `${endpoint.WORKSPACES}/${workspace_id}/host_policies/${policy_id}`
  );
}

export function create_workspace_policy(
  workspace_id: string,
  data: {
    policy_id: number;
    hosts_tags: { id: string; type: string }[];
  }
) {
  return request.post<never, { id: number }>(
    `${endpoint.WORKSPACES}/${workspace_id}/host_policies`,
    data
  );
}

export function update_workspace_policy(
  workspace_id: string,
  policy_id: number,
  data: Pick<IPolicyItem, "enabled"> & {
    hosts_tags: { id: string; type: string }[];
  }
) {
  return request.put<never, { id: number }>(
    `${endpoint.WORKSPACES}/${workspace_id}/host_policies/${policy_id}`,
    data
  );
}

export function delete_workspace_policy(
  workspace_id: string,
  policy_id: number
) {
  return request.delete<never, { id: number }>(
    `${endpoint.WORKSPACES}/${workspace_id}/host_policies/${policy_id}`
  );
}

export function delete_multiple_workspace_policy(
  workspace_id: string,
  ids: number[]
) {
  return request.post<never, { id: number }>(
    `${endpoint.WORKSPACES}/${workspace_id}/host_policies/multiple_delete`,
    { ids }
  );
}
