import { ReactComponent as DoubleQuotesL } from "#src/assets/images/icons/double-quotes-l.svg";
import EditorButton from "../EditorButton";
import { COMMENT_EDITOR_TEXTAREA_ID } from "../constants";

const QuoteButton = ({ disabled }: { disabled?: boolean }) => {
  return (
    <EditorButton
      onClick={() => {
        const editor = document.getElementById(
          COMMENT_EDITOR_TEXTAREA_ID
        ) as HTMLTextAreaElement;
        const selectionStart = editor.selectionStart;
        const selectionEnd = editor.selectionEnd;
        const selectionText = editor.value.substring(
          selectionStart,
          selectionEnd
        );

        if (selectionStart >= selectionEnd) {
          const splittedValue = editor.value.split("\n");
          const currentLine =
            editor.value.substring(0, selectionStart).split("\n").length - 1;
          const startOfCurrentLine = splittedValue
            .slice(0, currentLine)
            .reduce((prev, cur) => {
              return prev + cur.length + 1;
            }, 0);
          const endOfCurrentLine =
            startOfCurrentLine + splittedValue[currentLine].length;

          if (editor.value[startOfCurrentLine] === ">") {
            const prefix = "> \n\n";
            const suffix = "\n";

            editor.setRangeText(
              prefix + splittedValue[currentLine] + suffix,
              startOfCurrentLine,
              endOfCurrentLine
            );
            editor.setSelectionRange(
              selectionStart + prefix.length,
              selectionEnd + prefix.length
            );
          } else {
            const prefix = "\n> ";
            const suffix = "\n";

            editor.setRangeText(
              prefix + splittedValue[currentLine] + suffix,
              startOfCurrentLine,
              endOfCurrentLine
            );
            editor.setSelectionRange(
              selectionStart + prefix.length,
              selectionEnd + prefix.length
            );
          }
        } else {
          let newSelectionText = selectionText
            .split("\n")
            .map((item) => ">" + (item[0] === ">" ? "" : " ") + item)
            .join("\n");

          while (
            !(
              (newSelectionText[0] === "\n" && newSelectionText[1] === "\n") ||
              selectionStart <= 0 ||
              (newSelectionText[0] === "\n" &&
                editor.value[selectionStart - 1] === "\n")
            )
          ) {
            newSelectionText = "\n" + newSelectionText;
          }

          while (
            !(
              (newSelectionText[newSelectionText.length - 1] === "\n" &&
                newSelectionText[newSelectionText.length - 2] === "\n") ||
              selectionEnd >= editor.value.length ||
              (newSelectionText[newSelectionText.length - 1] === "\n" &&
                editor.value[selectionEnd] === "\n")
            )
          ) {
            newSelectionText = newSelectionText + "\n";
          }
          editor.setRangeText(newSelectionText, selectionStart, selectionEnd);
        }
      }}
      disabled={disabled}
    >
      <DoubleQuotesL />
    </EditorButton>
  );
};

export default QuoteButton;
