import { Dropdown } from "@lockerpm/design";
import {
  type FormEventHandler,
  forwardRef,
  type InputHTMLAttributes,
  type ReactNode,
  useState,
} from "react";
import { Input } from "../system/Input";

interface IInputWithSuggestionProps
  extends InputHTMLAttributes<HTMLInputElement> {
  suggestionList: { key: string; value: string; label: ReactNode }[];
  onClickSuggestion: (key: string) => void;
  onSubmitWithEnter?: FormEventHandler<HTMLFormElement>;
}

export const InputWithSuggestion = forwardRef<
  HTMLInputElement,
  IInputWithSuggestionProps
>(({ suggestionList, onClickSuggestion, onSubmitWithEnter, ...props }, ref) => {
  const [openSuggestions, setOpenSuggestions] = useState<boolean>(false);

  // TODO: not yet support paginated data

  return (
    <Dropdown
      open={openSuggestions}
      onOpenChange={(open) => {
        setOpenSuggestions(open);
      }}
      menu={{
        items: suggestionList,
        onClick: ({ key, domEvent }) => {
          domEvent.preventDefault();
          domEvent.stopPropagation();
          onClickSuggestion(key);
          setOpenSuggestions(false);
        },
        onMouseDown: (e) => {
          e.preventDefault();
        },
      }}
      placement="bottomRight"
      trigger={["click"]}
    >
      <form
        onSubmit={(e) => {
          e.preventDefault();
          if (onSubmitWithEnter) {
            onSubmitWithEnter(e);
          }
        }}
      >
        <Input
          autoFocus
          ref={ref}
          {...props}
          onBlur={(e) => {
            if (props.onBlur) {
              props.onBlur(e);
            }
            setOpenSuggestions(false);
          }}
        />
      </form>
    </Dropdown>
  );
});

InputWithSuggestion.displayName = "InputWithSuggestion";
