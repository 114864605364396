// Libraries
import { useTranslation } from "react-i18next";
import { useCallback, useEffect, useState } from "react";
import { useDebouncedCallback } from "use-debounce";

// General
import constants from "#src/config/constants";
import type { IFilterItem } from "#src/@types/common";

// Components
import {
  FilterDropdown,
  FilterDropdownSearch,
  SearchBox,
  SelectAllItemsDropdown,
} from "#src/components/common/Table/Filter";
import { apiErrorHandler } from "#src/utils/apiErrorHandler";

// API-related
import devicesServices from "#src/services/devices";
import tagsServices from "#src/services/tags";

// Children
import { hostStatusItems, osItems } from "#src/config/filterConstants";
import { policyComplianceItems } from "#src/config/filter/device";
import OsItemLabel from "./filterItems/OsItemLabel";
import HostStatusItemLabel from "./filterItems/HostStatusItemLabel";

interface IHostFilterProps {
  workspaceId: string;
  os: IFilterItem | null;
  hostStatus: IFilterItem | null;
  tag: IFilterItem | null;
  policyCompliance: IFilterItem | null;
  rules: IFilterItem | null;
  software: IFilterItem | null;
  searchKeyword: string;
  totalCount: number;
  selectedCount: number;
  onChangeOsSelection: (selected: IFilterItem | null) => void;
  onChangeHostStatusSelection: (selected: IFilterItem | null) => void;
  onChangeTagSelection: (selected: IFilterItem | null) => void;
  onChangePolicyComplianceSelection: (selected: IFilterItem | null) => void;
  onChangeRulesSelection: (selected: IFilterItem | null) => void;
  onChangeSoftwareSelection: (selected: IFilterItem | null) => void;
  onChangeSearchKeyword: (keyword: string) => void;
  onSelectAllDomains: () => void;
  onDeselectAllDomains: () => void;
}

const HostFilter = ({
  workspaceId,
  os,
  hostStatus,
  tag,
  policyCompliance,
  rules,
  software,
  searchKeyword,
  totalCount,
  selectedCount,
  onChangeOsSelection,
  onChangeHostStatusSelection,
  onChangeTagSelection,
  onChangePolicyComplianceSelection,
  onChangeRulesSelection,
  onChangeSoftwareSelection,
  onChangeSearchKeyword,
  onSelectAllDomains,
  onDeselectAllDomains,
}: IHostFilterProps) => {
  const { t } = useTranslation("devices", {
    keyPrefix: "hosts.page.hosts.filter",
  });

  const [hostSoftwareItems, setHostSoftwareItems] = useState<IFilterItem[]>([]);
  const [hostSoftwareCount, setHostSoftwareCount] = useState<number>(0);
  const [searchSoftwares, setSearchSoftware] = useState<string>("");
  const [loadedSoftwarePages, setLoadedSoftwarePages] = useState<number>(0);
  const [loadingSoftwares, setLoadingSoftwares] = useState<boolean>(false);

  const [tagItemList, setTagItemList] = useState<IFilterItem[]>([]);

  const [hostPolicyItems, setHostPolicyItems] = useState<IFilterItem[]>([]);

  const fetchMoreWorkspaceSoftwares = useCallback(() => {
    setLoadingSoftwares(true);

    if (workspaceId) {
      devicesServices
        .list_workspace_softwares(workspaceId, {
          q: searchSoftwares ? searchSoftwares : undefined,
          page: loadedSoftwarePages + 1,
          size: constants.DEFAULT_PAGE_SIZE,
        })
        .then((response) => {
          setHostSoftwareItems((prev) => [
            ...prev,
            ...response.results.map((software) => ({
              key: software.id.toString(),
              value: software.id.toString(),
              getLabel: () =>
                software.name +
                (software.version ? " - " + software.version : ""),
            })),
          ]);
          setHostSoftwareCount(response.count);
          setLoadedSoftwarePages((prev) => prev + 1);
          setLoadingSoftwares(false);
        })
        .catch((error) => {
          apiErrorHandler(error, {
            toastMessage: t("software.notification.fetchSoftware.fail"),
          });
        });
    }
  }, [workspaceId, loadedSoftwarePages, searchSoftwares, t]);

  const debouncedSearchWorkspaceSoftwares = useDebouncedCallback(
    (keyword: string) => {
      setLoadingSoftwares(true);
      if (workspaceId) {
        devicesServices
          .list_workspace_softwares(workspaceId, {
            q: keyword,
            page: undefined,
            size: constants.DEFAULT_PAGE_SIZE,
          })
          .then((response) => {
            setHostSoftwareItems(
              response.results.map((software) => ({
                key: software.id.toString(),
                value: software.id.toString(),
                getLabel: () =>
                  software.name +
                  (software.version ? " - " + software.version : ""),
              }))
            );
            setHostSoftwareCount(response.count);
            setLoadedSoftwarePages(1);
            setLoadingSoftwares(false);
          })
          .catch((error) => {
            apiErrorHandler(error, {
              toastMessage: t("software.notification.fetchSoftware.fail"),
            });
          });
      }
    },
    constants.DEBOUNCE_TIME
  );

  const onChangeSearchSoftwares = (keyword: string) => {
    setSearchSoftware(keyword);
    debouncedSearchWorkspaceSoftwares(keyword);
  };

  useEffect(() => {
    let ignore = false;

    if (!ignore) {
      if (workspaceId) {
        setLoadingSoftwares(true);
        devicesServices
          .list_workspace_softwares(workspaceId, {
            q: undefined,
            page: undefined,
            size: constants.DEFAULT_PAGE_SIZE,
          })
          .then((response) => {
            setHostSoftwareItems(
              response.results.map((software) => ({
                key: software.id.toString(),
                value: software.id.toString(),
                getLabel: () =>
                  software.name +
                  (software.version ? " - " + software.version : ""),
              }))
            );
            setHostSoftwareCount(response.count);
            setLoadedSoftwarePages(1);
            setLoadingSoftwares(false);
          })
          .catch((error) => {
            apiErrorHandler(error, {
              toastMessage: t("software.notification.fetchSoftware.fail"),
            });
          });
      }
    }

    return () => {
      ignore = true;
    };
  }, [workspaceId, t]);

  useEffect(() => {
    let ignore = false;

    if (!ignore) {
      if (workspaceId) {
        devicesServices
          .list_workspace_policies_shortly(workspaceId, {
            q: undefined,
            page: undefined,
            size: constants.DEFAULT_PAGE_SIZE,
          })
          .then((response) => {
            setHostPolicyItems(
              response.map((policy) => ({
                key: policy.id.toString(),
                value: policy.id.toString(),
                // FIXME: this will bug when changing languages
                getLabel: () => policy.policy.name.en,
              }))
            );
          })
          .catch((error) => {
            apiErrorHandler(error, {
              toastMessage: t("software.notification.fetchSoftware.fail"),
            });
          });
      }
    }

    return () => {
      ignore = true;
    };
  }, [workspaceId, t]);

  useEffect(() => {
    let ignore = false;

    if (!ignore && workspaceId) {
      tagsServices.list_workspace_tags_shortly(workspaceId).then((response) => {
        setTagItemList(
          response.map((tag) => ({
            key: tag.id.toString(),
            value: tag.id.toString(),
            getLabel: () => tag.name,
          }))
        );
      });
    }

    return () => {
      ignore = true;
    };
  }, [workspaceId]);

  return (
    <div className="flex justify-between gap-1">
      <div className="flex gap-1 max-w-[100%_-_15rem] whitespace-nowrap flex-wrap">
        <SelectAllItemsDropdown
          selectedCount={selectedCount}
          totalCount={totalCount}
          onSelectAll={onSelectAllDomains}
          onDeselectAll={onDeselectAllDomains}
        />
        <FilterDropdown
          label={t("hostStatus.label")}
          selected={hostStatus}
          options={hostStatusItems}
          onChangeSelection={onChangeHostStatusSelection}
          CustomLabel={HostStatusItemLabel}
        />
        <FilterDropdown
          label={t("os.label")}
          selected={os}
          options={osItems}
          onChangeSelection={onChangeOsSelection}
          CustomLabel={OsItemLabel}
        />
        <FilterDropdown
          label={t("policyCompliance.label")}
          selected={policyCompliance}
          options={policyComplianceItems}
          onChangeSelection={onChangePolicyComplianceSelection}
        />
        <FilterDropdown
          label={t("rules.label")}
          selected={rules}
          options={hostPolicyItems}
          onChangeSelection={onChangeRulesSelection}
        />
        <FilterDropdownSearch
          label={t("software.label")}
          selected={software}
          onChangeSelection={onChangeSoftwareSelection}
          options={hostSoftwareItems}
          loadedPages={loadedSoftwarePages}
          totalCount={hostSoftwareCount}
          fetchMoreItems={fetchMoreWorkspaceSoftwares}
          isLoadingMore={loadingSoftwares}
          setLoading={(value) => {
            setLoadingSoftwares(value);
          }}
          keyword={searchSoftwares}
          onChangeKeyword={onChangeSearchSoftwares}
        />
        <FilterDropdown
          label={t("tag.label")}
          selected={tag}
          options={tagItemList}
          onChangeSelection={onChangeTagSelection}
        />
      </div>

      <SearchBox
        searchKeyword={searchKeyword}
        onChangeSearchKeyword={onChangeSearchKeyword}
      />
    </div>
  );
};

export default HostFilter;
