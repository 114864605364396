import endpoint from "#src/config/endpoint";
import request from "#src/utils/request";

interface IBugBountyProgramRequirement {
  turn_on: boolean;
  value: string | any[];
  requirement_id: string;
  description: {
    vi: string;
    en: string;
  };
}

export function list_mssp_program_requirements(
  workspace_id: string,
  program_alias: string
) {
  return request.get<never, IBugBountyProgramRequirement[]>(
    `${endpoint.BASE}/mssp/${workspace_id}/programs/${program_alias}/requirements`
  );
}

export function update_mssp_program_requirement(
  workspace_id: string,
  program_alias: string,
  requirement_id: string,
  data: {
    turn_on: boolean;
    value: string | any[];
  }
) {
  return request.put<never, { success: boolean }>(
    `${endpoint.BASE}/mssp/${workspace_id}/programs/${program_alias}/requirements/${requirement_id}`,
    data
  );
}

interface IBugBountyProgramEligibilityChecklistItem {
  turn_on: boolean;
  value: string | any[];
  description: {
    vi: string;
    en: string;
  };
  my_value: any;
  requirement_id: string;
  eligibility: boolean;
}

export function check_mssp_program_eligibility(
  workspace_id: string,
  program_alias: string
) {
  return request.get<
    never,
    { role: string; checklist: IBugBountyProgramEligibilityChecklistItem[] }
  >(
    `${endpoint.BASE}/mssp/${workspace_id}/programs/${program_alias}/eligibility`
  );
}

export function update_allow_to_see_mssp_program(
  workspace_id: string,
  program_alias: string,
  data: { allow: boolean }
) {
  return request.put<never, any>(
    `${endpoint.BASE}/mssp/${workspace_id}/programs/${program_alias}/brief`,
    data
  );
}
