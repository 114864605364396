// Libraries
import { Dropdown } from "@lockerpm/design";
import { type ReactNode, useState } from "react";
import { useTranslation } from "react-i18next";

// Resources
import { ReactComponent as DeleteBack2Line } from "#src/assets/images/icons/delete-back-2-line.svg";
import { ReactComponent as ArrowDownSLine } from "#src/assets/images/icons/arrow-down-s-line.svg";
import { ReactComponent as PlusIcon } from "#src/assets/images/icons/plus.svg";

// General
import type { IFilterItem, IFilterItemWithChildren } from "#src/@types/common";

// Components
import {
  GroupItemLabel,
  GroupTitleLabel,
  statusDropdownRender,
} from "./StatusItemLabel";

// Children
import { vulnerabilityStatusItems } from "#src/config/filter/vulnerability";

interface IFilterDropdownProps {
  label: string;
  selected: IFilterItem[] | null;
  options: IFilterItemWithChildren[];
  onChangeSelection: (selected: IFilterItem[] | IFilterItem | null) => void;
}

interface IDropdownComponentProps {
  dropdownMenuItems: (Omit<IFilterItem, "getLabel"> & { label: ReactNode })[];
  onClickMenuItem: ({ key }: { key: string }) => void;
  onOpenChange: (open: boolean) => void;
  children: ReactNode;
}

const DropdownComponent = ({
  dropdownMenuItems,
  onClickMenuItem,
  onOpenChange,
  children,
}: IDropdownComponentProps) => {
  return (
    <Dropdown
      menu={{
        items: dropdownMenuItems,
        onClick: onClickMenuItem,
      }}
      dropdownRender={statusDropdownRender}
      placement="bottomRight"
      arrow
      trigger={["click"]}
      onOpenChange={onOpenChange}
    >
      {children}
    </Dropdown>
  );
};

const VulnerabilityStatusFilterDropdown = ({
  label,
  selected,
  options,
  onChangeSelection,
}: IFilterDropdownProps) => {
  const { t } = useTranslation("translation", { keyPrefix: "filter" });
  const [checked, setChecked] = useState<string[]>(
    selected?.map((item) => item.key) || []
  );

  const onChangeGroupTitleCheckbox = (group: IFilterItemWithChildren) => {
    if (!Array.isArray(group.children)) return;

    const childrenKeyList = group.children.map((c) => c.key);

    if (group.children.some((child) => checked.includes(child.key))) {
      setChecked((prev) =>
        prev.filter((item) => !childrenKeyList.includes(item))
      );
    } else {
      setChecked((prev) => [...prev, ...childrenKeyList]);
    }
  };

  const onChangeGroupItemCheckbox = (key: string) => {
    if (checked.includes(key)) {
      setChecked((prev) => prev.filter((item) => item !== key));
    } else {
      setChecked((prev) => [...prev, key]);
    }
  };

  const dropdownMenuItems = [
    ...options.map((item) => {
      return {
        key: item.key,
        value: item.value,
        type: item.type,
        label: (
          <GroupTitleLabel
            label={item.getLabel()}
            checked={item.children.every((item) => checked.includes(item.key))}
            indeterminate={
              item.children.some((item) => checked.includes(item.key)) &&
              item.children.some((item) => !checked.includes(item.key))
            }
            onChangeCheckbox={() => {
              onChangeGroupTitleCheckbox(item);
            }}
          />
        ),
        children: item.children.map((childItem) => ({
          key: childItem.key,
          value: childItem.value,
          label: (
            <GroupItemLabel
              label={childItem.getLabel()}
              value={childItem.value}
              checked={checked.includes(childItem.key)}
              onChangeCheckbox={() => {
                onChangeGroupItemCheckbox(childItem.key);
              }}
            />
          ),
        })),
      };
    }),
    {
      key: "All",
      value: undefined,
      label: (
        <div className="flex justify-center text-hard-grey p-2">{t("all")}</div>
      ),
    },
  ];

  const onClickMenuItem = ({ key }: { key: string }) => {
    if (key.toUpperCase() === "ALL") {
      onChangeSelection(options.map((option) => option.children).flat());
      return;
    }
    const newSelected = options
      .map((option) => option.children)
      .flat()
      .find((item) => item.key === key);
    if (newSelected) {
      onChangeSelection(newSelected);
    }
  };

  const onOpenChange = (open: boolean) => {
    if (open) {
      setChecked(selected?.map((item) => item.key) || []);
    } else {
      const selectedItems = vulnerabilityStatusItems
        .map((item) => item.children)
        .flat()
        .filter((item) => checked.includes(item.key));

      onChangeSelection(selectedItems.length ? selectedItems : null);
    }
  };

  return selected !== null ? (
    <div className="border border-light-grey text-hard-grey rounded-md flex items-center">
      <button
        className="ml-3 h-4 w-4 text-inherit"
        onClick={() => {
          setChecked([]);
          onChangeSelection(null);
        }}
      >
        <DeleteBack2Line width={"1rem"} height={"1rem"} />
      </button>
      <span className="px-3 border-r border-light-grey whitespace-nowrap">
        {label}
      </span>
      <DropdownComponent
        dropdownMenuItems={dropdownMenuItems}
        onClickMenuItem={onClickMenuItem}
        onOpenChange={onOpenChange}
      >
        <button className="text-primary font-medium-16 p-3 flex gap-2  items-center">
          <span className="max-w-[10rem] whitespace-nowrap overflow-hidden text-ellipsis">
            {selected.length ===
              vulnerabilityStatusItems.map((item) => item.children).flat()
                .length || selected.length === 0
              ? t("all")
              : selected.map((item) => item.getLabel()).join(", ")}
          </span>
          <ArrowDownSLine
            width={"1rem"}
            height={"1rem"}
            className="fill-hard-grey"
          />
        </button>
      </DropdownComponent>
    </div>
  ) : (
    <DropdownComponent
      dropdownMenuItems={dropdownMenuItems}
      onClickMenuItem={onClickMenuItem}
      onOpenChange={onOpenChange}
    >
      <button className="border border-dashed border-light-grey text-hard-grey rounded-md flex gap-3 p-3 items-center">
        <PlusIcon width={"1rem"} height={"1rem"} />
        <span className="whitespace-nowrap">{label}</span>
      </button>
    </DropdownComponent>
  );
};

export default VulnerabilityStatusFilterDropdown;
