import endpoint from "#src/config/endpoint";
import request from "#src/utils/request";
import type { IPaginateParams, IPaginatedResponse } from "#src/@types/api";

export interface ICertificateBaseItem {
  id: number;
  last_found: number;
  protocol: string;
  host: string;
  port: number | null;
  issuer: {
    issuer: string;
    name: string;
    org: string;
    org_unit: string;
    country: string;
  };
  not_after: number;
  not_before: number;
  common_names: string;
}

export type ICertificateItem = Omit<ICertificateBaseItem, "commom_names"> & {
  commom_names: string[];
  subject: {
    subject: string;
    name: string;
    org: string;
    location: string;
    state: string;
    country: string;
  };
  status: string;
  serial_number: string;
  sig_alg: string;
  key_alg: string;
  key_size: number;
  raw: string;
  alt_names: string[];
  fingerprint: string;
  parent_fingerprint: string;
  parents: {
    fingerprint: string;
    common_names: string[];
    not_before: number;
    not_after: number;
    status: string;
    subject: {
      C: string;
      O: string;
      CN: string;
      subject: string;
    };
    issuer: {
      C: string;
      O: string;
      CN: string;
      issuer: string;
    };
  }[];
  endpoints: {
    ipAddress: string;
    statusMessage: string;
    grade: string;
    gradeTrustIgnored: string;
    hasWarnings: boolean;
    isExceptional: boolean;
    progress: number;
    duration: number;
    delegation: number;
    details: {
      hostStartTime: number;
      certChains: {
        id: string;
        certIds: string[];
        trustPaths: {
          certIds: string[];
          trust: {
            rootStore: string;
            isTrusted: boolean;
            trustErrorMessage?: string;
          }[];
        }[];
        issues: number;
        noSni: boolean;
      }[];
      protocols: {
        id: number;
        name: string;
        version: string;
        score: number;
      }[];
      suites: {
        protocol: number;
        list: {
          id: number;
          name: string;
          cipherStrength: number;
          kxType?: string;
          kxStrength?: number;
          namedGroupBits?: number;
          namedGroupId?: number;
          namedGroupName?: string;
          dhBits?: number;
          dhP?: number;
          dhG?: number;
          dhYs?: number;
          q: number;
        }[];
        preference: boolean;
      }[];
      namedGroups: {
        list: {
          id: number;
          name: string;
          bits: number;
          namedGroupType: string;
        }[];
        preference: boolean;
      };
      serverSignature: string;
      prefixDelegation: boolean;
      nonPrefixDelegation: boolean;
      vulnBeast: boolean;
      renegSupport: number;
      sessionResumption: number;
      compressionMethods: number;
      supportsNpn: boolean;
      npnProtocols: string;
      supportsAlpn: boolean;
      alpnProtocols: string;
      sessionTickets: number;
      ocspStapling: boolean;
      sniRequired: boolean;
      httpStatusCode: number;
      supportsRc4: boolean;
      rc4WithModern: boolean;
      rc4Only: boolean;
      forwardSecrecy: number;
      supportsAead: boolean;
      protocolIntolerance: number;
      miscIntolerance: number;
      sims: {
        results: {
          client: {
            id: number;
            name: string;
            platform?: string;
            version: string;
            isReference: boolean;
          };
          errorCode: number;
          errorMessage?: string;
          attempts: number;
          certChainId?: string;
          protocolId?: number;
          suiteId?: number;
          suiteName?: string;
          kxType?: string;
          kxStrength?: number;
          namedGroupBits?: number;
          namedGroupId?: number;
          namedGroupName?: string;
          dhBits?: number;
          dhP?: number;
          dhG?: number;
          dhYs?: number;
          keyAlg?: string;
          keySize?: number;
          sigAlg?: string;
          alertType?: number;
          alertCode?: number;
        }[];
      };
      heartbleed: boolean;
      heartbeat: boolean;
      openSslCcs: number;
      openSSLLuckyMinus20: number;
      ticketbleed: number;
      bleichenbacher: number;
      poodle: boolean;
      poodleTls: number;
      fallbackScsv: true;
      freak: boolean;
      hasSct: number;
      dhPrimes: string[];
      dhUsesKnownPrimes: number;
      dhYsReuse: boolean;
      ecdhParameterReuse: boolean;
      logjam: boolean;
      hstsPolicy: {
        LONG_MAX_AGE: number;
        status: string;
        directives: any | {};
      };
      hstsPreloads: {
        source: string;
        hostname: string;
        status: string;
        sourceTime: number;
      }[];
      hpkpPolicy: {
        status: string;
        pins: any[];
        matchedPins: any[];
        directives: any[];
      };
      hpkpRoPolicy: {
        status: string;
        pins: any[];
        matchedPins: any[];
        directives: any[];
      };
      staticPkpPolicy: {
        status: string;
        pins: any[];
        matchedPins: any[];
        forbiddenPins: any[];
        matchedForbiddenPins: any[];
      };
      httpTransactions: {
        requestUrl: string;
        statusCode: number;
        requestLine: string;
        requestHeaders: string[];
        responseLine: string;
        responseHeadersRaw: string[];
        responseHeaders: { name: string; value: string }[];
        fragileServer: boolean;
      }[];
      drownHosts: any[];
      drownErrors: boolean;
      drownVulnerable: boolean;
      zeroRTTEnabled: number;
      zombiePoodle: number;
      goldenDoodle: number;
      supportsCBC: boolean;
      zeroLengthPaddingOracle: number;
      sleepingPoodle: number;
    };
    score: {
      certificate: number;
      protocol: number;
      key_exchange: number;
      cipher_strength: number;
    };
  }[];
};

export interface ICertificateItemTableData {
  id: number;
  cert_id: string;
  first_found: number;
  last_found: number;
  host: string;
  port: number | null;
  protocol: string;
  not_before: number;
  not_after: number;
  subject: string;
  subject_cn: string;
  subject_org: string;
  subject_location: string;
  subject_state: string;
  subject_country: string;
  issuer: string;
  issuer_cn: string;
  issuer_org: string;
  issuer_org_unit: string;
  issuer_country: string;
  sig_alg: string;
  key_alg: string;
  key_size: number;
  assets: number;
}

export function get_asset_certificate(workspace_id: string, asset_id: number) {
  return request.get<never, Array<ICertificateItem>>(
    `${endpoint.WORKSPACES}/${workspace_id}/assets/${asset_id}/certificate`
  );
}

export function list_certificates(
  workspace_id: string,
  params: IPaginateParams
) {
  return request.get<never, IPaginatedResponse<ICertificateItemTableData>>(
    `${endpoint.WORKSPACES}/${workspace_id}/certificates`,
    { params }
  );
}

interface ICertificateStatisticItem<T> {
  [key: string]: T | number;
  count: number;
}

interface ICertificateStatisticResponse {
  total: number;
  sig_alg: ICertificateStatisticItem<string>[];
  issuer_cn: ICertificateStatisticItem<string>[];
  key_size: ICertificateStatisticItem<number>[];
  expire_in: ICertificateStatisticItem<string>[];
}
export function get_certificate_statistics(
  workspace_id: string,
  params: IPaginateParams
) {
  return request.get<never, ICertificateStatisticResponse>(
    `${endpoint.WORKSPACES}/${workspace_id}/certificates/statistics`,
    { params }
  );
}

export function retrieve_certificate(workspace_id: string, cert_id: number) {
  return request.get<never, ICertificateItem>(
    `${endpoint.WORKSPACES}/${workspace_id}/certificates/${cert_id}`
  );
}


