// Libraries
import { useEffect, useState, useCallback } from "react";
import { useParams } from "react-router-dom";

// Components
import { StickySection, ContentSection } from "#src/layouts/content";
import LoadingState from "#src/components/common/system/LoadingState";
import { apiErrorHandler } from "#src/utils/apiErrorHandler";

// API-related
import type { ICertificateItem } from "#src/services/assets/certificates";
import { retrieve_certificate } from "#src/services/assets/certificates";

// Children
import CertificateExpirationCard from "../../../components/assets/details/Tabs/CertificatesTab/ExpirationCard";
import CertificatePath from "../../../components/assets/details/Tabs/CertificatesTab/Path";
import CertificateRaw from "../../../components/assets/details/Tabs/CertificatesTab/Raw";
import CertificateEndpoints from "../../../components/assets/details/Tabs/CertificatesTab/Endpoints";
import CertificateDetails from "../../../components/assets/details/Tabs/CertificatesTab/Details";

const AssetsCertificatesDetails = () => {
  const { workspace: workspaceId, asset: certificateId } = useParams<
    "workspace" | "asset"
  >();

  const [certificate, setCertificate] = useState<ICertificateItem | null>(null);

  // Loading state
  const [isLoading, setLoading] = useState<boolean>(false);

  const fetchCertificateDetails = useCallback(() => {
    if (workspaceId && certificateId && !isNaN(+certificateId)) {
      setLoading(true);
      retrieve_certificate(workspaceId, +certificateId)
        .then((response) => {
          setCertificate(response);
          setLoading(false);
        })
        .catch(apiErrorHandler);
    }
  }, [workspaceId, certificateId]);

  // fetch domain detail
  useEffect(() => {
    let ignore = false;

    if (!ignore) {
      fetchCertificateDetails();
    }

    return () => {
      ignore = true;
    };
  }, [fetchCertificateDetails]);

  return workspaceId ? (
    certificate === null ? (
      isLoading ? (
        <div className="pt-20">
          <LoadingState />
        </div>
      ) : (
        <></>
      )
    ) : (
      <>
        <StickySection>
          <div className="flex flex-col gap-4">
            <h1>{certificate.host}</h1>
          </div>
        </StickySection>
        <ContentSection className="flex flex-col gap-12">
          <CertificateExpirationCard
            expireNotAfter={certificate.not_after}
            issuerName={certificate.issuer.name}
            name={certificate.common_names[0]}
          />
          <CertificateDetails certificate={certificate} />
          <CertificatePath certificate={certificate} />
          <CertificateRaw certificate={certificate} />
          <CertificateEndpoints endpoints={certificate.endpoints} />
        </ContentSection>
      </>
    )
  ) : null;
};

export default AssetsCertificatesDetails;
