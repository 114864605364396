// Libraries
import { Modal } from "@lockerpm/design";
import { useState, type Dispatch, type SetStateAction, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { calculateBaseScore } from "@neuralegion/cvss";

// Components
import { SeverityLabel } from "#src/components/common/labels";
import { Button } from "#src/components/common/system/Button";
import { createToast } from "#src/components/common/system/toasts";

// Children
import CvssMetric from "./CvssMetricBlock";

interface ICvssModalProps {
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  cvssInit: string;
  onApplyCvss: (cvss: {
    vectorString: string;
    score: number;
    severity: string;
  }) => void;
}

const CvssModal = ({
  open,
  setOpen,
  cvssInit,
  onApplyCvss,
}: ICvssModalProps) => {
  const { t } = useTranslation("vulnerabilities", { keyPrefix: "modal.cvss3" });

  const attackVectorItems = [
    {
      value: "N",
      label: t("attackVector.network"),
    },
    {
      value: "A",
      label: t("attackVector.adjacent"),
    },
    {
      value: "L",
      label: t("attackVector.local"),
    },
    {
      value: "P",
      label: t("attackVector.physical"),
    },
  ];
  const attackComplexityItems = [
    {
      value: "L",
      label: t("attackComplexity.low"),
    },
    {
      value: "H",
      label: t("attackComplexity.high"),
    },
  ];
  const privilegesRequiredItems = [
    {
      value: "N",
      label: t("privilegesRequired.none"),
    },
    {
      value: "L",
      label: t("privilegesRequired.low"),
    },
    {
      value: "H",
      label: t("privilegesRequired.high"),
    },
  ];
  const userInteractionItems = [
    {
      value: "N",
      label: t("userInteraction.none"),
    },
    {
      value: "R",
      label: t("userInteraction.required"),
    },
  ];
  const scopeItems = [
    {
      value: "U",
      label: t("scope.unchanged"),
    },
    {
      value: "C",
      label: t("scope.changed"),
    },
  ];
  const confidentialityItems = [
    {
      value: "N",
      label: t("confidentiality.none"),
    },
    {
      value: "L",
      label: t("confidentiality.low"),
    },
    {
      value: "H",
      label: t("confidentiality.high"),
    },
  ];
  const integrityItems = [
    {
      value: "N",
      label: t("integrity.none"),
    },
    {
      value: "L",
      label: t("integrity.low"),
    },
    {
      value: "H",
      label: t("integrity.high"),
    },
  ];
  const availabilityItems = [
    {
      value: "N",
      label: t("availability.none"),
    },
    {
      value: "L",
      label: t("availability.low"),
    },
    {
      value: "H",
      label: t("availability.high"),
    },
  ];

  const cvssInitSplitted = cvssInit.replaceAll("CVSS:3.0/", "").split("/");

  const [selectedAttackVector, setSelectedAttackVector] = useState<string>(
    cvssInitSplitted.length > 1
      ? cvssInitSplitted[0].slice(-1)
      : attackVectorItems[0].value
  );
  const [selectedAttackComplexity, setSelectedAttackComplexity] =
    useState<string>(
      cvssInitSplitted.length > 1
        ? cvssInitSplitted[1].slice(-1)
        : attackComplexityItems[0].value
    );
  const [selectedPrivilegesRequired, setSelectedPrivilegesRequired] =
    useState<string>(
      cvssInitSplitted.length > 1
        ? cvssInitSplitted[2].slice(-1)
        : privilegesRequiredItems[0].value
    );
  const [selectedUserInteraction, setSelectedUserInteraction] =
    useState<string>(
      cvssInitSplitted.length > 1
        ? cvssInitSplitted[3].slice(-1)
        : userInteractionItems[0].value
    );
  const [selectedScope, setSelectedScope] = useState<string>(
    cvssInitSplitted.length > 1
      ? cvssInitSplitted[4].slice(-1)
      : scopeItems[0].value
  );
  const [selectedConfidentiality, setSelectedConfidentiality] =
    useState<string>(
      cvssInitSplitted.length > 1
        ? cvssInitSplitted[5].slice(-1)
        : confidentialityItems[0].value
    );
  const [selectedIntegrity, setSelectedIntegrity] = useState<string>(
    cvssInitSplitted.length > 1
      ? cvssInitSplitted[6].slice(-1)
      : integrityItems[0].value
  );
  const [selectedAvailability, setSelectedAvailability] = useState<string>(
    cvssInitSplitted.length > 1
      ? cvssInitSplitted[7].slice(-1)
      : availabilityItems[0].value
  );

  const cvssVector = useMemo(() => {
    try {
      const vectorString = `CVSS:3.0/AV:${selectedAttackVector}/AC:${selectedAttackComplexity}/PR:${selectedPrivilegesRequired}/UI:${selectedUserInteraction}/S:${selectedScope}/C:${selectedConfidentiality}/I:${selectedIntegrity}/A:${selectedAvailability}`;

      const score = calculateBaseScore(vectorString);

      const severity =
        score === 0
          ? "NONE"
          : score < 4
          ? "LOW"
          : score < 7
          ? "MEDIUM"
          : score < 9
          ? "HIGH"
          : "CRITICAL";

      return { vectorString, score, severity };
    } catch (error) {
      createToast({
        type: "error",
        message: "Invalid CVSS vector string",
        detail:
          "CVSS vector string must follow an exact form. This is likely to be our fault :( Apology and thank you for your understanding.",
      });
    }
  }, [
    selectedAttackVector,
    selectedScope,
    selectedAttackComplexity,
    selectedConfidentiality,
    selectedPrivilegesRequired,
    selectedIntegrity,
    selectedUserInteraction,
    selectedAvailability,
  ]);

  const onCloseModal = () => {
    setOpen(false);
    setSelectedAttackVector(attackVectorItems[0].value);
    setSelectedScope(scopeItems[0].value);
    setSelectedAttackComplexity(attackComplexityItems[0].value);
    setSelectedConfidentiality(confidentialityItems[0].value);
    setSelectedPrivilegesRequired(privilegesRequiredItems[0].value);
    setSelectedIntegrity(integrityItems[0].value);
    setSelectedUserInteraction(userInteractionItems[0].value);
    setSelectedAvailability(availabilityItems[0].value);
  };

  if (!cvssVector) return null;

  return (
    <Modal
      open={open}
      onCancel={() => {
        setOpen(false);
      }}
      title={<h2>{t("title")}</h2>}
      centered
      destroyOnClose
      footer={null}
      width="55rem"
    >
      <div className="flex flex-col gap-6">
        <span className="font-regular-14 text-hard-grey">
          {t("description")}
        </span>
        <div className="grid grid-cols-2 gap-x-6 gap-y-3">
          <CvssMetric
            label={t("attackVector.label")}
            items={attackVectorItems}
            selected={selectedAttackVector}
            setSelected={setSelectedAttackVector}
          />
          <CvssMetric
            label={t("scope.label")}
            items={scopeItems}
            selected={selectedScope}
            setSelected={setSelectedScope}
          />
          <CvssMetric
            label={t("attackComplexity.label")}
            items={attackComplexityItems}
            selected={selectedAttackComplexity}
            setSelected={setSelectedAttackComplexity}
          />
          <CvssMetric
            label={t("confidentiality.label")}
            items={confidentialityItems}
            selected={selectedConfidentiality}
            setSelected={setSelectedConfidentiality}
          />
          <CvssMetric
            label={t("privilegesRequired.label")}
            items={privilegesRequiredItems}
            selected={selectedPrivilegesRequired}
            setSelected={setSelectedPrivilegesRequired}
          />
          <CvssMetric
            label={t("integrity.label")}
            items={integrityItems}
            selected={selectedIntegrity}
            setSelected={setSelectedIntegrity}
          />
          <CvssMetric
            label={t("userInteraction.label")}
            items={userInteractionItems}
            selected={selectedUserInteraction}
            setSelected={setSelectedUserInteraction}
          />
          <CvssMetric
            label={t("availability.label")}
            items={availabilityItems}
            selected={selectedAvailability}
            setSelected={setSelectedAvailability}
          />
        </div>
        <div className="w-full border border-grey py-6 flex flex-col gap-3 items-center">
          <span className="font-medium-24 text-medium-grey">
            {cvssVector.score}
          </span>
          <SeverityLabel severity={cvssVector.severity} />
          <div className="p-3 text-hard-grey">{cvssVector.vectorString}</div>
        </div>
        <div className="flex gap-1 justify-end">
          <Button variant="secondary" onClick={onCloseModal}>
            {t("button.cancel")}
          </Button>
          <Button
            onClick={() => {
              onApplyCvss(cvssVector);
              onCloseModal();
            }}
          >
            {t("button.apply")}
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default CvssModal;
