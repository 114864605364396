import { type ButtonHTMLAttributes, forwardRef } from "react";
import { twMerge } from "tailwind-merge";

const DropdownTriggerButton = forwardRef<
  HTMLButtonElement,
  ButtonHTMLAttributes<HTMLButtonElement>
>((props, ref) => {
  return (
    <button
      ref={ref}
      {...props}
      className={twMerge(
        "flex gap-2 text-left items-center justify-between px-4 my-0.5 h-11 bg-bright-grey rounded-md outline outline-2 outline-transparent duration-150 hover:outline-light-grey disabled:hover:outline-transparent disabled:text-medium-grey",
        props.className
      )}
    />
  );
});

DropdownTriggerButton.displayName = "DropdownTriggerButton";

export default DropdownTriggerButton;
