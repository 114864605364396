// Libraries
import { useMemo, useState } from "react";

// Components
import useFetchNoPaging from "#src/hooks/useFetchNoPaging";

// Children
import TrustCenterFaqFilter from "./Filter";
import TrustCenterFaqTableGrid from "./Table";
import trustCenterServices from "#src/services/trustCenter";
interface ITrustCenterFaqTableProps {
  workspaceId: string;
}

const TrustCenterFaqTable = ({ workspaceId }: ITrustCenterFaqTableProps) => {
  const [searchKeyword, setSearchKeyword] = useState<string>("");

  const onChangeSearchKeyword = (keyword: string) => {
    setSearchKeyword(keyword);
  };

  const fetchParams = useMemo<
    Parameters<typeof trustCenterServices.list_trust_center_faq_no_paging>
  >(() => [workspaceId, { q: searchKeyword }], [workspaceId, searchKeyword]);

  const {
    list: faqList,
    isLoading,
    updateData: updateFaqList,
  } = useFetchNoPaging(
    trustCenterServices.list_trust_center_faq_no_paging,
    fetchParams
  );

  return (
    <>
      <TrustCenterFaqFilter
        searchKeyword={searchKeyword}
        onChangeSearchKeyword={onChangeSearchKeyword}
      />
      <TrustCenterFaqTableGrid
        workspaceId={workspaceId}
        faqList={faqList}
        isLoading={isLoading}
        onRefresh={() => {
          updateFaqList();
        }}
      />
    </>
  );
};

export default TrustCenterFaqTable;
