import type { IPaginatedResponse, IPaginateParams } from "#src/@types/api";
import endpoint from "#src/config/endpoint";
import request from "#src/utils/request";

interface IBugBountyProgramBlockedResearcher {
  id: number;
  user: {
    email: string;
    username: string;
    full_name: string;
    avatar: string;
  };
  is_public: boolean;
  created_time: number;
}

export function list_program_blocked_researchers(
  workspace_id: string,
  program_alias: string,
  params: Omit<IPaginateParams, "q">
) {
  return request.get<
    never,
    IPaginatedResponse<IBugBountyProgramBlockedResearcher>
  >(`${endpoint.WORKSPACES}/${workspace_id}/programs/${program_alias}/block`, {
    params,
  });
}

export function blacklist_researcher(
  workspace_id: string,
  program_alias: string,
  data: { username: string }
) {
  return request.post<never, { id: number }>(
    `${endpoint.WORKSPACES}/${workspace_id}/programs/${program_alias}/block`,
    data
  );
}

export function unblock_researcher(
  workspace_id: string,
  program_alias: string,
  block_id: number
) {
  return request.delete(
    `${endpoint.WORKSPACES}/${workspace_id}/programs/${program_alias}/block/${block_id}`
  );
}
