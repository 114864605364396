// Libraries
// import { Radio } from "@lockerpm/design";
import { useTranslation } from "react-i18next";
import { Dropdown } from "@lockerpm/design";

// Resources
import { ReactComponent as ArrowLeftSLine } from "#src/assets/images/icons/arrow-left-s-line.svg";
import { ReactComponent as ArrowRightSLine } from "#src/assets/images/icons/arrow-right-s-line.svg";
import { ReactComponent as ArrowUpSLine } from "#src/assets/images/icons/arrow-up-s-line.svg";
import { ReactComponent as MoreLine } from "#src/assets/images/icons/more-line.svg";

// Components
import { IconButton } from "../system/Button";
import DropdownItemLabel from "./antdProps/Dropdown/DropdownItemLabel";

interface IPaginationBarProps {
  numOfResult: number;
  selectedPage: number;
  pageSize: number;
  onChangePage: (page: number) => void;
  onChangePageSize: (size: number) => void;
}

const PaginationBar = ({
  numOfResult,
  selectedPage,
  pageSize,
  onChangePage,
  onChangePageSize,
}: IPaginationBarProps) => {
  const { t } = useTranslation("common", { keyPrefix: "pagination" });

  const numOfPage = Math.floor((numOfResult - 1) / pageSize) + 1;

  const pageSizeItems = [10, 25, 50, 100].map((item) => ({
    key: item,
    label: (
      <DropdownItemLabel selected={pageSize === item}>
        {item}/{t("page")}
      </DropdownItemLabel>
    ),
  }));

  const onClickPageButton = (pageNum: number) => {
    if (pageNum > 0 && pageNum <= numOfPage) {
      document.body.scrollTo({ top: 0 });
      document.documentElement.scrollTo({ top: 0 });
      onChangePage(pageNum);
    }
  };

  return (
    <div
      id="pagination-bar"
      className="fixed right-0 bottom-0 w-full h-12 bg-white drop-shadow-md flex justify-end items-center px-16 gap-6"
    >
      {/* Number of results */}
      <div className="flex items-center gap-2">
        <span className="font-bold-16">{numOfResult}</span>
        <span className="font-medium-16">
          {t("results", { count: numOfResult })}
        </span>
      </div>
      <div className="flex gap-2">
        {/* Left arrow button to move to previous page */}
        <IconButton
          variant="label-grey"
          size={8}
          onClick={() => onClickPageButton(selectedPage - 1)}
          disabled={selectedPage <= 1}
        >
          <ArrowLeftSLine />
        </IconButton>
        {/* To first page */}
        {selectedPage > 2 ? (
          // TODO: refactor into 1 component
          <button
            className="flex justify-center items-center h-8 w-8 font-medium-16 text-hard-grey"
            onClick={() => onClickPageButton(1)}
          >
            1
          </button>
        ) : null}
        {/* Indicator as there's more pages on the left */}
        {selectedPage > 3 ? (
          <IconButton variant="ghost" size={8}>
            <MoreLine />
          </IconButton>
        ) : null}
        {/* To previous page */}
        {selectedPage > 1 ? (
          <button
            className="flex justify-center items-center h-8 w-8 font-medium-16 text-hard-grey"
            onClick={() => onClickPageButton(selectedPage - 1)}
          >
            {selectedPage - 1}
          </button>
        ) : null}
        {/* Current page indicator */}
        <button
          className="flex justify-center items-center h-8 w-8 font-bold-16 text-black"
          disabled
        >
          {selectedPage}
        </button>
        {/* To next page */}
        {selectedPage < numOfPage ? (
          <button
            className="flex justify-center items-center h-8 w-8 font-medium-16 text-hard-grey"
            onClick={() => onClickPageButton(selectedPage + 1)}
          >
            {selectedPage + 1}
          </button>
        ) : null}
        {/* Indicator as there's more pages to the right */}
        {selectedPage < numOfPage - 2 ? (
          <IconButton variant="ghost" size={8}>
            <MoreLine />
          </IconButton>
        ) : null}
        {/* To last page */}
        {selectedPage < numOfPage - 1 ? (
          <button
            className="flex justify-center items-center h-8 w-8 font-medium-16 text-hard-grey"
            onClick={() => onClickPageButton(numOfPage)}
          >
            {numOfPage}
          </button>
        ) : null}
        {/* Right arrow button to move to next page */}
        <IconButton
          variant="label-grey"
          size={8}
          onClick={() => onClickPageButton(selectedPage + 1)}
          disabled={selectedPage >= numOfPage}
        >
          <ArrowRightSLine />
        </IconButton>
      </div>
      <Dropdown
        menu={{
          items: pageSizeItems,
          onClick: ({ key }) => {
            onChangePageSize(+key);
          },
        }}
        trigger={["click"]}
      >
        <div className="flex items-center bg-bright-grey border border-light-grey p-1.5 rounded-md gap-1.5 cursor-pointer">
          <span className="w-24 flex justify-center font-medium-16">
            {pageSize}/{t("page")}
          </span>
          <ArrowUpSLine className="fill-hard-grey" />
        </div>
      </Dropdown>
      {/* Antd sucks at customizing UI */}
      {/* <Radio.Group
        defaultValue="table"
        buttonStyle="solid"
        optionType="button"
        options={[
          { value: "table", label: "Table" },
          { value: "card", label: "Card" },
        ]}
      ></Radio.Group> */}
    </div>
  );
};

export default PaginationBar;
