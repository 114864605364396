// Libraries
import { type ReactNode, useEffect, useState, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import i18next from "i18next";

// Resources
import { ReactComponent as EditLine } from "#src/assets/images/icons/edit-line.svg";
// import { ReactComponent as DeleteBin6Line } from "#src/assets/images/icons/delete-bin-6-line.svg";
import { ReactComponent as CheckLine } from "#src/assets/images/icons/check-line.svg";
import { ReactComponent as CloseLine } from "#src/assets/images/icons/close-line.svg";
import { ReactComponent as LogoWindows10 } from "#src/assets/images/logos/logo-windows-10.svg";
import { ReactComponent as LogoApple } from "#src/assets/images/logos/logo-apple.svg";
import { ReactComponent as LogoUbuntu } from "#src/assets/images/logos/logo-ubuntu.svg";

// Components
import { ContentSection, StickySection } from "#src/layouts/content";
import LoadingState from "#src/components/common/system/LoadingState";
import NoResultsState from "#src/components/common/states/NoResultsState";
import { Button } from "#src/components/common/system/Button";
import { createToast } from "#src/components/common/system/toasts";
import { apiErrorHandler } from "#src/utils/apiErrorHandler";

// API-related
import type { IPolicyItem } from "#src/services/devices/policies";
import devicesServices, { type IHostItem } from "#src/services/devices";

// Children
import HostsByPolicyTable from "#src/components/devices/policies/HostsByPolicyTable";
import EditPolicyDrawer from "#src/components/devices/policies/drawers/EditPolicyDrawer";
import { PolicyComplianceValue } from "#src/config/filter/device/value";

const CompatibleItem = ({
  logo,
  osName,
  value,
}: {
  logo: ReactNode;
  osName: string;
  value: boolean;
}) => {
  return (
    <div className="flex items-center justify-between gap-3 bg-bright-grey rounded-md p-3">
      <div className="flex items-center gap-3">
        {logo}
        {osName}
      </div>
      {value ? (
        <CheckLine className="fill-warning-safe" />
      ) : (
        <CloseLine className="fill-warning" />
      )}
    </div>
  );
};

const DevicePolicyDetails = () => {
  const { t } = useTranslation("devices", { keyPrefix: "policies.detailPage" });
  const { workspace: workspaceId, policy: policyId } = useParams<
    "workspace" | "policy"
  >();
  const locale = i18next.language;

  const [policyDetails, setPolicyDetails] = useState<IPolicyItem | null>(null);
  const [hostsByPolicy, setHostsByPolicy] = useState<IHostItem[] | null>(null);

  const [isLoadingPolicy, setLoadingPolicy] = useState<boolean>(false);
  const [isLoadingHosts, setLoadingHosts] = useState<boolean>(false);

  const [openEditDrawer, setOpenEditDrawer] = useState<boolean>(false);

  const fetchPolicyDetails = useCallback(() => {
    if (workspaceId && policyId && !isNaN(+policyId)) {
      setLoadingPolicy(true);
      devicesServices
        .get_policy_details(workspaceId, +policyId)
        .then((response) => {
          setPolicyDetails(response);
          setLoadingPolicy(false);
        })
        .catch((error) => {
          apiErrorHandler(error, {
            toastMessage: t("notification.loadPolicy.fail"),
          });
        });
    }
  }, [workspaceId, policyId, t]);

  const onToggleEnablePolicy = (value: boolean) => {
    // TODO: optimistic UI, maybe we need not to refresh
    if (workspaceId && policyDetails) {
      devicesServices
        .update_workspace_policy(workspaceId, policyDetails.id, {
          enabled: value,
          hosts_tags: policyDetails.hosts_tags.map((item) => ({
            id: item.id.toString(),
            type: item.type,
          })),
        })
        .then(() => {
          fetchPolicyDetails();
          createToast({
            type: "success",
            message: t("notification.updatePolicy.success"),
          });
        })
        .catch((error) => {
          apiErrorHandler(error, {
            toastMessage: t("notification.updatePolicy.fail"),
          });
        });
    }
  };

  useEffect(() => {
    let ignore = false;

    if (!ignore) {
      fetchPolicyDetails();
    }

    return () => {
      ignore = true;
    };
  }, [fetchPolicyDetails]);

  useEffect(() => {
    let ignore = false;

    if (!ignore && workspaceId && policyId && !isNaN(+policyId)) {
      setLoadingHosts(true);
      devicesServices
        .list_hosts(workspaceId, {
          os: undefined,
          host_status: undefined,
          tag: undefined,
          policy_compliance: undefined,
          policy_compliance_id: policyId,
          program_id: undefined,
          q: undefined,
          page: undefined,
          size: undefined,
        })
        .then((response) => {
          setHostsByPolicy(response.results);
          setLoadingHosts(false);
        });
    }

    return () => {
      ignore = true;
    };
  }, [workspaceId, policyId, t]);

  return workspaceId && policyId && policyDetails ? (
    isLoadingPolicy ? (
      <LoadingState />
    ) : (
      <>
        <StickySection>
          <h1>
            {locale === "vi"
              ? policyDetails.policy.name.vi
              : policyDetails.policy.name.en}
          </h1>
          <div className="flex gap-1">
            <Button
              variant={policyDetails.enabled ? "warning" : "secondary"}
              size="large"
              onClick={() => {
                onToggleEnablePolicy(!policyDetails.enabled);
              }}
            >
              {policyDetails.enabled ? t("button.disable") : t("button.enable")}
            </Button>
            <Button
              variant="secondary"
              size="large"
              onClick={() => {
                setOpenEditDrawer(true);
              }}
            >
              <EditLine />
              {t("button.edit")}
            </Button>
            {/* <IconButton variant="warning">
              <DeleteBin6Line />
            </IconButton> */}
          </div>
        </StickySection>
        <ContentSection>
          <div className="grid grid-cols-3 gap-6">
            <div className="flex flex-col gap-6">
              <h2>{t("title.policyDescription")}</h2>
              <div className="bg-bright-grey font-regular-16-body p-6 rounded-md whitespace-pre-wrap">
                {locale === "vi"
                  ? policyDetails.policy.description.vi
                  : policyDetails.policy.description.en}
              </div>
            </div>
            <div className="flex flex-col gap-6">
              <h2>{t("title.resolve")}</h2>
              <div className="bg-bright-grey font-regular-16-body p-6 rounded-md whitespace-pre-wrap">
                {locale === "vi"
                  ? policyDetails.policy.resolution.vi
                  : policyDetails.policy.resolution.en}
              </div>
            </div>
          </div>
          <div className="flex flex-col gap-6">
            <h2>{t("title.compatibleWith")}</h2>
            <div className="grid grid-cols-3">
              <div className="grid grid-cols-3 gap-6 col-span-2">
                <CompatibleItem
                  logo={<LogoWindows10 />}
                  osName={"Windows"}
                  value={policyDetails.policy.platform.includes("windows")}
                />
                <CompatibleItem
                  logo={<LogoApple />}
                  osName={"macOS"}
                  value={policyDetails.policy.platform.includes("darwin")}
                />
                <CompatibleItem
                  logo={<LogoUbuntu />}
                  osName={"Linux"}
                  value={policyDetails.policy.platform.includes("linux")}
                />
              </div>
            </div>
          </div>
          {isLoadingHosts ? (
            <LoadingState />
          ) : hostsByPolicy && hostsByPolicy.length ? (
            <>
              <div className="flex gap-6 bg-bright-grey rounded-md">
                <div className="flex items-center justify-between p-6 flex-1">
                  <span className="font-medium-18 text-support-seafoam">
                    {t("passedPolicy")}
                  </span>
                  <span className="font-bold-24">
                    {
                      hostsByPolicy.filter(
                        (item) => item.policy_compliance === "passed"
                      ).length
                    }
                  </span>
                </div>
                <hr className="border-l border-light-grey" />
                <div className="flex items-center justify-between p-6  flex-1">
                  <span className="font-medium-18 text-warning-red-danger">
                    {t("failedPolicy")}
                  </span>
                  <span className="font-bold-24">
                    {
                      hostsByPolicy.filter(
                        (item) =>
                          item.policy_compliance ===
                          PolicyComplianceValue.FAILED
                      ).length
                    }
                  </span>
                </div>
              </div>
              <HostsByPolicyTable data={hostsByPolicy} />
            </>
          ) : (
            <NoResultsState />
          )}
        </ContentSection>
        <EditPolicyDrawer
          selectedItemId={openEditDrawer ? +policyId : null}
          open={openEditDrawer}
          onClose={() => {
            setOpenEditDrawer(false);
          }}
          workspaceId={workspaceId}
          onRefresh={fetchPolicyDetails}
        />
      </>
    )
  ) : null;
};

export default DevicePolicyDetails;
