// Libraries
import { useTranslation } from "react-i18next";

// Resources
import { ReactComponent as EditLine } from "#src/assets/images/icons/edit-line.svg";
import { ReactComponent as DeleteBin6Line } from "#src/assets/images/icons/delete-bin-6-line.svg";

// General
import { useAppSelector } from "#src/store/hooks";
import global from "#src/config/global";

// Components
import LoadingState from "#src/components/common/system/LoadingState";
import NoResultsState from "#src/components/common/states/NoResultsState";
import TableActionLabel from "#src/components/common/Table/TableActionLabel";
import TableHeader from "#src/components/common/Table/TableHeader";
import type { ITableHeaderPropItem } from "#src/components/common/Table/TableHeader";
import { apiErrorHandler } from "#src/utils/apiErrorHandler";

// API-related
import workspaceServices, {
  type IWorkspaceMember,
} from "#src/services/workspace";
import { WorkspaceMemberRoleValue } from "#src/config/filter/workspace/value";

// Children
import MemberTableRow from "./TableRow";

interface IMemberTableGridProps {
  workspaceId: string;
  currentUserRole: string | null;
  memberList: IWorkspaceMember[] | null;
  isLoading: boolean;
  selectedMemberIds: number[];
  onChangeSelectedMembers: (selected: IWorkspaceMember) => void;
  refreshMemberList: () => void;
}

const MemberTableGrid = ({
  workspaceId,
  currentUserRole,
  memberList,
  isLoading,
  selectedMemberIds,
  onChangeSelectedMembers,
  refreshMemberList,
}: IMemberTableGridProps) => {
  const { t } = useTranslation("workspace", {
    keyPrefix: "member",
  });
  const currentUser = useAppSelector((state) => state.auth.userInfo);

  const headers: ITableHeaderPropItem[] = [
    { name: "", align: "left" },
    { name: t("headers.number"), align: "left" },
    { name: t("headers.member"), align: "left" },
    { name: t("headers.email"), align: "left" },
    { name: t("headers.roles"), align: "left" },
    { name: t("headers.accessDate"), align: "left" },
    { name: t("headers.actions"), align: "center" },
  ];

  const actionList = [
    {
      key: "promote",
      label: (
        <TableActionLabel icon={<EditLine />} text={t("actions.promote")} />
      ),
    },
    {
      key: "demote",
      label: (
        <TableActionLabel icon={<EditLine />} text={t("actions.demote")} />
      ),
    },
    {
      key: "delete",
      label: (
        <TableActionLabel
          icon={<DeleteBin6Line />}
          text={t("actions.delete")}
          warning
        />
      ),
    },
  ];

  const onPromoteToAdmin = (memberId: number) => {
    workspaceServices
      .update_member(workspaceId, memberId, { role: "admin" })
      .then((response) => {
        if (response) {
          refreshMemberList();
        }
      })
      .catch((error) => {
        apiErrorHandler(error, {
          toastMessage: t("notification.updateMember.fail"),
        });
      });
  };

  const onDemoteToMember = (memberId: number) => {
    workspaceServices
      .update_member(workspaceId, memberId, { role: "member" })
      .then((response) => {
        if (response.success) {
          refreshMemberList();
        }
      })
      .catch((error) => {
        apiErrorHandler(error, {
          toastMessage: t("notification.updateMember.fail"),
        });
      });
  };

  const onDeleteMember = (memberId: number) => {
    workspaceServices
      .remove_member(workspaceId, memberId)
      .then(() => {
        refreshMemberList();
      })
      .catch((error) => {
        apiErrorHandler(error, {
          toastMessage: t("notification.deleteMember.fail"),
        });
      });
  };

  const onClickActionItems = (key: string, item: IWorkspaceMember) => {
    switch (key) {
      case "promote":
        onPromoteToAdmin(item.id);
        return;
      case "demote":
        onDemoteToMember(item.id);
        return;
      case "delete":
        global.confirm(() => onDeleteMember(item.id));
        return;
    }
  };

  return (
    <>
      {isLoading || memberList === null ? (
        <LoadingState />
      ) : memberList.length === 0 ? (
        <NoResultsState />
      ) : (
        <div className="grid grid-cols-[2.75rem_1fr_7fr_3fr_4fr_5fr_2fr]">
          <TableHeader title="assets/domains" headerProps={headers} />
          {memberList.map((item, index) => (
            <MemberTableRow
              key={`tworkspace/members-tr${item.id}`}
              index={index}
              data={item}
              isCurrentUser={
                (item.user ? item.user.email : item.email) ===
                currentUser?.email
              }
              actionList={actionList.filter((action) => {
                if (
                  currentUserRole === WorkspaceMemberRoleValue.OWNER ||
                  currentUserRole === WorkspaceMemberRoleValue.ADMIN
                ) {
                  if (
                    (item.role === WorkspaceMemberRoleValue.ADMIN &&
                      action.key === "promote") ||
                    (item.role === WorkspaceMemberRoleValue.MEMBER &&
                      action.key === "demote")
                  ) {
                    return false;
                  } else {
                    return true;
                  }
                } else {
                  return false;
                }
              })}
              onClickActionItems={onClickActionItems}
              selectedMemberIds={selectedMemberIds}
              onChangeSelectedMembers={onChangeSelectedMembers}
            />
          ))}
        </div>
      )}
    </>
  );
};

export default MemberTableGrid;
