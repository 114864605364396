import type { IPaginateParams, IPaginatedResponse } from "#src/@types/api";
import endpoint from "#src/config/endpoint";
import request from "#src/utils/request";

export type IVulnSource = "data_leak" | "bugbounty" | "auto_scan" | "pentest";

export interface IVulnerabilityItem {
  created_time: number;
  id: number;
  internal_id: number | null;
  is_rewarded: boolean;
  is_supported: boolean;
  last_update: number | null;
  reporter: {
    email: string;
    username: string;
    full_name: string;
    avatar: string;
  } | null;
  severity: string;
  status: string;
  sub_status: string;
  support_status: any | null;
  title: string;
  updated_time: number | null;
  vuln_source: IVulnSource;
}

interface IVulnerabilityParams extends IPaginateParams {
  filter_status: string | undefined;
  filter_sub_status: string | undefined;
  filter_severity: string | undefined;
  from: number | undefined;
  to: number | undefined;
  updated_time_from: number | undefined;
  updated_time_to: number | undefined;
  // sort: created_time_desc = (created_time | updated_time | severity)_(desc | asc)
  sort: string | undefined;
  // visibility: public | private
  visibility: string | undefined;
  program: string | undefined;
  // auto_scan: 0 | 1| undefined;
  assessment_type: string | undefined;
  // rewarded: 0 | 1
  rewarded: string | undefined;
  // TODO: this is not determined. Maybe wrong.
  pentest: string | undefined;
  archived: string | undefined;
  scan_history_id: string | undefined;
}

function list_vulnerabilities(
  workspace_id: string,
  params: IVulnerabilityParams
) {
  return request.get<never, IPaginatedResponse<IVulnerabilityItem>>(
    `${endpoint.WORKSPACES}/${workspace_id}/vulns`,
    { params }
  );
}

export interface IVulnerabilitySummary {
  open: number;
  accepted: number;
  rejected: number;
  pending_disclosure?: number;
  related?: number;
  all: number;
}

function vulnerabilities_summary(
  workspace_id: string,
  params: Omit<IVulnerabilityParams, "status">
) {
  return request.get<never, IVulnerabilitySummary>(
    `${endpoint.WORKSPACES}/${workspace_id}/vulns/summary`,
    { params }
  );
}

export interface IVulnDataLeakDescription {
  previews: string[];
  source: string;
  details: {
    [S: string]: string;
  };
  type: string;
  keyword: string;
}

export interface IVulnerabilityDetail extends IVulnerabilityItem {
  program: any | null;
  program_logo: any | null;
  org_alias: any | null;
  visibility: string;
  point_reward: { point: number; reason: string };
  money_rewarded: number;
  duplicated_report: any | null;
  data_leak_id: number | null;
  target: string;
  type: any | null;
  category: string;
  vuln_name: string;
  vuln_variant: string;
  cvss_v3: string;
  vuln_location: string;
  description: string | IVulnDataLeakDescription;
  steps: string;
  impact: string;
  recommendation: string;
  votes: number;
  user_vote: any | null;
  voted: boolean;
  attachments: any[];
}

function vulnerability_details(workspace_id: string, vulnerability_id: number) {
  return request.get<never, IVulnerabilityDetail>(
    `${endpoint.WORKSPACES}/${workspace_id}/vulns/${vulnerability_id}`
  );
}

function update_vulnerability(
  workspace_id: string,
  vulnerability_id: number,
  data: Partial<IVulnerabilityDetail>
) {
  return request.put(
    `${endpoint.WORKSPACES}/${workspace_id}/vulns/${vulnerability_id}`,
    { ...data }
  );
}

function archive_vulnerability(workspace_id: string, vulnerability_id: number) {
  return request.post(
    `${endpoint.WORKSPACES}/${workspace_id}/vulns/${vulnerability_id}/archive`
  );
}

function vote_vulnerability(
  workspace_id: string,
  vulnerability_id: number,
  vote: boolean | null
) {
  return request.post(
    `${endpoint.WORKSPACES}/${workspace_id}/vulns/${vulnerability_id}/votes`,
    { vote }
  );
}

export interface IAssigneeResponse {
  id: number;
  role: "member" | string;
  access_time: number;
  is_primary: boolean;
  email: string;
  full_name: string;
  avatar: string;
}

function get_vulnerability_assignee(
  workspace_id: string,
  vulnerability_id: number
) {
  return request.get<never, IAssigneeResponse>(
    `${endpoint.WORKSPACES}/${workspace_id}/vulns/${vulnerability_id}/assignee`
  );
}

function set_vulnerability_assignee(
  workspace_id: string,
  vulnerability_id: number,
  assigneeId: string
) {
  return request.post<never, { success: boolean }>(
    `${endpoint.WORKSPACES}/${workspace_id}/vulns/${vulnerability_id}/assignee`,
    { assignee: assigneeId }
  );
}

export interface IResourceVulnCategory {
  name: string;
  cvss_v3: string | null;
  subcategory: {
    name: string;
    cvss_v3: string | null;
    severity: string;
    variants: { name: string; cvss_v3: string | null; severity: string }[];
  }[];
}

export default {
  list_vulnerabilities,
  vulnerabilities_summary,
  vulnerability_details,
  update_vulnerability,
  archive_vulnerability,
  vote_vulnerability,
  get_vulnerability_assignee,
  set_vulnerability_assignee,
};
