// Libraries
import dayjs from "dayjs";
import { Tooltip } from "@lockerpm/design";
import { useTranslation } from "react-i18next";

// Resources
import { ReactComponent as CheckboxCircleLine } from "#src/assets/images/icons/checkbox-circle-line.svg";
import { ReactComponent as CircleLine } from "#src/assets/images/icons/circle-line.svg";
import { ReactComponent as ArrowRightSLine } from "#src/assets/images/icons/arrow-right-s-line.svg";

// Components
import { TableRowWrapper, TableDataCell } from "#src/components/common/Table";
import TooltipInnerWithCopy from "#src/components/common/TooltipInnerWithCopy";
import { convertWebkitTimestampToUnix } from "#src/utils/common";
import { Button } from "#src/components/common/system/Button";

// API-related
import type {
  ILeakedDataCookieDetails,
  ILeakedDataItem,
} from "#src/services/dataLeak";

interface IDataLeakCookieTableRowProps {
  workspaceId: string;
  data: ILeakedDataItem<ILeakedDataCookieDetails>;
  onClickViewRaw: (cookie: string) => void;
}

const DataLeakCookieTableRow = ({
  workspaceId,
  data,
  onClickViewRaw,
}: IDataLeakCookieTableRowProps) => {
  const { t } = useTranslation("dataLeakDetection", {
    keyPrefix: "tabs.cookie",
  });

  const getCookieExpiryString = (expiry: number | null | undefined) => {
    if (expiry === null || expiry === undefined) return t("invalidDate");

    if (dayjs.unix(expiry).isValid())
      return dayjs.unix(expiry).format("HH:mm DD.MM.YYYY");

    // Check if expiry date is on webkit/chrome timestamp
    // ref: webkit/chrome timestamp: https://www.epochconverter.com/webkit
    if (dayjs.unix(convertWebkitTimestampToUnix(expiry)).isValid())
      return dayjs
        .unix(convertWebkitTimestampToUnix(expiry))
        .format("HH:mm DD.MM.YYYY");

    return t("invalidDate");
  };

  return (
    <TableRowWrapper
      to={`/${workspaceId}/vulnerabilities?assessment_type=data_leak&vuln_id=${data.vuln_id}`}
    >
      <TableDataCell>
        {data.details.domain ? (
          <Tooltip title={<TooltipInnerWithCopy value={data.details.domain} />}>
            <span className="overflow-hidden text-ellipsis">
              {data.details.domain}
            </span>
          </Tooltip>
        ) : null}
      </TableDataCell>
      <TableDataCell className="justify-center">
        {data.details.include_subdomains ? (
          <CheckboxCircleLine className="text-primary h-6 w-6" />
        ) : (
          <CircleLine className="h-6 w-6" />
        )}
      </TableDataCell>
      <TableDataCell>
        {data.details.path ? (
          <Tooltip title={<TooltipInnerWithCopy value={data.details.path} />}>
            <span className="overflow-hidden text-ellipsis">
              {data.details.path}
            </span>
          </Tooltip>
        ) : null}
      </TableDataCell>
      <TableDataCell className="justify-center">
        {data.details.secure ? (
          <CheckboxCircleLine className="text-primary h-6 w-6" />
        ) : (
          <CircleLine className="h-6 w-6" />
        )}
      </TableDataCell>
      <TableDataCell>
        {getCookieExpiryString(data.details.expiry)}
      </TableDataCell>
      <TableDataCell>
        {data.details.name ? (
          <Tooltip title={<TooltipInnerWithCopy value={data.details.name} />}>
            <span className="overflow-hidden text-ellipsis">
              {data.details.name}
            </span>
          </Tooltip>
        ) : null}
      </TableDataCell>
      <TableDataCell>
        {data.details.value ? (
          <Tooltip title={<TooltipInnerWithCopy value={data.details.value} />}>
            <span className="overflow-hidden whitespace-nowrap text-ellipsis">
              {data.details.value}
            </span>
          </Tooltip>
        ) : null}
      </TableDataCell>
      <TableDataCell>
        <Button
          variant="text"
          className="px-0"
          onClick={(e) => {
            e.preventDefault();
            onClickViewRaw(data.details.raw ?? "");
          }}
        >
          {t("button.viewRaw")}
          <ArrowRightSLine height={"1rem"} width={"1rem"} />
        </Button>
      </TableDataCell>
    </TableRowWrapper>
  );
};

export default DataLeakCookieTableRow;
