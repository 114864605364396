import type { Params, RouteObject } from "react-router-dom";
import i18next from "i18next";
import { pathname } from "#src/config/pathname";

import Pentest from "#src/pages/pentest";
import PentestDetails from "#src/pages/pentest/details";
import AddPentest from "#src/pages/pentest/add";

const breadcrumbTFunc = i18next.getFixedT(null, "general", "breadcrumb");

export const pentestRoute: RouteObject = {
  path: pathname.PENTEST,
  handle: {
    crumb: () => ({
      getText: () => breadcrumbTFunc("pentest.label"),
      path: pathname.PENTEST,
    }),
  },
  children: [
    {
      index: true,
      element: <Pentest />,
    },
    {
      path: pathname.PENTEST_DETAILS,
      handle: {
        crumb: (params: Readonly<Params<string>>) => ({
          getText: () =>
            Object.hasOwn(params, "pentest") ? params["pentest"] : "---",
          path: pathname.PENTEST_DETAILS,
        }),
      },
      element: <PentestDetails />,
    },
    {
      path: pathname.ADD_PENTEST,
      handle: {
        crumb: () => ({
          getText: () => breadcrumbTFunc("pentest.addPentest.label"),
          path: pathname.ADD_PENTEST,
        }),
      },
      element: <AddPentest />,
    },
  ],
};
