// Libraries
import type { ReactNode } from "react";
import { useTranslation } from "react-i18next";
import dayjs from "dayjs";
import { Dropdown } from "@lockerpm/design";

// Resources
import { ReactComponent as MoreLine } from "#src/assets/images/icons/more-line.svg";
import { ReactComponent as CheckboxCircleLine } from "#src/assets/images/icons/checkbox-circle-line.svg";
import { ReactComponent as DraftLine } from "#src/assets/images/icons/draft-line.svg";
import { ReactComponent as PdfLine } from "#src/assets/images/icons/pdf-line.svg";

// Components
import { IconButton } from "#src/components/common/system/Button";
import { TableRowWrapperDiv } from "#src/components/common/Table/TableRowWrapper";
import { TableDataCell } from "#src/components/common/Table";
import { StatusLabel } from "#src/components/common/labels";
import { ObjectImage } from "#src/components/common/system/Object";

// API-related
import type { ITrustCenterDocumentItem } from "#src/services/trustCenter/document";

// Children
import {
  TrustCenterDocumentActionEnum,
  TrustCenterDocumentStatusValue,
} from "../enum";

const StatusCard = ({ status }: { status: string }) => {
  const { t } = useTranslation("trustCenter", { keyPrefix: "policy" });

  return status === TrustCenterDocumentStatusValue.DRAFT ? (
    <StatusLabel
      name={t("card.draft")}
      color="bg-button-disabled text-hard-grey"
      icon={<DraftLine className="h-4 w-4 min-w-[1rem]" />}
    />
  ) : status === TrustCenterDocumentStatusValue.PUBLISHED ? (
    <StatusLabel
      name={t("card.published")}
      color="bg-support-seafoam-pastel text-support-seafoam"
      icon={<CheckboxCircleLine className="h-4 w-4 min-w-[1rem]" />}
    />
  ) : null;
};

interface ITrustCenterDocumentTableRowProps {
  data: ITrustCenterDocumentItem;
  actionList: {
    key: TrustCenterDocumentActionEnum;
    getLabel: () => ReactNode;
  }[];
  onClickActionItems: (key: string, item: ITrustCenterDocumentItem) => void;
}

const TrustCenterDocumentTableRow = ({
  data,
  actionList,
  onClickActionItems,
}: ITrustCenterDocumentTableRowProps) => {
  return (
    <TableRowWrapperDiv>
      <TableDataCell>{data.name}</TableDataCell>
      <TableDataCell>
        {dayjs
          .unix(data.updated_time ?? data.created_time)
          .format("HH:mm DD.MM.YYYY")}
      </TableDataCell>
      <TableDataCell>
        <StatusCard status={data.status} />
      </TableDataCell>
      <TableDataCell className="py-2 px-0">
        <a
          className="bg-bright-grey w-full p-2 flex gap-2 items-center rounded-md text-current hover:text-current no-underline"
          href={data.url ?? undefined}
          target="_blank"
          rel="noreferrer"
        >
          {data.url ? (
            <ObjectImage
              data={data.url}
              className="h-9 w-9 object-cover rounded-md overflow-hidden"
            >
              <div className="flex items-center justify-center h-full w-full border border-grey bg-light-grey rounded-md">
                <PdfLine className="h-6 w-6 fill-grey" />
              </div>
            </ObjectImage>
          ) : (
            <div className="flex items-center justify-center h-9 w-9 border border-grey bg-light-grey rounded-md">
              <PdfLine className="h-6 w-6 fill-grey" />
            </div>
          )}
          {data.name}
        </a>
      </TableDataCell>
      <TableDataCell className="justify-center">
        <Dropdown
          menu={{
            items: actionList.map((item) => ({
              key: item.key,
              label: item.getLabel(),
            })),
            onClick: ({ key, domEvent }) => {
              domEvent.preventDefault();
              domEvent.stopPropagation();
              onClickActionItems(key, data);
            },
          }}
          trigger={["click"]}
          disabled={actionList.length === 0}
        >
          <IconButton
            variant="ghost"
            size={9}
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
            }}
          >
            <MoreLine />
          </IconButton>
        </Dropdown>
      </TableDataCell>
    </TableRowWrapperDiv>
  );
};

export default TrustCenterDocumentTableRow;
