import { Modal } from "@lockerpm/design";
import { useTranslation } from "react-i18next";
import { SourceLabel } from "#src/components/common/labels";
import { getDomainFromUrl } from "./utilities";

interface ISourceDetailsModalProps {
  open: boolean;
  onClose: () => void;
  source: string[] | null;
}

/** Used when user clicking on source cells on Data Leak Tables. The same pattern is reused so we made it a component. */
const SourceDetailsModal = ({
  open,
  onClose,
  source,
}: ISourceDetailsModalProps) => {
  const { t } = useTranslation("common", { keyPrefix: "source" });

  const onCloseAndCleanup = () => {
    onClose();
  };

  const getDomainList = source
    ? source.reduce<string[]>((prev, cur) => {
        const domain = getDomainFromUrl(cur);
        if (prev.includes(domain)) {
          return prev;
        }
        return [...prev, domain];
      }, [])
    : [];

  const groupByDomain = source
    ? getDomainList.map((domain) => ({
        domain,
        urls: source.filter((item) => getDomainFromUrl(item).includes(domain)),
      }))
    : [];

  return (
    <Modal
      open={open}
      onCancel={onCloseAndCleanup}
      title={<h2>{t("title")}</h2>}
      centered
      footer={null}
      width="44rem"
    >
      {groupByDomain.map((item) => (
        <div
          key={`source-modal-${item.domain}`}
          className="flex flex-col gap-3 overflow-hidden"
        >
          <span className="text-hard-grey">{item.domain}</span>
          <div className="flex flex-wrap items-center gap-2">
            {item.urls?.map((url, urlId) => (
              <SourceLabel
                key={`source-modal-${item.domain}-url${url}${urlId}`}
                noEllipsis
              >
                {url}
              </SourceLabel>
            ))}
          </div>
        </div>
      ))}
    </Modal>
  );
};

export default SourceDetailsModal;
