// Libraries
import { Dropdown } from "@lockerpm/design";
import { useState } from "react";

// Resources
import { ReactComponent as ArrowDownSLine } from "#src/assets/images/icons/arrow-down-s-line.svg";
import { ReactComponent as CloseLine } from "#src/assets/images/icons/close-line.svg";
import { ReactComponent as UserLine } from "#src/assets/images/icons/user-line.svg";

// Components
import DropdownItemLabel from "#src/components/common/helper/antdProps/Dropdown/DropdownItemLabel";
import { ObjectImage } from "#src/components/common/system/Object";

// APi-related
import type { IWorkspaceMember } from "#src/services/workspace";
import type { IAssigneeResponse } from "#src/services/vulnerabilities";

interface IAssigneeInputProps {
  assignee: Pick<IAssigneeResponse, "id" | "full_name" | "avatar"> | null;
  userList: IWorkspaceMember[];
  onChangeAssignee: (key: string | null) => void;
}

const AssigneeInput = ({
  assignee,
  userList,
  onChangeAssignee,
}: IAssigneeInputProps) => {
  const [open, setOpen] = useState<boolean>(false);

  return (
    <Dropdown
      open={open}
      onOpenChange={(currentOpen) => {
        setOpen(currentOpen);
      }}
      menu={{
        items: userList.map((user) => ({
          key: user.id,
          label: (
            <DropdownItemLabel
              selected={user.id === assignee?.id}
              syncWidthId="assignee-select"
            >
              <ObjectImage data={user.user?.avatar} className="rounded-full">
                <div className="w-full h-full flex items-center justify-center bg-light-grey rounded-full">
                  <UserLine className="h-5 w-5 fill-medium-grey" />
                </div>
              </ObjectImage>
              {user.user ? user.user.full_name : user.email}
            </DropdownItemLabel>
          ),
        })),
        onClick: ({ key }) => {
          onChangeAssignee(key);
          setOpen(false);
        },
      }}
      trigger={["click"]}
    >
      <div
        id="assignee-select"
        className={`flex p-2 text-left items-center justify-between ${
          open
            ? "outline outline-2 outline-primary"
            : "bg-bright-grey cursor-pointer"
        } rounded-md w-full`}
      >
        <div className="flex items-center gap-2">
          <ObjectImage
            // key here to let React know that we want to rerender this when assignee is changed.
            key={`vuln-assignee-${assignee?.id}`}
            data={assignee?.avatar}
            className="rounded-full"
          >
            <div className="w-full h-full flex items-center justify-center bg-light-grey rounded-full">
              <UserLine className="h-5 w-5 fill-medium-grey" />
            </div>
          </ObjectImage>
          {open ? (
            <input
              className="w-full outline-none caret-primary bg-transparent"
              autoFocus
              onClick={(e) => {
                e.stopPropagation();
              }}
            />
          ) : (
            <span className={assignee ? "text-primary" : "text-medium-grey"}>
              {assignee ? assignee.full_name : "Unassigned"}
            </span>
          )}
        </div>
        {open ? (
          <button
            className="w-4 h-4 flex items-center"
            onClick={() => {
              setOpen(false);
            }}
          >
            <CloseLine className="fill-hard-grey w-4 h-4" />
          </button>
        ) : (
          <div className="w-4 h-4 flex items-center">
            <ArrowDownSLine className="fill-hard-grey w-4 h-4" />
          </div>
        )}
      </div>
    </Dropdown>
  );
};

export default AssigneeInput;
