import type { IUserInfo } from "#src/services/auth";
import { type PayloadAction, createSlice } from "@reduxjs/toolkit";

interface IAuthState {
  userInfo: IUserInfo | null;
}

const initialState: IAuthState = { userInfo: null };

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    updateUserInfo: (state, action: PayloadAction<IUserInfo>) => ({
      ...state,
      userInfo: action.payload,
    }),
  },
});

export const { updateUserInfo } = authSlice.actions;

export default authSlice.reducer;
