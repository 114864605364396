import endpoint from "#src/config/endpoint";
import request from "#src/utils/request";

export interface IVulnerabilityComment {
  id: number;
  created_time: number;
  message: string;
  attachments: { id: number; url: string; file_name: string; status: string }[];
  is_owner: boolean;
  is_reporter: boolean;
  is_owner_comment: boolean;
  program_alias: any;
  comment_type: string;
  notification: { [language: string]: string };
  user: {
    full_name: string;
    avatar: string;
    is_org: boolean;
    is_public: boolean;
    username: string;
  };
  program_org: {
    name: string;
    email: string;
    phone: string;
    color: string | null;
    description: {
      en: string;
      vi: string;
    };
    logo: string | null;
    social: any[];
  };
}

function list_vulnerability_comments(
  workspace_id: string,
  vulnerability_id: number
) {
  return request.get<never, IVulnerabilityComment[]>(
    `${endpoint.WORKSPACES}/${workspace_id}/vulns/${vulnerability_id}/comments`
  );
}

function create_new_comment(
  workspace_id: string,
  vulnerability_id: number,
  comment: { message: string; attachments: string[] }
) {
  return request.post<never, { id: number }>(
    `${endpoint.WORKSPACES}/${workspace_id}/vulns/${vulnerability_id}/comments`,
    comment
  );
}

function create_new_note(
  workspace_id: string,
  vulnerability_id: number,
  note: { message: string; attachments: string[] }
) {
  return request.post<never, { id: number }>(
    `${endpoint.WORKSPACES}/${workspace_id}/vulns/${vulnerability_id}/comments/notes`,
    note
  );
}

export default {
  list_vulnerability_comments,
  create_new_comment,
  create_new_note,
};
