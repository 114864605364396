export enum TrustCenterDocumentActionEnum {
  EDIT = "TRUST_CENTER_DOCUMENT_ACTION_ENUM_EDIT",
  MARK_AS_PRIVATE = "TRUST_CENTER_DOCUMENT_ACTION_ENUM_MARK_AS_PRIVATE",
  MARK_AS_PUBLIC = "TRUST_CENTER_DOCUMENT_ACTION_ENUM_MARK_AS_PUBLIC",
  PUBLISH = "TRUST_CENTER_DOCUMENT_ACTION_ENUM_PUBLISH",
  PUT_TO_DRAFT = "TRUST_CENTER_DOCUMENT_ACTION_ENUM_PUT_TO_DRAFT",
  DOWNLOAD = "TRUST_CENTER_DOCUMENT_ACTION_ENUM_DOWNLOAD",
  DELETE = "TRUST_CENTER_DOCUMENT_ACTION_ENUM_DELETE",
}

export enum TrustCenterDocumentStatusValue {
  DRAFT = "draft",
  PUBLISHED = "published",
}
