import type { ReactNode } from "react";
import { ReactComponent as InformationLine } from "#src/assets/images/icons/information-line.svg";

interface IInputFieldWrapperProps {
  title: string;
  requiredMark?: boolean;
  input: ReactNode;
  error?: string;
}

/** This doesn't feel right. Might want to remake this into a common wrapper for every input and adapters for each of them. */
export const InputFieldWrapper = ({
  title,
  requiredMark = false,
  input,
  error,
}: IInputFieldWrapperProps) => {
  return (
    <div className="flex flex-col gap-3">
      <h4>
        {requiredMark ? <span className="text-primary pr-0.5">*</span> : null}
        {title}
      </h4>
      {input}
      {error ? (
        <span className="font-medium-14-forced text-warning flex items-center gap-1">
          <InformationLine className="h-5 w-5" />
          {error}
        </span>
      ) : null}
    </div>
  );
};
