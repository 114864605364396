// Libraries
import { useTranslation } from "react-i18next";
import { generatePath, useNavigate } from "react-router";

// General
import global from "#src/config/global";
import { pathname } from "#src/config/pathname";

// Components
import { TableHeader } from "#src/components/common/Table";
import type { ITableHeaderPropItem } from "#src/components/common/Table/TableHeader";
import LoadingState from "#src/components/common/system/LoadingState";
import NoResultsState from "#src/components/common/states/NoResultsState";
import { useCurrentWorkspaceRole } from "#src/hooks/useCurrentWorkspaceRole";
import { apiErrorHandler } from "#src/utils/apiErrorHandler";

// API-related
import type { ITrustCenterFaqItem } from "#src/services/trustCenter/faq";
import trustCenterServices from "#src/services/trustCenter";
import { WorkspaceMemberRoleValue } from "#src/config/filter/workspace/value";

// Children
import TrustCenterFaqTableRow from "./TableRow";
import { trustCenterFaqActionList } from "./actions";
import { TrustCenterFaqActionEnum, TrustCenterFaqStatusValue } from "../enum";

interface ITrustCenterFaqTableGridProps {
  workspaceId: string;
  faqList: ITrustCenterFaqItem[] | null;
  isLoading: boolean;
  onRefresh: () => void;
}

const TrustCenterFaqTableGrid = ({
  workspaceId,
  faqList,
  isLoading,
  onRefresh,
}: ITrustCenterFaqTableGridProps) => {
  const { t } = useTranslation("trustCenter", { keyPrefix: "faq" });

  const navigate = useNavigate();

  const headers: ITableHeaderPropItem[] = [
    { name: t("headers.questions"), align: "left" },
    { name: t("headers.status"), align: "left" },
    { name: t("headers.actions"), align: "center" },
  ];

  const workspaceRole = useCurrentWorkspaceRole(workspaceId);

  const getFilteredActionList = (data: ITrustCenterFaqItem) => {
    if (
      workspaceRole !== WorkspaceMemberRoleValue.OWNER &&
      workspaceRole !== WorkspaceMemberRoleValue.ADMIN
    ) {
      return [];
    }
    if (data.status === TrustCenterFaqStatusValue.DRAFT) {
      return trustCenterFaqActionList.filter(
        (action) => action.key !== TrustCenterFaqActionEnum.DRAFT
      );
    }
    if (data.status === TrustCenterFaqStatusValue.PUBLISHED) {
      return trustCenterFaqActionList.filter(
        (action) => action.key !== TrustCenterFaqActionEnum.PUBLISH
      );
    }
    return [];
  };

  const onChangeStatus = (
    item: ITrustCenterFaqItem,
    newStatus: TrustCenterFaqStatusValue
  ) => {
    trustCenterServices
      .update_trust_center_faq(workspaceId, item.id, {
        details: item.details,
        status: newStatus,
      })
      .then(() => {
        onRefresh();
      })
      .catch((error) => {
        apiErrorHandler(error, {
          toastMessage: t("notification.updateStatus.fail"),
        });
      });
  };

  const onDeleteQuestion = (item: ITrustCenterFaqItem) => {
    trustCenterServices
      .delete_trust_center_faq(workspaceId, item.id)
      .then(() => {
        onRefresh();
      })
      .catch((error) => {
        apiErrorHandler(error, {
          toastMessage: t("notification.deleteQuestion.fail"),
        });
      });
  };

  const onClickActionItems = (key: string, item: ITrustCenterFaqItem) => {
    switch (key) {
      case TrustCenterFaqActionEnum.EDIT:
        navigate(
          generatePath(pathname.EDIT_TRUST_CENTER_FAQ, {
            workspace: workspaceId,
            faq: item.id,
          })
        );
        return;
      case TrustCenterFaqActionEnum.DRAFT:
        onChangeStatus(item, TrustCenterFaqStatusValue.DRAFT);
        return;
      case TrustCenterFaqActionEnum.PUBLISH:
        onChangeStatus(item, TrustCenterFaqStatusValue.PUBLISHED);
        return;
      case TrustCenterFaqActionEnum.DELETE:
        global.confirm(() => {
          onDeleteQuestion(item);
        });
        return;
    }
  };

  return (
    <>
      {isLoading || faqList === null ? (
        <LoadingState />
      ) : faqList.length === 0 ? (
        <NoResultsState />
      ) : (
        <div className="grid grid-cols-[6fr_2fr_1fr]">
          <TableHeader title="trustCenter-faq" headerProps={headers} />
          {faqList.map((item) => (
            <TrustCenterFaqTableRow
              key={`ttrustCenter/faq-tr${item.id}`}
              workspaceId={workspaceId}
              data={item}
              actionList={getFilteredActionList(item)}
              onClickActionItems={onClickActionItems}
            />
          ))}
        </div>
      )}
    </>
  );
};

export default TrustCenterFaqTableGrid;
