import type { ReactNode } from "react";
import { ReactComponent as AccountPinBoxLine } from "#src/assets/images/icons/account-pin-box-line.svg";
import { ReactComponent as CalendarLine } from "#src/assets/images/icons/calendar-line.svg";
import { ReactComponent as TimerLine } from "#src/assets/images/icons/timer-line.svg";
import { ReactComponent as TimerFlashLine } from "#src/assets/images/icons/timer-flash-line.svg";
import { ReactComponent as UserLine } from "#src/assets/images/icons/user-line.svg";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import { useTranslation } from "react-i18next";
import type { IScanDetailItem } from "#src/services/scans";
import { getScheduleSummary } from "#src/components/scans/utils";

dayjs.extend(relativeTime);

const OverviewCardRow = ({
  title,
  content,
}: {
  title: ReactNode;
  content: string | null;
}) => {
  return (
    <>
      <div className="flex gap-3 items-center h-8">{title}</div>
      <div className="flex gap-1 items-center text-dark-blue">
        <p className="overflow-hidden whitespace-nowrap text-ellipsis">
          {content}
        </p>
      </div>
    </>
  );
};

const ScanDetailsOverviewCard = ({
  scanDetails,
}: {
  scanDetails: IScanDetailItem;
}) => {
  const { t } = useTranslation("scans", {
    keyPrefix: "page.scanDetails.tab.overview.card.overview",
  });

  return (
    <>
      <h2 className="flex items-center h-9 mb-5">{t("title")}</h2>
      <div className="grid grid-cols-2 gap-4 text-hard-grey whitespace-nowrap">
        <OverviewCardRow
          title={
            <>
              <div className="h-5 w-5">
                <AccountPinBoxLine />
              </div>
              {t("row.scannerIP")}
            </>
          }
          content={scanDetails.scanner_ip}
        />
        <OverviewCardRow
          title={
            <>
              <div className="h-5 w-5">
                <UserLine />
              </div>
              {t("row.createdBy")}
            </>
          }
          content={scanDetails.created_by.full_name}
        />
        <OverviewCardRow
          title={
            <>
              <div className="h-5 w-5">
                <CalendarLine />
              </div>
              {t("row.createdAt")}
            </>
          }
          content={dayjs
            .unix(scanDetails.created_time)
            .format("HH:mm DD.MM.YYYY")}
        />
        <OverviewCardRow
          title={
            <>
              <div className="h-5 w-5">
                <TimerLine />
              </div>
              {t("row.elapsedTime")}
            </>
          }
          content={dayjs.unix(scanDetails.created_time).fromNow()}
        />
        <OverviewCardRow
          title={
            <>
              <div className="h-5 w-5">
                <TimerFlashLine />
              </div>
              {t("row.scheduleType")}
            </>
          }
          content={getScheduleSummary(
            scanDetails.scan_profile.schedule,
            scanDetails.scan_profile.duration_type,
            scanDetails.scan_profile.repeat_number,
            scanDetails.scan_profile.activated_time,
            scanDetails.scan_profile.days.map((item) => +item)
          )}
        />
      </div>
    </>
  );
};

export default ScanDetailsOverviewCard;
