// Libraries
import { useState } from "react";
import { useTranslation } from "react-i18next";

// Resources
import { ReactComponent as EditLine } from "#src/assets/images/icons/edit-line.svg";
import { ReactComponent as DeleteBin6Line } from "#src/assets/images/icons/delete-bin-6-line.svg";

// General
import global from "#src/config/global";

// Components
import type { ITableHeaderPropItem } from "#src/components/common/Table/TableHeader";
import { TableActionLabel, TableHeader } from "#src/components/common/Table";
import LoadingState from "#src/components/common/system/LoadingState";
import NoResultsState from "#src/components/common/states/NoResultsState";
import { createToast } from "#src/components/common/system/toasts";
import { apiErrorHandler } from "#src/utils/apiErrorHandler";

// API-related
import type { IAssetSubdomainItem } from "#src/services/assets/subdomains";
import assetsServices from "#src/services/assets";

// Children
import EditSettingDrawer from "#src/components/assets/drawers/EditSettingDrawer";
import SubdomainTableRow from "./TableRow";

interface ISubdomainTableProps {
  workspaceId: string;
  subdomains: IAssetSubdomainItem[] | null;
  isLoading: boolean;
  selectedSubdomainIds: number[];
  onChangeSelectedSubdomains: (selected: IAssetSubdomainItem) => void;
  refreshSubdomainList: () => void;
  refreshTabState: () => void;
}

const SubdomainTableGrid = ({
  workspaceId,
  subdomains,
  isLoading,
  selectedSubdomainIds,
  onChangeSelectedSubdomains,
  refreshSubdomainList,
  refreshTabState,
}: ISubdomainTableProps) => {
  const { t } = useTranslation("assets", {
    keyPrefix: "domains.page.details.tab.subdomains",
  });

  const [openEditDrawer, setOpenEditDrawer] = useState<boolean>(false);

  // Item that is chosen to edit
  const [activeItem, setActiveItem] = useState<IAssetSubdomainItem | null>(
    null
  );

  /** Asset Detail Subdomain Table's header list */
  const headers: ITableHeaderPropItem[] = [
    { name: "", align: "left" },
    { name: t("headers.name"), align: "left" },
    { name: t("headers.scans"), align: "left" },
    { name: t("headers.vulnerabilities"), align: "left" },
    { name: t("headers.createdDate"), align: "left" },
    { name: t("headers.tags"), align: "left" },
    { name: t("headers.actions"), align: "center" },
  ];

  const subdomainActionList = [
    {
      key: "edit-settings",
      label: (
        <TableActionLabel
          icon={<EditLine />}
          text={t("actions.editSettings")}
        />
      ),
    },
    {
      key: "delete-domain",
      label: (
        <TableActionLabel
          icon={<DeleteBin6Line />}
          text={t("actions.deleteDomain")}
          warning
        />
      ),
    },
  ];

  const onDeleteSubdomain = (id: number) => {
    assetsServices
      .delete_asset(id, workspaceId)
      .then(() => {
        refreshSubdomainList();
        createToast({
          type: "success",
          message: t("notification.deleteDomain.success"),
        });
      })
      .catch((error) => {
        apiErrorHandler(error, {
          toastMessage: t("notification.deleteDomain.fail"),
        });
      });
  };

  const onClickActionItems = (key: string, item: IAssetSubdomainItem) => {
    switch (key) {
      case "edit-settings":
        setOpenEditDrawer(true);
        setActiveItem(item);
        return;
      case "delete-domain":
        global.confirm(() => onDeleteSubdomain(item.id));
        return;
    }
  };

  return isLoading || subdomains === null ? (
    <LoadingState />
  ) : subdomains.length === 0 ? (
    <NoResultsState />
  ) : (
    <div className="grid grid-cols-[2.75rem_8fr_3fr_4fr_5fr_4fr_3fr]">
      <TableHeader title="assets/details-subdomains" headerProps={headers} />
      {subdomains.map((item) => (
        <SubdomainTableRow
          key={`tassets/details-subdomains-tr${item.id}`}
          data={item}
          actionList={subdomainActionList}
          onClickActionItems={onClickActionItems}
          selectedSubdomainIds={selectedSubdomainIds}
          onChangeSelectedSubdomains={onChangeSelectedSubdomains}
          refreshTabState={refreshTabState}
        />
      ))}
      {activeItem && openEditDrawer ? (
        <EditSettingDrawer
          selectedItemId={activeItem.id}
          open={openEditDrawer}
          onClose={() => {
            setOpenEditDrawer(false);
            setActiveItem(null);
          }}
          workspaceId={workspaceId}
          onRefresh={refreshSubdomainList}
        />
      ) : null}
    </div>
  );
};

export default SubdomainTableGrid;
