// Libraries
import { useTranslation } from "react-i18next";
import DOMPurify from "dompurify";
import { marked } from "marked";

// Common components
import type { IMsspBugBountyDetails } from "#src/services/mssp/bugBounty";

interface IMsspBugBountyDetailsPolicyProps {
  bugBountyDetails: IMsspBugBountyDetails;
}

const MsspBugBountyDetailsPolicy = ({
  bugBountyDetails,
}: IMsspBugBountyDetailsPolicyProps) => {
  const { t } = useTranslation("bugBounty", {
    keyPrefix: "page.bugBountyDetails.tab.policy",
  });

  return (
    <section className="flex flex-col gap-6 pt-6">
      <div className="flex flex-col gap-4">
        <h2>{t("title")}</h2>
        <span className="text-hard-grey">{t("info")}</span>
      </div>
      <div
        className="markdown-body"
        dangerouslySetInnerHTML={{
          __html: DOMPurify.sanitize(
            marked.parse(bugBountyDetails.metadata.en) as string
            // https://github.com/markedjs/marked/issues/3101 Marked cannot tell Typescript the async false option. Last update 17/01/2024
          ),
        }}
      ></div>
    </section>
  );
};

export default MsspBugBountyDetailsPolicy;
