// Libraries
import { Dropdown } from "@lockerpm/design";
import dayjs from "dayjs";
import type { ItemType } from "@lockerpm/design/es/menu/hooks/useItems";
import { useState, type ReactNode } from "react";
import { useTranslation } from "react-i18next";

// Resources
import { ReactComponent as MoreLine } from "#src/assets/images/icons/more-line.svg";
import { ReactComponent as LogoWindows10 } from "#src/assets/images/logos/logo-windows-10.svg";
import { ReactComponent as LogoApple } from "#src/assets/images/logos/logo-apple.svg";
import { ReactComponent as LogoUbuntu } from "#src/assets/images/logos/logo-ubuntu.svg";
import { ReactComponent as ShutDownLine } from "#src/assets/images/icons/shut-down-line.svg";
import { ReactComponent as RestartLine } from "#src/assets/images/icons/restart-line.svg";
import { ReactComponent as EditLine } from "#src/assets/images/icons/edit-line.svg";
import { ReactComponent as UninstallLine } from "#src/assets/images/icons/uninstall-line.svg";
import { ReactComponent as ImageCircleFill } from "#src/assets/images/icons/image-circle-fill.svg";
import { ReactComponent as CheckboxCircleLine } from "#src/assets/images/icons/checkbox-circle-line.svg";
import { ReactComponent as CloseCircleLine } from "#src/assets/images/icons/close-circle-line.svg";

// Components
import {
  TableActionLabel,
  TableCheckbox,
  TableDataCell,
  TableHeader,
  TableRowWrapper,
} from "#src/components/common/Table";
import { RoundedSeeMoreTags, RoundedTag } from "#src/components/common/Tags";
import { IconButton } from "#src/components/common/system/Button";
import LoadingState from "#src/components/common/system/LoadingState";
import NoResultsState from "#src/components/common/states/NoResultsState";
import type { ITableHeaderPropItem } from "#src/components/common/Table/TableHeader";
import { StatusLabel } from "#src/components/common/labels";
import { createToast } from "#src/components/common/system/toasts";
import { apiErrorHandler } from "#src/utils/apiErrorHandler";

// API-related
import devicesServices, { type IHostItem } from "#src/services/devices";

// Children
import EditTagsDrawer from "../drawers/EditTags";
import { PolicyComplianceValue } from "#src/config/filter/device/value";

const iconByOs: { [os: string]: ReactNode } = {
  windows: <LogoWindows10 className="h-6 w-6" />,
  darwin: <LogoApple className="h-6 w-6" />,
  ubuntu: <LogoUbuntu className="h-6 w-6" />,
};

/** Windows is Windows, Darwin is the core of macOS, and we only detect Ubuntu, not the other Linux distros */
const matchOsName = (values: string[]) => {
  const osList = ["windows", "darwin", "ubuntu"];
  return osList.reduce((prev, os) => {
    if (values.some((value) => value.toLowerCase().includes(os))) {
      return os;
    } else return prev;
  }, undefined);
};

const getOsIcon = (values: string[]) => {
  const osName = matchOsName(values);
  if (osName) {
    return iconByOs[osName];
  }
  return <ImageCircleFill className="fill-hard-grey h-6 w-6" />;
};

interface IHostTableRowProps {
  data: IHostItem;
  actionList: ItemType[];
  onClickActionItems: (key: string, item: IHostItem) => void;
  selectedHostIds: number[];
  onChangeSelectedHosts: (selected: IHostItem) => void;
}

const HostTableRow = ({
  data,
  actionList,
  onClickActionItems,
  selectedHostIds,
  onChangeSelectedHosts,
}: IHostTableRowProps) => {
  return (
    <TableRowWrapper to={`${data.id}`}>
      <TableDataCell className="p-0 justify-center">
        <TableCheckbox
          checked={selectedHostIds.includes(data.id)}
          onClick={() => {
            onChangeSelectedHosts(data);
          }}
        />
      </TableDataCell>
      <TableDataCell>
        <div className="h-11 w-11 flex justify-center items-center">
          {getOsIcon([data.os.name, data.os.family])}
        </div>
      </TableDataCell>
      <TableDataCell>
        {/* 300s is time tolerance for online status */}
        {data.last_seen &&
        dayjs().diff(dayjs.unix(data.last_seen), "second") < 300 ? (
          <>
            <span className="h-2.5 w-2.5 rounded-full bg-warning-safe" />
            {/* TOOD: this should have i18n? */}
            Online
          </>
        ) : (
          <>
            <span className="h-2.5 w-2.5 rounded-full bg-warning" />
            {/* TOOD: this should have i18n? */}
            Offline
          </>
        )}
      </TableDataCell>
      <TableDataCell>{data.name}</TableDataCell>
      <TableDataCell>{data.owner ? data.owner : "--"}</TableDataCell>
      <TableDataCell>{data.internal_ip}</TableDataCell>
      <TableDataCell>
        {dayjs.unix(data.created_time).format("HH:mm DD.MM.YYYY")}
      </TableDataCell>
      <TableDataCell>
        {data.policy_compliance === PolicyComplianceValue.PASSED ? (
          <StatusLabel
            // TODO: might want to use i18n
            name={"Passed"}
            color="bg-support-seafoam-pastel text-support-seafoam"
            icon={<CheckboxCircleLine className="h-4 w-4 min-w-[1rem]" />}
          />
        ) : data.policy_compliance === PolicyComplianceValue.FAILED ? (
          <StatusLabel
            // TODO: might want to use i18n
            name={"Failed"}
            color="text-warning-red-danger bg-warning-red-10%"
            icon={<CloseCircleLine className="h-4 w-4 min-w-[1rem]" />}
          />
        ) : null}
      </TableDataCell>
      <TableDataCell>
        {data.tags.length > 0 ? <RoundedTag {...data.tags[0]} /> : null}
        {data.tags.length > 1 ? (
          <RoundedSeeMoreTags tagList={data.tags.slice(1)} />
        ) : null}
      </TableDataCell>
      <TableDataCell className="p-0 justify-center">
        <Dropdown
          menu={{
            items: actionList,
            onClick: ({ key, domEvent }) => {
              domEvent.preventDefault();
              domEvent.stopPropagation();
              onClickActionItems(key, data);
            },
          }}
          trigger={["click"]}
          disabled={selectedHostIds.length > 0}
        >
          <IconButton
            variant="ghost"
            size={9}
            onClick={(e) => {
              e.preventDefault();
            }}
            disabled={selectedHostIds.length > 0}
          >
            <MoreLine />
          </IconButton>
        </Dropdown>
      </TableDataCell>
    </TableRowWrapper>
  );
};

interface IHostTableGridProps {
  workspaceId: string;
  isLoading: boolean;
  hostList: IHostItem[] | null;
  selectedHostIds: number[];
  onChangeSelectedHosts: (selected: IHostItem) => void;
  refreshHostList: () => void;
}

const HostTableGrid = ({
  workspaceId,
  isLoading,
  hostList,
  selectedHostIds,
  onChangeSelectedHosts,
  refreshHostList,
}: IHostTableGridProps) => {
  const { t } = useTranslation("devices", { keyPrefix: "hosts.page.hosts" });

  const [openEditDrawer, setOpenEditDrawer] = useState<boolean>(false);
  const [editingItemId, setEditingItemId] = useState<number | null>(null);

  const headers: ITableHeaderPropItem[] = [
    { name: "", align: "left" },
    { name: t("headers.os"), align: "left" },
    { name: t("headers.status"), align: "left" },
    { name: t("headers.name"), align: "left" },
    { name: t("headers.owner"), align: "left" },
    { name: t("headers.ip"), align: "left" },
    { name: t("headers.createdDate"), align: "left" },
    { name: t("headers.policyCompliance"), align: "left" },
    { name: t("headers.tags"), align: "left" },
    { name: t("headers.actions"), align: "center" },
  ];

  const actionList = [
    {
      key: "shutdown",
      label: (
        <TableActionLabel
          icon={<ShutDownLine />}
          text={t("actions.shutdown")}
        />
      ),
    },
    {
      key: "restart",
      label: (
        <TableActionLabel icon={<RestartLine />} text={t("actions.restart")} />
      ),
    },
    {
      key: "editTags",
      label: (
        <TableActionLabel icon={<EditLine />} text={t("actions.editTags")} />
      ),
    },
    {
      key: "uninstall",
      label: (
        <TableActionLabel
          icon={<UninstallLine />}
          text={t("actions.uninstall")}
          warning
        />
      ),
    },
  ];

  const onShutdownHost = (hostId: number) => {
    devicesServices
      .shutdown_host(workspaceId, hostId)
      .then((response) => {
        if (response.success) {
          refreshHostList();
        } else {
          createToast({
            type: "error",
            message: t("notification.shutdownHost.fail"),
          });
        }
      })
      .catch((error) => {
        apiErrorHandler(error, {
          toastMessage: t("notification.shutdownHost.fail"),
        });
      });
  };

  const onRestartHost = (hostId: number) => {
    devicesServices
      .restart_host(workspaceId, hostId)
      .then((response) => {
        if (response.success) {
          refreshHostList();
        } else {
          createToast({
            type: "error",
            message: t("notification.shutdownHost.fail"),
          });
        }
      })
      .catch((error) => {
        apiErrorHandler(error, {
          toastMessage: t("notification.shutdownHost.fail"),
        });
      });
  };

  const onUninstallHost = (hostId: number) => {
    devicesServices
      .uninstall_host(workspaceId, hostId)
      .then((response) => {
        if (response.success) {
          refreshHostList();
        } else {
          createToast({
            type: "error",
            message: t("notification.uninstallHost.fail"),
          });
        }
      })
      .catch((error) => {
        apiErrorHandler(error, {
          toastMessage: t("notification.uninstallHost.fail"),
        });
      });
  };

  const onOpenEditTagDrawer = (itemId: number) => {
    setEditingItemId(itemId);
    setOpenEditDrawer(true);
  };

  const onClickActionItems = (key: string, item: IHostItem) => {
    switch (key) {
      case "shutdown":
        onShutdownHost(item.id);
        return;
      case "restart":
        onRestartHost(item.id);
        return;
      case "editTags":
        onOpenEditTagDrawer(item.id);
        return;
      case "uninstall":
        onUninstallHost(item.id);
        return;
    }
  };

  return (
    <>
      {isLoading || hostList === null ? (
        <LoadingState />
      ) : hostList.length === 0 ? (
        <NoResultsState />
      ) : (
        <div className="grid grid-cols-[2.75rem_4rem_2fr_5fr_3fr_3fr_4fr_2fr_3fr_6rem]">
          <TableHeader title="devices/hosts" headerProps={headers} />
          {hostList.map((host) => (
            <HostTableRow
              key={`tdevices/hosts-tr-${host.id}`}
              data={host}
              actionList={actionList}
              onClickActionItems={onClickActionItems}
              selectedHostIds={selectedHostIds}
              onChangeSelectedHosts={onChangeSelectedHosts}
            />
          ))}
        </div>
      )}

      {/* TODO: move these variables to smooth out Drawer's animation */}
      {openEditDrawer && editingItemId ? (
        <EditTagsDrawer
          selectedItemId={editingItemId}
          open={openEditDrawer}
          onClose={() => {
            setOpenEditDrawer(false);
            setEditingItemId(null);
          }}
          workspaceId={workspaceId}
          onRefresh={refreshHostList}
        />
      ) : null}
    </>
  );
};

export default HostTableGrid;
