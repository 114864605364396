// Libraries
import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

// General
import constants from "#src/config/constants";
import type { IFilterItem } from "#src/@types/common";

// components
import PaginationBar from "#src/components/common/helper/PaginationBar";
import useFetchPaginated from "#src/hooks/useFetchPaginated";
import { createToast } from "#src/components/common/system/toasts";
import { apiErrorHandler } from "#src/utils/apiErrorHandler";

// API-related
import assetsServices from "#src/services/assets";
import type { IAssetSubdomainItem } from "#src/services/assets/subdomains";

// Children
import SubdomainFilter from "./Filter";
import SubdomainTableGrid from "./Table";
import AddSubdomainDrawer from "#src/components/assets/drawers/AddSubdomainDrawer";

interface IDetailsSubdomainsTabProps {
  workspaceId: string;
  domainId: number;
  domainAddress: string;
  refreshAssetDetails: () => void;
  refreshTabState: () => void;
}

const AssetsDetailsSubdomains = ({
  workspaceId,
  domainId,
  domainAddress,
  refreshAssetDetails,
  refreshTabState,
}: IDetailsSubdomainsTabProps) => {
  const { t } = useTranslation("assets", {
    keyPrefix: "domains.page.details.tab.subdomains",
  });

  const [selectedPage, setSelectedPage] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(constants.DEFAULT_PAGE_SIZE);

  const [vulnerable, setVulnerable] = useState<IFilterItem | null>(null);
  const [selectedSubdomains, setSelectedSubdomains] = useState<
    IAssetSubdomainItem[]
  >([]);
  const [searchKeyword, setSearchKeyword] = useState<string>("");
  const [from, setFrom] = useState<number | null>(null);
  const [to, setTo] = useState<number | null>(null);

  const [openAddSubdomainDrawer, setOpenAddSubdomainDrawer] =
    useState<boolean>(false);

  const fetchParams = useMemo<
    Parameters<typeof assetsServices.list_asset_subdomains>
  >(
    () => [
      workspaceId,
      domainId,
      {
        from: from ? from : undefined,
        to: to ? to : undefined,
        vulnerable: vulnerable?.value,
        q: searchKeyword,
        page: selectedPage,
        size: pageSize,
      },
    ],
    [
      workspaceId,
      domainId,
      from,
      to,
      vulnerable,
      searchKeyword,
      selectedPage,
      pageSize,
    ]
  );

  const onChangeTimeConditionValue = (
    condition: string,
    from: number,
    to: number
  ) => {
    if (condition) {
      setFrom(from);
      setTo(to);
    } else {
      setFrom(null);
      setTo(null);
    }
    setSelectedPage(1);
  };

  const onChangeVulnerableSelection = (selected: typeof vulnerable) => {
    setVulnerable(selected);
    setSelectedPage(1);
  };

  const onChangeSearchKeyword = (keyword: string) => {
    setSearchKeyword(keyword);
    setSelectedPage(1);
  };

  const onChangePage = (page: number) => {
    setSelectedPage(page);
    setSelectedSubdomains([]);
  };

  const onChangePageSize = (size: number) => {
    setPageSize(size);
    setSelectedPage(1);
    setSelectedSubdomains([]);
  };

  const onChangeSelectedDomains = (selected: IAssetSubdomainItem) => {
    if (selectedSubdomains.map((item) => item.id).includes(selected.id)) {
      setSelectedSubdomains((prev) => [
        ...prev.filter((item) => item.id !== selected.id),
      ]);
    } else {
      setSelectedSubdomains((prev) => [...prev, selected]);
    }
  };

  const onSelectAllSubdomains = () => {
    if (subdomainList !== null) {
      setSelectedSubdomains(subdomainList);
    }
  };

  const onDeselectAllSubdomains = () => {
    setSelectedSubdomains([]);
  };

  /** Used after subdomain changes, such as adding and removing subdomains */
  const refreshSubdomainList = () => {
    updateSubdomainList();
    refreshAssetDetails();
  };

  const onDeleteSelectedSubdomains = () => {
    assetsServices
      .delete_multiple_subdomains(
        domainId,
        workspaceId,
        selectedSubdomains.map((item) => item.id)
      )
      .then(() => {
        refreshSubdomainList();
        createToast({
          type: "success",
          message: t("notification.deleteDomain.success"),
        });
      })
      .catch((error) => {
        apiErrorHandler(error, {
          toastMessage: t("notification.deleteDomain.fail"),
        });
      });
    setSelectedSubdomains([]);
  };

  const {
    list: subdomainList,
    count: subdomainCount,
    isLoading,
    updateData: updateSubdomainList,
  } = useFetchPaginated(assetsServices.list_asset_subdomains, fetchParams);

  return (
    <section className="flex flex-col gap-6 pt-6">
      <SubdomainFilter
        searchKeyword={searchKeyword}
        vulnerable={vulnerable}
        totalCount={subdomainCount}
        selectedCount={selectedSubdomains.length}
        onChangeTimeConditionValue={onChangeTimeConditionValue}
        onChangeVulnerableSelection={onChangeVulnerableSelection}
        onChangeSearchKeyword={onChangeSearchKeyword}
        onSelectAllSubdomains={onSelectAllSubdomains}
        onDeselectAllSubdomains={onDeselectAllSubdomains}
        onDeleteSelectedSubdomains={onDeleteSelectedSubdomains}
        onOpenAddSubdomainDrawer={() => setOpenAddSubdomainDrawer(true)}
      />
      <SubdomainTableGrid
        workspaceId={workspaceId}
        subdomains={subdomainList}
        isLoading={isLoading}
        selectedSubdomainIds={selectedSubdomains.map((item) => item.id)}
        onChangeSelectedSubdomains={onChangeSelectedDomains}
        refreshSubdomainList={refreshSubdomainList}
        refreshTabState={refreshTabState}
      />
      <PaginationBar
        numOfResult={subdomainCount}
        selectedPage={selectedPage}
        pageSize={pageSize}
        onChangePage={onChangePage}
        onChangePageSize={onChangePageSize}
      />
      <AddSubdomainDrawer
        open={openAddSubdomainDrawer}
        onClose={() => setOpenAddSubdomainDrawer(false)}
        workspaceId={workspaceId}
        onRefresh={() => {
          refreshSubdomainList();
        }}
        domainId={domainId}
        domainAddress={domainAddress}
      />
    </section>
  );
};

export default AssetsDetailsSubdomains;
