// Libraries
import { useMemo, useState } from "react";

// General
import constants from "#src/config/constants";
import type { IFilterItem } from "#src/@types/common";

// Components
import PaginationBar from "#src/components/common/helper/PaginationBar";
import useFetchPaginated from "#src/hooks/useFetchPaginated";

// API-related
import scansServices from "#src/services/scans";

// Children
import PortFilter from "./Filter";
import PortTableGrid from "./Table";

interface IScanDetailsPortsProps {
  workspaceId: string;
  scanId: number;
  domainId: number;
}

const ScanDetailsPorts = ({
  workspaceId,
  scanId,
  domainId,
}: IScanDetailsPortsProps) => {
  const [protocol, setProtocol] = useState<IFilterItem | null>(null);

  const [selectedPage, setSelectedPage] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(constants.DEFAULT_PAGE_SIZE);
  const [searchKeyword, setSearchKeyword] = useState<string>("");

  const fetchParams = useMemo<Parameters<typeof scansServices.list_scan_ports>>(
    () => [
      workspaceId,
      scanId,
      {
        domain_id: domainId,
        protocol: protocol?.value,
        q: searchKeyword,
        page: selectedPage,
        size: pageSize,
      },
    ],
    [
      workspaceId,
      scanId,
      domainId,
      protocol,
      searchKeyword,
      selectedPage,
      pageSize,
    ]
  );

  const onChangeProtocolSelection = (selected: typeof protocol) => {
    setProtocol(selected);
    setSelectedPage(1);
  };

  const onChangeSearchKeyword = (keyword: string) => {
    setSearchKeyword(keyword);
    setSelectedPage(1);
  };

  const onChangePage = (page: number) => {
    setSelectedPage(page);
  };

  const onChangePageSize = (size: number) => {
    setSelectedPage(1);
    setPageSize(size);
  };

  const {
    list: portList,
    count: portCount,
    isLoading,
  } = useFetchPaginated(scansServices.list_scan_ports, fetchParams);

  return (
    <section className="flex flex-col gap-6 pt-6">
      <PortFilter
        searchKeyword={searchKeyword}
        protocol={protocol}
        onChangeProtocolSelection={onChangeProtocolSelection}
        onChangeSearchKeyword={onChangeSearchKeyword}
      />
      <PortTableGrid portList={portList} isLoading={isLoading} />
      <PaginationBar
        numOfResult={portCount}
        selectedPage={selectedPage}
        pageSize={pageSize}
        onChangePage={onChangePage}
        onChangePageSize={onChangePageSize}
      />
    </section>
  );
};

export default ScanDetailsPorts;
