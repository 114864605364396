import { Switch } from "@lockerpm/design";
import { ReactComponent as ArrowDownSLine } from "#src/assets/images/icons/arrow-down-s-line.svg";

type IAccordionIconVariant = "arrow" | "switch" | undefined;

interface IAccordionHeadingProps {
  active?: boolean;
  title: string;
  onToggle?: () => void;
  variant?: IAccordionIconVariant;
}

const AccordionIcon = ({
  variant,
  active,
}: {
  variant: IAccordionIconVariant;
  active: boolean;
}) => {
  if (variant === "arrow") {
    return active ? (
      <ArrowDownSLine className="rotate-180 duration-300 ease-out" />
    ) : (
      <ArrowDownSLine className="duration-300 ease-out" />
    );
  }

  if (variant === "switch") {
    return <Switch checked={active} />;
  }

  return null;
};

interface IAccordionHeading3Props extends IAccordionHeadingProps {
  instruction?: string;
}

export const AccordionHeading3 = ({
  active = true,
  title,
  onToggle,
  variant,
  instruction,
}: IAccordionHeading3Props) => {
  return (
    <div className="flex flex-col gap-4 p-6 cursor-pointer" onClick={onToggle}>
      <div className="flex justify-between items-center">
        <h3
          className={`${
            variant === "switch" && active === false
              ? "text-medium-grey"
              : "text-dark-blue"
          } overflow-hidden whitespace-nowrap text-ellipsis transition duration-300`}
        >
          {title}
        </h3>
        <AccordionIcon variant={variant} active={active} />
      </div>
      {instruction ? (
        <p className="font-regular-14 text-hard-grey">{instruction}</p>
      ) : null}
    </div>
  );
};

export const AccordionHeading4 = ({
  active = true,
  title,
  onToggle,
  variant,
}: IAccordionHeadingProps) => {
  return (
    <div
      className="flex justify-between items-center px-6 py-4"
      onClick={onToggle}
    >
      <h4
        className={`${
          variant === "switch" && active === false
            ? "text-medium-grey"
            : "text-dark-blue"
        } overflow-hidden whitespace-nowrap text-ellipsis transition duration-300`}
      >
        {title}
      </h4>
      <AccordionIcon variant={variant} active={active} />
    </div>
  );
};
