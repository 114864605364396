import type { IPaginateParams, IPaginatedResponse } from "#src/@types/api";
import endpoint from "#src/config/endpoint";
import request from "#src/utils/request";

export interface IAssetVulnerabilityItem {
  id: number;
  created_time: number;
  updated_time: number;
  title: string;
  category: string | null;
  severity: string;
  cvss_score: number;
  status: string;
  sub_status: string;
}

export function list_asset_vulnerabilities(
  workspace_id: string,
  domain_id: number,
  params: {
    severity: string | undefined;
    cvss_score_from: string | undefined;
    cvss_score_to: string | undefined;
    sub_status: string | undefined;
  } & IPaginateParams
) {
  return request.get<never, IPaginatedResponse<IAssetVulnerabilityItem>>(
    `${endpoint.WORKSPACES}/${workspace_id}/assets/${domain_id}/vulnerabilities`,
    { params }
  );
}

export function update_vulnerability(
  vulnerability_id: number,
  workspace_id: string,
  sub_status: string
) {
  return request.put<never, { success: boolean }>(
    `${endpoint.WORKSPACES}/${workspace_id}/vulns/${vulnerability_id}`,
    { sub_status }
  );
}

export function update_status_multiple_vulnerabilities(
  workspace_id: string,
  vulnerability_ids: number[],
  sub_status: string
) {
  return request.put<never, { success: boolean }>(
    `${endpoint.WORKSPACES}/${workspace_id}/vulns/status`,
    { sub_status, ids: vulnerability_ids }
  );
}
