// Libraries
import { useTranslation } from "react-i18next";
import { useEffect, useState, useCallback } from "react";
import dayjs from "dayjs";

// Resources
import { ReactComponent as RefreshLine } from "#src/assets/images/icons/refresh-line.svg";
import { ReactComponent as LinkIcon } from "#src/assets/images/icons/link.svg";
import { ReactComponent as TimeLine } from "#src/assets/images/icons/time-line.svg";
import { ReactComponent as ImageCircleFill } from "#src/assets/images/icons/image-circle-fill.svg";

// Components
import { Button } from "#src/components/common/system/Button";
import LoadingState from "#src/components/common/system/LoadingState";
import { RoundedTag } from "#src/components/common/Tags";

// API-related
import bugBountyServices from "#src/services/bugBounty";
import workspaceServices from "#src/services/workspace";

// Children
import BugBountyMsspManagementDrawer, {
  bugBountyManagementPanelKeys,
} from "./ManagementDrawer";
import type { IBugBountyProgramMssp } from "#src/services/bugBounty/mssp";
import { ObjectImage } from "#src/components/common/system/Object";

interface IBugBountyDetailsMsspProps {
  workspaceId: string;
  bugBountyAlias: string;
  bugBountyStatus: string;
}

const BugBountyDetailsMssp = ({
  workspaceId,
  bugBountyAlias,
  bugBountyStatus,
}: IBugBountyDetailsMsspProps) => {
  const { t } = useTranslation("bugBounty", {
    keyPrefix: "page.bugBountyDetails.tab.mssp",
  });

  const [msspWorkspaceInfo, setMsspWorkspaceInfo] =
    useState<IBugBountyProgramMssp | null>(null);
  const [isLoading, setLoading] = useState<boolean>(false);

  const [openManagementDrawer, setOpenManagementDrawer] =
    useState<boolean>(false);
  const [activePanel, setActivePanel] = useState<string[]>();

  const fetchMssp = useCallback(async () => {
    setLoading(true);
    const response = await bugBountyServices.retrieve_program_mssp(
      workspaceId,
      bugBountyAlias
    );

    if (response.mssp !== null) {
      setMsspWorkspaceInfo(response.mssp);
      setActivePanel([bugBountyManagementPanelKeys.MANAGED_PROVIDER]);
      setLoading(false);
      return;
    }

    const workspace = await workspaceServices.retrieve_workspace(workspaceId);
    setMsspWorkspaceInfo(workspace);
    setActivePanel([bugBountyManagementPanelKeys.SELF_MANAGED]);
    setLoading(false);
    return;
  }, [workspaceId, bugBountyAlias]);

  useEffect(() => {
    fetchMssp();
  }, [fetchMssp]);

  return (
    <section className="flex flex-col gap-6 pt-6">
      <h2>{t("basicInformation.title")}</h2>
      {msspWorkspaceInfo === null || isLoading ? (
        <LoadingState />
      ) : (
        <div className="bg-bright-grey rounded-md py-6 flex">
          <div className="flex-1 p-6 flex flex-col gap-6">
            <div className="flex flex-col gap-2">
              <div className="h-[10.875rem] relative">
                <div
                  className="h-28 rounded-md"
                  style={{ backgroundColor: msspWorkspaceInfo.color }}
                />
                <div className="p-4 absolute bottom-0 left-1/2 -translate-x-1/2">
                  <div className="border-[3px] border-white rounded-md bg-bright-grey h-28 w-28 flex items-center justify-center">
                    <ObjectImage
                      data={msspWorkspaceInfo.logo}
                      className="h-[5.5rem] w-[5.5rem]"
                    >
                      <ImageCircleFill className="h-[2.75rem] w-[2.75rem] fill-medium-grey" />
                    </ObjectImage>
                  </div>
                </div>
              </div>
              {msspWorkspaceInfo.id === workspaceId ? (
                <div className="flex items-center justify-center text-hard-grey gap-1">
                  <TimeLine height={"0.875rem"} width={"0.875rem"} />
                  {/* TODO: this is not in the workspace API? */}
                  <span className="font-regular-12">
                    {t("basicInformation.joined")}{" "}
                    {dayjs.unix(msspWorkspaceInfo.created_time).fromNow()}
                  </span>
                </div>
              ) : null}
            </div>
            <div className="flex justify-center">
              <Button
                variant="secondary"
                size="large"
                className="w-fit"
                onClick={() => {
                  setOpenManagementDrawer(true);
                }}
                disabled={bugBountyStatus !== "draft"}
              >
                <RefreshLine height={"1.25rem"} width={"1.25rem"} />
                {t("basicInformation.button.changeProvider")}
              </Button>
            </div>
          </div>
          <div className="flex-1 p-6 flex flex-col gap-6">
            <div className="flex flex-col gap-3">
              <span className="text-hard-grey">
                {t("basicInformation.companyName")}
              </span>
              <span className="font-medium-16">{msspWorkspaceInfo.name}</span>
            </div>
            {/* <div className="flex flex-col gap-3">
              <span className="text-hard-grey">
                {t("basicInformation.websiteUrl")}
              </span>
              <span className="flex items-center gap-2 text-primary">
                <LinkIcon height={"1.25rem"} width={"1.25rem"} />
                https://@cystack.net/
              </span>
            </div>
            <div className="flex flex-col gap-3">
              <span className="text-hard-grey">
                {t("basicInformation.contactUs")}
              </span>
              <span className="flex items-center gap-2 text-primary">
                <LinkIcon height={"1.25rem"} width={"1.25rem"} />
                https://@cystack.net/contact
              </span>
            </div> */}
            <div className="flex flex-col gap-3">
              <span className="text-hard-grey">
                {t("basicInformation.companyOwnerEmail")}
              </span>
              {msspWorkspaceInfo.email ? (
                <span className="flex items-center gap-2 text-primary">
                  <LinkIcon height={"1.25rem"} width={"1.25rem"} />
                  {msspWorkspaceInfo.email}
                </span>
              ) : (
                <span className="font-medium-16">N/A</span>
              )}
            </div>
          </div>
          <div className="flex-1 p-6 flex flex-col gap-6">
            <div className="flex flex-col gap-3">
              <span className="text-hard-grey">
                {t("basicInformation.description")}
              </span>
              <span className="font-medium-16">
                {msspWorkspaceInfo.description.en
                  ? msspWorkspaceInfo.description.en
                  : "N/A"}
              </span>
            </div>
            {msspWorkspaceInfo.technologies.length === 0 ? null : (
              <div className="flex flex-col gap-3">
                <span className="text-hard-grey">
                  {t("basicInformation.technologies")}
                </span>
                <div className="flex gap-1">
                  {msspWorkspaceInfo.technologies.map((technology) => (
                    <RoundedTag
                      key={`bugBounty-mssp-technology-${technology}`}
                      name={technology}
                    />
                  ))}
                </div>
              </div>
            )}
          </div>
        </div>
      )}
      <BugBountyMsspManagementDrawer
        open={openManagementDrawer}
        onClose={() => {
          setOpenManagementDrawer(false);
        }}
        workspaceId={workspaceId}
        bugBountyAlias={bugBountyAlias}
        onRefresh={fetchMssp}
        activePanel={activePanel ?? []}
        setActivePanel={setActivePanel}
      />
    </section>
  );
};

export default BugBountyDetailsMssp;
