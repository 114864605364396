// Libraries
import { Drawer } from "@lockerpm/design";
import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDebouncedCallback } from "use-debounce";

// Resources
import { ReactComponent as CloseLine } from "#src/assets/images/icons/close-line.svg";
import { ReactComponent as AddLine } from "#src/assets/images/icons/add-line.svg";
// import { ReactComponent as SaveLine } from "#src/assets/images/icons/save-line.svg";

// General
import constants from "#src/config/constants";
import type { IDrawerAdapterProps } from "#src/@types/common";

// Components
import { Button, IconButton } from "#src/components/common/system/Button";
import { SearchBox } from "#src/components/common/Table/Filter";
import { createToast } from "#src/components/common/system/toasts";
import { apiErrorHandler } from "#src/utils/apiErrorHandler";

// API-related
import dataLeakServices, { type IKeywordItem } from "#src/services/dataLeak";

// Children
import KeywordItem from "./KeywordItem";

const FooterButtons = ({
  onSave,
  onCancel,
}: {
  onSave: () => void;
  onCancel: () => void;
}) => {
  const { t } = useTranslation("dataLeakDetection", {
    keyPrefix: "drawer.editKeywords",
  });

  return (
    <div className="flex flex-col gap-3">
      <Button className="w-full" size="large" onClick={onSave}>
        {t("button.save")}
      </Button>
      <Button
        variant="secondary"
        className="w-full"
        size="large"
        onClick={onCancel}
      >
        {t("button.cancel")}
      </Button>
    </div>
  );
};

interface IEditKeywordsDrawerProps extends IDrawerAdapterProps {}

const EditKeywordsDrawer = ({
  open,
  onClose,
  workspaceId,
  onRefresh,
}: IEditKeywordsDrawerProps) => {
  const { t } = useTranslation("dataLeakDetection", {
    keyPrefix: "drawer.editKeywords",
  });

  const [keywordList, setKeywordList] = useState<IKeywordItem[]>([]);
  const [keywordCount, setKeywordCount] = useState<number | null>(null);
  const [searchKeyword, setSearchKeyword] = useState<string>("");

  const [removedKeywordList, setRemovedKeywordList] = useState<IKeywordItem[]>(
    []
  );
  const [addedKeywordList, setAddedKeywordList] = useState<string[]>([]);

  const [loadingMoreKeywords, setLoadingMoreKeywords] =
    useState<boolean>(false);
  const [loadedKeywordPages, setLoadedKeywordPages] = useState<number>(0);

  const fetchDataLeakKeywordList = useCallback(
    (keyword?: string) => {
      dataLeakServices
        .list_keywords(workspaceId, {
          q: keyword ? keyword : undefined,
          page: undefined,
          size: constants.DEFAULT_PAGE_SIZE,
        })
        .then((response) => {
          setKeywordList(response.results);
          setKeywordCount(response.count);
          setLoadedKeywordPages(1);
        })
        .catch((error) => {
          apiErrorHandler(error, {
            toastMessage: t("notification.loadKeyword.fail"),
          });
        });
    },
    [workspaceId, t]
  );

  const fetchMoreKeywords = () => {
    dataLeakServices
      .list_keywords(workspaceId, {
        q: searchKeyword ? searchKeyword : undefined,
        page: loadedKeywordPages + 1,
        size: constants.DEFAULT_PAGE_SIZE,
      })
      .then((response) => {
        setKeywordList((prev) => [...prev, ...response.results]);
        setLoadedKeywordPages((prev) => prev + 1);
        setLoadingMoreKeywords(false);
      })
      .catch((error) => {
        apiErrorHandler(error, {
          toastMessage: t("notification.loadKeyword.fail"),
        });
      });
  };

  const debouncedFetchDataLeakKeywordList = useDebouncedCallback(
    fetchDataLeakKeywordList,
    constants.DEBOUNCE_TIME
  );

  const onChangeSearchKeyword = (keyword: string) => {
    setSearchKeyword(keyword);
    debouncedFetchDataLeakKeywordList(keyword);
  };

  const onCloseDrawerAndCleanup = () => {
    onClose();
    setAddedKeywordList([]);
    setRemovedKeywordList([]);
  };

  const onSaveAllSettings = async () => {
    if (addedKeywordList.some((item) => item.length < 5)) {
      createToast({
        type: "warning",
        message: t("notification.onSave.invalidKeywordLength.message"),
        detail: t("notification.onSave.invalidKeywordLength.description"),
      });
      return;
    }

    if (addedKeywordList.length) {
      await dataLeakServices
        .add_multiple_keywords(workspaceId, { keywords: addedKeywordList })
        .then(() => {
          // console.log(response);
        })
        .catch((error) => {
          apiErrorHandler(error, {
            toastMessage: t("notification.addKeywords.fail"),
          });
        });
    }

    if (removedKeywordList.length) {
      await dataLeakServices
        .remove_multiple_keywords(workspaceId, {
          ids: removedKeywordList.map((item) => item.id),
        })
        .then(() => {
          // console.log(response);
        })
        .catch((error) => {
          apiErrorHandler(error, {
            toastMessage: t("notification.addKeywords.fail"),
          });
        });
    }

    onRefresh();
    fetchDataLeakKeywordList();
    onCloseDrawerAndCleanup();
  };

  const onCancelChanges = () => {
    onCloseDrawerAndCleanup();
    // createToast({
    //   type: "info",
    //   message: t("notification.cancel.message"),
    //   detail: t("notification.cancel.description"),
    // });
  };

  const onAddKeyword = () => {
    if (removedKeywordList.map((item) => item.keyword).includes("")) {
      setRemovedKeywordList((prev) =>
        prev.filter((item) => item.keyword !== "")
      );
    } else {
      if (
        addedKeywordList.includes("") ||
        keywordList.map((item) => item.keyword).includes("")
      ) {
        createToast({
          type: "warning",
          message: t("notification.duplicatedKeyword"),
        });
      } else {
        setAddedKeywordList((prev) => [...prev, ""]);
      }
    }
  };

  const onRemoveKeyword = (keyword: string) => {
    if (addedKeywordList.includes(keyword)) {
      setAddedKeywordList((prev) => prev.filter((item) => item !== keyword));
    } else {
      const removingKeywordItem = keywordList.find(
        (item) => item.keyword === keyword
      );
      if (removingKeywordItem) {
        setRemovedKeywordList((prev) => [...prev, removingKeywordItem]);
      }
    }
  };

  const onChangeKeyword = (oldKeyword: string, newKeyword: string) => {
    if (newKeyword === "") {
      createToast({
        type: "warning",
        message: t("notification.emptyKeyword.message"),
        detail: t("notification.emptyKeyword.description"),
      });
      return;
    }
    if (oldKeyword === newKeyword) return;
    onRemoveKeyword(oldKeyword);
    if (removedKeywordList.map((item) => item.keyword).includes(newKeyword)) {
      setRemovedKeywordList((prev) =>
        prev.filter((item) => item.keyword !== newKeyword)
      );
    } else {
      if (
        addedKeywordList.includes(newKeyword) ||
        keywordList.map((item) => item.keyword).includes(newKeyword)
      ) {
        createToast({
          type: "warning",
          message: t("notification.duplicatedKeyword"),
        });
      } else {
        setAddedKeywordList((prev) => [...prev, newKeyword]);
      }
    }
  };

  useEffect(() => {
    let ignore = false;

    if (!ignore) {
      fetchDataLeakKeywordList();
    }

    return () => {
      ignore = true;
    };
  }, [fetchDataLeakKeywordList]);

  return (
    <Drawer
      open={open}
      onClose={onCancelChanges}
      closable={false}
      title={<h2>{t("title")}</h2>}
      extra={
        <IconButton variant="label-grey" size={9} onClick={onCancelChanges}>
          <CloseLine />
        </IconButton>
      }
      footer={
        <FooterButtons onSave={onSaveAllSettings} onCancel={onCancelChanges} />
      }
      destroyOnClose
    >
      <div className="flex flex-col gap-6 p-6 h-full">
        <div className="flex flex-col gap-3">
          <span className="font-regular-14 text-hard-grey">{t("info")}</span>
          <SearchBox
            searchKeyword={searchKeyword}
            onChangeSearchKeyword={onChangeSearchKeyword}
            placeholder={t("searchKeyword.placeholder")}
            stretchWidth
          />
        </div>
        <div
          className="flex flex-col gap-3 overflow-y-auto"
          style={{ scrollbarWidth: "none" }}
          onScroll={(e) => {
            if (
              e.currentTarget.clientHeight + e.currentTarget.scrollTop >
                e.currentTarget.scrollHeight - 10 &&
              !loadingMoreKeywords &&
              keywordCount &&
              keywordCount > loadedKeywordPages * constants.DEFAULT_PAGE_SIZE
            ) {
              setLoadingMoreKeywords(true);
              fetchMoreKeywords();
            }
          }}
        >
          {keywordList
            .filter(
              (item) => !removedKeywordList.map((k) => k.id).includes(item.id)
            )
            .map((item, index) => (
              <KeywordItem
                key={`edit-keyword_${item.id}_${index}`}
                keyword={item.keyword}
                disabled={addedKeywordList.some(
                  (keyword) => keyword.length < 5
                )}
                onRemoveKeyword={onRemoveKeyword}
                onChangeKeyword={onChangeKeyword}
              />
            ))}
          {addedKeywordList
            .filter(
              (item) =>
                !removedKeywordList.map((item) => item.keyword).includes(item)
            )
            .filter((item) => item)
            .map((item, index) => (
              <KeywordItem
                key={`edit-keyword_${item}_${index}`}
                keyword={item}
                disabled={addedKeywordList.some(
                  (keyword) => keyword.length < 5
                )}
                onRemoveKeyword={onRemoveKeyword}
                onChangeKeyword={onChangeKeyword}
              />
            ))}
        </div>
        {addedKeywordList
          .filter((item) => !item)
          .map((item, index) => (
            <KeywordItem
              key={`edit-keyword_${item}_${index}`}
              keyword={item}
              disabled={addedKeywordList.some((keyword) => keyword.length < 5)}
              onRemoveKeyword={onRemoveKeyword}
              onChangeKeyword={onChangeKeyword}
            />
          ))}
        <div className="flex justify-center">
          <Button
            variant="text"
            size="large"
            disabled={
              searchKeyword !== "" ||
              addedKeywordList.some((item) => item.length < 5)
            }
            onClick={() => {
              onAddKeyword();
            }}
          >
            <AddLine />
            {t("button.addKeyword")}
          </Button>
        </div>
      </div>
    </Drawer>
  );
};

export default EditKeywordsDrawer;
