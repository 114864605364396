import { useTranslation } from "react-i18next";
import type { IFilterItem } from "#src/@types/common";
import { FilterDropdown, SearchBox } from "#src/components/common/Table/Filter";
import { policyComplianceItems } from "#src/config/filter/device";

interface IHostPoliciesFilterProps {
  policyCompliance: IFilterItem | null;
  searchKeyword: string;
  onChangePolicyComplianceSelection: (selected: IFilterItem | null) => void;
  onChangeSearchKeyword: (keyword: string) => void;
}

const HostPoliciesFilter = ({
  policyCompliance,
  searchKeyword,
  onChangePolicyComplianceSelection,
  onChangeSearchKeyword,
}: IHostPoliciesFilterProps) => {
  const { t } = useTranslation("devices", {
    keyPrefix: "hosts.page.details.tab.policies.filter",
  });

  return (
    <div className="flex justify-between gap-1">
      <div className="flex gap-1 max-w-[100%_-_15rem] whitespace-nowrap flex-wrap">
        <FilterDropdown
          label={t("policyCompliance.label")}
          selected={policyCompliance}
          options={policyComplianceItems}
          onChangeSelection={onChangePolicyComplianceSelection}
        />
      </div>

      <SearchBox
        searchKeyword={searchKeyword}
        onChangeSearchKeyword={onChangeSearchKeyword}
      />
    </div>
  );
};

export default HostPoliciesFilter;
