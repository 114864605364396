import { type ButtonHTMLAttributes, forwardRef } from "react";
import { COMMENT_EDITOR_TEXTAREA_ID } from "./constants";

const EditorButton = forwardRef<
  HTMLButtonElement,
  ButtonHTMLAttributes<HTMLButtonElement>
>(({ ...props }, ref) => {
  return (
    <button
      ref={ref}
      className="h-8 w-8 flex items-center justify-center disabled:text-button-disabled"
      onMouseDown={(e) => {
        e.preventDefault();
        const textarea = document.getElementById(COMMENT_EDITOR_TEXTAREA_ID);
        if (textarea) {
          textarea?.focus();
        }
      }}
      {...props}
    />
  );
});
EditorButton.displayName = "EditorButton";

export default EditorButton;
