// Libraries
import { Tooltip } from "@lockerpm/design";
import { useTranslation } from "react-i18next";

// Resources
import { ReactComponent as DeleteBin6Line } from "#src/assets/images/icons/delete-bin-6-line.svg";
import { ReactComponent as Settings4Line } from "#src/assets/images/icons/settings-4-line.svg";
import { ReactComponent as ErrorWarningFill } from "#src/assets/images/icons/error-warning-fill.svg";
import { ReactComponent as ShieldCheckLine } from "#src/assets/images/icons/shield-check-line.svg";
import { ReactComponent as QrScanLine } from "#src/assets/images/icons/qr-scan-line.svg";

// General
import global from "#src/config/global";
import urls from "#src/config/urls";

// Components
import { Button, IconButton } from "#src/components/common/system/Button";
import { ObjectImage } from "#src/components/common/system/Object";
import { RoundedTag } from "#src/components/common/Tags";

// API-related
import type { IAssetDetailItem } from "#src/services/assets";

interface IAssetDetailProfileSectionProps {
  details: IAssetDetailItem;
  onOpenEditDrawer: () => void;
  onOpenVerifyDrawer: () => void;
  onOpenScanDrawer: () => void;
  onDeleteThis: () => void;
}

/** Contains logo, asset name, tag list, edit button and delete button */
const AssetDetailProfileSection = ({
  details,
  onOpenEditDrawer,
  onOpenVerifyDrawer,
  onOpenScanDrawer,
  onDeleteThis,
}: IAssetDetailProfileSectionProps) => {
  const { t } = useTranslation("assets", { keyPrefix: "domains.page.details" });

  return (
    <>
      <div className="flex gap-3">
        <ObjectImage
          data={
            urls.IMAGE_LOGO_SERVER_URL.replace(
              ":address",
              details.detail.address
            ) + "?size=60&v=1"
          }
          className="h-[3.75rem] w-[3.75rem] min-w-[3.75rem] rounded-md border border-light-grey"
        />
        <div className="flex flex-col gap-3 justify-center">
          <div className="flex gap-2 flex-wrap items-center">
            <h2>
              {details.name.length > 0 &&
              details.name !== details.detail.address
                ? details.name + " (" + details.detail.address + ")"
                : details.detail.address}
            </h2>
            {details.verification ? null : (
              <ErrorWarningFill className="fill-fire" />
            )}
          </div>
          {details.tags.length > 0 ? (
            <div className="flex gap-2">
              <span>{t("profile.tags")}</span>
              <div className="flex gap-1 flex-wrap">
                {details.tags.map((tag) => (
                  <RoundedTag key={`details-tag-${tag.name}`} name={tag.name} />
                ))}
              </div>
            </div>
          ) : null}
        </div>
      </div>
      <div className="flex gap-1">
        {details.verification ? (
          <Button variant="secondary" size="large" onClick={onOpenScanDrawer}>
            <QrScanLine />
            <span className="font-medium-16">
              {t("button.launchNewScan.label")}
            </span>
          </Button>
        ) : (
          <Tooltip
            title={
              <div className="font-medium-12 whitespace-nowrap">
                {t("button.verifyOwnership.tooltip")}
              </div>
            }
            placement="top"
          >
            <Button
              variant="secondary"
              size="large"
              onClick={onOpenVerifyDrawer}
            >
              <ShieldCheckLine />
              <span className="font-medium-16 whitespace-nowrap">
                {t("button.verifyOwnership.label")}
              </span>
            </Button>
          </Tooltip>
        )}
        <IconButton variant="secondary" onClick={onOpenEditDrawer}>
          <Settings4Line />
        </IconButton>
        <IconButton
          variant="warning"
          onClick={() => {
            global.confirm(() => onDeleteThis());
          }}
        >
          <DeleteBin6Line />
        </IconButton>
      </div>
    </>
  );
};

export default AssetDetailProfileSection;
