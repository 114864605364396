export const pathname = {
  HOME: "/",
  // Authenticate
  AUTHENTICATE: "/authenticate",
  LOGIN: "/login",

  // === Functional paths ===
  // Asset
  DOMAINS: "/:workspace/assets/domains",
  DOMAIN_DETAILS: "/:workspace/assets/domains/:asset",
  IP_ADDRESS: "/:workspace/assets/ip-address",
  IP_ADDRESS_DETAILS: "/:workspace/assets/ip-address/:asset",
  CERTIFICATES: "/:workspace/assets/certificates",
  CERTIFICATE_DETAILS: "/:workspace/assets/certificates/:asset",
  // Device
  HOSTS: "/:workspace/devices/hosts",
  HOST_DETAILS: "/:workspace/devices/hosts/:host",
  POLICIES: "/:workspace/devices/policies",
  ADD_POLICIES: "/:workspace/devices/policies/add-policies",
  POLICY_DETAILS: "/:workspace/devices/policies/:policy",
  CONTROL: "/:workspace/devices/control",
  // Vulnerability
  VULNERABILITIES: "/:workspace/vulnerabilities",
  // Scan
  ALL_SCANS: "/:workspace/scans/all-scans",
  SCAN_DETAILS: "/:workspace/scans/all-scans/details/:scan",
  SCHEDULES: "/:workspace/scans/schedules",
  // Pentest
  PENTEST: "/:workspace/pentest",
  PENTEST_DETAILS: "/:workspace/pentest/details/:pentest",
  ADD_PENTEST: "/:workspace/pentest/add",
  // Bugbounty
  BUG_BOUNTY_PROGRAMS: "/:workspace/bug-bounty",
  BUG_BOUNTY_DETAILS: "/:workspace/bug-bounty/details/:bugbounty",
  BUG_BOUNTY_DETAILS_INTEGRATION_SETTINGS:
    "/:workspace/bug-bounty/details/:bugbounty/integration/:integration",
  ADD_BUG_BOUNTY: "/:workspace/bug-bounty/add",
  // Data leak
  DATA_LEAK_DETECTION: "/:workspace/data-leak-detection",
  // Trust center
  TRUST_CENTER_POLICY: "/:workspace/trust-center/policy",
  ADD_TRUST_CENTER_POLICY: "/:workspace/trust-center/policy/add",
  EDIT_TRUST_CENTER_POLICY: "/:workspace/trust-center/policy/:policy/edit",
  TRUST_CENTER_COMPLIANCE: "/:workspace/trust-center/compliance",
  TRUST_CENTER_CONTROL: "/:workspace/trust-center/control",
  TRUST_CENTER_DOCUMENT: "/:workspace/trust-center/document",
  TRUST_CENTER_THIRD_PARTY: "/:workspace/trust-center/third-party",
  TRUST_CENTER_FAQ: "/:workspace/trust-center/faq",
  ADD_TRUST_CENTER_FAQ: "/:workspace/trust-center/faq/add",
  EDIT_TRUST_CENTER_FAQ: "/:workspace/trust-center/faq/:faq/edit",
  TRUST_CENTER_CONFIG: "/:workspace/trust-center/config",
  TRUST_CENTER_REGISTER_PRIVATE_DOMAIN:
    "/:workspace/trust-center/register-private-domain",
  // Workspace & settings
  NEW_WORKSPACE: "/workspace/new",
  ACCOUNT_SETTINGS: "/:workspace/account/account-settings",
  NOTIFICATION: "/:workspace/account/notification",
  WORKSPACE_DETAILS: "/:workspace/workspace/details",
  WORKSPACE_MEMBERS: "/:workspace/workspace/members",
  WORKSPACE_ACTIVITY_LOGS: "/:workspace/workspace-activities",
  WORKSPACE_BILLING: "/:workspace/workspace/billing",
  WORKSPACE_BILLING_RENEW: "/:workspace/workspace/billing/renew",
  WORKSPACE_BILLING_UPGRADE_TRUST_CENTER:
    "/:workspace/workspace/billing/upgrade/trust_center",
  WORKSPACE_BILLING_UPGRADE_TC_CUSTOM_DOMAIN:
    "/:workspace/workspace/billing/upgrade/trust_center/custom_domain",
  // MSSP paths
  MSSP_PENTEST: "/mssp/:workspace/pentest",
  MSSP_PENTEST_DETAILS: "/mssp/:workspace/pentest/details/:pentest",
  MSSP_BUG_BOUNTY_PROGRAMS: "/mssp/:workspace/bug-bounty",
  MSSP_BUG_BOUNTY_DETAILS: "/mssp/:workspace/bug-bounty/details/:bugbounty",
  MSSP_BUG_BOUNTY_DETAILS_INTEGRATION_SETTINGS:
    "/mssp/:workspace/bug-bounty/details/:bugbounty/integration/:integration",
  SOLUTIONS: "/:workspace/solutions",
} satisfies { [K in string]: string };

// Every pathname should start with a slash ("/"). Authenticated pathname should almost always have a workspace param ("/:workspace")
// TODO: might want to move this to a test file. This is just to help on debugging.
Object.values(pathname).forEach((value) => {
  if (value[0] !== "/") console.log("Invalid pathname: " + value);
});

export const helperPathname = {
  ASSETS: "/:workspace/assets",
  DEVICES: "/:workspace/devices",
  SCANS: "/:workspace/scans",
  TRUST_CENTER: "/:workspace/trust-center",
  ACCOUNT: "/:workspace/account",
  WORKSPACE: "/:workspace/workspace",
  MSSP: "/mssp/:workspace",
} satisfies { [K in string]: string };
