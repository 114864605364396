import { forwardRef, type HTMLAttributes } from "react";
import { twMerge } from "tailwind-merge";

export const SubgridRow = forwardRef<
  HTMLDivElement,
  HTMLAttributes<HTMLDivElement>
>((props, ref) => {
  return (
    <div
      ref={ref}
      {...props}
      className={twMerge(
        "grid grid-cols-subgrid col-span-full",
        props.className
      )}
    ></div>
  );
});

SubgridRow.displayName = "SubgridRow";

export const PlanHeader = forwardRef<
  HTMLDivElement,
  HTMLAttributes<HTMLDivElement>
>((props, ref) => {
  return (
    <div
      ref={ref}
      {...props}
      className={twMerge("p-6 flex items-center font-bold-16", props.className)}
    ></div>
  );
});

PlanHeader.displayName = "PlanHeader";

export const PlanCell = forwardRef<
  HTMLDivElement,
  HTMLAttributes<HTMLDivElement>
>((props, ref) => {
  return (
    <div
      ref={ref}
      {...props}
      className={twMerge(
        "p-6 flex items-center text-hard-grey",
        props.className
      )}
    ></div>
  );
});

PlanCell.displayName = "PlanCell";
