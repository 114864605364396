// Libraries
import { type RefObject, useCallback, useEffect, useState } from "react";

// Components
import LoadingState from "#src/components/common/system/LoadingState";
import { apiErrorHandler } from "#src/utils/apiErrorHandler";

// API-related
import vulnerabilitiesServices, {
  type IVulnerabilityDetail,
} from "#src/services/vulnerabilities";

// Children
import { autoScanReporter, dataLeakReporter } from "../utils";
import ContentSection from "#src/components/vulnerabilities/DetailSection/ContentSection";
import InfoSection from "#src/components/vulnerabilities/DetailSection/InfoSection";

interface IVulnerabilityDetailSectionProps {
  workspaceId: string;
  containerRef: RefObject<HTMLDivElement>;
  vulnerabilityId: number | null;
  refreshVulnerabilityList: () => void;
}

const VulnerabilityDetailSection = ({
  workspaceId,
  containerRef,
  vulnerabilityId,
  refreshVulnerabilityList,
}: IVulnerabilityDetailSectionProps) => {
  const [vulnDetail, setVulnDetail] = useState<IVulnerabilityDetail | null>(
    null
  );
  const [isLoading, setLoading] = useState<boolean>(false);

  const refreshVulnerabilityDetail = useCallback(() => {
    if (workspaceId && vulnerabilityId) {
      setLoading(true);
      vulnerabilitiesServices
        .vulnerability_details(workspaceId, vulnerabilityId)
        .then((response) => {
          if (response.vuln_source === "auto_scan") {
            setVulnDetail({ ...response, reporter: autoScanReporter });
            setLoading(false);
            return;
          }
          if (response.vuln_source === "data_leak") {
            setVulnDetail({ ...response, reporter: dataLeakReporter });
            setLoading(false);
            return;
          }
          setVulnDetail(response);
          setLoading(false);
        })
        .catch(apiErrorHandler);
    }
  }, [workspaceId, vulnerabilityId]);

  useEffect(() => {
    let ignore = false;

    if (!ignore) {
      refreshVulnerabilityDetail();
    }

    return () => {
      ignore = true;
    };
  }, [refreshVulnerabilityDetail]);

  return vulnDetail === null || isLoading ? (
    <div className="flex-1">
      <LoadingState />
    </div>
  ) : (
    <>
      <ContentSection
        workspaceId={workspaceId}
        containerRef={containerRef}
        vulnDetail={vulnDetail}
        refreshVulnerabilityDetail={refreshVulnerabilityDetail}
      />
      <InfoSection
        workspaceId={workspaceId}
        containerRef={containerRef}
        vulnDetail={vulnDetail}
        refreshVulnerabilityDetail={refreshVulnerabilityDetail}
        refreshVulnerabilityList={refreshVulnerabilityList}
      />
    </>
  );
};

export default VulnerabilityDetailSection;
