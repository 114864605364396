export const PentestDetailTabEnum = {
  OVERVIEW: "PENTEST_DETAIL_TAB_OVERVIEW",
  SCOPE: "PENTEST_DETAIL_TAB_SCOPE",
  POLICY: "PENTEST_DETAIL_TAB_POLICY",
  UPDATES: "PENTEST_DETAIL_TAB_UPDATES",
  MSSP: "PENTEST_DETAIL_TAB_MSSP",
  MEMBERS: "PENTEST_DETAIL_TAB_MEMBERS",
};

export const PentestMemberActionEnum = {
  AUTO_ASSIGN_SUBMISSION: "PENTEST_MEMBER_ACTION_ENUM_AUTO_ASSIGN_SUBMISSION",
  DELETE_MEMBER: "PENTEST_MEMBER_ACTION_ENUM_DELETE_MEMBER",
};
