// Libraries
import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

// Resources
import { ReactComponent as ErrorWarningLine } from "#src/assets/images/icons/error-warning-line.svg";

// General
import { useAppSelector } from "#src/store/hooks";

// Components
import { Modal } from "#src/components/common/system/Modal";
import { Input } from "#src/components/common/system/Input";
import { Button } from "#src/components/common/system/Button";
import LoadingState from "#src/components/common/system/LoadingState";
import useFetch from "#src/hooks/useFetch";

// API-related
import workspaceServices from "#src/services/workspace";
import { TrustCenterPlanValue } from "#src/config/filter/trustCenter/value";

interface IDeactivateModalProps {
  open: boolean;
  onChangeOpen: (value: boolean) => void;
  onOk: () => void;
  workspaceId: string;
}

const DeactivateModal = ({
  open,
  onChangeOpen,
  onOk,
  workspaceId,
}: IDeactivateModalProps) => {
  const { t } = useTranslation("trustCenter", {
    keyPrefix: "configuration.tab.website.dangerZone.modal",
  });

  const trustCenterPlan = useAppSelector((state) => state.trustCenter.plan);

  const [keyword, setKeyword] = useState<string>("");
  const [invalid, setInvalid] = useState<boolean>(false);

  const workspaceParams = useMemo(() => [workspaceId], [workspaceId]);

  const { result: currentWorkspace } = useFetch(
    workspaceServices.retrieve_workspace,
    workspaceParams
  );

  const onCloseAndCleanup = () => {
    onChangeOpen(false);
  };

  return (
    <Modal open={open} onChangeOpen={onChangeOpen}>
      {currentWorkspace ? (
        <>
          <div className="flex flex-col gap-3">
            <h2 className="text-warning">{t("title")}</h2>
            <p className="text-hard-grey">
              {t("whenDeactivate")}
              <b>{t("cannotBeAccessedAnymore")}</b>
              {trustCenterPlan === TrustCenterPlanValue.ENTERPRISE ? (
                "."
              ) : (
                <>{t("dataReserved")}</>
              )}
              <br />
              <br />
              {trustCenterPlan === TrustCenterPlanValue.ENTERPRISE
                ? t("sendDeactivateRequest")
                : t("toDeactivate")}
              <b>{currentWorkspace?.name}</b>
            </p>
            {trustCenterPlan === TrustCenterPlanValue.ENTERPRISE ? null : (
              <>
                <Input
                  value={keyword}
                  onChange={(e) => {
                    setKeyword(e.target.value);
                    if (invalid) {
                      setInvalid(false);
                    }
                  }}
                />
                {invalid ? (
                  <span className="font-medium-14 text-warning flex items-center gap-1">
                    <ErrorWarningLine className="w-5 h-5 max-w-[1.25rem]" />
                    {t("mismatchKeyword")}
                  </span>
                ) : null}
              </>
            )}
          </div>
          <div className="flex justify-end">
            <div className="flex gap-1">
              <Button
                variant="secondary"
                onClick={() => {
                  onCloseAndCleanup();
                }}
              >
                {t("button.cancel")}
              </Button>
              <Button
                variant="danger"
                onClick={() => {
                  if (trustCenterPlan === TrustCenterPlanValue.ENTERPRISE) {
                    onOk();
                    onCloseAndCleanup();
                    return;
                  }
                  if (keyword === currentWorkspace.name) {
                    onOk();
                    onCloseAndCleanup();
                    return;
                  }
                  // If keyword is mismatched
                  setInvalid(true);
                }}
              >
                {t("button.deactivate")}
              </Button>
            </div>
          </div>
        </>
      ) : (
        <LoadingState />
      )}
    </Modal>
  );
};

export default DeactivateModal;
