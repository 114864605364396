import Cookies from "js-cookie";
import request from "#src/utils/request";
import endpoint from "#src/config/endpoint";

export interface IUserInfo {
  avatar: string;
  country: string;
  country_code: string;
  email: string;
  external_emails: any;
  full_name: string;
  is_deleting: boolean;
  is_factor2: boolean;
  language: string;
  organization: any;
  phone: string;
  registered_time: number;
  stripe_customer_id: any;
  username: string;
  utm_source: any;
}

// TODO: rename, refactor & re-typescript these services
function get_account() {
  return request.get<never, IUserInfo>(`${endpoint.BASE}/users/me`);
}

function update_access_token(data: any) {
  Cookies.set("access_token", JSON.stringify(data));
}

function update_access_token_type(data: any) {
  Cookies.set("access_token_type", JSON.stringify(data));
}

function access_token(): string | undefined {
  try {
    return JSON.parse(Cookies.get("access_token") || "");
  } catch (_) {
    return undefined;
  }
}

function access_token_type() {
  try {
    return JSON.parse(Cookies.get("access_token_type") || "");
  } catch (_) {
    return "";
  }
}

function userLogout() {
  // TODO: https://api.cystack.org/v3/sso/logout
  return request.post(`${endpoint.BASE}/users/logout`);
}

const authServices = {
  get_account,
  update_access_token,
  update_access_token_type,
  access_token,
  access_token_type,
  userLogout,
};

export default authServices;
