import { ReactComponent as LoaderLine } from "#src/assets/images/animations/loader-line.svg";
import type { CSSProperties } from "react";

interface ILoaderProps {
  baseSize?: number;
  particleCount?: number;
}

/**
 * Loading indicator. Can be used inside other components.
 * @param baseSize - Size of the loader component. Unit for this size constant is _`rem`_. Default 5.
 * @param particleCount - Number of particles, or `loader-line`. Default 8.
 */
const Loader = ({ baseSize = 5, particleCount = 8 }: ILoaderProps) => {
  /** Ratio of (particleHeight / the whole component size).
   * This should not be changed to make sure the image will not be distorted. */
  const particleHeightRatio = 0.3;
  /** Ratio of (particleWidth / the whole component size).
   * This should not be changed to make sure the image will not be distorted. */
  const particleWidthRatio = 0.1;

  const animationTime = 1.5;

  const loaderStyle: CSSProperties = {
    position: "absolute",
    height: baseSize * particleHeightRatio + "rem",
    width: baseSize * particleWidthRatio + "rem",
    left: baseSize * (0.5 - particleWidthRatio / 2) + "rem",
    top: (baseSize * (0.5 - particleHeightRatio)) / 4 + "rem",
    fill: "#D9EDFF",
    animation: `glow ${animationTime}s ease-in infinite`,
    transformOrigin:
      (baseSize * particleWidthRatio) / 2 + "rem " + baseSize * 0.45 + "rem",
  };

  return (
    <div
      className="relative"
      style={{
        height: baseSize + "rem",
        width: baseSize + "rem",
      }}
    >
      {particleCount !== 0
        ? Array(particleCount)
            .fill(undefined)
            .map((_, index) => (
              <LoaderLine
                key={`loader-line-${index}`}
                style={{
                  ...loaderStyle,
                  rotate: (360 / particleCount) * index + "deg",
                  animationDelay: (animationTime / particleCount) * index + "s",
                }}
              />
            ))
        : null}
    </div>
  );
};

export default Loader;
