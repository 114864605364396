import { useTranslation } from "react-i18next";
import { ReactComponent as ArrowExpandDownLine } from "#src/assets/images/icons/arrow-expand-down-line.svg";
import { MultipleChoiceBlockWrapper } from "#src/components/common/composed/divWrappers/MultipleChoiceBlockWrapper";

interface IPlanItemProps {
  getName: () => string;
  price: number;
  getTag?: () => string;
  selected: boolean;
  onClickItem: () => void;
}

/** Use on product plan select. Layouts can be different but this block seems to be consistent. */
const PlanItem = ({
  getName,
  price,
  getTag,
  selected,
  onClickItem,
}: IPlanItemProps) => {
  const { t } = useTranslation("trustCenter", {
    keyPrefix: "registerDomain.chooseAPlan",
  });

  return (
    <MultipleChoiceBlockWrapper selected={selected} onSelect={onClickItem}>
      <span>{getName()}</span>
      <span className="font-medium-20">
        <span className="font-bold-20">{price.toLocaleString()}</span>{" "}
        {t("currency")}/ {t("month")}
      </span>
      {getTag ? (
        <span className="absolute right-0 top-0 bg-support-seafoam text-white font-bold-16 px-1.5 py-1 rounded-md flex items-center gap-0.5">
          <ArrowExpandDownLine className="h-5 w-5 min-w-[1.25rem]" />
          {getTag()}
        </span>
      ) : null}
    </MultipleChoiceBlockWrapper>
  );
};

export default PlanItem;
