// Libraries
import { useTranslation } from "react-i18next";
import { useEffect, useState, type Dispatch, type SetStateAction } from "react";

// Resources
import { ReactComponent as ProfileLine } from "#src/assets/images/icons/profile-line.svg";
import { ReactComponent as EditLine } from "#src/assets/images/icons/edit-line.svg";

// Components
import { Button } from "#src/components/common/system/Button";
import { Input, TextArea } from "#src/components/common/system/Input";
import type { ITrustCenterConfigDetails } from "#src/services/trustCenter/configuration";
import { apiErrorHandler } from "#src/utils/apiErrorHandler";

// API-related
import trustCenterServices from "#src/services/trustCenter";

export interface ITrustCenterConfigProfileStates {
  name: string;
  shortName: string;
  homePage: string;
  email: string;
  phone: string;
  description: {
    vi: string;
    en: string;
  };
}

interface ITrustCenterConfigProfileProps {
  workspaceId: string;
  originalSettings: ITrustCenterConfigDetails;
  setOriginalSettings: Dispatch<SetStateAction<ITrustCenterConfigDetails>>;
}

const TrustCenterConfigProfile = ({
  workspaceId,
  originalSettings,
  setOriginalSettings,
}: ITrustCenterConfigProfileProps) => {
  const { t } = useTranslation("trustCenter", {
    keyPrefix: "configuration.tab.profile",
  });

  const [isEditing, setIsEditing] = useState<boolean>(false);
  const [profile, setProfile] = useState<ITrustCenterConfigProfileStates>({
    name: "",
    shortName: "",
    homePage: "",
    email: "",
    phone: "",
    description: {
      en: "",
      vi: "",
    },
  });

  const [pending, setPending] = useState<boolean>(false);

  useEffect(() => {
    setProfile({
      name: originalSettings.profile.name || "",
      shortName: originalSettings.profile.short_name || "",
      homePage: originalSettings.profile.website || "",
      email: originalSettings.profile.email || "",
      phone: originalSettings.profile.phone || "",
      description: originalSettings.profile.description || { en: "", vi: "" },
    });
  }, [originalSettings]);

  const onCancelEditProfile = () => {
    setIsEditing(false);
    setProfile({
      name: originalSettings.profile.name || "",
      shortName: originalSettings.profile.short_name || "",
      homePage: originalSettings.profile.website || "",
      email: originalSettings.profile.email || "",
      phone: originalSettings.profile.phone || "",
      description: originalSettings.profile.description || { en: "", vi: "" },
    });
  };

  const onSaveEditProfile = async () => {
    setPending(true);
    trustCenterServices
      .update_trust_center_config_profile(workspaceId, {
        name: profile.name,
        short_name: profile.shortName,
        website: profile.homePage,
        email: profile.email,
        phone: profile.phone,
        description: profile.description,
        og_title: originalSettings?.profile.og_title,
        og_description: originalSettings?.profile.og_description,
        og_logo: originalSettings?.profile.og_logo,
      })
      .then((response) => {
        setOriginalSettings(response);
        setProfile({
          name: response.profile.name,
          shortName: response.profile.short_name
            ? response.profile.short_name
            : "",
          homePage: response.profile.website ? response.profile.website : "",
          email: response.profile.email,
          phone: response.profile.phone ? response.profile.phone : "",
          description: response.profile.description,
        });
      })
      .catch(apiErrorHandler)
      .finally(() => {
        setPending(false);
        setIsEditing(false);
      });
  };

  return (
    <div className="flex flex-col gap-6 w-2/3">
      <div className="flex flex-col border border-light-grey rounded-md">
        <div className="p-6 flex justify-between rounded-md bg-bright-grey">
          <div className="flex items-center gap-3">
            <ProfileLine className="h-7 w-7" />
            <h2 className="font-medium-20">{t("profile.title")}</h2>
          </div>
          <div className="flex gap-2">
            {isEditing ? (
              <>
                <Button variant="secondary" onClick={onCancelEditProfile}>
                  {t("securityProfile.button.cancel")}
                </Button>
                <Button
                  variant="primary"
                  pending={pending}
                  onClick={onSaveEditProfile}
                >
                  {t("securityProfile.button.save")}
                </Button>
              </>
            ) : (
              <Button variant="secondary" onClick={() => setIsEditing(true)}>
                <EditLine />
                {t("securityProfile.button.edit")}
              </Button>
            )}
          </div>
        </div>
        <div className="p-6 grid grid-cols-2 gap-x-[4.5rem] gap-y-6">
          <div className="flex flex-col gap-y-6">
            <div className="flex flex-col gap-3">
              <h4>{t("profile.companyName")}</h4>
              <Input
                value={profile.name}
                disabled={!isEditing}
                onChange={(e) => {
                  setProfile((prev) => ({ ...prev, name: e.target.value }));
                }}
              />
            </div>
            <div className="flex flex-col gap-3">
              <h4>{t("profile.companyAlias")}</h4>
              <Input
                value={profile.shortName}
                disabled={!isEditing}
                onChange={(e) => {
                  setProfile((prev) => ({
                    ...prev,
                    shortName: e.target.value,
                  }));
                }}
              />
            </div>
            <div className="flex flex-col gap-3">
              <h4>{t("profile.homePage")}</h4>
              <Input
                value={profile.homePage}
                disabled={!isEditing}
                onChange={(e) => {
                  setProfile((prev) => ({ ...prev, homePage: e.target.value }));
                }}
              />
            </div>
            <div className="flex flex-col gap-3">
              <h4>{t("profile.contactEmail")}</h4>
              <Input
                value={profile.email}
                disabled={!isEditing}
                onChange={(e) => {
                  setProfile((prev) => ({ ...prev, email: e.target.value }));
                }}
              />
            </div>
            <div className="flex flex-col gap-3">
              <h4>{t("profile.contactPhoneNumber")}</h4>
              <Input
                value={profile.phone}
                disabled={!isEditing}
                onChange={(e) => {
                  setProfile((prev) => ({ ...prev, phone: e.target.value }));
                }}
              />
            </div>
          </div>
          <div className="flex flex-col gap-y-6">
            <div className="flex flex-col gap-3">
              <h4>{t("profile.descriptionVIE")}</h4>
              <TextArea
                rows={4}
                value={profile.description?.vi}
                disabled={!isEditing}
                onChange={(e) => {
                  setProfile((prev) => ({
                    ...prev,
                    description: {
                      ...prev.description,
                      vi: e.target.value,
                    },
                  }));
                }}
              />
            </div>
            <div className="flex flex-col gap-3">
              <h4>{t("profile.descriptionEN")}</h4>
              <TextArea
                rows={4}
                value={profile.description?.en}
                disabled={!isEditing}
                onChange={(e) => {
                  setProfile((prev) => ({
                    ...prev,
                    description: {
                      ...prev.description,
                      en: e.target.value,
                    },
                  }));
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TrustCenterConfigProfile;
