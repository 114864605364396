export const ControlTableTabEnum = {
  NETWORK: "CONTROL_TAB_NETWORK",
  IP_ADDRESS: "CONTROL_TAB_IP_ADDRESS",
  PORT: "CONTROL_TAB_PORT",
  RESTRICTION: "CONTROL_TAB_RESTRICTION",
};

export const AddControlTabEnum = {
  NETWORK: "ADD_CONTROL_TAB_NETWORK",
  IP_ADDRESS: "ADD_CONTROL_TAB_IP_ADDRESS",
  PORT: "ADD_CONTROL_TAB_PORT",
  RESTRICTION: "ADD_CONTROL_TAB_RESTRICTION",
};
