// Libraries
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { useCallback, useEffect, useState } from "react";
import { Link } from "react-router-dom";

// import { ReactComponent as AddLine } from "#src/assets/images/icons/add-line.svg";

// Components
import { Button } from "#src/components/common/system/Button";
import { ContentSection, StickySection } from "#src/layouts/content";
import { apiErrorHandler } from "#src/utils/apiErrorHandler";

// API-related
import devicesServices, {
  type IDeviceHostStatistics,
} from "#src/services/devices";

// Children
import HostTable from "#src/components/devices/hosts/HostTable";
import HostStatistics from "#src/components/devices/hosts/Statistics";

const DeviceHosts = () => {
  const { t } = useTranslation("devices", { keyPrefix: "hosts.page.hosts" });
  const { workspace: workspaceId } = useParams<"workspace">();

  const [hostStatistics, setHostStatistics] =
    useState<IDeviceHostStatistics | null>(null);

  const fetchHostStatistics = useCallback(() => {
    if (workspaceId) {
      devicesServices
        .get_host_statistics(workspaceId)
        .then((response) => {
          setHostStatistics(response);
        })
        .catch((error) => {
          apiErrorHandler(error, {
            toastMessage: t("notification.loadStatistics.fail"),
          });
        });
    }
  }, [workspaceId, t]);

  useEffect(() => {
    let ignore = false;

    if (!ignore) {
      fetchHostStatistics();
    }

    return () => {
      ignore = true;
    };
  }, [fetchHostStatistics]);

  if (!workspaceId || workspaceId === "null") {
    // TODO: Replace with empty state illustration
    return <>{t("notification.noWorkspaceSelected")}</>;
  }

  return (
    <>
      {/* This stacking context has 2 sections, the "header" one should have a higher z-index (1) to make sure every elements on the "content" section are not covering the "header" */}
      <StickySection>
        <h1>{t("title")}</h1>
        <Link to="https://cystack.net/products/contact-sales" target="_blank">
          <Button
            size="large"
            // disabled
            // onClick={() => setOpenAddDomainDrawer((prev) => !prev)}
          >
            {/* <AddLine />
              {t("button.newHost")} */}
            {"Contact us"}
          </Button>
        </Link>
      </StickySection>
      <ContentSection>
        <HostStatistics statistics={hostStatistics} />
        <HostTable
          workspaceId={workspaceId}
          refreshHostStatistics={fetchHostStatistics}
        />
      </ContentSection>
    </>
  );
};

export default DeviceHosts;
