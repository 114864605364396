import type { ReactNode } from "react";

export const MultipleChoiceBlockWrapper = ({
  selected,
  onSelect,
  disabled = false,
  children,
}: {
  selected: boolean;
  onSelect: () => void;
  disabled?: boolean;
  children?: ReactNode;
}) => {
  return (
    <div
      className={`px-6 py-4 border rounded-md flex flex-col gap-3 flex-1 relative transition-all duration-150 ${
        selected
          ? "bg-button-secondary-hover border-primary"
          : "bg-bright-grey border-light-grey"
      } ${disabled ? "select-none" : "cursor-pointer"}`}
      onClick={disabled ? undefined : onSelect}
    >
      {children}
    </div>
  );
};
