import type { ICertificateItem } from "#src/services/assets/certificates";
import { useTranslation } from "react-i18next";

const CertificateHttpRequests = ({
  endpointDetails,
}: {
  endpointDetails: ICertificateItem["endpoints"][number]["details"];
}) => {
  const { t } = useTranslation("assets", {
    keyPrefix: "domains.page.details.tab.certificates",
  });

  return (
    <>
      <h2>{t("httpRequests")}</h2>
      <div>
        {endpointDetails.httpTransactions.map((transaction) => (
          <div
            key={`transaction-${transaction.requestUrl}`}
            className="px-9 py-5 bg-white first:border-t border-b border-light-grey"
          >
            {transaction.requestUrl + transaction.responseLine}
          </div>
        ))}
      </div>
    </>
  );
};

export default CertificateHttpRequests;
