// Libraries
import { Modal } from "@lockerpm/design";
import { useEffect, useState, type ReactNode } from "react";
import { useTranslation } from "react-i18next";

// Resources
import { ReactComponent as EyeOffLine } from "#src/assets/images/icons/eye-off-line.svg";
import { ReactComponent as EyeLine } from "#src/assets/images/icons/eye-line.svg";

// Components
import CopyToClipboardButton from "#src/components/common/CopyToClipboardButton";
import { IconButton } from "#src/components/common/system/Button";
import { createToast } from "#src/components/common/system/toasts";

// API-related
import type { IScanItem } from "#src/services/scans";
import assetsServices, { type IAssetDetailItem } from "#src/services/assets";

// Children
import { getScheduleSummary } from "#src/components/scans/utils";

const ScanConfigModalItem = ({
  name,
  value,
}: {
  name: string;
  value: ReactNode;
}) => {
  return (
    <div className="grid grid-cols-[4fr_5fr]">
      <p className="text-hard-grey">{name}</p>
      {value}
    </div>
  );
};

const ScanConfigModalHeading = ({ name }: { name: string }) => {
  return (
    <div className="flex gap-6 items-center">
      <h4>{name}</h4>
      <div className="flex items-center flex-1">
        <div className="border-b border-grey w-full" />
      </div>
    </div>
  );
};

interface IScanConfigModalProps {
  workspaceId: string;
  open: boolean;
  onClose: () => void;
  scanProfile: IScanItem["scan_profile"];
}

const ScanConfigModal = ({
  workspaceId,
  open,
  onClose,
  scanProfile,
}: IScanConfigModalProps) => {
  const { t } = useTranslation("scans", {
    keyPrefix: "page.scanDetails.basicInfo.scanConfigModal",
  });

  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [domainDetails, setDomainDetails] = useState<IAssetDetailItem>();

  useEffect(() => {
    let ignore = false;

    const domainId = scanProfile.assets[0]?.asset_id;

    if (!ignore && workspaceId && domainId && !isNaN(+domainId)) {
      assetsServices
        .retrieve_asset(workspaceId, +domainId)
        .then((response) => {
          setDomainDetails(response);
        })
        .catch(() => {
          createToast({
            type: "warning",
            message: t("notification.cannotLoadAssetsInfo.message"),
          });
        });
    }

    return () => {
      ignore = true;
    };
  }, [workspaceId, scanProfile.assets, t]);

  return (
    <Modal
      open={open}
      onCancel={() => {
        onClose();
        setShowPassword(false);
      }}
      title={<h2>{t("title")}</h2>}
      centered
      footer={null}
      width="44rem"
    >
      <div className="flex flex-col gap-6">
        {domainDetails ? (
          <>
            <ScanConfigModalHeading
              name={t("assetScanProfile.title").toUpperCase()}
            />
            {domainDetails.authentication_method === "basic" ? (
              <>
                <ScanConfigModalItem
                  name={t("assetScanProfile.authMethod.title")}
                  value={t("assetScanProfile.authMethod.basic")}
                />
                <ScanConfigModalItem
                  name={t("assetScanProfile.authUsername")}
                  value={domainDetails.authentication?.username}
                />
                <ScanConfigModalItem
                  name={t("assetScanProfile.authPassword")}
                  value={
                    <div className="flex justify-between">
                      <span>
                        {showPassword
                          ? domainDetails.authentication?.password
                          : "*".repeat(
                              domainDetails.authentication?.password?.length ||
                                0
                            )}
                      </span>
                      <IconButton
                        variant="ghost"
                        size={5}
                        onClick={() => {
                          setShowPassword((prev) => !prev);
                        }}
                      >
                        {showPassword ? <EyeLine /> : <EyeOffLine />}
                      </IconButton>
                    </div>
                  }
                />
              </>
            ) : domainDetails.authentication_method === "cookie" ? (
              <>
                <ScanConfigModalItem
                  name={t("assetScanProfile.authMethod.title")}
                  value={t("assetScanProfile.authMethod.cookie")}
                />
                <ScanConfigModalItem
                  name={t("assetScanProfile.authCookie")}
                  value={
                    <div className="flex justify-between">
                      <span>{domainDetails.authentication?.cookie}</span>
                      <CopyToClipboardButton
                        value={
                          domainDetails.authentication?.cookie
                            ? domainDetails.authentication?.cookie
                            : undefined
                        }
                        inline
                      />
                    </div>
                  }
                />
              </>
            ) : (
              <ScanConfigModalItem
                name={t("assetScanProfile.authMethod.title")}
                value={"---"}
              />
            )}
            <ScanConfigModalItem
              name={t("assetScanProfile.cystackUserAgentHeader")}
              value={
                domainDetails.use_cystack_user_agent ? (
                  <div className="bg-bright-blue p-3 rounded-md">
                    {/* This is fixed text, no need to translate */}
                    <code>
                      User-agent:
                      <code className="text-primary">
                        `Cystack Vulnerabitity Scanner - https://cystack.io`
                      </code>
                    </code>
                  </div>
                ) : (
                  "---"
                )
              }
            />
            <ScanConfigModalItem
              name={t("assetScanProfile.customHeaders")}
              value={
                domainDetails.custom_headers?.length ? (
                  <div className="bg-bright-blue p-3 rounded-md flex flex-col">
                    {domainDetails.custom_headers?.map((header) => (
                      <code key={`customHeaders-${header.name}`}>
                        {header.name}
                        {": "}
                        <code className="text-primary">{header.value}</code>
                      </code>
                    ))}
                  </div>
                ) : (
                  "---"
                )
              }
            />
          </>
        ) : null}
        <ScanConfigModalHeading
          name={t("scheduleConfiguration.title").toUpperCase()}
        />
        {scanProfile ? (
          <ScanConfigModalItem
            name={t("scheduleConfiguration.scheduleSummary")}
            value={getScheduleSummary(
              scanProfile.schedule,
              scanProfile.duration_type,
              scanProfile.repeat_number,
              scanProfile.activated_time,
              scanProfile.days.map((item) => +item)
            )}
          />
        ) : null}
        <ScanConfigModalItem
          name={t("scheduleConfiguration.timezone")}
          value={<p>Asia/Saigon (+07:00)</p>}
        />
      </div>
    </Modal>
  );
};

export default ScanConfigModal;
