// Libraries
import {
  generatePath,
  Outlet,
  useMatch,
  useNavigate,
  useParams,
} from "react-router-dom";
import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

// Resources
import { ReactComponent as GitRepositoryLine } from "#src/assets/images/icons/git-repository-line.svg";

// General
import { helperPathname, pathname } from "#src/config/pathname";
import { useAppDispatch } from "#src/store/hooks";
import { updatePlan } from "#src/store/slices/trustCenter";

// Components
import LoadingState from "#src/components/common/system/LoadingState";
import { ContentSection, StickySection } from "#src/layouts/content";
import { apiErrorHandler } from "#src/utils/apiErrorHandler";

// API-related
import trustCenterServices from "#src/services/trustCenter";

// Children
import PricingPlan from "#src/components/trustCenter/onboard/PricingPlan";

const TrustCenterIndexPage = () => {
  const { t } = useTranslation("trustCenter", { keyPrefix: "onboard" });

  const { workspace: workspaceId } = useParams<{ workspace: string }>();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const isAtOnboarding = useMatch(helperPathname.TRUST_CENTER);

  const [currentPlan, setCurrentPlan] = useState<string | null>();

  const onChangePlan = useCallback(
    (newPlan: typeof currentPlan) => {
      setCurrentPlan(newPlan);

      // If user never uses Trust Center, redirect to Trust Center (aka. Onboard) page
      if (!isAtOnboarding && newPlan === null) {
        navigate(
          generatePath(helperPathname.TRUST_CENTER, { workspace: workspaceId })
        );
      }

      // If user has used Trust Center, guide them to a functional page
      if (isAtOnboarding && typeof newPlan === "string") {
        navigate(
          generatePath(pathname.TRUST_CENTER_POLICY, { workspace: workspaceId })
        );
      }
    },
    [isAtOnboarding, navigate, workspaceId]
  );

  useEffect(() => {
    if (workspaceId) {
      trustCenterServices
        .retrieve_trust_center_plan(workspaceId)
        .then((response) => {
          onChangePlan(response.product_plan_id);
          dispatch(updatePlan(response.product_plan_id));
        })
        .catch(apiErrorHandler);
    } else {
      throw Error("Invalid workspace parameter");
    }
  }, [workspaceId, onChangePlan, dispatch]);

  const requestFreePlan = () => {
    if (workspaceId) {
      trustCenterServices
        .request_trust_center_plan(workspaceId, {
          product_plan_id: "tc_free",
        })
        .then((response) => {
          onChangePlan(response.product_plan_id);
          dispatch(updatePlan(response.product_plan_id));
        })
        .catch(apiErrorHandler);
    }
  };

  return workspaceId ? (
    currentPlan === undefined ? (
      <LoadingState />
    ) : currentPlan === null ? (
      <>
        <StickySection>
          <div className="flex flex-col gap-3">
            <h1>{t("title")}</h1>
            <p className="font-regular-18-body text-hard-grey">
              {t("description")}
            </p>
            <div className="flex gap-2">
              <GitRepositoryLine
                width="1.25rem"
                height="1.25rem"
                className="fill-primary"
              />
              <a>{t("link.learnMore")}</a>
            </div>
          </div>
        </StickySection>
        <ContentSection className="gap-[3.75rem]">
          <PricingPlan
            workspaceId={workspaceId}
            requestFreePlan={requestFreePlan}
          />
        </ContentSection>
      </>
    ) : (
      <Outlet />
    )
  ) : null;
};

export default TrustCenterIndexPage;
