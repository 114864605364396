import { Tooltip } from "@lockerpm/design";
import { useLayoutEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";

const labelDisplay = (num: number, width: number) => {
  if (width / 16 > num.toString().length) return num;
  return "";
};

interface ILinearGaugeChartProps {
  id: number;
  vulnBySeverity: { [severity: string]: number | undefined };
  onClickSeries: (severity: string) => void;
}

// This should not be a common component since it has too much specific business logic. Only after extracting all the business logic out of this then we can move this to common component folder
export const LinearGaugeChart = ({
  id,
  vulnBySeverity,
  onClickSeries,
}: ILinearGaugeChartProps) => {
  const { t } = useTranslation();

  const ref = useRef<HTMLDivElement>(null);
  const [gaugeWidth, setGaugeWidth] = useState<number>(0);

  const legends: { [severity: string]: string } = {
    CRITICAL: "bg-severity-critical",
    HIGH: "bg-severity-high",
    MEDIUM: "bg-severity-medium",
    LOW: "bg-severity-low",
    INFORMATION: "bg-severity-information",
  };
  const label: { [severity: string]: string } = {
    CRITICAL: t("severity.critical"),
    HIGH: t("severity.high"),
    MEDIUM: t("severity.medium"),
    LOW: t("severity.low"),
    INFORMATION: t("severity.information"),
  };

  const total = Object.values(vulnBySeverity).reduce<number>(
    (prev, cur) => (cur ?? 0) + prev,
    0
  );

  useLayoutEffect(() => {
    // TODO: Add debounce
    const measureGaugeWidth = () => {
      setGaugeWidth(ref.current?.getBoundingClientRect().width || 0);
    };

    measureGaugeWidth();
    window.addEventListener("resize", measureGaugeWidth);

    return () => {
      window.removeEventListener("resize", measureGaugeWidth);
    };
  }, []);

  if (Object.values(vulnBySeverity).length === 0 || total === 0)
    return (
      <div
        ref={ref}
        id={`linearGauge-${id}`}
        // In our design this has the font of font-medium-14-forced, but our current font-variant-numeric settings make it hard to read, so I choose to avoid that
        // Currently we're working around with the forced font, so it's ok. Originally this use "font-medium-14"
        className="w-full h-5 flex rounded-[0.25rem] bg-silver items-center justify-center font-medium-14-forced text-white"
      >
        0
      </div>
    );

  return (
    <div
      ref={ref}
      id={`linearGauge-${id}`}
      className="w-full h-5 flex rounded-[0.25rem] bg-silver overflow-hidden"
    >
      {Object.entries(vulnBySeverity).map(([key, value]) => {
        const sectionWidth = (gaugeWidth / total) * (value ?? 0);

        return (
          <Tooltip
            key={`linearGauge-${id}_series-${key}`}
            title={
              <span className="font-medium-12">
                {label[key] + ": " + value}
              </span>
            }
          >
            <div
              // Using inline css since we have to calculate the widths. This is much better than using Tailwind with backtick strings.
              style={{
                width: sectionWidth,
              }}
              // In our design this has the font of font-medium-14-forced, but our current font-variant-numeric settings make it hard to read, so I choose to avoid that
              // Currently we're working around with the forced font, so it's ok. Originally this use "font-medium-14"
              className={`${legends[key]} flex items-center justify-center font-medium-14-forced text-white hover:brightness-90`}
              onClick={(e) => {
                e.preventDefault();
                onClickSeries(key);
              }}
            >
              {labelDisplay(value ?? 0, sectionWidth)}
            </div>
          </Tooltip>
        );
      })}
    </div>
  );
};
