import type { IPaginatedResponse } from "#src/@types/api";
import endpoint from "#src/config/endpoint";
import request from "#src/utils/request";
import type { IIntegrationItem } from "#src/services/resource";

export interface IProgramIntegrationItem {
  integration: IIntegrationItem;
  turn_on: boolean;
  status: string;
  language: string;
  configuration_url: string;
  config: {
    new_report: boolean;
    changes_status: boolean;
    is_rewarded: boolean;
    researcher_comment: boolean;
    manager_comment: boolean;
    manager_note: boolean;
    researcher_request: boolean;
    request_disclosure: boolean;
  };
}

export function list_program_integrations(
  workspace_id: string,
  program_alias: string
) {
  return request.get<never, IPaginatedResponse<IProgramIntegrationItem>>(
    `${endpoint.WORKSPACES}/${workspace_id}/programs/${program_alias}/integrations`
  );
}

export function retrieve_program_integration_details(
  workspace_id: string,
  program_alias: string,
  integration_id: string
) {
  return request.get<never, IProgramIntegrationItem>(
    `${endpoint.WORKSPACES}/${workspace_id}/programs/${program_alias}/integrations/${integration_id}`
  );
}

export function create_program_integration(
  workspace_id: string,
  program_alias: string,
  integration_id: string,
  // NOTE: this is Slack's data. Trello and others can be different
  data: {
    code: string;
    state: string;
    redirect_uri: string;
  }
) {
  return request.post<never, { success: boolean }>(
    `${endpoint.WORKSPACES}/${workspace_id}/programs/${program_alias}/integrations/${integration_id}/access_token`,
    data
  );
}

export function update_program_integration(
  workspace_id: string,
  program_alias: string,
  integration_id: string,
  data: Partial<IProgramIntegrationItem>
) {
  return request.put<never, { success: boolean }>(
    `${endpoint.WORKSPACES}/${workspace_id}/programs/${program_alias}/integrations/${integration_id}`,
    data
  );
}
