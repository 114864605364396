import { BugBountyStatusResponseValue } from "#src/config/filter/bugbounty/value";

const colorByBugBountyStatus: { [S in string]: string } = {
  [BugBountyStatusResponseValue.DRAFT]: "bg-silver",
  [BugBountyStatusResponseValue.REQUESTED]: "bg-blue",
  [BugBountyStatusResponseValue.OPEN]: "bg-green",
  [BugBountyStatusResponseValue.CLOSED]: "bg-yellow",
  [BugBountyStatusResponseValue.LOCKED]: "bg-red",
};

export const getColorByBugBountyStatus = (status: string) => {
  if (Object.keys(colorByBugBountyStatus).includes(status)) {
    return colorByBugBountyStatus[status];
  }
  return "bg-silver";
};
