import type { ReactNode } from "react";

const TableActionLabel = ({
  icon,
  text,
  warning = false,
}: {
  icon: ReactNode;
  text: string;
  warning?: boolean;
}) => {
  return (
    <div
      className={`flex items-center gap-3 py-4 px-6 ${
        warning ? "text-warning" : ""
      }`}
    >
      {icon}
      <span className="font-medium-16">{text}</span>
    </div>
  );
};

export default TableActionLabel;
