// Libraries
import { type ReactNode } from "react";
import { createPortal } from "react-dom";
import { twMerge } from "tailwind-merge";

// Resources
import { ReactComponent as CloseLine } from "#src/assets/images/icons/close-line.svg";

// Components
import { IconButton } from "#src/components/common/system/Button";

interface IModalProps {
  open: boolean;
  onChangeOpen: (value: boolean) => void;
  children?: ReactNode;
  className?: string;
}

/** @todo Detach the close button so we can make this a standalone reusable across projects. */
export const Modal = ({
  open,
  onChangeOpen,
  children,
  className,
}: IModalProps) => {
  const rootElement = document.getElementById("app");

  if (rootElement === null) {
    throw Error(
      "Thrown from modal: Root element is not found?? What have you done, frontend dev?"
    );
  }

  return (
    <>
      {createPortal(
        <div
          className={`fixed top-0 left-0 h-screen w-screen flex items-center justify-center z-10 duration-150 transition-all bg-dark-blue bg-opacity-[48%] ${
            open ? "" : "invisible opacity-0"
          }`}
          onClick={() => {
            onChangeOpen(false);
          }}
        >
          <div
            className={twMerge(
              `relative w-[40rem] px-14 py-10 rounded-md bg-white flex flex-col gap-6 overflow-hidden transition-all duration-150 ${
                open ? "" : "scale-50"
              }`,
              className
            )}
            onClick={(e) => {
              e.stopPropagation();
            }}
          >
            {children}
            <div className="absolute right-0 top-0 p-4">
              <IconButton
                variant="label-grey"
                onClick={() => {
                  onChangeOpen(false);
                }}
              >
                <CloseLine />
              </IconButton>
            </div>
          </div>
        </div>,
        rootElement
      )}
    </>
  );
};
