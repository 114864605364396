import { useMemo, useState } from "react";
import constants from "#src/config/constants";
import type { IFilterItem } from "#src/@types/common";
import PaginationBar from "#src/components/common/helper/PaginationBar";
import useFetchPaginated from "#src/hooks/useFetchPaginated";
import PentestFilter from "./Filter";
import PentestTableGrid from "./Table";
import pentestServices from "#src/services/pentest";

interface IPentestListProps {
  workspaceId: string;
}

const PentestList = ({ workspaceId }: IPentestListProps) => {
  const [from, setFrom] = useState<number | null>(null);
  const [to, setTo] = useState<number | null>(null);
  const [pentestStatus, setPentestStatus] = useState<IFilterItem | null>(null);
  const [searchKeyword, setSearchKeyword] = useState<string>("");

  const [selectedPage, setSelectedPage] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(constants.DEFAULT_PAGE_SIZE);

  const fetchParams = useMemo<
    Parameters<typeof pentestServices.list_pentest_projects>
  >(
    () => [
      workspaceId,
      {
        from: from ? from : undefined,
        to: to ? to : undefined,
        status: pentestStatus ? pentestStatus.value : undefined,
        q: searchKeyword ? searchKeyword : undefined,
        page: selectedPage,
        size: pageSize,
      },
    ],
    [
      workspaceId,
      from,
      to,
      pentestStatus,
      searchKeyword,
      selectedPage,
      pageSize,
    ]
  );

  const onChangeTimeConditionValue = (
    condition: string,
    from: number,
    to: number
  ) => {
    if (condition) {
      setFrom(from);
      setTo(to);
    } else {
      setFrom(null);
      setTo(null);
    }
    setSelectedPage(1);
  };

  const onChangePentestStatusSelection = (selected: typeof pentestStatus) => {
    setPentestStatus(selected);
    setSelectedPage(1);
  };

  const onChangeSearchKeyword = (keyword: string) => {
    setSearchKeyword(keyword);
    setSelectedPage(1);
  };

  const onChangePage = (page: number) => {
    setSelectedPage(page);
  };

  const onChangePageSize = (size: number) => {
    setPageSize(size);
    setSelectedPage(1);
  };

  const {
    list: pentestList,
    count: pentestCount,
    isLoading,
    updateData: updatePentestList,
  } = useFetchPaginated(pentestServices.list_pentest_projects, fetchParams);

  return (
    <>
      <PentestFilter
        pentestStatus={pentestStatus}
        searchKeyword={searchKeyword}
        onChangeTimeConditionValue={onChangeTimeConditionValue}
        onChangePentestStatusSelection={onChangePentestStatusSelection}
        onChangeSearchKeyword={onChangeSearchKeyword}
      />
      <PentestTableGrid
        workspaceId={workspaceId}
        pentestList={pentestList}
        isLoading={isLoading}
        refreshProjectList={updatePentestList}
      />
      <PaginationBar
        numOfResult={pentestCount}
        selectedPage={selectedPage}
        pageSize={pageSize}
        onChangePage={onChangePage}
        onChangePageSize={onChangePageSize}
      />
    </>
  );
};

export default PentestList;
