// Libraries
import { Link, matchPath, useMatches } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { type ReactElement, cloneElement, useEffect } from "react";
import { Dropdown } from "@lockerpm/design";
import { generatePath, useNavigate, useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "#src/store/hooks";

// Resources
import { ReactComponent as ArrowDownSLine } from "#src/assets/images/icons/arrow-down-s-line.svg";
import { ReactComponent as AddLine } from "#src/assets/images/icons/add-line.svg";

// General
import { pathname } from "#src/config/pathname";
import { selectWorkspace } from "#src/store/slices/workspace";
import { updateWorkspaceList } from "#src/store/slices/workspace";

// Components
import DropdownItemLabel from "#src/components/common/helper/antdProps/Dropdown/DropdownItemLabel";
import { ObjectImage } from "#src/components/common/system/Object";
import { apiErrorHandler } from "#src/utils/apiErrorHandler";

// API-related
import workspaceServices from "#src/services/workspace";

// Children
import { msspPaths } from "../pathConfig";

interface IWorkspaceSelect {
  lightMode?: boolean;
}

const WorkspaceSelect = ({ lightMode = false }: IWorkspaceSelect) => {
  const { t } = useTranslation("general", {
    keyPrefix: "sidebar.workspaceSelect",
  });

  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { workspace: workspaceId } = useParams<{ workspace: string }>();

  const workspaceList = useAppSelector((state) => state.workspace.workspaces);

  const currentWorkspace = workspaceList.find((ws) => ws.id === workspaceId);

  const pathMatches = useMatches();

  const isOnMsspMode = pathMatches.some((match) =>
    msspPaths.some((path) => matchPath(path, match.pathname))
  );

  useEffect(() => {
    workspaceServices
      .list_workspaces()
      .then((wsResponse) => {
        if (wsResponse.length > 0) {
          dispatch(updateWorkspaceList(wsResponse));
        } else {
          navigate(pathname.NEW_WORKSPACE);
        }
      })
      .catch(apiErrorHandler);
    // "navigate" should not be in the dependency. It will trigger this useEffect every time we change url although we don't need to.
    // Best practice should be using "loaders" and "actions" and resolve this by using "redirect" instead of "navigate".
    // DO NOT TS-IGNORE THIS since it will make a pitfall for other dependencies.
  }, [dispatch]);

  return (
    <Dropdown
      menu={{
        items: (isOnMsspMode
          ? workspaceList.filter((ws) => ws.type === "mssp")
          : workspaceList
        ).map((ws) => ({
          key: ws.id,
          label: (
            <DropdownItemLabel
              selected={ws.id === workspaceId}
              syncWidthId="workspaceSelect"
            >
              <ObjectImage
                data={ws.logo}
                className="h-6 w-6 min-w-[1.5rem] rounded-md"
              />
              {ws.name}
            </DropdownItemLabel>
          ),
        })),
        onClick: ({ key }) => {
          dispatch(selectWorkspace(key));
          // Navigate on changing workspaces
          if (workspaceId !== key) {
            // TODO: navigate to current path but change the workspace param only. Currently we're settled with navigating to Domains (de facto Home) page since an user should not have to usually jump from one workspace to other.
            navigate(generatePath(pathname.DOMAINS, { workspace: key }));
          }
        },
      }}
      trigger={["click"]}
      placement="bottomLeft"
      dropdownRender={(menu) => {
        return (
          <div className="shadow-md rounded-md">
            {cloneElement(menu as ReactElement, {
              style: {
                boxShadow: "none",
                borderRadius: "0.25rem 0.25rem 0 0",
              },
            })}
            <Link
              className="rounded-b-md w-full flex items-center justify-start gap-2 p-3 bg-white text-primary border-t border-light-grey no-underline"
              to="/workspace/new"
            >
              <AddLine className="h-4 w-4" />
              <span className="font-medium-14">{t("addANewWorkspace")}</span>
            </Link>
          </div>
        );
      }}
    >
      <button
        id="workspaceSelect"
        // probably the way to go should be a default "px-3" and override with "px-6" when needed
        className={`relative w-full py-3 flex justify-between items-center gap-1 rounded-md outline ${
          lightMode
            ? "px-3 bg-bright-grey outline-2 outline-transparent hover:outline-light-grey text-hard-grey"
            : "px-6 hover:bg-grey-blue outline-1 outline-grey-blue"
        } duration-150`}
      >
        <div
          className="flex items-center gap-3 text-left"
          // Tailwind don't have this, so we have to use inline style to keep the spirit of Tailwind: manage everything inline
          style={{ wordBreak: "break-word" }}
        >
          <ObjectImage
            data={currentWorkspace?.logo}
            className="h-6 w-6 min-w-[1.5rem] rounded-md"
          />
          <span className="line-clamp-2">{currentWorkspace?.name}</span>
        </div>
        <ArrowDownSLine className="w-5 h-5 min-w-[1.25rem] min-h-[1.25rem]" />
      </button>
    </Dropdown>
  );
};

export default WorkspaceSelect;
