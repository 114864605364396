import type { ReactNode } from "react";

type MainCardColorType = "blue" | "green" | "orange" | "red";

// TODO: let user change the colors without this variant thing
const variants: {
  [Color in MainCardColorType]: { bg: string; marker: string };
} = {
  blue: {
    bg: "bg-[#D9EDFF]",
    marker: "bg-[#3C4EF8]",
  },
  red: {
    bg: "bg-[#FFE4E6]",
    marker: "bg-[#E45B59]",
  },
  orange: {
    bg: "bg-[#FFF1E5]",
    marker: "bg-[#EE9550]",
  },
  green: {
    bg: "bg-[#F6FFED]",
    marker: "bg-[#B3EC7A]",
  },
};

interface IOverviewMainCardProps {
  label: ReactNode;
  value: number | string | undefined;
  variant: MainCardColorType;
  className?:string;
}

// TODO: this is not consistent enough to be a reusable common component?
/** A colored card with big headline, a number and a small marker on top of the number
 * @param label - label of the card, or the headliner
 * @param value
 * @param variant - supports 4 colors: blue, green, orange and red
 */
const OverviewMainCard = ({
  label,
  value,
  variant = "blue",
  className,
}: IOverviewMainCardProps) => {
  return (
    <div
      className={`${className} ${variants[variant]["bg"]} min-w-[17rem] w-[20%] flex items-center justify-between px-6 rounded-md whitespace-pre-line`}
    >
      <span className="font-medium-20 py-9">{label}</span>
      <span className="relative h-full">
        {/* Marker. This should be placed on top of the value */}
        {value ? (
          <span
            className={`absolute -right-1 w-[calc(100%_+_0.5rem)] ${variants[variant]["marker"]} h-1.5 rounded-b-full`}
          />
        ) : null}
        <span className="h-full flex items-center font-bold-32 tracking-tight tabular-nums">
          {value}
        </span>
      </span>
    </div>
  );
};

export default OverviewMainCard;
