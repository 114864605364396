// Libraries
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

// Resources
import { ReactComponent as DeleteBin6Line } from "#src/assets/images/icons/delete-bin-6-line.svg";

// General
import type { IFilterItem } from "#src/@types/common";

// Components
import {
  FilterDropdown,
  SearchBox,
  SelectAllItemsDropdown,
} from "#src/components/common/Table/Filter";
import { IconButton } from "#src/components/common/system/Button";
import { apiErrorHandler } from "#src/utils/apiErrorHandler";

// API-related
import devicesServices from "#src/services/devices";
import tagsServices from "#src/services/tags";

interface IPolicyFilterProps {
  workspaceId: string;
  totalCount: number;
  selectedCount: number;
  host: IFilterItem | null;
  tag: IFilterItem | null;
  searchKeyword: string;
  onChangeHostSelection: (selected: IFilterItem | null) => void;
  onChangeTagSelection: (selected: IFilterItem | null) => void;
  onChangeSearchKeyword: (keyword: string) => void;
  onSelectAllPolicies: () => void;
  onDeselectAllPolicies: () => void;
  // onEnableSelectedPolicies: () => void;
  onDisableSelectedPolicies: () => void;
}

const PolicyFilter = ({
  workspaceId,
  totalCount,
  selectedCount,
  host,
  tag,
  searchKeyword,
  onChangeHostSelection,
  onChangeTagSelection,
  onChangeSearchKeyword,
  onSelectAllPolicies,
  onDeselectAllPolicies,
  // onEnableSelectedPolicies,
  onDisableSelectedPolicies,
}: IPolicyFilterProps) => {
  const { t } = useTranslation("devices", { keyPrefix: "policies.filter" });

  const [hostItems, setHostItems] = useState<IFilterItem[]>([]);
  const [tagItems, setTagItems] = useState<IFilterItem[]>([]);

  useEffect(() => {
    let ignore = false;

    if (!ignore) {
      const fetchHosts = () => {
        devicesServices
          .list_hosts_shortly(workspaceId)
          .then((res) => {
            setHostItems(
              res.map((item) => ({
                key: item.id.toString(),
                value: item.id.toString(),
                getLabel: () => item.name,
              }))
            );
          })
          .catch(apiErrorHandler);
      };
      fetchHosts();
    }

    return () => {
      ignore = true;
    };
  }, [workspaceId]);

  useEffect(() => {
    let ignore = false;

    if (!ignore) {
      const fetchTags = () => {
        tagsServices
          .list_workspace_tags_shortly(workspaceId)
          .then((res) => {
            setTagItems(
              res.map((item) => ({
                key: item.id.toString(),
                value: item.id.toString(),
                getLabel: () => item.name,
              }))
            );
          })
          .catch(apiErrorHandler);
      };
      fetchTags();
    }

    return () => {
      ignore = true;
    };
  }, [workspaceId]);

  return (
    <div className="flex justify-between gap-1">
      <div className="flex gap-1 max-w-[100%_-_15rem] whitespace-nowrap flex-wrap">
        <SelectAllItemsDropdown
          selectedCount={selectedCount}
          totalCount={totalCount}
          onSelectAll={onSelectAllPolicies}
          onDeselectAll={onDeselectAllPolicies}
        />
        {/* <IconButton
          variant="ghost"
          disabled={selectedCount === 0}
          onClick={() => {
            global.confirm(() => onDeleteSelectedPolicies());
          }}
        >
          <DeleteBin6Line />
        </IconButton> */}
        {/* <button
          className="h-11 rounded-md flex justify-center items-center text-hard-grey disabled:text-button-disabled"
          disabled={selectedCount === 0}
          onClick={() => {
            onEnableSelectedPolicies();
          }}
        >
          <span className="p-2">{t("button.enable")}</span>
        </button> */}
        <IconButton
          disabled={selectedCount === 0}
          onClick={() => {
            onDisableSelectedPolicies();
          }}
          variant="ghost"
        >
          <DeleteBin6Line />
        </IconButton>
        <FilterDropdown
          label={t("applyToTag")}
          selected={tag}
          options={tagItems}
          onChangeSelection={onChangeTagSelection}
        />
        <FilterDropdown
          label={t("applyToHost")}
          selected={host}
          options={hostItems}
          onChangeSelection={onChangeHostSelection}
        />
      </div>

      <SearchBox
        searchKeyword={searchKeyword}
        onChangeSearchKeyword={onChangeSearchKeyword}
      />
    </div>
  );
};

export default PolicyFilter;
