// Libraries
import { useTranslation } from "react-i18next";
import { generatePath, useNavigate, useParams } from "react-router-dom";
import {
  type ReactNode,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import { Tabs } from "@lockerpm/design";

// Resources
import { ReactComponent as EditLine } from "#src/assets/images/icons/edit-line.svg";
import { ReactComponent as ArrowGoBackLine } from "#src/assets/images/icons/arrow-go-back-line.svg";
import { ReactComponent as SaveLine } from "#src/assets/images/icons/save-line.svg";

// General
import global from "#src/config/global";
import { pathname } from "#src/config/pathname";

// Components
import { ContentSection, StickySection } from "#src/layouts/content";
import { Button } from "#src/components/common/system/Button";
import TabButtonLabel from "#src/components/common/helper/antdProps/Tabs/TabButtonLabel";
import { toPascalCase } from "#src/utils/common";
import { apiErrorHandler } from "#src/utils/apiErrorHandler";

// API-related
import bugBountyServices, {
  type IBugBountyDetails,
} from "#src/services/bugBounty";

// Children
import BugBountyDetailsProgramBrief, {
  type IBugBountyProgramBriefStates,
} from "#src/components/bug-bounty/details/ProgramBriefTab";
import {
  BugBountyDetailsTabEnum,
  BugBountyProgramTypeEnum,
} from "#src/components/bug-bounty/enum";
import BugBountyDetailsScope from "#src/components/bug-bounty/details/ScopeTab";
import BugBountyDetailsPolicy from "#src/components/bug-bounty/details/PolicyTab";
import BugBountyDetailsRequirements from "#src/components/bug-bounty/details/RequirementsTab";
import { bugBountyRewardTypeItems } from "#src/components/bug-bounty/AddBugBounty/RewardRange";
import BugBountyDetailsMembers from "#src/components/bug-bounty/details/MembersTab";
import BugBountyDetailsMssp from "#src/components/bug-bounty/details/MsspTab";
import BugBountyDetailsIntegration from "#src/components/bug-bounty/details/IntegrationTab";
import BugBountyDetailsBlocking from "#src/components/bug-bounty/details/BlockingTab";
import BugBountyDetailsAccessRequest from "#src/components/bug-bounty/details/AccessRequestTab";

const BugBountyDetails = () => {
  const { t } = useTranslation("bugBounty", {
    keyPrefix: "page.bugBountyDetails",
  });
  const { workspace: workspaceId, bugbounty: bugBountyAlias } = useParams<
    "workspace" | "bugbounty"
  >();

  const navigate = useNavigate();

  const briefRef = useRef<{ onSaveChanges: () => void }>(null);
  const requirementsRef = useRef<{
    onSaveChanges: () => void;
    onRevertChanges: () => void;
  }>(null);

  const [activeTab, setActiveTab] = useState<string>(
    BugBountyDetailsTabEnum.PROGRAM_BRIEF
  );

  const [bugBountyDetails, setBugBountyDetails] =
    useState<IBugBountyDetails | null>(null);
  const [programBriefStates, setProgramBriefStates] =
    useState<IBugBountyProgramBriefStates>({
      programType: BugBountyProgramTypeEnum.PUBLIC,
      briefName: "",
      alias: "",
      description: "",
      currency: "VND",
      rewardRange: [],
      status: "",
      // Magic string is just default color. Not anything particular
      color: "#e7e8ea",
      avatarUrl: null,
    });

  const [isEditing, setEditing] = useState<boolean>(false);

  const onChangeTab = (key: string) => {
    if (isEditing) {
      // TODO: customize this into a better common modal
      global.confirm(() => {
        setActiveTab(key);
        setEditing(false);
      });
    } else {
      setActiveTab(key);
    }
  };

  const getProgramBriefStatesFromDetails = (
    details: IBugBountyDetails
  ): IBugBountyProgramBriefStates => {
    return {
      programType: Object.keys(BugBountyProgramTypeEnum).includes(
        details.type.toUpperCase()
      )
        ? BugBountyProgramTypeEnum[
            details.type.toUpperCase() as keyof typeof BugBountyProgramTypeEnum
          ]
        : BugBountyProgramTypeEnum.PUBLIC,
      briefName: details.org.name,
      alias: details.alias,
      description: details.org.description.en,
      currency: details.currency.toUpperCase() === "VND" ? "VND" : "USD",
      rewardRange: details.reward
        // sort by point_value
        .sort((a, b) => a.point_value - b.point_value)
        .map((range) => ({
          type: bugBountyRewardTypeItems.find(
            (item) => item.value === range.type
          ) || {
            key: range.type,
            value: range.type,
            getLabel: () => toPascalCase(range.type),
          },
          from: range.range_from,
          to: range.range_to,
          severity: range.severity.toUpperCase(),
          point: range.point_value,
        })),
      status: details.status,
      color: details.org.color,
      avatarUrl: details.org.logo,
    };
  };

  const fetchBugBountyDetails = useCallback(() => {
    if (workspaceId && bugBountyAlias) {
      bugBountyServices
        .retrieve_program_details(workspaceId, bugBountyAlias)
        .then((response) => {
          if (Object.keys(response).includes("redirect_alias")) {
            navigate(
              generatePath(pathname.BUG_BOUNTY_DETAILS, {
                workspace: workspaceId,
                bugbounty: (response as { redirect_alias: string })
                  .redirect_alias,
              }),
              { replace: true }
            );
          } else {
            const assertedResponse = response as IBugBountyDetails;
            setBugBountyDetails(assertedResponse);
            setProgramBriefStates(
              getProgramBriefStatesFromDetails(assertedResponse)
            );
          }
        })
        .catch((error) => {
          apiErrorHandler(error, {
            toastMessage: t("notification.loadBugBounty.fail"),
          });
        });
    }
  }, [workspaceId, bugBountyAlias, t, navigate]);

  useEffect(() => {
    let ignore = false;

    if (!ignore) {
      fetchBugBountyDetails();
    }

    return () => {
      ignore = true;
    };
  }, [fetchBugBountyDetails]);

  const tabList: { key: string; label: ReactNode; children: ReactNode }[] =
    bugBountyDetails && workspaceId && bugBountyAlias
      ? [
          {
            key: BugBountyDetailsTabEnum.PROGRAM_BRIEF,
            label: <TabButtonLabel name={t("tab.programBrief.title")} />,
            children: (
              <BugBountyDetailsProgramBrief
                ref={briefRef}
                workspaceId={workspaceId}
                bugBountyAlias={bugBountyAlias}
                bugBountyDetails={bugBountyDetails}
                programBriefStates={programBriefStates}
                setProgramBriefStates={setProgramBriefStates}
                isEditing={isEditing}
                onRefresh={fetchBugBountyDetails}
              />
            ),
          },
          {
            key: BugBountyDetailsTabEnum.SCOPE,
            label: <TabButtonLabel name={t("tab.scope.title")} />,
            children: (
              <BugBountyDetailsScope
                workspaceId={workspaceId}
                bugBountyAlias={bugBountyAlias}
                bugBountyDetails={bugBountyDetails}
              />
            ),
          },
          {
            key: BugBountyDetailsTabEnum.POLICY,
            label: <TabButtonLabel name={t("tab.policy.title")} />,
            children: (
              <BugBountyDetailsPolicy
                workspaceId={workspaceId}
                bugBountyAlias={bugBountyAlias}
                bugBountyDetails={bugBountyDetails}
                onRefresh={fetchBugBountyDetails}
              />
            ),
          },
          {
            key: BugBountyDetailsTabEnum.REQUIREMENTS,
            label: <TabButtonLabel name={t("tab.requirements.title")} />,
            children: (
              <BugBountyDetailsRequirements
                ref={requirementsRef}
                workspaceId={workspaceId}
                bugBountyAlias={bugBountyAlias}
                isEditing={isEditing}
                onRefresh={fetchBugBountyDetails}
              />
            ),
          },
          {
            key: BugBountyDetailsTabEnum.MSSP,
            label: <TabButtonLabel name={t("tab.mssp.title")} />,
            children: (
              <BugBountyDetailsMssp
                workspaceId={workspaceId}
                bugBountyAlias={bugBountyAlias}
                bugBountyStatus={bugBountyDetails.status}
              />
            ),
          },
          {
            key: BugBountyDetailsTabEnum.MEMBERS,
            label: <TabButtonLabel name={t("tab.members.title")} />,
            children: (
              <BugBountyDetailsMembers
                workspaceId={workspaceId}
                bugBountyAlias={bugBountyAlias}
              />
            ),
          },
          {
            key: BugBountyDetailsTabEnum.ACCESS_REQUESTS,
            label: <TabButtonLabel name={t("tab.accessRequests.title")} />,
            children: (
              <BugBountyDetailsAccessRequest
                workspaceId={workspaceId}
                bugBountyAlias={bugBountyAlias}
              />
            ),
          },
          {
            key: BugBountyDetailsTabEnum.INTEGRATIONS,
            label: <TabButtonLabel name={t("tab.integrations.title")} />,
            children: (
              <BugBountyDetailsIntegration
                workspaceId={workspaceId}
                bugBountyAlias={bugBountyAlias}
              />
            ),
          },
          {
            key: BugBountyDetailsTabEnum.BLOCKING,
            label: <TabButtonLabel name={t("tab.blocking.title")} />,
            children: (
              <BugBountyDetailsBlocking
                workspaceId={workspaceId}
                bugBountyAlias={bugBountyAlias}
              />
            ),
          },
        ]
      : [];

  return workspaceId && bugBountyAlias && bugBountyDetails ? (
    <>
      <StickySection>
        <h1 className="break-all">
          {bugBountyDetails.org.name
            ? bugBountyDetails.org.name
            : bugBountyDetails.alias}
        </h1>
        {[
          BugBountyDetailsTabEnum.PROGRAM_BRIEF,
          BugBountyDetailsTabEnum.REQUIREMENTS,
        ].includes(activeTab) ? (
          isEditing ? (
            <div className="flex gap-1">
              <Button
                variant="secondary"
                size="large"
                onClick={() => {
                  if (activeTab === BugBountyDetailsTabEnum.PROGRAM_BRIEF) {
                    setProgramBriefStates(
                      getProgramBriefStatesFromDetails(bugBountyDetails)
                    );
                  }
                  if (activeTab === BugBountyDetailsTabEnum.REQUIREMENTS) {
                    requirementsRef.current?.onRevertChanges();
                  }
                  setEditing(false);
                }}
              >
                <ArrowGoBackLine height={"1.25rem"} width={"1.25rem"} />
                {t("button.revertAllChanges")}
              </Button>
              <Button
                size="large"
                onClick={() => {
                  if (activeTab === BugBountyDetailsTabEnum.PROGRAM_BRIEF) {
                    briefRef.current?.onSaveChanges();
                  }
                  if (activeTab === BugBountyDetailsTabEnum.REQUIREMENTS) {
                    requirementsRef.current?.onSaveChanges();
                  }
                  setEditing(false);
                }}
              >
                <SaveLine height={"1.25rem"} width={"1.25rem"} />
                {t("button.saveChanges")}
              </Button>
            </div>
          ) : (
            <Button
              size="large"
              onClick={() => {
                setEditing(true);
              }}
            >
              <EditLine height={"1.25rem"} width={"1.25rem"} />
              {t("button.updateProgram")}
            </Button>
          )
        ) : null}
      </StickySection>
      <ContentSection>
        <Tabs
          items={tabList}
          activeKey={activeTab}
          onChange={onChangeTab}
          destroyInactiveTabPane
        />
      </ContentSection>
    </>
  ) : null;
};

export default BugBountyDetails;
