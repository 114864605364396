// Libraries
import { Drawer } from "@lockerpm/design";
import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";

// Resources
import { ReactComponent as CloseLine } from "#src/assets/images/icons/close-line.svg";

// General
import type { IDrawerAdapterProps } from "#src/@types/common";

// Components
import { Button, IconButton } from "#src/components/common/system/Button";
import { TextArea } from "#src/components/common/system/Input";
import { TagEditor } from "#src/components/common/Tags/TagEditor";
import { apiErrorHandler } from "#src/utils/apiErrorHandler";

// API-related
import devicesServices from "#src/services/devices";
import tagsServices from "#src/services/tags";
import { TagTypeValue } from "#src/config/filter/tag/value";

// Children
import SoftwareInput from "../common/SoftwareInput";
import HostInput from "../common/HostInput";

interface IEditControlRestrictionDrawer extends IDrawerAdapterProps {
  editingItemId: string | null;
}

const EditControlRestrictionDrawer = ({
  open,
  onClose,
  workspaceId,
  onRefresh,
  editingItemId,
}: IEditControlRestrictionDrawer) => {
  const { t } = useTranslation("devices", {
    keyPrefix: "control.drawers.editRestrictionControl",
  });

  // const controlActionItems = useMemo(
  //   () => [
  //     {
  //       key: "controlAction-block",
  //       value: "block",
  //       label: t("field.action.block"),
  //     },
  //     {
  //       key: "controlAction-allow",
  //       value: "allow",
  //       label: t("field.action.allow"),
  //     },
  //   ],
  //   [t]
  // );

  const [selectedSoftware, setSelectedSoftware] = useState<{
    key: string;
    value: string;
    label: string;
  } | null>(null);
  // const [controlAction, setControlAction] = useState<{
  //   key: string;
  //   value: string;
  //   label: string;
  // }>(controlActionItems[0]);
  const [selectedHosts, setSelectedHosts] = useState<
    { key: string; id: string; name: string; label: string }[]
  >([]);
  const [tagList, setTagList] = useState<{ name: string }[]>([]);
  const [note, setNote] = useState<string>("");

  const [hostIdCounter, setHostIdCounter] = useState(0);

  const [tagItems, setTagItems] = useState<
    { key: string; value: string; label: string }[]
  >([]);
  // TODO: is this necessary?

  const onChangeSoftware = (value: {
    key: string;
    value: string;
    label: string;
  }) => {
    setSelectedSoftware(value);
  };

  const onAddDefaultHost = () => {
    setHostIdCounter((prev) => prev + 1);
    setSelectedHosts((prev) => [
      ...prev,
      {
        key: (hostIdCounter + 1).toString(),
        id: "",
        name: "",
        label: "",
      },
    ]);
  };

  const onChangeHost = (newHost: {
    key: string;
    id: string;
    name: string;
    label: string;
  }) => {
    setSelectedHosts((prev) => {
      const changingHost = prev.findIndex((item) => item.key === newHost.key);
      let newList = prev;
      newList[changingHost] = newHost;
      return [...newList];
    });
  };

  const onRemoveHost = (removedHost: {
    key: string;
    id: string;
    name: string;
    label: string;
  }) => {
    setSelectedHosts((prev) => {
      return prev.filter((host) => host.key !== removedHost.key);
    });
  };

  const onChangeTags = (tags: { name: string }[]) => {
    setTagList(tags);
  };

  const onChangeNote = (value: string) => {
    setNote(value);
  };

  const onEditControlRestriction = () => {
    if (selectedSoftware && editingItemId) {
      devicesServices
        .edit_control(workspaceId, editingItemId, {
          // action:
          //   controlAction.value === "block"
          //     ? "block_file"
          //     : controlAction.value === "allow"
          //     ? "allow_file"
          //     : "",
          description: note,
          matches: [selectedSoftware.value],
          hosts_tags: [
            ...selectedHosts.map((host) => ({
              id: host.id,
              type: "host",
            })),
            ...tagList
              .map((tag) => {
                const tagId = tagItems.find(
                  (item) => item.label === tag.name
                )?.value;
                return {
                  id: typeof tagId === "string" ? tagId : "-1",
                  type: "tag",
                };
              })
              .filter((tag) => tag.id !== "-1"),
          ],
        })
        .then((response) => {
          if (response.id) {
            onRefresh();
            onCloseAndCleanup();
          }
        })
        .catch((error) => {
          apiErrorHandler(error, {
            toastMessage: t("notification.editControl.fail"),
          });
        });
    }
  };

  const onCleanup = () => {
    setSelectedSoftware(null);
    // setControlAction(controlActionItems[0]);
    setSelectedHosts([]);
    setTagList([]);
    setNote("");
    setHostIdCounter(0);
  };

  const onCloseAndCleanup = () => {
    onCleanup();
    onClose();
  };

  useEffect(() => {
    let ignore = false;

    if (!ignore) {
      tagsServices
        .list_workspace_tags_shortly(workspaceId)
        .then((response) => {
          setTagItems(
            response.map((item) => ({
              key: item.id.toString(),
              value: item.id.toString(),
              label: item.name,
            }))
          );
        })
        .catch((error) => {
          apiErrorHandler(error, {
            toastMessage: t("notification.loadTagList.fail"),
          });
        });
    }

    return () => {
      ignore = true;
    };
  }, [workspaceId, t]);

  useEffect(() => {
    let ignore = false;

    if (!ignore && editingItemId) {
      devicesServices
        .get_control_details(workspaceId, editingItemId)
        .then((response) => {
          setSelectedSoftware({
            key: "",
            value: response.matches[0],
            label: response.matches[0],
          });
          // if (response.action === "block_network") {
          //   const initControlAction = controlActionItems.find(
          //     (item) => item.value === "block"
          //   );
          //   if (initControlAction) {
          //     setControlAction(initControlAction);
          //   }
          // }
          // if (response.action === "allow_network") {
          //   const initControlAction = controlActionItems.find(
          //     (item) => item.value === "allow"
          //   );
          //   if (initControlAction) {
          //     setControlAction(initControlAction);
          //   }
          // }
          setSelectedHosts(
            response.hosts_tags
              .filter((item) => item.type === "host")
              .map((host) => ({
                key: host.id.toString(),
                id: host.id.toString(),
                name: host.name,
                label: host.name,
              }))
          );
          setTagList(
            response.hosts_tags
              .filter((item) => item.type === "tag")
              .map((tag) => ({
                name: tag.name,
              }))
          );
          setNote(response.description);
        });
    }

    return () => {
      ignore = true;
    };
  }, [workspaceId, editingItemId]);

  return (
    <Drawer
      open={open}
      onClose={onCloseAndCleanup}
      closeIcon={null}
      title={<h2>{t("title")}</h2>}
      extra={
        <IconButton variant="label-grey" size={9} onClick={onCloseAndCleanup}>
          <CloseLine />
        </IconButton>
      }
      footer={
        <div className="flex flex-col gap-3">
          <Button
            className="w-full"
            size="large"
            onClick={onEditControlRestriction}
          >
            {t("button.saveChanges")}
          </Button>
          <Button
            variant="secondary"
            className="w-full"
            size="large"
            onClick={onCloseAndCleanup}
          >
            {t("button.cancel")}
          </Button>
        </div>
      }
    >
      <div className="p-6 flex flex-col gap-6">
        <div className="flex flex-col gap-3">
          <h4>{t("field.software.label")}</h4>
          <SoftwareInput
            workspaceId={workspaceId}
            selectedSoftware={selectedSoftware}
            onChangeSoftware={onChangeSoftware}
          />
        </div>
        {/* <div className="flex flex-col gap-3">
        <h4>
          {t("field.action.label")}
        </h4>
        <Dropdown
          menu={{
            items: controlActionItems.map((item) => ({
              key: item.key,
              label: (
                <DropdownItemLabel selected={controlAction.key === item.key}>
                  {item.label}
                </DropdownItemLabel>
              ),
            })),
            onClick: ({ key }) => {
              onSelectControlAction(key);
            },
          }}
          trigger={["click"]}
        >
          <button className="flex gap-2 items-center justify-between px-4 py-3 bg-bright-grey text-hard-grey rounded-md disabled:bg-button-disabled disabled:text-medium-grey">
            {controlAction.label}
            <ArrowDownSLine />
          </button>
        </Dropdown>
      </div> */}
        <div className="flex flex-col gap-3">
          <h4>{t("field.applyToTags.label")}</h4>
          <TagEditor
            workspaceId={workspaceId}
            tagList={tagList}
            onChangeTags={onChangeTags}
            type={TagTypeValue.HOST}
          />
        </div>
        <div className="flex flex-col gap-3">
          <h4>{t("field.applyToHostName.label")}</h4>
          {selectedHosts.map((host, index) => (
            <HostInput
              key={`devices/control/newControl-restrictionTab-hostInput-${host}-${index}`}
              workspaceId={workspaceId}
              selectedHost={host}
              onChangeHost={onChangeHost}
              onRemoveHost={onRemoveHost}
              autofocus={!host.id}
            />
          ))}
          <button
            className="px-4 py-3 bg-bright-grey text-medium-grey rounded-md text-left"
            onClick={(e) => {
              e.stopPropagation();
              onAddDefaultHost();
            }}
          >
            {t("field.applyToHostName.search.placeholder")}
          </button>
        </div>
        <div className="flex flex-col gap-3">
          <h4>{t("field.notes.label")}</h4>
          <TextArea
            placeholder={t("field.notes.placeholder")}
            value={note}
            onChange={(e) => {
              onChangeNote(e.currentTarget.value);
            }}
          />
        </div>
      </div>
    </Drawer>
  );
};

export default EditControlRestrictionDrawer;
