// Libraries
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import { useTranslation } from "react-i18next";
import DOMPurify from "dompurify";
import { marked } from "marked";
import { useLayoutEffect, useRef } from "react";
import { createSearchParams, useSearchParams } from "react-router-dom";

// Resources
import { ReactComponent as TimeLine } from "#src/assets/images/icons/time-line.svg";
import { ReactComponent as ShareLine } from "#src/assets/images/icons/share-line.svg";
import { ReactComponent as UserLine } from "#src/assets/images/icons/user-line.svg";

// General
import { TAILWIND_COLORS } from "#src/utils/common";

// Components
import { ObjectImage } from "#src/components/common/system/Object";
import { SeverityLabel } from "#src/components/common/labels";
import { createToast } from "#src/components/common/system/toasts";

// API-related
import type { IVulnerabilityComment } from "#src/services/vulnerabilities/comments";

dayjs.extend(relativeTime);

const VulnerabilityCommentItem = ({
  comment,
  currentLanguage,
}: {
  comment: IVulnerabilityComment;
  currentLanguage: string;
}) => {
  const { t } = useTranslation("vulnerabilities", {
    keyPrefix: "detailSection",
  });

  const { t: filterTFunc } = useTranslation("translation");

  const statusLabelTemplate = (name: string, color: string) => {
    return `<span style="display: flex; align-items: center; gap: 0.25rem; margin-left: 0.75rem; margin-right: 0.75rem; font-weight: 500;"><div style="height: 0.5rem; width: 0.5rem; background-color: ${color}; border-radius: 0.25rem;"></div>${name}</span>`;
  };

  const styledLabels = {
    New: statusLabelTemplate(
      filterTFunc("filter.vulnerabilityStatus.new"),
      TAILWIND_COLORS["sky"]
    ),
    Triaged: statusLabelTemplate(
      filterTFunc("filter.vulnerabilityStatus.triaged"),
      TAILWIND_COLORS["blue"]
    ),
    Resolved: statusLabelTemplate(
      filterTFunc("filter.vulnerabilityStatus.resolved"),
      TAILWIND_COLORS["green"]
    ),
    Unresolved: statusLabelTemplate(
      filterTFunc("filter.vulnerabilityStatus.unresolved"),
      TAILWIND_COLORS["wine"]
    ),
    Duplicate: statusLabelTemplate(
      filterTFunc("filter.vulnerabilityStatus.duplicate"),
      TAILWIND_COLORS["fire"]
    ),
    "Out of scope": statusLabelTemplate(
      filterTFunc("filter.vulnerabilityStatus.outOfScope"),
      TAILWIND_COLORS["medium-grey"]
    ),
    "Not reproducible": statusLabelTemplate(
      filterTFunc("filter.vulnerabilityStatus.notReproducible"),
      TAILWIND_COLORS["medium-grey"]
    ),
    "Wont fix": statusLabelTemplate(
      filterTFunc("filter.vulnerabilityStatus.wontFix"),
      TAILWIND_COLORS["medium-grey"]
    ),
    "Not applicable": statusLabelTemplate(
      filterTFunc("filter.vulnerabilityStatus.notApplicable"),
      TAILWIND_COLORS["medium-grey"]
    ),
    Spam: statusLabelTemplate(
      filterTFunc("filter.vulnerabilityStatus.spam"),
      TAILWIND_COLORS["medium-grey"]
    ),
    CRITICAL: SeverityLabel.spanAsString({ severity: "CRITICAL" }),
    HIGH: SeverityLabel.spanAsString({ severity: "HIGH" }),
    MEDIUM: SeverityLabel.spanAsString({ severity: "MEDIUM" }),
    LOW: SeverityLabel.spanAsString({ severity: "LOW" }),
    INFORMATION: SeverityLabel.spanAsString({ severity: "INFORMATION" }),
    NONE: SeverityLabel.spanAsString({ severity: "NONE" }),
  };

  const getCommentNotification = (comment: IVulnerabilityComment) => {
    if (comment.comment_type === "chat") return "added a comment";
    if (comment.comment_type === "note") return "added a note";
    if (comment.comment_type === "notification") {
      // If current language is available
      if (Object.keys(comment.notification).includes(currentLanguage)) {
        return comment.notification[currentLanguage];
      }
      if (comment.notification["en"]) {
        return comment.notification["en"];
      }
      return Object.values(comment.notification)[0];
    }
    console.log("unidentified comment type", comment.id);

    return "";
  };

  const aggregateLabelToNotification = (notification: string) => {
    return notification
      .split("`")
      .map((item, index) => {
        if (index % 2 === 0 || index === notification.split("`").length - 1) {
          return item;
        } else {
          if (Object.keys(styledLabels).includes(item)) {
            return styledLabels[item as keyof typeof styledLabels];
          } else {
            return item;
          }
        }
      })
      .join("");
  };

  const [searchParams] = useSearchParams();

  const commentRef = useRef<HTMLDivElement>(null);

  useLayoutEffect(() => {
    if (comment.id.toString() === searchParams.get("comment_id")) {
      commentRef.current?.scrollIntoView();
    }
  }, [comment.id, searchParams]);

  return (
    <div
      ref={commentRef}
      className="py-6 border-b border-light-grey flex flex-col gap-3"
    >
      <div className="flex items-center gap-6">
        <div className="flex items-center gap-3">
          <ObjectImage
            data={comment.user.avatar}
            className="h-11 w-11 rounded-full"
          >
            <div className="w-11 h-11 flex items-center justify-center bg-light-grey rounded-full">
              <UserLine className="h-7 w-7 fill-medium-grey" />
            </div>
          </ObjectImage>
          <div className="flex flex-col gap-1">
            <span className="text-primary whitespace-nowrap">
              {comment.user.full_name}
            </span>
            <div className="flex items-center gap-1 font-regular-12 text-hard-grey">
              <TimeLine className="h-3.5 w-3.5" />
              {dayjs.unix(comment.created_time).fromNow()}
            </div>
          </div>
        </div>
        <div
          className={`px-3 py-1.5 font-medium-14 ${
            comment.is_owner_comment ? "bg-[#43FFF03D]" : "bg-[#FFD54D3D]"
          } rounded-md whitespace-nowrap`}
        >
          {comment.is_owner_comment
            ? comment.program_org.name
            : t("researcher")}
        </div>
        <span
          className="font-regular-14 text-hard-grey inline-flex flex-wrap items-center"
          dangerouslySetInnerHTML={{
            __html: DOMPurify.sanitize(
              marked.parseInline(
                aggregateLabelToNotification(getCommentNotification(comment))
              ) as string
              // https://github.com/markedjs/marked/issues/3101 Marked cannot tell Typescript the default option async false. Last update 17/01/2024
            ),
          }}
        ></span>
      </div>
      {comment.comment_type === "note" && comment.is_owner_comment ? (
        <div
          className={"bg-note flex-1 p-4 ml-16 rounded-md"}
          style={{ wordBreak: "break-word" }}
          dangerouslySetInnerHTML={{
            __html: DOMPurify.sanitize(
              marked.parse(comment.message) as string
              // https://github.com/markedjs/marked/issues/3101 Marked cannot tell Typescript the default option async false. Last update 17/01/2024
            ),
          }}
        ></div>
      ) : null}
      {comment.comment_type === "chat" ? (
        <div className="ml-16 flex items-end gap-4">
          <div
            className={"bg-label-blue flex-1 p-4 rounded-md"}
            style={{ wordBreak: "break-word" }}
            dangerouslySetInnerHTML={{
              __html: DOMPurify.sanitize(
                marked.parse(comment.message) as string
                // https://github.com/markedjs/marked/issues/3101 Marked cannot tell Typescript the default option async false. Last update 17/01/2024
              ),
            }}
          ></div>
          <button
            className="h-9 w-9 flex items-center justify-center bg-button-secondary-hover rounded-full hover:bg-primary hover:text-white transition-colors duration-150"
            onClick={() => {
              const commentLink =
                new URL(window.location.origin + window.location.pathname) +
                "?" +
                createSearchParams({
                  vuln_id: searchParams.get("vuln_id") ?? "",
                  comment_id: comment.id.toString(),
                }).toString();

              navigator.clipboard.writeText(commentLink);
              createToast({
                type: "info",
                message: "Copied comment URL to your clipboard!",
                detail: commentLink,
              });
            }}
          >
            <ShareLine className="h-5 w-5" />
          </button>
        </div>
      ) : null}
    </div>
  );
};

export default VulnerabilityCommentItem;
