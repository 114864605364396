// Libraries
import { useState } from "react";
import { useTranslation } from "react-i18next";

// Components
import { apiErrorHandler } from "#src/utils/apiErrorHandler";
import { generateKeyString } from "#src/utils/common";

// API-related
import bugBountyServices, {
  type IBugBountyDetails,
} from "#src/services/bugBounty";

// Children
import BugBountyDetailsScopeTable from "./Table";
import type { IBugBountyScopeInputItem } from "../../AddBugBounty/Scope";
import {
  pentestScopeScopeItems,
  pentestScopeTypeItems,
} from "#src/config/filter/pentest";

const targetToScopeEditItem = (
  target: IBugBountyDetails["target"][number]
): IBugBountyScopeInputItem => {
  return {
    id: target.id.toString(),
    assetType: pentestScopeTypeItems.find(
      (item) => item.value === target.type
    ) ?? {
      key: target.type,
      value: target.type,
      getLabel: () => target.type,
    },
    assets: target.name,
    scope: pentestScopeScopeItems.find(
      (item) => item.value === target.scope
    ) ?? {
      key: target.scope,
      value: target.scope,
      getLabel: () => target.scope,
    },
    editing: false,
  };
};

interface IBugBountyDetailsScopeProps {
  workspaceId: string;
  bugBountyAlias: string;
  bugBountyDetails: IBugBountyDetails;
}

const BugBountyDetailsScope = ({
  workspaceId,
  bugBountyAlias,
  bugBountyDetails,
}: IBugBountyDetailsScopeProps) => {
  const { t } = useTranslation("bugBounty", {
    keyPrefix: "page.bugBountyDetails.tab.scope",
  });

  const [savedScope, setSavedScope] = useState<
    (Omit<IBugBountyDetails["target"][number], "id"> & { id: string })[]
  >(
    bugBountyDetails.target.map((item) => ({ ...item, id: item.id.toString() }))
  );
  const [displayedScope, setDisplayedScope] = useState<
    IBugBountyScopeInputItem[]
  >(bugBountyDetails.target.map(targetToScopeEditItem));

  // Check if there are any scopes with empty asset names
  const [invalidAssets, setInvalidAssets] = useState<boolean>(false);

  // An array contains id of scope item that is loading (updating)
  const [loadingScope, setLoadingScope] = useState<string[]>([]);

  // This should also submit the change
  const deactivateEditScope = (id: string) => {
    const currentItem = displayedScope.find((item) => item.id === id);

    if (!currentItem) {
      console.log(`Can't find scope id: ${id}. Dumb bug.`);
      return;
    }

    // Validate. Here it's just "not empty".
    if (!currentItem.assets) {
      setInvalidAssets(true);
      return;
    }

    setLoadingScope((prev) => [...prev, id]);

    bugBountyServices
      .update_program(workspaceId, bugBountyAlias, {
        alias: bugBountyDetails.alias,
        name: bugBountyDetails.org.name,
        type: bugBountyDetails.type,
        metadata: bugBountyDetails.metadata,
        target: savedScope.map((item) => item.id).includes(id)
          ? savedScope.map((item) =>
              item.id === currentItem.id
                ? {
                    ...item,
                    name: currentItem.assets,
                    scope: currentItem.scope.value ?? item.scope,
                    type: currentItem.assetType.value ?? item.type,
                  }
                : item
            )
          : [
              ...savedScope,
              {
                id: currentItem.id,
                name: currentItem.assets,
                scope: currentItem.scope.value ?? "",
                type: currentItem.assetType.value ?? "",
                link_download: "",
                asset_id: null,
              },
            ],
      })
      .then((response) => {
        if (response.success) {
          // TODO: refetch to make sure it syncs
          setDisplayedScope((prev) => {
            const newArr = prev.map((item) =>
              item.id === id ? { ...item, editing: false } : item
            );

            return newArr;
          });
          setSavedScope((prev) =>
            prev.map((item) => item.id).includes(id)
              ? prev.map((item) =>
                  item.id === currentItem.id
                    ? {
                        ...item,
                        name: currentItem.assets,
                        scope: currentItem.scope.value ?? item.scope,
                        type: currentItem.assetType.value ?? item.type,
                      }
                    : item
                )
              : [
                  ...prev,
                  {
                    id: currentItem.id,
                    name: currentItem.assets,
                    scope: currentItem.scope.value ?? "",
                    type: currentItem.assetType.value ?? "",
                    link_download: "",
                    asset_id: null,
                  },
                ]
          );
          setLoadingScope((prev) => prev.filter((item) => item !== id));
        }
      })
      .catch((error) => {
        setLoadingScope((prev) => prev.filter((item) => item !== id));
        apiErrorHandler(error, {
          toastMessage: t("notification.updateScope.fail"),
        });
      });
  };

  const activateEditScope = (id: string) => {
    setDisplayedScope((prev) => {
      const newArr = prev.map((item) =>
        item.id === id ? { ...item, editing: true } : item
      );

      return newArr;
    });
  };

  const addScope = () => {
    setDisplayedScope((prev) => [
      ...prev,
      {
        id: generateKeyString(),
        assetType: pentestScopeTypeItems[0],
        assets: "",
        scope: pentestScopeScopeItems[0],
        editing: true,
      },
    ]);
  };

  const removeScope = (id: string) => {
    const currentItem = displayedScope.find((item) => item.id === id);

    if (!currentItem) {
      console.log(`Unmatched id. Can't find scope id: ${id}. Dumb bug.`);
      return;
    }

    setInvalidAssets(false);

    setLoadingScope((prev) => [...prev, id]);
    if (savedScope.map((item) => item.id).includes(id)) {
      bugBountyServices
        .update_program(workspaceId, bugBountyAlias, {
          alias: bugBountyDetails.alias,
          name: bugBountyDetails.org.name,
          type: bugBountyDetails.type,
          metadata: bugBountyDetails.metadata,
          target: savedScope.map((item) =>
            item.id === currentItem.id
              ? {
                  ...item,
                  name: currentItem.assets,
                  scope: currentItem.scope.value ?? item.scope,
                  type: currentItem.assetType.value ?? item.type,
                }
              : item
          ),
        })
        .then((response) => {
          if (response.success) {
            // TODO: refetch to make sure it syncs
            setDisplayedScope((prev) => prev.filter((item) => item.id !== id));
            setSavedScope((prev) => prev.filter((item) => item.id !== id));
            setLoadingScope((prev) => prev.filter((item) => item !== id));
          }
        });
    } else {
      setDisplayedScope((prev) => prev.filter((item) => item.id !== id));
    }
  };

  const onChangeScopeAssetType = (id: string, key: string) => {
    const selected = pentestScopeTypeItems.find((item) => item.key === key);

    if (selected) {
      setDisplayedScope((prev) =>
        prev.map((item) =>
          item.id === id ? { ...item, assetType: selected } : item
        )
      );
    }
  };

  const onChangeScopeAssets = (id: string, value: string) => {
    if (invalidAssets) {
      setInvalidAssets(false);
    }

    setDisplayedScope((prev) =>
      prev.map((item) => (item.id === id ? { ...item, assets: value } : item))
    );
  };

  const onChangeScopeScope = (id: string, key: string) => {
    const selected = pentestScopeScopeItems.find((item) => item.key === key);

    if (selected) {
      setDisplayedScope((prev) =>
        prev.map((item) =>
          item.id === id ? { ...item, scope: selected } : item
        )
      );
    }
  };

  return (
    <section className="flex flex-col gap-6 pt-6 w-2/3">
      <div className="flex flex-col gap-4">
        <h2>{t("title")}</h2>
        <span className="text-hard-grey">{t("info")}</span>
      </div>
      <BugBountyDetailsScopeTable
        scope={displayedScope}
        invalidAssets={invalidAssets}
        loadingScope={loadingScope}
        activateEditScope={activateEditScope}
        deactivateEditScope={deactivateEditScope}
        addScope={addScope}
        removeScope={removeScope}
        onChangeScopeAssetType={onChangeScopeAssetType}
        onChangeScopeAssets={onChangeScopeAssets}
        onChangeScopeScope={onChangeScopeScope}
      />
    </section>
  );
};

export default BugBountyDetailsScope;
