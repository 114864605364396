// Libraries
import { Drawer } from "@lockerpm/design";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

// Resources
import { ReactComponent as CloseLine } from "#src/assets/images/icons/close-line.svg";

// General
import type { IDrawerAdapterProps } from "#src/@types/common";

// Components
import { Button, IconButton } from "#src/components/common/system/Button";
import { TagEditor } from "#src/components/common/Tags/TagEditor";
import { Accordion } from "#src/components/common/library/Accordion";
import {
  AccordionHeading3,
  AccordionHeading4,
} from "#src/components/common/helper/wrapper/AccordionHeader";
import { createToast } from "#src/components/common/system/toasts";
import { apiErrorHandler } from "#src/utils/apiErrorHandler";

// API-related
import assetsServices, { type IAssetDetailItem } from "#src/services/assets";
import { TagTypeValue } from "#src/config/filter/tag/value";

// Children
import { EditCustomHeaders } from "./EditCustomHeaders";
import { EditAuthentication } from "./EditAuthentication";

enum PanelKeys {
  TAGS = "tags",
  SCAN_PROFILE = "scanProfile",
  AUTHENTICATION = "scanProfile-authentication",
  USE_CYSTACK_USER_AGENT = "scanProfile-useCystackUserAgent",
  CUSTOM_HEADERS = "scanProfile-customHeaders",
}

const FooterButtons = ({
  onSave,
  onCancel,
  pending,
}: {
  onSave: () => void;
  onCancel: () => void;
  pending: boolean;
}) => {
  const { t } = useTranslation("assets", {
    keyPrefix: "domains.drawer.editSettings",
  });

  return (
    <div className="flex flex-col gap-3">
      <Button
        className="w-full"
        size="large"
        pending={pending}
        onClick={onSave}
      >
        {t("button.save")}
      </Button>
      <Button
        variant="secondary"
        className="w-full"
        size="large"
        onClick={() => {
          createToast({
            type: "info",
            message: t("notification.cancel.message"),
            detail: t("notification.cancel.description"),
          });
          onCancel();
        }}
      >
        {t("button.cancel")}
      </Button>
    </div>
  );
};

interface IEditSettingsDrawerProps extends IDrawerAdapterProps {
  selectedItemId: number | null;
}

const EditSettingsDrawer = ({
  workspaceId,
  open,
  onClose,
  onRefresh,
  selectedItemId,
}: IEditSettingsDrawerProps) => {
  const { t } = useTranslation("assets", {
    keyPrefix: "domains.drawer.editSettings",
  });

  const [editingItem, setEditingItem] = useState<IAssetDetailItem | null>(null);
  // TODO: use this hook to refactor
  // const {
  //   original: originalAsset,
  //   state: assetDetails,
  //   updateOriginal: updateOriginalAsset,
  //   onChange: onChangeAssetDetails,
  // } = useEditState<IAssetDetailItem>();
  const [activeAuthPanel, setActiveAuthPanel] = useState<string[]>([]);

  const [pending, setPending] = useState<boolean>(false);

  const onChangeTags = (tags: { name: string }[]) => {
    setEditingItem((prev) => {
      if (prev === null) return null;
      return { ...prev, tags };
    });
  };

  const onChangeAuthentication = ({
    method,
    authSettings,
  }: {
    method: "cookie" | "basic" | null;
    authSettings: {
      cookie: string | null;
      username: string | null;
      password: string | null;
    };
  }) => {
    setEditingItem((prev) => {
      if (prev === null) return null;
      return {
        ...prev,
        authentication_method: method,
        authentication: authSettings,
      };
    });
  };

  const onChangeCustomHeaders = (
    headers: { name: string; value: string }[]
  ) => {
    if (headers.length === 0)
      setActiveAuthPanel((prev) =>
        prev.filter((panel) => panel !== PanelKeys.CUSTOM_HEADERS)
      );
    setEditingItem((prev) => {
      if (prev === null) return null;
      return {
        ...prev,
        custom_headers: headers,
      };
    });
  };

  const sanitizeSettings = (currentItem: IAssetDetailItem) => {
    const sanitizedData: Partial<
      Omit<IAssetDetailItem, "tags"> & { tags: string[] }
    > = {
      tags: currentItem.tags.map((tag) => tag.name),
    };
    // authentication_method & authentication
    if (currentItem.authentication_method === null) {
      sanitizedData.authentication_method = null;
      sanitizedData.authentication = {
        cookie: null,
        username: null,
        password: null,
      };
    }
    if (currentItem.authentication_method === "cookie") {
      if (!currentItem.authentication?.cookie) {
        sanitizedData.authentication_method = null;
        sanitizedData.authentication = {
          cookie: null,
          username: null,
          password: null,
        };
      } else {
        sanitizedData.authentication_method = "cookie";
        sanitizedData.authentication = {
          cookie: currentItem.authentication.cookie,
          username: null,
          password: null,
        };
      }
    }
    if (currentItem.authentication_method === "basic") {
      if (
        !currentItem.authentication?.username ||
        !currentItem.authentication?.password
      ) {
        sanitizedData.authentication_method = null;
        sanitizedData.authentication = {
          cookie: null,
          username: null,
          password: null,
        };
      } else {
        sanitizedData.authentication_method = "basic";
        sanitizedData.authentication = {
          cookie: null,
          username: currentItem.authentication.username,
          password: currentItem.authentication.password,
        };
      }
    }
    // use_cystack_user_agent
    sanitizedData.use_cystack_user_agent = currentItem.use_cystack_user_agent;
    // custom_headers
    sanitizedData.custom_headers =
      currentItem.custom_headers?.filter(
        (header) => header.name && header.value
      ) || [];
    return sanitizedData;
  };

  const onSaveAllSettings = () => {
    if (editingItem) {
      const sanitizedData = sanitizeSettings(editingItem);
      setPending(true);
      assetsServices
        .update_asset(editingItem.id, workspaceId, {
          tags: sanitizedData.tags,
          authentication_method: sanitizedData.authentication_method,
          authentication: sanitizedData.authentication,
          use_cystack_user_agent: sanitizedData.use_cystack_user_agent,
          custom_headers: sanitizedData.custom_headers,
        })
        .then(() => {
          onRefresh();
          onClose();
          createToast({
            type: "success",
            message: t("notification.saveSettings.success"),
          });
        })
        .catch((error) => {
          apiErrorHandler(error, {
            toastMessage: t("notification.saveSettings.fail"),
          });
        })
        .finally(() => {
          setPending(false);
        });
    }
  };

  useEffect(() => {
    const fetchAssetDetail = () => {
      if (selectedItemId) {
        assetsServices
          .retrieve_asset(workspaceId, selectedItemId)
          .then((response) => {
            setEditingItem(response);
            const defaultActiveAuthPanel: PanelKeys[] = [];
            if (response.authentication_method !== null)
              defaultActiveAuthPanel.push(PanelKeys.AUTHENTICATION);
            if (response.use_cystack_user_agent)
              defaultActiveAuthPanel.push(PanelKeys.USE_CYSTACK_USER_AGENT);
            if (response.custom_headers && response.custom_headers.length > 0)
              defaultActiveAuthPanel.push(PanelKeys.CUSTOM_HEADERS);
            setActiveAuthPanel(defaultActiveAuthPanel);
          })
          .catch(apiErrorHandler);
      }
    };
    fetchAssetDetail();
  }, [selectedItemId, workspaceId]);

  return editingItem ? (
    <Drawer
      open={open}
      onClose={onClose}
      closable={false}
      title={<h2>{t("title")}</h2>}
      extra={
        <IconButton variant="label-grey" size={9} onClick={onClose}>
          <CloseLine />
        </IconButton>
      }
      footer={
        <FooterButtons
          onSave={onSaveAllSettings}
          onCancel={onClose}
          pending={pending}
        />
      }
      destroyOnClose
    >
      <Accordion
        wrapperClassName="border-b border-light-grey"
        label={
          <AccordionHeading3
            title={t("section.tags.title")}
            active={activeAuthPanel.includes(PanelKeys.TAGS)}
            onToggle={() => {
              setActiveAuthPanel((prev) => {
                if (prev.includes(PanelKeys.TAGS)) {
                  return prev.filter((k) => k !== PanelKeys.TAGS);
                } else {
                  return [...prev, PanelKeys.TAGS];
                }
              });
            }}
            variant="arrow"
          />
        }
        expanded={activeAuthPanel.includes(PanelKeys.TAGS)}
      >
        <div className="flex flex-col gap-6 pb-6 px-6">
          <TagEditor
            workspaceId={workspaceId}
            tagList={editingItem.tags}
            onChangeTags={onChangeTags}
            withInstruction
            allowCreateTag
            type={TagTypeValue.DOMAIN}
          />
        </div>
      </Accordion>
      <Accordion
        wrapperClassName="border-b border-light-grey"
        label={
          <AccordionHeading3
            title={t("section.scanProfile.title")}
            instruction={t("section.scanProfile.instruction")}
            active={activeAuthPanel.includes(PanelKeys.SCAN_PROFILE)}
            onToggle={() => {
              setActiveAuthPanel((prev) => {
                if (prev.includes(PanelKeys.SCAN_PROFILE)) {
                  return prev.filter((k) => k !== PanelKeys.SCAN_PROFILE);
                } else {
                  return [...prev, PanelKeys.SCAN_PROFILE];
                }
              });
            }}
            variant="arrow"
          />
        }
        expanded={activeAuthPanel.includes(PanelKeys.SCAN_PROFILE)}
      >
        <Accordion
          label={
            <AccordionHeading4
              active={activeAuthPanel.includes(PanelKeys.AUTHENTICATION)}
              title={t("section.scanProfile.authentication.title")}
              onToggle={() => {
                setActiveAuthPanel((prev) => {
                  if (prev.includes(PanelKeys.AUTHENTICATION)) {
                    setEditingItem((prev) => {
                      if (prev === null) return null;
                      return { ...prev, authentication_method: null };
                    });
                    return prev.filter((k) => k !== PanelKeys.AUTHENTICATION);
                  } else {
                    setEditingItem((prev) => {
                      if (prev === null) return null;
                      return { ...prev, authentication_method: "basic" };
                    });
                    return [...prev, PanelKeys.AUTHENTICATION];
                  }
                });
              }}
              variant="switch"
            />
          }
          expanded={activeAuthPanel.includes(PanelKeys.AUTHENTICATION)}
        >
          <EditAuthentication
            method={editingItem.authentication_method}
            authSettings={editingItem.authentication}
            onChangeAuthentication={onChangeAuthentication}
          />
        </Accordion>
        <Accordion
          label={
            <AccordionHeading4
              active={activeAuthPanel.includes(
                PanelKeys.USE_CYSTACK_USER_AGENT
              )}
              title={t("section.scanProfile.useCystackUserAgent.title")}
              onToggle={() => {
                setActiveAuthPanel((prev) => {
                  if (prev.includes(PanelKeys.USE_CYSTACK_USER_AGENT)) {
                    setEditingItem((prev) => {
                      if (prev === null) return null;
                      return { ...prev, use_cystack_user_agent: false };
                    });
                    return prev.filter(
                      (k) => k !== PanelKeys.USE_CYSTACK_USER_AGENT
                    );
                  } else {
                    setEditingItem((prev) => {
                      if (prev === null) return null;
                      return { ...prev, use_cystack_user_agent: true };
                    });
                    return [...prev, PanelKeys.USE_CYSTACK_USER_AGENT];
                  }
                });
              }}
              variant="switch"
            />
          }
          expanded={activeAuthPanel.includes(PanelKeys.USE_CYSTACK_USER_AGENT)}
        >
          <div className="flex flex-col gap-6 pt-3 pb-6 px-6">
            <span className="font-regular-14 text-hard-grey">
              {t("section.scanProfile.useCystackUserAgent.instruction")}
            </span>
            {/* Container div */}
            <div className="bg-bright-blue p-6 rounded-md">
              {/* This is fixed text, no need to translate */}
              <code>
                User-agent:
                <code className="text-primary">
                  `CyStack Vulnerabitity Scanner - https://cystack.io`
                </code>
              </code>
            </div>
          </div>
        </Accordion>
        <Accordion
          label={
            <AccordionHeading4
              active={activeAuthPanel.includes(PanelKeys.CUSTOM_HEADERS)}
              title={t("section.scanProfile.authentication.title")}
              onToggle={() => {
                setActiveAuthPanel((prev) => {
                  if (prev.includes(PanelKeys.CUSTOM_HEADERS)) {
                    setEditingItem((prev) => {
                      if (prev === null) return null;
                      return { ...prev, custom_headers: [] };
                    });
                    return prev.filter((k) => k !== PanelKeys.CUSTOM_HEADERS);
                  } else {
                    setEditingItem((prev) => {
                      if (prev === null) return null;
                      return {
                        ...prev,
                        custom_headers:
                          editingItem.custom_headers &&
                          editingItem.custom_headers.length > 0
                            ? editingItem.custom_headers
                            : [{ name: "", value: "" }],
                      };
                    });
                    return [...prev, PanelKeys.CUSTOM_HEADERS];
                  }
                });
              }}
              variant="switch"
            />
          }
          expanded={activeAuthPanel.includes(PanelKeys.CUSTOM_HEADERS)}
        >
          <EditCustomHeaders
            customHeaders={editingItem.custom_headers}
            onChangeCustomHeaders={onChangeCustomHeaders}
          />
        </Accordion>
      </Accordion>
    </Drawer>
  ) : null;
};

export default EditSettingsDrawer;
