// Libraries
import { Dropdown, Switch, Typography } from "@lockerpm/design";
import type { ItemType } from "@lockerpm/design/es/menu/hooks/useItems";
import dayjs from "dayjs";
import { useTranslation } from "react-i18next";

// Resources
import { ReactComponent as MoreLine } from "#src/assets/images/icons/more-line.svg";
import { ReactComponent as DeleteBin6Line } from "#src/assets/images/icons/delete-bin-6-line.svg";
import { ReactComponent as EditLine } from "#src/assets/images/icons/edit-line.svg";

// Components
import {
  TableActionLabel,
  TableCheckbox,
  TableDataCell,
  TableHeader,
  TableRowWrapper,
} from "#src/components/common/Table";
import type { ITableHeaderPropItem } from "#src/components/common/Table/TableHeader";
import { IconButton } from "#src/components/common/system/Button";
import { createToast } from "#src/components/common/system/toasts";
import { apiErrorHandler } from "#src/utils/apiErrorHandler";

// API-related
import scansServices from "#src/services/scans";
import type { IScanScheduleItem } from "#src/services/scans/schedules";

// Children
import { getScheduleSummary } from "#src/components/scans/utils";

interface IScheduleTableRowProps {
  data: IScanScheduleItem;
  actionList: ItemType[];
  onClickActionItems: (key: string, item: IScanScheduleItem) => void;
  selectedScheduleIds: number[];
  onChangeSelectedSchedules: (selected: IScanScheduleItem) => void;
  onToggleEnableSchedule: (value: boolean, item: IScanScheduleItem) => void;
}

const ScheduleTableRow = ({
  data,
  actionList,
  onClickActionItems,
  selectedScheduleIds,
  onChangeSelectedSchedules,
  onToggleEnableSchedule,
}: IScheduleTableRowProps) => {
  const summary = getScheduleSummary(
    data.schedule,
    data.duration_type,
    data.repeat_number,
    data.activated_time
  );

  return (
    <TableRowWrapper>
      <TableDataCell className="p-0 justify-center">
        <TableCheckbox
          checked={selectedScheduleIds.includes(data.id)}
          onClick={() => {
            onChangeSelectedSchedules(data);
          }}
        />
      </TableDataCell>
      <TableDataCell>{data.id}</TableDataCell>
      <TableDataCell>
        {data.domains.length >= 1 ? (
          <div className="px-3 py-2 border border-grey rounded-md">
            {data.domains[0].name}
          </div>
        ) : null}
        {data.domains.length >= 2 ? (
          <div className="px-3 py-2 border border-grey rounded-md">
            {data.domains[1].name}
          </div>
        ) : null}
        {data.domains.length >= 3 ? (
          <div className="px-3 py-2 border border-grey rounded-md">
            {"+" + (data.domains.length - 2)}
          </div>
        ) : null}
      </TableDataCell>
      <TableDataCell>
        <Typography.Text ellipsis={{ tooltip: summary }}>
          {summary}
        </Typography.Text>
      </TableDataCell>
      <TableDataCell>
        {data.updated_time
          ? dayjs.unix(data.updated_time).format("DD.MM.YYYY")
          : null}
      </TableDataCell>
      <TableDataCell className="justify-center">
        <Switch
          checked={data.activated}
          onChange={(e) => {
            onToggleEnableSchedule(e.valueOf(), data);
          }}
        />
      </TableDataCell>
      <TableDataCell className="p-0 justify-center">
        {actionList.length ? (
          <Dropdown
            menu={{
              items: actionList,
              onClick: ({ key, domEvent }) => {
                domEvent.preventDefault();
                domEvent.stopPropagation();
                onClickActionItems(key, data);
              },
            }}
            trigger={["click"]}
            disabled={selectedScheduleIds.length > 0}
          >
            <IconButton
              variant="ghost"
              size={9}
              onClick={(e) => {
                e.preventDefault();
              }}
              disabled={selectedScheduleIds.length > 0}
            >
              <MoreLine />
            </IconButton>
          </Dropdown>
        ) : (
          <div className="h-9 w-9" />
        )}
      </TableDataCell>
    </TableRowWrapper>
  );
};

interface IScheduleTableProps {
  workspaceId: string;
  scheduleList: IScanScheduleItem[];
  selectedScheduleIds: number[];
  onChangeSelectedSchedules: (selected: IScanScheduleItem) => void;
  onChangeEditingSchedule: (selected: IScanScheduleItem) => void;
  refreshScheduleList: () => void;
}

const ScheduleTableGrid = ({
  workspaceId,
  scheduleList,
  selectedScheduleIds,
  onChangeSelectedSchedules,
  onChangeEditingSchedule,
  refreshScheduleList,
}: IScheduleTableProps) => {
  const { t } = useTranslation("scanSchedules");

  /** Schedule Table's header list */
  const headers: ITableHeaderPropItem[] = [
    { name: "", align: "left" },
    { name: "#", align: "left" },
    { name: t("headers.domains"), align: "left" },
    { name: t("headers.scheduleSummary"), align: "left" },
    { name: t("headers.updatedTime"), align: "left" },
    { name: t("headers.enabled"), align: "center" },
    { name: t("headers.actions"), align: "center" },
  ];

  const onToggleEnableSchedule = (value: boolean, item: IScanScheduleItem) => {
    scansServices
      .update_schedule(workspaceId, item.id, {
        activated: value,
        activated_time: item.activated_time,
        schedule: item.schedule,
        timezone: item.timezone,
        days: item.days,
        duration_type: item.duration_type,
      })
      .then(() => {
        refreshScheduleList();
        createToast({
          type: "success",
          message: t("notification.updateSchedule.success"),
        });
      })
      .catch((error) => {
        apiErrorHandler(error, {
          toastMessage: t("notification.updateSchedule.fail"),
        });
      });
  };

  const onDeleteSchedule = (scheduleId: number) => {
    scansServices
      .delete_schedule(workspaceId, scheduleId)
      .then((response) => {
        console.log(response);
        refreshScheduleList();
      })
      .catch((error) => {
        apiErrorHandler(error, {
          toastMessage: t("notification.deleteSchedule.fail"),
        });
      });
  };

  const onClickActionItems = (key: string, item: IScanScheduleItem) => {
    switch (key) {
      case "edit-schedule":
        onChangeEditingSchedule(item);
        return;
      case "delete-schedule":
        onDeleteSchedule(item.id);
        return;
    }
  };
  const actionList = [
    {
      key: "edit-schedule",
      label: <TableActionLabel icon={<EditLine />} text={t("actions.edit")} />,
    },
    {
      key: "delete-schedule",
      label: (
        <TableActionLabel
          icon={<DeleteBin6Line />}
          text={t("actions.delete")}
          warning
        />
      ),
    },
  ];

  return (
    <div className="grid grid-cols-[2.75rem_minmax(6rem,_1fr)_3fr_3fr_minmax(8rem,_1fr)_minmax(6rem,_1fr)_minmax(6rem,_1fr)]">
      <TableHeader title="scanSchedules" headerProps={headers} />
      {scheduleList.map((item) => (
        <ScheduleTableRow
          key={`tscanSchedules-tr${item.id}`}
          data={item}
          actionList={actionList}
          onClickActionItems={onClickActionItems}
          selectedScheduleIds={selectedScheduleIds}
          onChangeSelectedSchedules={onChangeSelectedSchedules}
          onToggleEnableSchedule={onToggleEnableSchedule}
        />
      ))}
    </div>
  );
};

export default ScheduleTableGrid;
