import type { IFilterItem } from "#src/@types/common";
import i18next from "i18next";
import {
  BugBountyMemberRoleEnum,
  BugBountyStatusEnum,
  MsspBugBountyMemberRoleEnum,
} from "./enum";
import {
  BugBountyMemberRoleResponseValue,
  BugBountyStatusResponseValue,
  MsspBugBountyMemberRoleResponseValue,
} from "./value";

export const bugBountyMemberRoleItems: IFilterItem[] = [
  {
    key: BugBountyMemberRoleEnum.PROJECT_OWNER,
    value: BugBountyMemberRoleResponseValue.PROJECT_OWNER,
    getLabel: () => i18next.t("filter.bugBountyMemberRole.owner"),
  },
  {
    key: BugBountyMemberRoleEnum.VIEWER,
    value: BugBountyMemberRoleResponseValue.VIEWER,
    getLabel: () => i18next.t("filter.bugBountyMemberRole.viewer"),
  },
  {
    key: BugBountyMemberRoleEnum.DEVELOPER,
    value: BugBountyMemberRoleResponseValue.DEVELOPER,
    getLabel: () => i18next.t("filter.bugBountyMemberRole.developer"),
  },
  {
    key: BugBountyMemberRoleEnum.RESEARCHER,
    value: BugBountyMemberRoleResponseValue.RESEARCHER,
    getLabel: () => i18next.t("filter.bugBountyMemberRole.researcher"),
  },
];

export const msspBugBountyMemberRoleItems: IFilterItem[] = [
  {
    key: MsspBugBountyMemberRoleEnum.SECURITY_MANAGER,
    value: MsspBugBountyMemberRoleResponseValue.SECURITY_MANAGER,
    getLabel: () => i18next.t("filter.msspPentestMemberRole.securityManager"),
  },
  {
    key: MsspBugBountyMemberRoleEnum.PENTESTER,
    value: MsspBugBountyMemberRoleResponseValue.PENTESTER,
    getLabel: () => i18next.t("filter.msspPentestMemberRole.pentester"),
  },
];

export const bugBountyStatusItems = [
  {
    key: BugBountyStatusEnum.DRAFT,
    value: BugBountyStatusResponseValue.DRAFT,
    getLabel: () => i18next.t("filter.bugBountyStatus.draft"),
  },
  {
    key: BugBountyStatusEnum.REQUESTED,
    value: BugBountyStatusResponseValue.REQUESTED,
    getLabel: () => i18next.t("filter.bugBountyStatus.requested"),
  },
  {
    key: BugBountyStatusEnum.OPEN,
    value: BugBountyStatusResponseValue.OPEN,
    getLabel: () => i18next.t("filter.bugBountyStatus.open"),
  },
  {
    key: BugBountyStatusEnum.CLOSED,
    value: BugBountyStatusResponseValue.CLOSED,
    getLabel: () => i18next.t("filter.bugBountyStatus.closed"),
  },
  {
    key: BugBountyStatusEnum.LOCKED,
    value: BugBountyStatusResponseValue.LOCKED,
    getLabel: () => i18next.t("filter.bugBountyStatus.locked"),
  },
  {
    key: BugBountyStatusEnum.ALL,
    value: undefined,
    getLabel: () => i18next.t("filter.all"),
  },
];
