// TODO: re-check this component's design
// Libraries
import { Modal } from "@lockerpm/design";
import { type Dispatch, type SetStateAction, useState } from "react";
import i18next from "i18next";
import { useTranslation } from "react-i18next";

// General
import { LANGUAGE_LIST } from "#src/config/languages";

// Components
import { Input } from "#src/components/common/system/Input";

// Services
import systemService from "#src/services/system";

interface ISelectLanguageModal {
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
}

const SelectLanguageModal = ({ open, setOpen }: ISelectLanguageModal) => {
  const { t } = useTranslation("common", { keyPrefix: "modal.changeLanguage" });

  const [searchKeyword, setSearchKeyword] = useState<string>("");

  const availableLanguages = LANGUAGE_LIST.filter((item) =>
    Object.keys(i18next.store.data).includes(item.key)
  );

  return (
    <Modal
      open={open}
      title={<h2>{t("chooseYourLanguage")}</h2>}
      onCancel={() => {
        setOpen(false);
      }}
      centered
      footer={null}
    >
      <div className="mt-3 flex flex-col gap-3">
        <Input
          placeholder={t("searchBox.placeholder")}
          value={searchKeyword}
          onChange={(e) => {
            setSearchKeyword(e.target.value);
          }}
        />
        <div className="grid grid-cols-3 gap-1">
          {availableLanguages
            .filter((item) =>
              item.searchValue.includes(searchKeyword.toLowerCase())
            )
            .map((item) => (
              <button
                key={`language-item_${item.key}`}
                className="p-3 flex items-center gap-2 bg-bright-grey rounded-md"
                onClick={() => {
                  systemService.updateLanguage(item.key);
                  setOpen(false);
                }}
              >
                {item.flag}
                <span className="text-hard-grey whitespace-nowrap overflow-hidden text-ellipsis">
                  {item.getLabel()}
                </span>
              </button>
            ))}
        </div>
      </div>
    </Modal>
  );
};

export default SelectLanguageModal;
