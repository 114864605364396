// Libraries
import { useTranslation } from "react-i18next";
import { useState, useEffect } from "react";
import { Upload, type UploadProps } from "@lockerpm/design";
import { type Dispatch, type SetStateAction } from "react";

// Resources
import { ReactComponent as SearchEyeLine } from "#src/assets/images/icons/search-eye-line.svg";
import { ReactComponent as EditLine } from "#src/assets/images/icons/edit-line.svg";
import { ReactComponent as CloseLine } from "#src/assets/images/icons/close-line.svg";
import { ReactComponent as Attachment2 } from "#src/assets/images/icons/attachment-2.svg";
import { ReactComponent as Asterisk } from "#src/assets/images/icons/asterisk.svg";

// Components
import { TextArea, Input } from "#src/components/common/system/Input";
import { Button } from "#src/components/common/system/Button";
import { ObjectImage } from "#src/components/common/system/Object";
import type { ITrustCenterConfigDetails } from "#src/services/trustCenter/configuration";
import { createToast } from "#src/components/common/system/toasts";
import { FileUploadActionValue, uploadFile } from "#src/utils/uploadFile";
import { apiErrorHandler } from "#src/utils/apiErrorHandler";

// API-related
import trustCenterServices from "#src/services/trustCenter";

export interface ITrustCenterConfigSecurityProfileStates {
  og_title: string;
  og_description: string;
  og_logo: string;
}

interface ITrustCenterConfigSecurityProfileProps {
  workspaceId: string;
  originalSettings: ITrustCenterConfigDetails;
  setOriginalSettings: Dispatch<SetStateAction<ITrustCenterConfigDetails>>;
}

const TrustCenterConfigSecurityProfile = ({
  workspaceId,
  originalSettings,
  setOriginalSettings,
}: ITrustCenterConfigSecurityProfileProps) => {
  const { t } = useTranslation("trustCenter", {
    keyPrefix: "configuration.tab.profile",
  });

  const [isEditing, setIsEditing] = useState<boolean>(false);
  const [securityProfile, setSecurityProfile] =
    useState<ITrustCenterConfigSecurityProfileStates>({
      og_title: "",
      og_description: "",
      og_logo: "",
    });
  const [logoFile, setLogoFile] = useState<any>(null);
  const [logoBase64, setLogoBase64] = useState<string>("");

  const [pending, setPending] = useState<boolean>(false);

  const getBase64 = (file: any): Promise<string> =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result as string);
      reader.onerror = (error) => reject(error);
    });

  const props: UploadProps = {
    name: "file",
    accept: "image/*",
    multiple: false,
    showUploadList: false,
    action: "",
    beforeUpload: async (file) => {
      const img = new Image();
      img.src = URL.createObjectURL(file);

      img.onload = async () => {
        if (img.width * img.height > 64 * 64) {
          createToast({
            type: "warning",
            message: t("securityProfile.uploadOgLogo.note"),
          });
          return false;
        }
        const fileBase64 = await getBase64(file);
        setLogoBase64(fileBase64);
        setLogoFile(file);
        URL.revokeObjectURL(img.src);
      };
    },
  };

  useEffect(() => {
    setSecurityProfile({
      og_title: originalSettings.profile.og_title || "",
      og_description: originalSettings.profile.og_description || "",
      og_logo: originalSettings.profile.og_logo,
    });
  }, [
    originalSettings.profile.og_description,
    originalSettings.profile.og_logo,
    originalSettings.profile.og_title,
  ]);

  const onCancelEditProfileSecurity = () => {
    setIsEditing(false);
    setLogoBase64("");
    setLogoFile(null);
    setSecurityProfile({
      og_title: originalSettings.profile.og_title || "",
      og_description: originalSettings.profile.og_description || "",
      og_logo: originalSettings.profile.og_logo,
    });
  };

  const onUploadLogo = async () => {
    return await uploadFile(
      logoFile,
      FileUploadActionValue.TRUST_CENTER_OG_LOGO,
      {
        workspace_id: workspaceId,
      }
    )
      .then((signedResponse) => {
        return signedResponse.signed_url;
      })
      .catch((error) => {
        setLogoFile(null);
        setLogoBase64("");
        apiErrorHandler(error, {
          toastMessage: t("securityProfile.notification.uploadFail"),
        });
        return "";
      });
  };

  const onSaveEditProfileSecurity = async () => {
    let ogLogoUrl: string = securityProfile.og_logo;
    if (logoFile) {
      ogLogoUrl = await onUploadLogo();
    }
    setPending(true);
    trustCenterServices
      .update_trust_center_config_profile(workspaceId, {
        ...originalSettings.profile,
        og_title: securityProfile.og_title,
        og_description: securityProfile.og_description,
        og_logo: ogLogoUrl,
      })
      .then((response) => {
        setOriginalSettings(response);
        setSecurityProfile({
          og_title: response.profile.og_title || "",
          og_description: response.profile.og_description || "",
          og_logo: response.profile.og_logo,
        });
      })
      .catch(apiErrorHandler)
      .finally(() => {
        setPending(false);
        setIsEditing(false);
        setLogoBase64("");
        setLogoFile(null);
      });
  };

  return (
    <div className="flex flex-col gap-6 w-2/3">
      <div className="flex flex-col border border-light-grey rounded-md">
        <div className="p-6 flex justify-between rounded-md bg-bright-grey">
          <div className="flex items-center gap-3">
            <SearchEyeLine className="h-7 w-7" />
            <h2 className="font-medium-20">{t("securityProfile.title")}</h2>
          </div>
          <div className="flex gap-2">
            {isEditing ? (
              <>
                <Button
                  variant="secondary"
                  onClick={onCancelEditProfileSecurity}
                >
                  {t("securityProfile.button.cancel")}
                </Button>
                <Button
                  variant="primary"
                  disabled={!securityProfile.og_title}
                  pending={pending}
                  onClick={onSaveEditProfileSecurity}
                >
                  {t("securityProfile.button.save")}
                </Button>
              </>
            ) : (
              <Button variant="secondary" onClick={() => setIsEditing(true)}>
                <EditLine />
                {t("securityProfile.button.edit")}
              </Button>
            )}
          </div>
        </div>
        <div className="flex flex-col gap-3 p-6">
          <span className="font-regular-16-body text-hard-grey">
            {t("securityProfile.description")}
          </span>
          <div className="grid grid-cols-2 gap-x-[4.5rem] gap-y-6">
            <div className="flex flex-col gap-3">
              <span className="font-medium-14-forced uppercase text-severity-none flex items-center">
                <Asterisk className="-mt-2 mr-[0,125rem]" />
                {t("securityProfile.ogTitle")}
              </span>
              <Input
                className="font-regular-16"
                placeholder={t("securityProfile.ogTitleNote")}
                value={securityProfile.og_title}
                disabled={!isEditing}
                onChange={(e) => {
                  setSecurityProfile((prev) => ({
                    ...prev,
                    og_title: e.target.value,
                  }));
                }}
              />
            </div>
            <div className="flex flex-col gap-3">
              <h4>{t("securityProfile.ogDescription")}</h4>
              <TextArea
                value={securityProfile.og_description}
                disabled={!isEditing}
                placeholder={t("securityProfile.ogDescriptionNote")}
                onChange={(e) => {
                  setSecurityProfile((prev) => ({
                    ...prev,
                    og_description: e.target.value,
                  }));
                }}
              />
            </div>
            <div className="flex flex-col gap-3">
              <h4 className="uppercase">{t("securityProfile.ogLogo")}</h4>
              <div>
                {logoBase64 ? (
                  <div className="w-[4.6rem] h-[4.6rem] p-2 relative">
                    <ObjectImage
                      data={logoBase64}
                      className="w-full h-full p-0 rounded-md border border-grey"
                    />
                    <div className="absolute right-0 top-0 z-10 w-[2.3rem] h-[2.3rem] flex items-center justify-center">
                      <button
                        className="rounded-full border border-warning-red-40% bg-warning-red-5% h-6 w-6 p-1 flex items-center justify-center"
                        onClick={() => {
                          setLogoBase64("");
                          setLogoFile(null);
                        }}
                      >
                        <CloseLine className="h-4 w-4 fill-warning-red-danger" />
                      </button>
                    </div>
                  </div>
                ) : (
                  <>
                    {securityProfile.og_logo && (
                      <div className="w-[4.6rem] h-[4.6rem] p-2 relative">
                        <ObjectImage
                          data={securityProfile.og_logo}
                          className="w-full h-full p-0 rounded-md border border-grey"
                        />
                        {isEditing && (
                          <div className="absolute right-0 top-0 z-10 w-[2.3rem] h-[2.3rem] flex items-center justify-center">
                            <button
                              className="rounded-full border border-warning-red-40% bg-warning-red-5% h-6 w-6 p-1 flex items-center justify-center"
                              onClick={() => {
                                setSecurityProfile({
                                  ...securityProfile,
                                  og_logo: "",
                                });
                              }}
                            >
                              <CloseLine className="h-4 w-4 fill-warning-red-danger" />
                            </button>
                          </div>
                        )}
                      </div>
                    )}
                  </>
                )}
              </div>
              {isEditing && (
                <div>
                  <Upload.Dragger {...props} className="bg-white">
                    <div className="flex flex-col gap-3 px-6">
                      <div className="text-center h-5">
                        <Attachment2 className="text-primary h-5 w-5" />
                      </div>
                      <span className="text-center text-primary font-regular-12">
                        {t("securityProfile.uploadOgLogo.title")}
                      </span>
                      <span className="font-regular-12 text-hard-grey">
                        ({t("securityProfile.uploadOgLogo.note")})
                      </span>
                    </div>
                  </Upload.Dragger>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TrustCenterConfigSecurityProfile;
