import type { IResourceVulnCategory } from "#src/services/vulnerabilities";
import { Popover } from "@lockerpm/design";
import { type ReactNode, useState } from "react";
import { ReactComponent as ArrowDownSLine } from "#src/assets/images/icons/arrow-down-s-line.svg";
import { ReactComponent as ArrowRightSLine } from "#src/assets/images/icons/arrow-right-s-line.svg";

const CategorySectionWrapper = ({ children }: { children?: ReactNode }) => {
  return (
    <div className="flex-1 overflow-x-auto flex flex-col gap-4 w-[20rem] px-6 border-r border-light-grey last:border-none">
      {children}
    </div>
  );
};

interface IVulnVariantSelectButtonProps {
  key: string;
  label: string;
  expandable: boolean;
  selected: boolean;
  onClick: () => void;
}

const VulnVariantSelectButton = ({
  key,
  label,
  expandable,
  selected,
  onClick,
}: IVulnVariantSelectButtonProps) => {
  return (
    <button
      key={key}
      className={`w-full pr-1 text-left flex justify-between items-center ${
        selected
          ? "font-medium-14 text-primary"
          : "font-regular-14 text-medium-grey"
      }`}
      onClick={onClick}
    >
      <span className="overflow-hidden whitespace-nowrap text-ellipsis">
        {label}
      </span>
      {expandable ? <ArrowRightSLine height={"1rem"} width={"1rem"} /> : null}
    </button>
  );
};

interface IVulnerabilityPopoverProps {
  open: boolean;
  onChangeOpen: (value: boolean) => void;
  value: string;
  resourceVulnList: IResourceVulnCategory[];
  selectedVulnVariant: {
    category: IResourceVulnCategory;
    subcategory?: IResourceVulnCategory["subcategory"][number];
    variant?: IResourceVulnCategory["subcategory"][number]["variants"][number];
  } | null;
  onChangeVulnVariant: (selected: {
    category: IResourceVulnCategory;
    subcategory?: IResourceVulnCategory["subcategory"][number];
    variant?: IResourceVulnCategory["subcategory"][number]["variants"][number];
  }) => void;
}

const VulnerabilityPopover = ({
  open,
  onChangeOpen,
  value,
  resourceVulnList,
  selectedVulnVariant,
  onChangeVulnVariant,
}: IVulnerabilityPopoverProps) => {
  const [selectedCategory, setSelectedCategory] =
    useState<IResourceVulnCategory | null>(
      selectedVulnVariant?.category ? selectedVulnVariant.category : null
    );
  const [selectedSubcategory, setSelectedSubcategory] = useState<
    IResourceVulnCategory["subcategory"][number] | null
  >(selectedVulnVariant?.subcategory ? selectedVulnVariant.subcategory : null);
  const [selectedVariant, setSelectedVariant] = useState<
    IResourceVulnCategory["subcategory"][number]["variants"][number] | null
  >(selectedVulnVariant?.variant ? selectedVulnVariant.variant : null);

  return (
    <Popover
      open={open}
      onOpenChange={onChangeOpen}
      content={
        <div className="h-[20rem] flex justify-between">
          <CategorySectionWrapper>
            {resourceVulnList.map((category) => {
              return (
                <VulnVariantSelectButton
                  key={`vrt_category_${category.name}`}
                  label={category.name}
                  expandable={category.subcategory.length > 0}
                  selected={category.name === selectedCategory?.name}
                  onClick={() => {
                    if (category.name !== selectedCategory?.name) {
                      setSelectedCategory(category);
                      setSelectedSubcategory(null);
                      setSelectedVariant(null);
                      if (category.subcategory.length === 0) {
                        onChangeVulnVariant({ category });
                      }
                    }
                  }}
                />
              );
            })}
          </CategorySectionWrapper>

          {selectedCategory ? (
            <CategorySectionWrapper>
              {selectedCategory.subcategory.map((subcategory) => (
                <VulnVariantSelectButton
                  key={`vrt_subcategory_${subcategory.name}`}
                  label={subcategory.name}
                  expandable={subcategory.variants.length > 0}
                  selected={subcategory.name === selectedSubcategory?.name}
                  onClick={() => {
                    if (subcategory.name !== selectedSubcategory?.name) {
                      setSelectedSubcategory(subcategory);
                      setSelectedVariant(null);

                      if (subcategory.variants.length === 0) {
                        onChangeVulnVariant({
                          category: selectedCategory,
                          subcategory,
                        });
                      }
                    }
                  }}
                />
              ))}
            </CategorySectionWrapper>
          ) : null}

          {selectedCategory && selectedSubcategory ? (
            <CategorySectionWrapper>
              {selectedSubcategory.variants.map((variant) => (
                <VulnVariantSelectButton
                  key={`vrt_variant_${variant.name}`}
                  label={variant.name}
                  expandable={false}
                  selected={variant.name === selectedVariant?.name}
                  onClick={() => {
                    if (variant.name !== selectedVariant?.name) {
                      setSelectedVariant(variant);
                      onChangeVulnVariant({
                        category: selectedCategory,
                        subcategory: selectedSubcategory,
                        variant,
                      });
                    }
                  }}
                />
              ))}
            </CategorySectionWrapper>
          ) : null}
        </div>
      }
      trigger={["click"]}
    >
      <button className="flex justify-between items-center text-left p-3 w-full rounded-md bg-bright-grey text-hard-grey">
        {value}
        <ArrowDownSLine width={"1rem"} height={"1rem"} />
      </button>
    </Popover>
  );
};

export default VulnerabilityPopover;
