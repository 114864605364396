// Libraries
import { generatePath, useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import i18next from "i18next";
import { AxiosError } from "axios";
import { z } from "zod";
import { Tooltip } from "@lockerpm/design";

// Resources
import { ReactComponent as CornerLeftUpLine } from "#src/assets/images/icons/corner-left-up-line.svg";
import { ReactComponent as CloseLine } from "#src/assets/images/icons/close-line.svg";
import { ReactComponent as AddLine } from "#src/assets/images/icons/add-line.svg";
import { ReactComponent as InformationLine } from "#src/assets/images/icons/information-line.svg";
import usFlag from "#src/assets/images/flags/us.png";
import vnFlag from "#src/assets/images/flags/vn.png";

// General
import { pathname } from "#src/config/pathname";

// Components
import LoadingState from "#src/components/common/system/LoadingState";
import NoResultsState from "#src/components/common/states/NoResultsState";
import { ContentSection, StickySection } from "#src/layouts/content";
import { Button } from "#src/components/common/system/Button";
import { InputField } from "#src/components/common/helper/wrapper/InputField";
import { apiErrorHandler, parseBadRequest } from "#src/utils/apiErrorHandler";
import MarkdownEditor from "#src/components/common/MarkdownEditor";
import { Flag } from "#src/components/common/Flag";

// API-related
import trustCenterServices from "#src/services/trustCenter";
import type { ITrustCenterFaqItem } from "#src/services/trustCenter/faq";

const EditTrustCenterFaq = () => {
  const { t } = useTranslation("trustCenter", {
    keyPrefix: "faq.editFaq",
  });

  const locale = i18next.language;

  const navigate = useNavigate();

  const { workspace: workspaceId, faq: faqId } = useParams<
    "workspace" | "faq"
  >();

  const [thisFaq, setThisFaq] = useState<ITrustCenterFaqItem | null>(null);
  const [faqDetail, setFaqDetail] = useState<
    { lang: string; question: string; answer: string }[]
  >([{ lang: locale, question: "", answer: "" }]);
  const [isLoading, setLoading] = useState<boolean>(false);
  const [errorObj, setErrorObj] = useState<{
    question: string | undefined;
    answer: string | undefined;
  }>({ question: undefined, answer: undefined });

  const [pending, setPending] = useState<boolean>(false);

  useEffect(() => {
    const fetchFaq = async () => {
      if (workspaceId && faqId) {
        setLoading(true);
        trustCenterServices
          .retrieve_trust_center_faq(workspaceId, faqId)
          .then((response) => {
            setThisFaq(response);
            setFaqDetail(response.details);
            setLoading(false);
          })
          .catch(apiErrorHandler);
      }
    };

    fetchFaq();
  }, [workspaceId, faqId]);

  if (!workspaceId || workspaceId === "null") {
    return <NoResultsState />;
  }

  const onSaveFaq = () => {
    if (workspaceId && faqId && thisFaq) {
      setPending(true);
      trustCenterServices
        .update_trust_center_faq(workspaceId, faqId, {
          details: faqDetail,
          status: thisFaq.status,
        })
        .then(() => {
          navigate(
            generatePath(pathname.TRUST_CENTER_FAQ, {
              workspace: workspaceId,
            })
          );
        })
        .catch((error) => {
          if (
            error instanceof AxiosError &&
            error.response &&
            error.response.status === 400
          ) {
            const getErrorData = parseBadRequest(
              error.response.data,
              z.object({
                details: z.array(
                  z.object({
                    question: z.optional(z.array(z.string())),
                    answer: z.optional(z.array(z.string())),
                  })
                ),
              })
            );
            setErrorObj((prev) => ({
              ...prev,
              question:
                getErrorData.details.details.length > 0 &&
                getErrorData.details.details[0].question
                  ? getErrorData.details.details[0].question[0]
                  : undefined,
              answer:
                getErrorData.details.details.length > 0 &&
                getErrorData.details.details[0].answer
                  ? getErrorData.details.details[0].answer[0]
                  : undefined,
            }));
          } else {
            apiErrorHandler(error, {
              toastMessage: t("notification.createFaq.fail"),
            });
          }
        })
        .finally(() => {
          setPending(false);
        });
    }
  };

  const onClickAddAnotherLang = () => {
    if (faqDetail.every((item) => item.lang !== "en")) {
      setFaqDetail((prev) => [
        ...prev,
        { lang: "en", question: "", answer: "" },
      ]);
      return;
    }
    if (faqDetail.every((item) => item.lang !== "vi")) {
      setFaqDetail((prev) => [
        ...prev,
        { lang: "vi", question: "", answer: "" },
      ]);
      return;
    }
  };

  return thisFaq === null || isLoading ? (
    <LoadingState />
  ) : (
    <>
      <StickySection>
        <h1>{t("title")}</h1>
        <div className="flex gap-1">
          <Button
            size="large"
            variant="secondary"
            onClick={() => {
              navigate(
                generatePath(pathname.TRUST_CENTER_FAQ, {
                  workspace: workspaceId,
                })
              );
            }}
          >
            {t("button.cancel")}
          </Button>
          <Tooltip
            title={
              faqDetail.some(
                (faqLangItem) =>
                  faqLangItem.answer.length === 0 ||
                  faqLangItem.question.length === 0
              ) ? (
                <div className="font-medium-12 whitespace-nowrap">
                  {t("button.disabledTooltip")}
                </div>
              ) : null
            }
            placement="top"
          >
            <div>
              <Button size="large" pending={pending} onClick={onSaveFaq}>
                <CornerLeftUpLine />
                {t("button.saveChanges")}
              </Button>
            </div>
          </Tooltip>
        </div>
      </StickySection>
      <ContentSection className="gap-12">
        <div className="flex flex-col gap-6">
          <h2>{t("title")}</h2>
          {faqDetail.map((faqLangItem) => {
            return (
              <div
                className="flex flex-col gap-6"
                key={`faqByLanguage-${faqLangItem.lang}`}
              >
                {/* h-6 to fit the close icon so when adding new language this div's height won't change. */}
                <div className="flex justify-between items-center h-6">
                  <p className="text-hard-grey flex gap-2">
                    {faqLangItem.lang === "en" ? (
                      <>
                        <Flag pngProp={usFlag} />
                        {t("language.english")}
                      </>
                    ) : faqLangItem.lang === "vi" ? (
                      <>
                        <Flag pngProp={vnFlag} />
                        {t("language.vietnamese")}
                      </>
                    ) : null}
                  </p>
                  {faqDetail.length > 1 ? (
                    <button
                      className="h-6 w-6 text-hard-grey"
                      onClick={() => {
                        setFaqDetail((prev) =>
                          prev.filter((item) => item.lang !== faqLangItem.lang)
                        );
                      }}
                    >
                      <CloseLine />
                    </button>
                  ) : null}
                </div>
                <div className="flex flex-col gap-6 border border-light-grey p-6 rounded-md">
                  <InputField
                    title={t("question.title")}
                    placeholder={t("question.placeholder")}
                    required
                    value={faqLangItem.question}
                    onChangeValue={(value) => {
                      setFaqDetail((prev) =>
                        prev.map((item) =>
                          item.lang === faqLangItem.lang
                            ? { ...item, question: value }
                            : item
                        )
                      );
                    }}
                    error={errorObj.question}
                    onChangeError={(value) => {
                      setErrorObj((prev) => ({ ...prev, question: value }));
                    }}
                  />
                  <div className="flex flex-col gap-3">
                    <h4>
                      <span className="text-primary pr-0.5">*</span>
                      {t("answer.title")}
                    </h4>
                    <MarkdownEditor
                      value={faqLangItem.answer}
                      placeholder={t("answer.placeholder")}
                      className={
                        errorObj.answer
                          ? "bg-button-warning-bg outline-warning text-warning"
                          : ""
                      }
                      onChange={(e) => {
                        setFaqDetail((prev) =>
                          prev.map((item) =>
                            item.lang === faqLangItem.lang
                              ? { ...item, answer: e.target.value }
                              : item
                          )
                        );
                        if (errorObj.answer) {
                          setErrorObj((prev) => ({
                            ...prev,
                            answer: undefined,
                          }));
                        }
                      }}
                      onBlur={(e) => {
                        if (!e.target.value) {
                          setErrorObj((prev) => ({
                            ...prev,
                            answer: i18next.t("common:input.error.required"),
                          }));
                        }
                      }}
                      noBox
                    />
                    {errorObj.answer ? (
                      <span className="font-medium-14-forced text-warning flex items-center gap-1">
                        <InformationLine className="h-5 w-5" />
                        {errorObj.answer}
                      </span>
                    ) : null}
                  </div>
                </div>
              </div>
            );
          })}
        </div>
        {/* If every languages are used, hide the button. */}
        {["en", "vi"].every((lang) =>
          faqDetail.some((item) => item.lang === lang)
        ) ? null : (
          <Button variant="text" size="large" onClick={onClickAddAnotherLang}>
            <AddLine />
            {t("button.addAnotherLanguage")}
          </Button>
        )}
      </ContentSection>
    </>
  );
};

export default EditTrustCenterFaq;
