import { Popover } from "@lockerpm/design";
import TimePopoverContent, {
  type TimeConditionEnum,
} from "./TimePopoverContent";
import { ReactComponent as DeleteBack2Line } from "#src/assets/images/icons/delete-back-2-line.svg";
import { ReactComponent as ArrowDownSLine } from "#src/assets/images/icons/arrow-down-s-line.svg";
import { ReactComponent as PlusIcon } from "#src/assets/images/icons/plus.svg";

interface ITimePopoverProps {
  label: string;
  timeCondition: TimeConditionEnum | null;
  open: boolean;
  textDisplay: string;
  setOpen: (value: boolean) => void;
  onChangeTime: (
    text: string,
    condition: TimeConditionEnum | null,
    from: number | null,
    to: number | null
  ) => void;
}

const TimePopover = ({
  label,
  timeCondition,
  open,
  textDisplay,
  setOpen,
  onChangeTime,
}: ITimePopoverProps) => {
  // TODO: remove open state since it's not needed to be controlled?
  return timeCondition ? (
    <div className="border border-light-grey text-hard-grey rounded-md flex items-center">
      <button
        className="ml-3 h-4 w-4 text-inherit"
        onClick={() => onChangeTime("", null, null, null)}
      >
        <DeleteBack2Line width={"1rem"} height={"1rem"} />
      </button>
      <span className="px-3 border-r border-light-grey whitespace-nowrap">
        {label}
      </span>
      <Popover
        content={
          <TimePopoverContent
            defaultCondition={timeCondition}
            applyChangeTime={onChangeTime}
            closePopover={() => setOpen(false)}
          />
        }
        open={open}
        onOpenChange={(state) => {
          setOpen(state);
        }}
        placement="bottomLeft"
        trigger="click"
      >
        <button className="text-primary font-medium-16 p-3 flex gap-2">
          <span className="whitespace-nowrap">{textDisplay}</span>
          <ArrowDownSLine
            width={"1rem"}
            height={"1rem"}
            className="fill-hard-grey"
          />
        </button>
      </Popover>
    </div>
  ) : (
    <Popover
      content={
        <TimePopoverContent
          defaultCondition={timeCondition}
          applyChangeTime={onChangeTime}
          closePopover={() => setOpen(false)}
        />
      }
      open={open}
      onOpenChange={(state) => {
        setOpen(state);
      }}
      placement="bottomLeft"
      trigger="click"
    >
      <button className="border border-dashed border-light-grey text-hard-grey rounded-md flex gap-3 p-3 items-center">
        <PlusIcon width={"1rem"} height={"1rem"} />
        <span className="whitespace-nowrap">{label}</span>
      </button>
    </Popover>
  );
};

export default TimePopover;
