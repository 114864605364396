import { Popover } from "@lockerpm/design";
import { ReactComponent as CloseLine } from "#src/assets/images/icons/close-line.svg";
import { getTagColorNumber, legendTailwindString } from "./utils";

type ITagProps = {
  name: string;
  noLegend?: boolean;
};

const styleTailwindString: { [S in "filled" | "outlined"]: string } = {
  filled: "bg-label-blue",
  outlined: "bg-inherit border border-light-grey",
};

interface IRoundedTagProps extends ITagProps {
  style?: "filled" | "outlined";
  onClose?: () => void;
}

/** Implemented since the Tag from Antd didn't match the shape and I can't find any customizations that allow us to change it
 * We currently have 10 legend colors, any legendIds bigger than 10 will be hashed.
 * @param name - the text displayed in the tag
 * @param style - (optional) choose between "filled" and "outlined". Default is "filled".
 * @param onClose - (optional) if this function is passed here the tag will have an X button, click the X button to trigger this `onClose` function
 * @param noLegend - (optional)
 */
export const RoundedTag = ({
  name = "Hashtag",
  style = "filled",
  onClose,
  noLegend = false,
}: IRoundedTagProps) => {
  return name ? (
    <div
      className={`rounded-full ${styleTailwindString[style]} h-fit w-fit px-2 py-1 flex items-center gap-1 whitespace-nowrap`}
    >
      {noLegend ? null : (
        <div
          className={`rounded-full h-2 w-2 ${
            legendTailwindString[getTagColorNumber(name)]
          }`}
        />
      )}
      <p className="font-medium-12 text-ellipsis overflow-hidden max-w-[10rem]">
        {name}
      </p>
      {onClose ? (
        <button
          onClick={(e) => {
            e.stopPropagation();
            onClose();
          }}
          className="flex justify-center items-center"
        >
          <CloseLine width="0.75rem" height="0.75rem" />
        </button>
      ) : null}
    </div>
  ) : null;
};

export const RoundedSeeMoreTags = ({ tagList }: { tagList: ITagProps[] }) => {
  if (!tagList || tagList.length <= 0) return null;

  return (
    <Popover
      placement="bottomLeft"
      content={
        <div className="flex gap-x-1 gap-y-2 max-w-[44rem] flex-wrap">
          {tagList.map((item, index) => {
            return (
              <RoundedTag key={`popover-tag-${item.name}-${index}`} {...item} />
            );
          })}
        </div>
      }
      trigger="hover"
    >
      <button className="h-fit">
        <RoundedTag name={`+${tagList.length}`} noLegend />
      </button>
    </Popover>
  );
};
