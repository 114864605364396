import { configureStore } from "@reduxjs/toolkit";
import authReducer from "./slices/auth";
import { trustCenterReducer } from "./slices/trustCenter";
import workspaceReducer from "./slices/workspace";

const store = configureStore({
  reducer: {
    auth: authReducer,
    trustCenter: trustCenterReducer,
    workspace: workspaceReducer,
  },
});

export default store;

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
