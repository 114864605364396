// Libraries
import { useMemo, useState } from "react";
import { useLocation, useSearchParams } from "react-router-dom";

// General
import constants from "#src/config/constants";
import type { IFilterItem } from "#src/@types/common";

// Components
import PaginationBar from "#src/components/common/helper/PaginationBar";
import useFetchPaginated from "#src/hooks/useFetchPaginated";

// API-related
import devicesServices, { type IHostItem } from "#src/services/devices";

// Children
import HostFilter from "./Filter";
import HostTableGrid from "./Table";

interface IHostTableProps {
  workspaceId: string;
  refreshHostStatistics: () => void;
}

const HostTable = ({ workspaceId, refreshHostStatistics }: IHostTableProps) => {
  const [searchParams] = useSearchParams();
  const softwareInitId = searchParams.get("program_id");
  const policyInitId = searchParams.get("policy_compliance_id");
  const { state } = useLocation();
  const softwareInitName =
    typeof state === "object" &&
    state !== null &&
    Object.hasOwn(state, "program_name")
      ? state.program_name
      : softwareInitId;
  const policyInitName =
    typeof state === "object" &&
    state !== null &&
    Object.hasOwn(state, "policy_compliance_name")
      ? state.policy_compliance_name
      : policyInitId;

  const [os, setOs] = useState<IFilterItem | null>(null);
  const [hostStatus, setHostStatus] = useState<IFilterItem | null>(null);
  const [policyCompliance, setPolicyCompliance] = useState<IFilterItem | null>(
    null
  );
  const [rules, setRules] = useState<IFilterItem | null>(
    policyInitId
      ? {
          key: policyInitId,
          value: policyInitId,
          getLabel: () => policyInitName,
        }
      : null
  );
  const [software, setSoftware] = useState<IFilterItem | null>(
    softwareInitId
      ? {
          key: softwareInitId,
          value: softwareInitId,
          getLabel: () => softwareInitName,
        }
      : null
  );
  const [tag, setTag] = useState<IFilterItem | null>(null);
  const [selectedHosts, setSelectedHosts] = useState<IHostItem[]>([]);

  const [selectedPage, setSelectedPage] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(constants.DEFAULT_PAGE_SIZE);

  const [searchKeyword, setSearchKeyword] = useState<string>("");

  const fetchParams = useMemo<Parameters<typeof devicesServices.list_hosts>>(
    () => [
      workspaceId,
      {
        os: os?.value,
        host_status: hostStatus?.value,
        tag: tag?.value,
        policy_compliance: policyCompliance?.value,
        policy_compliance_id: rules?.value,
        program_id: software?.value,
        q: searchKeyword,
        page: selectedPage,
        size: pageSize,
      },
    ],
    [
      workspaceId,
      os,
      hostStatus,
      tag,
      policyCompliance,
      rules,
      software,
      searchKeyword,
      selectedPage,
      pageSize,
    ]
  );

  const onChangeOsSelection = (selected: typeof os) => {
    setOs(selected);
    setSelectedPage(1);
  };

  const onChangeHostStatusSelection = (selected: typeof hostStatus) => {
    setHostStatus(selected);
    setSelectedPage(1);
  };

  const onChangeTagSelection = (selected: typeof tag) => {
    setTag(selected);
    setSelectedPage(1);
  };

  const onChangePolicyComplianceSelection = (
    selected: typeof policyCompliance
  ) => {
    setPolicyCompliance(selected);
    setSelectedPage(1);
  };

  const onChangeRulesSelection = (selected: typeof rules) => {
    setRules(selected);
    setSelectedPage(1);
  };

  const onChangeSoftwareSelection = (selected: typeof software) => {
    setSoftware(selected);
    setSelectedPage(1);
  };

  const onChangePage = (selectedPage: number) => {
    setSelectedPage(selectedPage);
    setSelectedHosts([]);
  };

  const onChangePageSize = (size: number) => {
    setPageSize(size);
    setSelectedHosts([]);
    setSelectedPage(1);
  };

  const onChangeSearchKeyword = (keyword: string) => {
    setSearchKeyword(keyword);
    setSelectedPage(1);
  };

  const onChangeSelectedHosts = (selected: IHostItem) => {
    if (selectedHosts.map((item) => item.id).includes(selected.id)) {
      setSelectedHosts((prev) => [
        ...prev.filter((item) => item.id !== selected.id),
      ]);
    } else {
      setSelectedHosts((prev) => [...prev, selected]);
    }
  };

  const onSelectAllDomains = () => {
    if (hostList) {
      setSelectedHosts(hostList);
    }
  };

  const onDeselectAllDomains = () => {
    setSelectedHosts([]);
  };

  const {
    list: hostList,
    count: hostCount,
    isLoading,
    updateData: updateHostList,
  } = useFetchPaginated(devicesServices.list_hosts, fetchParams);

  return (
    <div className="flex flex-col gap-6">
      <HostFilter
        workspaceId={workspaceId}
        os={os}
        hostStatus={hostStatus}
        tag={tag}
        policyCompliance={policyCompliance}
        rules={rules}
        software={software}
        searchKeyword={searchKeyword}
        totalCount={hostCount}
        selectedCount={selectedHosts.length}
        onChangeOsSelection={onChangeOsSelection}
        onChangeHostStatusSelection={onChangeHostStatusSelection}
        onChangeTagSelection={onChangeTagSelection}
        onChangePolicyComplianceSelection={onChangePolicyComplianceSelection}
        onChangeRulesSelection={onChangeRulesSelection}
        onChangeSoftwareSelection={onChangeSoftwareSelection}
        onChangeSearchKeyword={onChangeSearchKeyword}
        onSelectAllDomains={onSelectAllDomains}
        onDeselectAllDomains={onDeselectAllDomains}
      />
      <HostTableGrid
        workspaceId={workspaceId}
        isLoading={isLoading}
        hostList={hostList}
        selectedHostIds={selectedHosts.map((host) => host.id)}
        onChangeSelectedHosts={onChangeSelectedHosts}
        refreshHostList={() => {
          updateHostList();
          refreshHostStatistics();
          setSelectedPage(1);
        }}
      />
      <PaginationBar
        numOfResult={hostCount}
        selectedPage={selectedPage}
        pageSize={pageSize}
        onChangePage={onChangePage}
        onChangePageSize={onChangePageSize}
      />
    </div>
  );
};

export default HostTable;
