import i18next from "i18next";

export const COMMENT_EDITOR_TEXTAREA_ID = "comment-editor-textarea";

const fixedTFunc = i18next.getFixedT(
  null,
  "vulnerabilities",
  "detailSection.commentEditor"
);

export const CommentTypeEnum = {
  COMMENT: "COMMENT_TYPE_ENUM_COMMENT",
  NOTE: "COMMENT_TYPE_ENUM_NOTE",
};

export const commentTypeDropdownItems = [
  {
    key: CommentTypeEnum.COMMENT,
    getLabel: () => fixedTFunc("addAComment"),
  },
  {
    key: CommentTypeEnum.NOTE,
    getLabel: () => fixedTFunc("addANote"),
  },
];
