// Libraries
import dayjs from "dayjs";
import { Tooltip } from "@lockerpm/design";

// Components
import { TableRowWrapper, TableDataCell } from "#src/components/common/Table";
import SeveritySelect from "#src/components/common/SeveritySelect";
import { SubStatusSelect } from "#src/components/common/SubStatusSelect";
import { SourceLabel } from "#src/components/common/labels";
import TooltipInnerWithCopy from "#src/components/common/TooltipInnerWithCopy";

// API-related
import type {
  ILeakedDataAccountDetails,
  ILeakedDataItem,
} from "#src/services/dataLeak";

// Children
import { getDomainFromUrl } from "../../utilities";

interface IDataLeakAccountTableRowProps {
  workspaceId: string;
  data: ILeakedDataItem<ILeakedDataAccountDetails>;
  onClickViewSource: (
    source: ILeakedDataItem<ILeakedDataAccountDetails>["source"]
  ) => void;
  onChangeSeverity: (leakedDataId: number, severity: string) => void;
  onChangeSubStatus: (leakedDataId: number, substatus: string) => void;
}

const DataLeakAccountTableRow = ({
  workspaceId,
  data,
  onClickViewSource,
  onChangeSeverity,
  onChangeSubStatus,
}: IDataLeakAccountTableRowProps) => {
  return (
    <TableRowWrapper
      to={`/${workspaceId}/vulnerabilities?assessment_type=data_leak&vuln_id=${data.vuln_id}`}
    >
      <TableDataCell>
        {data.details.url ? (
          <Tooltip title={<TooltipInnerWithCopy value={data.details.url} />}>
            <span className="overflow-hidden text-ellipsis">
              {getDomainFromUrl(data.details.url)}
            </span>
          </Tooltip>
        ) : null}
      </TableDataCell>
      <TableDataCell>
        {data.details.username ? (
          <Tooltip
            title={<TooltipInnerWithCopy value={data.details.username} />}
          >
            <span className="overflow-hidden text-ellipsis">
              {data.details.username}
            </span>
          </Tooltip>
        ) : null}
      </TableDataCell>
      <TableDataCell>
        {data.details.password ? (
          <Tooltip
            title={<TooltipInnerWithCopy value={data.details.password} />}
          >
            <span className="overflow-hidden text-ellipsis line-clamp-3">
              {data.details.password}
            </span>
          </Tooltip>
        ) : null}
      </TableDataCell>
      <TableDataCell>
        {data.source.length > 0 ? (
          <SourceLabel
            onClick={(e) => {
              e.preventDefault();
              onClickViewSource(data.source);
            }}
          >
            {getDomainFromUrl(data.source[0])}
          </SourceLabel>
        ) : null}
        {data.source.length > 1 ? (
          <SourceLabel
            onClick={(e) => {
              e.preventDefault();
              onClickViewSource(data.source);
            }}
          >
            +{data.source.length - 1}
          </SourceLabel>
        ) : null}
      </TableDataCell>
      <TableDataCell>
        <SeveritySelect
          severityValue={data.severity ? data.severity.toUpperCase() : "NONE"}
          updateData={(severity) => {
            if (severity) {
              onChangeSeverity(data.id, severity);
            }
          }}
        />
      </TableDataCell>
      <TableDataCell>{data.keyword}</TableDataCell>
      <TableDataCell>
        <SubStatusSelect
          subStatusValue={data.sub_status}
          updateData={(substatus) => {
            if (substatus) {
              onChangeSubStatus(data.id, substatus);
            }
          }}
        />
      </TableDataCell>
      <TableDataCell>
        {dayjs.unix(data.created_time).format("HH:mm DD.MM.YYYY")}
      </TableDataCell>
    </TableRowWrapper>
  );
};

export default DataLeakAccountTableRow;
