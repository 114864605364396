const patterns = {
  EMAIL:
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
  PHONE: /^[+]?[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{4,6}$/,
  // "Link" is unused
  LINK: /^[(http(s)?)://(www.)?a-zA-Z0-9@:%._+~#=-]{2,256}\.[a-z]{2,256}\b([-a-zA-Z0-9@:%_+.~#?&//=-]*)$/,
  IP: /^((25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)(\.|$)){4}$/,
  // "Username" is unused
  USERNAME: /^[a-zA-Z0-9]+$/,
  // "Port" is unused
  PORT: /^((6553[0-5])|(655[0-2][0-9])|(65[0-4][0-9]{2})|(6[0-4][0-9]{3})|([1-5][0-9]{4})|([0-5]{0,5})|([0-9]{1,4}))$/gi,
  DOMAIN:
    /(?:[a-z0-9](?:[a-z0-9-]{0,61}[a-z0-9])?\.)+[a-z0-9][a-z0-9-]{0,61}[a-z0-9]/,
  // "External URL" is not used
  EXTERNAL_URL: /^(https?:|mailto:|tel:)/,
};

export default patterns;
