// Libraries
import { useTranslation } from "react-i18next";

// Common components
import { type IPentestProjectDetails } from "#src/services/pentest";

// Children
import MsspPentestDetailsScopeTable from "./Table";

interface IMsspPentestDetailsScopeProps {
  pentestDetails: IPentestProjectDetails;
}

const MsspPentestDetailsScope = ({
  pentestDetails,
}: IMsspPentestDetailsScopeProps) => {
  const { t } = useTranslation("pentest", {
    keyPrefix: "page.pentestDetails.tab.scope",
  });

  return (
    <section className="flex flex-col gap-6 pt-6 w-2/3">
      <div className="flex flex-col gap-4">
        <h2>{t("title")}</h2>
        <span className="text-hard-grey">{t("info")}</span>
      </div>
      <MsspPentestDetailsScopeTable scope={pentestDetails.targets} />
    </section>
  );
};

export default MsspPentestDetailsScope;
