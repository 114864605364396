// Libraries
import { type ReactNode, useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import dayjs from "dayjs";
import { Tabs } from "@lockerpm/design";

// Resources
import { ReactComponent as LogoCyStack } from "#src/assets/images/logos/logo-cystack.svg";
import { ReactComponent as DeleteBin6Line } from "#src/assets/images/icons/delete-bin-6-line.svg";

// Components
import { Button, IconButton } from "#src/components/common/system/Button";
import { ContentSection, StickySection } from "#src/layouts/content";
import LoadingState from "#src/components/common/system/LoadingState";
import { toPascalCase } from "#src/utils/common";
import TabButtonLabel from "#src/components/common/helper/antdProps/Tabs/TabButtonLabel";
import { apiErrorHandler } from "#src/utils/apiErrorHandler";

// API-related
import pentestServices, {
  type IPentestProjectDetails,
} from "#src/services/pentest";
import { PentestDetailTabEnum } from "#src/components/pentest/details/enums";
import { PentestStatusResponseValue } from "#src/config/filter/pentest/value";

// Children
import DetailsOverview from "#src/components/pentest/details/OverviewTab";
import DetailsScope from "#src/components/pentest/details/ScopeTab";
import DetailsPolicy from "#src/components/pentest/details/PolicyTab";
import DetailsUpdates from "#src/components/pentest/details/UpdatesTab";
import DetailsMembers from "#src/components/pentest/details/MembersTab";
import DetailsMssp from "#src/components/pentest/details/MsspTab";
import { getColorByPentestStatus } from "#src/components/pentest/color";

const InfoItemLabel = ({ name, value }: { name: string; value: ReactNode }) => {
  return (
    <div className="text-hard-grey whitespace-nowrap flex gap-2 items-center">
      {name}
      <span className="text-dark-blue ml-2 font-medium-16">{value}</span>
    </div>
  );
};

const PentestDetails = () => {
  const { t } = useTranslation("pentest", { keyPrefix: "page.pentestDetails" });
  const { workspace: workspaceId, pentest: pentestAlias } = useParams<
    "workspace" | "pentest"
  >();

  const [activeTab, setActiveTab] = useState<string>();

  const [pentestDetails, setPentestDetails] =
    useState<IPentestProjectDetails | null>(null);

  const [isLoadingPentest, setLoadingPentest] = useState<boolean>(false);

  const fetchPentestDetails = useCallback(
    (callback?: (arg: IPentestProjectDetails) => void) => {
      if (workspaceId && pentestAlias) {
        setLoadingPentest(true);
        pentestServices
          .get_pentest_project_details(workspaceId, pentestAlias)
          .then((response) => {
            setPentestDetails(response);
            setLoadingPentest(false);
            if (callback) callback(response);
          })
          .catch((error) => {
            apiErrorHandler(error, {
              toastMessage: t("notification.loadPentest.fail"),
            });
          });
      }
    },
    [workspaceId, pentestAlias, t]
  );

  const onChangeTab = (key: string) => {
    setActiveTab(key);
  };

  const tabList: { key: string; label: ReactNode; children: ReactNode }[] =
    pentestDetails && workspaceId && pentestAlias
      ? [
          {
            key: PentestDetailTabEnum.OVERVIEW,
            label: <TabButtonLabel name={t("tab.overview.title")} />,
            children: (
              <DetailsOverview
                workspaceId={workspaceId}
                pentestAlias={pentestAlias}
              />
            ),
          },
          {
            key: PentestDetailTabEnum.SCOPE,
            label: <TabButtonLabel name={t("tab.scope.title")} />,
            children: (
              <DetailsScope
                workspaceId={workspaceId}
                pentestAlias={pentestAlias}
                pentestDetails={pentestDetails}
              />
            ),
          },
          {
            key: PentestDetailTabEnum.POLICY,
            label: <TabButtonLabel name={t("tab.policy.title")} />,
            children: (
              <DetailsPolicy
                workspaceId={workspaceId}
                pentestAlias={pentestAlias}
                pentestDetails={pentestDetails}
                onRefresh={fetchPentestDetails}
              />
            ),
          },
          {
            key: PentestDetailTabEnum.UPDATES,
            label: <TabButtonLabel name={t("tab.updates.title")} />,
            children: (
              <DetailsUpdates
                workspaceId={workspaceId}
                pentestAlias={pentestAlias}
              />
            ),
          },
          {
            key: PentestDetailTabEnum.MSSP,
            label: <TabButtonLabel name={t("tab.mssp.title")} />,
            children: (
              <DetailsMssp
                workspaceId={workspaceId}
                pentestAlias={pentestAlias}
                pentestStatus={pentestDetails.status}
                onRefresh={fetchPentestDetails}
              />
            ),
          },
          {
            key: PentestDetailTabEnum.MEMBERS,
            label: <TabButtonLabel name={t("tab.members.title")} />,
            children: (
              <DetailsMembers
                workspaceId={workspaceId}
                pentestAlias={pentestAlias}
              />
            ),
          },
        ].filter((item) => {
          if (
            pentestDetails.status === PentestStatusResponseValue.DRAFT ||
            pentestDetails.status === PentestStatusResponseValue.REQUESTED
          ) {
            if (
              item.key === PentestDetailTabEnum.OVERVIEW ||
              item.key === PentestDetailTabEnum.UPDATES
            ) {
              return false;
            }
          }
          return true;
        })
      : [];

  const onRequestPentest = () => {
    if (workspaceId && pentestAlias) {
      pentestServices
        .submit_pentest_project(workspaceId, pentestAlias)
        .then((response) => {
          if (response.alias) {
            fetchPentestDetails();
          }
        });
    }
  };

  const onCancelRequestPentest = () => {
    if (workspaceId && pentestAlias) {
      pentestServices
        .cancel_request_pentest_project(workspaceId, pentestAlias)
        .then((response) => {
          if (response.alias) {
            fetchPentestDetails();
          }
        });
    }
  };

  const onDeletePentestProject = () => {
    if (workspaceId && pentestAlias) {
      pentestServices
        .delete_pentest_project(workspaceId, pentestAlias)
        .then(() => {
          fetchPentestDetails();
        });
    }
  };

  useEffect(() => {
    let ignore = false;

    if (!ignore) {
      fetchPentestDetails();
    }

    return () => {
      ignore = true;
    };
  }, [fetchPentestDetails]);

  return workspaceId && pentestAlias && pentestDetails ? (
    isLoadingPentest ? (
      <LoadingState />
    ) : (
      <>
        <StickySection>
          <h1>{pentestDetails.org.name}</h1>
          <div className="flex gap-1">
            {pentestDetails.status.toLowerCase() === "draft" ? (
              <>
                <Button
                  variant="secondary"
                  size="large"
                  onClick={onRequestPentest}
                >
                  {t("button.sendRequest")}
                </Button>
                <IconButton variant="warning" onClick={onDeletePentestProject}>
                  <DeleteBin6Line />
                </IconButton>
              </>
            ) : null}
            {pentestDetails.status.toLowerCase() === "requested" ? (
              <>
                <Button
                  variant="warning"
                  size="large"
                  onClick={onCancelRequestPentest}
                >
                  {t("button.cancelRequest")}
                </Button>
              </>
            ) : null}
            {/* At status execution this screen has no permission */}
            {/* Status closure has no buttons */}
            {/* At status pause this screen has no permission */}
          </div>
        </StickySection>
        <ContentSection>
          <section className="p-6 border border-grey rounded-md flex flex-wrap gap-x-10 gap-y-3">
            <InfoItemLabel
              name={t("info.mssp")}
              value={
                <div className="flex items-center gap-2">
                  <LogoCyStack className="h-[1.125rem] w-[1.125rem]" />
                  {pentestDetails.mssp ? pentestDetails.mssp.name : "---"}
                </div>
              }
            />
            <InfoItemLabel
              name={t("info.status")}
              value={
                <div className="flex items-center gap-2">
                  <div
                    className={`w-2 h-2 rounded-sm rotate-45 ${getColorByPentestStatus(
                      pentestDetails.status
                    )}`}
                  />
                  {toPascalCase(pentestDetails.status)}
                </div>
              }
            />
            {pentestDetails.status === PentestStatusResponseValue.DRAFT ||
            pentestDetails.status ===
              PentestStatusResponseValue.REQUESTED ? null : (
              <InfoItemLabel
                name={t("info.vulnerabilities")}
                value={pentestDetails.vulnerabilities}
              />
            )}
            {pentestDetails.status ===
            PentestStatusResponseValue.DRAFT ? null : (
              <InfoItemLabel
                name={t("info.requestedDate")}
                value={dayjs
                  .unix(pentestDetails.created_time)
                  .format("MMMM D, YYYY")}
              />
            )}
          </section>
          <Tabs
            items={tabList}
            activeKey={activeTab}
            onChange={onChangeTab}
            destroyInactiveTabPane
          />
        </ContentSection>
      </>
    )
  ) : null;
};

export default PentestDetails;
