import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import en from "../locale/en.json";
import enAssetsDomains from "../locale/en/assets/domains.json";
import enAssetsIpAddress from "../locale/en/assets/ipAddress.json";
import enAssetsCertificates from "../locale/en/assets/certificates.json";
import enDevicesHosts from "../locale/en/devices/hosts.json";
import enDevicesPolicies from "../locale/en/devices/policies.json";
import enDevicesControl from "../locale/en/devices/control.json";
import enVulnerabilities from "../locale/en/vulnerabilities.json";
import enScans from "../locale/en/scans/allScans.json";
import enScanSchedules from "../locale/en/scans/schedules.json";
import enPentest from "../locale/en/pentest.json";
import enBugBounty from "../locale/en/bugBounty.json";
import enDataLeakDetection from "../locale/en/dataLeakDetection.json";
import enTrustCenterPolicy from "../locale/en/trustCenter/policy.json";
import enTrustCenterCompliance from "../locale/en/trustCenter/compliance.json";
import enTrustCenterControl from "../locale/en/trustCenter/control.json";
import enTrustCenterDocument from "../locale/en/trustCenter/document.json";
import enTrustCenterThirdParty from "../locale/en/trustCenter/thirdParty.json";
import enTrustCenterFaq from "../locale/en/trustCenter/faq.json";
import enTrustCenterConfiguration from "../locale/en/trustCenter/configuration.json";
import enTrustCenterRegisterDomain from "../locale/en/trustCenter/registerDomain.json";
import enTrustCenterOnboard from "../locale/en/trustCenter/onboard.json";
import enCommon from "../locale/en/common.json";
import enAccountSettings from "../locale/en/settings/account.json";
import enNotification from "../locale/en/settings/notification.json";
import enWorkspaceDetail from "../locale/en/settings/workspace/detail.json";
import enWorkspaceMember from "../locale/en/settings/workspace/member.json";
import enWorkspaceNewWorkspace from "../locale/en/settings/workspace/newWorkspace.json";
import enWorkspaceBilling from "../locale/en/settings/workspace/billing.json";
import enSidebar from "../locale/en/general/sidebar.json";
import enBreadcrumb from "../locale/en/general/breadcrumb.json";
import enSolutions from "../locale/en/solutions.json";

import vi from "../locale/vi.json";
import viAssetsDomains from "../locale/vi/assets/domains.json";
import viAssetsIpAddress from "../locale/vi/assets/ipAddress.json";
import viAssetsCertificates from "../locale/vi/assets/certificates.json";
import viDevicesHosts from "../locale/vi/devices/hosts.json";
import viDevicesPolicies from "../locale/vi/devices/policies.json";
import viDevicesControl from "../locale/vi/devices/control.json";
import viVulnerabilities from "../locale/vi/vulnerabilities.json";
import viScans from "../locale/vi/scans/allScans.json";
import viScanSchedules from "../locale/vi/scans/schedules.json";
import viPentest from "../locale/vi/pentest.json";
import viBugBounty from "../locale/vi/bugBounty.json";
import viDataLeakDetection from "../locale/vi/dataLeakDetection.json";
import viTrustCenterPolicy from "../locale/vi/trustCenter/policy.json";
import viTrustCenterCompliance from "../locale/vi/trustCenter/compliance.json";
import viTrustCenterControl from "../locale/vi/trustCenter/control.json";
import viTrustCenterDocument from "../locale/vi/trustCenter/document.json";
import viTrustCenterThirdParty from "../locale/vi/trustCenter/thirdParty.json";
import viTrustCenterFaq from "../locale/vi/trustCenter/faq.json";
import viTrustCenterConfiguration from "../locale/vi/trustCenter/configuration.json";
import viTrustCenterRegisterDomain from "../locale/vi/trustCenter/registerDomain.json";
import viTrustCenterOnboard from "../locale/vi/trustCenter/onboard.json";
import viCommon from "../locale/vi/common.json";
import viAccountSettings from "../locale/vi/settings/account.json";
import viNotification from "../locale/vi/settings/notification.json";
import viWorkspaceDetail from "../locale/vi/settings/workspace/detail.json";
import viWorkspaceMember from "../locale/vi/settings/workspace/member.json";
import viWorkspaceNewWorkspace from "../locale/vi/settings/workspace/newWorkspace.json";
import viWorkspaceBilling from "../locale/vi/settings/workspace/billing.json";
import viSidebar from "../locale/vi/general/sidebar.json";
import viBreadcrumb from "../locale/vi/general/breadcrumb.json";
import viSolutions from "../locale/vi/solutions.json";

import systemService from "../services/system";

export const resources = {
  en: {
    translation: en,
    assets: {
      domains: enAssetsDomains,
      ipAddress: enAssetsIpAddress,
      certificates: enAssetsCertificates,
    },
    devices: {
      hosts: enDevicesHosts,
      policies: enDevicesPolicies,
      control: enDevicesControl,
    },
    vulnerabilities: enVulnerabilities,
    scans: enScans,
    scanSchedules: enScanSchedules,
    pentest: enPentest,
    bugBounty: enBugBounty,
    dataLeakDetection: enDataLeakDetection,
    trustCenter: {
      policy: enTrustCenterPolicy,
      compliance: enTrustCenterCompliance,
      control: enTrustCenterControl,
      document: enTrustCenterDocument,
      thirdParty: enTrustCenterThirdParty,
      faq: enTrustCenterFaq,
      configuration: enTrustCenterConfiguration,
      registerDomain: enTrustCenterRegisterDomain,
      onboard: enTrustCenterOnboard,
    },
    common: enCommon,
    accountSettings: enAccountSettings,
    notification: enNotification,
    workspace: {
      detail: enWorkspaceDetail,
      member: enWorkspaceMember,
      newWorkspace: enWorkspaceNewWorkspace,
      billing: enWorkspaceBilling,
    },
    general: {
      sidebar: enSidebar,
      breadcrumb: enBreadcrumb,
    },
    solutions: enSolutions,
  },
  vi: {
    translation: vi,
    assets: {
      domains: viAssetsDomains,
      ipAddress: viAssetsIpAddress,
      certificates: viAssetsCertificates,
    },
    devices: {
      hosts: viDevicesHosts,
      policies: viDevicesPolicies,
      control: viDevicesControl,
    },
    vulnerabilities: viVulnerabilities,
    scans: viScans,
    scanSchedules: viScanSchedules,
    pentest: viPentest,
    bugBounty: viBugBounty,
    dataLeakDetection: viDataLeakDetection,
    trustCenter: {
      policy: viTrustCenterPolicy,
      compliance: viTrustCenterCompliance,
      control: viTrustCenterControl,
      document: viTrustCenterDocument,
      thirdParty: viTrustCenterThirdParty,
      faq: viTrustCenterFaq,
      configuration: viTrustCenterConfiguration,
      registerDomain: viTrustCenterRegisterDomain,
      onboard: viTrustCenterOnboard,
    },
    common: viCommon,
    accountSettings: viAccountSettings,
    notification: viNotification,
    workspace: {
      detail: viWorkspaceDetail,
      member: viWorkspaceMember,
      newWorkspace: viWorkspaceNewWorkspace,
      billing: viWorkspaceBilling,
    },
    general: {
      sidebar: viSidebar,
      breadcrumb: viBreadcrumb,
    },
    solutions: viSolutions,
  },
};

i18n.use(initReactI18next).init({
  resources,
  lng: systemService.getLanguage(),
  interpolation: {
    escapeValue: false,
  },
});

export default i18n;
