import type { Params, RouteObject } from "react-router-dom";
import i18next from "i18next";
import { pathname } from "#src/config/pathname";

import BugBounty from "#src/pages/bugBountyPrograms";
import BugBountyDetails from "#src/pages/bugBountyPrograms/details";
import AddBugBounty from "#src/pages/bugBountyPrograms/add";
import BugBountyDetailsIntegrationSettings from "#src/pages/bugBountyPrograms/integrationSettings";

const breadcrumbTFunc = i18next.getFixedT(null, "general", "breadcrumb");

export const bugBountyRoute: RouteObject = {
  path: pathname.BUG_BOUNTY_PROGRAMS,
  handle: {
    crumb: () => ({
      getText: () => breadcrumbTFunc("bugBounty.label"),
      path: pathname.BUG_BOUNTY_PROGRAMS,
    }),
  },
  children: [
    {
      index: true,
      element: <BugBounty />,
    },
    {
      path: pathname.BUG_BOUNTY_DETAILS,
      handle: {
        crumb: (params: Readonly<Params<string>>) => {
          return {
            getText: () =>
              Object.hasOwn(params, "bugbounty") ? params["bugbounty"] : "---",
            path: pathname.BUG_BOUNTY_DETAILS,
          };
        },
      },
      children: [
        {
          index: true,
          element: <BugBountyDetails />,
        },
        {
          path: pathname.BUG_BOUNTY_DETAILS_INTEGRATION_SETTINGS,
          handle: {
            crumb: (params: Readonly<Params<string>>) => ({
              getText: () =>
                breadcrumbTFunc("bugBounty.integration.label") +
                ": " +
                (Object.hasOwn(params, "integration")
                  ? params["integration"]
                  : "---"),
              path: pathname.BUG_BOUNTY_DETAILS_INTEGRATION_SETTINGS,
            }),
          },
          element: <BugBountyDetailsIntegrationSettings />,
        },
      ],
    },
    {
      path: pathname.ADD_BUG_BOUNTY,
      handle: {
        crumb: () => ({
          getText: () => breadcrumbTFunc("bugBounty.addBugBounty.label"),
          path: pathname.ADD_BUG_BOUNTY,
        }),
      },
      element: <AddBugBounty />,
    },
  ],
};
