import { useTranslation } from "react-i18next";

interface IDaysOfWeekToggleProps {
  daysOfWeek: number[];
  onToggleDayOfWeek: (key: number) => void;
}

const DaysOfWeekToggleGroup = ({
  daysOfWeek,
  onToggleDayOfWeek,
}: IDaysOfWeekToggleProps) => {
  const { t } = useTranslation("scans", {
    keyPrefix: "drawer.addScan.field.repeatBy.daysOfWeek",
  });
  const daysOfWeekItems = [
    {
      key: 0,
      label: t("sun"),
    },
    {
      key: 1,
      label: t("mon"),
    },
    {
      key: 2,
      label: t("tue"),
    },
    {
      key: 3,
      label: t("wed"),
    },
    {
      key: 4,
      label: t("thu"),
    },
    {
      key: 5,
      label: t("fri"),
    },
    {
      key: 6,
      label: t("sat"),
    },
  ];

  return (
    <div className="grid grid-cols-7 font-medium-14">
      {daysOfWeekItems.map((item) => (
        <button
          key={`repeatedBy-dayOfWeek-${item.key}`}
          className={`flex justify-center items-center py-2 border-y border-r first:border-l border-grey first:rounded-l-md last:rounded-r-md ${
            daysOfWeek.includes(item.key)
              ? "bg-primary text-white"
              : "text-hard-grey"
          }`}
          onClick={() => onToggleDayOfWeek(item.key)}
        >
          {item.label}
        </button>
      ))}
    </div>
  );
};

export default DaysOfWeekToggleGroup;
