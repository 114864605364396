import { useTranslation } from "react-i18next";
import type { IChartTooltipGetter } from "#src/@types/chart";

export const getChartTooltip: IChartTooltipGetter = ({
  chartPlot,
  totalValue,
}) => {
  const { t } = useTranslation("common", { keyPrefix: "chart.tooltip" });

  return chartPlot ? (
    <div
      style={{ backgroundColor: chartPlot.fill }}
      className="rounded-md p-3 shadow-lg whitespace-pre text-white font-medium-14"
    >
      <p>{chartPlot.name}</p>
      <p>
        {t("ticketAmount", {
          count: chartPlot.value,
          percent: ((chartPlot.value / totalValue) * 100).toFixed(),
        })}
      </p>
    </div>
  ) : null;
};
