import type { IFilterItem } from "#src/@types/common";
import i18next from "i18next";
import {
  ContractStatusEnum,
  ContractTypeEnum,
  MemberRoleEnum,
  PaymentMethodEnum,
  PaymentStatusEnum,
  ServiceStatusEnum,
} from "./enum";
import {
  ContractStatusValue,
  ContractTypeValue,
  PaymentMethodValue,
  PaymentStatusValue,
  ServiceStatusValue,
  WorkspaceMemberRoleValue,
} from "./value";

export const memberRoleItems: IFilterItem[] = [
  {
    key: MemberRoleEnum.MEMBER,
    value: WorkspaceMemberRoleValue.MEMBER,
    getLabel: () => i18next.t("filter.memberRole.member"),
  },
  {
    key: MemberRoleEnum.ADMIN,
    value: WorkspaceMemberRoleValue.ADMIN,
    getLabel: () => i18next.t("filter.memberRole.admin"),
  },
  {
    key: MemberRoleEnum.OWNER,
    value: WorkspaceMemberRoleValue.OWNER,
    getLabel: () => i18next.t("filter.memberRole.owner"),
  },
];

export const serviceStatusItems: IFilterItem[] = [
  {
    key: ServiceStatusEnum.ACTIVE,
    value: ServiceStatusValue.ACTIVE,
    getLabel: () => i18next.t("filter.serviceStatus.active"),
  },
  {
    key: ServiceStatusEnum.EXPIRING,
    value: ServiceStatusValue.EXPIRING,
    getLabel: () => i18next.t("filter.serviceStatus.expiring"),
  },
  {
    key: ServiceStatusEnum.EXPIRED,
    value: ServiceStatusValue.EXPIRED,
    getLabel: () => i18next.t("filter.serviceStatus.expired"),
  },
  {
    key: ServiceStatusEnum.ALL,
    value: undefined,
    getLabel: () => i18next.t("filter.all"),
  },
];

export const paymentMethodItems: IFilterItem[] = [
  {
    key: PaymentMethodEnum.BANKING,
    value: PaymentMethodValue.BANKING,
    getLabel: () => i18next.t("filter.paymentMethod.banking"),
  },
  {
    key: PaymentMethodEnum.CARD,
    value: PaymentMethodValue.CARD,
    getLabel: () => i18next.t("filter.paymentMethod.card"),
  },
  {
    key: PaymentMethodEnum.ALL,
    value: undefined,
    getLabel: () => i18next.t("filter.all"),
  },
];

export const paymentStatusItems: IFilterItem[] = [
  {
    key: PaymentStatusEnum.PAID,
    value: PaymentStatusValue.PAID,
    getLabel: () => i18next.t("filter.paymentStatus.paid"),
  },
  {
    key: PaymentStatusEnum.PROCESSING,
    value: PaymentStatusValue.PROCESSING,
    getLabel: () => i18next.t("filter.paymentStatus.processing"),
  },
  {
    key: PaymentStatusEnum.ALL,
    value: undefined,
    getLabel: () => i18next.t("filter.all"),
  },
];

export const contractStatusItems: IFilterItem[] = [
  {
    key: ContractStatusEnum.NOT_SIGNED,
    value: ContractStatusValue.NOT_SIGNED,
    getLabel: () => i18next.t("filter.contractStatus.notSigned"),
  },
  {
    key: ContractStatusEnum.SIGNED,
    value: ContractStatusValue.SIGNED,
    getLabel: () => i18next.t("filter.contractStatus.signed"),
  },
  {
    key: ContractStatusEnum.ALL,
    value: undefined,
    getLabel: () => i18next.t("filter.all"),
  },
];

export const contractTypeItems: IFilterItem[] = [
  {
    key: ContractTypeEnum.ESIGN,
    value: ContractTypeValue.ESIGN,
    getLabel: () => i18next.t("filter.contractType.esign"),
  },
  {
    key: ContractTypeEnum.DIRECT,
    value: ContractTypeValue.DIRECT,
    getLabel: () => i18next.t("filter.contractType.direct"),
  },
  {
    key: ContractTypeEnum.ALL,
    value: undefined,
    getLabel: () => i18next.t("filter.all"),
  },
];
