// Libraries
import { type ReactNode, useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Drawer, Modal, Tabs } from "@lockerpm/design";
import useWebSocket from "react-use-websocket";

// General
import endpoint from "#src/config/endpoint";
import constants from "#src/config/constants";

// Components
import { Button } from "#src/components/common/system/Button";
import TabButtonLabel from "#src/components/common/helper/antdProps/Tabs/TabButtonLabel";
import { ContentSection, StickySection } from "#src/layouts/content";
import CopyToClipboardButton from "#src/components/common/CopyToClipboardButton";
import { createToast } from "#src/components/common/system/toasts";
import { apiErrorHandler } from "#src/utils/apiErrorHandler";

// API-related
import devicesServices, { type IHostDetailItem } from "#src/services/devices";
import authServices from "#src/services/auth";

// Children
import ProfileSection from "#src/components/devices/hosts/details/ProfileSection";
import BasicInfoSection from "#src/components/devices/hosts/details/BasicInfoSection";
import HostDetailsDetails from "#src/components/devices/hosts/details/Tabs/DetailTab";
import HostDetailsSoftwares from "#src/components/devices/hosts/details/Tabs/SoftwaresTab";
import HostDetailsPolicies from "#src/components/devices/hosts/details/Tabs/PoliciesTab";
import { PolicyComplianceValue } from "#src/config/filter/device/value";

const DeviceHostDetails = () => {
  const { t } = useTranslation("devices", { keyPrefix: "hosts.page.details" });

  const { workspace: workspaceId, host: hostId } = useParams<
    "workspace" | "host"
  >();

  const accessToken = authServices.access_token() || "";

  const { lastJsonMessage } = useWebSocket<
    | {
        command: string;
        status: "success";
        result: { username: string; password: string };
      }
    | {
        command: string;
        status: "error";
        error: { code: string; detail: string };
      }
  >(`${endpoint.WEBSOCKET}/hosts/${hostId}?token=${accessToken}`);

  const [hostDetails, setHostDetails] = useState<IHostDetailItem | null>(null);
  const [failingPolicyCount, setFailingPolicyCount] = useState<number>(0);
  // Initial state is the first key on tabList. Should be careful when changing.
  const [activeTab, setActiveTab] = useState<string>("Tab-Details");

  const [openWaitingRemoteModal, setOpenWaitingRemoteModal] =
    useState<boolean>(false);
  const [openRemoteDeviceDrawer, setOpenRemoteDeviceDrawer] =
    useState<boolean>(false);
  const [remoteDeviceInfo, setRemoteDeviceInfo] = useState<{
    username: string;
    password: string;
  } | null>(null);

  const onChangeTab = (key: string) => {
    setActiveTab(key);
  };

  const fetchHostDetails = useCallback(() => {
    if (workspaceId && hostId && !isNaN(+hostId))
      devicesServices
        .get_host_details(workspaceId, +hostId)
        .then((response) => {
          setHostDetails(response);
        })
        .catch(apiErrorHandler);
  }, [workspaceId, hostId]);

  const onRemoteToDevice = () => {
    if (workspaceId && hostId && !isNaN(+hostId))
      devicesServices
        .remote_host(workspaceId, +hostId)
        .then((response) => {
          if (response.success) {
            setOpenWaitingRemoteModal(true);
            // This commented line only exists to test the drawer. Do not push.
            // setOpenRemoteDeviceDrawer(true);
          }
        })
        .catch((error) => {
          apiErrorHandler(error, {
            toastMessage: t("notification.onRemoteToDevice.fail"),
          });
        });
  };

  const onCloseConnection = () => {
    if (workspaceId && hostId && !isNaN(+hostId))
      devicesServices
        .remote_host(workspaceId, +hostId, { close: true })
        .then((response) => {
          if (response.success) {
            setOpenRemoteDeviceDrawer(false);
            setRemoteDeviceInfo(null);
          }
        })
        .catch((error) => {
          apiErrorHandler(error, {
            toastMessage: t("notification.onCloseConnection.fail"),
          });
        });
  };

  const onShowDeviceInfo = () => {
    setOpenRemoteDeviceDrawer(true);
  };

  // TODO: update with enum
  const tabList: { key: string; label: ReactNode; children: ReactNode }[] =
    workspaceId && hostDetails
      ? [
          {
            key: "Tab-Details",
            label: <TabButtonLabel name={t("tab.details.title")} />,
            children: <HostDetailsDetails hostDetails={hostDetails} />,
          },
          {
            key: "Tab-Softwares",
            label: <TabButtonLabel name={t("tab.softwares.title")} />,
            children: (
              <HostDetailsSoftwares
                hostDetails={hostDetails}
                workspaceId={workspaceId}
              />
            ),
          },
          {
            key: "Tab-Policies",
            label: (
              <TabButtonLabel
                name={t("tab.policies.title")}
                count={failingPolicyCount}
              />
            ),
            children: (
              <HostDetailsPolicies
                workspaceId={workspaceId}
                hostId={hostDetails.id}
                failingCount={failingPolicyCount}
              />
            ),
          },
        ]
      : [];

  useEffect(() => {
    let ignore = false;

    if (!ignore) {
      fetchHostDetails();
    }

    return () => {
      ignore = true;
    };
  }, [fetchHostDetails]);

  useEffect(() => {
    let ignore = false;

    if (!ignore) {
      if (workspaceId && hostId && !isNaN(+hostId)) {
        devicesServices
          .list_host_policies(workspaceId, +hostId, {
            policy_compliance_status: PolicyComplianceValue.FAILED,
            q: undefined,
            page: undefined,
            size: constants.DEFAULT_PAGE_SIZE,
          })
          .then((response) => {
            setFailingPolicyCount(response.count);
          })
          .catch((error) => {
            apiErrorHandler(error, {
              toastMessage: t("notification.fetchHostPolicies.fail"),
            });
          });
      }
    }

    return () => {
      ignore = true;
    };
  }, [workspaceId, hostId, t]);

  useEffect(() => {
    if (
      lastJsonMessage &&
      Object.hasOwn(lastJsonMessage, "status") &&
      Object.hasOwn(lastJsonMessage, "command")
    ) {
      if (
        lastJsonMessage.status === "success" &&
        lastJsonMessage.command === "remote_device" &&
        // Only open the drawer if user is waiting for it. Don't randomly open every time WebSocket return something.
        openWaitingRemoteModal
      ) {
        setRemoteDeviceInfo(lastJsonMessage?.result);
        setOpenWaitingRemoteModal(false);
        setOpenRemoteDeviceDrawer(true);
      }
      if (lastJsonMessage.status === "error") {
        setOpenWaitingRemoteModal(false);
        // TODO: These 2 notifications should not be fade off by themselves. Require user interaction here.
        if (lastJsonMessage?.error?.code === "remote_control_not_found") {
          createToast({
            type: "error",
            message: "remote_control_not_found",
          });
        }
        if (lastJsonMessage?.error?.code === "remote_control_client_deny") {
          createToast({
            type: "error",
            message: "remote_control_client_deny",
          });
        }
      }
    }
  }, [lastJsonMessage, openWaitingRemoteModal]);

  return hostDetails ? (
    <>
      <StickySection>
        <ProfileSection details={hostDetails} />
      </StickySection>
      <ContentSection>
        <BasicInfoSection
          details={hostDetails}
          isRemoting={!!remoteDeviceInfo}
          onRemoteToDevice={onRemoteToDevice}
          onShowDeviceInfo={onShowDeviceInfo}
        />
        <Tabs
          items={tabList}
          activeKey={activeTab}
          onChange={onChangeTab}
          destroyInactiveTabPane
        />
      </ContentSection>
      <Modal
        open={openWaitingRemoteModal}
        onCancel={() => {
          setOpenWaitingRemoteModal(false);
          createToast({
            type: "warning",
            message: t("notification.remoteRequestCancelled"),
          });
        }}
        title={null}
        centered
        footer={null}
        width="44rem"
      >
        <div className="flex justify-center font-medium-18">
          {t("modal.waitingToRemote.waitingForResponseFrom") +
            " " +
            hostDetails.name}
        </div>
        {/* TODO: loader */}
      </Modal>
      <Drawer
        open={openRemoteDeviceDrawer}
        onClose={() => {
          setOpenRemoteDeviceDrawer(false);
        }}
        closable={false}
        title={
          <h2>
            {t("drawer.remoteDevice.remoteTo") + " "}
            <span className="text-primary">{hostDetails.name}</span>
          </h2>
        }
        extra={null}
        footer={
          <div className="flex flex-col gap-3">
            <Button className="w-full" size="large" disabled>
              {/* Should be a button to download the app */}
              {t("drawer.remoteDevice.button.download")}
            </Button>
            <Button
              className="w-full"
              size="large"
              onClick={onCloseConnection}
              variant="warning"
            >
              {t("drawer.remoteDevice.button.closeConnection")}
            </Button>
          </div>
        }
      >
        <div className="p-6 flex flex-col gap-6">
          {t("drawer.remoteDevice.pleaseDownloadRustdesk") +
            " " +
            hostDetails.name}
          <div className="flex flex-col gap-2">
            <h4>{t("drawer.remoteDevice.id")}</h4>
            <div className="flex items-center justify-between gap-1">
              <div className="bg-bright-grey flex items-center h-11 rounded-md flex-1 p-3">
                {remoteDeviceInfo?.username}
              </div>
              <CopyToClipboardButton value={remoteDeviceInfo?.username} />
            </div>
          </div>
          <div className="flex flex-col gap-2">
            <h4>{t("drawer.remoteDevice.password")}</h4>
            <div className="flex items-center justify-between gap-1">
              <div className="bg-bright-grey flex items-center h-11 rounded-md flex-1 p-3">
                {remoteDeviceInfo?.password}
              </div>
              <CopyToClipboardButton value={remoteDeviceInfo?.password} />
            </div>
          </div>
        </div>
      </Drawer>
    </>
  ) : null;
};

export default DeviceHostDetails;
