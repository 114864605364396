export const BugBountyMemberRoleResponseValue = {
  PROJECT_OWNER: "owner",
  VIEWER: "viewer",
  DEVELOPER: "developer",
  RESEARCHER: "researcher",
};

export const MsspBugBountyMemberRoleResponseValue = {
  SECURITY_MANAGER: "security_manager",
  PENTESTER: "pentester",
};

export const BugBountyStatusResponseValue = {
  DRAFT: "draft",
  REQUESTED: "requested",
  OPEN: "open",
  CLOSED: "closed",
  LOCKED: "locked",
};
