// Libraries
import { useEffect, useState } from "react";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";

// Components
import LoadingState from "#src/components/common/system/LoadingState";
import NoResultsState from "#src/components/common/states/NoResultsState";
import { apiErrorHandler } from "#src/utils/apiErrorHandler";

// API-related
import type { ICertificateItem } from "#src/services/assets/certificates";
import assetsServices, { type IAssetDetailItem } from "#src/services/assets";

// Children
import CertificateExpirationCard from "./ExpirationCard";
import CertificatePath from "./Path";
import CertificateRaw from "./Raw";
import CertificateEndpoints from "./Endpoints";
import CertificateDetails from "./Details";

dayjs.extend(relativeTime);

interface IDetailsCertificatesTabProps {
  workspaceId: string;
  assetId: number;
  certificateDetailsInit: IAssetDetailItem["certificates"];
}

const AssetsDetailsCertificates = ({
  workspaceId,
  assetId,
  certificateDetailsInit,
}: IDetailsCertificatesTabProps) => {
  const [certificates, setCertificates] = useState<Array<ICertificateItem>>([]);
  const [certificate, setCertificate] = useState<ICertificateItem | null>(null);
  const [isLoading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    let ignore = false;
    setLoading(true);

    if (!ignore) {
      assetsServices
        .get_asset_certificate(workspaceId, assetId)
        .then((response) => {
          setCertificates(response);
          setCertificate(response[0]);
          setLoading(false);
        })
        .catch(apiErrorHandler);
    }

    return () => {
      ignore = true;
    };
  }, [workspaceId, assetId]);

  return certificates.length === 0 || certificate === null ? (
    <section className="flex flex-col gap-12 pt-6">
      {certificateDetailsInit === null ? null : (
        <CertificateExpirationCard
          expireNotAfter={certificateDetailsInit.not_after}
          issuerName={certificateDetailsInit.issuer.name}
          name={certificateDetailsInit.common_names}
        />
      )}
      {isLoading ? <LoadingState /> : <NoResultsState />}
    </section>
  ) : (
    <section className="flex flex-col gap-12 pt-6">
      <div className="grid grid-cols-3 gap-x-6">
        <div className="col-span-2">
          <CertificateExpirationCard
            expireNotAfter={certificate.not_after}
            issuerName={certificate.issuer.name}
            name={certificate.common_names[0]}
            certificateId={certificate.id}
            certificates={certificates}
            setCertificate={setCertificate}
          />
        </div>
      </div>
      <CertificateDetails certificate={certificate} />
      <CertificatePath certificate={certificate} />
      <CertificateRaw certificate={certificate} />
      <CertificateEndpoints endpoints={certificate.endpoints} />
    </section>
  );
};

export default AssetsDetailsCertificates;
