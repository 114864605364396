// Libraries
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import { useTranslation } from "react-i18next";

// Components
import {
  TableDataCell,
  TableHeader,
  TableRowWrapper,
} from "#src/components/common/Table";
import type { ITableHeaderPropItem } from "#src/components/common/Table/TableHeader";
import LoadingState from "#src/components/common/system/LoadingState";
import NoResultsState from "#src/components/common/states/NoResultsState";

// API-related
import type { IScanApplicationItem } from "#src/services/scans/applications";

dayjs.extend(relativeTime);

/* @param data - data of the row, represent an application from selected domain */
const ApplicationTableRow = ({ data }: { data: IScanApplicationItem }) => {
  return (
    <TableRowWrapper>
      <TableDataCell>{data.program.name}</TableDataCell>
      <TableDataCell>{data.port}</TableDataCell>
      <TableDataCell>{data.install_location}</TableDataCell>
      <TableDataCell>{data.program.version}</TableDataCell>
      <TableDataCell>{data.program.cpe}</TableDataCell>
      <TableDataCell>
        {/* {dayjs.unix(data.last_found).format("HH:mm DD.MM.YYYY")} */}
        {dayjs.unix(data.last_found).fromNow()}
      </TableDataCell>
    </TableRowWrapper>
  );
};

interface IApplicationTableProps {
  applicationList: IScanApplicationItem[] | null;
  isLoading: boolean;
}

const ApplicationTableGrid = ({
  applicationList,
  isLoading,
}: IApplicationTableProps) => {
  const { t } = useTranslation("scans", {
    keyPrefix: "page.scanDetails.tab.applications",
  });
  const headers: ITableHeaderPropItem[] = [
    { name: t("headers.name"), align: "left" },
    { name: t("headers.port"), align: "left" },
    { name: t("headers.location"), align: "left" },
    { name: t("headers.version"), align: "left" },
    { name: t("headers.cpe"), align: "left" },
    { name: t("headers.lastFound"), align: "left" },
  ];

  return applicationList === null || isLoading ? (
    <LoadingState />
  ) : applicationList.length === 0 ? (
    <NoResultsState />
  ) : (
    <div className="grid grid-cols-[2fr_6rem_3fr_8rem_6rem_2fr]">
      <TableHeader title="scannedDomains-applications" headerProps={headers} />
      {applicationList.map((item) => (
        <ApplicationTableRow
          key={`tscannedDomains-applications-tr${item.port}-${item.program.name}`}
          data={item}
        />
      ))}
    </div>
  );
};

export default ApplicationTableGrid;
