// Libraries
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import { useMemo, useState } from "react";

// General
import constants from "#src/config/constants";
import type { IFilterItem } from "#src/@types/common";

// Components
import PaginationBar from "#src/components/common/helper/PaginationBar";
import useFetchPaginated from "#src/hooks/useFetchPaginated";

// API-related
import devicesServices, { type IHostDetailItem } from "#src/services/devices";

// Children
import HostSoftwaresFilter from "./Filter";
import HostSoftwaresTableGrid from "./Table";

dayjs.extend(relativeTime);

interface IHostDetailsSoftwaresProps {
  hostDetails: IHostDetailItem;
  workspaceId: string;
}

const HostDetailsSoftwares = ({
  hostDetails,
  workspaceId,
}: IHostDetailsSoftwaresProps) => {
  const [vulnerable, setVulnerable] = useState<IFilterItem | null>(null);

  const [searchKeyword, setSearchKeyword] = useState<string>("");
  const [selectedPage, setSelectedPage] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(constants.DEFAULT_PAGE_SIZE);

  const fetchParams = useMemo<
    Parameters<typeof devicesServices.list_host_softwares>
  >(
    () => [
      workspaceId,
      hostDetails.id,
      {
        vulnerable: vulnerable?.value,
        q: searchKeyword,
        page: selectedPage,
        size: pageSize,
      },
    ],
    [
      workspaceId,
      hostDetails,
      vulnerable,
      searchKeyword,
      selectedPage,
      pageSize,
    ]
  );

  const onChangeVulnerableSelection = (selected: typeof vulnerable) => {
    setVulnerable(selected);
    setSelectedPage(1);
  };

  const onChangeSearchKeyword = (keyword: string) => {
    setSearchKeyword(keyword);
    setSelectedPage(1);
  };

  const onChangePage = (selectedPage: number) => {
    setSelectedPage(selectedPage);
  };

  const onChangePageSize = (size: number) => {
    setPageSize(size);
    setSelectedPage(1);
  };

  const {
    list: hostSoftwareList,
    count: hostSoftwareCount,
    isLoading,
  } = useFetchPaginated(devicesServices.list_host_softwares, fetchParams);

  return (
    <div className="flex flex-col gap-6 pt-6">
      <HostSoftwaresFilter
        vulnerable={vulnerable}
        searchKeyword={searchKeyword}
        onChangeVulnerableSelection={onChangeVulnerableSelection}
        onChangeSearchKeyword={onChangeSearchKeyword}
      />
      <HostSoftwaresTableGrid
        hostSoftwareList={hostSoftwareList}
        isLoading={isLoading}
      />
      <PaginationBar
        numOfResult={hostSoftwareCount}
        selectedPage={selectedPage}
        pageSize={pageSize}
        onChangePage={onChangePage}
        onChangePageSize={onChangePageSize}
      />
    </div>
  );
};

export default HostDetailsSoftwares;
