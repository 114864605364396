import { useCallback, useEffect, useState } from "react";
import { useDebouncedCallback } from "use-debounce";
import constants from "#src/config/constants";
import { apiErrorHandler } from "#src/utils/apiErrorHandler";

// TODO: maybe we should not use this at all? Try loader and useFetcher
/** A convenient util to fetch data when we don't need any side effects. */
function useFetch<T>(
  fetch: (...arg: unknown[]) => Promise<T>,
  arg: Parameters<typeof fetch>
) {
  const [result, setResult] = useState<T | null>(null);
  const [isLoading, setLoading] = useState<boolean>(false);

  const fetchData = useCallback(
    (
      fetch: (...arg: unknown[]) => Promise<T>,
      ...arg: Parameters<typeof fetch>
    ) => {
      setLoading(true);
      fetch(...arg)
        .then((response) => {
          setResult(response);
          setLoading(false);
        })
        .catch(apiErrorHandler);
    },
    []
  );

  const debouncedFetchData = useDebouncedCallback(
    fetchData,
    constants.DEBOUNCE_TIME
  );

  useEffect(() => {
    debouncedFetchData(fetch, ...arg);
  }, [debouncedFetchData, fetch, arg]);

  return {
    result,
    isLoading,
    updateData: () => {
      fetchData(fetch, ...arg);
    },
  };
}

export default useFetch;
