import { ReactComponent as BoldIcon } from "#src/assets/images/icons/bold.svg";
import EditorButton from "../EditorButton";
import { COMMENT_EDITOR_TEXTAREA_ID } from "../constants";

const BoldButton = ({ disabled }: { disabled?: boolean }) => {
  return (
    <EditorButton
      onClick={() => {
        const editor = document.getElementById(
          COMMENT_EDITOR_TEXTAREA_ID
        ) as HTMLTextAreaElement;
        const selectionStart = editor.selectionStart;
        const selectionEnd = editor.selectionEnd;
        const selectionText = editor.value.substring(
          selectionStart,
          selectionEnd
        );

        const prefix = "**";
        const suffix = "**";

        editor.setRangeText(
          prefix + selectionText + suffix,
          selectionStart,
          selectionEnd
        );
        editor.setSelectionRange(
          selectionStart + prefix.length,
          selectionEnd + prefix.length
        );
      }}
      disabled={disabled}
    >
      <BoldIcon />
    </EditorButton>
  );
};

export default BoldButton;
