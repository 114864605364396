import { ReactComponent as LinkIcon } from "#src/assets/images/icons/link.svg";
import EditorButton from "../EditorButton";
import { COMMENT_EDITOR_TEXTAREA_ID } from "../constants";

const LinkButton = ({ disabled }: { disabled?: boolean }) => {
  return (
    <EditorButton
      onClick={() => {
        const editor = document.getElementById(
          COMMENT_EDITOR_TEXTAREA_ID
        ) as HTMLTextAreaElement;
        const selectionStart = editor.selectionStart;
        const selectionEnd = editor.selectionEnd;
        const selectionText = editor.value.substring(
          selectionStart,
          selectionEnd
        );

        const prefix = "[";
        const suffix = "](url)";

        editor.setRangeText(
          prefix + selectionText + suffix,
          selectionStart,
          selectionEnd
        );

        editor.setSelectionRange(
          selectionStart + "[](".length + selectionText.length,
          selectionStart + "[](url".length + selectionText.length
        );
      }}
      disabled={disabled}
    >
      <LinkIcon />
    </EditorButton>
  );
};

export default LinkButton;
