// Libraries
import { useTranslation } from "react-i18next";
import { Dropdown } from "@lockerpm/design";

// Resources
import { ReactComponent as LogoCyStack } from "#src/assets/images/logos/logo-cystack.svg";
import { ReactComponent as ArrowDownSLine } from "#src/assets/images/icons/arrow-down-s-line.svg";

// Components
import DropdownItemLabel from "#src/components/common/helper/antdProps/Dropdown/DropdownItemLabel";
import DropdownTriggerButton from "#src/components/common/helper/antdProps/Dropdown/DropdownTriggerButton";

// Children
import { BugBountyManagementModeEnum } from "../enum";

interface IAddBugBountyManagementModeProps {
  managementMode: string;
  onChangeManagementMode: (value: string) => void;
}

const AddBugBountyManagementMode = ({
  managementMode,
  onChangeManagementMode,
}: IAddBugBountyManagementModeProps) => {
  const { t } = useTranslation("bugBounty", {
    keyPrefix: "page.addBugBounty.managementMode",
  });

  return (
    <div className="flex flex-col gap-4">
      <h2>{t("title")}</h2>
      <div className="grid grid-cols-2 gap-6">
        <div className="p-6 flex flex-col gap-4">
          <label className="flex gap-3 font-medium-18 hover:cursor-pointer">
            <input
              type="radio"
              className="hover:cursor-pointer"
              checked={
                managementMode === BugBountyManagementModeEnum.BY_PROVIDER
              }
              onChange={() => {
                onChangeManagementMode(BugBountyManagementModeEnum.BY_PROVIDER);
              }}
            />
            {t("managedProvider.title")}
          </label>
          <span className="text-hard-grey">
            {t("managedProvider.description")}
          </span>
          <Dropdown
            menu={{
              // Currently we don't have any MSSP outside of CyStack. Should fetch this from resource instead,
              items: [
                {
                  key: "CyStack",
                  label: (
                    <DropdownItemLabel selected syncWidthId="provider-dropdown">
                      <LogoCyStack className="h-[1.125rem] w-[1.125rem]" />
                      CyStack
                    </DropdownItemLabel>
                  ),
                },
              ],
              onClick: ({ domEvent }) => {
                domEvent.preventDefault();
                domEvent.stopPropagation();
                // onClickItems(key, data);
              },
            }}
            trigger={["click"]}
          >
            <DropdownTriggerButton id="provider-dropdown">
              <div className="flex items-center gap-2">
                <LogoCyStack className="h-[1.125rem] w-[1.125rem]" />
                CyStack
              </div>
              <ArrowDownSLine />
            </DropdownTriggerButton>
          </Dropdown>
        </div>
        <div className="p-6 flex flex-col gap-4">
          <label className="flex gap-3 font-medium-18 hover:cursor-pointer">
            <input
              type="radio"
              className="hover:cursor-pointer"
              checked={
                managementMode === BugBountyManagementModeEnum.SELF_MANAGED
              }
              onChange={() => {
                onChangeManagementMode(
                  BugBountyManagementModeEnum.SELF_MANAGED
                );
              }}
            />
            {t("selfManaged.title")}
          </label>
          <span className="text-hard-grey">{t("selfManaged.description")}</span>
        </div>
      </div>
    </div>
  );
};

export default AddBugBountyManagementMode;
