// Libraries
import { Drawer } from "@lockerpm/design";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

// Resources
import { ReactComponent as CloseLine } from "#src/assets/images/icons/close-line.svg";

// General
import type { IDrawerAdapterProps } from "#src/@types/common";

// Components
import { Button, IconButton } from "#src/components/common/system/Button";
import { TagEditor } from "#src/components/common/Tags/TagEditor";
import { createToast } from "#src/components/common/system/toasts";
import { apiErrorHandler } from "#src/utils/apiErrorHandler";

// API-related
import devicesServices from "#src/services/devices";
import { TagTypeValue } from "#src/config/filter/tag/value";

const FooterButtons = ({
  onSave,
  onCancel,
}: {
  onSave: () => void;
  onCancel: () => void;
}) => {
  const { t } = useTranslation("assets", {
    keyPrefix: "domains.drawer.editSettings",
  });

  return (
    <div className="flex flex-col gap-3">
      <Button className="w-full" size="large" onClick={onSave}>
        {t("button.save")}
      </Button>
      <Button
        variant="secondary"
        className="w-full"
        size="large"
        onClick={() => {
          createToast({
            type: "info",
            message: t("notification.cancel.message"),
            detail: t("notification.cancel.description"),
          });
          onCancel();
        }}
      >
        {t("button.cancel")}
      </Button>
    </div>
  );
};

interface IEditSettingsDrawerProps extends IDrawerAdapterProps {
  selectedItemId: number;
}

const EditTagsDrawer = ({
  selectedItemId,
  open,
  onClose,
  workspaceId,
  onRefresh,
}: IEditSettingsDrawerProps) => {
  const { t } = useTranslation("assets", {
    keyPrefix: "domains.drawer.editSettings",
  });

  const [tagList, setTagList] = useState<{ name: string }[]>([]);

  const onChangeTags = (tags: { name: string }[]) => {
    setTagList(tags);
  };

  const onSaveTags = () => {
    devicesServices
      .update_host(workspaceId, selectedItemId, {
        tags: tagList.map((tag) => tag.name),
      })
      .then((response) => {
        if (response.success) {
          onRefresh();
          onClose();
        }
      })
      .catch((error) => {
        apiErrorHandler(error, {
          toastMessage: t("notification.saveSettings.fail"),
        });
      });
  };

  useEffect(() => {
    const fetchHostDetail = () => {
      devicesServices
        .get_host_details(workspaceId, selectedItemId)
        .then((response) => {
          setTagList(response.tags.map((tag) => ({ name: tag.name })));
        });
    };
    fetchHostDetail();
  }, [selectedItemId, workspaceId]);

  return (
    <Drawer
      open={open}
      onClose={onClose}
      closable={false}
      title={<h2>{t("title")}</h2>}
      extra={
        <IconButton variant="label-grey" size={9} onClick={onClose}>
          <CloseLine />
        </IconButton>
      }
      footer={<FooterButtons onSave={onSaveTags} onCancel={onClose} />}
      destroyOnClose={true}
    >
      <h4 className="pt-6 px-6">{t("section.tags.title")}</h4>
      <div className="flex flex-col gap-6 pt-3 pb-6 px-6">
        <TagEditor
          workspaceId={workspaceId}
          tagList={tagList}
          onChangeTags={onChangeTags}
          withInstruction
          type={TagTypeValue.HOST}
        />
      </div>
    </Drawer>
  );
};

export default EditTagsDrawer;
