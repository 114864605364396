import type { IPentestProjectDetails } from "#src/services/pentest";
import DOMPurify from "dompurify";
import { marked } from "marked";

interface IMsspPentestDetailsPolicyProps {
  pentestDetails: IPentestProjectDetails;
}

const MsspPentestDetailsPolicy = ({
  pentestDetails,
}: IMsspPentestDetailsPolicyProps) => {
  return (
    // TODO: might want to put this to something similar to a markdown preview component
    <section className="flex flex-col gap-6 pt-6">
      <div
        className="markdown-body"
        dangerouslySetInnerHTML={{
          __html: DOMPurify.sanitize(
            marked.parse(pentestDetails.metadata.en) as string
            // https://github.com/markedjs/marked/issues/3101 Marked cannot tell Typescript the async false option. Last update 17/01/2024
          ),
        }}
      ></div>
    </section>
  );
};

export default MsspPentestDetailsPolicy;
