import { useTranslation } from "react-i18next";
import MarkdownEditor from "#src/components/common/MarkdownEditor";

interface IAddBugBountyPolicyProps {
  policy: string;
  agreeTermsPolicies: boolean;
  onChangePolicy: (value: string) => void;
  onClickAgreeTermsPolicies: () => void;
}

const AddBugBountyPolicy = ({
  policy,
  agreeTermsPolicies,
  onChangePolicy,
  onClickAgreeTermsPolicies,
}: IAddBugBountyPolicyProps) => {
  const { t } = useTranslation("bugBounty", {
    keyPrefix: "page.addBugBounty.policy",
  });

  return (
    <div className="flex flex-col gap-4">
      <h2>{t("title")}</h2>
      <span className="text-hard-grey">{t("instruction")}</span>
      <MarkdownEditor
        className="font-regular-14-body bg-bright-blue"
        rows={5}
        value={policy}
        onChange={(e) => {
          onChangePolicy(e.currentTarget.value);
        }}
      />
      <div className="flex gap-3">
        <input
          type="radio"
          className="hover:cursor-pointer"
          checked={agreeTermsPolicies}
          onClick={() => {
            onClickAgreeTermsPolicies();
          }}
          readOnly
        />
        <span className="text-hard-grey">
          {t("iAgreeTo")}
          <a>{t("customerTerms")}</a>
          {t("and")}
          <a>{t("disclosurePolicy")}</a>
        </span>
      </div>
    </div>
  );
};

export default AddBugBountyPolicy;
