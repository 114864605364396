import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import { useTranslation } from "react-i18next";
import type { IScanDetailItem } from "#src/services/scans";
import { ReactComponent as InformationLine } from "#src/assets/images/icons/information-line.svg";
import i18next from "i18next";

dayjs.extend(relativeTime);

const ScanDetailsOverviewActivity = ({
  scanDetails,
}: {
  scanDetails: IScanDetailItem;
}) => {
  const { t } = useTranslation("scans", {
    keyPrefix: "page.scanDetails.tab.overview.card.activity",
  });

  const locale = i18next.language;

  return (
    <>
      <h2 className="flex items-center h-9 mb-5">{t("title")}</h2>
      {scanDetails.activity.length > 0 ? (
        <div className="grid grid-cols-2 gap-4">
          <div className="grid col-span-full grid-cols-subgrid text-hard-grey">
            <div>{t("event")}</div>
            <div>{t("created")}</div>
          </div>
          {/* Show only 5 activities. Not ideal. */}
          {scanDetails.activity.slice(0, 5).map((act, actId) => (
            <div
              key={`activity-${act.created_time}${actId}`}
              className="grid col-span-full grid-cols-subgrid"
            >
              <div className="flex items-center gap-x-3">
                <InformationLine className="h-5 w-5 min-h-[1.25rem] min-w-[1.25rem] fill-hard-grey" />
                <span className="overflow-hidden text-ellipsis whitespace-nowrap">
                  {Object.keys(act.description).includes(locale)
                    ? act.description[locale as keyof typeof act.description]
                    : act.description.en}
                </span>
              </div>
              <div>
                {dayjs.unix(act.created_time).format("HH:mm DD.MM.YYYY")}
              </div>
            </div>
          ))}
        </div>
      ) : (
        t("noActivities")
      )}
    </>
  );
};

export default ScanDetailsOverviewActivity;
