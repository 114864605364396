import { forwardRef, useRef, useState, type SVGAttributes } from "react";
import { createPortal } from "react-dom";
import type { IChartPlotProps, IChartTooltipGetter } from "#src/@types/chart";
import { generateKeyString } from "#src/utils/common";

// A standard size, we choose 200 so that the circle radius can be 100.
const SVG_SIZE = 200;
// Pie chart and donut chart are circles, so we need circle's radius.
const RADIUS = 100;
// Donut RADIUS - Donut's hole size.
const SMALL_RADIUS = 72;
// Our circle has the same size as the SVG, so this is the coordinates of its center.
const CIRCLE_CENTER = { x: RADIUS, y: RADIUS };

interface IDonutChartProps
  extends Omit<SVGAttributes<SVGSVGElement>, "viewBox"> {
  data: IChartPlotProps[];
  tooltip?: IChartTooltipGetter;
}

const DonutChart = forwardRef<SVGSVGElement, IDonutChartProps>(
  ({ data, tooltip, ...props }, ref) => {
    const [tooltipProps, setTooltipProps] = useState<{
      top: number;
      left: number;
      display: "none" | "block";
      plot: IChartPlotProps | null;
    }>({
      top: 0,
      left: 0,
      display: "none",
      plot: null,
    });

    const rootElement = document.getElementById("app");

    if (rootElement === null) {
      throw Error(
        "Thrown from modal: Root element is not found?? What have you done, frontend dev?"
      );
    }

    const chartId = useRef(props.id ? props.id : generateKeyString());

    const dataWithoutZero = data.filter((item) => item.value > 0);

    /** Angle starts from 12:00, then go clock-wise */
    const getCircleCoordFromAngle = (angle: number, radius: number) => {
      // coordinations in standard coordinate
      const standardX = Math.sin((angle * Math.PI) / 180);
      const standardY = -Math.cos((angle * Math.PI) / 180);

      // calculated coordinations in specific circle and svg
      const x = standardX * radius + CIRCLE_CENTER.x;
      const y = standardY * radius + CIRCLE_CENTER.y;

      return { x, y };
    };

    /** return a string as d in SVG Path Arc's parameters from angle. 0 degree is 12:00 in clock. We go clock-wise.
     * @param radius
     * @param startAngle start of sweeping angle for this arc. This related to the current coordinate of the cursor.
     * @param endAngle end of sweeping angle for this arc. If this is smaller than startAngle, the arc will be drawn counter-clockwise.
     */
    const declareArc = (
      radius: number,
      startAngle: number,
      endAngle: number
    ) => {
      // We want to draw circle so elipse's rotate angle can be whatever. We choose 0 as a default.
      const rotateAngle = 0;

      // If the angle of the arc is bigger than 180 degree, we will need the large arc, therefore this flag should be 1. Otherwise it should be 0.
      const largeArcFlag = Math.abs(startAngle - endAngle) % 360 > 180 ? 1 : 0;

      const sweepFlag = startAngle > endAngle ? 0 : 1;

      // calculated coordinations in specific circle and svg
      const { x, y } = getCircleCoordFromAngle(endAngle, radius);

      return `A ${radius} ${radius} ${rotateAngle} ${largeArcFlag} ${sweepFlag} ${x} ${y}`;
    };

    const totalValue = data.reduce((prev, cur) => {
      return prev + cur.value;
    }, 0);

    let accumulatedAngle = 0;

    const handleMouseMove = (
      item: IChartPlotProps,
      mouseX: number,
      mouseY: number
    ) => {
      setTooltipProps({
        // tooltip should show a bit to the down-right direction of the mouse.
        left: mouseX + 7,
        top: mouseY + 7,
        display: "block",
        plot: item,
      });
    };

    const handleMouseOut = () => {
      setTooltipProps({
        left: 0,
        top: 0,
        display: "none",
        plot: null,
      });
    };

    return (
      <>
        <svg
          ref={ref}
          height={"11.25rem"}
          width={"11.25rem"}
          {...props}
          viewBox={`0 0 ${SVG_SIZE} ${SVG_SIZE}`}
        >
          {dataWithoutZero.length > 1 ? (
            dataWithoutZero.map((item) => {
              const sweep = (item.value / totalValue) * 360;
              const startAngle = accumulatedAngle;
              const endAngle = startAngle + sweep;
              accumulatedAngle = endAngle;

              return (
                <path
                  key={`donut-${chartId.current}-${item.id}`}
                  fill={item.fill}
                  // We start drawing from 12:00, so first of all we move the cursor to that place.
                  d={`M ${getCircleCoordFromAngle(startAngle, RADIUS).x} ${
                    getCircleCoordFromAngle(startAngle, RADIUS).y
                  } ${declareArc(RADIUS, startAngle, endAngle)} L ${
                    getCircleCoordFromAngle(endAngle, SMALL_RADIUS).x
                  } ${
                    getCircleCoordFromAngle(endAngle, SMALL_RADIUS).y
                  } ${declareArc(SMALL_RADIUS, endAngle, startAngle)}`}
                  onMouseMove={(e) => {
                    handleMouseMove(item, e.pageX, e.pageY);
                  }}
                  onMouseOut={handleMouseOut}
                />
              );
            })
          ) : dataWithoutZero.length === 1 ? (
            <>
              {/* Draw a single donut using circle and mask, since using path to draw circle is weirdly hard */}
              <mask id={`donut-core-${chartId.current}`}>
                <rect
                  x={0}
                  y={0}
                  width={SVG_SIZE}
                  height={SVG_SIZE}
                  fill="white"
                />
                <circle
                  cx={CIRCLE_CENTER.x}
                  cy={CIRCLE_CENTER.y}
                  r={SMALL_RADIUS}
                />
              </mask>
              <circle
                cx={CIRCLE_CENTER.x}
                cy={CIRCLE_CENTER.y}
                r={RADIUS}
                fill={dataWithoutZero[0].fill}
                mask={`url(#donut-core-${chartId.current})`}
              />
            </>
          ) : null}
        </svg>
        {tooltip
          ? createPortal(
              <div
                style={{
                  top: tooltipProps.top,
                  left: tooltipProps.left,
                  display: tooltipProps.display,
                }}
                className="fixed z-10 pointer-events-none"
              >
                {tooltip({ chartPlot: tooltipProps.plot, totalValue })}
              </div>,
              rootElement
            )
          : null}
      </>
    );
  }
);

DonutChart.displayName = "DonutChart";

export default DonutChart;
