import type { RouteObject } from "react-router-dom";
import i18next from "i18next";
import { pathname } from "#src/config/pathname";

import TotalLeaksFound from "#src/pages/dataLeakDetection";

const breadcrumbTFunc = i18next.getFixedT(null, "general", "breadcrumb");

export const dataLeakRoute: RouteObject = {
  path: pathname.DATA_LEAK_DETECTION,
  handle: {
    crumb: () => ({
      getText: () => breadcrumbTFunc("dataLeak.label"),
      path: pathname.DATA_LEAK_DETECTION,
    }),
  },
  element: <TotalLeaksFound />,
};
