// Libraries
import { useTranslation } from "react-i18next";
import { Link, useParams } from "react-router-dom";

// Resources
import { ReactComponent as AddLine } from "#src/assets/images/icons/add-line.svg";
import { ReactComponent as GitRepositoryLine } from "#src/assets/images/icons/git-repository-line.svg";

// Components
import { Button } from "#src/components/common/system/Button";
import { ContentSection, StickySection } from "#src/layouts/content";
import { useCurrentWorkspaceRole } from "#src/hooks/useCurrentWorkspaceRole";

// API-related
import { WorkspaceMemberRoleValue } from "#src/config/filter/workspace/value";

// Children
import PentestList from "#src/components/pentest/PentestList";

const Pentest = () => {
  const { t } = useTranslation("pentest");

  const { workspace: workspaceId } = useParams<"workspace">();

  const workspaceRole = useCurrentWorkspaceRole(workspaceId);

  return workspaceId ? (
    <>
      <StickySection>
        <div className="flex flex-col gap-4">
          <h1>{t("title")}</h1>
          <div className="flex gap-2">
            <GitRepositoryLine
              width="1.25rem"
              height="1.25rem"
              className="fill-primary"
            />
            <a>{t("link.learnMore")}</a>
          </div>
        </div>
        {workspaceRole === WorkspaceMemberRoleValue.OWNER ? (
          <Link to={"add"}>
            <Button size="large">
              <AddLine />
              {t("button.newProject")}
            </Button>
          </Link>
        ) : null}
      </StickySection>
      <ContentSection>
        <PentestList workspaceId={workspaceId} />
      </ContentSection>
    </>
  ) : null;
};

export default Pentest;
