// Libraries
import dayjs from "dayjs";
import { useTranslation } from "react-i18next";
import { ReactComponent as ArrowRightUpLine } from "#src/assets/images/icons/arrow-right-up-line.svg";


// Components
import { TableDataCell } from "#src/components/common/Table";
import { TableRowWrapperAnchor } from "#src/components/common/Table/TableRowWrapper";
import { Button } from "#src/components/common/system/Button";

// API-related
import { ICertificateItemTableData } from "#src/services/assets/certificates";
import Expiration from "#src/components/common/Expiration";

interface IAssetsCertificatesTableRowProps {
  data: ICertificateItemTableData;
  onViewLinkedDomain: (value:ICertificateItemTableData) => void
}

/** Row template for Assets/Certificates table view, since this depends on each table and cannot be built into a common template
 * @param data - data of the row, represent a domain
 */
const CertificateTableRow = ({
  data,
  onViewLinkedDomain
}: IAssetsCertificatesTableRowProps) => {
  const { t } = useTranslation("assets", { keyPrefix: "certificates.page.certificates" });

  return (
    <TableRowWrapperAnchor to={`${data.id}`}>
      <TableDataCell>
        <div>
          <p className="text-dark-blue font-regular-16 mb-1 line-clamp-1">{data.subject_cn}</p>
          <p className="text-hard-grey font-medium-14">{data.subject_org}</p>
        </div>
      </TableDataCell>
      <TableDataCell>
        <div>
          <p className="text-dark-blue font-regular-16 mb-1 line-clamp-1">{data.issuer_cn}</p>
          <p className="text-hard-grey font-medium-14">{data.issuer_org}</p>
        </div>
      </TableDataCell>
      <TableDataCell>
        <div className="w-full flex flex-col justify-center items-center">
          <p className="text-dark-blue font-regular-16 mb-1 line-clamp-1">
            {data.not_after
            ? dayjs.unix(data.not_after).format("DD.MM.YYYY")
            : "---"}
          </p>
          <Expiration timestamp={data.not_after} className="flex items-center font-medium-14 gap-1" />
        </div>
      </TableDataCell>
      <TableDataCell>{data.key_alg ? data.key_alg : "-" }</TableDataCell>
      <TableDataCell>{data.key_size}</TableDataCell>
      <TableDataCell>
        {data.last_found
          ? dayjs.unix(data.last_found).format("DD.MM.YYYY")
          : "---"}
      </TableDataCell>
      <TableDataCell>
        {data.assets}
      </TableDataCell>
      <TableDataCell>
        <Button
          className="flex items-center gap-1 bg-transparent"
          variant="text"
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            onViewLinkedDomain(data);
          }}
        >
          <span>{t('view_all_domain')}</span>
          <ArrowRightUpLine className="w-4 h-4" />
        </Button>
      </TableDataCell>
    </TableRowWrapperAnchor>
  );
};

export default CertificateTableRow;
