// Libraries
import { useEffect } from "react";
import {
  createSearchParams,
  generatePath,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import Cookies from "js-cookie";

// General
import { pathname } from "#src/config/pathname";
import { updateUserInfo } from "#src/store/slices/auth";
import { useAppDispatch } from "#src/store/hooks";
import {
  selectWorkspace,
  updateWorkspaceList,
} from "#src/store/slices/workspace";
import { apiErrorHandler } from "#src/utils/apiErrorHandler";

// Components
import LoadingState from "#src/components/common/system/LoadingState";

// API-related
import workspaceServices from "#src/services/workspace";
import authServices from "#src/services/auth";

const Authenticate = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  useEffect(() => {
    // TODO: rewrite all of this with loader and better logic/conditions.
    // 1. Set token cookie
    // 2. Get current workspace (with cookie/workspace list)
    // 3. Navigate to return_url
    // 4. Catch error
    const loginWithToken = () => {
      const returnUrl = searchParams.get("return_url")
        ? decodeURIComponent(searchParams.get("return_url") as string)
        : "/";
      const token = searchParams.get("token");

      if (token) {
        authServices.update_access_token(token);
        authServices
          .get_account()
          .then(async (res) => {
            dispatch(updateUserInfo(res));
            const selectedWorkspace = await workspaceServices
              .list_workspaces()
              .then((wsResponse) => {
                dispatch(updateWorkspaceList(wsResponse));
                if (wsResponse.length > 0) {
                  const cachedWorkspaceId = Cookies.get("workspace");
                  if (
                    cachedWorkspaceId &&
                    wsResponse.map((ws) => ws.id).includes(cachedWorkspaceId)
                  ) {
                    return cachedWorkspaceId;
                  } else {
                    // Use the first workspace from returned list.
                    dispatch(selectWorkspace(wsResponse[0].id));
                    return wsResponse[0].id;
                  }
                }
                return "";
              })
              .catch(apiErrorHandler);

            // Catch some returnUrls thrown by id.cystack
            if (returnUrl === "/scans") {
              if (selectedWorkspace) {
                navigate({
                  pathname: generatePath(pathname.ALL_SCANS, {
                    workspace: selectedWorkspace,
                  }),
                });
              } else {
                navigate({
                  pathname: generatePath(pathname.NEW_WORKSPACE),
                  search: createSearchParams({
                    returnUrl: pathname.ALL_SCANS,
                  }).toString(),
                });
              }
            } else if (returnUrl === "/devices/hosts") {
              if (selectedWorkspace) {
                navigate({
                  pathname: generatePath(pathname.HOSTS, {
                    workspace: selectedWorkspace,
                  }),
                });
              } else {
                navigate({
                  pathname: generatePath(pathname.NEW_WORKSPACE),
                  search: createSearchParams({
                    returnUrl: pathname.HOSTS,
                  }).toString(),
                });
              }
            } else if (returnUrl === "/trust-center/policy") {
              if (selectedWorkspace) {
                navigate({
                  pathname: generatePath(pathname.TRUST_CENTER_POLICY, {
                    workspace: selectedWorkspace,
                  }),
                });
              } else {
                navigate({
                  pathname: generatePath(pathname.NEW_WORKSPACE),
                  search: createSearchParams({
                    returnUrl: pathname.TRUST_CENTER_POLICY,
                  }).toString(),
                });
              }
            } else {
              navigate(pathname.HOME);
            }
          })
          .catch(apiErrorHandler);
      } else {
        navigate(pathname.LOGIN);
      }
    };

    loginWithToken();
  }, []);

  return <LoadingState />;
};

export default Authenticate;
