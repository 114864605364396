import { useState } from "react";
import { useTranslation } from "react-i18next";
import { ReactComponent as ArrowExpandDownLine } from "#src/assets/images/icons/arrow-expand-down-line.svg";
import { Button } from "#src/components/common/system/Button";
import type { ICertificateItem } from "#src/services/assets/certificates";

const CertificateCipherSuites = ({
  endpointDetails,
}: {
  endpointDetails: ICertificateItem["endpoints"][number]["details"];
}) => {
  const { t } = useTranslation("assets", {
    keyPrefix: "domains.page.details.tab.certificates",
  });

  const [viewMore, setViewMore] = useState<boolean>(false);

  return (
    <>
      <h2>{t("cipherSuites.title")}</h2>
      {(viewMore
        ? endpointDetails.suites
        : endpointDetails.suites.slice(0, 2)
      ).map((suite) => {
        const matchedProtocol = endpointDetails.protocols.find(
          (protocol) => protocol.id === suite.protocol
        );

        if (matchedProtocol === undefined) {
          console.log(
            "unmatched protocol: cannot find protocol id " + suite.protocol
          );
          return null;
        }

        return (
          <div
            key={`cipherSuitesTable-${suite.protocol}`}
            className="grid grid-cols-[8rem_2fr_1fr] gap-x-3"
          >
            <div className="grid grid-cols-subgrid col-span-full bg-bright-grey-subline border-y border-y-light-grey justify-center items-center px-6">
              <div className="py-3 pl-3 font-medium-20">
                {matchedProtocol.name + " " + matchedProtocol.version}
              </div>
              <div className="py-3 text-hard-grey">
                {t("cipherSuites.algorithm")}
              </div>
              <div className="py-3 text-hard-grey">
                {t("cipherSuites.keySize")}
              </div>
            </div>
            {suite.list.map((suiteItem) => (
              <div
                key={`cipherSuitesTable-${suite.protocol}-item-${suiteItem.id}`}
                className="grid grid-cols-subgrid col-span-full bg-white border-b border-b-light-grey justify-center items-center px-6"
              >
                <div />
                <div className="py-3">{suiteItem.name}</div>
                <div className="py-3">{suiteItem.cipherStrength}</div>
              </div>
            ))}
          </div>
        );
      })}
      {endpointDetails.suites.length > 2 ? (
        <Button
          variant="text"
          onClick={() => {
            setViewMore((prev) => !prev);
          }}
        >
          {viewMore ? t("button.collapse") : t("button.viewMore")}
          <ArrowExpandDownLine className={viewMore ? "rotate-180" : ""} />
        </Button>
      ) : null}
    </>
  );
};

export default CertificateCipherSuites;
