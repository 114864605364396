import { Checkbox } from "@lockerpm/design";

interface ITableCheckboxProps {
  checked: boolean;
  onClick: () => void;
}

const TableCheckbox = ({ checked, onClick }: ITableCheckboxProps) => {
  return (
    <Checkbox
      // This hidden until hover thing feels so uncomfortable so I delayed it
      // className={checked ? "" : "hidden group-hover:inline-flex"}
      checked={checked}
      onClick={(e) => {
        // using onClick since onChange's default cannot be prevented and it will trigger the link behind
        e.preventDefault();
        onClick();
      }}
    />
  );
};

export default TableCheckbox;
