/** A styled flag, just to keep the consistency.
 * @param pngProp flag's png as string
 */
export const Flag = ({ pngProp }: { pngProp: string }) => {
  return (
    <img
      src={pngProp}
      className="object-contain rounded-[3px] w-[1.125rem] h-[1.125rem]"
    />
  );
};
