// Libraries
import { useTranslation } from "react-i18next";
import type { ItemType } from "@lockerpm/design/es/menu/hooks/useItems";
import { Dropdown } from "@lockerpm/design";
import dayjs from "dayjs";

// Resources
import { ReactComponent as MoreLine } from "#src/assets/images/icons/more-line.svg";
import { ReactComponent as ArrowDownSLine } from "#src/assets/images/icons/arrow-down-s-line.svg";
import { ReactComponent as TimeLine } from "#src/assets/images/icons/time-line.svg";

// Components
import { IconButton } from "#src/components/common/system/Button";
import { ObjectImage } from "#src/components/common/system/Object";
import { RoleLabel } from "#src/components/common/labels";
import { toPascalCase } from "#src/utils/common";
import DropdownItemLabel from "#src/components/common/helper/antdProps/Dropdown/DropdownItemLabel";
import DropdownTriggerButton from "#src/components/common/helper/antdProps/Dropdown/DropdownTriggerButton";

// API-related
import type { IPentestProjectMsspMember } from "#src/services/pentest/members";
import type { IUserInfo } from "#src/services/auth";
import { msspPentestMemberRoleItems } from "#src/config/filter/pentest";

interface IMsspMemberItemProps {
  currentUser: IUserInfo | null;
  member: IPentestProjectMsspMember;
  actionList: ItemType[];
  onChangeMemberRole: (
    key: string,
    item: IPentestProjectMsspMember["id"]
  ) => void;
  onClickActionItem: (key: string, item: IPentestProjectMsspMember) => void;
}

const MsspMemberItem = ({
  currentUser,
  member,
  actionList,
  onChangeMemberRole,
  onClickActionItem,
}: IMsspMemberItemProps) => {
  const { t } = useTranslation("pentest", {
    keyPrefix: "page.pentestDetails.tab.members",
  });

  return (
    <div className="border border-grey rounded-md p-6 flex justify-between gap-2">
      <div className="flex items-center gap-2">
        <ObjectImage
          data={member.user?.avatar}
          className="h-11 w-11 min-w-[2.75rem] rounded-full"
        />
        <div>
          <div className="font-medium-16 flex gap-2 items-center">
            {member.user ? member.user.full_name : member.id}
            <div className="flex gap-1">
              {member.role === "security_manager" ? (
                <RoleLabel
                  name={t("label.securityManager")}
                  color={"bg-dark-blue"}
                />
              ) : null}
              {member.role === "pentester" ? (
                <RoleLabel name={t("label.pentester")} color={"bg-fire"} />
              ) : null}
            </div>
          </div>
          <span className="font-regular-14 text-primary">
            {"@" + (member.user ? member.user.username : member.id)}
          </span>
        </div>
      </div>
      <div className="flex flex-col gap-2 items-end">
        <div className="flex gap-1">
          {member.user?.email === currentUser?.email ? (
            <div className="h-11" />
          ) : (
            <Dropdown
              menu={{
                items: msspPentestMemberRoleItems.map((item) => ({
                  key: item.key,
                  label: (
                    <DropdownItemLabel selected={item.value === member.role}>
                      {item.getLabel()}
                    </DropdownItemLabel>
                  ),
                })),
                onClick: ({ key, domEvent }) => {
                  domEvent.preventDefault();
                  domEvent.stopPropagation();
                  onChangeMemberRole(key, member.id);
                },
              }}
              trigger={["click"]}
            >
              <DropdownTriggerButton id="dropdown-member-role">
                {toPascalCase(member.role)}
                <ArrowDownSLine />
              </DropdownTriggerButton>
            </Dropdown>
          )}
          <Dropdown
            menu={{
              items: actionList,
              onClick: ({ key, domEvent }) => {
                domEvent.preventDefault();
                domEvent.stopPropagation();
                onClickActionItem(key, member);
              },
            }}
            trigger={["click"]}
            disabled={actionList.length === 0}
          >
            <IconButton
              variant="ghost"
              size={9}
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
              }}
            >
              <MoreLine />
            </IconButton>
          </Dropdown>
        </div>
        <div className="flex gap-1 items-center font-regular-12 text-hard-grey">
          <TimeLine height={"0.875rem"} width={"0.875rem"} />
          {t("joined", { timestamp: dayjs.unix(member.access_time).fromNow() })}
        </div>
      </div>
    </div>
  );
};

export default MsspMemberItem;
