import type { IScanDetailItem } from "#src/services/scans";
import { OverviewTabCardLayout } from "#src/components/assets/details/Tabs/OverviewTab";
import OverviewActivity from "./ActivityCard";
import OverviewCard from "./OverviewCard";
// import OverviewVulnerabilitiesByDomainCard from "./VulnByDomainCard";
import VulnerabilitiesCard from "#src/components/assets/details/Tabs/OverviewTab/VulnerabilitiesCard";
import { ScanDetailsTabEnum } from "../../enums";

interface IScanDetailsOverviewProps {
  scanDetails: IScanDetailItem | undefined;
  onChangeTab: (key: string) => void;
}

const ScanDetailsOverview = ({
  scanDetails,
  onChangeTab,
}: IScanDetailsOverviewProps) => {
  return scanDetails ? (
    <section className="flex flex-col gap-6 pt-6">
      <div className="grid grid-cols-[1fr_2fr] gap-6">
        <OverviewTabCardLayout stretch>
          <OverviewCard scanDetails={scanDetails} />
        </OverviewTabCardLayout>
        <OverviewTabCardLayout stretch>
          <OverviewActivity scanDetails={scanDetails} />
        </OverviewTabCardLayout>
      </div>
      <OverviewTabCardLayout horizontal stretch>
        <VulnerabilitiesCard
          vulnData={{
            count: Object.values(scanDetails.vulnerabilities.by_severity)
              .filter((item): item is number => true)
              .reduce((prev, curr) => prev + curr, 0),
            topVulns: scanDetails.top_vulns.map((item) => ({
              id: item.id,
              name: item.vuln ? item.vuln.name : item.id,
              severity: item.severity,
            })),
            byStatus: scanDetails.vulnerabilities.by_status,
            bySeverity: scanDetails.vulnerabilities.by_severity,
          }}
          onClickSeeAll={() => {
            onChangeTab(ScanDetailsTabEnum.VULNERABILITIES);
          }}
        />
      </OverviewTabCardLayout>
      {/* DEPRECATED: Each scan has only 1 asset, so this is redundant */}
      {/* <OverviewVulnerabilitiesByDomainCard scanDetails={scanDetails} /> */}
    </section>
  ) : null;
};

export default ScanDetailsOverview;
