import type { IPaginateParams, IPaginatedResponse } from "#src/@types/api";
import endpoint from "#src/config/endpoint";
import request from "#src/utils/request";
import { list_scan_applications } from "./applications";
import { get_scan_certificate } from "./certificates";
import { list_scan_ports } from "./ports";
import { list_scanned_domains } from "./scannedDomains";
import {
  delete_multiple_schedules,
  delete_schedule,
  get_detail_schedule,
  list_schedules,
  update_schedule,
} from "./schedules";
import { get_scan_subdomains } from "./subdomains";
import { list_scan_vulnerabilities } from "./vulnerabilities";

export interface IScanItem {
  id: number;
  created_time: number;
  updated_time: number | null;
  // 5 statuses: pending, running, completed, stopping, stopped
  status: string;
  progress: number | null;
  scanner_ip: string | null;
  scan_profile: {
    schedule_id: number;
    days: string[];
    duration_type: string;
    repeat_number: number;
    schedule: boolean;
    timezone: string;
    timezone_value: number;
    activated_time: number | null;
    assets: {
      asset_id: number;
      include_subdomains: boolean;
      name: string;
      address: string;
    }[];
  };
  vulnerabilities: {
    number_issues: number;
    by_severity: Record<string, number>;
  };
}

export interface IScanDetailItem extends IScanItem {
  vulnerabilities: {
    number_issues: IScanItem["vulnerabilities"]["number_issues"];
    by_severity: IScanItem["vulnerabilities"]["by_severity"];
    by_status: Record<string, number>;
    by_asset: {
      [id: number]: {
        [severity: string]: number | undefined;
      };
    };
  };
  created_by: {
    email: string;
    username: string;
    full_name: string;
    avatar: string;
  };
  activity: {
    created_time: number;
    description: {
      vi: string;
      en: string;
    };
  }[];
  scanned_assets: {
    id: number;
    name: string;
    address: string;
    include_subdomains: boolean;
  }[];
  scanned_subdomains: any[];
  top_vulns: {
    severity: string;
    id: string;
    affected_locations: string[];
    traffics: {
      request: string;
      response: any;
    }[];
    details: string;
    host: string;
    port: number;
    vuln: {
      name: string;
      internal_id: string;
    } | null;
    first_found: number;
    last_found: number;
    asset: {
      id: number;
      name: string;
      address: string;
    };
  }[];
}

function list_scans(
  workspace_id: string,
  params: {
    from: number | undefined;
    to: number | undefined;
    domain_id: string | undefined;
    status: string | undefined;
    // Should only be 0 | 1
    is_schedule: string | undefined;
    // TODO: Should be `vulnerabilities`?
    // vulnerable: string | undefined;
  } & IPaginateParams
) {
  return request.get<never, IPaginatedResponse<IScanItem>>(
    `${endpoint.WORKSPACES}/${workspace_id}/scans`,
    { params }
  );
}

function get_detail_scan(workspace_id: string, scan_id: string) {
  return request.get<never, IScanDetailItem>(
    `${endpoint.WORKSPACES}/${workspace_id}/scans/${scan_id}`
  );
}

function create_new_scan(
  workspace_id: string,
  data: {
    domains: { domain_id: number; include_subdomains: boolean }[];
    schedule: boolean;
    activated_time: number;
    timezone: string;
    duration_type: string;
    repeat_number: number;
    days: string[];
  }
) {
  return request.post<never, { success: boolean }>(
    `${endpoint.WORKSPACES}/${workspace_id}/scans`,
    data
  );
}

function stop_scan(workspace_id: string, scan_id: number) {
  return request.post<never, { success: boolean }>(
    `${endpoint.WORKSPACES}/${workspace_id}/scans/${scan_id}/stop`
  );
}

function delete_scan(workspace_id: string, scan_id: number) {
  return request.delete(
    `${endpoint.WORKSPACES}/${workspace_id}/scans/${scan_id}`
  );
}

const scansServices = {
  list_scans,
  get_detail_scan,
  create_new_scan,
  stop_scan,
  delete_scan,

  list_scan_vulnerabilities,
  list_scan_ports,
  list_scan_applications,
  get_scan_certificate,
  get_scan_subdomains,
  list_scanned_domains,

  list_schedules,
  get_detail_schedule,
  update_schedule,
  delete_schedule,
  delete_multiple_schedules,
};

export default scansServices;
