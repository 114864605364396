import type { IPaginateParams, IPaginatedResponse } from "#src/@types/api";
import endpoint from "#src/config/endpoint";
import request from "#src/utils/request";
import {
  create_mssp_program_integration,
  list_mssp_program_integrations,
  retrieve_mssp_program_integration_details,
  update_mssp_program_integration,
} from "./integration";
import {
  create_mssp_program_member,
  delete_mssp_program_member,
  list_program_mssp_members,
  list_program_workspace_members,
  update_mssp_program_member,
} from "./members";
import {
  check_mssp_program_eligibility,
  list_mssp_program_requirements,
  update_allow_to_see_mssp_program,
  update_mssp_program_requirement,
} from "./requirements";

interface IOrganization {
  alias: string;
  name: string;
  email: string;
  address: string;
  phone: string;
  color: string;
  description: {
    en: string;
    vi: string;
  };
  logo: string;
  social: { name?: string; url?: string };
}

export interface IMsspBugBountyProgram {
  alias: string;
  org_alias: string;
  in_review: boolean;
  status: string;
  type: string;
  created_time: number;
  updated_time: number | null;
  last_update: number | null;
  currency: string;
  mode: string;
  is_wh_managed: boolean;
  nda: boolean;
  program_plan: string;
  mssp: { id: string; name: string } | null;
  role: string | null;
  org: IOrganization;
}

function list_mssp_programs(
  workspace_id: string,
  params: any & IPaginateParams
) {
  return request.get<never, IPaginatedResponse<IMsspBugBountyProgram>>(
    `${endpoint.BASE}/mssp/${workspace_id}/programs`,
    { params }
  );
}

export interface IMsspBugBountyDetails
  extends Omit<IMsspBugBountyProgram, "program_plan"> {
  metadata: {
    vi: string;
    en: string;
  };
  non_vat: boolean;
  is_subscribe: boolean;
  is_blocked: boolean;
  requirements: boolean;
  allow_to_see_brief: boolean;
  program_plan: {
    name: string;
    remain_support: number;
  };
  is_eligible: boolean;
  can_see_brief: boolean;
  // DEPRECATED: duplicated with allow_to_see_brief
  allow_see_brief: boolean;
  target: {
    id: number;
    name: string;
    type: string;
    scope: string;
    link_download: string;
    asset_id: number | null;
  }[];
  reward: {
    type: string;
    range_from: number;
    range_to: number;
    severity: string;
    point_value: number;
  }[];
}

function retrieve_mssp_program_details(
  workspace_id: string,
  program_alias: string
) {
  return request.get<never, IMsspBugBountyDetails>(
    `${endpoint.BASE}/mssp/${workspace_id}/programs/${program_alias}`
  );
}

interface IMsspBugBountyPostData {
  status: string;
  alias: string;
  name: string;
  description?: {
    en: string;
    vi: string;
  };
  logo?: string;
  color?: string;
  type: string;
  target: {
    name: string;
    type: string;
    scope: string;
    link_download: string;
    file?: string;
  }[];
  reward?: {
    type: string;
    range_from: number;
    range_to: number;
    severity: string;
  }[];
  metadata: {
    en: string;
    vi: string;
  };
  currency?: string;
  mssp_id?: string | null;
  // These fields are not currently needed
  // email?: string;
  // phone?: string;
  // address?: string;
  // mode?: string;
}

function update_mssp_program_details(
  workspace_id: string,
  program_alias: string,
  data: Partial<IMsspBugBountyPostData>
) {
  return request.put<never, { success: boolean }>(
    `${endpoint.BASE}/mssp/${workspace_id}/programs/${program_alias}`,
    data
  );
}

function retrieve_mssp_program_organization(
  workspace_id: string,
  program_alias: string
) {
  return request.get<never, IOrganization>(
    `${endpoint.BASE}/mssp/${workspace_id}/programs/${program_alias}/workspace`
  );
}

export default {
  list_mssp_programs,
  retrieve_mssp_program_details,
  update_mssp_program_details,
  retrieve_mssp_program_organization,

  list_program_mssp_members,
  list_program_workspace_members,
  create_mssp_program_member,
  update_mssp_program_member,
  delete_mssp_program_member,

  list_mssp_program_integrations,
  retrieve_mssp_program_integration_details,
  create_mssp_program_integration,
  update_mssp_program_integration,

  list_mssp_program_requirements,
  update_mssp_program_requirement,
  check_mssp_program_eligibility,
  update_allow_to_see_mssp_program,
};
