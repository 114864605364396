type IMarkdownEditorAction = (
  editor: HTMLTextAreaElement,
  selectionStart: number,
  selectionEnd: number,
  selectionText: string
) => void;

export const onClickHeadingButton: IMarkdownEditorAction = (
  ...[editor, selectionStart, selectionEnd, selectionText]
) => {
  const prefix = "\n### ";
  const suffix = "\n";

  if (selectionStart >= selectionEnd) {
    const splittedValue = editor.value.split("\n");
    const currentLine =
      editor.value.substring(0, selectionStart).split("\n").length - 1;
    const startOfCurrentLine = splittedValue
      .slice(0, currentLine)
      .reduce((prev, cur) => {
        return prev + cur.length + 1;
      }, 0);
    const endOfCurrentLine =
      startOfCurrentLine + splittedValue[currentLine].length;

    editor.setRangeText(
      prefix + splittedValue[currentLine] + suffix,
      startOfCurrentLine,
      endOfCurrentLine
    );
    editor.setSelectionRange(
      selectionStart + prefix.length,
      selectionEnd + prefix.length
    );
  } else {
    editor.setRangeText(
      prefix + selectionText + suffix,
      selectionStart,
      selectionEnd
    );
    editor.setSelectionRange(
      selectionStart + prefix.length,
      selectionEnd + prefix.length
    );
  }
};

export const onClickBoldButton: IMarkdownEditorAction = (
  ...[editor, selectionStart, selectionEnd, selectionText]
) => {
  const prefix = "**";
  const suffix = "**";

  editor.setRangeText(
    prefix + selectionText + suffix,
    selectionStart,
    selectionEnd
  );
  editor.setSelectionRange(
    selectionStart + prefix.length,
    selectionEnd + prefix.length
  );
};

export const onClickItalicButton: IMarkdownEditorAction = (
  ...[editor, selectionStart, selectionEnd, selectionText]
) => {
  const prefix = "*";
  const suffix = "*";

  editor.setRangeText(
    prefix + selectionText + suffix,
    selectionStart,
    selectionEnd
  );
  editor.setSelectionRange(
    selectionStart + prefix.length,
    selectionEnd + prefix.length
  );
};

export const onClickQuoteButton: IMarkdownEditorAction = (
  ...[editor, selectionStart, selectionEnd, selectionText]
) => {
  if (selectionStart >= selectionEnd) {
    const splittedValue = editor.value.split("\n");
    const currentLine =
      editor.value.substring(0, selectionStart).split("\n").length - 1;
    const startOfCurrentLine = splittedValue
      .slice(0, currentLine)
      .reduce((prev, cur) => {
        return prev + cur.length + 1;
      }, 0);
    const endOfCurrentLine =
      startOfCurrentLine + splittedValue[currentLine].length;

    if (editor.value[startOfCurrentLine] === ">") {
      const prefix = "> \n\n";
      const suffix = "\n";

      editor.setRangeText(
        prefix + splittedValue[currentLine] + suffix,
        startOfCurrentLine,
        endOfCurrentLine
      );
      editor.setSelectionRange(
        selectionStart + prefix.length,
        selectionEnd + prefix.length
      );
    } else {
      const prefix = "\n> ";
      const suffix = "\n";

      editor.setRangeText(
        prefix + splittedValue[currentLine] + suffix,
        startOfCurrentLine,
        endOfCurrentLine
      );
      editor.setSelectionRange(
        selectionStart + prefix.length,
        selectionEnd + prefix.length
      );
    }
  } else {
    let newSelectionText = selectionText
      .split("\n")
      .map((item) => ">" + (item[0] === ">" ? "" : " ") + item)
      .join("\n");

    while (
      !(
        (newSelectionText[0] === "\n" && newSelectionText[1] === "\n") ||
        selectionStart <= 0 ||
        (newSelectionText[0] === "\n" &&
          editor.value[selectionStart - 1] === "\n")
      )
    ) {
      newSelectionText = "\n" + newSelectionText;
    }

    while (
      !(
        (newSelectionText[newSelectionText.length - 1] === "\n" &&
          newSelectionText[newSelectionText.length - 2] === "\n") ||
        selectionEnd >= editor.value.length ||
        (newSelectionText[newSelectionText.length - 1] === "\n" &&
          editor.value[selectionEnd] === "\n")
      )
    ) {
      newSelectionText = newSelectionText + "\n";
    }
    editor.setRangeText(newSelectionText, selectionStart, selectionEnd);
  }
};

export const onClickCodeButton: IMarkdownEditorAction = (
  ...[editor, selectionStart, selectionEnd, selectionText]
) => {
  const startLine =
    editor.value.substring(0, selectionStart).split("\n").length - 1;
  const endLine =
    editor.value.substring(0, selectionEnd - 1).split("\n").length - 1;

  const prefix = startLine === endLine ? "`" : "\n```\n";
  const suffix = startLine === endLine ? "`" : "\n```\n";

  editor.setRangeText(
    prefix + selectionText + suffix,
    selectionStart,
    selectionEnd
  );
  editor.setSelectionRange(
    selectionStart + prefix.length,
    selectionEnd + prefix.length
  );
};

export const onClickLinkButton: IMarkdownEditorAction = (
  ...[editor, selectionStart, selectionEnd, selectionText]
) => {
  const prefix = "[";
  const suffix = "](url)";

  editor.setRangeText(
    prefix + selectionText + suffix,
    selectionStart,
    selectionEnd
  );

  editor.setSelectionRange(
    selectionStart + "[](".length + selectionText.length,
    selectionStart + "[](url".length + selectionText.length
  );
};

export const onClickImageButton: IMarkdownEditorAction = (
  ...[editor, selectionStart, selectionEnd, selectionText]
) => {
  const prefix = "![";
  const suffix = "](url)";

  editor.setRangeText(
    prefix + selectionText + suffix,
    selectionStart,
    selectionEnd
  );

  editor.setSelectionRange(
    selectionStart + "![](".length + selectionText.length,
    selectionStart + "![](url".length + selectionText.length
  );
};

export const onClickOrderedListButton: IMarkdownEditorAction = (
  ...[editor]
) => {
  const splittedValue = editor.value.split("\n");
  const startLine =
    editor.value.substring(0, editor.selectionStart).split("\n").length - 1;
  const endLine =
    editor.value.substring(0, editor.selectionEnd - 1).split("\n").length - 1;
  const selectionStart =
    startLine > 0
      ? splittedValue
          .slice(0, startLine)
          .reduce((prev, cur) => prev + cur.length + 1, 0)
      : 0;
  const selectionEnd = splittedValue
    .slice(0, endLine + 1)
    .reduce((prev, cur) => prev + cur.length + 1, 0);
  const selectionText = editor.value.substring(selectionStart, selectionEnd);

  let newSelectionText = selectionText
    .split("\n")
    .filter((item) => !!item)
    .map((item, index) => index + 1 + ". " + item)
    .join("\n");

  if (newSelectionText === "") {
    editor.setRangeText("1. ", selectionStart, selectionEnd);
    editor.setSelectionRange(
      selectionStart + "1. ".length,
      selectionStart + "1. ".length
    );
  } else {
    while (
      !(
        (newSelectionText[0] === "\n" && newSelectionText[1] === "\n") ||
        selectionStart <= 0 ||
        (newSelectionText[0] === "\n" &&
          editor.value[selectionStart - 1] === "\n") ||
        (editor.value[selectionStart - 1] === "\n" &&
          editor.value[selectionStart - 2] === "\n")
      )
    ) {
      newSelectionText = "\n" + newSelectionText;
    }

    while (
      !(
        (newSelectionText[newSelectionText.length - 1] === "\n" &&
          newSelectionText[newSelectionText.length - 2] === "\n") ||
        selectionEnd >= editor.value.length ||
        (newSelectionText[newSelectionText.length - 1] === "\n" &&
          editor.value[selectionEnd] === "\n")
      )
    ) {
      newSelectionText = newSelectionText + "\n";
    }

    editor.setRangeText(newSelectionText, selectionStart, selectionEnd);
    editor.setSelectionRange(
      selectionStart,
      selectionStart + newSelectionText.length
    );
  }
};

export const onClickUnorderedListButton: IMarkdownEditorAction = (
  ...[editor]
) => {
  const splittedValue = editor.value.split("\n");
  const startLine =
    editor.value.substring(0, editor.selectionStart).split("\n").length - 1;
  const endLine =
    editor.value.substring(0, editor.selectionEnd - 1).split("\n").length - 1;
  const selectionStart =
    startLine > 0
      ? splittedValue
          .slice(0, startLine)
          .reduce((prev, cur) => prev + cur.length + 1, 0)
      : 0;
  const selectionEnd = splittedValue
    .slice(0, endLine + 1)
    .reduce((prev, cur) => prev + cur.length + 1, 0);
  const selectionText = editor.value.substring(selectionStart, selectionEnd);

  let newSelectionText = selectionText
    .split("\n")
    .filter((item) => !!item)
    .map((item) => "- " + item)
    .join("\n");

  if (newSelectionText === "") {
    editor.setRangeText("- ", selectionStart, selectionEnd);
    editor.setSelectionRange(
      selectionStart + "- ".length,
      selectionStart + "- ".length
    );
  } else {
    while (
      !(
        (newSelectionText[0] === "\n" && newSelectionText[1] === "\n") ||
        selectionStart <= 0 ||
        (newSelectionText[0] === "\n" &&
          editor.value[selectionStart - 1] === "\n") ||
        (editor.value[selectionStart - 1] === "\n" &&
          editor.value[selectionStart - 2] === "\n")
      )
    ) {
      newSelectionText = "\n" + newSelectionText;
    }

    while (
      !(
        (newSelectionText[newSelectionText.length - 1] === "\n" &&
          newSelectionText[newSelectionText.length - 2] === "\n") ||
        selectionEnd >= editor.value.length ||
        (newSelectionText[newSelectionText.length - 1] === "\n" &&
          editor.value[selectionEnd] === "\n")
      )
    ) {
      newSelectionText = newSelectionText + "\n";
    }

    editor.setRangeText(newSelectionText, selectionStart, selectionEnd);
    editor.setSelectionRange(
      selectionStart,
      selectionStart + newSelectionText.length
    );
  }
};
