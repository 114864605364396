// Libraries
import { useTranslation } from "react-i18next";
import { Dropdown } from "@lockerpm/design";
import { type Dispatch, type SetStateAction, useState } from "react";
import i18next from "i18next";

// Resources
import { ReactComponent as ArrowDownSLine } from "#src/assets/images/icons/arrow-down-s-line.svg";

// General
import patterns from "#src/config/patterns";

// Components
import { InputField } from "#src/components/common/helper/wrapper/InputField";
import { Accordion } from "#src/components/common/library/Accordion";
import { InputFieldWrapper } from "#src/components/common/composed/divWrappers/InputFieldWrapper";
import DropdownItemLabel from "#src/components/common/helper/antdProps/Dropdown/DropdownItemLabel";
import DropdownTriggerButton from "#src/components/common/helper/antdProps/Dropdown/DropdownTriggerButton";

// API-related
import type { IPaymentForm } from "#src/services/payment";

// Children
import {
  type ContactFormSelection,
  jobTitleList,
} from "#src/components/trustCenter/onboard/ContactForm/contactDetailFields";

interface IPaymentFillFormCustomerInfoProps {
  customer: IPaymentForm["customer"];
  onChangeCustomer: (value: IPaymentForm["customer"]) => void;
  jobTitle: ContactFormSelection;
  setJobTitle: Dispatch<SetStateAction<ContactFormSelection>>;
  errorObj: {
    full_name: string | undefined;
    phone_number: string | undefined;
    email: string | undefined;
  };
  setErrorObj: Dispatch<
    SetStateAction<{
      full_name: string | undefined;
      phone_number: string | undefined;
      email: string | undefined;
    }>
  >;
}

const PaymentFillFormCustomerInfo = ({
  customer,
  onChangeCustomer,
  jobTitle,
  setJobTitle,
  errorObj,
  setErrorObj,
}: IPaymentFillFormCustomerInfoProps) => {
  const { t } = useTranslation("trustCenter", {
    keyPrefix: "registerDomain.fillForm.customerInformation",
  });

  const [expanded, setExpanded] = useState<boolean>(true);

  return (
    <Accordion
      wrapperClassName="gap-6"
      label={
        <h2 className="flex justify-between items-center">
          {t("title")}
          <ArrowDownSLine
            className={`h-6 w-6 ${
              expanded ? "rotate-180" : ""
            } transition-all cursor-pointer`}
            onClick={() => {
              setExpanded((prev) => !prev);
            }}
          />
        </h2>
      }
      expanded={expanded}
    >
      <div className="grid grid-cols-2 px-6 py-3 gap-x-6 gap-y-3">
        <InputField
          title={t("fields.fullName.label")}
          required
          placeholder={t("fields.fullName.placeholder")}
          value={customer.full_name}
          onChangeValue={(value) => {
            onChangeCustomer({ ...customer, full_name: value });
          }}
          error={errorObj.full_name}
          onChangeError={(value) => {
            setErrorObj((prev) => ({ ...prev, full_name: value }));
          }}
        />
        <InputField
          title={t("fields.phoneNumber.label")}
          required
          placeholder={t("fields.phoneNumber.placeholder")}
          value={customer.phone_number}
          onChangeValue={(value) => {
            onChangeCustomer({ ...customer, phone_number: value });
          }}
          error={errorObj.phone_number}
          onChangeError={(value) => {
            setErrorObj((prev) => ({ ...prev, phone_number: value }));
          }}
          pattern={patterns.PHONE}
          patternFailedString={i18next.t("common:validateWarning.phone")}
        />
        <InputFieldWrapper
          title={t("fields.jobTitle.label")}
          requiredMark
          input={
            <Dropdown
              menu={{
                items: jobTitleList.map((p) => ({
                  key: p.key,
                  label: (
                    <DropdownItemLabel
                      selected={jobTitle.key === p.key}
                      syncWidthId="jobTitle-dropdown"
                    >
                      {p.getLabel()}
                    </DropdownItemLabel>
                  ),
                  onClick: ({ key }) => {
                    const clickedItem = jobTitleList.find((p) => p.key === key);
                    if (clickedItem) {
                      setJobTitle(clickedItem);
                      onChangeCustomer({
                        ...customer,
                        job_title: clickedItem.value,
                      });
                    }
                  },
                })),
              }}
              trigger={["click"]}
            >
              <DropdownTriggerButton id="jobTitle-dropdown">
                {jobTitle.getLabel()}
                <ArrowDownSLine
                  width={"1rem"}
                  height={"1rem"}
                  className="fill-hard-grey"
                />
              </DropdownTriggerButton>
            </Dropdown>
          }
        />
        <InputField
          title={t("fields.emailAddress.label")}
          required
          placeholder={t("fields.emailAddress.placeholder")}
          value={customer.email}
          onChangeValue={(value) => {
            onChangeCustomer({ ...customer, email: value });
          }}
          error={errorObj.email}
          onChangeError={(value) => {
            setErrorObj((prev) => ({ ...prev, email: value }));
          }}
          pattern={patterns.EMAIL}
          patternFailedString={i18next.t("common:validateWarning.email")}
        />
      </div>
    </Accordion>
  );
};

export default PaymentFillFormCustomerInfo;
