export enum ComplianceStatusValue {
  NON_COMPLIANCE = "non_compliance",
  COMPLIANCE = "compliance",
  UNKNOWN = "unknown",
}

export enum TrustCenterPlanValue {
  FREE = "tc_free",
  ENTERPRISE = "tc_enterprise",
}

export enum TrustCenterPolicyStatusValue {
  DRAFT = "draft",
  PUBLISHED = "published",
}
