import type { Params, RouteObject } from "react-router-dom";
import i18next from "i18next";
import { pathname } from "#src/config/pathname";

import MsspPentest from "#src/pages/mssp/pentest";
import MsspPentestDetails from "#src/pages/mssp/pentest/details";

const breadcrumbTFunc = i18next.getFixedT(null, "general", "breadcrumb");

export const msspPentestRoute: RouteObject = {
  path: pathname.MSSP_PENTEST,
  handle: {
    crumb: () => ({
      getText: () => breadcrumbTFunc("mssp.pentest.label"),
      path: pathname.MSSP_PENTEST,
    }),
  },
  children: [
    {
      index: true,
      element: <MsspPentest />,
    },
    {
      path: pathname.MSSP_PENTEST_DETAILS,
      handle: {
        crumb: (params: Readonly<Params<string>>) => {
          return {
            getText: () =>
              Object.hasOwn(params, "pentest") ? params["pentest"] : "---",
            path: pathname.MSSP_PENTEST_DETAILS,
          };
        },
      },
      element: <MsspPentestDetails />,
    },
  ],
};
