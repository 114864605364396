// Libraries
import { type ReactNode, useEffect, useRef, useState } from "react";
import {
  type InternalToastProps,
  ToastState,
  type ToastType,
} from "./observer";

// Resources
import { ReactComponent as CheckboxCircleLine } from "#src/assets/images/icons/checkbox-circle-line.svg";
import { ReactComponent as CloseCircleLine } from "#src/assets/images/icons/close-circle-line.svg";
import { ReactComponent as InformationLine } from "#src/assets/images/icons/information-line.svg";
import { ReactComponent as CloseLine } from "#src/assets/images/icons/close-line.svg";

// Components
import { IconButton } from "#src/components/common/system/Button";
import constants from "#src/config/constants";

const borderByToastType: { [S in ToastType]: string } = {
  success: "border-warning-safe",
  error: "border-warning-red-danger",
  warning: "border-status-duplicate",
  info: "border-status-triaged",
};

const iconByToastType: { [S in ToastType]: ReactNode } = {
  success: (
    <CheckboxCircleLine className="fill-warning-safe h-6 w-6 min-w-[1.5rem]" />
  ),
  error: (
    <CloseCircleLine className="fill-warning-red-danger h-6 w-6 min-w-[1.5rem]" />
  ),
  warning: (
    <InformationLine className="fill-status-duplicate h-6 w-6 min-w-[1.5rem]" />
  ),
  info: (
    <InformationLine className="fill-status-triaged h-6 w-6 min-w-[1.5rem]" />
  ),
};

/** A slice of toast. Freshly out of the toaster, crunchy on the outside, soft and tender on the inside.
 * Or is it just notification's UI.
 */
export const ToastSlice = ({ toast }: { toast: InternalToastProps }) => {
  const [mounted, setMounted] = useState<boolean>(false);

  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    // Trigger enter animation
    setMounted(true);
  }, []);

  useEffect(() => {
    // After a few seconds, toast will be automatically dismissed. We can add a prop here to prevent auto dismiss.
    const timer = setTimeout(() => {
      ToastState.dismissToast(toast);
    }, constants.TOAST_LIFETIME);

    return () => clearTimeout(timer);
  }, [toast]);

  return (
    <div
      ref={ref}
      style={
        !mounted
          ? // The first state: before toast is mounted
            {
              transform: "translate(0, -100%)",
              height: ref.current?.offsetHeight,
            }
          : toast.status === "dismissed"
          ? // The last state before toast is destroyed
            {
              height: 0,
              minHeight: 0,
              opacity: 0,
            }
          : // The stable state
            {
              height: ref.current?.offsetHeight,
            }
      }
      className={`bg-white px-6 py-3 flex items-center gap-3 rounded-md border duration-300 ease-out shadow-[0_0_1rem_rgba(94,94,94,0.08)] mb-3 min-h-[4rem] overflow-hidden ${
        borderByToastType[toast.type]
      }`}
      // TODO: this is not consistent and might be easily broken
      onTransitionEnd={(e) => {
        if (e.target === e.currentTarget) {
          if (e.propertyName === "height") {
            ToastState.destroyToast(toast);
          }
        }
      }}
    >
      {iconByToastType[toast.type]}
      <div className="flex flex-col gap-1">
        <span className="font-medium-18 w-80 min-h-[1em] break-words">
          {toast.message}
        </span>
        {toast.detail ? (
          <span className="font-medium-16 w-80 min-h-[1em] break-words text-hard-grey">
            {toast.detail}
          </span>
        ) : null}
      </div>
      <IconButton
        variant="ghost"
        size={5}
        onClick={() => {
          ToastState.dismissToast(toast);
        }}
      >
        <CloseLine className="h-5 w-5 fill-hard-grey" />
      </IconButton>
    </div>
  );
};
