// Libraries
import { type Dispatch, type SetStateAction, useState } from "react";
import { useTranslation } from "react-i18next";
import { Tooltip } from "@lockerpm/design";

// Resources
import { ReactComponent as ArrowDownSLine } from "#src/assets/images/icons/arrow-down-s-line.svg";
import { ReactComponent as VietcombankSquare } from "#src/assets/images/logos/banks/vietcombank-square.svg";
import { ReactComponent as VPBankSquare } from "#src/assets/images/logos/banks/vpbank-square.svg";
import { ReactComponent as TechcombankSquare } from "#src/assets/images/logos/banks/techcombank-square.svg";
import { ReactComponent as ACBSquare } from "#src/assets/images/logos/banks/acb-square.svg";
import { ReactComponent as VIBSquare } from "#src/assets/images/logos/banks/vib-square.svg";
import { ReactComponent as MBBankSquare } from "#src/assets/images/logos/banks/mbbank-square.svg";
import { ReactComponent as VisaSvg } from "#src/assets/images/logos/banks/visa.svg";
import { ReactComponent as MasterCardSvg } from "#src/assets/images/logos/banks/mastercard.svg";
import { ReactComponent as AmericanExpressSvg } from "#src/assets/images/logos/banks/americanexpress.svg";

// Components
import { Accordion } from "#src/components/common/library/Accordion";
import { MultipleChoiceBlockWrapper } from "#src/components/common/composed/divWrappers/MultipleChoiceBlockWrapper";

// API-related
import paymentService, { type IPaymentForm } from "#src/services/payment";
import { PaymentMethodValue } from "#src/config/filter/workspace/value";

// Children
import PaymentFillFormPaymentOptions from "#src/components/payment/FillForm/PaymentMethod/Options";

interface IBillingServiceRenewalFillFormPaymentMethodProps {
  paymentForm: IPaymentForm;
  setPaymentForm: Dispatch<SetStateAction<IPaymentForm>>;
  defaultPaymentSettings: Awaited<
    ReturnType<typeof paymentService.retrieve_workspace_payment_settings>
  > | null;
}

const BillingServiceRenewalFillFormPaymentMethod = ({
  paymentForm,
  setPaymentForm,
  defaultPaymentSettings,
}: IBillingServiceRenewalFillFormPaymentMethodProps) => {
  const { t } = useTranslation("trustCenter", {
    keyPrefix: "registerDomain.fillForm.paymentMethod",
  });

  const [expanded, setExpanded] = useState<boolean>(true);

  const onChangePaymentMethod = (
    value: (typeof paymentForm)["payment_method"]
  ) => {
    setPaymentForm((prev) => ({ ...prev, payment_method: value }));
  };

  return (
    <Accordion
      label={
        <h2 className="flex justify-between items-center">
          {t("title")}
          <ArrowDownSLine
            className={`h-6 w-6 ${
              expanded ? "rotate-180" : ""
            } transition-all cursor-pointer`}
            onClick={() => {
              setExpanded((prev) => !prev);
            }}
          />
        </h2>
      }
      expanded={expanded}
    >
      <div className="flex flex-col gap-6 p-6">
        <div className="grid grid-cols-2 gap-6">
          <MultipleChoiceBlockWrapper
            selected={paymentForm.payment_method === PaymentMethodValue.BANKING}
            onSelect={() => {
              onChangePaymentMethod(PaymentMethodValue.BANKING);
            }}
          >
            <span className="font-bold-20">{t("banking.title")}</span>
            <div className="flex flex-col gap-4">
              <span className="text-hard-grey">{t("banking.note")}</span>
              <div className="flex items-center justify-between gap-3">
                <div className="flex gap-3 overflow-hidden">
                  <VietcombankSquare className="h-8 w-8" />
                  <VPBankSquare className="h-8 w-8" />
                  <TechcombankSquare className="h-8 w-8" />
                  <ACBSquare className="h-8 w-8" />
                  <VIBSquare className="h-8 w-8" />
                  <MBBankSquare className="h-8 w-8" />
                </div>
                {/* TODO: add the see all modal here */}
                <a>{t("banking.seeAll")}</a>
              </div>
            </div>
          </MultipleChoiceBlockWrapper>
          <Tooltip
            title={
              <span className="font-medium-12 text-center align-center">
                {t("renewWithOldMethod.bankingOnly")}
                <a className="text-subcard-blue">
                  {t("renewWithOldMethod.instructCard")}
                </a>
                .
              </span>
            }
          >
            {/* We need the useless div here so that Antd Tooltip can work. */}
            <div>
              <MultipleChoiceBlockWrapper
                selected={
                  paymentForm.payment_method === PaymentMethodValue.CARD
                }
                onSelect={() => {
                  onChangePaymentMethod(PaymentMethodValue.CARD);
                }}
                disabled
              >
                <span className="font-bold-20">{t("card.title")}</span>
                <div className="flex flex-col gap-4">
                  <span className="text-hard-grey">{t("card.note")}</span>
                  <div className="flex gap-3 overflow-hidden">
                    <VisaSvg className="h-8 w-8" />
                    <MasterCardSvg className="h-8 w-8" />
                    <AmericanExpressSvg className="h-8 w-8" />
                  </div>
                </div>
              </MultipleChoiceBlockWrapper>
            </div>
          </Tooltip>
        </div>
        <span className="text-warning">{t("requestVat")}</span>
        <PaymentFillFormPaymentOptions
          paymentForm={paymentForm}
          setPaymentForm={setPaymentForm}
          defaultPaymentSettings={defaultPaymentSettings}
          disableContract
        />
      </div>
    </Accordion>
  );
};

export default BillingServiceRenewalFillFormPaymentMethod;
