// Libraries
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { useState } from "react";

// Resources
import { ReactComponent as AddLine } from "#src/assets/images/icons/add-line.svg";

// Components
import { ContentSection, StickySection } from "#src/layouts/content";
import NoResultsState from "#src/components/common/states/NoResultsState";
import { Button } from "#src/components/common/system/Button";
import { useCurrentWorkspaceRole } from "#src/hooks/useCurrentWorkspaceRole";

// Children
import TrustCenterDocumentTable from "#src/components/trustCenter/document/DocumentTable";
import { WorkspaceMemberRoleValue } from "#src/config/filter/workspace/value";

const TrustCenterDocument = () => {
  const { t } = useTranslation("trustCenter", { keyPrefix: "document" });

  const { workspace: workspaceId } = useParams<"workspace">();

  const [openAddDrawer, setOpenAddDrawer] = useState<boolean>(false);

  if (!workspaceId || workspaceId === "null") {
    return <NoResultsState />;
  }

  const workspaceRole = useCurrentWorkspaceRole(workspaceId);

  return (
    <>
      <StickySection>
        <h1>{t("title")}</h1>
        {workspaceRole === WorkspaceMemberRoleValue.ADMIN ||
        workspaceRole === WorkspaceMemberRoleValue.OWNER ? (
          <Button
            size="large"
            onClick={() => {
              setOpenAddDrawer(true);
            }}
          >
            <AddLine className="h-5 w-5" />
            {t("button.addDocument")}
          </Button>
        ) : null}
      </StickySection>
      <ContentSection>
        <TrustCenterDocumentTable
          workspaceId={workspaceId}
          openAddDrawer={openAddDrawer}
          onCloseAddDrawer={() => {
            setOpenAddDrawer(false);
          }}
        />
      </ContentSection>
    </>
  );
};

export default TrustCenterDocument;
