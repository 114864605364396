import type { IFilterItem } from "#src/@types/common";

const colorByHostStatus: { [status: string]: string } = {
  online: "bg-warning-safe",
  offline: "bg-warning",
};

interface IDropdownItemLabelProps {
  item: IFilterItem;
  selected: IFilterItem | null;
}

const HostStatusItemLabel = ({ item, selected }: IDropdownItemLabelProps) => {
  return item.value === "all" ? (
    <span
      className={`flex justify-center font-medium-16 p-3 min-w-[10rem] ${
        selected?.key && item.key === selected.key
          ? "text-hard-grey"
          : "text-medium-grey"
      } text-center`}
    >
      {item.getLabel()}
    </span>
  ) : (
    <span
      className={`flex items-center gap-3 font-medium-16 p-3 min-w-[10rem] ${
        selected?.key && item.key === selected.key
          ? "text-hard-grey"
          : "text-medium-grey"
      } text-center`}
    >
      <span
        className={`h-2.5 w-2.5 rounded-full ${
          item.value ? colorByHostStatus[item.value] : ""
        }`}
      />
      {item.getLabel()}
    </span>
  );
};

export default HostStatusItemLabel;
