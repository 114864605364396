import i18next from "i18next";
import type { IProductPlan } from "#src/services/resource";

const planTFunc = i18next.getFixedT(null, "common", "aggregate.planDuration");

export interface IPlanDurationAdditional {
  duration: string | null;
  getTitle: () => string;
  getDecorator?: () => string;
  getSpan: () => string;
}

export type IAggregatedRecurringPrice =
  IProductPlan["recurring_prices"][number] & IPlanDurationAdditional;

/**
 * @param duration the value that API returns
 * @param getTitle i.e. "Chu kỳ 1 tháng"
 * @param getSpan i.e. "1 tháng"
 */
const durationExpandData: IPlanDurationAdditional[] = [
  {
    duration: null,
    getTitle: () => "-",
    getSpan: () => "-",
  },
  {
    duration: "monthly",
    getTitle: () => planTFunc("1month.title"),
    getSpan: () => planTFunc("1month.span"),
  },
  {
    duration: "quarter_yearly",
    getTitle: () => planTFunc("3months.title"),
    getSpan: () => planTFunc("3months.span"),
  },
  {
    duration: "half_yearly",
    getTitle: () => planTFunc("6months.title"),
    getSpan: () => planTFunc("6months.span"),
  },
  {
    duration: "yearly",
    getTitle: () => planTFunc("12months.title"),
    getDecorator: () => planTFunc("12months.tag"),
    getSpan: () => planTFunc("12months.span"),
  },
];

export const expandPlanDuration = <T extends { duration: string | null }>(
  objectWithDuration: T
) => {
  const matchedPlan = durationExpandData.find(
    (p) => p.duration === objectWithDuration.duration
  );

  if (matchedPlan) {
    return {
      ...objectWithDuration,
      ...matchedPlan,
    };
  }

  return undefined;
};
