// group nhận 1 trong các giá trị:
// "process" | "network" | "file"
// action nhận 1 trong các giá trị:
// "block_program" | "allow_program"
// "block_network" | "allow_network"
// "block_network_port" | "allow_network_port"
// "block_file" | "allow_file"

import type { IPaginateParams, IPaginatedResponse } from "#src/@types/api";
import endpoint from "#src/config/endpoint";
import request from "#src/utils/request";

export interface IControlItem {
  id: string;
  group: string;
  action: string;
  source?: string | null;
  matches: string[];
  description: string;
  hosts_tags: { id: number; name: string; type: string }[];
  disabled: boolean;
  created_time: number;
  updated_time: number;
  status: string;
}

export function get_control_details(workspace_id: string, control_id: string) {
  return request.get<never, IControlItem>(
    `${endpoint.WORKSPACES}/${workspace_id}/active_responses/${control_id}`
  );
}

interface IControlParams extends IPaginateParams {
  action: string | undefined;
  host_ids: string | undefined; // this is actually an array of numbers being joined with ","
  tag_ids: string | undefined; // this is actually an array of numbers being joined with ","
  group: string | undefined;
}

export function list_controls(workspace_id: string, params: IControlParams) {
  return request.get<never, IPaginatedResponse<IControlItem>>(
    `${endpoint.WORKSPACES}/${workspace_id}/active_responses`,
    { params }
  );
}

export function list_controls_shortly(
  workspace_id: string,
  params: {
    action: string | undefined;
    host_ids: string | undefined; // this is actually an array of numbers being joined with ","
    tag_ids: string | undefined; // this is actually an array of numbers being joined with ","
    group: string | undefined;
  }
) {
  return request.get<never, IControlItem[]>(
    `${endpoint.WORKSPACES}/${workspace_id}/active_responses`,
    { params: { paging: 0, ...params } }
  );
}

export function create_control(
  workspace_id: string,
  data: Pick<
    IControlItem,
    "group" | "action" | "source" | "matches" | "description"
  > & { hosts_tags: { id: string; type: string }[] }
) {
  return request.post<never, { id: string }>(
    `${endpoint.WORKSPACES}/${workspace_id}/active_responses?group=network`,
    data
  );
}

export function edit_control(
  workspace_id: string,
  control_id: string,
  data: Partial<
    Pick<IControlItem, "matches" | "description"> & {
      hosts_tags: { id: string; type: string }[];
    }
  >
) {
  return request.put<never, { id: string }>(
    `${endpoint.WORKSPACES}/${workspace_id}/active_responses/${control_id}`,
    data
  );
}

export function change_control_priority(
  workspace_id: string,
  data: { id: string; swap_id: string | null }
) {
  return request.post<never, { success: boolean }>(
    `${endpoint.WORKSPACES}/${workspace_id}/active_responses/priority`,
    data
  );
}

export function delete_control(workspace_id: string, control_id: string) {
  return request.delete<never>(
    `${endpoint.WORKSPACES}/${workspace_id}/active_responses/${control_id}`
  );
}
