import endpoint from "#src/config/endpoint";
import request from "#src/utils/request";

export interface INotificationSettingItem {
  enabled: boolean;
  mail: boolean;
  web: boolean;
  category: {
    id: string;
    name: string;
    name_vi: string;
    mail: boolean;
    web: boolean;
    category: string;
    order_number: number;
  };
}

function list_notification_settings() {
  return request.get<never, INotificationSettingItem[]>(
    `${endpoint.BASE}/notifications/settings`
  );
}

function update_notification_settings(
  notification_id: string,
  enabled: boolean
) {
  return request.put<never, { success: boolean }>(
    `${endpoint.BASE}/notifications/settings/${notification_id}`,
    { enabled }
  );
}

export default {
  list_notification_settings,
  update_notification_settings,
};
