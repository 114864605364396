// Libraries
import { Dropdown } from "@lockerpm/design";
import type { ReactNode } from "react";

// Resources
import { ReactComponent as DeleteBack2Line } from "#src/assets/images/icons/delete-back-2-line.svg";
import { ReactComponent as ArrowDownSLine } from "#src/assets/images/icons/arrow-down-s-line.svg";
import { ReactComponent as PlusIcon } from "#src/assets/images/icons/plus.svg";

// General
import type { IFilterItem } from "#src/@types/common";

// Components
import DropdownItemLabel from "#src/components/common/helper/antdProps/Dropdown/DropdownItemLabel";

interface IDropdownItemLabelProps {
  item: IFilterItem;
  selected: IFilterItem | null;
}

interface IFilterDropdownProps {
  label: string;
  selected: IFilterItem | null;
  options: IFilterItem[];
  onChangeSelection: (selected: IFilterItem | null) => void;
  CustomLabel?: (props: IDropdownItemLabelProps) => ReactNode;
  DropdownRender?: (originNode: ReactNode) => ReactNode;
}

const DropdownComponent = ({
  selected,
  options,
  onChangeSelection,
  CustomLabel,
  DropdownRender,
  children,
}: Omit<IFilterDropdownProps, "label"> & { children: ReactNode }) => {
  return (
    <Dropdown
      menu={{
        items: options.map((item) => ({
          key: item.key,
          value: item.value,
          label: CustomLabel ? (
            <CustomLabel item={item} selected={selected} />
          ) : (
            <DropdownItemLabel selected={selected?.key === item.key}>
              {item.getLabel()}
            </DropdownItemLabel>
          ),
        })),
        onClick: (obj) => {
          const newSelected = options.find((item) => item.key === obj.key);
          onChangeSelection(newSelected ? newSelected : null);
        },
      }}
      dropdownRender={DropdownRender}
      placement="bottomLeft"
      arrow
      trigger={["click"]}
    >
      {children}
    </Dropdown>
  );
};

const FilterDropdown = ({
  label,
  selected,
  options,
  onChangeSelection,
  CustomLabel,
  DropdownRender,
}: IFilterDropdownProps) => {
  return selected ? (
    <div className="border border-light-grey text-hard-grey rounded-md flex items-center">
      <button
        className="ml-3 h-4 w-4 text-inherit"
        onClick={() => onChangeSelection(null)}
      >
        <DeleteBack2Line width={"1rem"} height={"1rem"} />
      </button>
      <span className="px-3 border-r border-light-grey whitespace-nowrap">
        {label}
      </span>
      <DropdownComponent
        selected={selected}
        options={options}
        onChangeSelection={onChangeSelection}
        CustomLabel={CustomLabel}
        DropdownRender={DropdownRender}
      >
        <button className="text-primary font-medium-16 p-3 flex gap-2 items-center">
          <span className="whitespace-nowrap max-w-[16rem] text-ellipsis overflow-hidden">
            {selected.getLabel ? selected.getLabel() : null}
          </span>
          <ArrowDownSLine
            width={"1rem"}
            height={"1rem"}
            className="fill-hard-grey"
          />
        </button>
      </DropdownComponent>
    </div>
  ) : (
    <DropdownComponent
      selected={selected}
      options={options}
      onChangeSelection={onChangeSelection}
      CustomLabel={CustomLabel}
      DropdownRender={DropdownRender}
    >
      <button className="border border-dashed border-light-grey text-hard-grey rounded-md flex gap-3 p-3 items-center">
        <PlusIcon width={"1rem"} height={"1rem"} />
        <span className="whitespace-nowrap">{label}</span>
      </button>
    </DropdownComponent>
  );
};

export default FilterDropdown;
