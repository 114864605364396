import scansServices from "#src/services/scans";
import SubdomainTableGrid from "./Table";
import useFetchNoPaging from "#src/hooks/useFetchNoPaging";
import { useMemo } from "react";

interface IScanDetailsSubdomainsProps {
  workspaceId: string;
  scanId: number;
  domainId: number;
}

const ScanDetailsSubdomains = ({
  workspaceId,
  scanId,
  domainId,
}: IScanDetailsSubdomainsProps) => {
  const fetchParams = useMemo<
    Parameters<typeof scansServices.get_scan_subdomains>
  >(
    () => [workspaceId, scanId, { domain_id: domainId }],
    [workspaceId, scanId, domainId]
  );

  const { list: subdomainList, isLoading } = useFetchNoPaging(
    scansServices.get_scan_subdomains,
    fetchParams
  );

  return (
    <section className="flex flex-col gap-6 pt-6">
      <SubdomainTableGrid subdomainList={subdomainList} isLoading={isLoading} />
    </section>
  );
};

export default ScanDetailsSubdomains;
