import type { Params, RouteObject } from "react-router-dom";
import i18next from "i18next";
import { helperPathname, pathname } from "#src/config/pathname";

import DeviceHosts from "#src/pages/devices/hosts";
import DeviceHostDetails from "#src/pages/devices/hosts/details";
import DevicePolicies from "#src/pages/devices/policies";
import DeviceControl from "#src/pages/devices/control";
import DevicePolicyAddPolicy from "#src/pages/devices/policies/add";
import DevicePolicyDetails from "#src/pages/devices/policies/details";

const breadcrumbTFunc = i18next.getFixedT(null, "general", "breadcrumb");

export const deviceRoute: RouteObject = {
  path: helperPathname.DEVICES,
  handle: {
    crumb: () => ({
      getText: () => breadcrumbTFunc("device.label"),
      path: pathname.HOSTS,
    }),
  },
  children: [
    {
      path: pathname.HOSTS,
      handle: {
        crumb: () => ({
          getText: () => breadcrumbTFunc("device.host.label"),
          path: pathname.HOSTS,
        }),
      },
      children: [
        {
          index: true,
          element: <DeviceHosts />,
        },
        {
          path: pathname.HOST_DETAILS,
          handle: {
            crumb: (params: Readonly<Params<string>>) => ({
              getText: () =>
                Object.hasOwn(params, "host") ? params["host"] : "---",
              path: pathname.HOST_DETAILS,
            }),
          },
          element: <DeviceHostDetails />,
        },
      ],
    },
    {
      path: pathname.POLICIES,
      handle: {
        crumb: () => ({
          getText: () => breadcrumbTFunc("device.policy.label"),
          path: pathname.POLICIES,
        }),
      },
      children: [
        {
          index: true,
          element: <DevicePolicies />,
        },
        {
          path: pathname.ADD_POLICIES,
          handle: {
            crumb: () => ({
              getText: () => breadcrumbTFunc("device.policy.addPolicy.label"),
              path: pathname.ADD_POLICIES,
            }),
          },
          element: <DevicePolicyAddPolicy />,
        },
        {
          path: pathname.POLICY_DETAILS,
          handle: {
            crumb: (params: Readonly<Params<string>>) => ({
              getText: () =>
                Object.hasOwn(params, "policy") ? params["policy"] : "---",
              path: pathname.POLICY_DETAILS,
            }),
          },
          element: <DevicePolicyDetails />,
        },
      ],
    },
    {
      path: pathname.CONTROL,
      handle: {
        crumb: () => ({
          getText: () => breadcrumbTFunc("device.control.label"),
          path: pathname.CONTROL,
        }),
      },
      element: <DeviceControl />,
    },
  ],
};
