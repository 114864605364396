// Libraries
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { Player } from "@lottiefiles/react-lottie-player";

// Resource
import LoadingCystack2Json from "#src/assets/images/animations/loading-cystack-2.json";

// Support
import Loader from "./Loader";

/** Loading state for CyStack Platform, with a little bit of fun. */
const LoadingState = () => {
  const { t } = useTranslation("common", { keyPrefix: "state.loading" });

  const catSeed = useRef<number>(Math.floor(Math.random() * 10000));
  const catReady = catSeed.current < 5;

  const [showText, setShowText] = useState<boolean>(false);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setShowText(true);
    }, 5000);

    return () => clearTimeout(timeout);
  }, []);

  if (catReady) {
    console.log(catSeed);
  }

  return (
    <div className="h-40 w-full flex justify-center items-center py-12">
      {catReady ? (
        <div className="relative">
          <img
            src={"/loading-cat.jpg"}
            className="object-cover max-h-40 overflow-hidden"
          />
          <div className="absolute top-[4.55rem] left-[4.65rem]">
            <Loader baseSize={0.8} particleCount={12} />
          </div>
        </div>
      ) : (
        <div className="flex flex-col items-center gap-6">
          <Player
            autoplay
            loop
            src={LoadingCystack2Json}
            className="h-12 w-12"
          ></Player>
          {showText ? (
            <div className="flex flex-col items-center gap-1">
              <h2>{t("loading")}</h2>
              <h4>{t("pleaseWait")}</h4>
            </div>
          ) : null}
        </div>
      )}
    </div>
  );
};

export default LoadingState;
