import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import { useTranslation } from "react-i18next";
import { Dropdown } from "@lockerpm/design";

import { ReactComponent as ExpiredBadge } from "#src/assets/images/icons/expired-badge.svg";
import { ReactComponent as ValidBadge } from "#src/assets/images/icons/valid-badge.svg";
import { ReactComponent as ArrowDownSLine } from "#src/assets/images/icons/arrow-down-s-line.svg";

import type { ICertificateItem } from "#src/services/assets/certificates";

dayjs.extend(relativeTime);

interface ICertificateExpirationCardProps {
  expireNotAfter: ICertificateItem["not_after"];
  issuerName: ICertificateItem["issuer"]["issuer"];
  name: ICertificateItem["common_names"][0];
  certificateId?: ICertificateItem["id"];
  certificates?: Array<ICertificateItem>;
  setCertificate?: (item: ICertificateItem) => void
}

const CertificateExpirationCard = ({
  expireNotAfter,
  issuerName,
  name,
  certificateId,
  certificates = [],
  setCertificate = () => {},
}: ICertificateExpirationCardProps) => {
  const { t } = useTranslation("assets", {
    keyPrefix: "domains.page.details.tab.certificates",
  });

  /**Time difference from now to expire timestamp.
   * If this returns positive, the certificate is not expired
   */
  const expireDiff = dayjs.unix(expireNotAfter).diff(dayjs(), "day");

  return (
    <div className="flex gap-6 items-center w-full rounded-md p-6 bg-bright-grey">
      {expireDiff > 0 ? (
        <ValidBadge className="h-24" />
      ) : (
        <ExpiredBadge className="h-24" />
      )}
      <div className="flex flex-col gap-2 flex-1">
        <div className="flex items-center justify-between">
          <span className="font-medium-20">{name}</span>
          {
            certificates?.length > 0 && <Dropdown
              overlayClassName="certificates-dropdown"
              placement="bottomRight"
              menu={{
                items: certificates.map((cert) => ({
                  key: cert.id,
                  label: (
                    <div className="flex items-center justify-between p-3 min-w-[32.75rem]">
                      <div className="flex items-center">
                        {
                          (() => {
                            const expireDiff = dayjs.unix(cert.not_after).diff(dayjs(), "day");
                            if (expireDiff > 0) {
                              return <ValidBadge className="h-12 w-12" />
                            }
                            return <ExpiredBadge className="h-12 w-12" />
                          })()
                        }
                        <span className="font-regular-16 ml-3 text-dark-blue">{name}</span>
                      </div>
                      <p className="font-regular-16 text-medium-grey">
                        {t("rootCard.issuedBy")}{" "}
                        <span className="text-dark-blue">{issuerName}</span>
                      </p>
                    </div>
                  ),
                  onClick: ({ key }) => {
                    const clickedItem: ICertificateItem | null = certificates.find((item) => item.id == Number(key)) || null;
                    if (clickedItem) {
                      setCertificate(clickedItem);
                    }
                  },
                })),
                selectedKeys: [`${certificateId}`]
              }}
              trigger={["click"]}
            >
              <div
                id="certificates-dropdown"
                className="cursor-pointer"
              >
                <ArrowDownSLine />
              </div>
            </Dropdown> 
          }
        </div>
        <div className="flex flex-col gap-1">
          {expireDiff > 0 ? (
            <p className="text-medium-grey">
              {t("rootCard.expiresIn")}{" "}
              <span className="text-dark-blue">
                {t("rootCard.expireTimestamp", { numOfDays: expireDiff })}
              </span>{" "}
              {t("rootCard.by")}{" "}
              <span className="text-dark-blue">
                {dayjs.unix(expireNotAfter).format("HH:mm DD.MM.YYYY")}
              </span>
            </p>
          ) : (
            <p className="text-medium-grey">
              {t("rootCard.expiredAt")}{" "}
              <span className="text-dark-blue">
                {dayjs.unix(expireNotAfter).format("HH:mm DD.MM.YYYY")}
              </span>
            </p>
          )}
          <p className="text-medium-grey">
            {t("rootCard.issuedBy")}{" "}
            <span className="text-dark-blue">{issuerName}</span>
          </p>
        </div>
      </div>
    </div>
  );
};

export default CertificateExpirationCard;
