import { IconButton } from "#src/components/common/system/Button";
import { Input } from "#src/components/common/system/Input";
import { ReactComponent as Search2Line } from "#src/assets/images/icons/search-2-line.svg";
import { useTranslation } from "react-i18next";

interface ISearchBoxProps {
  searchKeyword: string;
  onChangeSearchKeyword: (value: string) => void;
  placeholder?: string;
  stretchWidth?: boolean;
}

const SearchBox = ({
  searchKeyword,
  onChangeSearchKeyword,
  placeholder,
  stretchWidth = false,
}: ISearchBoxProps) => {
  const { t } = useTranslation("common", { keyPrefix: "filter.searchBox" });
  return (
    <div className="flex gap-2">
      <Input
        placeholder={placeholder ? placeholder : t("placeholder")}
        className={stretchWidth ? "h-11" : "w-64 h-11"}
        value={searchKeyword}
        onChange={(e) => onChangeSearchKeyword(e.currentTarget.value)}
      />
      <IconButton variant="label-blue" disabled>
        <Search2Line />
      </IconButton>
    </div>
  );
};

export default SearchBox;
