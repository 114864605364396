import { CardSolution, ISolution } from "#src/pages/solutions";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";

const productKeys = [
  "secrets_manager",
  "password_manager",
  "endpoint",
  "data_leak",
  "vuln_scan",
  "trust_center",
];
const serviceKeys = [
  "penetration_testing",
  "red_teaming",
  "cloud_security_audit",
  "source_code_audit",
  "blockchain_protocol_audit",
  "whitehub_bug_bounty",
  "security_compliance",
  "security_operations_center",
  "vulnerability_management",
];
const AllSolutions = ({ solutions }: { solutions: ISolution[] }) => {
  const { t } = useTranslation("solutions");

  const products = useMemo(() => {
    const filteredServices = solutions.filter((item) =>
      productKeys.includes(item.key)
    );
    return filteredServices.sort(
      (a, b) => productKeys.indexOf(a.key) - productKeys.indexOf(b.key)
    );
  }, [solutions]);
  const services = useMemo(() => {
    const filteredServices = solutions.filter((item) =>
      serviceKeys.includes(item.key)
    );
    return filteredServices.sort(
      (a, b) => serviceKeys.indexOf(a.key) - serviceKeys.indexOf(b.key)
    );
  }, [solutions]);

  return (
    <section className="space-y-12">
      <div className="space-y-6">
        <h3 className="normal-case font-medium-24">{t("products")}</h3>
        <div className="grid grid-cols-3 gap-6">
          {products.map((item) => (
            <div key={item.title} className="col-span-1">
              <CardSolution
                logo={item.logo}
                title={item.title}
                description={item.description}
                url={item.url}
                enabled={item.enabled}
                isCreate={item.isCreate}
                isComming={item.isComming}
                isExternal={item.isExternal}
              />
            </div>
          ))}
        </div>
      </div>
      <div className="space-y-6">
        <h3 className="normal-case font-medium-24 mb-6">{t("services")}</h3>
        <div className="grid grid-cols-3 gap-6">
          {services.map((item) => (
            <div key={item.title} className="col-span-1">
              <CardSolution
                logo={item.logo}
                title={item.title}
                description={item.description}
                url={item.url}
                enabled={item.enabled}
                isCreate={item.isCreate}
                isComming={item.isComming}
                isExternal={item.isExternal}
              />
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default AllSolutions;
