// Libraries
import { type Dispatch, type SetStateAction, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

// General
import constants from "#src/config/constants";
import type { IFilterItem } from "#src/@types/common";

// Components
import PaginationBar from "#src/components/common/helper/PaginationBar";
import useFetchPaginated from "#src/hooks/useFetchPaginated";
import { apiErrorHandler } from "#src/utils/apiErrorHandler";

// Children
import MemberTableGrid from "./Table";
import workspaceServices, {
  type IWorkspaceMember,
} from "#src/services/workspace";
import MemberFilter from "./Filter";
import AddMemberDrawer from "../AddMemberDrawer";

interface IMemberTableProps {
  workspaceId: string;
  currentUserRole: string | null;
  openAddMemberDrawer: boolean;
  setOpenAddMemberDrawer: Dispatch<SetStateAction<boolean>>;
}

const MemberTable = ({
  workspaceId,
  currentUserRole,
  openAddMemberDrawer,
  setOpenAddMemberDrawer,
}: IMemberTableProps) => {
  const { t } = useTranslation("workspace", {
    keyPrefix: "member",
  });

  const [memberRole, setMemberRole] = useState<IFilterItem | null>(null);
  const [searchKeyword, setSearchKeyword] = useState<string>("");
  const [selectedMembers, setSelectedMembers] = useState<IWorkspaceMember[]>(
    []
  );

  const [selectedPage, setSelectedPage] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(constants.DEFAULT_PAGE_SIZE);

  const fetchParams = useMemo<
    Parameters<typeof workspaceServices.list_members>
  >(
    () => [
      workspaceId,
      {
        roles: memberRole ? memberRole.value : undefined,
        q: searchKeyword,
        page: selectedPage,
        size: pageSize,
      },
    ],
    [workspaceId, memberRole, searchKeyword, selectedPage, pageSize]
  );

  const onChangeSelectedMembers = (selected: IWorkspaceMember) => {
    if (selectedMembers.map((item) => item.id).includes(selected.id)) {
      setSelectedMembers((prev) => [
        ...prev.filter((item) => item.id !== selected.id),
      ]);
    } else {
      setSelectedMembers((prev) => [...prev, selected]);
    }
  };

  const onSelectAllMembers = () => {
    if (memberList) {
      setSelectedMembers(memberList);
    }
  };

  const onDeselectAllMembers = () => {
    setSelectedMembers([]);
  };

  const onChangeMemberRoleSelection = (selected: typeof memberRole) => {
    setMemberRole(selected);
    setSelectedPage(1);
  };

  const onChangeSearchKeyword = (keyword: string) => {
    setSearchKeyword(keyword);
    setSelectedPage(1);
  };

  const onChangePage = (selectedPage: number) => {
    setSelectedPage(selectedPage);
    setSelectedMembers([]);
  };

  const onChangePageSize = (size: number) => {
    setPageSize(size);
    setSelectedPage(1);
    setSelectedMembers([]);
  };

  const onDeleteSelectedMembers = () => {
    Promise.allSettled(
      selectedMembers.map((member) => {
        return workspaceServices.remove_member(workspaceId, member.id);
      })
    )
      .then(() => {
        setSelectedMembers([]);
        updateMemberList();
      })
      .catch((error) => {
        apiErrorHandler(error, {
          toastMessage: t("notification.deleteMember.fail"),
        });
      });
  };

  const {
    list: memberList,
    count: memberCount,
    isLoading,
    updateData: updateMemberList,
  } = useFetchPaginated(workspaceServices.list_members, fetchParams);

  return (
    <>
      <MemberFilter
        totalCount={memberList ? memberList.length : 0}
        selectedCount={selectedMembers.length}
        memberRole={memberRole}
        searchKeyword={searchKeyword}
        onChangeMemberRoleSelection={onChangeMemberRoleSelection}
        onChangeSearchKeyword={onChangeSearchKeyword}
        onSelectAllMembers={onSelectAllMembers}
        onDeselectAllMembers={onDeselectAllMembers}
        onDeleteSelectedMembers={onDeleteSelectedMembers}
      />
      <MemberTableGrid
        workspaceId={workspaceId}
        currentUserRole={currentUserRole}
        memberList={memberList}
        isLoading={isLoading}
        selectedMemberIds={selectedMembers.map((item) => item.id)}
        onChangeSelectedMembers={onChangeSelectedMembers}
        refreshMemberList={updateMemberList}
      />
      <PaginationBar
        numOfResult={memberCount}
        selectedPage={selectedPage}
        pageSize={pageSize}
        onChangePage={onChangePage}
        onChangePageSize={onChangePageSize}
      />
      <AddMemberDrawer
        workspaceId={workspaceId}
        open={openAddMemberDrawer}
        onClose={() => setOpenAddMemberDrawer(false)}
        onRefresh={updateMemberList}
      />
    </>
  );
};

export default MemberTable;
