// Libraries
import { Drawer } from "@lockerpm/design";
import { useTranslation } from "react-i18next";
import { useMemo, useState } from "react";

// Resources
import { ReactComponent as CloseLine } from "#src/assets/images/icons/close-line.svg";

// General
import type { IDrawerAdapterProps } from "#src/@types/common";
import constants from "#src/config/constants";

// Components
import { Button, IconButton } from "#src/components/common/system/Button";
import useFetchPaginated from "#src/hooks/useFetchPaginated";
import DropdownItemLabel from "#src/components/common/helper/antdProps/Dropdown/DropdownItemLabel";
import { InputWithSuggestion } from "#src/components/common/legacy/InputWithSuggestions";
import { ObjectImage } from "#src/components/common/system/Object";
import { apiErrorHandler } from "#src/utils/apiErrorHandler";

// API-related
import resourceServices, {
  type IThirdPartyService,
} from "#src/services/resource";
import type { ITrustCenterThirdPartyItem } from "#src/services/trustCenter/thirdParty";
import trustCenterServices from "#src/services/trustCenter";

interface IAddThirdPartyDrawerProps extends IDrawerAdapterProps {
  addedServices: ITrustCenterThirdPartyItem[];
}

const AddThirdPartyDrawer = ({
  workspaceId,
  open,
  onClose,
  onRefresh,
  addedServices,
}: IAddThirdPartyDrawerProps) => {
  const { t } = useTranslation("trustCenter", {
    keyPrefix: "thirdParty.addThirdPartyDrawer",
  });

  const [selectedServices, setSelectedServices] = useState<
    IThirdPartyService[]
  >([]);
  const [searchKeyword, setSearchKeyword] = useState<string>("");

  const [pending, setPending] = useState<boolean>(false);

  const onCloseDrawerAndCleanup = () => {
    onClose();
    setSelectedServices([]);
    setSearchKeyword("");
  };

  const onAddService = (newService: IThirdPartyService) => {
    setSelectedServices((prev) => [...prev, newService]);
    setSearchKeyword("");
  };

  const onRemoveService = (service: IThirdPartyService) => {
    setSelectedServices((prev) =>
      prev.filter((item) => item.id !== service.id)
    );
  };

  const onSubmitAddingServices = () => {
    setPending(true);
    trustCenterServices
      .create_multiple_trust_center_third_party(
        workspaceId,
        selectedServices.map((service) => ({ service_id: service.id }))
      )
      .then((response) => {
        if (response.success) {
          onRefresh();
          onCloseDrawerAndCleanup();
        }
      })
      .catch((error) => {
        apiErrorHandler(error, {
          toastMessage: t("notification.addServices.fail"),
        });
      })
      .finally(() => {
        setPending(false);
      });
  };

  const resourceParams = useMemo<
    Parameters<typeof resourceServices.list_third_party_services>
  >(
    () => [{ q: searchKeyword, page: 1, size: constants.DEFAULT_PAGE_SIZE }],
    [searchKeyword]
  );

  const { list: serviceList } = useFetchPaginated(
    resourceServices.list_third_party_services,
    resourceParams
  );

  return (
    <Drawer
      open={open}
      onClose={onCloseDrawerAndCleanup}
      closable={false}
      title={<h2>{t("title")}</h2>}
      extra={
        <IconButton
          variant="label-grey"
          size={9}
          onClick={onCloseDrawerAndCleanup}
        >
          <CloseLine />
        </IconButton>
      }
      footer={
        <div className="flex flex-col gap-3">
          <Button
            className="w-full"
            size="large"
            pending={pending}
            onClick={onSubmitAddingServices}
          >
            {t("button.add")}
          </Button>
          <Button
            variant="secondary"
            className="w-full"
            size="large"
            onClick={onCloseDrawerAndCleanup}
          >
            {t("button.cancel")}
          </Button>
        </div>
      }
    >
      <div className="flex flex-col gap-3 p-6">
        <h4>{t("field.serviceName.label")}</h4>
        <InputWithSuggestion
          id="thirdPartyService-search"
          value={searchKeyword}
          onChange={(e) => {
            setSearchKeyword(e.target.value);
          }}
          suggestionList={
            serviceList
              ? serviceList
                  .filter(
                    (service) =>
                      !selectedServices
                        .map((item) => item.id)
                        .includes(service.id) &&
                      (!addedServices
                        .map((item) => item.name)
                        .includes(service.name) ||
                        !addedServices
                          .map((item) => item.name)
                          .includes(service.name))
                  )
                  .map((service) => ({
                    key: service.id.toString(),
                    value: service.id.toString(),
                    label: (
                      <DropdownItemLabel
                        selected={false}
                        syncWidthId="thirdPartyService-search"
                      >
                        <ObjectImage
                          data={service.logo}
                          className="h-6 w-6 min-w-[1.5rem] rounded-md"
                        />
                        {service.name}
                      </DropdownItemLabel>
                    ),
                  }))
              : []
          }
          onClickSuggestion={(key) => {
            const selected = serviceList?.find(
              (service) => service.id.toString() === key
            );
            if (selected) {
              onAddService(selected);
            }
          }}
          onSubmitWithEnter={() => {
            const selected = serviceList?.find(
              (service) =>
                service.name.toLowerCase() === searchKeyword.toLowerCase() ||
                service.name.toLowerCase() === searchKeyword.toLowerCase()
            );
            if (selected) {
              onAddService(selected);
            }
          }}
        />
        {selectedServices.map((service) => (
          <div
            key={`trustCenter-addThirdPartyService-selected-${service.id}`}
            className="rounded-md bg-bright-grey pl-3 h-11 flex items-center justify-between gap-2"
          >
            <div className="flex items-center gap-2">
              <ObjectImage
                data={service.logo}
                className="h-6 w-6 min-w-[1.5rem] rounded-md"
              />
              {service.name}
            </div>
            <button
              className="h-8 w-8 flex items-center justify-center"
              onClick={() => {
                onRemoveService(service);
              }}
            >
              <div className="h-6 w-6 flex items-center justify-center rounded-full border border-warning-red-40% bg-warning-red-5%">
                <CloseLine className="h-4 w-4 fill-warning-red-danger" />
              </div>
            </button>
          </div>
        ))}
      </div>
    </Drawer>
  );
};

export default AddThirdPartyDrawer;
