import type { IPaginatedResponse, IPaginateParams } from "#src/@types/api";
import endpoint from "#src/config/endpoint";
import request from "#src/utils/request";
import type {
  IPentestProjectMember,
  IPentestProjectMsspMember,
} from "../../pentest/members";

export function list_pentest_project_workspace_members(
  workspace_id: string,
  pentest_alias: string,
  params: IPaginateParams
) {
  return request.get<never, IPaginatedResponse<IPentestProjectMember>>(
    `${endpoint.BASE}/mssp/${workspace_id}/pentest_projects/${pentest_alias}/members/workspace`,
    { params }
  );
}

export function list_pentest_project_mssp_members(
  workspace_id: string,
  pentest_alias: string,
  params: IPaginateParams
) {
  return request.get<never, IPaginatedResponse<IPentestProjectMsspMember>>(
    `${endpoint.BASE}/mssp/${workspace_id}/pentest_projects/${pentest_alias}/members`,
    { params }
  );
}

export function create_mssp_pentest_project_member(
  workspace_id: string,
  pentest_alias: string,
  data: { members: { email: string; role: string }[] }
) {
  return request.post<never, { success: boolean }>(
    `${endpoint.BASE}/mssp/${workspace_id}/pentest_projects/${pentest_alias}/members`,
    data
  );
}

export function update_mssp_pentest_project_member(
  workspace_id: string,
  pentest_alias: string,
  member_id: string,
  data: { role?: string; default_assign?: boolean }
) {
  return request.put<never, { success: boolean }>(
    `${endpoint.BASE}/mssp/${workspace_id}/pentest_projects/${pentest_alias}/members/${member_id}`,
    data
  );
}

export function delete_mssp_pentest_project_member(
  workspace_id: string,
  pentest_alias: string,
  member_id: string
) {
  return request.delete(
    `${endpoint.BASE}/mssp/${workspace_id}/pentest_projects/${pentest_alias}/members/${member_id}`
  );
}
