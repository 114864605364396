import i18next from "i18next";
import usFlag from "#src/assets/images/flags/us.png";
import vnFlag from "#src/assets/images/flags/vn.png";
import { Flag } from "#src/components/common/Flag";

const fixedTFunc = i18next.getFixedT(null, "common", "languages");

export const LANGUAGE_LIST: {
  key: string;
  searchValue: string;
  getLabel: () => string;
  flag: JSX.Element;
}[] = [
  {
    key: "en",
    searchValue: "english",
    getLabel: () => fixedTFunc("english"),
    flag: <Flag pngProp={usFlag} />,
  },
  {
    key: "vi",
    searchValue: "vietnamese",
    getLabel: () => fixedTFunc("vietnamese"),
    flag: <Flag pngProp={vnFlag} />,
  },
];
