import { ReactComponent as ItalicIcon } from "#src/assets/images/icons/italic.svg";
import EditorButton from "../EditorButton";
import { COMMENT_EDITOR_TEXTAREA_ID } from "../constants";

const ItalicButton = ({ disabled }: { disabled?: boolean }) => {
  return (
    <EditorButton
      onClick={() => {
        const editor = document.getElementById(
          COMMENT_EDITOR_TEXTAREA_ID
        ) as HTMLTextAreaElement;
        const selectionStart = editor.selectionStart;
        const selectionEnd = editor.selectionEnd;
        const selectionText = editor.value.substring(
          selectionStart,
          selectionEnd
        );

        const prefix = "*";
        const suffix = "*";

        editor.setRangeText(
          prefix + selectionText + suffix,
          selectionStart,
          selectionEnd
        );
        editor.setSelectionRange(
          selectionStart + prefix.length,
          selectionEnd + prefix.length
        );
      }}
      disabled={disabled}
    >
      <ItalicIcon />
    </EditorButton>
  );
};

export default ItalicButton;
