// Libraries
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";

// Resources
import { ReactComponent as ArrowRightLine } from "#src/assets/images/icons/arrow-right-line.svg";
import { ReactComponent as ArrowLeftLine } from "#src/assets/images/icons/arrow-left-line.svg";

// Components
import { Button } from "#src/components/common/system/Button";
import { ContentSection, StickySection } from "#src/layouts/content";
import TabProgress, {
  type IFormProgressStep,
} from "#src/components/common/TabProgress";
import { skipToContentTop } from "#src/utils/common";
import { apiErrorHandler } from "#src/utils/apiErrorHandler";

// API-related
import resourceServices, { type IProductPlan } from "#src/services/resource";
import paymentService, {
  type IPromotionCodeResponse,
  type IPaymentForm,
  type IValidPromotionCode,
  type IPricingInput,
} from "#src/services/payment";
import { PaymentMethodValue } from "#src/config/filter/workspace/value";

// Children
import TrustCenterCustomDomainChoosePlan from "#src/components/trustCenter/registerDomain/ChoosePlan";
import FillForm from "#src/components/trustCenter/registerDomain/FillForm";
import CompletePayment from "#src/components/trustCenter/registerDomain/CompletePayment";
import {
  type ContactFormSelection,
  jobTitleList,
} from "#src/components/trustCenter/onboard/ContactForm/contactDetailFields";

const WorkspaceBillingUpgradeTcCustomDomain = () => {
  const { t } = useTranslation("workspace", {
    keyPrefix: "billing.upgrade.trustCenterCustomDomain",
  });

  const { workspace: workspaceId } = useParams<{ workspace: string }>();

  // Initial data
  const [productPlanInfo, setProductPlanInfo] = useState<IProductPlan | null>(
    null
  );
  const [isLoadingProductPlanInfo, setLoadingProductPlanInfo] =
    useState<boolean>(false);
  const [oldPlanDuration, setOldPlanDuration] = useState<string>();
  const [defaultPaymentSettings, setDefaultPaymentSettings] = useState<Awaited<
    ReturnType<typeof paymentService.retrieve_workspace_payment_settings>
  > | null>(null);

  // Current step of form filling
  const [registerProgress, setRegisterProgress] = useState<number>(0);

  // User's selections
  const [selectedPlan, setSelectedPlan] = useState<
    IProductPlan["recurring_prices"][number] | null
  >(null);
  const [pricingForm, setPricingForm] = useState<IPricingInput>({
    currency: "VND",
    product_plans: [
      {
        product_plan_id: "tc_custom_domain",
        duration: "monthly",
      },
    ],
  });
  const [paymentForm, setPaymentForm] = useState<IPaymentForm>({
    payment_method: PaymentMethodValue.BANKING,
    customer: {
      full_name: "",
      phone_number: "",
      email: "",
      job_title: jobTitleList[0].value,
    },
    product_plans: [
      {
        product_plan_id: "tc_custom_domain",
        duration: "monthly",
      },
    ],
  });
  const [jobTitle, setJobTitle] = useState<ContactFormSelection>(
    jobTitleList[0]
  );

  const [pricingInfo, setPricingInfo] = useState<Awaited<
    ReturnType<typeof paymentService.calculate_pricing>
  > | null>(null);
  const [paymentInfo, setPaymentInfo] = useState<Awaited<
    ReturnType<typeof paymentService.upgrade_subscription>
  > | null>(null);
  const [errorObj, setErrorObj] = useState<{
    full_name: string | undefined;
    phone_number: string | undefined;
    email: string | undefined;
  }>({ full_name: undefined, phone_number: undefined, email: undefined });

  const getPricingInfo = async (form: typeof pricingForm) => {
    if (workspaceId) {
      await paymentService
        .calculate_pricing(workspaceId, form)
        .then((response) => {
          // TODO: check error_promo
          setPricingInfo(response);
        })
        .catch(apiErrorHandler);
    }
  };

  // Typeguard for promotion code
  function isPromoValid(
    promo: IPromotionCodeResponse
  ): promo is IValidPromotionCode {
    return promo.error_promo === null;
  }

  const onChangeSelectedPlan = (
    value: IProductPlan["recurring_prices"][number]
  ) => {
    setSelectedPlan(value);
    setPaymentForm((prev) => ({
      ...prev,
      product_plans: [
        {
          product_plan_id: "tc_custom_domain",
          duration: value.duration,
        },
      ],
    }));
  };

  // For the edit modal in the step 2 (FillForm)
  const onEditPlan = (value: IProductPlan["recurring_prices"][number]) => {
    onChangeSelectedPlan(value);
    if (workspaceId) {
      paymentService
        .calculate_pricing(workspaceId, {
          currency: "VND",
          product_plans: [
            {
              product_plan_id: "tc_custom_domain",
              duration: value.duration,
              promo_code: pricingForm.product_plans[0].promo_code,
            },
          ],
          calc_action: "upgrade",
        })
        .then((response) => {
          // TODO: check error_promo
          setPricingInfo(response);
        })
        .catch(apiErrorHandler);
    }
  };

  const onApplyPromocode = async (value: string) => {
    if (!workspaceId) return false;

    return paymentService
      .check_promotion_code(workspaceId, { promo_code: value })
      .then((response) => {
        if (isPromoValid(response)) {
          const newPricingForm: typeof pricingForm = {
            ...pricingForm,
            product_plans: pricingForm.product_plans.map((plan) =>
              response.only_plan.includes(plan.product_plan_id) &&
              !plan.promo_code
                ? {
                    ...plan,
                    promo_code: value,
                  }
                : plan
            ),
          };
          setPricingForm(newPricingForm);
          getPricingInfo(newPricingForm);
          return true;
        }
        return false;
      })
      .catch(apiErrorHandler);
  };

  const onRemovePromocode = (removeId: String) => {
    const newPricingForm: typeof pricingForm = {
      ...pricingForm,
      product_plans: pricingForm.product_plans.map((plan) =>
        plan.product_plan_id === removeId
          ? {
              ...plan,
              promo_code: undefined,
            }
          : plan
      ),
    };
    setPricingForm(newPricingForm);
    getPricingInfo(newPricingForm);
  };

  const onClickNextToFillForm = () => {
    if (workspaceId && selectedPlan) {
      paymentService
        .calculate_pricing(workspaceId, {
          currency: "VND",
          product_plans: [
            {
              product_plan_id: "tc_custom_domain",
              duration: selectedPlan.duration,
              promo_code: "",
            },
          ],
          calc_action: "upgrade",
        })
        .then((response) => {
          // TODO: check error_promo
          setRegisterProgress(1);
          skipToContentTop();
          setPricingInfo(response);
        })
        .catch(apiErrorHandler);
    } else {
      // This should never happen since if this happens the button will be disabled already.
      // TODO: make something formal here just to make sure.
      console.log("Must select a plan to continue");
    }
  };

  const onClickNextToCompletePayment = () => {
    if (
      workspaceId &&
      // TODO: better validate, send error to correspondent fields
      paymentForm.customer.full_name &&
      paymentForm.customer.email &&
      paymentForm.customer.phone_number
    ) {
      paymentService
        .upgrade_subscription(workspaceId, paymentForm)
        .then((response) => {
          setRegisterProgress(2);
          skipToContentTop();
          setPaymentInfo(response);
        })
        .catch(apiErrorHandler);
    } else {
      // This should never happen since if this happens the button will be disabled already.
      console.log("Must fill the information to continue");
    }
  };

  const registerSteps: IFormProgressStep[] = workspaceId
    ? [
        {
          title: t("chooseAPlan.tabLabel"),
          element: (
            <TrustCenterCustomDomainChoosePlan
              planList={productPlanInfo ? productPlanInfo.recurring_prices : []}
              isLoading={isLoadingProductPlanInfo}
              selectedPlan={selectedPlan}
              onChangeSelectedPlan={onChangeSelectedPlan}
              oldPlanDuration={oldPlanDuration}
            />
          ),
        },
        {
          title: t("fillForm.tabLabel"),
          element: (
            <>
              <FillForm
                productPlanInfo={productPlanInfo}
                selectedPlan={selectedPlan}
                pricingInfo={pricingInfo}
                paymentForm={paymentForm}
                setPaymentForm={setPaymentForm}
                jobTitle={jobTitle}
                setJobTitle={setJobTitle}
                defaultPaymentSettings={defaultPaymentSettings}
                onApplyPromocode={onApplyPromocode}
                onRemovePromocode={onRemovePromocode}
                errorObj={errorObj}
                setErrorObj={setErrorObj}
                onEditPlan={onEditPlan}
                oldPlanDuration={oldPlanDuration}
              />
            </>
          ),
        },
        {
          title: t("payment.tabLabel"),
          element: (
            <>
              <CompletePayment
                workspaceId={workspaceId}
                paymentInfo={paymentInfo}
              />
            </>
          ),
        },
      ]
    : [];

  useEffect(() => {
    setLoadingProductPlanInfo(true);
    resourceServices
      .list_product_plans({ productId: "trust_center_custom_domain" })
      .then((response) => {
        // We're assuming that there will only be 1 plan for this product.
        setProductPlanInfo(response[0]);
        setLoadingProductPlanInfo(false);
      })
      .catch(apiErrorHandler);
  }, []);

  useEffect(() => {
    if (workspaceId) {
      paymentService
        .retrieve_workspace_payment_settings(workspaceId)
        .then((response) => {
          setPaymentForm((prev) => ({
            ...prev,
            customer: {
              full_name: response.customer_full_name,
              phone_number: response.customer_phone_number,
              email: response.customer_email,
              job_title: response.customer_job_title,
            },
          }));
          // TODO: when triggering things, update them with this default value
          setDefaultPaymentSettings(response);
          const matchedJob = jobTitleList.find(
            (title) => title.value === response.customer_job_title
          );
          if (matchedJob) {
            setJobTitle(matchedJob);
          }
        })
        .catch(apiErrorHandler);
    }
  }, [workspaceId]);

  useEffect(() => {
    if (workspaceId) {
      paymentService
        .list_workspace_payment_plans(workspaceId, {
          status: "active,expiring",
        })
        .then((response) => {
          const findTcCustomDomain = response.find(
            (plan) => plan.product_plan.id === "tc_custom_domain"
          );
          if (findTcCustomDomain) {
            setOldPlanDuration(findTcCustomDomain.duration);
          }
        })
        .catch(apiErrorHandler);
    }
  }, [workspaceId]);

  return (
    <>
      <StickySection className="py-10 items-center max-w-[96rem]">
        <h1>{t("title")}</h1>
        <div className="flex gap-1">
          {registerProgress === 0 ? (
            <Button
              size="large"
              disabled={selectedPlan === null}
              onClick={onClickNextToFillForm}
            >
              {t("button.continue")}
              <ArrowRightLine className="h-5 w-5" />
            </Button>
          ) : null}
          {registerProgress === 1 ? (
            <>
              <Button
                size="large"
                variant="secondary"
                onClick={() => {
                  setRegisterProgress(0);
                  skipToContentTop();
                }}
              >
                <ArrowLeftLine className="h-5 w-5" />
                {t("button.back")}
              </Button>
              <Button
                size="large"
                disabled={
                  !paymentForm.customer.full_name ||
                  !paymentForm.customer.email ||
                  !paymentForm.customer.phone_number
                }
                onClick={onClickNextToCompletePayment}
              >
                {t("button.continue")}
                <ArrowRightLine className="h-5 w-5" />
              </Button>
            </>
          ) : null}
        </div>
      </StickySection>
      <ContentSection className="max-w-[96rem]">
        <TabProgress steps={registerSteps} progress={registerProgress} />
      </ContentSection>
    </>
  );
};

export default WorkspaceBillingUpgradeTcCustomDomain;
