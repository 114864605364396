import endpoint from "#src/config/endpoint";
import request from "#src/utils/request";
import type { IPaginatedResponse } from "#src/@types/api";
import type {
  ContractTypeValue,
  PaymentMethodValue,
} from "#src/config/filter/workspace/value";
import type { MultilingualText } from "#src/@types/common";

export interface IPricingInfo {
  total_price: number;
  discount: number;
  currency: string;
  immediate_payment: number;
  product_plans: {
    id: string;
    price: number;
    duration: string;
    currency: string;
    sub_total: number;
    discount: number;
    promo_code: null | string;
    next_billing_time: number;
    start_period: number;
    end_period: number;
    product: {
      id: string;
      name: MultilingualText;
      description: MultilingualText;
      category: {
        id: string;
        name: MultilingualText;
      };
    };
    promo_description: {
      en: null | string;
      vi: null | string;
    };
    error_promo: {
      promo_code: string[];
    } | null;
  }[];
  promo_description: {
    en: null | string;
    vi: null | string;
  };
  error_promo: null;
}

export interface IPricingInput {
  // promo_code: string;
  currency: string;
  product_plans: {
    product_plan_id: string;
    duration: string;
    promo_code?: string;
  }[];
  calc_action?: "upgrade";
}

function calculate_pricing(workspace_id: string, data: IPricingInput) {
  return request.post<never, IPricingInfo>(
    `${endpoint.WORKSPACES}/${workspace_id}/payments/calc`,
    data
  );
}

export interface IValidPromotionCode {
  error_promo: null;
  promo_description: MultilingualText;
  only_plan: string[];
  promo_code_type: string;
  value: number;
}

interface IInvalidPromotionCode {
  error_promo: { promo_code: string[] };
}

export type IPromotionCodeResponse =
  | IValidPromotionCode
  | IInvalidPromotionCode;

function check_promotion_code(
  workspace_id: string,
  data: { promo_code: string }
) {
  return request.post<never, IPromotionCodeResponse>(
    `${endpoint.WORKSPACES}/${workspace_id}/payments/promo_code/calc`,
    data
  );
}

export interface IPaymentForm {
  promo_code?: string;
  currency?: string;
  payment_method: PaymentMethodValue;
  is_sign_contract?: boolean;
  contract_type?: ContractTypeValue;
  vat_invoice?: boolean;
  vat_information?:
    | {
        name: string;
        tax_code: string;
        address: string;
        type: "enterprise";
      }
    | {
        name: string;
        identity_card: string;
        address: string;
        type: "personal";
      };
  customer: {
    full_name: string;
    phone_number: string;
    email: string;
    job_title?: string;
  };
  product_plans: {
    product_plan_id: string;
    duration: string;
    promo_code?: string;
  }[];
}

interface IPaymentInfo {
  payment_id: string;
  created_time: number;
  total_price: number;
  discount: number;
  status: string;
  description: string;
  transaction_type: string;
  payment_method: string;
  failure_reason: null;
  currency: string;
  bank_id: string;
  bank_qr_url: string;
}

function create_subscription_banking(workspace_id: string, data: IPaymentForm) {
  return request.post<never, IPaymentInfo>(
    `${endpoint.WORKSPACES}/${workspace_id}/payments/subscriptions`,
    data
  );
}

function retrieve_payment(workspace_id: string, payment_id: string) {
  return request.get<never, IPaymentInfo>(
    `${endpoint.WORKSPACES}/${workspace_id}/payments/${payment_id}`
  );
}

interface IWorkspacePaymentSettings {
  customer_full_name: string;
  customer_phone_number: string;
  customer_job_title: string;
  customer_email: string;
  contract_type: ContractTypeValue;
  vat_name: string | null;
  vat_tax_code: string | null;
  vat_identity_card: string | null;
  vat_address: string | null;
  vat_type: "personal" | "enterprise";
}

function retrieve_workspace_payment_settings(workspace_id: string) {
  return request.get<never, IWorkspacePaymentSettings>(
    `${endpoint.WORKSPACES}/${workspace_id}/payments/setting`
  );
}

function update_workspace_payment_settings(
  workspace_id: string,
  data: IWorkspacePaymentSettings
) {
  return request.put<never, IWorkspacePaymentSettings>(
    `${endpoint.WORKSPACES}/${workspace_id}/payments/setting`,
    data
  );
}

export interface IRecurringPrice {
  duration: string;
  vnd: number;
  usd: number;
  monthly_vnd: number;
  monthly_usd: number;
}

export interface IWorkspacePaymentPlanItem {
  start_period: null | number;
  end_period: null | number;
  duration: string;
  cancel_at_period_end: boolean;
  payment_method: PaymentMethodValue;
  status: "active" | "expiring" | "expired";
  product: {
    id: string;
    name: MultilingualText;
    description: MultilingualText;
    category: {
      id: string;
      name: MultilingualText;
    };
  };
  product_plan: {
    id: string;
    name: MultilingualText;
    recurring_price: IRecurringPrice | {};
  };
}

/** Return list of currently active services that will need to be paid. */
function list_workspace_payment_plans(
  workspace_id: string,
  params: { status?: string; payment_method?: string; q?: string }
) {
  return request.get<never, IWorkspacePaymentPlanItem[]>(
    `${endpoint.WORKSPACES}/${workspace_id}/payments/plans`,
    { params: { ...params, paging: 0 } }
  );
}

interface IPaymentOverview {
  pending_payments: number;
  pending_contracts: number;
  expiring_plans: number;
}

function retrieve_workspace_payment_overview(workspace_id: string) {
  return request.get<never, IPaymentOverview>(
    `${endpoint.WORKSPACES}/${workspace_id}/payments/overview`
  );
}

function renew_subscription_banking(workspace_id: string, data: IPaymentForm) {
  return request.post<never, IPaymentInfo>(
    `${endpoint.WORKSPACES}/${workspace_id}/payments/renewal`,
    data
  );
}

interface IPaymentItem {
  payment_id: string;
  created_time: string;
  paid_time: null | number;
  product: {
    id: string;
    name: MultilingualText;
    description: MultilingualText;
    category: {
      id: string;
      name: MultilingualText;
    };
  };
  product_plan: {
    id: string;
    name: MultilingualText;
  };
  duration: string;
  sub_total: number;
  discount: number;
  payment_method: string;
  status: string;
  currency: string;
}

function list_payments(
  workspace_id: string,
  params: { status?: string; q?: string }
) {
  return request.get<never, IPaginatedResponse<IPaymentItem>>(
    `${endpoint.WORKSPACES}/${workspace_id}/payments`,
    { params }
  );
}

interface IPaymentContract {
  id: string;
  payment_id: string;
  contract_type: string;
  signed_time: null | number;
  status: string;
  product: {
    id: string;
    name: MultilingualText;
    description: MultilingualText;
    category: {
      id: string;
      name: MultilingualText;
    };
  };
  product_plan: {
    id: string;
    name: MultilingualText;
  };
}

function list_contracts(
  workspace_id: string,
  params: { status?: string; q?: string }
) {
  return request.get<never, IPaginatedResponse<IPaymentContract>>(
    `${endpoint.WORKSPACES}/${workspace_id}/payments/contracts`,
    { params }
  );
}

function upgrade_subscription(workspace_id: string, data: IPaymentForm) {
  return request.post<never, IPaymentInfo>(
    `${endpoint.WORKSPACES}/${workspace_id}/payments/upgrade`,
    data
  );
}

const paymentService = {
  calculate_pricing,
  check_promotion_code,
  create_subscription_banking,
  retrieve_payment,
  retrieve_workspace_payment_settings,
  update_workspace_payment_settings,
  list_workspace_payment_plans,
  retrieve_workspace_payment_overview,
  renew_subscription_banking,
  list_payments,
  list_contracts,
  upgrade_subscription,
};

export default paymentService;
