import scansServices from "#src/services/scans";
import { useMemo } from "react";
// import ApplicationFilter from "./Filter";
import ApplicationTableGrid from "./Table";
import useFetchNoPaging from "#src/hooks/useFetchNoPaging";

interface IScanDetailsApplicationsProps {
  workspaceId: string;
  scanId: number;
  domainId: number;
}

const ScanDetailsApplications = ({
  workspaceId,
  scanId,
  domainId,
}: IScanDetailsApplicationsProps) => {
  const fetchParams = useMemo<
    Parameters<typeof scansServices.list_scan_applications>
  >(
    () => [workspaceId, scanId, { domain_id: domainId }],
    [workspaceId, scanId, domainId]
  );

  const { list: applicationList, isLoading } = useFetchNoPaging(
    scansServices.list_scan_applications,
    fetchParams
  );

  return (
    <section className="flex flex-col gap-6 pt-6">
      {/* <ApplicationFilter
        searchKeyword={searchKeyword}
        onChangeSearchKeyword={onChangeSearchKeyword}
      /> */}
      <ApplicationTableGrid
        applicationList={applicationList}
        isLoading={isLoading}
      />
      {/* <PaginationBar
    numOfResult={applicationCount}
    selectedPage={selectedPage}
    onChangePage={(value) => {
      setSelectedPage(value);
      fetchApplicationList(searchKeyword, value);
    }}
  /> */}
    </section>
  );
};

export default ScanDetailsApplications;
