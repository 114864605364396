// Libraries
import { Tabs } from "@lockerpm/design";
import { type ReactNode, useEffect, useState, useCallback } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

// Components
import TabButtonLabel from "#src/components/common/helper/antdProps/Tabs/TabButtonLabel";
import { ContentSection, StickySection } from "#src/layouts/content";
import LoadingState from "#src/components/common/system/LoadingState";
import { createToast } from "#src/components/common/system/toasts";
import { apiErrorHandler } from "#src/utils/apiErrorHandler";

// API-related
import assetsServices, { type IAssetDetailItem } from "#src/services/assets";

// Children
import AssetsDetailsOverview from "#src/components/assets/details/Tabs/OverviewTab";
import AssetsDetailsSubdomains from "#src/components/assets/details/Tabs/SubdomainsTab";
import AssetsDetailsPorts from "#src/components/assets/details/Tabs/PortsTab";
import AssetsDetailsVulnerabilities from "#src/components/assets/details/Tabs/VulnerabilitiesTab";
import AssetsDetailsCertificates from "#src/components/assets/details/Tabs/CertificatesTab";
import AssetDetailProfileSection from "#src/components/assets/details/ProfileSection";
import AssetDetailBasicInfoSection from "#src/components/assets/details/BasicInfoSection";
import EditSettingDrawer from "#src/components/assets/drawers/EditSettingDrawer";
import VerifyOwnershipDrawer from "#src/components/assets/drawers/VerifyOwnershipDrawer";
import AddScanDrawer from "#src/components/scans/all-scans/AddScanDrawer";
import { AssetDetailTabEnum } from "#src/components/assets/details/Tabs/enums";

const AssetsDomainsDetails = () => {
  const { t } = useTranslation("assets", {
    keyPrefix: "domains.page.details",
  });
  const navigate = useNavigate();

  const { workspace: workspaceId, asset: domainId } = useParams<
    "workspace" | "asset"
  >();

  const [domainDetails, setDomainDetails] = useState<IAssetDetailItem | null>(
    null
  );
  const [activeTab, setActiveTab] = useState<string>(
    AssetDetailTabEnum.OVERVIEW
  );

  // Drawer states
  const [openEditDrawer, setOpenEditDrawer] = useState<boolean>(false);
  const [openVerifyDrawer, setOpenVerifyDrawer] = useState<boolean>(false);
  const [openScanDrawer, setOpenScanDrawer] = useState<boolean>(false);

  // Loading state
  const [isLoading, setLoading] = useState<boolean>(false);

  const onChangeTab = (key: string) => {
    setActiveTab(key);
  };

  const fetchDomainDetails = useCallback(() => {
    if (workspaceId && domainId && !isNaN(+domainId)) {
      setLoading(true);
      assetsServices
        .retrieve_asset(workspaceId, +domainId)
        .then((response) => {
          setDomainDetails(response);
          setLoading(false);
        })
        .catch(apiErrorHandler);
    }
  }, [workspaceId, domainId]);

  const onDeleteThisDomain = () => {
    if (workspaceId && domainId && !isNaN(+domainId))
      assetsServices
        .delete_asset(+domainId, workspaceId)
        .then(() => {
          // WARNING: Might break in the case user went straight into this url.
          // TODO: check history before navigating?
          navigate(-1);
          createToast({
            type: "success",
            message: t("notification.deleteDomain.success"),
          });
        })
        .catch((error) => {
          apiErrorHandler(error, {
            toastMessage: t("notification.deleteDomain.fail"),
          });
        });
  };

  const tabList: { key: string; label: ReactNode; children: ReactNode }[] =
    domainDetails && workspaceId && domainId && !isNaN(+domainId)
      ? [
          {
            key: AssetDetailTabEnum.OVERVIEW,
            label: <TabButtonLabel name={t("tab.overview.title")} />,
            children: (
              <AssetsDetailsOverview
                assetDetails={domainDetails}
                onChangeTab={onChangeTab}
              />
            ),
          },
          {
            key: AssetDetailTabEnum.SUBDOMAINS,
            label: <TabButtonLabel name={t("tab.subdomains.title")} />,
            children: (
              <AssetsDetailsSubdomains
                workspaceId={workspaceId}
                domainId={+domainId}
                domainAddress={domainDetails.detail.address}
                refreshAssetDetails={fetchDomainDetails}
                refreshTabState={() => {
                  onChangeTab(AssetDetailTabEnum.OVERVIEW);
                }}
              />
            ),
          },
          {
            key: AssetDetailTabEnum.PORTS,
            label: <TabButtonLabel name={t("tab.ports.title")} />,
            children: (
              <AssetsDetailsPorts
                portDetailsInit={domainDetails.ports}
                workspaceId={workspaceId}
                assetId={+domainId}
              />
            ),
          },
          {
            key: AssetDetailTabEnum.VULNERABILITIES,
            label: (
              <TabButtonLabel
                name={t("tab.vulnerabilities.title")}
                count={domainDetails.vulnerabilities.count}
              />
            ),
            children: (
              <AssetsDetailsVulnerabilities
                workspaceId={workspaceId}
                assetId={+domainId}
                refreshAssetDetails={fetchDomainDetails}
              />
            ),
          },
          {
            key: AssetDetailTabEnum.CERTIFICATES,
            label: <TabButtonLabel name={t("tab.certificates.title")} />,
            children: (
              <AssetsDetailsCertificates
                workspaceId={workspaceId}
                assetId={domainDetails.id}
                certificateDetailsInit={domainDetails.certificates}
              />
            ),
          },
        ]
      : [];

  // fetch domain detail
  useEffect(() => {
    let ignore = false;

    if (!ignore) {
      fetchDomainDetails();
    }

    return () => {
      ignore = true;
    };
  }, [fetchDomainDetails]);

  return workspaceId ? (
    domainDetails === null ? (
      isLoading ? (
        <div className="pt-20">
          <LoadingState />
        </div>
      ) : (
        <></>
      )
    ) : (
      <>
        <StickySection>
          <AssetDetailProfileSection
            details={domainDetails}
            onOpenEditDrawer={() => {
              setOpenEditDrawer(true);
            }}
            onOpenVerifyDrawer={() => {
              setOpenVerifyDrawer(true);
            }}
            onOpenScanDrawer={() => {
              setOpenScanDrawer(true);
            }}
            onDeleteThis={onDeleteThisDomain}
          />
        </StickySection>
        <ContentSection>
          <AssetDetailBasicInfoSection assetDetails={domainDetails} />
          <Tabs
            items={tabList.filter((tab) => {
              if (
                tab.key === AssetDetailTabEnum.CERTIFICATES &&
                domainDetails.certificates === null
              )
                return false;
              if (
                tab.key === AssetDetailTabEnum.SUBDOMAINS &&
                domainDetails.is_subdomain
              )
                return false;
              return true;
            })}
            activeKey={activeTab}
            onChange={onChangeTab}
            destroyInactiveTabPane
          />
          <EditSettingDrawer
            selectedItemId={domainDetails ? domainDetails.id : null}
            open={openEditDrawer}
            onClose={() => {
              setOpenEditDrawer(false);
            }}
            workspaceId={workspaceId}
            onRefresh={fetchDomainDetails}
          />
          <VerifyOwnershipDrawer
            open={openVerifyDrawer}
            onClose={() => {
              setOpenVerifyDrawer(false);
            }}
            workspaceId={workspaceId}
            onRefresh={() => {}}
            assetName={domainDetails ? domainDetails.name : null}
            assetId={domainDetails ? domainDetails.id : null}
          />
          <AddScanDrawer
            open={openScanDrawer}
            workspaceId={workspaceId}
            onClose={() => {
              setOpenScanDrawer(false);
            }}
            onRefresh={fetchDomainDetails}
            assetInit={
              domainDetails
                ? {
                    key: domainDetails.id.toString(),
                    id: domainDetails.id.toString(),
                    value: domainDetails.id.toString(),
                    address: domainDetails.detail.address,
                    label: domainDetails.detail.address,
                  }
                : undefined
            }
          />
        </ContentSection>
      </>
    )
  ) : null;
};

export default AssetsDomainsDetails;
