import { marked } from "marked";

const defaultRenderer = new marked.Renderer();

// On markdown, links will be rendered as normal texts
defaultRenderer.link = (href, title, text) => {
  return `<a href="${href}">${text}</a>`;
};

// Add sizes to fit into markdown's container
defaultRenderer.image = (href, title, text) => {
  return `<img src="${href}" alt="${text}" width="100%" height="100%"/>`;
};

marked.setOptions({ renderer: defaultRenderer, breaks: true });
