import { Dropdown } from "@lockerpm/design";
import { ReactComponent as ArrowDownSLine } from "#src/assets/images/icons/arrow-down-s-line.svg";
import { getTwColorByStatus } from "#src/components/common/variants/byStatus";
import type { IFilterItem } from "#src/@types/common";
import { vulnerabilityStatusItems } from "#src/config/filter/vulnerability";

interface ISubStatusSelectProps {
  subStatusValue: string;
  updateData: (subStatus: string | undefined) => void;
}

export const SubStatusSelect = ({
  subStatusValue,
  updateData,
}: ISubStatusSelectProps) => {
  const subStatusItem = vulnerabilityStatusItems
    .map((item) => item.children)
    .flat()
    .find((item) => item.value === subStatusValue);

  return (
    <Dropdown
      menu={{
        items: vulnerabilityStatusItems.map((item) => ({
          key: item.key,
          value: item.value,
          type: item.type,
          label: (
            <div className="px-3 py-2 bg-bright-grey text-medium-grey font-medium-14-forced w-full">
              {item.getLabel()}
            </div>
          ),
          children: item.children.map((childItem) => ({
            key: childItem.key,
            value: childItem.value,
            label: (
              <div
                className={`flex items-center gap-2 p-3 w-full font-medium-16 ${
                  subStatusValue === childItem.value
                    ? "text-hard-grey"
                    : "text-medium-grey"
                }`}
              >
                <div
                  className={`${
                    childItem.value ? getTwColorByStatus(childItem.value) : ""
                  } h-2 w-2 rounded-full`}
                />
                {childItem.getLabel()}
              </div>
            ),
          })),
        })),
        onClick: ({ key, domEvent }) => {
          domEvent.preventDefault();
          const newSelected = vulnerabilityStatusItems
            .map((item) => item.children)
            .flat()
            .find((item) => item.key === key);
          if (newSelected) updateData(newSelected.value);
        },
      }}
      placement="bottomRight"
      arrow
      trigger={["click"]}
    >
      {SubStatusSelectLabel({ subStatusItem })}
    </Dropdown>
  );
};

const SubStatusSelectLabel = ({
  subStatusItem,
}: {
  subStatusItem: IFilterItem | undefined;
}) => {
  return (
    <div
      className="flex gap-3 bg-bright-grey p-3 w-full items-center justify-between rounded-md cursor-pointer focus:outline focus:outline-2"
      onClick={(e) => {
        e.preventDefault();
      }}
    >
      <div className="flex gap-3 items-center overflow-hidden whitespace-nowrap text-ellipsis">
        <div
          className={`w-2.5 h-2.5 min-w-[0.625rem] rounded-full ${
            subStatusItem && subStatusItem.value
              ? getTwColorByStatus(subStatusItem.value)
              : ""
          }`}
        />
        <span className="font-medium-16 overflow-hidden whitespace-nowrap text-ellipsis">
          {subStatusItem?.getLabel()}
        </span>
      </div>
      <ArrowDownSLine className="fill-hard-grey" />
    </div>
  );
};
