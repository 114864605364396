import dayjs from "dayjs";
import i18next from "i18next";
import { ScanFrequencyResponseValue } from "#src/config/filter/scan/value";

export const getScheduleSummary = (
  schedule: boolean,
  frequency: string,
  repeat: number,
  startTime: number | null,
  daysOfWeek?: number[]
) => {
  const fixedTFunc = i18next.getFixedT(
    null,
    "scans",
    "page.allScans.scheduleSummary"
  );

  if (!schedule) return fixedTFunc("onDemand");

  if (frequency === ScanFrequencyResponseValue.ONCE) {
    if (!startTime) {
      return fixedTFunc("once");
    }
    return fixedTFunc("onceAt", {
      dateTime: dayjs.unix(startTime).format("HH:mm, dddd, MMMM D, YYYY"),
    });
  }

  if (frequency === ScanFrequencyResponseValue.DAILY) {
    if (!startTime) {
      return fixedTFunc("everyday", { count: repeat });
    }

    return fixedTFunc("everydayAt", {
      count: repeat,
      time: dayjs.unix(startTime).format("HH:mm:ss"),
    });
  }

  if (frequency === ScanFrequencyResponseValue.WEEKLY) {
    const daysOfWeekString = daysOfWeek
      ? daysOfWeek.reduce((prev, curr, index) => {
          if (prev !== "") {
            if (index === daysOfWeek.length - 1) {
              return prev + " and " + dayjs().day(curr).format("dddd");
            }
            return prev + ", " + dayjs().day(curr).format("dddd");
          }
          return dayjs().day(curr).format("dddd");
        }, "")
      : "";

    if (!startTime) {
      return fixedTFunc("everyWeekAt", {
        count: repeat,
        dateTime: daysOfWeekString ? daysOfWeekString : "",
      });
    }

    return fixedTFunc("everyWeekAt", {
      count: repeat,
      dateTime: daysOfWeekString
        ? daysOfWeekString + ", " + dayjs.unix(startTime).format("HH:mm:ss")
        : dayjs.unix(startTime).format("dddd, HH:mm:ss"),
    });
  }

  if (frequency === ScanFrequencyResponseValue.MONTHLY) {
    if (!startTime) {
      return fixedTFunc("everyMonth", { count: repeat });
    }

    return fixedTFunc("everyMonthAt", {
      count: repeat,
      dateTime: dayjs.unix(startTime).format("D, HH:mm:ss"),
    });
  }

  return "";
};
