interface ICvssSelectionButtonProps {
  label: string;
  selected: boolean;
  onClick: () => void;
}

const CvssSelectionButton = ({
  label,
  selected,
  onClick,
}: ICvssSelectionButtonProps) => {
  return (
    <button
      className={`${
        selected ? "bg-dark-blue text-white" : "bg-light-grey text-hard-grey"
      } first:rounded-l-md last:rounded-r-md first:border-l-0 border-l border-grey py-2 text-center font-medium-14`}
      onClick={onClick}
    >
      {label}
    </button>
  );
};

export default CvssSelectionButton;
