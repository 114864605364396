import type { RouteObject } from "react-router-dom";
import i18next from "i18next";
import { helperPathname, pathname } from "#src/config/pathname";

import WorkspaceDetailsPage from "#src/pages/workspace/details";
import WorkspaceMembersPage from "#src/pages/workspace/members";
import WorkspaceActivityLogsPage from "#src/pages/workspace/activityLogs";
import WorkspaceBillingPage from "#src/pages/workspace/billing";
import WorkspaceBillingRenewSubscriptionPage from "#src/pages/workspace/billing/renew";
import WorkspaceBillingUpgradeTrustCenter from "#src/pages/workspace/billing/upgrade/trustCenter";
import WorkspaceBillingUpgradeTcCustomDomain from "#src/pages/workspace/billing/upgrade/trustCenter/customDomain";

const breadcrumbTFunc = i18next.getFixedT(null, "general", "breadcrumb");

export const workspaceRoute: RouteObject = {
  path: helperPathname.WORKSPACE,
  children: [
    {
      path: pathname.WORKSPACE_DETAILS,
      handle: {
        crumb: () => ({
          getText: () => breadcrumbTFunc("workspace.detail.label"),
          path: pathname.WORKSPACE_DETAILS,
        }),
      },
      element: <WorkspaceDetailsPage />,
    },
    {
      path: pathname.WORKSPACE_MEMBERS,
      handle: {
        crumb: () => ({
          getText: () => breadcrumbTFunc("workspace.member.label"),
          path: pathname.WORKSPACE_MEMBERS,
        }),
      },
      element: <WorkspaceMembersPage />,
    },
    {
      path: pathname.WORKSPACE_ACTIVITY_LOGS,
      element: <WorkspaceActivityLogsPage />,
    },
    {
      path: pathname.WORKSPACE_BILLING,
      handle: {
        crumb: () => ({
          getText: () => breadcrumbTFunc("workspace.billing.label"),
          path: pathname.WORKSPACE_BILLING,
        }),
      },
      children: [
        {
          index: true,
          element: <WorkspaceBillingPage />,
        },
        {
          path: pathname.WORKSPACE_BILLING_RENEW,
          handle: {
            crumb: () => ({
              getText: () => breadcrumbTFunc("workspace.billing.extend.label"),
              path: pathname.WORKSPACE_BILLING_RENEW,
            }),
          },
          element: <WorkspaceBillingRenewSubscriptionPage />,
        },
        {
          path: pathname.WORKSPACE_BILLING_UPGRADE_TRUST_CENTER,
          element: <WorkspaceBillingUpgradeTrustCenter />,
        },
        {
          path: pathname.WORKSPACE_BILLING_UPGRADE_TC_CUSTOM_DOMAIN,
          element: <WorkspaceBillingUpgradeTcCustomDomain />,
        },
      ],
    },
  ],
};
