// Libraries
import { useMemo, useState } from "react";

// General
import constants from "#src/config/constants";
import type { IFilterItem } from "#src/@types/common";

// Components
import PaginationBar from "#src/components/common/helper/PaginationBar";
import useFetchPaginated from "#src/hooks/useFetchPaginated";

// API-related
import msspBugBountyServices from "#src/services/mssp/bugBounty";

// Children
import BugBountyFilter from "./Filter";
import BugBountyTableGrid from "./Table";

interface IMsspBugBountyTableProps {
  workspaceId: string;
}

const MsspBugBountyTable = ({ workspaceId }: IMsspBugBountyTableProps) => {
  const [selectedPage, setSelectedPage] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(constants.DEFAULT_PAGE_SIZE);

  const [programStatus, setProgramStatus] = useState<IFilterItem | null>(null);
  const [programType, setProgramType] = useState<IFilterItem | null>(null);
  const [searchKeyword, setSearchKeyword] = useState<string>("");
  const [from, setFrom] = useState<number | null>(null);
  const [to, setTo] = useState<number | null>(null);

  const fetchParams = useMemo<
    Parameters<typeof msspBugBountyServices.list_mssp_programs>
  >(
    () => [
      workspaceId,
      {
        from: from ? from : undefined,
        to: to ? to : undefined,
        status: programStatus ? programStatus.value : undefined,
        type: programType ? programType.value : undefined,
        q: searchKeyword ? searchKeyword : undefined,
        page: selectedPage,
        size: pageSize,
      },
    ],
    [
      workspaceId,
      from,
      to,
      programStatus,
      programType,
      searchKeyword,
      selectedPage,
      pageSize,
    ]
  );

  const onChangeTimeConditionValue = (
    condition: string,
    from: number,
    to: number
  ) => {
    if (condition) {
      setFrom(from);
      setTo(to);
    } else {
      setFrom(null);
      setTo(null);
    }
  };

  const onChangeProgramStatusSelection = (selected: typeof programStatus) => {
    setProgramStatus(selected);
  };

  const onChangeProgramTypeSelection = (selected: typeof programType) => {
    setProgramType(selected);
  };

  const onChangeSearchKeyword = (keyword: string) => {
    setSearchKeyword(keyword);
  };

  const onChangePage = (selectedPage: number) => {
    setSelectedPage(selectedPage);
  };

  const onChangePageSize = (size: number) => {
    setPageSize(size);
    setSelectedPage(1);
  };

  const {
    list: bugBountyList,
    count: bugBountyCount,
    isLoading,
    updateData: updateBugBountyList,
  } = useFetchPaginated(msspBugBountyServices.list_mssp_programs, fetchParams);

  return (
    <>
      <BugBountyFilter
        programStatus={programStatus}
        programType={programType}
        searchKeyword={searchKeyword}
        onChangeTimeConditionValue={onChangeTimeConditionValue}
        onChangeProgramStatusSelection={onChangeProgramStatusSelection}
        onChangeProgramTypeSelection={onChangeProgramTypeSelection}
        onChangeSearchKeyword={onChangeSearchKeyword}
      />
      <BugBountyTableGrid
        workspaceId={workspaceId}
        bugBountyList={bugBountyList}
        isLoading={isLoading}
        refreshBugBountyList={updateBugBountyList}
      />
      <PaginationBar
        numOfResult={bugBountyCount}
        selectedPage={selectedPage}
        pageSize={pageSize}
        onChangePage={onChangePage}
        onChangePageSize={onChangePageSize}
      />
    </>
  );
};

export default MsspBugBountyTable;
