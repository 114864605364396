// Libraries
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import { useTranslation } from "react-i18next";

// Components
import {
  TableDataCell,
  TableHeader,
  TableRowWrapper,
} from "#src/components/common/Table";
import type { IScanSubdomainItem } from "#src/services/scans/subdomains";
import type { ITableHeaderPropItem } from "#src/components/common/Table/TableHeader";
import LoadingState from "#src/components/common/system/LoadingState";
import NoResultsState from "#src/components/common/states/NoResultsState";

dayjs.extend(relativeTime);

/* @param data - data of the row, represent an Subdomain from selected domain */
const SubdomainTableRow = ({ data }: { data: IScanSubdomainItem }) => {
  return (
    <TableRowWrapper>
      <TableDataCell>{data.address}</TableDataCell>
      <TableDataCell>{data.ips.join(", ")}</TableDataCell>
      <TableDataCell>
        {/* {dayjs.unix(data.last_found).format("HH:mm DD.MM.YYYY")} */}
        {dayjs.unix(data.last_found).fromNow()}
      </TableDataCell>
    </TableRowWrapper>
  );
};

interface ISubdomainTableProps {
  subdomainList: IScanSubdomainItem[] | null;
  isLoading: boolean;
}

const SubdomainTableGrid = ({
  subdomainList,
  isLoading,
}: ISubdomainTableProps) => {
  const { t } = useTranslation("scans", {
    keyPrefix: "page.scanDetails.tab.subdomains",
  });

  const headers: ITableHeaderPropItem[] = [
    { name: t("headers.address"), align: "left" },
    { name: t("headers.ipAddress"), align: "left" },
    { name: t("headers.lastFound"), align: "left" },
  ];

  return subdomainList === null || isLoading ? (
    <LoadingState />
  ) : subdomainList.length === 0 ? (
    <NoResultsState />
  ) : (
    <div className="grid grid-cols-3">
      <TableHeader title="scannedDomains-subdomains" headerProps={headers} />
      {subdomainList.map((item) => (
        <SubdomainTableRow
          key={`tscannedDomains-subdomains-tr${item.address}`}
          data={item}
        />
      ))}
    </div>
  );
};

export default SubdomainTableGrid;
