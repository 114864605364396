import endpoint from "#src/config/endpoint";
import request from "#src/utils/request";

export interface IAssetPortItem {
  last_found: number;
  port: number;
  protocol: string;
  service: string;
  product: string;
  version: string;
  cpe: string;
}

export function list_asset_ports(
  workspace_id: string,
  domain_id: number,
  params: {
    from: number | undefined;
    to: number | undefined;
    protocol: string | undefined;
    q: string | undefined;
    page: number | undefined;
    size: number | undefined;
  }
) {
  return request.get<never, IAssetPortItem[]>(
    `${endpoint.WORKSPACES}/${workspace_id}/assets/${domain_id}/open_ports`,
    { params }
  );
}
