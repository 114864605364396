const BACKEND_URL = process.env.REACT_APP_API_URL;

export default {
  BACKEND_URL,

  BASE: `${BACKEND_URL}`,
  WORKSPACES: `${BACKEND_URL}/workspaces`,
  RESOURCES: `${BACKEND_URL}/resources/cystack_platform/wh`,
  ATTACHMENTS: `${BACKEND_URL}/attachments`,
  WEBSOCKET: `${BACKEND_URL?.replace("https://", "wss://")}/ws`,
};
