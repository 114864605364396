// Libraries
import { Drawer } from "@lockerpm/design";
import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

// Resources
import { ReactComponent as CloseLine } from "#src/assets/images/icons/close-line.svg";

// General
import constants from "#src/config/constants";
import type { IDrawerAdapterProps, IFilterItem } from "#src/@types/common";

// Components
import { Button, IconButton } from "#src/components/common/system/Button";
import { apiErrorHandler } from "#src/utils/apiErrorHandler";

// API-related
import dataLeakServices, { type IKeywordItem } from "#src/services/dataLeak";

// Children
import { KeywordLabel } from "../KeywordBar";

const FooterButtons = ({
  onOpenEditDrawer,
  onCancel,
}: {
  onOpenEditDrawer: () => void;
  onCancel: () => void;
}) => {
  const { t } = useTranslation("dataLeakDetection", {
    keyPrefix: "drawer.viewKeywords",
  });

  return (
    <div className="flex flex-col gap-3">
      <Button className="w-full" size="large" onClick={onOpenEditDrawer}>
        {t("button.edit")}
      </Button>
      <Button
        variant="secondary"
        className="w-full"
        size="large"
        onClick={onCancel}
      >
        {t("button.cancel")}
      </Button>
    </div>
  );
};

interface IViewKeywordsDrawerProps extends IDrawerAdapterProps {
  onOpenEditDrawer: () => void;
  onChangeSelectedKeyword: (selected: IFilterItem | null) => void;
}

const ViewKeywordsDrawer = ({
  open,
  onClose,
  workspaceId,
  onOpenEditDrawer,
  onChangeSelectedKeyword,
}: IViewKeywordsDrawerProps) => {
  const { t } = useTranslation("dataLeakDetection", {
    keyPrefix: "drawer.viewKeywords",
  });

  const [keywordList, setKeywordList] = useState<IKeywordItem[]>([]);
  const [keywordCount, setKeywordCount] = useState<number | null>(null);

  const [loadingMoreKeywords, setLoadingMoreKeywords] =
    useState<boolean>(false);
  const [loadedKeywordPages, setLoadedKeywordPages] = useState<number>(0);

  const fetchDataLeakKeywordList = useCallback(
    (keyword?: string) => {
      dataLeakServices
        .list_keywords(workspaceId, {
          q: keyword ? keyword : undefined,
          page: undefined,
          size: constants.DEFAULT_PAGE_SIZE,
        })
        .then((response) => {
          setKeywordList(response.results);
          setKeywordCount(response.count);
          setLoadedKeywordPages(1);
        })
        .catch((error) => {
          apiErrorHandler(error, {
            toastMessage: t("notification.loadKeyword.fail"),
          });
        });
    },
    [workspaceId, t]
  );

  const fetchMoreKeywords = () => {
    dataLeakServices
      .list_keywords(workspaceId, {
        q: undefined,
        page: loadedKeywordPages + 1,
        size: constants.DEFAULT_PAGE_SIZE,
      })
      .then((response) => {
        setKeywordList((prev) => [...prev, ...response.results]);
        setLoadedKeywordPages((prev) => prev + 1);
        setLoadingMoreKeywords(false);
      })
      .catch((error) => {
        apiErrorHandler(error, {
          toastMessage: t("notification.loadKeyword.fail"),
        });
      });
  };

  const onCloseDrawerAndCleanup = () => {
    onClose();
  };

  const onCancel = () => {
    onCloseDrawerAndCleanup();
  };

  useEffect(() => {
    let ignore = false;

    if (!ignore) {
      fetchDataLeakKeywordList();
    }

    return () => {
      ignore = true;
    };
  }, [fetchDataLeakKeywordList]);

  return (
    <Drawer
      open={open}
      onClose={onCancel}
      closable={false}
      title={<h2>{t("title")}</h2>}
      extra={
        <IconButton variant="label-grey" size={9} onClick={onCancel}>
          <CloseLine />
        </IconButton>
      }
      footer={
        <FooterButtons
          onOpenEditDrawer={() => {
            onOpenEditDrawer();
            onCloseDrawerAndCleanup();
          }}
          onCancel={onCancel}
        />
      }
      destroyOnClose
    >
      <div className="flex flex-col gap-6 p-6 h-full">
        <div
          className="flex flex-col gap-6 overflow-y-auto"
          style={{ scrollbarWidth: "none" }}
          onScroll={(e) => {
            if (
              e.currentTarget.clientHeight + e.currentTarget.scrollTop >
                e.currentTarget.scrollHeight - 10 &&
              !loadingMoreKeywords &&
              keywordCount &&
              keywordCount > loadedKeywordPages * constants.DEFAULT_PAGE_SIZE
            ) {
              setLoadingMoreKeywords(true);
              fetchMoreKeywords();
            }
          }}
        >
          {keywordList.map((item, index) => (
            <KeywordLabel
              key={`view-keyword_${item.id}_${index}`}
              value={item.keyword}
              onClick={() => {
                onChangeSelectedKeyword({
                  key: item.id.toString(),
                  value: item.id.toString(),
                  getLabel: () => item.keyword,
                });
                onCloseDrawerAndCleanup();
              }}
            />
          ))}
        </div>
      </div>
    </Drawer>
  );
};

export default ViewKeywordsDrawer;
