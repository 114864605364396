import { ReactComponent as ListOrderedIcon } from "#src/assets/images/icons/list-ordered.svg";
import EditorButton from "../EditorButton";
import { COMMENT_EDITOR_TEXTAREA_ID } from "../constants";

const OrderedListButton = ({ disabled }: { disabled?: boolean }) => {
  return (
    <EditorButton
      onClick={() => {
        const editor = document.getElementById(
          COMMENT_EDITOR_TEXTAREA_ID
        ) as HTMLTextAreaElement;
        const splittedValue = editor.value.split("\n");
        const startLine =
          editor.value.substring(0, editor.selectionStart).split("\n").length -
          1;
        const endLine =
          editor.value.substring(0, editor.selectionEnd - 1).split("\n")
            .length - 1;
        const selectionStart =
          startLine > 0
            ? splittedValue
                .slice(0, startLine)
                .reduce((prev, cur) => prev + cur.length + 1, 0)
            : 0;
        const selectionEnd = splittedValue
          .slice(0, endLine + 1)
          .reduce((prev, cur) => prev + cur.length + 1, 0);
        const selectionText = editor.value.substring(
          selectionStart,
          selectionEnd
        );

        let newSelectionText = selectionText
          .split("\n")
          .filter((item) => !!item)
          .map((item, index) => index + 1 + ". " + item)
          .join("\n");

        if (newSelectionText === "") {
          editor.setRangeText("1. ", selectionStart, selectionEnd);
          editor.setSelectionRange(
            selectionStart + "1. ".length,
            selectionStart + "1. ".length
          );
        } else {
          while (
            !(
              (newSelectionText[0] === "\n" && newSelectionText[1] === "\n") ||
              selectionStart <= 0 ||
              (newSelectionText[0] === "\n" &&
                editor.value[selectionStart - 1] === "\n") ||
              (editor.value[selectionStart - 1] === "\n" &&
                editor.value[selectionStart - 2] === "\n")
            )
          ) {
            newSelectionText = "\n" + newSelectionText;
          }

          while (
            !(
              (newSelectionText[newSelectionText.length - 1] === "\n" &&
                newSelectionText[newSelectionText.length - 2] === "\n") ||
              selectionEnd >= editor.value.length ||
              (newSelectionText[newSelectionText.length - 1] === "\n" &&
                editor.value[selectionEnd] === "\n")
            )
          ) {
            newSelectionText = newSelectionText + "\n";
          }

          editor.setRangeText(newSelectionText, selectionStart, selectionEnd);
          editor.setSelectionRange(
            selectionStart,
            selectionStart + newSelectionText.length
          );
        }
      }}
      disabled={disabled}
    >
      <ListOrderedIcon />
    </EditorButton>
  );
};

export default OrderedListButton;
