// Libraries
import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

// Resources
import { ReactComponent as AddLine } from "#src/assets/images/icons/add-line.svg";
import { ReactComponent as EditLine } from "#src/assets/images/icons/edit-line.svg";

// Components
import useFetchNoPaging from "#src/hooks/useFetchNoPaging";
import LoadingState from "#src/components/common/system/LoadingState";
import NoResultsState from "#src/components/common/states/NoResultsState";
import { Button } from "#src/components/common/system/Button";
import useFetchPaginated from "#src/hooks/useFetchPaginated";
import { ObjectImage } from "#src/components/common/system/Object";

// API-related
import resourceServices, {
  type IIntegrationItem,
} from "#src/services/resource";
import bugBountyServices from "#src/services/bugBounty";

// Children
import BugBountyAddIntegrationDrawer from "./AddIntegrationDrawer";

interface IBugBountyDetailsIntegrationCardProps {
  integration: IIntegrationItem;
  status: string;
  onClickOpenAddDrawer: () => void;
}

const BugBountyDetailsIntegrationCard = ({
  integration,
  status,
  onClickOpenAddDrawer,
}: IBugBountyDetailsIntegrationCardProps) => {
  const { t } = useTranslation("bugBounty", {
    keyPrefix: "page.bugBountyDetails.tab.integrations",
  });

  return (
    <div className="rounded-md border border-light-grey bg-bright-grey">
      <div className="h-[6.75rem] relative">
        <div className="h-[4.5rem] border-b border-light-grey"></div>
        <div className="absolute bottom-0 left-0 right-0 mx-auto h-[4.5rem] w-[4.5rem] border border-light-grey bg-white p-1 rounded-md flex items-center justify-center">
          <ObjectImage data={integration.logo} className="h-10 w-10" />
        </div>
      </div>
      <div className="p-8 pt-3 flex flex-col items-center gap-6">
        <span className="font-medium-20">{integration.name}</span>
        <div
          className={`px-3 py-2 rounded-md flex items-center gap-1 font-medium-14 border bg-white ${
            status === "CONNECTED"
              ? "text-support-green border-support-green"
              : status === "DISABLED"
              ? "text-hard-grey border-hard-grey"
              : "text-red border-red"
          }`}
        >
          <div
            className={`rounded-full h-2 w-2 rotate-45 ${
              status === "CONNECTED"
                ? "bg-support-green"
                : status === "DISABLED"
                ? "bg-hard-grey"
                : "bg-red"
            }`}
          />
          {status === "CONNECTED"
            ? t("connectStatus.connected")
            : status === "DISABLED"
            ? t("connectStatus.disabled")
            : t("connectStatus.notConnected")}
        </div>
        <span className="text-center">{integration.description.en}</span>
        {status === "CONNECTED" || status === "DISABLED" ? (
          <Link to={`integration/${integration.alias}`}>
            <Button variant="text">
              <EditLine height={"1rem"} width={"1rem"} />
              {t("button.editIntegration")}
            </Button>
          </Link>
        ) : (
          <Button variant="text" onClick={onClickOpenAddDrawer}>
            <AddLine height={"1rem"} width={"1rem"} />
            {t("button.addIntegration")}
          </Button>
        )}
      </div>
    </div>
  );
};

interface IBugBountyDetailsIntegrationProps {
  workspaceId: string;
  bugBountyAlias: string;
}

const BugBountyDetailsIntegration = ({
  workspaceId,
  bugBountyAlias,
}: IBugBountyDetailsIntegrationProps) => {
  const { t } = useTranslation("bugBounty", {
    keyPrefix: "page.bugBountyDetails.tab.integrations",
  });

  const [openAddDrawer, setOpenAddDrawer] = useState<boolean>(false);
  const [selectedIntegration, setSelectedIntegration] =
    useState<IIntegrationItem | null>(null);

  const onClickOpenAddDrawer = (integration: IIntegrationItem) => {
    setSelectedIntegration(integration);
    setOpenAddDrawer(true);
  };

  const fetchParams = useMemo(
    () => [workspaceId, bugBountyAlias],
    [workspaceId, bugBountyAlias]
  );

  const { list: integrationList, isLoading: isLoadingResource } =
    useFetchNoPaging(resourceServices.list_integrations, fetchParams);

  const { list: programIntegrationList, isLoading: isLoadingProgram } =
    useFetchPaginated(bugBountyServices.list_program_integrations, fetchParams);

  return (
    <section className="flex flex-col gap-6 pt-6">
      <div className="flex flex-col gap-4">
        <h2>{t("title")}</h2>
        <span className="text-hard-grey">{t("instruction")}</span>
      </div>
      <div className="grid grid-cols-3 xl:grid-cols-4 2xl:grid-cols-5 gap-6">
        {integrationList === null ||
        isLoadingResource ||
        programIntegrationList === null ||
        isLoadingProgram ? (
          <LoadingState />
        ) : integrationList.length === 0 ? (
          <NoResultsState />
        ) : (
          integrationList.map((integration) => {
            const programIntegration = programIntegrationList.find(
              (item) => item.integration.alias === integration.alias
            );
            const status =
              programIntegration !== undefined
                ? programIntegration.turn_on
                  ? "CONNECTED"
                  : "DISABLED"
                : "NOT_CONNECTED";
            return (
              <BugBountyDetailsIntegrationCard
                key={`bugbounty-integration-${integration.alias}`}
                integration={integration}
                status={status}
                onClickOpenAddDrawer={() => {
                  onClickOpenAddDrawer(integration);
                }}
              />
            );
          })
        )}
      </div>
      <BugBountyAddIntegrationDrawer
        workspaceId={workspaceId}
        bugBountyAlias={bugBountyAlias}
        integration={selectedIntegration}
        open={openAddDrawer}
        onClose={() => setOpenAddDrawer(false)}
        onRefresh={() => {}}
      />
    </section>
  );
};

export default BugBountyDetailsIntegration;
