import type { IPaginateParams, IPaginatedResponse } from "#src/@types/api";
import endpoint from "#src/config/endpoint";
import request from "#src/utils/request";
import {
  create_pentest_project_member,
  delete_pentest_project_member,
  list_pentest_project_members,
  list_pentest_project_mssp_members,
  update_pentest_project_member,
} from "./members";
import { retrieve_pentest_mssp, update_pentest_mssp } from "./mssp";

export interface IPentestProject {
  alias: string;
  status: string;
  in_review: boolean;
  type: string;
  created_time: number;
  updated_time: number | null;
  org: {
    alias: string;
    name: string;
    email: string;
    address: string;
    phone: string;
    color: string;
    description: {
      en: string;
      vi: string;
    };
    logo: string | null;
    social: any;
  };
  mssp: {
    id: string;
    name: string;
  } | null;
  vulnerabilities: number;
}

export interface IPentestProjectDetails extends IPentestProject {
  requested_time: number | null;
  metadata: { vi: string; en: string };
  targets: {
    id: number;
    name: string;
    type: string;
    scope: string;
    link_download: string;
  }[];
}

function list_pentest_projects(
  workspace_id: string,
  params: {
    from: number | undefined;
    to: number | undefined;
    status: string | undefined;
  } & IPaginateParams
) {
  return request.get<never, IPaginatedResponse<IPentestProject>>(
    `${endpoint.WORKSPACES}/${workspace_id}/pentest_projects`,
    { params }
  );
}

function list_pentest_projects_shortly(
  workspace_id: string,
  params: {
    from: number | undefined;
    to: number | undefined;
    status: string | undefined;
    q: string | undefined;
  }
) {
  return request.get<never, IPentestProject[]>(
    `${endpoint.WORKSPACES}/${workspace_id}/pentest_projects`,
    { params: { ...params, paging: 0 } }
  );
}

export interface IPentestProjectOverview {
  alias: string;
  created_time: number;
  updated_time: number | null;
  last_update: number | null;
  report_statistics: {
    status: {
      open: number;
      accepted: number;
      rejected: number;
    };
    open_sub_status: {
      new: number;
      triaged: number;
    };
    accepted_sub_status: {
      resolved: number;
      duplicate: number;
      unresolved: number;
    };
    rejected_sub_status: {
      out_of_scope: number;
      not_reproducible: number;
      wont_fix: number;
      not_applicable: number;
      spam: number;
    };
    severity: {
      CRITICAL: number;
      HIGH: number;
      MEDIUM: number;
      LOW: number;
      INFORMATION: number;
      NONE: number;
    };
    category: any[];
    targets: any[];
    time: { [T: string]: { vuln_count: number } };
  };
}

function get_pentest_project_overview(
  workspace_id: string,
  pentest_alias: string
) {
  return request.get<never, IPentestProjectOverview>(
    `${endpoint.WORKSPACES}/${workspace_id}/pentest_projects/${pentest_alias}/overview`
  );
}

function get_pentest_project_details(
  workspace_id: string,
  pentest_alias: string
) {
  return request.get<never, IPentestProjectDetails>(
    `${endpoint.WORKSPACES}/${workspace_id}/pentest_projects/${pentest_alias}`
  );
}

interface IPentestHistoryItem {
  id: number;
  created_time: number;
  description: {
    en: string;
    vi: string;
  };
  editor: string;
}

function list_pentest_project_history(
  workspace_id: string,
  pentest_alias: string,
  // This API doesn't accept page "size", which is annoying but ok
  params: { page: number | undefined }
) {
  return request.get<never, IPaginatedResponse<IPentestHistoryItem>>(
    `${endpoint.WORKSPACES}/${workspace_id}/pentest_projects/${pentest_alias}/history`,
    { params }
  );
}

interface IPentestProjectPostData {
  alias?: string;
  status?: string;
  name: string;
  description?: {
    en: string;
    vi: string;
  };
  email?: string;
  phone?: string;
  address?: string;
  logo?: string;
  color?: string;
  targets?: {
    name: string;
    type: string;
    scope: string;
    link_download: string;
  }[];
  metadata: { en: string; vi: string };
  mssp_id: string;
}

function create_pentest_project(
  workspace_id: string,
  data: IPentestProjectPostData
) {
  return request.post<never, { id: number; alias: string }>(
    `${endpoint.WORKSPACES}/${workspace_id}/pentest_projects`,
    data
  );
}

function update_pentest_project(
  workspace_id: string,
  pentest_alias: string,
  data: Partial<Omit<IPentestProjectPostData, "alias">> &
    Pick<IPentestProjectPostData, "metadata">
) {
  return request.put<never, { success: boolean }>(
    `${endpoint.WORKSPACES}/${workspace_id}/pentest_projects/${pentest_alias}`,
    data
  );
}

function delete_pentest_project(workspace_id: string, pentest_alias: string) {
  return request.delete(
    `${endpoint.WORKSPACES}/${workspace_id}/pentest_projects/${pentest_alias}`
  );
}

function submit_pentest_project(workspace_id: string, alias: string) {
  return request.post<never, { alias: string }>(
    `${endpoint.WORKSPACES}/${workspace_id}/pentest_projects/${alias}/submit`
  );
}

function cancel_request_pentest_project(workspace_id: string, alias: string) {
  return request.post<never, { alias: string }>(
    `${endpoint.WORKSPACES}/${workspace_id}/pentest_projects/${alias}/cancel_request`
  );
}

export default {
  list_pentest_projects,
  list_pentest_projects_shortly,
  get_pentest_project_details,
  get_pentest_project_overview,
  list_pentest_project_history,
  create_pentest_project,
  update_pentest_project,
  delete_pentest_project,
  submit_pentest_project,
  cancel_request_pentest_project,

  list_pentest_project_members,
  list_pentest_project_mssp_members,
  create_pentest_project_member,
  update_pentest_project_member,
  delete_pentest_project_member,

  retrieve_pentest_mssp,
  update_pentest_mssp,
};
