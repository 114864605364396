import endpoint from "#src/config/endpoint";
import request from "#src/utils/request";
import constants from "#src/config/constants";

function generate_form_upload(
  file_name: string,
  action: string,
  // depends on action, we'll have to send different params
  params?: { [P: string]: string }
) {
  return request.post<never, { url: string }>(`${endpoint.ATTACHMENTS}`, {
    file_name,
    action,
    upload_service: constants.UPLOAD_SERVICE,
    ...params,
  });
}

function sign_attachment(attachment_url: string, action: string) {
  return request.post<never, { signed_url: string }>(
    `${endpoint.ATTACHMENTS}/signed`,
    {
      attachment_url,
      action,
      upload_service: constants.UPLOAD_SERVICE,
    }
  );
}

function retrieve_attachment(attachment_id: number) {
  return request.get<never, { url: string }>(
    `${endpoint.ATTACHMENTS}/${attachment_id}`
  );
}

export default { generate_form_upload, sign_attachment, retrieve_attachment };
