import { Dropdown } from "@lockerpm/design";
import {
  SeverityDropdownItemLabel,
  SeverityDropdownRender,
} from "#src/components/common/composed/SeverityDropdownHelper";
import { severityItems } from "#src/config/filter/vulnerability";
import { SeverityLabel } from "./labels";

interface ISeverityTagProps {
  severityValue: string;
  updateData: (severity: string | undefined) => void;
}

/** A Select Dropdown specially styled for Severity.
 * @param severityValue - current selected Severity
 * @param updateData - a function triggers when a value is selected
 */
const SeveritySelect = ({ severityValue, updateData }: ISeverityTagProps) => {
  const severityItem =
    severityItems
      .filter((item) => !item.key.includes("ALL"))
      .find(
        (item) => item.value?.toLowerCase() === severityValue.toLowerCase()
      ) || null;

  return severityItem ? (
    <Dropdown
      menu={{
        items: severityItems
          .filter((item) => !item.key.includes("ALL"))
          .map((item) => ({
            key: item.key,
            value: item.value,
            label: <SeverityDropdownItemLabel item={item} />,
          })),
        onClick: ({ key, domEvent }) => {
          domEvent.preventDefault();
          const newSelected = severityItems
            .filter((item) => !item.key.includes("ALL"))
            .find((item) => item.key === key);
          if (newSelected) updateData(newSelected.value);
        },
      }}
      dropdownRender={SeverityDropdownRender}
      placement="bottomRight"
      arrow
      trigger={["click"]}
    >
      {/* Antd Dropdown is not good enough to use a JSX inside. */}
      {SeverityLabel({
        severity: severityItem.value ?? "NONE",
        mode: "dropdown",
      })}
    </Dropdown>
  ) : null;
};

export default SeveritySelect;
