import endpoint from "#src/config/endpoint";
import request from "#src/utils/request";

export interface IScanApplicationItem {
  last_found: number;
  install_date: any | null;
  install_location: string;
  size: any | null;
  port: number;
  program: {
    name: string;
    version: any | null;
    publisher: any | null;
    cpe: any | null;
  };
}

export function list_scan_applications(
  workspace_id: string,
  scan_id: number,
  params: {
    domain_id: number | undefined;
  }
) {
  return request.get<never, IScanApplicationItem[]>(
    `${endpoint.WORKSPACES}/${workspace_id}/scans/${scan_id}/applications`,
    { params }
  );
}
