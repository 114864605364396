// Libraries
import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

// Resources
import { ReactComponent as ArrowRightUpLine } from "#src/assets/images/icons/arrow-right-up-line.svg";

// Components
import LoadingState from "#src/components/common/system/LoadingState";
import { Button } from "#src/components/common/system/Button";
import { NoVulnerabilitiesState } from "#src/components/common/states";

// API-related
import pentestServices, {
  type IPentestProjectOverview,
} from "#src/services/pentest";

// Children
import OverviewCard from "./OverviewCard";
import TablesAndGraphs from "./TablesAndGraphs";
import { apiErrorHandler } from "#src/utils/apiErrorHandler";

interface IPentestDetailsOverviewProps {
  workspaceId: string;
  pentestAlias: string;
}

const PentestDetailsOverview = ({
  workspaceId,
  pentestAlias,
}: IPentestDetailsOverviewProps) => {
  const { t } = useTranslation("pentest", {
    keyPrefix: "page.pentestDetails.tab.overview",
  });

  const [overviewDetails, setOverviewDetails] =
    useState<IPentestProjectOverview | null>(null);
  const [isLoading, setLoading] = useState<boolean>(false);

  const fetchPentestOverview = useCallback(() => {
    if (workspaceId && pentestAlias) {
      setLoading(true);
      pentestServices
        .get_pentest_project_overview(workspaceId, pentestAlias)
        .then((response) => {
          setOverviewDetails(response);
          setLoading(false);
        })
        .catch(apiErrorHandler);
    }
  }, [workspaceId, pentestAlias]);

  useEffect(() => {
    let ignore = false;

    if (!ignore) {
      fetchPentestOverview();
    }

    return () => {
      ignore = true;
    };
  }, [fetchPentestOverview]);

  const total = overviewDetails
    ? overviewDetails.report_statistics.status.open +
      overviewDetails.report_statistics.status.accepted +
      overviewDetails.report_statistics.status.rejected
    : 0;

  return overviewDetails === null || isLoading ? (
    <LoadingState />
  ) : (
    <section className="flex flex-col gap-6 pt-6">
      <div className="grid grid-cols-2 2xl:grid-cols-4 gap-6 w-fit">
        <OverviewCard
          label={t("statistics.submissionReceived")}
          value={
            total -
            overviewDetails.report_statistics.accepted_sub_status.duplicate
          }
          info={t("statistics.excludingDuplicate")}
        />
        <OverviewCard
          label={t("statistics.openVulnerabilities")}
          value={
            overviewDetails.report_statistics.open_sub_status.triaged +
            overviewDetails.report_statistics.accepted_sub_status.unresolved
          }
          info={
            <>
              {t("statistics.in")}
              <div className="flex items-center gap-1">
                <div className="h-2 w-2 bg-blue rounded-full" />
                {t("statistics.triaged")}
                <div className="h-2 w-2 bg-red rounded-full" />
                {t("statistics.unresolved")}
              </div>
            </>
          }
        />
        <OverviewCard
          label={t("statistics.fixedVulnerabilities")}
          value={overviewDetails.report_statistics.accepted_sub_status.resolved}
          info={
            <>
              {t("statistics.currentlyIn")}
              <div className="flex gap-1 items-center">
                <div className="h-2 w-2 bg-green rounded-full" />
                {t("statistics.resolved")}
              </div>
            </>
          }
        />
        <OverviewCard
          label={t("statistics.rejectedVulnerabilities")}
          value={overviewDetails.report_statistics.status.rejected}
          info={
            <>
              {t("statistics.currentlyIn")}
              <div className="flex gap-1 items-center">
                <div className="h-2 w-2 bg-medium-grey rounded-full" />
                {t("statistics.rejected")}
              </div>
            </>
          }
        />
      </div>
      <div className="flex justify-center">
        <Link
          to={`/${workspaceId}/vulnerabilities?assessment_type=pentest_project&pentest=${pentestAlias}`}
        >
          <Button variant="text">
            {t("statistics.button.viewVulnerabilities")}
            <ArrowRightUpLine />
          </Button>
        </Link>
      </div>
      {total === 0 ? <NoVulnerabilitiesState /> : <TablesAndGraphs />}
    </section>
  );
};

export default PentestDetailsOverview;
