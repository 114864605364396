// Libraries
import { useTranslation } from "react-i18next";
import dayjs from "dayjs";
import { Tooltip } from "@lockerpm/design";
import { generatePath, useNavigate, useParams } from "react-router-dom";
import { createSearchParams } from "react-router-dom";

// Resources
import { ReactComponent as ArrowRightUpLine } from "#src/assets/images/icons/arrow-right-up-line.svg";

// General
import { pathname } from "#src/config/pathname";

// Components
import { Button } from "#src/components/common/system/Button";
import { toPascalCase } from "#src/utils/common";
import type { ITableHeaderPropItem } from "#src/components/common/Table/TableHeader";
import LoadingState from "#src/components/common/system/LoadingState";
import NoResultsState from "#src/components/common/states/NoResultsState";
import {
  TableDataCell,
  TableHeader,
  TableRowWrapper,
} from "#src/components/common/Table";
import { createToast } from "#src/components/common/system/toasts";

// APi-related
import type { IHostSoftwareItem } from "#src/services/devices/softwares";

interface IHostSoftwaresTableRowProps {
  data: IHostSoftwareItem;
}

const HostSoftwaresTableRow = ({ data }: IHostSoftwaresTableRowProps) => {
  const { t } = useTranslation("devices", {
    keyPrefix: "hosts.page.details.tab.softwares",
  });

  const params = useParams<"workspace">();
  const navigate = useNavigate();

  const onClickViewAllHost = () => {
    navigate(
      {
        pathname: generatePath(pathname.HOSTS, params),
        search: createSearchParams({
          program_id: data.program.id.toString(),
        }).toString(),
      },
      { state: { program_name: data.program.name } }
    );
  };

  const formatSource = (source: string) => {
    const splitted = source.split("_");

    return (
      splitted
        .slice(0, splitted.length - 1)
        .map((item) => toPascalCase(item))
        .join(" ") +
      " (" +
      splitted[splitted.length - 1] +
      ")"
    );
  };

  return (
    <TableRowWrapper>
      <TableDataCell>
        <Tooltip title={data.program.name}>
          <span className="line-clamp-2" style={{ wordBreak: "break-word" }}>
            {data.program.name}
          </span>
        </Tooltip>
      </TableDataCell>
      <TableDataCell>{data.program.version}</TableDataCell>
      <TableDataCell>
        {data.program.source ? formatSource(data.program.source) : "---"}
      </TableDataCell>
      <TableDataCell>
        {data.program.vulnerabilities.length ? (
          <Tooltip
            title={
              <div className="flex flex-col items-center">
                {data.program.vulnerabilities.slice(0, 3).map((vuln) => (
                  <span
                    key={`tdevices/hostSoftwares-tr-${data.program.id}-vuln-${vuln.cve}`}
                  >
                    {vuln.cve}
                  </span>
                ))}
                {data.program.vulnerabilities.length > 3 ? (
                  data.program.vulnerabilities.length > 4 ? (
                    `+${data.program.vulnerabilities.length - 3} more`
                  ) : (
                    <span>{data.program.vulnerabilities[3].cve}</span>
                  )
                ) : null}
              </div>
            }
          >
            <span className="whitespace-nowrap">
              {t("vulnerabilityCount", {
                count: data.program.vulnerabilities.length,
              })}
            </span>
          </Tooltip>
        ) : (
          "---"
        )}
      </TableDataCell>
      <TableDataCell>
        {dayjs.unix(data.last_found).format("HH:mm DD.MM.YYYY")}
      </TableDataCell>
      <TableDataCell>
        {data.install_location[0] ? (
          <Tooltip title={data.install_location[0]}>
            <button
              onClick={() => {
                navigator.clipboard.writeText(data.install_location[0]);
                createToast({
                  type: "info",
                  message: "Copied to your clipboard!",
                  detail: data.install_location[0],
                });
              }}
            >
              <span className="break-all line-clamp-2 hover:text-primary text-left">
                {data.install_location[0]}
              </span>
            </button>
          </Tooltip>
        ) : (
          "---"
        )}
      </TableDataCell>
      <TableDataCell>
        <Tooltip title={t("button.viewAllHosts.tooltip")}>
          <Button
            variant="text"
            onClick={() => {
              onClickViewAllHost();
            }}
          >
            {t("button.viewAllHosts.label")}
            <ArrowRightUpLine />
          </Button>
        </Tooltip>
      </TableDataCell>
    </TableRowWrapper>
  );
};

interface IHostSoftwaresTableGridProps {
  hostSoftwareList: IHostSoftwareItem[] | null;
  isLoading: boolean;
}

const HostSoftwaresTableGrid = ({
  hostSoftwareList,
  isLoading,
}: IHostSoftwaresTableGridProps) => {
  const { t } = useTranslation("devices", {
    keyPrefix: "hosts.page.details.tab.softwares",
  });

  const headers: ITableHeaderPropItem[] = [
    { name: t("headers.name"), align: "left" },
    { name: t("headers.version"), align: "left" },
    { name: t("headers.type"), align: "left" },
    { name: t("headers.vulnerabilities"), align: "left" },
    { name: t("headers.lastFound"), align: "left" },
    { name: t("headers.filePath"), align: "left" },
    { name: "", align: "left" },
  ];

  return isLoading || hostSoftwareList === null ? (
    <LoadingState />
  ) : hostSoftwareList.length === 0 ? (
    <NoResultsState />
  ) : (
    <div className="grid grid-cols-[2fr_1fr_1fr_1fr_1fr_2fr_1fr]">
      <TableHeader title="devices/hosts/softwares" headerProps={headers} />
      {hostSoftwareList.map((software) => (
        <HostSoftwaresTableRow
          key={`tdevices/hostSoftwares-tr-${software.program.id}`}
          data={software}
        />
      ))}
    </div>
  );
};

export default HostSoftwaresTableGrid;
