import endpoint from "#src/config/endpoint";
import request from "#src/utils/request";
import type { ICertificateItem } from "../assets/certificates";

export function get_scan_certificate(
  workspace_id: string,
  scan_id: number,
  params: {
    domain_id: number | undefined;
  }
) {
  return request.get<never, ICertificateItem | {}>(
    `${endpoint.WORKSPACES}/${workspace_id}/scans/${scan_id}/certificate`,
    { params }
  );
}
