import { ESolutionKeys } from "#src/@types/common";
import { ReactComponent as AddFile } from "#src/assets/images/icons/add-file.svg";
import { ReactComponent as ArrowRightUpLine } from "#src/assets/images/icons/arrow-right-up-line.svg";
import { ReactComponent as LogoCyStack } from "#src/assets/images/logos/logo-cystack-small.svg";
import { ReactComponent as LogoLocker } from "#src/assets/images/logos/logo-locker.svg";
import { ReactComponent as LogoWhitehub } from "#src/assets/images/logos/logo-whitehub.svg";
import { Button } from "#src/components/common/system/Button";
import AllSolutions from "#src/components/solutions/All";
import DataSecurity from "#src/components/solutions/DataSecurity";
import SecurityCompliance from "#src/components/solutions/SecurityCompliance";
import SecurityOperation from "#src/components/solutions/SecurityOperation";
import VulnScan from "#src/components/solutions/Vulnscan";
import constants from "#src/config/constants";
import { ContentSection, StickySection } from "#src/layouts/content";
import { ISolutionFromApi, list_solutions } from "#src/services/solutions";
import { Tabs } from "@lockerpm/design";
import { t } from "i18next";
import { ReactNode, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";

const SOLUTION_TAB_KEY = {
  ALL: "all",
  VULNSCAN: "vulnscan",
  SECURITY_OPERATION: "security_operation",
  DATA_SECURITY: "data_security",
  SECURITY_COMPLIANCE: "security_compliance",
};

export interface ISolution {
  key: ESolutionKeys;
  logo: ReactNode;
  title: string;
  description: string;
  url: string;
  isExternal?: boolean;
  enabled: boolean;
  isCreate?: boolean;
  isComming?: boolean;
}

export const CardSolution = ({
  logo,
  title,
  description,
  isExternal = true,
  url,
  enabled,
  isComming,
  isCreate,
}: {
  logo: ReactNode;
  title: string;
  description: string;
  isExternal?: boolean;
  url: string;
  enabled: boolean;
  isComming?: boolean;
  isCreate?: boolean;
}) => {
  return (
    <div className="rounded-md flex flex-col justify-between bg-bright-grey p-6 h-full">
      <div className="mb-6 space-y-3">
        <div className="flex items-center gap-3">
          <div className="logo w-[60px] h-[60px] flex-shrink-0 flex items-center justify-center bg-white rounded-md border border-solid border-light-grey">
            {logo}
          </div>
          <span className="font-medium-18 text-dark-blue">{title}</span>
        </div>
        <p className="text-hard-grey font-regular-16-body line-clamp-3">
          {description}
        </p>
      </div>
      <div className="flex justify-end">
        {isComming ? (
          <Button disabled>{t("solutions:actions.comming_soon")}</Button>
        ) : (
          <a
            href={url}
            rel="noreferrer"
            target={isExternal ? "_blank" : "_parent"}
          >
            {isCreate ? (
              <Button variant="secondary">
                <AddFile className="w-4 h-4" />
                <span>{t("solutions:actions.explore")}</span>
              </Button>
            ) : enabled ? (
              <Button>{t("solutions:actions.open")}</Button>
            ) : (
              <Button variant="secondary">
                {t("solutions:actions.start_now")}
                <ArrowRightUpLine className="w-4 h-4" />
              </Button>
            )}
          </a>
        )}
      </div>
    </div>
  );
};

const AllSolutionsIndexPage = () => {
  const { t } = useTranslation("solutions");

  const { workspace: workspaceId } = useParams<"workspace">();

  const [activeTab, setActiveTab] = useState<string>(SOLUTION_TAB_KEY.ALL);
  const [solutions, setSolutions] = useState<ISolutionFromApi[]>([]);
  const onChangeTab = (key: string) => {
    setActiveTab(key);
  };

  const pageTitle = useMemo(() => {
    switch (activeTab) {
      case SOLUTION_TAB_KEY.ALL:
        return t("pageTitle.all");
      case SOLUTION_TAB_KEY.VULNSCAN:
        return t("pageTitle.vulnscan");
      case SOLUTION_TAB_KEY.SECURITY_OPERATION:
        return t("pageTitle.securityOperation");
      case SOLUTION_TAB_KEY.DATA_SECURITY:
        return t("pageTitle.dataSecurity");
      default:
        return t("pageTitle.securityCompliance");
    }
  }, [t, activeTab]);

  const tabList = useMemo(() => {
    return [
      {
        key: SOLUTION_TAB_KEY.ALL,
        label: <div className="font-medium-16 py-3 px-8">{t("tabs.all")}</div>,
      },
      {
        key: SOLUTION_TAB_KEY.VULNSCAN,
        label: (
          <div className="font-medium-16 py-3 px-8">{t("tabs.vulnscan")}</div>
        ),
      },
      {
        key: SOLUTION_TAB_KEY.SECURITY_OPERATION,
        label: (
          <div className="font-medium-16 py-3 px-8">
            {t("tabs.securityOperation")}
          </div>
        ),
      },
      {
        key: SOLUTION_TAB_KEY.DATA_SECURITY,
        label: (
          <div className="font-medium-16 py-3 px-8">
            {t("tabs.dataSecurity")}
          </div>
        ),
      },
      {
        key: SOLUTION_TAB_KEY.SECURITY_COMPLIANCE,
        label: (
          <div className="font-medium-16 py-3 px-8">
            {t("tabs.securityCompliance")}
          </div>
        ),
      },
    ];
  }, [t]);

  useEffect(() => {
    const fetchSolution = async () => {
      if (!workspaceId) return;
      const response = await list_solutions(workspaceId);
      setSolutions(response);
    };
    fetchSolution();
  }, [workspaceId]);

  const defaultSolutions: Omit<ISolution, "enabled">[] = useMemo(
    () => [
      {
        key: ESolutionKeys.SECRETS_MANAGER,
        logo: <LogoLocker />,
        title: t("solution.secrets_manager.title"),
        description: t("solution.secrets_manager.description"),
        url: constants.SOLUTION_LINKS.LOCKER_SECRET_MANAGER,
      },
      {
        key: ESolutionKeys.PASSWORD_MANAGER,
        logo: <LogoLocker />,
        title: t("solution.password_manager.title"),
        description: t("solution.password_manager.description"),
        url: constants.SOLUTION_LINKS.LOCKER_PASSWORD_MANAGER,
      },
      {
        key: ESolutionKeys.ENDPOINT,
        logo: <LogoCyStack />,
        title: t("solution.endpoint.title"),
        description: t("solution.endpoint.description"),
        url: constants.SOLUTION_LINKS.ENDPOINT,
        isComming: true,
      },
      {
        key: ESolutionKeys.DATA_LEAK,
        logo: <LogoCyStack />,
        title: t("solution.data_leak.title"),
        description: t("solution.data_leak.description"),
        url: constants.SOLUTION_LINKS.DATA_LEAK,
        isComming: true,
      },
      {
        key: ESolutionKeys.VULN_SCAN,
        logo: <LogoCyStack />,
        title: t("solution.vuln_scan.title"),
        description: t("solution.vuln_scan.description"),
        url: constants.SOLUTION_LINKS.VULNSCAN,
        isComming: true,
      },
      {
        key: ESolutionKeys.TRUST_CENTER,
        logo: <LogoCyStack />,
        title: t("solution.trust_center.title"),
        description: t("solution.trust_center.description"),
        url: constants.SOLUTION_LINKS.TRUST_CENTER.replace(
          ":workspaceId",
          workspaceId || ""
        ),
        isExternal: false,
        isCreate: true,
      },
      {
        key: ESolutionKeys.PENETRATION_TESTING,
        logo: <LogoCyStack />,
        title: t("solution.penetration_testing.title"),
        description: t("solution.penetration_testing.description"),
        url: constants.SOLUTION_LINKS.PENETRATION_TESTING,
        isCreate: true,
      },
      {
        key: ESolutionKeys.RED_TEAMING,
        logo: <LogoCyStack />,
        title: t("solution.red_teaming.title"),
        description: t("solution.red_teaming.description"),
        url: constants.SOLUTION_LINKS.RED_TEAM,
        isCreate: true,
      },
      {
        key: ESolutionKeys.CLOUD_SECURITY_AUDIT,
        logo: <LogoCyStack />,
        title: t("solution.cloud_security_audit.title"),
        description: t("solution.cloud_security_audit.description"),
        url: constants.SOLUTION_LINKS.INFRASTRUCTURE_AUDIT,
        isCreate: true,
      },
      {
        key: ESolutionKeys.SOURCE_CODE_AUDIT,
        logo: <LogoCyStack />,
        title: t("solution.source_code_audit.title"),
        description: t("solution.source_code_audit.description"),
        url: constants.SOLUTION_LINKS.SOURCE_CODE_AUDIT,
        isCreate: true,
      },
      {
        key: ESolutionKeys.BLOCKCHAIN_PROTOCOL_AUDIT,
        logo: <LogoCyStack />,
        title: t("solution.blockchain_protocol_audit.title"),
        description: t("solution.blockchain_protocol_audit.description"),
        url: constants.SOLUTION_LINKS.BLOCKCHAIN_AUDIT,
        isCreate: true,
      },
      {
        key: ESolutionKeys.WHITEHUB_BUG_BOUNTY,
        logo: <LogoWhitehub />,
        title: t("solution.whitehub_bug_bounty.title"),
        description: t("solution.whitehub_bug_bounty.description"),
        url: constants.SOLUTION_LINKS.WHITEHUB_BUG_BOUNTY,
        isCreate: true,
      },
      {
        key: ESolutionKeys.SECURITY_COMPLIANCE,
        logo: <LogoCyStack />,
        title: t("solution.security_compliance.title"),
        description: t("solution.security_compliance.description"),
        url: constants.SOLUTION_LINKS.COMPLIANCE_AUDIT_SERVICE,
        isCreate: true,
      },
      {
        key: ESolutionKeys.SECURITY_OPERATIONS_CENTER,
        logo: <LogoCyStack />,
        title: t("solution.security_operations_center.title"),
        description: t("solution.security_operations_center.description"),
        url: constants.SOLUTION_LINKS.SOC,
        isCreate: true,
      },
      {
        key: ESolutionKeys.VULNERABILITY_MANAGEMENT,
        logo: <LogoCyStack />,
        title: t("solution.vulnerability_management.title"),
        description: t("solution.vulnerability_management.description"),
        url: constants.SOLUTION_LINKS.VULNERABILITY_MANAGEMENT,
        isCreate: true,
      },
    ],
    [t, workspaceId]
  );

  const finalSolutions: ISolution[] = useMemo(() => {
    return defaultSolutions.map((item) => {
      const solution = solutions.find((s) => s.solution.id === item.key);
      return {
        ...item,
        enabled: solution?.enabled || false,
      };
    });
  }, [defaultSolutions, solutions]);

  return (
    <>
      <StickySection>
        <div className="flex flex-col gap-5">
          <h1>{pageTitle}</h1>
          <Tabs
            items={tabList}
            activeKey={activeTab}
            onChange={onChangeTab}
            destroyInactiveTabPane
          />
        </div>
      </StickySection>
      <ContentSection>
        {activeTab === SOLUTION_TAB_KEY.ALL ? (
          <AllSolutions solutions={finalSolutions} />
        ) : null}
        {activeTab === SOLUTION_TAB_KEY.VULNSCAN ? (
          <VulnScan solutions={finalSolutions} />
        ) : null}
        {activeTab === SOLUTION_TAB_KEY.SECURITY_OPERATION ? (
          <SecurityOperation solutions={finalSolutions} />
        ) : null}
        {activeTab === SOLUTION_TAB_KEY.DATA_SECURITY ? (
          <DataSecurity solutions={finalSolutions} />
        ) : null}
        {activeTab === SOLUTION_TAB_KEY.SECURITY_COMPLIANCE ? (
          <SecurityCompliance solutions={finalSolutions} />
        ) : null}
      </ContentSection>
    </>
  );
};

export default AllSolutionsIndexPage;
