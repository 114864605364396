// Libraries
import { Dispatch, SetStateAction, useMemo } from "react";

// General
import constants from "#src/config/constants";

// Components
import useFetchPaginated from "#src/hooks/useFetchPaginated";

// API-related
import trustCenterServices from "#src/services/trustCenter";

// Children
import TrustCenterThirdPartyTableGrid from "./Table";
import AddThirdPartyDrawer from "../AddThirdPartyDrawer";

interface ITrustCenterThirdPartyTableProps {
  workspaceId: string;
  openAddDrawer: boolean;
  setOpenAddDrawer: Dispatch<SetStateAction<boolean>>;
}

const TrustCenterThirdPartyTable = ({
  workspaceId,
  openAddDrawer,
  setOpenAddDrawer,
}: ITrustCenterThirdPartyTableProps) => {
  const fetchParams = useMemo<
    Parameters<typeof trustCenterServices.list_trust_center_third_party>
  >(
    () => [
      workspaceId,
      { q: undefined, page: 1, size: constants.DEFAULT_PAGE_SIZE },
    ],
    [workspaceId]
  );

  const {
    list: thirdPartyList,
    isLoading,
    updateData: updateThirdPartyList,
  } = useFetchPaginated(
    trustCenterServices.list_trust_center_third_party,
    fetchParams
  );

  return (
    <>
      <TrustCenterThirdPartyTableGrid
        workspaceId={workspaceId}
        thirdPartyList={thirdPartyList}
        isLoading={isLoading}
        onRefresh={() => {
          updateThirdPartyList();
        }}
      />
      <AddThirdPartyDrawer
        workspaceId={workspaceId}
        open={openAddDrawer}
        onClose={() => {
          setOpenAddDrawer(false);
        }}
        onRefresh={() => {
          updateThirdPartyList();
        }}
        addedServices={thirdPartyList ?? []}
      />
    </>
  );
};

export default TrustCenterThirdPartyTable;
