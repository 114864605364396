import { useEffect, useState } from "react";
import { flushSync } from "react-dom";
import { ToastObservable, type InternalToastProps } from "./observer";

/** Returns the list of current existing toasts. */
export const useToastList = () => {
  const [toasts, setToasts] = useState<InternalToastProps[]>([]);

  useEffect(() => {
    return ToastObservable.subscribe((toast: InternalToastProps) => {
      if (toast.status === "dismissed") {
        setToasts((prev) =>
          prev.map((t) =>
            t.id === toast.id ? { ...t, status: "dismissed" } : t
          )
        );
        return;
      }

      if (toast.status === "destroyed") {
        setToasts((prev) => prev.filter((t) => t.id !== toast.id));
        return;
      }

      // Prevent batching, temp solution.
      setTimeout(() => {
        flushSync(() => {
          setToasts((prev) => {
            return [...prev, toast];
          });
        });
      });
    });
  }, []);

  return toasts;
};
