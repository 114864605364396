export enum ComplianceStatusEnum {
  COMPLIANCE = "TRUST_CENTER_COMPLIANCE_STATUS_ENUM_COMPLIANCE",
  NON_COMPLIANCE = "TRUST_CENTER_COMPLIANCE_STATUS_ENUM_NON_COMPLIANT",
  UNKNOWN = "TRUST_CENTER_COMPLIANCE_STATUS_ENUM_UNKNOWN",
  ALL = "TRUST_CENTER_COMPLIANCE_STATUS_ENUM_ALL",
}

export enum TrustCenterPolicyStatusEnum {
  DRAFT = "TRUST_CENTER_POLICY_STATUS_ENUM_DRAFT",
  PUBLISHED = "TRUST_CENTER_POLICY_STATUS_ENUM_PUBLISHED",
  ALL = "TRUST_CENTER_POLICY_STATUS_ENUM_ALL",
}
