// Libraries
import { type Dispatch, type SetStateAction, useState, useMemo } from "react";
import { useTranslation } from "react-i18next";

// General
import type { IFilterItem } from "#src/@types/common";
import constants from "#src/config/constants";

// Components
import PaginationBar from "#src/components/common/helper/PaginationBar";
import useFetchPaginated from "#src/hooks/useFetchPaginated";
import OverviewMainCard from "#src/components/common/OverviewMainCard";
import { createToast } from "#src/components/common/system/toasts";
import { apiErrorHandler } from "#src/utils/apiErrorHandler";

// API-related
import assetsServices, { type IAssetItem } from "#src/services/assets";

// Children
import IpAddressFilter from "./Filter";
import IpAddressTableGrid from "./Table";
import AddIpAddressDrawer from "../drawers/AddIpAddressDrawer";

interface IIpAddressTableProps {
  workspaceId: string;
  openAddIpAddressDrawer: boolean;
  setOpenAddIpAddressDrawer: Dispatch<SetStateAction<boolean>>;
}

const IpAddressTable = ({
  workspaceId,
  openAddIpAddressDrawer,
  setOpenAddIpAddressDrawer,
}: IIpAddressTableProps) => {
  const { t } = useTranslation("assets", {
    keyPrefix: "ipAddress.page.ipAddress",
  });

  const [selectedPage, setSelectedPage] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(constants.DEFAULT_PAGE_SIZE);

  const [vulnerable, setVulnerable] = useState<IFilterItem | null>(null);
  const [verifiedOwnership, setVerifiedOwnership] =
    useState<IFilterItem | null>(null);
  const [selectedIpAddresses, setSelectedIpAddresses] = useState<IAssetItem[]>(
    []
  );
  const [searchKeyword, setSearchKeyword] = useState<string>("");

  const fetchParams = useMemo<Parameters<typeof assetsServices.list_domains>>(
    () => [
      workspaceId,
      {
        is_domain: 0,
        from: undefined,
        to: undefined,
        vulnerable: vulnerable?.value,
        verification: verifiedOwnership?.value,
        q: searchKeyword,
        page: selectedPage,
        size: pageSize,
      },
    ],
    [
      workspaceId,
      vulnerable,
      verifiedOwnership,
      searchKeyword,
      selectedPage,
      pageSize,
    ]
  );

  const {
    list: ipAddressList,
    count: ipAddressCount,
    isLoading,
    updateData: updateIpAddressList,
    statistics,
  } = useFetchPaginated(
    assetsServices.list_domains,
    fetchParams,
    assetsServices.get_asset_statistics
  );

  const onChangeVulnerableSelection = (selected: typeof vulnerable) => {
    setVulnerable(selected);
    setSelectedPage(1);
  };

  const onChangeVerifiedOwnershipSelection = (
    selected: typeof verifiedOwnership
  ) => {
    setVerifiedOwnership(selected);
    setSelectedPage(1);
  };

  const onChangeSearchKeyword = (keyword: string) => {
    setSearchKeyword(keyword);
    setSelectedPage(1);
  };

  const onChangePage = (page: number) => {
    setSelectedPage(page);
    setSelectedIpAddresses([]);
  };

  const onChangePageSize = (size: number) => {
    setPageSize(size);
    setSelectedPage(1);
    setSelectedIpAddresses([]);
  };

  const onChangeSelectedIpAddresses = (selected: IAssetItem) => {
    if (selectedIpAddresses.map((item) => item.id).includes(selected.id)) {
      setSelectedIpAddresses((prev) => [
        ...prev.filter((item) => item.id !== selected.id),
      ]);
    } else {
      setSelectedIpAddresses((prev) => [...prev, selected]);
    }
  };

  const onSelectAllIpAddresses = () => {
    if (ipAddressList) {
      setSelectedIpAddresses(ipAddressList);
    }
  };

  const onDeselectAllIpAddresses = () => {
    setSelectedIpAddresses([]);
  };

  const onDeleteSelectedIpAddresses = () => {
    assetsServices
      .delete_multiple_assets(
        selectedIpAddresses.map((item) => item.id),
        workspaceId
      )
      .then(() => {
        updateIpAddressList();
        createToast({
          type: "success",
          message: t("notification.deleteIpAddress.success"),
        });
      })
      .catch((error) => {
        apiErrorHandler(error, {
          toastMessage: t("notification.deleteIpAddress.fail"),
        });
      });
    setSelectedIpAddresses([]);
  };

  const vulnerablePercentage =
    statistics && statistics.total > 0
      ? (statistics?.vulnerable_domains / statistics?.total) * 100
      : 0;

  return (
    <>
      <div className="flex flex-wrap gap-6">
        <OverviewMainCard
          label={t("mainCard.totalIpAddresses")}
          value={statistics?.total}
          variant="blue"
        />
        <OverviewMainCard
          label={t("mainCard.vulnerableIpAddresses")}
          value={statistics ? vulnerablePercentage.toFixed() + "%" : undefined}
          variant={
            statistics
              ? vulnerablePercentage < 10
                ? "green"
                : vulnerablePercentage * 100 < 70
                ? "orange"
                : "red"
              : "green"
          }
        />
      </div>
      <IpAddressFilter
        vulnerable={vulnerable}
        verifiedOwnership={verifiedOwnership}
        searchKeyword={searchKeyword}
        totalCount={ipAddressList ? ipAddressList.length : 0}
        selectedCount={selectedIpAddresses.length}
        onChangeVulnerableSelection={onChangeVulnerableSelection}
        onChangeVerifiedOwnershipSelection={onChangeVerifiedOwnershipSelection}
        onChangeSearchKeyword={onChangeSearchKeyword}
        onSelectAllIpAddresses={onSelectAllIpAddresses}
        onDeselectAllIpAddresses={onDeselectAllIpAddresses}
        onDeleteSelectedIpAddresses={onDeleteSelectedIpAddresses}
      />
      <IpAddressTableGrid
        workspaceId={workspaceId}
        ipAddressList={ipAddressList}
        selectedIpAddressIds={selectedIpAddresses.map((item) => item.id)}
        isLoading={isLoading}
        onChangeSelectedIpAddresses={onChangeSelectedIpAddresses}
        refreshIpAddressList={updateIpAddressList}
      />
      <PaginationBar
        numOfResult={ipAddressCount}
        selectedPage={selectedPage}
        pageSize={pageSize}
        onChangePage={onChangePage}
        onChangePageSize={onChangePageSize}
      />
      <AddIpAddressDrawer
        open={openAddIpAddressDrawer}
        onClose={() => {
          setOpenAddIpAddressDrawer(false);
        }}
        workspaceId={workspaceId}
        onRefresh={updateIpAddressList}
      />
    </>
  );
};

export default IpAddressTable;
