import type { ReactNode } from "react";

interface IPentestDetailsOverviewOverviewCardProps {
  label: string;
  value: number;
  info: ReactNode;
}

const PentestDetailsOverviewOverviewCard = ({
  label,
  value,
  info,
}: IPentestDetailsOverviewOverviewCardProps) => {
  return (
    <div className="flex-1 flex items-start justify-between gap-6 px-6 py-4 border border-grey rounded-md whitespace-pre-line w-full max-w-[24rem]">
      <div className="flex flex-col gap-3">
        <span className="font-medium-20 overflow-hidden whitespace-nowrap text-ellipsis">
          {label}
        </span>
        <span className="font-medium-16 text-hard-grey flex gap-3 items-center whitespace-nowrap">
          {info}
        </span>
      </div>
      <span className="font-bold-24">{value}</span>
    </div>
  );
};

export default PentestDetailsOverviewOverviewCard;
