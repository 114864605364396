// Libraries
import type { Dispatch, SetStateAction } from "react";

// Components
import { expandPlanDuration } from "#src/utils/apiHelper";

// API-related
import paymentService, {
  type IPaymentForm,
  type IRecurringPrice,
} from "#src/services/payment";

// Children
import PaymentFillFormRecheckTable from "#src/components/payment/FillForm/RecheckTable";
import PaymentFillFormCustomerInfo from "#src/components/payment/FillForm/CustomerInfo";
import PaymentMethod from "./PaymentMethod";
import PaymentFillFormSummary from "#src/components/payment/FillForm/Summary";
import type { ContactFormSelection } from "#src/components/trustCenter/onboard/ContactForm/contactDetailFields";

interface IBillingServiceRenewalFillFormProps {
  productPlans: Awaited<
    ReturnType<typeof paymentService.list_workspace_payment_plans>
  >;
  pricingInfo: Awaited<
    ReturnType<typeof paymentService.calculate_pricing>
  > | null;
  paymentForm: IPaymentForm;
  setPaymentForm: Dispatch<SetStateAction<IPaymentForm>>;
  jobTitle: ContactFormSelection;
  setJobTitle: Dispatch<SetStateAction<ContactFormSelection>>;
  defaultPaymentSettings: Awaited<
    ReturnType<typeof paymentService.retrieve_workspace_payment_settings>
  > | null;
  onApplyPromocode: (value: string) => Promise<boolean>;
  onRemovePromocode: (removeId: string) => void;
  errorObj: {
    full_name: string | undefined;
    phone_number: string | undefined;
    email: string | undefined;
  };
  setErrorObj: Dispatch<
    SetStateAction<{
      full_name: string | undefined;
      phone_number: string | undefined;
      email: string | undefined;
    }>
  >;
}

const BillingServiceRenewalFillForm = ({
  productPlans,
  pricingInfo,
  paymentForm,
  setPaymentForm,
  jobTitle,
  setJobTitle,
  defaultPaymentSettings,
  onApplyPromocode,
  onRemovePromocode,
  errorObj,
  setErrorObj,
}: IBillingServiceRenewalFillFormProps) => {
  // const { t } = useTranslation("trustCenter", {
  //   keyPrefix: "registerDomain.fillForm",
  // });

  const onChangeCustomer = (value: (typeof paymentForm)["customer"]) => {
    setPaymentForm((prev) => ({
      ...prev,
      customer: value,
    }));
  };

  return (
    <div className="flex flex-col gap-10">
      <PaymentFillFormRecheckTable
        products={productPlans
          .map(expandPlanDuration)
          .filter(<T,>(p: T): p is Exclude<T, undefined> => p !== undefined)
          .map((product) => ({
            id: product.product.id,
            service: product.product.name,
            product: product.product.category.name,
            plan: product.product_plan.name,
            cycle: product.getSpan,
            price: ((price): price is IRecurringPrice =>
              typeof price === "object" && Object.hasOwn(price, "vnd"))(
              product.product_plan.recurring_price
            )
              ? product.product_plan.recurring_price.vnd
              : 0,
            // TODO: edit modal.
            onClickEdit: () => {},
            nextBillingTime: pricingInfo?.product_plans.find(
              (plan) => plan.id === product.product_plan.id
            )?.next_billing_time,
          }))}
      />
      <div className="grid grid-cols-[3fr_2fr] gap-6">
        <div className="flex flex-col gap-6">
          <PaymentFillFormCustomerInfo
            customer={paymentForm.customer}
            onChangeCustomer={onChangeCustomer}
            jobTitle={jobTitle}
            setJobTitle={setJobTitle}
            errorObj={errorObj}
            setErrorObj={setErrorObj}
          />
          <PaymentMethod
            paymentForm={paymentForm}
            setPaymentForm={setPaymentForm}
            defaultPaymentSettings={defaultPaymentSettings}
          />
        </div>
        <PaymentFillFormSummary
          pricingInfo={pricingInfo}
          onApplyPromocode={onApplyPromocode}
          onRemovePromocode={onRemovePromocode}
        />
      </div>
    </div>
  );
};

export default BillingServiceRenewalFillForm;
