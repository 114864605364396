// Libraries
import { useTranslation } from "react-i18next";
import dayjs from "dayjs";

// Resources
import { ReactComponent as TimeLine } from "#src/assets/images/icons/time-line.svg";

// Components
import { ObjectImage } from "#src/components/common/system/Object";
import { RoleLabel } from "#src/components/common/labels";

// API-related
import type { IPentestProjectMember } from "#src/services/pentest/members";

interface IMemberItemProps {
  member: IPentestProjectMember;
}

const MemberItem = ({ member }: IMemberItemProps) => {
  const { t } = useTranslation("pentest", {
    keyPrefix: "page.pentestDetails.tab.members",
  });

  return (
    <div className="border border-grey rounded-md p-6 flex justify-between gap-2">
      <div className="flex items-center gap-2">
        <ObjectImage
          data={member.user?.avatar}
          className="h-11 w-11 min-w-[2.75rem] rounded-full"
        />
        <div className="flex flex-col">
          <div className="font-medium-16 flex gap-2 items-center">
            {member.user ? member.user.full_name : member.email}
            <div className="flex gap-1">
              {member.role === "owner" ? (
                <RoleLabel
                  name={t("label.projectOwner")}
                  color={"bg-hard-grey"}
                />
              ) : null}
              {member.role === "admin" ? (
                <RoleLabel name={t("label.admin")} color={"bg-support-green"} />
              ) : null}
              {member.role === "developer" ? (
                <RoleLabel
                  name={t("label.developer")}
                  color={"bg-support-blue"}
                />
              ) : null}
              {member.default_assign ? (
                <RoleLabel
                  name={t("label.autoAssign")}
                  color={"bg-yellow text-black"}
                />
              ) : null}
            </div>
          </div>
          <span className="font-regular-14 text-primary">
            {"@" + (member.user ? member.user.username : member.email)}
          </span>
        </div>
      </div>
      <div className="flex flex-col gap-2 items-end">
        <div className="h-11" />
        <div className="flex gap-1 items-center font-regular-12 text-hard-grey">
          <TimeLine height={"0.875rem"} width={"0.875rem"} />
          {t("joined", { timestamp: dayjs.unix(member.access_time).fromNow() })}
        </div>
      </div>
    </div>
  );
};

export default MemberItem;
