// Libraries
import type { ReactNode } from "react";
import { useTranslation } from "react-i18next";
import dayjs from "dayjs";

// Resources
import { ReactComponent as LogoWindows10 } from "#src/assets/images/logos/logo-windows-10.svg";
import { ReactComponent as LogoApple } from "#src/assets/images/logos/logo-apple.svg";
import { ReactComponent as LogoUbuntu } from "#src/assets/images/logos/logo-ubuntu.svg";
import { ReactComponent as ImageCircleFill } from "#src/assets/images/icons/image-circle-fill.svg";
import { ReactComponent as CheckboxCircleLine } from "#src/assets/images/icons/checkbox-circle-line.svg";
import { ReactComponent as CloseCircleLine } from "#src/assets/images/icons/close-circle-line.svg";

// Components
import { RoundedSeeMoreTags, RoundedTag } from "#src/components/common/Tags";
import {
  TableDataCell,
  TableHeader,
  TableRowWrapper,
} from "#src/components/common/Table";
import type { ITableHeaderPropItem } from "#src/components/common/Table/TableHeader";
import { StatusLabel } from "#src/components/common/labels";

// API-related
import type { IHostItem } from "#src/services/devices";
import { PolicyComplianceValue } from "#src/config/filter/device/value";

const iconByOs: { [os: string]: ReactNode } = {
  windows: <LogoWindows10 />,
  macos: <LogoApple />,
  ubuntu: <LogoUbuntu />,
};

interface IHostTableRowProps {
  data: IHostItem;
}

const HostTableRow = ({ data }: IHostTableRowProps) => {
  // Windows is Windows, Darwin is the core of macOS, and we only detect Ubuntu, not the other Linux distros
  const getOs =
    data.os.name.toLowerCase().includes("windows") ||
    data.os.family.toLowerCase().includes("windows")
      ? "windows"
      : data.os.name.toLowerCase().includes("darwin") ||
        data.os.family.toLowerCase().includes("darwin")
      ? "macos"
      : data.os.name.toLowerCase().includes("ubuntu") ||
        data.os.family.toLowerCase().includes("ubuntu")
      ? "ubuntu"
      : undefined;

  return (
    <TableRowWrapper to={`./../../hosts/${data.id}`}>
      <TableDataCell>
        {getOs ? (
          iconByOs[getOs]
        ) : (
          <ImageCircleFill className="fill-hard-grey" />
        )}
      </TableDataCell>
      <TableDataCell>
        {/* 300s is time tolerance for online status */}
        {data.last_seen &&
        dayjs().diff(dayjs.unix(data.last_seen), "second") < 300 ? (
          <>
            <span className="h-2.5 w-2.5 rounded-full bg-warning-safe" />
            {/* TOOD: this should have i18n? */}
            Online
          </>
        ) : (
          <>
            <span className="h-2.5 w-2.5 rounded-full bg-warning" />
            {/* TOOD: this should have i18n? */}
            Offline
          </>
        )}
      </TableDataCell>
      <TableDataCell>{data.name}</TableDataCell>
      <TableDataCell>{data.owner}</TableDataCell>
      <TableDataCell>{data.internal_ip}</TableDataCell>
      <TableDataCell>
        {dayjs.unix(data.created_time).format("HH:mm DD.MM.YYYY")}
      </TableDataCell>
      <TableDataCell>
        {data.policy_compliance === PolicyComplianceValue.PASSED ? (
          <StatusLabel
            // TODO: might want to use i18n
            name={"Passed"}
            color="bg-support-seafoam-pastel text-support-seafoam"
            icon={<CheckboxCircleLine className="h-4 w-4 min-w-[1rem]" />}
          />
        ) : data.policy_compliance === PolicyComplianceValue.FAILED ? (
          <StatusLabel
            // TODO: might want to use i18n
            name={"Failed"}
            color="text-warning-red-danger bg-warning-red-10%"
            icon={<CloseCircleLine className="h-4 w-4 min-w-[1rem]" />}
          />
        ) : null}
      </TableDataCell>
      <TableDataCell>
        {data.tags.length > 0 ? <RoundedTag {...data.tags[0]} /> : null}
        {data.tags.length > 1 ? (
          <RoundedSeeMoreTags tagList={data.tags.slice(1)} />
        ) : null}
      </TableDataCell>
    </TableRowWrapper>
  );
};

interface IHostsByPolicyProps {
  data: IHostItem[];
}

const HostsByPolicyTable = ({ data }: IHostsByPolicyProps) => {
  const { t } = useTranslation("devices", {
    keyPrefix: "policies.detailPage.hostTable",
  });
  const headers: ITableHeaderPropItem[] = [
    { name: t("headers.os"), align: "left" },
    { name: t("headers.status"), align: "left" },
    { name: t("headers.name"), align: "left" },
    { name: t("headers.owner"), align: "left" },
    { name: t("headers.ip"), align: "left" },
    { name: t("headers.createdDate"), align: "left" },
    { name: t("headers.policyCompliance"), align: "left" },
    { name: t("headers.tags"), align: "left" },
  ];

  return (
    <div className="grid grid-cols-[4rem_2fr_5fr_3fr_3fr_4fr_2fr_3fr]">
      <TableHeader
        title="devices/policies-hostsByPolicy"
        headerProps={headers}
      />
      {data.map((host) => (
        <HostTableRow
          key={`tdevices/policies-hostsByPolicy-tr-${host.id}`}
          data={host}
        />
      ))}
    </div>
  );
};

export default HostsByPolicyTable;
