import {
  type InputHTMLAttributes,
  type TextareaHTMLAttributes,
  forwardRef,
} from "react";
import { twMerge } from "tailwind-merge";

type InputVariant = "primary" | "warning";

const variants: { [Variant in InputVariant]: string } = {
  primary: "bg-bright-grey outline-transparent hover:outline-light-grey",
  warning: "bg-button-warning-bg outline-warning text-warning",
};

const defaultStyles =
  "px-3 py-1.5 m-0.5 w-[calc(100%_-_0.25rem)] rounded-md outline outline-2 caret-subcard-blue focus:outline-primary duration-150 disabled:text-dark-blue disabled:hover:outline-transparent";

const inputStyles = "h-11 min-h-[2.75rem]";

interface IInputProps extends InputHTMLAttributes<HTMLInputElement> {
  variant?: InputVariant;
}

export const Input = forwardRef<HTMLInputElement, IInputProps>(
  ({ variant = "primary", ...props }, ref) => {
    return (
      <input
        ref={ref}
        {...props}
        placeholder={props.disabled ? undefined : props.placeholder}
        className={twMerge(
          defaultStyles,
          inputStyles,
          variants[variant],
          props.className
        )}
      />
    );
  }
);

Input.displayName = "Input";

interface ITextAreaProps extends TextareaHTMLAttributes<HTMLTextAreaElement> {
  variant?: InputVariant;
}

export const TextArea = forwardRef<HTMLTextAreaElement, ITextAreaProps>(
  ({ variant = "primary", ...props }, ref) => {
    return (
      <textarea
        ref={ref}
        {...props}
        placeholder={props.disabled ? undefined : props.placeholder}
        className={twMerge(defaultStyles, variants[variant], props.className)}
        rows={props.rows ? props.rows : 3}
      />
    );
  }
);

TextArea.displayName = "TextArea";
