// Libraries
import { type ReactNode, useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import dayjs from "dayjs";
import { Tabs } from "@lockerpm/design";

// Resources
import { ReactComponent as LogoCyStack } from "#src/assets/images/logos/logo-cystack.svg";
import { ReactComponent as CheckLine } from "#src/assets/images/icons/check-line.svg";
import { ReactComponent as CloseLine } from "#src/assets/images/icons/close-line.svg";
import { ReactComponent as PauseCircleLine } from "#src/assets/images/icons/pause-circle-line.svg";
import { ReactComponent as CloseCircleLine } from "#src/assets/images/icons/close-circle-line.svg";

// Components
import { Button } from "#src/components/common/system/Button";
import { ContentSection, StickySection } from "#src/layouts/content";
import LoadingState from "#src/components/common/system/LoadingState";
import { toPascalCase } from "#src/utils/common";
import TabButtonLabel from "#src/components/common/helper/antdProps/Tabs/TabButtonLabel";
import { apiErrorHandler } from "#src/utils/apiErrorHandler";

// API-related
import msspPentestServices from "#src/services/mssp/pentest";
import type { IPentestProjectDetails } from "#src/services/pentest";
import { MsspPentestDetailTabEnum } from "#src/components/mssp/pentest/details/enums";

// Children
import DetailsScope from "#src/components/mssp/pentest/details/ScopeTab";
import DetailsPolicy from "#src/components/mssp/pentest/details/PolicyTab";
import DetailsUpdates from "#src/components/mssp/pentest/details/UpdatesTab";
import DetailsMembers from "#src/components/mssp/pentest/details/MembersTab";
import DetailsOrganization from "#src/components/mssp/pentest/details/OrganizationTab";
import { getColorByPentestStatus } from "#src/components/pentest/color";

const InfoItemLabel = ({ name, value }: { name: string; value: ReactNode }) => {
  return (
    <div className="text-hard-grey whitespace-nowrap flex gap-2 items-center">
      {name}
      <span className="text-dark-blue ml-2 font-medium-16">{value}</span>
    </div>
  );
};

const MsspPentestDetails = () => {
  const { t } = useTranslation("pentest", { keyPrefix: "page.pentestDetails" });
  const { workspace: workspaceId, pentest: pentestAlias } = useParams<
    "workspace" | "pentest"
  >();

  const [activeTab, setActiveTab] = useState<string>(
    MsspPentestDetailTabEnum.ORGANIZATION
  );

  const [pentestDetails, setPentestDetails] =
    useState<IPentestProjectDetails | null>(null);

  const [isLoadingPentest, setLoadingPentest] = useState<boolean>(false);

  const fetchPentestDetails = useCallback(() => {
    if (workspaceId && pentestAlias) {
      setLoadingPentest(true);
      msspPentestServices
        .retrieve_mssp_pentest_project_details(workspaceId, pentestAlias)
        .then((response) => {
          setPentestDetails(response);
          setLoadingPentest(false);
        })
        .catch((error) => {
          apiErrorHandler(error, {
            toastMessage: t("notification.loadPentest.fail"),
          });
        });
    }
  }, [workspaceId, pentestAlias, t]);

  const onChangeTab = (key: string) => {
    setActiveTab(key);
  };

  const tabList: { key: string; label: ReactNode; children: ReactNode }[] =
    pentestDetails && workspaceId && pentestAlias
      ? [
          {
            key: MsspPentestDetailTabEnum.ORGANIZATION,
            label: <TabButtonLabel name={t("tab.organization.title")} />,
            children: <DetailsOrganization pentestDetails={pentestDetails} />,
          },
          {
            key: MsspPentestDetailTabEnum.SCOPE,
            label: <TabButtonLabel name={t("tab.scope.title")} />,
            children: <DetailsScope pentestDetails={pentestDetails} />,
          },
          {
            key: MsspPentestDetailTabEnum.POLICY,
            label: <TabButtonLabel name={t("tab.policy.title")} />,
            children: <DetailsPolicy pentestDetails={pentestDetails} />,
          },
          {
            key: MsspPentestDetailTabEnum.UPDATES,
            label: <TabButtonLabel name={t("tab.updates.title")} />,
            children: (
              <DetailsUpdates
                workspaceId={workspaceId}
                pentestAlias={pentestAlias}
              />
            ),
          },
          {
            key: MsspPentestDetailTabEnum.MEMBERS,
            label: <TabButtonLabel name={t("tab.members.title")} />,
            children: (
              <DetailsMembers
                workspaceId={workspaceId}
                pentestAlias={pentestAlias}
              />
            ),
          },
        ]
      : [];

  const onAcceptRequest = () => {
    if (workspaceId && pentestAlias) {
      msspPentestServices
        .retrieve_mssp_pentest_project_details(workspaceId, pentestAlias)
        .then(() => {
          msspPentestServices
            .update_mssp_pentest_project(workspaceId, pentestAlias, {
              status: "execution",
            })
            .then((response) => {
              if (response.success) {
                fetchPentestDetails();
              }
            });
        });
    }
  };

  const onRejectRequest = () => {
    if (workspaceId && pentestAlias) {
      msspPentestServices
        .retrieve_mssp_pentest_project_details(workspaceId, pentestAlias)
        .then(() => {
          msspPentestServices
            .update_mssp_pentest_project(workspaceId, pentestAlias, {
              status: "draft",
            })
            .then((response) => {
              if (response.success) {
                fetchPentestDetails();
              }
            });
        });
    }
  };

  const onPausePentestProject = () => {
    if (workspaceId && pentestAlias) {
      msspPentestServices
        .retrieve_mssp_pentest_project_details(workspaceId, pentestAlias)
        .then(() => {
          msspPentestServices
            .update_mssp_pentest_project(workspaceId, pentestAlias, {
              status: "pause",
            })
            .then((response) => {
              if (response.success) {
                fetchPentestDetails();
              }
            });
        });
    }
  };

  const onClosePentestProject = () => {
    if (workspaceId && pentestAlias) {
      msspPentestServices
        .retrieve_mssp_pentest_project_details(workspaceId, pentestAlias)
        .then(() => {
          msspPentestServices
            .update_mssp_pentest_project(workspaceId, pentestAlias, {
              status: "closure",
            })
            .then((response) => {
              if (response.success) {
                fetchPentestDetails();
              }
            });
        });
    }
  };

  const onContinuePentestProject = () => {
    if (workspaceId && pentestAlias) {
      msspPentestServices
        .retrieve_mssp_pentest_project_details(workspaceId, pentestAlias)
        .then(() => {
          msspPentestServices
            .update_mssp_pentest_project(workspaceId, pentestAlias, {
              status: "execution",
            })
            .then((response) => {
              if (response.success) {
                fetchPentestDetails();
              }
            });
        });
    }
  };

  useEffect(() => {
    let ignore = false;

    if (!ignore) {
      fetchPentestDetails();
    }

    return () => {
      ignore = true;
    };
  }, [fetchPentestDetails]);

  return workspaceId && pentestAlias && pentestDetails ? (
    isLoadingPentest ? (
      <LoadingState />
    ) : (
      <>
        <StickySection>
          <h1>{pentestDetails.org.name}</h1>
          <div className="flex gap-1">
            {pentestDetails.status.toLowerCase() === "requested" ? (
              <>
                <Button
                  variant="secondary"
                  size="large"
                  onClick={onAcceptRequest}
                >
                  <CheckLine height={"1.25rem"} width={"1.25rem"} />
                  {t("button.accept")}
                </Button>
                <Button
                  variant="warning"
                  size="large"
                  onClick={onRejectRequest}
                >
                  <CloseLine height={"1.25rem"} width={"1.25rem"} />
                  {t("button.reject")}
                </Button>
              </>
            ) : null}
            {pentestDetails.status.toLowerCase() === "execution" ? (
              <>
                <Button
                  variant="secondary"
                  size="large"
                  onClick={onPausePentestProject}
                >
                  <PauseCircleLine height={"1.25rem"} width={"1.25rem"} />
                  {t("button.pause")}
                </Button>
                <Button
                  variant="warning"
                  size="large"
                  onClick={onClosePentestProject}
                >
                  <CloseCircleLine height={"1.25rem"} width={"1.25rem"} />
                  {t("button.close")}
                </Button>
              </>
            ) : null}
            {/* Status closure has no buttons */}
            {/* At status pause this screen has no permission */}
            {pentestDetails.status.toLowerCase() === "pause" ? (
              <>
                <Button
                  variant="secondary"
                  size="large"
                  onClick={onContinuePentestProject}
                >
                  {t("button.continue")}
                </Button>
                <Button
                  variant="warning"
                  size="large"
                  onClick={onClosePentestProject}
                >
                  {t("button.close")}
                </Button>
              </>
            ) : null}
          </div>
        </StickySection>
        <ContentSection>
          <section className="p-6 border border-grey rounded-md flex flex-wrap gap-x-10 gap-y-3">
            <InfoItemLabel
              name={t("info.mssp")}
              value={
                <div className="flex items-center gap-2">
                  <LogoCyStack className="h-[1.125rem] w-[1.125rem]" />
                  {pentestDetails.mssp ? pentestDetails.mssp.name : "---"}
                </div>
              }
            />
            <InfoItemLabel
              name={t("info.status")}
              value={
                <div className="flex items-center gap-2">
                  <div
                    className={`w-2 h-2 rounded-sm rotate-45 ${getColorByPentestStatus(
                      pentestDetails.status
                    )}`}
                  ></div>
                  {toPascalCase(pentestDetails.status)}
                </div>
              }
            />
            <InfoItemLabel
              name={t("info.vulnerabilities")}
              value={pentestDetails.vulnerabilities}
            />
            <InfoItemLabel
              name={t("info.requestedDate")}
              value={dayjs
                .unix(pentestDetails.created_time)
                .format("MMMM D, YYYY")}
            />
          </section>
          <Tabs
            items={tabList}
            activeKey={activeTab}
            onChange={onChangeTab}
            destroyInactiveTabPane
          />
        </ContentSection>
      </>
    )
  ) : null;
};

export default MsspPentestDetails;
