import { forwardRef, type HTMLAttributes } from "react";
import { twMerge } from "tailwind-merge";

interface ITableDataCellProps extends HTMLAttributes<HTMLDivElement> {}

/** A Table Cell made by adding default CSS to extend div Element. */
const TableDataCell = forwardRef<HTMLDivElement, ITableDataCellProps>(
  (props, ref) => {
    return (
      <div
        ref={ref}
        {...props}
        className={twMerge(
          `bg-inherit py-5 px-4 border-b border-light-grey flex items-center gap-1.5 justify-start text-left ${
            props.onClick ? "cursor-pointer" : ""
          }`,
          props.className
        )}
      >
        {props.children}
      </div>
    );
  }
);

TableDataCell.displayName = "TableDataCell";

export default TableDataCell;
