/** @type {import('tailwindcss').Config} */
export default {
  content: ["./src/**/*.{js,jsx,ts,tsx}"],
  theme: {
    extend: {
      colors: {
        primary: "#3C4EF8",
        "bright-grey": "#F7F9FB",
        "bright-grey-subline": "#EFEFF2",
        "light-grey": "#EEEEEE",
        grey: "#D7D7D7",
        "medium-grey": "#9595A5",
        "hard-grey": "#646478",
        "bright-blue": "#EDF5FC",
        "label-blue": "#D9EDFF",
        "subcard-blue": "#5A7FFF",
        "dark-blue": "#18181C",
        "grey-blue": "#363643",
        warning: {
          DEFAULT: "#E20D27",
          safe: "#1BC185",
          "dark-red": "#AC0C0A",
          red: {
            danger: "#E20D27",
            "40%": "#F49EA9",
            "10%": "#F8E7E9",
            "5%": "#FBF3F4",
          },
        },
        button: {
          hover: "#000F97",
          secondary: {
            DEFAULT: "#F6F6FD",
            hover: "#EBEDFD",
          },
          disabled: "#E7E8EA",
          warning: {
            bg: "#FBF3F4",
            hover: "#F8E7E9",
          },
        },
        wine: "#BB4040",
        red: "#E45B59",
        fire: "#EE9550",
        yellow: "#F1D064",
        blue: "#7FBAF6",
        silver: "#D9DAE1",
        sky: "#C5E2FF",
        green: "#B3EC7A",
        note: "#FFE2CB",
        support: {
          teal: "#3BA69F",
          pink: {
            DEFAULT: "#E391C7",
            pastel: "#FFE4E6",
          },
          purple: {
            DEFAULT: "#8766E7",
            pastel: "#F1EDFC",
          },
          blue: {
            DEFAULT: "#3699F4",
            pastel: "#E7F3FE",
          },
          "fire-pastel": "#FFF1E5",
          green: "#4A9005",
          seafoam: {
            DEFAULT: "#10A871",
            pastel: "#E8F9F3",
          },
          "yellow-pastel": "#FCF4DA",
        },
        policy: {
          failed: "#CA1816",
          expire: {
            in30days: "#DA6F1C",
            in2months: "#D8A912",
          },
        },
        "mssp-sidebar": "#2B2C32",
        status: {
          new: "#C5E2FF",
          triaged: "#7FBAF6",
          resolved: "#B3EC7A",
          unresolved: "#E45B59",
          duplicate: "#EE9550",
          rejected: "#9595A5",
        },
        severity: {
          critical: {
            DEFAULT: "#861010",
            bg: "#F2BFBF",
          },
          high: {
            DEFAULT: "#E20D27",
            bg: "#FFD9DD",
          },
          medium: {
            DEFAULT: "#D55D00",
            bg: "#FFE5D1",
          },
          low: {
            DEFAULT: "#D0980A",
            bg: "#FFF1C3",
          },
          information: {
            DEFAULT: "#3699F4",
            bg: "#E7F3FE",
          },
          none: {
            DEFAULT: "#646478",
            bg: "#EEEEEE",
          },
        },
      },
    },
  },
  plugins: [],
  corePlugins: {
    preflight: false,
  },
};
