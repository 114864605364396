// Libraries
import { useTranslation } from "react-i18next";

// Components
import LoadingState from "#src/components/common/system/LoadingState";
import NoResultsState from "#src/components/common/states/NoResultsState";
import TableHeader, {
  type ITableHeaderPropItem,
} from "#src/components/common/Table/TableHeader";
import { useCurrentWorkspaceRole } from "#src/hooks/useCurrentWorkspaceRole";

// Children
import type { ITrustCenterComplianceItem } from "#src/services/trustCenter/compliance";
import TrustCenterComplianceTableRow from "./TableRow";

interface ITrustCenterComplianceTableGridProps {
  workspaceId: string;
  complianceList: ITrustCenterComplianceItem[] | null;
  isLoading: boolean;
  updateComplianceList: () => void;
}

const TrustCenterComplianceTableGrid = ({
  workspaceId,
  complianceList,
  isLoading,
  updateComplianceList,
}: ITrustCenterComplianceTableGridProps) => {
  const { t } = useTranslation("trustCenter", { keyPrefix: "compliance" });

  const headers: ITableHeaderPropItem[] = [
    { name: t("headers.standard"), align: "left" },
    { name: t("headers.status"), align: "left" },
  ];

  const workspaceRole = useCurrentWorkspaceRole(workspaceId);

  return (
    <>
      {isLoading || complianceList === null ? (
        <LoadingState />
      ) : complianceList.length === 0 ? (
        <NoResultsState />
      ) : (
        <div className="grid grid-cols-[7fr_2fr]">
          <TableHeader
            title="trustCenter/compliance"
            headerProps={headers}
            cellClassName="px-9 py-5"
          />
          {complianceList.map((item) => (
            <TrustCenterComplianceTableRow
              key={`trustCenter-compliance-item${item.id}`}
              workspaceId={workspaceId}
              data={item}
              workspaceRole={workspaceRole}
              updateComplianceList={updateComplianceList}
            />
          ))}
        </div>
      )}
    </>
  );
};

export default TrustCenterComplianceTableGrid;
