// Libraries
import { useSortable } from "@dnd-kit/sortable";
import { type HTMLAttributes, forwardRef, CSSProperties } from "react";
import { CSS } from "@dnd-kit/utilities";
import type { ItemType } from "@lockerpm/design/es/menu/hooks/useItems";
import { DraggableAttributes } from "@dnd-kit/core";
import { SyntheticListenerMap } from "@dnd-kit/core/dist/hooks/utilities";
import { Dropdown, Popover, Typography } from "@lockerpm/design";

// Resources
import { ReactComponent as ExpandUpDownFill } from "#src/assets/images/icons/expand-up-down-fill.svg";
import { ReactComponent as MoreLine } from "#src/assets/images/icons/more-line.svg";

// Components
import { toPascalCase } from "#src/utils/common";
import {
  TableCheckbox,
  TableDataCell,
  TableRowWrapper,
} from "#src/components/common/Table";
import { RoundedTag } from "#src/components/common/Tags";
import { IconButton } from "#src/components/common/system/Button";

// API-related
import type { IControlItem } from "#src/services/devices/control";

interface IControlTableRowProps {
  data: IControlItem;
  actionList: ItemType[];
  onClickActionItems: (key: string, item: IControlItem) => void;
  selectedControlIds: string[];
  onChangeSelectedControl: (selected: IControlItem) => void;
  disableSort: boolean;
}

export const SortableControlTableRow = (props: IControlTableRowProps) => {
  const {
    attributes,
    listeners,
    isDragging,
    setNodeRef,
    transform,
    transition,
  } = useSortable({ id: props.data.id, disabled: props.disableSort });

  const style = {
    opacity: isDragging ? 0 : undefined,
    transform: CSS.Transform.toString(transform),
    transition,
  };

  return (
    <ControlTableRow
      ref={setNodeRef}
      {...props}
      containerStyle={style}
      attributes={attributes}
      listeners={listeners}
    />
  );
};

export const ControlTableRow = forwardRef<
  HTMLDivElement | HTMLAnchorElement,
  IControlTableRowProps & {
    containerStyle?: CSSProperties;
    attributes?: DraggableAttributes;
    listeners?: SyntheticListenerMap;
  } & HTMLAttributes<HTMLElement>
>(
  (
    {
      data,
      actionList,
      onClickActionItems,
      selectedControlIds,
      onChangeSelectedControl,
      disableSort,
      containerStyle,
      attributes,
      listeners,
      ...props
    },
    ref
  ) => {
    const getActionLabel = (actionString: string) => {
      const splitted = actionString.split("_");
      return toPascalCase(splitted[0]);
    };

    const hosts = data.hosts_tags.filter((item) => item.type === "host");
    const tags = data.hosts_tags.filter((item) => item.type === "tag");

    return (
      <TableRowWrapper ref={ref} style={containerStyle} {...props}>
        <TableDataCell className="p-0 justify-center">
          <TableCheckbox
            checked={selectedControlIds.includes(data.id)}
            onClick={() => {
              onChangeSelectedControl(data);
            }}
          />
        </TableDataCell>
        <TableDataCell {...attributes} {...listeners}>
          {disableSort ? null : (
            <div className="w-full h-full cursor-grab active:cursor-grabbing flex justify-center items-center">
              <ExpandUpDownFill className="opacity-0 group-hover:opacity-100 group-active:opacity-100 fill-hard-grey" />
            </div>
          )}
        </TableDataCell>
        <TableDataCell>{getActionLabel(data.action)}</TableDataCell>
        <TableDataCell>
          {data.matches.length >= 1 ? (
            <div className="px-3 py-2 border border-grey rounded-xl">
              {data.matches[0]}
            </div>
          ) : null}
          {data.matches.length >= 2 ? (
            <Popover
              placement="bottomLeft"
              content={
                <div className="flex gap-1 flex-wrap max-w-[40rem]">
                  {data.matches.slice(1).map((item) => (
                    <div
                      key={`control-table-targets-popover-${item}`}
                      className="px-3 py-2 border border-grey rounded-xl"
                    >
                      {item}
                    </div>
                  ))}
                </div>
              }
            >
              <div className="px-3 py-2 border border-grey rounded-xl">
                {"+" + (data.matches.length - 1)}
              </div>
            </Popover>
          ) : null}
        </TableDataCell>
        <TableDataCell>
          {data.source ? toPascalCase(data.source) : "--"}
        </TableDataCell>
        <TableDataCell>
          <div className="flex flex-col gap-3">
            {hosts.length ? (
              <Typography.Text
                ellipsis={{
                  tooltip: hosts.map((host) => host.name).join(", "),
                }}
              >
                {hosts.map((host) => host.name).join(", ")}
              </Typography.Text>
            ) : null}
            {tags.length ? (
              <div className="flex flex-wrap gap-1">
                {tags.map((tag) => (
                  <RoundedTag
                    key={`control-table-row${data.id}-tag-${tag.id}`}
                    name={tag.name}
                  />
                ))}
              </div>
            ) : null}
          </div>
        </TableDataCell>
        <TableDataCell>{data.description}</TableDataCell>
        <TableDataCell className="p-0 justify-center">
          <Dropdown
            menu={{
              items: actionList,
              onClick: ({ key, domEvent }) => {
                domEvent.preventDefault();
                domEvent.stopPropagation();
                onClickActionItems(key, data);
              },
            }}
            trigger={["click"]}
          >
            <IconButton
              variant="ghost"
              size={9}
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
              }}
            >
              <MoreLine />
            </IconButton>
          </Dropdown>
        </TableDataCell>
      </TableRowWrapper>
    );
  }
);

ControlTableRow.displayName = "ControlTableRow";
