import { useEffect, useState } from "react";
import workspaceServices from "#src/services/workspace";
import { apiErrorHandler } from "#src/utils/apiErrorHandler";

export const useCurrentWorkspaceRole = (workspaceId: string | undefined) => {
  const [currentRole, setCurrentRole] = useState<string>();

  useEffect(() => {
    if (workspaceId) {
      workspaceServices
        .retrieve_workspace(workspaceId)
        .then((response) => {
          setCurrentRole(response.role);
        })
        .catch((error) => {
          // No i18n since this should not be something related to user flow.
          // TODO: change this into alert or different toast or something
          apiErrorHandler(error, {
            toastMessage: "cannot access workspace list",
          });
        });
    }
  }, [workspaceId]);

  return currentRole;
};
