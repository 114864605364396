// Libraries
import { useEffect, useState } from "react";
import { Switch } from "@lockerpm/design";
import {
  generatePath,
  Link,
  matchPath,
  useMatches,
  useNavigate,
  useParams,
} from "react-router-dom";
import { useTranslation } from "react-i18next";

// Resources
import { ReactComponent as ArrowDownSLine } from "#src/assets/images/icons/arrow-down-s-line.svg";
import { ReactComponent as Settings4Line } from "#src/assets/images/icons/settings-4-line.svg";

// General
import { helperPathname, pathname } from "#src/config/pathname";
import { updatePlan } from "#src/store/slices/trustCenter";

// Components
import { Accordion } from "#src/components/common/library/Accordion";
import { useAppDispatch, useAppSelector } from "#src/store/hooks";
import { ObjectImage } from "#src/components/common/system/Object";
import { apiErrorHandler } from "#src/utils/apiErrorHandler";

// API-related
import trustCenterServices from "#src/services/trustCenter";

// Children
import WorkspaceSelect from "./WorkspaceSelect";
import { getMainMenuRoutes, msspMenuRoutes } from "./routeList";
import { accountSettingsPaths, msspPaths } from "../pathConfig";

const LayoutSidebarMainMenu = () => {
  // We are using i18n here, so we need to call the useTranslation to let it update the texts when changing languages
  useTranslation("general", { keyPrefix: "sidebar" });

  const { selectedWorkspace, workspaces } = useAppSelector(
    (state) => state.workspace
  );
  const trustCenterPlan = useAppSelector((state) => state.trustCenter.plan);
  const dispatch = useAppDispatch();

  const navigate = useNavigate();

  const { workspace: workspaceId } = useParams<{ workspace: string }>();

  const pathMatches = useMatches();

  const isAccountSettings = pathMatches.some((match) =>
    accountSettingsPaths.some((path) => matchPath(path, match.pathname))
  );

  const isOnMsspMode = pathMatches.some((match) =>
    msspPaths.some((path) => matchPath(path, match.pathname))
  );

  const currentWorkspace = workspaces.find((ws) => ws.id === selectedWorkspace);

  const [expandedMenu, setExpandedMenu] = useState<string[]>([]);

  useEffect(() => {
    if (workspaceId) {
      trustCenterServices
        .retrieve_trust_center_plan(workspaceId)
        .then((response) => {
          dispatch(updatePlan(response.product_plan_id));
        })
        .catch(apiErrorHandler);
    } else {
      throw Error("Invalid workspace parameter");
    }
  }, [workspaceId, dispatch]);

  // Open Trust Center box when trustCenterPlan is changed. This helps people recognize what's possible to use, although to be honest this won't be that helpful
  useEffect(() => {
    if (trustCenterPlan) {
      setExpandedMenu((prev) => [...prev, helperPathname.TRUST_CENTER]);
    }
  }, [trustCenterPlan]);

  // TODO: break this into smaller chunks?
  return (
    <div
      style={{ scrollbarGutter: "stable" }}
      className={`flex flex-col h-full ${
        isOnMsspMode ? "bg-mssp-sidebar" : "bg-dark-blue"
      } text-white overflow-y-auto ${
        isAccountSettings ? "py-6 pl-6 pr-2 w-fit" : "w-full"
      } transition-all`}
    >
      <>
        <div
          className={`flex flex-col gap-6 sticky top-0 z-10 ${
            isAccountSettings ? "" : "p-6"
          } ${isOnMsspMode ? "bg-mssp-sidebar" : "bg-dark-blue"}`}
        >
          {currentWorkspace?.type === "mssp" && !isAccountSettings ? (
            <div className="flex justify-between gap-1 font-medium-16">
              MSSP
              <Link
                to={
                  isOnMsspMode
                    ? // This is default first page on normal mode
                      `/${selectedWorkspace}/assets/domains`
                    : // This is default first page on mssp mode
                      `/mssp/${selectedWorkspace}/pentest`
                }
              >
                <Switch checked={isOnMsspMode} className="bg-button-disabled" />
              </Link>
            </div>
          ) : null}
          {isAccountSettings ? (
            <div className="p-3">
              <ObjectImage
                data={currentWorkspace?.logo}
                className="h-6 w-6 min-w-[1.5rem] rounded-md"
              />
            </div>
          ) : (
            <div className="flex gap-1 justify-center items-center">
              <WorkspaceSelect />
              <button
                className="h-11 w-11 min-h-[2.75rem] min-w-[2.75rem] flex items-center justify-center rounded-md"
                onClick={() => {
                  navigate(
                    generatePath(pathname.WORKSPACE_DETAILS, {
                      workspace: selectedWorkspace,
                    })
                  );
                }}
              >
                <Settings4Line />
              </button>
            </div>
          )}
        </div>
      </>
      <div
        className={`flex flex-col gap-6 pb-6 justify-between ${
          isAccountSettings ? "" : "px-6"
        }`}
      >
        {(isOnMsspMode
          ? msspMenuRoutes
          : getMainMenuRoutes({ trustCenterPlan })
        ).map((group) => {
          return (
            <div
              key={`sidebar-menu-group-${group.key}`}
              className="flex flex-col gap-3"
            >
              <h4 className="overflow-hidden whitespace-nowrap text-ellipsis">
                {isAccountSettings ? (
                  <span className="w-11 flex justify-center">-</span>
                ) : (
                  group.getLabel()
                )}
              </h4>
              {group.routes.map((page) => {
                const active = pathMatches.some((match) =>
                  matchPath(page.pathname, match.pathname)
                );
                const expanded =
                  expandedMenu.includes(page.pathname) && !isAccountSettings;

                return (
                  <Accordion
                    key={`sidebar-menu-page-${page.pathname}`}
                    expanded={expanded}
                    label={
                      <button
                        className={`relative w-full py-3 flex justify-between rounded-md ${
                          active ? "bg-hard-grey" : "hover:bg-grey-blue"
                        } ${
                          isAccountSettings ? "px-3" : "px-6"
                        } duration-300 hover:duration-150 group`}
                        onClick={() => {
                          if (!page.children?.length) {
                            if (workspaceId) {
                              navigate(
                                generatePath(page.pathname, {
                                  workspace: workspaceId,
                                })
                              );
                            } else {
                              console.log(
                                "No workspace param. This should not happen."
                              );
                            }
                            return;
                          }
                          if (isAccountSettings) {
                            if (workspaceId) {
                              navigate(
                                generatePath(page.children[0].pathname, {
                                  workspace: workspaceId,
                                })
                              );
                              setExpandedMenu([page.pathname]);
                            } else {
                              console.log(
                                "No workspace param. This should not happen."
                              );
                            }
                            return;
                          }
                          if (!expanded) {
                            setExpandedMenu((prev) => [...prev, page.pathname]);
                            return;
                          }
                          setExpandedMenu((prev) =>
                            prev.filter((item) => item !== page.pathname)
                          );
                        }}
                      >
                        {/* This is the mark on the left of the button. Not visible on default state, so try hover or active state to see it. */}
                        <span
                          className={`opacity-0 group-hover:opacity-100 absolute left-0 top-1/2 -translate-y-1/2 h-7 min-w-[0.25rem] ${
                            active
                              ? "bg-light-grey opacity-100"
                              : "bg-hard-grey"
                          } duration-300 hover:duration-150 rounded-r-full`}
                        />
                        <div
                          className={`flex gap-3 ${
                            page.children?.length
                              ? "w-[calc(100%_-_1.25rem)]"
                              : "w-full"
                          }`}
                        >
                          <span className="h-5">{page.icon}</span>
                          {isAccountSettings ? null : (
                            <span className="font-medium-16 overflow-hidden whitespace-nowrap text-ellipsis">
                              {page.getLabel()}
                            </span>
                          )}
                        </div>
                        {page.children?.length && !isAccountSettings ? (
                          <span className="h-5">
                            <ArrowDownSLine
                              className={`${
                                expanded ? "rotate-180" : ""
                              } duration-300 ease-out w-5 h-5 min-w-[1.25rem] min-h-[1.25rem]`}
                            />
                          </span>
                        ) : null}
                      </button>
                    }
                  >
                    {isAccountSettings ? null : (
                      <div className="flex flex-col gap-1.5 pt-3">
                        {page.children?.map((subpage) => {
                          const active = pathMatches.some((match) =>
                            matchPath(subpage.pathname, match.pathname)
                          );

                          return (
                            <button
                              key={`sidebar-menu-subpage-${subpage.pathname}`}
                              className={`h-11 pl-14 py-0.5 rounded-md ${
                                active
                                  ? "text-subcard-blue bg-primary bg-opacity-[16%] font-bold-14 hover:text-subcard-blue hover:cursor-default"
                                  : "text-medium-grey bg-dark-blue font-regular-14 hover:font-bold-14 hover:text-white"
                              } text-left duration-300 hover:duration-150 overflow-hidden whitespace-nowrap text-ellipsis no-underline flex items-center`}
                              onClick={() => {
                                if (workspaceId) {
                                  navigate(
                                    generatePath(subpage.pathname, {
                                      workspace: workspaceId,
                                    })
                                  );
                                } else {
                                  console.log(
                                    "No workspace param. This should not happen."
                                  );
                                }
                              }}
                            >
                              {subpage.getLabel()}
                            </button>
                          );
                        })}
                      </div>
                    )}
                  </Accordion>
                );
              })}
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default LayoutSidebarMainMenu;
