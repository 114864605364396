export const ScanStatusResponseValue = {
  PENDING: "pending",
  RUNNING: "running",
  COMPLETED: "completed",
  STOPPING: "stopping",
  STOPPED: "stopped",
};

export const ScanFrequencyResponseValue = {
  ONCE: "once",
  DAILY: "daily",
  WEEKLY: "weekly",
  MONTHLY: "monthly",
};
