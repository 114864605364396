import endpoint from "#src/config/endpoint";
import request from "#src/utils/request";

export interface ISolutionFromApi {
  enabled: boolean;
  solution: {
    id: string;
    type: string;
  };
}
export function list_solutions(workspace_id: string) {
  return request.get<never, ISolutionFromApi[]>(
    `${endpoint.WORKSPACES}/${workspace_id}/solutions`
  );
}
