// Libraries
import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

// General
import constants from "#src/config/constants";
import type { IFilterItem } from "#src/@types/common";

// Components
import PaginationBar from "#src/components/common/helper/PaginationBar";
import LoadingState from "#src/components/common/system/LoadingState";
import { NoVulnerabilitiesState } from "#src/components/common/states";
import useFetchPaginated from "#src/hooks/useFetchPaginated";
import { useSyncSearchParam } from "#src/hooks/useSyncSearchParam";
import { createToast } from "#src/components/common/system/toasts";
import { apiErrorHandler } from "#src/utils/apiErrorHandler";

// Children
import VulnerabilityFilter from "./Filter";
import VulnerabilityTableGrid from "./Table";
import assetsServices from "#src/services/assets";
import type { IScanVulnerabilityItem } from "#src/services/scans/vulnerabilities";
import scansServices from "#src/services/scans";

interface IDetailVulnerabilityTabProps {
  workspaceId: string;
  scanId: number;
  refreshScanDetails: () => void;
  initSeverity: string | null;
  scannedAssetItems: IFilterItem[];
}

const ScanDetailsVulnerabilities = ({
  workspaceId,
  scanId,
  refreshScanDetails,
  initSeverity,
  scannedAssetItems,
}: IDetailVulnerabilityTabProps) => {
  const { t } = useTranslation("assets", {
    keyPrefix: "domains.page.details.tab.vulnerabilities",
  });

  const [selectedPage, setSelectedPage] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(constants.DEFAULT_PAGE_SIZE);

  // TODO: check this init state to make sure it matches the actual item list
  const [severity, setSeverity] = useState<IFilterItem | null>(
    initSeverity
      ? { key: initSeverity, value: initSeverity, getLabel: () => initSeverity }
      : null
  );
  const [cvssScore, setCvssScore] = useState<IFilterItem | null>(null);
  const [status, setStatus] = useState<IFilterItem[] | null>(null);
  const [selectedVulnerabilities, setSelectedVulnerabilities] = useState<
    IScanVulnerabilityItem[]
  >([]);
  const [domains, setDomains] = useState<IFilterItem | null>(null);
  const [searchKeyword, setSearchKeyword] = useState<string>("");

  const fetchParams = useMemo<
    Parameters<typeof scansServices.list_scan_vulnerabilities>
  >(
    () => [
      workspaceId,
      scanId,
      {
        filter_severity: severity?.value,
        cvss_score_from: cvssScore?.value?.split("-")[0] ?? undefined,
        cvss_score_to: cvssScore?.value?.split("-")[1] ?? undefined,
        filter_sub_status:
          status && status.length > 0
            ? status.map((item) => item.value || "").join(",")
            : undefined,
        q: searchKeyword,
        page: selectedPage,
        size: pageSize,
        domain_id: domains?.value,
      },
    ],
    [
      workspaceId,
      scanId,
      severity,
      cvssScore,
      status,
      domains,
      searchKeyword,
      selectedPage,
      pageSize,
    ]
  );

  const onChangeSeveritySelection = (selected: typeof severity) => {
    setSeverity(selected);
    setSelectedPage(1);
  };

  const onChangeCvssScoreSelection = (selected: typeof cvssScore) => {
    setCvssScore(selected);
    setSelectedPage(1);
  };

  const onChangeStatusSelection = (selected: typeof status) => {
    setStatus(selected);
    setSelectedPage(1);
  };

  const onChangeDomainsSelection = (selected: typeof domains) => {
    setDomains(selected);
    setSelectedPage(1);
  };

  const onChangePage = (page: number) => {
    setSelectedPage(page);
    setSelectedVulnerabilities([]);
  };

  const onChangePageSize = (size: number) => {
    setPageSize(size);
    setSelectedPage(1);
    setSelectedVulnerabilities([]);
  };

  const onChangeSearchKeyword = (keyword: string) => {
    setSearchKeyword(keyword);
    setSelectedPage(1);
  };

  const onChangeSelectedVulnerabilities = (
    selected: IScanVulnerabilityItem
  ) => {
    if (selectedVulnerabilities.map((item) => item.id).includes(selected.id)) {
      setSelectedVulnerabilities((prev) => [
        ...prev.filter((item) => item.id !== selected.id),
      ]);
    } else {
      setSelectedVulnerabilities((prev) => [...prev, selected]);
    }
  };

  const onSelectAllVulnerabilities = () => {
    if (vulnerabilityList) {
      setSelectedVulnerabilities(vulnerabilityList);
    }
  };

  const onDeselectAllVulnerabilities = () => {
    setSelectedVulnerabilities([]);
  };

  /** Used after vulnerability changes, such as adding and removing vulnerabilities */
  const refreshVulnerabilityList = () => {
    updateVulnerabilityList();
    refreshScanDetails();
  };

  const onEditSelectedVulnerabilites = (status: IFilterItem) => {
    if (status.value) {
      assetsServices
        .update_status_multiple_vulnerabilities(
          workspaceId,
          selectedVulnerabilities.map((item) => item.id),
          status.value
        )
        .then(() => {
          refreshVulnerabilityList();
          createToast({
            type: "success",
            message: t("notification.statusUpdated.success"),
          });
        })
        .catch((error) => {
          apiErrorHandler(error, {
            toastMessage: t("notification.statusUpdated.fail"),
          });
        });
      setSelectedVulnerabilities([]);
    }
  };

  useSyncSearchParam("severity", severity?.value ?? "");

  const {
    list: vulnerabilityList,
    count: vulnerabilityCount,
    isLoading,
    updateData: updateVulnerabilityList,
  } = useFetchPaginated(scansServices.list_scan_vulnerabilities, fetchParams);

  return (
    <section className="flex flex-col gap-6 pt-6">
      <VulnerabilityFilter
        domainItems={scannedAssetItems}
        severity={severity}
        cvssScore={cvssScore}
        status={status}
        domains={domains}
        searchKeyword={searchKeyword}
        totalCount={vulnerabilityCount}
        selectedCount={selectedVulnerabilities.length}
        onChangeSeveritySelection={onChangeSeveritySelection}
        onChangeCvssScoreSelection={onChangeCvssScoreSelection}
        onChangeStatusSelection={onChangeStatusSelection}
        onChangeDomainsSelection={onChangeDomainsSelection}
        onChangeSearchKeyword={onChangeSearchKeyword}
        onSelectAllVulnerabilities={onSelectAllVulnerabilities}
        onDeselectAllVulnerabilities={onDeselectAllVulnerabilities}
        onEditSelectedVulnerabilites={onEditSelectedVulnerabilites}
      />
      {vulnerabilityList === null || isLoading ? (
        <LoadingState />
      ) : vulnerabilityList.length === 0 ? (
        <NoVulnerabilitiesState />
      ) : (
        <VulnerabilityTableGrid
          workspaceId={workspaceId}
          scanId={scanId}
          vulnerabilities={vulnerabilityList}
          selectedVulnerabilityIds={selectedVulnerabilities.map(
            (item) => item.id
          )}
          onChangeSelectedVulnerabilities={onChangeSelectedVulnerabilities}
          refreshVulnerabilityList={refreshVulnerabilityList}
        />
      )}
      <PaginationBar
        numOfResult={vulnerabilityCount}
        selectedPage={selectedPage}
        pageSize={pageSize}
        onChangePage={onChangePage}
        onChangePageSize={onChangePageSize}
      />
    </section>
  );
};

export default ScanDetailsVulnerabilities;
