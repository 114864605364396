import type { Params, RouteObject } from "react-router-dom";
import i18next from "i18next";
import { helperPathname, pathname } from "#src/config/pathname";

import AllScansDashboard from "#src/pages/scans/all-scans";
import ScanDetails from "#src/pages/scans/all-scans/details";
import ScanSchedules from "#src/pages/scans/schedules";

const breadcrumbTFunc = i18next.getFixedT(null, "general", "breadcrumb");

export const scanRoute: RouteObject = {
  path: helperPathname.SCANS,
  handle: {
    crumb: () => ({
      getText: () => breadcrumbTFunc("scan.label"),
      path: pathname.ALL_SCANS,
    }),
  },
  children: [
    {
      path: pathname.ALL_SCANS,
      handle: {
        crumb: () => ({
          getText: () => breadcrumbTFunc("scan.allScans.label"),
          path: pathname.ALL_SCANS,
        }),
      },
      children: [
        {
          index: true,
          element: <AllScansDashboard />,
        },
        {
          path: pathname.SCAN_DETAILS,
          handle: {
            crumb: (params: Readonly<Params<string>>) => ({
              getText: () =>
                Object.hasOwn(params, "scan") ? params["scan"] : "---",
              path: pathname.SCAN_DETAILS,
            }),
          },
          element: <ScanDetails />,
        },
      ],
    },
    {
      path: pathname.SCHEDULES,
      handle: {
        crumb: () => ({
          getText: () => breadcrumbTFunc("scan.schedule.label"),
          path: pathname.SCHEDULES,
        }),
      },
      element: <ScanSchedules />,
    },
  ],
};
