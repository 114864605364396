// Libraries
import {
  generatePath,
  Link,
  matchPath,
  useMatches,
  useParams,
} from "react-router-dom";

// Resources
import { ReactComponent as LogoCyStackLettermark } from "#src/assets/images/logos/logo-cystack-lettermark.svg";
import { ReactComponent as UserLine } from "#src/assets/images/icons/user-line.svg";
import { ReactComponent as ArrowGoBackLine } from "#src/assets/images/icons/arrow-go-back-line.svg";

// General
import { pathname } from "#src/config/pathname";

// Children
import { accountSettingsPaths, msspPaths, noSidebarPaths } from "../pathConfig";

const SidebarHeader = () => {
  const { workspace: workspaceId } = useParams<{ workspace: string }>();

  const pathMatches = useMatches();

  const isAccountSettings = pathMatches.some((match) =>
    accountSettingsPaths.some((path) => matchPath(path, match.pathname))
  );

  const isOnMsspMode = pathMatches.some((match) =>
    msspPaths.some((path) => matchPath(path, match.pathname))
  );

  const noSidebarMode = pathMatches.some((match) =>
    noSidebarPaths.some((path) => matchPath(path, match.pathname))
  );

  const needBackButton = isAccountSettings && !noSidebarMode;

  return (
    <div
      className={`flex items-center ${
        isOnMsspMode ? "bg-mssp-sidebar" : "bg-dark-blue"
      } border-b border-grey-blue lg:max-w-[22rem] max-w-[16.75rem] lg:w-[22rem] w-[16.75rem] transition-all`}
    >
      <button className="w-full h-full pl-6 flex items-center">
        <LogoCyStackLettermark className="fill-white" />
      </button>
      {needBackButton ? (
        <button
          className="min-w-[4rem] px-6 w-16 h-16 border-x text-white hover:text-white flex items-center transition duration-150 bg-hard-grey border-hard-grey"
          onClick={() => {
            window.history.back();
          }}
        >
          <ArrowGoBackLine />
        </button>
      ) : workspaceId ? (
        <Link
          to={generatePath(pathname.ACCOUNT_SETTINGS, {
            workspace: workspaceId,
          })}
          className="min-w-[4rem] px-6 w-16 h-16 border-x text-white hover:text-white flex items-center transition duration-150 border-grey-blue"
        >
          <UserLine />
        </Link>
      ) : null}
    </div>
  );
};

export default SidebarHeader;
