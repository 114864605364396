// Libraries
import { useEffect, useState } from "react";
import { useParams, useSearchParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { AxiosError } from "axios";
import { z } from "zod";

// Resources
import { ReactComponent as ArrowRightLine } from "#src/assets/images/icons/arrow-right-line.svg";
import { ReactComponent as NoVulnerabilities } from "#src/assets/images/illustrations/no-vulnerabilities.svg";

// General
import patterns from "#src/config/patterns";

// Components
import { ContentSection, StickySection } from "#src/layouts/content";
import TabProgress, {
  type IFormProgressStep,
} from "#src/components/common/TabProgress";
import { Button } from "#src/components/common/system/Button";
import { skipToContentTop } from "#src/utils/common";
import { apiErrorHandler, parseBadRequest } from "#src/utils/apiErrorHandler";
import LoadingState from "#src/components/common/system/LoadingState";

// API-related
import paymentService, {
  type IPricingInput,
  type IPromotionCodeResponse,
  type IPaymentForm,
  type IValidPromotionCode,
} from "#src/services/payment";
import { PaymentMethodValue } from "#src/config/filter/workspace/value";

// Children
import {
  ContactFormSelection,
  jobTitleList,
} from "#src/components/trustCenter/onboard/ContactForm/contactDetailFields";
import FillForm from "#src/components/workspace/billing/renewSubscription/FillForm";
import CompletePayment from "#src/components/workspace/billing/renewSubscription/CompletePayment";

const WorkspaceBillingRenewSubscriptionPage = () => {
  const { t } = useTranslation("workspace", {
    keyPrefix: "billing.renewSubscription",
  });

  const { workspace: workspaceId } = useParams<{ workspace: string }>();
  const [searchParams] = useSearchParams();
  const filterProductId = searchParams.get("product_id");
  const filterExpiring = searchParams.get("expiring");

  const [defaultPaymentSettings, setDefaultPaymentSettings] = useState<Awaited<
    ReturnType<typeof paymentService.retrieve_workspace_payment_settings>
  > | null>(null);
  const [expiringPlans, setExpiringPlans] = useState<Awaited<
    ReturnType<typeof paymentService.list_workspace_payment_plans>
  > | null>(null);
  const [pricingInfo, setPricingInfo] = useState<Awaited<
    ReturnType<typeof paymentService.calculate_pricing>
  > | null>(null);

  // Current step of form filling
  const [formProgress, setFormProgress] = useState<number>(0);

  // Tab's states
  // Fill Form tab
  const [pricingForm, setPricingForm] = useState<IPricingInput>({
    currency: "VND",
    product_plans: [],
  });
  const [paymentForm, setPaymentForm] = useState<IPaymentForm>({
    payment_method: PaymentMethodValue.BANKING,
    customer: {
      full_name: "",
      phone_number: "",
      email: "",
      job_title: jobTitleList[0].value,
    },
    product_plans: [
      {
        product_plan_id: "tc_custom_domain",
        duration: "monthly",
      },
    ],
  });
  const [jobTitle, setJobTitle] = useState<ContactFormSelection>(
    jobTitleList[0]
  );

  const [paymentInfo, setPaymentInfo] = useState<Awaited<
    ReturnType<typeof paymentService.create_subscription_banking>
  > | null>(null);

  const [errorObj, setErrorObj] = useState<{
    full_name: string | undefined;
    phone_number: string | undefined;
    email: string | undefined;
  }>({ full_name: undefined, phone_number: undefined, email: undefined });

  const getPricingInfo = async (form: typeof pricingForm) => {
    if (workspaceId) {
      await paymentService
        .calculate_pricing(workspaceId, form)
        .then((response) => {
          // TODO: check error_promo
          setPricingInfo(response);
        })
        .catch(apiErrorHandler);
    }
  };

  // Typeguard for promotion code
  function isPromoValid(
    promo: IPromotionCodeResponse
  ): promo is IValidPromotionCode {
    return promo.error_promo === null;
  }

  const onApplyPromocode = async (value: string) => {
    if (!workspaceId) return false;

    return paymentService
      .check_promotion_code(workspaceId, { promo_code: value })
      .then((response) => {
        if (isPromoValid(response)) {
          const newPricingForm: typeof pricingForm = {
            ...pricingForm,
            product_plans: pricingForm.product_plans.map((plan) =>
              response.only_plan.includes(plan.product_plan_id) &&
              !plan.promo_code
                ? {
                    ...plan,
                    promo_code: value,
                  }
                : plan
            ),
          };
          setPricingForm(newPricingForm);
          getPricingInfo(newPricingForm);
          return true;
        }
        return false;
      })
      .catch(apiErrorHandler);
  };

  const onRemovePromocode = (removeId: String) => {
    const newPricingForm: typeof pricingForm = {
      ...pricingForm,
      product_plans: pricingForm.product_plans.map((plan) =>
        plan.product_plan_id === removeId
          ? {
              ...plan,
              promo_code: undefined,
            }
          : plan
      ),
    };
    setPricingForm(newPricingForm);
    getPricingInfo(newPricingForm);
  };

  const renewSteps: IFormProgressStep[] =
    workspaceId && expiringPlans
      ? [
          {
            title: t("fillForm.tabLabel"),
            element: (
              <>
                <FillForm
                  productPlans={expiringPlans}
                  pricingInfo={pricingInfo}
                  paymentForm={paymentForm}
                  setPaymentForm={setPaymentForm}
                  jobTitle={jobTitle}
                  setJobTitle={setJobTitle}
                  defaultPaymentSettings={defaultPaymentSettings}
                  onApplyPromocode={onApplyPromocode}
                  onRemovePromocode={onRemovePromocode}
                  errorObj={errorObj}
                  setErrorObj={setErrorObj}
                />
              </>
            ),
          },
          {
            title: t("payment.tabLabel"),
            element: (
              <CompletePayment
                workspaceId={workspaceId}
                paymentInfo={paymentInfo}
              />
            ),
          },
        ]
      : [];

  const onClickNextToCompletePayment = () => {
    if (
      workspaceId &&
      // TODO: better validate, send error to correspondent fields
      paymentForm.customer.full_name &&
      paymentForm.customer.email &&
      paymentForm.customer.phone_number
    ) {
      paymentService
        .renew_subscription_banking(workspaceId, paymentForm)
        .then((response) => {
          setFormProgress(1);
          skipToContentTop();
          setPaymentInfo(response);
        })
        .catch((error) => {
          if (
            error instanceof AxiosError &&
            error.response &&
            error.response.status === 400
          ) {
            const getErrorData = parseBadRequest(
              error.response.data,
              z.object({
                customer: z.object({
                  full_name: z.optional(z.array(z.string())),
                  phone_number: z.optional(z.array(z.string())),
                  email: z.optional(z.array(z.string())),
                }),
              })
            );
            setErrorObj((prev) => ({
              ...prev,
              full_name: getErrorData.details.customer.full_name
                ? getErrorData.details.customer.full_name[0]
                : undefined,
              phone_number: getErrorData.details.customer.phone_number
                ? getErrorData.details.customer.phone_number[0]
                : undefined,
              email: getErrorData.details.customer.email
                ? getErrorData.details.customer.email[0]
                : undefined,
            }));
          } else {
            apiErrorHandler(error);
          }
        });
    } else {
      // This should never happen since if this happens the button will be disabled already.
      console.log("Must fill the information to continue");
    }
  };

  useEffect(() => {
    if (workspaceId) {
      paymentService
        .retrieve_workspace_payment_settings(workspaceId)
        .then((response) => {
          setPaymentForm((prev) => ({
            ...prev,
            customer: {
              full_name: response.customer_full_name,
              phone_number: response.customer_phone_number,
              email: response.customer_email,
              job_title: response.customer_job_title,
            },
          }));
          // TODO: when triggering things, update them with this default value
          setDefaultPaymentSettings(response);
          const matchedJob = jobTitleList.find(
            (title) => title.value === response.customer_job_title
          );
          if (matchedJob) {
            setJobTitle(matchedJob);
          }
        })
        .catch(apiErrorHandler);
    }
  }, [workspaceId]);

  useEffect(() => {
    const initRenewList = async () => {
      if (workspaceId) {
        const renewList = await paymentService
          .list_workspace_payment_plans(workspaceId, {
            status: filterExpiring === "1" ? "expiring,expired" : undefined,
          })
          .then((response) => {
            return filterProductId
              ? response.filter((plan) => plan.product.id === filterProductId)
              : response;
          })
          .catch(apiErrorHandler);
        setExpiringPlans(renewList);
        paymentService
          .calculate_pricing(workspaceId, {
            currency: "VND",
            product_plans: renewList.map((p) => ({
              product_plan_id: p.product_plan.id,
              duration: p.duration,
            })),
          })
          .then((pricingResponse) => {
            // TODO: check error_promo
            setPricingInfo(pricingResponse);
          })
          .catch(apiErrorHandler);
      }
    };
    initRenewList();
  }, [workspaceId, filterProductId, filterExpiring]);

  return (
    <>
      <StickySection className="py-10 items-center max-w-[96rem]">
        <h1>{t("title")}</h1>
        {expiringPlans === null || expiringPlans.length === 0 ? null : (
          <div className="flex gap-1">
            {formProgress === 0 ? (
              <>
                <Button
                  size="large"
                  disabled={
                    !paymentForm.customer.full_name ||
                    !paymentForm.customer.email ||
                    !paymentForm.customer.phone_number ||
                    !patterns.EMAIL.test(paymentForm.customer.email) ||
                    !patterns.PHONE.test(paymentForm.customer.phone_number)
                  }
                  onClick={onClickNextToCompletePayment}
                >
                  {t("button.continue")}
                  <ArrowRightLine className="h-5 w-5" />
                </Button>
              </>
            ) : null}
          </div>
        )}
      </StickySection>
      <ContentSection className="max-w-[96rem]">
        {expiringPlans === null ? (
          <LoadingState />
        ) : expiringPlans.length === 0 ? (
          <div className="flex flex-col items-center gap-3">
            <NoVulnerabilities />
            <span>{t("nothingToRenew")}</span>
          </div>
        ) : (
          <TabProgress steps={renewSteps} progress={formProgress} />
        )}
      </ContentSection>
    </>
  );
};

export default WorkspaceBillingRenewSubscriptionPage;
