import type { IPaginateParams, IPaginatedResponse } from "#src/@types/api";
import endpoint from "#src/config/endpoint";
import request from "#src/utils/request";

export interface IScanVulnerabilityItem {
  severity: string;
  id: number;
  // affected_locations: string[];
  // traffics: { request: string | null; response: string | null }[];
  // details: string;
  // host: string;
  // port: number;
  vuln: {
    internal_id: string;
    name: string;
    cvss_v2_score: string | null;
    cvss_v2_vector: string | null;
    cvss_v3_score: string | null;
    cvss_v3_vector: string | null;
  } | null;
  first_found: number;
  last_found: number;
  asset: {
    id: number;
    name: string;
    address: string;
  };
  sub_status: string;
}

export function list_scan_vulnerabilities(
  workspace_id: string,
  scan_id: number,
  params: {
    cvss_score_from: string | undefined;
    cvss_score_to: string | undefined;
    filter_severity: string | undefined;
    filter_sub_status: string | undefined;
    domain_id: string | undefined;
  } & IPaginateParams
) {
  return request.get<never, IPaginatedResponse<IScanVulnerabilityItem>>(
    `${endpoint.WORKSPACES}/${workspace_id}/scans/${scan_id}/vulnerabilities`,
    { params }
  );
}
