// Libraries
import {
  type Dispatch,
  forwardRef,
  type SetStateAction,
  useCallback,
  useImperativeHandle,
} from "react";

// API-related
import msspBugBountyServices, {
  type IMsspBugBountyDetails,
} from "#src/services/mssp/bugBounty";

// Children
import { BugBountyProgramTypeEnum } from "#src/components/bug-bounty/enum";
import AddBugBountyRewardRange, {
  type IBugBountyRewardRangeItem,
} from "#src/components/bug-bounty/AddBugBounty/RewardRange";
import AddBugBountyProgramType from "#src/components/bug-bounty/AddBugBounty/ProgramType";
import AddBugBountyProgramName from "#src/components/bug-bounty/AddBugBounty/ProgramName";
import AddBugBountyDescription from "#src/components/bug-bounty/AddBugBounty/Description";

export interface IBugBountyProgramBriefStates {
  programType: (typeof BugBountyProgramTypeEnum)[keyof typeof BugBountyProgramTypeEnum];
  briefName: string;
  alias: string;
  description: string;
  currency: "VND" | "USD";
  rewardRange: IBugBountyRewardRangeItem[];
  status: string;
}

interface IMsspBugBountyDetailsProgramBriefRef {
  onSaveChanges: () => void;
}

interface IMsspBugBountyDetailsProgramBriefProps {
  workspaceId: string;
  bugBountyAlias: string;
  bugBountyDetails: IMsspBugBountyDetails;
  programBriefStates: IBugBountyProgramBriefStates;
  setProgramBriefStates: Dispatch<SetStateAction<IBugBountyProgramBriefStates>>;
  isEditing: boolean;
  onRefresh: () => void;
}

const MsspBugBountyDetailsProgramBrief = forwardRef<
  IMsspBugBountyDetailsProgramBriefRef,
  IMsspBugBountyDetailsProgramBriefProps
>(
  (
    {
      workspaceId,
      bugBountyAlias,
      bugBountyDetails,
      programBriefStates,
      setProgramBriefStates,
      isEditing,
      onRefresh,
    },
    ref
  ) => {
    const onChangeProgramType = (
      value: typeof programBriefStates.programType
    ) => {
      setProgramBriefStates((prev) => ({ ...prev, programType: value }));
    };

    const onChangeBriefName = (value: typeof programBriefStates.briefName) => {
      setProgramBriefStates((prev) => ({ ...prev, briefName: value }));
    };

    const onChangeAlias = (value: typeof programBriefStates.alias) => {
      setProgramBriefStates((prev) => ({ ...prev, alias: value }));
    };

    const onChangeDescription = (
      value: typeof programBriefStates.description
    ) => {
      setProgramBriefStates((prev) => ({ ...prev, description: value }));
    };

    const onChangeCurrency = (value: typeof programBriefStates.currency) => {
      setProgramBriefStates((prev) => ({ ...prev, currency: value }));
    };

    const onChangeRewardRangeType = (
      severity: (typeof programBriefStates.rewardRange)[number]["severity"],
      type: (typeof programBriefStates.rewardRange)[number]["type"]
    ) => {
      setProgramBriefStates((prev) => ({
        ...prev,
        rewardRange: prev.rewardRange.map((range) =>
          range.severity === severity
            ? type.value === "point"
              ? { ...range, type, from: 0, to: 0 }
              : { ...range, type }
            : range
        ),
      }));
    };

    const onChangeRewardRangeFrom = (
      severity: (typeof programBriefStates.rewardRange)[number]["severity"],
      from: (typeof programBriefStates.rewardRange)[number]["from"]
    ) => {
      setProgramBriefStates((prev) => ({
        ...prev,
        rewardRange: prev.rewardRange.map((range) =>
          range.severity === severity ? { ...range, from } : range
        ),
      }));
    };

    const onChangeRewardRangeTo = (
      severity: (typeof programBriefStates.rewardRange)[number]["severity"],
      to: (typeof programBriefStates.rewardRange)[number]["to"]
    ) => {
      setProgramBriefStates((prev) => ({
        ...prev,
        rewardRange: prev.rewardRange.map((range) =>
          range.severity === severity ? { ...range, to } : range
        ),
      }));
    };

    const onSaveChanges = useCallback(() => {
      msspBugBountyServices
        .update_mssp_program_details(workspaceId, bugBountyAlias, {
          name: programBriefStates.briefName
            ? programBriefStates.briefName
            : "",
          alias: programBriefStates.alias,
          type: bugBountyDetails.type,
          metadata: bugBountyDetails.metadata,
          description: {
            en: programBriefStates.description,
            vi: programBriefStates.description,
          },
          currency: programBriefStates.currency,
          reward: programBriefStates.rewardRange.map((range) => ({
            type: range.type.value ?? "",
            range_from: range.from,
            range_to: range.to,
            severity: range.severity,
            point_value: range.point,
          })),
        })
        .then((response) => {
          if (response.success) {
            return onRefresh();
          }
        });
    }, [
      workspaceId,
      bugBountyAlias,
      bugBountyDetails,
      programBriefStates,
      onRefresh,
    ]);

    // WARNING: This is using an escape hatch. Not the ideal way to do things.
    useImperativeHandle(
      ref,
      () => {
        return {
          onSaveChanges,
        };
      },
      [onSaveChanges]
    );

    return (
      <section className="flex gap-6 pt-6">
        <div className="flex flex-col gap-6 w-2/3">
          <AddBugBountyProgramType
            programType={programBriefStates.programType}
            onChangeProgramType={onChangeProgramType}
            disabled
          />
          <div className="flex flex-col gap-6">
            <AddBugBountyProgramName
              briefName={programBriefStates.briefName}
              alias={programBriefStates.alias}
              onChangeBriefName={onChangeBriefName}
              onChangeAlias={onChangeAlias}
              disabled={!isEditing}
            />
            <AddBugBountyDescription
              description={programBriefStates.description}
              onChangeDescription={onChangeDescription}
              disabled={!isEditing}
            />
          </div>
          <AddBugBountyRewardRange
            status={programBriefStates.status}
            currency={programBriefStates.currency}
            rewardRange={programBriefStates.rewardRange}
            onChangeCurrency={onChangeCurrency}
            onChangeRewardRangeType={onChangeRewardRangeType}
            onChangeRewardRangeFrom={onChangeRewardRangeFrom}
            onChangeRewardRangeTo={onChangeRewardRangeTo}
            disabled={!isEditing}
          />
        </div>
      </section>
    );
  }
);

MsspBugBountyDetailsProgramBrief.displayName =
  "MsspBugBountyDetailsProgramBrief";

export default MsspBugBountyDetailsProgramBrief;
