// Libraries
import { useTranslation } from "react-i18next";
import dayjs from "dayjs";
import type { ReactNode } from "react";

// Resources
// import { ReactComponent as Repeat2Line } from "#src/assets/images/icons/repeat-2-line.svg";
import { ReactComponent as DeleteBin6Line } from "#src/assets/images/icons/delete-bin-6-line.svg";
import { ReactComponent as Settings4Line } from "#src/assets/images/icons/settings-4-line.svg";
import { ReactComponent as AddLine } from "#src/assets/images/icons/add-line.svg";
import { ReactComponent as LogoWindows10 } from "#src/assets/images/logos/logo-windows-10.svg";
import { ReactComponent as LogoApple } from "#src/assets/images/logos/logo-apple.svg";
import { ReactComponent as LogoUbuntu } from "#src/assets/images/logos/logo-ubuntu.svg";
import { ReactComponent as ImageCircleFill } from "#src/assets/images/icons/image-circle-fill.svg";

// Components
import { Button, IconButton } from "#src/components/common/system/Button";
import { RoundedTag } from "#src/components/common/Tags";

// API-related
import type { IHostDetailItem } from "#src/services/devices";

const iconByOs: { [os: string]: ReactNode } = {
  windows: <LogoWindows10 className="h-10 w-10" />,
  darwin: <LogoApple className="h-10 w-10" />,
  ubuntu: <LogoUbuntu className="h-10 w-10" />,
};

/** Windows is Windows, Darwin is the core of macOS, and we only detect Ubuntu, not the other Linux distros */
const matchOsName = (values: string[]) => {
  const osList = ["windows", "darwin", "ubuntu"];
  return osList.reduce((prev, os) => {
    if (values.some((value) => value.toLowerCase().includes(os))) {
      return os;
    } else return prev;
  }, undefined);
};

const getOsIcon = (values: string[]) => {
  const osName = matchOsName(values);
  if (osName) {
    return iconByOs[osName];
  }
  return <ImageCircleFill className="fill-medium-grey h-7 w-7" />;
};

interface IHostDetailProfileSectionProps {
  details: IHostDetailItem;
}

const HostDetailsProfileSection = ({
  details,
}: IHostDetailProfileSectionProps) => {
  const { t } = useTranslation("devices", {
    keyPrefix: "hosts.page.details.profile",
  });

  return (
    <>
      <div className="flex gap-3 items-center">
        <div className="flex justify-center items-center h-[3.75rem] w-[3.75rem] min-w-[3.75rem] rounded-md border border-light-grey">
          {getOsIcon([details.os.name, details.os.family])}
        </div>
        <div className="flex flex-col gap-2 justify-center">
          <div className="flex gap-x-6 items-center flex-wrap">
            <h2>{details.name}</h2>
            <div className="flex gap-2">
              <span className="text-hard-grey">{t("createdDate")}</span>
              <span className="font-medium-16">
                {dayjs.unix(details.created_time).format("HH:mm DD.MM.YYYY")}
              </span>
            </div>
            {/* <Button variant="text" disabled>
              {t("button.refetch")}
              <Repeat2Line />
            </Button> */}
          </div>
          {details.tags.length > 0 ? (
            <div className="flex gap-2">
              <span>{t("tags")}</span>
              <div className="flex gap-1 flex-wrap">
                {details.tags.map((tag) => (
                  <RoundedTag key={`details-tag-${tag.name}`} name={tag.name} />
                ))}
              </div>
            </div>
          ) : null}
        </div>
      </div>
      <div className="flex gap-1">
        {!details.owner ? (
          <Button variant="secondary" size="large" disabled>
            <AddLine />
            {t("addAHostOwner")}
          </Button>
        ) : null}
        <IconButton
          variant="secondary"
          // TODO: onClick={onOpenEditDrawer}
          disabled
        >
          <Settings4Line />
        </IconButton>
        <IconButton
          variant="warning"
          onClick={() => {
            // TODO: global.confirm(() => onDeleteThis());
          }}
          disabled
        >
          <DeleteBin6Line />
        </IconButton>
      </div>
    </>
  );
};

export default HostDetailsProfileSection;
