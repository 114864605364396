// Libraries
import i18next from "i18next";

// Resources
import { ReactComponent as EditLine } from "#src/assets/images/icons/edit-line.svg";
import { ReactComponent as CheckboxCircleLine } from "#src/assets/images/icons/checkbox-circle-line.svg";
import { ReactComponent as DeleteBin6Line } from "#src/assets/images/icons/delete-bin-6-line.svg";
import { ReactComponent as DraftLine } from "#src/assets/images/icons/draft-line.svg";

// Components
import { TableActionLabel } from "#src/components/common/Table";

// Children
import { TrustCenterFaqActionEnum } from "../enum";

const fixedTFunc = i18next.getFixedT(null, "trustCenter", "faq.actions");

export const trustCenterFaqActionList = [
  {
    key: TrustCenterFaqActionEnum.EDIT,
    getLabel: () => (
      <TableActionLabel icon={<EditLine />} text={fixedTFunc("edit")} />
    ),
  },
  {
    key: TrustCenterFaqActionEnum.PUBLISH,
    getLabel: () => (
      <TableActionLabel
        icon={<CheckboxCircleLine />}
        text={fixedTFunc("publish")}
      />
    ),
  },
  {
    key: TrustCenterFaqActionEnum.DRAFT,
    getLabel: () => (
      <TableActionLabel icon={<DraftLine />} text={fixedTFunc("putToDraft")} />
    ),
  },
  {
    key: TrustCenterFaqActionEnum.DELETE,
    getLabel: () => (
      <TableActionLabel
        icon={<DeleteBin6Line />}
        text={fixedTFunc("delete")}
        warning
      />
    ),
  },
];
