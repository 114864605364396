import type { IHostDetailItem } from "#src/services/devices";
import { useTranslation } from "react-i18next";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import { Fragment, useState } from "react";
import { SearchBox } from "#src/components/common/Table/Filter";

dayjs.extend(relativeTime);

const AboutItem = ({ name, value }: { name: string; value: string }) => {
  return (
    <>
      <div className="text-hard-grey">{name}</div>
      <div>{value}</div>
    </>
  );
};

const HostDetailsDetails = ({
  hostDetails,
}: {
  hostDetails: IHostDetailItem;
}) => {
  const { t } = useTranslation("devices", {
    keyPrefix: "hosts.page.details.tab.details",
  });

  const [searchKeyword, setSearchKeyword] = useState<string>("");

  const onChangeSearchKeyword = (keyword: string) => {
    setSearchKeyword(keyword);
  };

  return (
    <div className="flex flex-col gap-6 pt-6">
      <div className="flex flex-col gap-6">
        <h2>{t("about.label")}</h2>
        <div className="bg-bright-grey p-6 rounded-md flex gap-x-10">
          <div className="grid grid-cols-2 flex-1 max-w-[30rem] gap-y-4">
            <AboutItem
              name={t("about.addedToFleet")}
              value={dayjs.unix(hostDetails.created_time).fromNow()}
            />
            <AboutItem
              name={t("about.serialNumber")}
              value={
                typeof hostDetails.system.hardware_serial === "string"
                  ? hostDetails.system.hardware_serial
                  : "---"
              }
            />
            <AboutItem
              name={t("about.usedBy")}
              value={t("about.userCount", { count: hostDetails.users.length })}
            />
            <AboutItem
              name={t("about.lastRestarted")}
              // TODO: change this
              value={dayjs.unix(hostDetails.created_time).fromNow()}
            />
          </div>
          <hr className="border-l" />
          <div className="grid grid-cols-2 flex-1 max-w-[30rem] gap-y-4">
            <AboutItem
              name={t("about.privateIpAddress")}
              value={hostDetails.internal_ip}
            />
            <AboutItem
              name={t("about.publicIpAddress")}
              value={hostDetails.public_ip || "---"}
            />
            <AboutItem
              name={t("about.batteryCondition")}
              value={
                hostDetails.system.batteries
                  ? hostDetails.system.batteries[0].health
                  : "---"
              }
            />
            <AboutItem
              name={t("about.hardwareModel")}
              value={
                typeof hostDetails.system.hardware_model === "string"
                  ? hostDetails.system.hardware_model
                  : "---"
              }
            />
          </div>
        </div>
      </div>
      <div className="flex flex-col gap-6">
        <div className="flex justify-between">
          <div className="flex items-center gap-6">
            <h2>{t("users.label")}</h2>
            <span className="text-hard-grey font-medium-16">
              {t("users.userCount", {
                count: hostDetails.users.length,
              }).toUpperCase()}
            </span>
          </div>
          <SearchBox
            searchKeyword={searchKeyword}
            onChangeSearchKeyword={onChangeSearchKeyword}
          />
        </div>
        <div className="grid grid-cols-2">
          <div className="px-6 py-5 bg-bright-grey text-hard-grey">
            {t("users.headers.username")}
          </div>
          <div className="px-6 py-5 bg-bright-grey text-hard-grey">
            {t("users.headers.shell")}
          </div>
          {hostDetails.users
            .filter((user) => user.username.includes(searchKeyword))
            .map((user) => {
              return (
                <Fragment key={`hostDetails-details-user${user.uid}`}>
                  <div className="px-6 py-5 border-b border-light-grey">
                    {user.username}
                  </div>
                  <div className="px-6 py-5 border-b border-light-grey">
                    {user.shell}
                  </div>
                </Fragment>
              );
            })}
        </div>
      </div>
    </div>
  );
};

export default HostDetailsDetails;
