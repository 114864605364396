import i18next from "i18next";
import type { ReactNode } from "react";

import { ReactComponent as Artboard2Line } from "#src/assets/images/icons/artboard-2-line.svg";
// import { ReactComponent as DeviceLine } from "#src/assets/images/icons/device-line.svg";
import { ReactComponent as BugLine } from "#src/assets/images/icons/bug-line.svg";
// import { ReactComponent as QrScanLine } from "#src/assets/images/icons/qr-scan-line.svg";
import { ReactComponent as FileShield2Line } from "#src/assets/images/icons/file-shield-2-line.svg";
import { ReactComponent as SymbolWh } from "#src/assets/images/icons/symbol-wh.svg";
// import { ReactComponent as DataLeakLine } from "#src/assets/images/icons/data-leak-line.svg";
import { ReactComponent as AwardLine } from "#src/assets/images/icons/award-line.svg";

import { helperPathname, pathname } from "#src/config/pathname";

const sidebarTFunc = i18next.getFixedT(null, "general", "sidebar.menu");

interface IMenuLeafRoute {
  pathname: string;
  getLabel: () => string;
}

interface IMenuRoute extends IMenuLeafRoute {
  icon: ReactNode;
  children?: IMenuLeafRoute[];
}

interface IMenuGroup {
  key: string;
  getLabel: () => string;
  routes: IMenuRoute[];
}

// TODO: re-enable this on staging & local
export const getMainMenuRoutes = ({
  trustCenterPlan,
}: {
  trustCenterPlan: undefined | null | string;
}): IMenuGroup[] => [
  {
    key: "attack_surface_management",
    getLabel: () => sidebarTFunc("headers.attackSurfaceManagement"),
    routes: [
      {
        pathname: helperPathname.ASSETS,
        icon: (
          <Artboard2Line className="w-5 h-5 min-w-[1.25rem] min-h-[1.25rem]" />
        ),
        getLabel: () => sidebarTFunc("assets.label"),
        children: [
          {
            pathname: pathname.DOMAINS,
            getLabel: () => sidebarTFunc("assets.domains"),
          },
          {
            pathname: pathname.IP_ADDRESS,
            getLabel: () => sidebarTFunc("assets.ipAddress"),
          },
          {
            pathname: pathname.CERTIFICATES,
            getLabel: () => sidebarTFunc("assets.certificates"),
          },
        ],
      },
      // {
      //   pathname: helperPathname.DEVICES,
      //   icon: (
      //     <DeviceLine className="w-5 h-5 min-w-[1.25rem] min-h-[1.25rem]" />
      //   ),
      //   getLabel: () => sidebarTFunc("devices.label"),
      //   children: [
      //     {
      //       pathname: pathname.HOSTS,
      //       getLabel: () => sidebarTFunc("devices.hosts"),
      //     },
      //     {
      //       pathname: pathname.POLICIES,
      //       getLabel: () => sidebarTFunc("devices.policies"),
      //     },
      //     {
      //       pathname: pathname.CONTROL,
      //       getLabel: () => sidebarTFunc("devices.control"),
      //     },
      //     // {
      //     //   pathname: pathname.PASSPORTS,
      //     //   getLabel: () => sidebarTFunc("passports"),
      //     // },
      //   ],
      // },
      {
        pathname: pathname.VULNERABILITIES,
        icon: <BugLine className="w-5 h-5 min-w-[1.25rem] min-h-[1.25rem]" />,
        getLabel: () => sidebarTFunc("vulnerabilities"),
      },
    ],
  },
  // {
  //   key: "vulnerability_assessment",
  //   getLabel: () => sidebarTFunc("headers.vulnerabilityAssessment"),
  //   routes: [
  //     {
  //       pathname: helperPathname.SCANS,
  //       icon: (
  //         <QrScanLine className="w-5 h-5 min-w-[1.25rem] min-h-[1.25rem]" />
  //       ),
  //       getLabel: () => sidebarTFunc("scans.label"),
  //       children: [
  //         {
  //           pathname: pathname.ALL_SCANS,
  //           getLabel: () => sidebarTFunc("scans.allScans"),
  //         },
  //         {
  //           pathname: pathname.SCHEDULES,
  //           getLabel: () => sidebarTFunc("scans.schedules"),
  //         },
  //       ],
  //     },
  //     {
  //       pathname: pathname.PENTEST,
  //       icon: (
  //         <FileShield2Line className="w-5 h-5 min-w-[1.25rem] min-h-[1.25rem]" />
  //       ),
  //       getLabel: () => sidebarTFunc("pentest"),
  //     },
  //     {
  //       pathname: pathname.BUG_BOUNTY_PROGRAMS,
  //       icon: <SymbolWh className="w-5 h-5 min-w-[1.25rem] min-h-[1.25rem]" />,
  //       getLabel: () => sidebarTFunc("bugBountyPrograms"),
  //     },
  //   ],
  // },
  {
    key: "security_control",
    getLabel: () => sidebarTFunc("headers.securityControl"),
    routes: [
      // {
      //   pathname: pathname.DATA_LEAK_DETECTION,
      //   icon: (
      //     <DataLeakLine className="w-5 h-5 min-w-[1.25rem] min-h-[1.25rem]" />
      //   ),
      //   getLabel: () => sidebarTFunc("dataLeakDetection"),
      // },
      {
        pathname: helperPathname.TRUST_CENTER,
        icon: <AwardLine className="w-5 h-5 min-w-[1.25rem] min-h-[1.25rem]" />,
        getLabel: () => sidebarTFunc("trustCenter.label"),
        children:
          trustCenterPlan === null
            ? []
            : [
                {
                  pathname: pathname.TRUST_CENTER_POLICY,
                  getLabel: () => sidebarTFunc("trustCenter.policy"),
                },
                {
                  pathname: pathname.TRUST_CENTER_COMPLIANCE,
                  getLabel: () => sidebarTFunc("trustCenter.compliance"),
                },
                {
                  pathname: pathname.TRUST_CENTER_CONTROL,
                  getLabel: () => sidebarTFunc("trustCenter.control"),
                },
                {
                  pathname: pathname.TRUST_CENTER_DOCUMENT,
                  getLabel: () => sidebarTFunc("trustCenter.document"),
                },
                {
                  pathname: pathname.TRUST_CENTER_THIRD_PARTY,
                  getLabel: () => sidebarTFunc("trustCenter.thirdPartyService"),
                },
                {
                  pathname: pathname.TRUST_CENTER_FAQ,
                  getLabel: () => sidebarTFunc("trustCenter.faq"),
                },
                {
                  pathname: pathname.TRUST_CENTER_CONFIG,
                  getLabel: () => sidebarTFunc("trustCenter.configuration"),
                },
              ],
      },
    ],
  },
];

export const msspMenuRoutes: IMenuGroup[] = [
  {
    key: "vulnerability_assessment",
    getLabel: () => sidebarTFunc("headers.vulnerabilityAssessment"),
    routes: [
      {
        pathname: pathname.MSSP_PENTEST,
        icon: (
          <FileShield2Line className="w-5 h-5 min-w-[1.25rem] min-h-[1.25rem]" />
        ),
        getLabel: () => sidebarTFunc("pentest"),
      },
      {
        pathname: pathname.MSSP_BUG_BOUNTY_PROGRAMS,
        icon: <SymbolWh className="w-5 h-5 min-w-[1.25rem] min-h-[1.25rem]" />,
        getLabel: () => sidebarTFunc("bugBountyPrograms"),
      },
    ],
  },
];

export const accountMenuRoutes: IMenuLeafRoute[] = [
  {
    pathname: pathname.ACCOUNT_SETTINGS,
    getLabel: () => sidebarTFunc("accountSettings.personalDetails"),
  },
  {
    pathname: pathname.NOTIFICATION,
    getLabel: () => sidebarTFunc("accountSettings.notificationSettings"),
  },
];

export const workspaceMenuRoutes: IMenuLeafRoute[] = [
  {
    pathname: pathname.WORKSPACE_DETAILS,
    getLabel: () => sidebarTFunc("workspaceSettings.details"),
  },
  {
    pathname: pathname.WORKSPACE_MEMBERS,
    getLabel: () => sidebarTFunc("workspaceSettings.members"),
  },
  {
    pathname: pathname.WORKSPACE_BILLING,
    getLabel: () => sidebarTFunc("workspaceSettings.billing"),
  },
];
