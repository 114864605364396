// Libraries
import { Drawer } from "@lockerpm/design";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import i18next from "i18next";

// Resources
import { ReactComponent as CloseLine } from "#src/assets/images/icons/close-line.svg";

// General
import type { IDrawerAdapterProps } from "#src/@types/common";

// Components
import { Button, IconButton } from "#src/components/common/system/Button";
import LoadingState from "#src/components/common/system/LoadingState";
import { TextArea } from "#src/components/common/system/Input";
import { TagEditor } from "#src/components/common/Tags/TagEditor";
import { createToast } from "#src/components/common/system/toasts";
import { apiErrorHandler } from "#src/utils/apiErrorHandler";

// API-related
import type { IPolicyItem } from "#src/services/devices/policies";
import devicesServices from "#src/services/devices";
import tagsServices from "#src/services/tags";

// Children
import HostInput from "../../control/common/HostInput";
import { TagTypeValue } from "#src/config/filter/tag/value";

const FooterButtons = ({
  onSave,
  onCancel,
}: {
  onSave: () => void;
  onCancel: () => void;
}) => {
  const { t } = useTranslation("devices", { keyPrefix: "policies.editDrawer" });

  return (
    <div className="flex flex-col gap-3">
      <Button className="w-full" size="large" onClick={onSave}>
        {t("button.save")}
      </Button>
      <Button
        variant="secondary"
        className="w-full"
        size="large"
        onClick={() => {
          onCancel();
          createToast({
            type: "info",
            message: t("notification.cancel.message"),
            detail: t("notification.cancel.description"),
          });
        }}
      >
        {t("button.cancel")}
      </Button>
    </div>
  );
};

interface IEditPolicyDrawerProps extends IDrawerAdapterProps {
  selectedItemId: number | null;
}

const EditPolicyDrawer = ({
  open,
  onClose,
  workspaceId,
  onRefresh,
  selectedItemId,
}: IEditPolicyDrawerProps) => {
  const { t } = useTranslation("devices", { keyPrefix: "policies.editDrawer" });
  const locale = i18next.language;

  const [editingItem, setEditingItem] = useState<IPolicyItem | null>(null);

  const [tagItems, setTagItems] = useState<
    { key: string; value: string; label: string }[]
  >([]);

  const [tagList, setTagList] = useState<{ name: string }[]>([]);
  const [selectedHosts, setSelectedHosts] = useState<
    { key: string; id: string; name: string; label: string }[]
  >([]);
  const [hostIdCounter, setHostIdCounter] = useState(0);

  const [isLoading, setLoading] = useState<boolean>(false);

  const onCloseAndCleanup = () => {
    onClose();
    setEditingItem(null);
    setTagList([]);
    setSelectedHosts([]);
    setHostIdCounter(0);
  };

  const onChangeTags = (newTags: { name: string }[]) => {
    setTagList(newTags);
  };

  const onAddDefaultHost = () => {
    setHostIdCounter((prev) => prev + 1);
    setSelectedHosts((prev) => [
      ...prev,
      {
        key: (hostIdCounter + 1).toString(),
        id: "",
        name: "",
        label: "",
      },
    ]);
  };

  const onChangeHost = (newHost: {
    key: string;
    id: string;
    name: string;
    label: string;
  }) => {
    setSelectedHosts((prev) => {
      const changingHost = prev.findIndex((item) => item.key === newHost.key);
      let newList = prev;
      newList[changingHost] = newHost;
      return [...newList];
    });
  };

  const onRemoveHost = (removedHost: {
    key: string;
    id: string;
    name: string;
    label: string;
  }) => {
    setSelectedHosts((prev) => {
      return prev.filter((host) => host.key !== removedHost.key);
    });
  };

  const onSave = () => {
    if (selectedItemId && editingItem) {
      devicesServices
        .update_workspace_policy(workspaceId, selectedItemId, {
          enabled: editingItem.enabled,
          hosts_tags: [
            ...selectedHosts.map((host) => ({
              id: host.id,
              type: "host",
            })),
            ...tagList
              .map((tag) => {
                const tagId = tagItems.find(
                  (item) => item.label === tag.name
                )?.value;
                return {
                  id: typeof tagId === "string" ? tagId : "-1",
                  type: "tag",
                };
              })
              .filter((tag) => tag.id !== "-1"),
          ],
        })
        .then(() => {
          onRefresh();
          onCloseAndCleanup();
        });
    }
  };

  useEffect(() => {
    let ignore = false;

    if (!ignore) {
      tagsServices
        .list_workspace_tags_shortly(workspaceId)
        .then((response) => {
          setTagItems(
            response.map((item) => ({
              key: item.id.toString(),
              value: item.id.toString(),
              label: item.name,
            }))
          );
        })
        .catch((error) => {
          apiErrorHandler(error, {
            toastMessage: t("notification.loadTagList.fail"),
          });
        });
    }

    return () => {
      ignore = true;
    };
  }, [workspaceId, t]);

  useEffect(() => {
    let ignore = false;

    if (!ignore && selectedItemId) {
      setLoading(true);
      devicesServices
        .get_policy_details(workspaceId, selectedItemId)
        .then((response) => {
          setEditingItem(response);
          setTagList(
            response.hosts_tags
              .filter((item) => item.type === "tag")
              .map((tag) => ({
                name: tag.name,
              }))
          );
          setSelectedHosts(
            response.hosts_tags
              .filter((item) => item.type === "host")
              .map((host) => ({
                key: host.id.toString(),
                id: host.id.toString(),
                name: host.name,
                label: host.name,
              }))
          );
          setLoading(false);
        })
        .catch((error) => {
          apiErrorHandler(error, {
            toastMessage: t("notification.loadPolicy.fail"),
          });
        });
    }

    return () => {
      ignore = true;
    };
  }, [workspaceId, selectedItemId, t]);

  return (
    <Drawer
      open={open}
      onClose={onCloseAndCleanup}
      closable={false}
      title={<h2>{t("title")}</h2>}
      extra={
        <IconButton variant="label-grey" size={9} onClick={onCloseAndCleanup}>
          <CloseLine />
        </IconButton>
      }
      footer={<FooterButtons onSave={onSave} onCancel={onCloseAndCleanup} />}
      destroyOnClose={true}
    >
      {isLoading ? (
        <LoadingState />
      ) : (
        <div className="p-6 flex flex-col gap-6">
          <div className="p-3 rounded-md font-medium-16 bg-label-blue">
            {locale === "vi"
              ? editingItem?.policy.name.vi
              : editingItem?.policy.name.en}
          </div>
          <div className="flex flex-col gap-3">
            <h4>{t("field.policyDescription.label")}</h4>
            <TextArea
              value={
                locale === "vi"
                  ? editingItem?.policy.description.vi
                  : editingItem?.policy.description.en
              }
              disabled
            />
          </div>
          {/* Currently there's no policy category. Last updated: 16/05/2024 */}
          {/* <div className="flex flex-col gap-3">
            <h4>{t("field.policyCategory.label")}</h4>
            <Input value={editingItem?.policy.description} disabled />
          </div> */}
          <div className="flex flex-col gap-3">
            <h4>{t("field.applyToTags.label")}</h4>
            <TagEditor
              workspaceId={workspaceId}
              tagList={tagList}
              onChangeTags={onChangeTags}
              type={TagTypeValue.HOST}
            />
          </div>
          <div className="flex flex-col gap-3">
            <h4>{t("field.applyToHosts.label")}</h4>
            {selectedHosts.map((host, index) => (
              <HostInput
                key={`devices/control/newControl-networkTab-hostInput-${host}-${index}`}
                workspaceId={workspaceId}
                selectedHost={host}
                onChangeHost={onChangeHost}
                onRemoveHost={onRemoveHost}
              />
            ))}
            <button
              className="p-3 bg-bright-grey text-medium-grey rounded-md text-left"
              onClick={(e) => {
                e.stopPropagation();
                onAddDefaultHost();
              }}
            >
              {t("field.applyToHosts.search.placeholder")}
            </button>
          </div>
        </div>
      )}
    </Drawer>
  );
};

export default EditPolicyDrawer;
