import { forwardRef, type HTMLAttributes } from "react";
import { twMerge } from "tailwind-merge";

/* A layout that extends `section` Element. */
const ContentSection = forwardRef<HTMLElement, HTMLAttributes<HTMLElement>>(
  (props, ref) => {
    return (
      // padding-bottom & margin-bottom is matching the height of the PaginationBar component. We use both to guarantee cross-browser behaviours
      <section
        {...props}
        ref={ref}
        className={twMerge(
          "flex flex-col gap-6 px-16 pb-12 mb-12 w-full first:pt-4",
          props.className
        )}
      >
        {props.children}
      </section>
    );
  }
);

ContentSection.displayName = "ContentSection";

export default ContentSection;
