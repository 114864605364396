import ReactDOM from "react-dom/client";
import { Provider } from "react-redux";
import store from "./store";
import "./index.css";

import App from "./App";
import reportWebVitals from "./reportWebVitals";

import i18n from "./config/i18n";
import { I18nextProvider } from "react-i18next";
import { ConfigProvider } from "@lockerpm/design";
import { TAILWIND_COLORS } from "#src/utils/common";

const root = ReactDOM.createRoot(document.getElementById("app") as HTMLElement);

root.render(
  <Provider store={store}>
    <ConfigProvider
      theme={{
        token: { colorPrimary: TAILWIND_COLORS.primary },
      }}
    >
      <I18nextProvider i18n={i18n}>
        <App />
      </I18nextProvider>
    </ConfigProvider>
  </Provider>
);

reportWebVitals();
