import type { IPaginateParams, IPaginatedResponse } from "#src/@types/api";
import endpoint from "#src/config/endpoint";
import request from "#src/utils/request";

export interface ITrustCenterControlOverviewStats {
  status: {
    total: number;
    compliance: number;
    non_compliance: number;
  };
  domain: {
    id: string;
    name: { en: string; vi: string };
    total: number;
    compliance: number;
    non_compliance: number;
  }[];
}

export function retrieve_control_overview(workspace_id: string) {
  return request.get<never, ITrustCenterControlOverviewStats>(
    `${endpoint.WORKSPACES}/${workspace_id}/trust_center/controls/overview`
  );
}

export interface ITrustCenterControlItem {
  id: number;
  status: string;
  updated_time: number | null;
  refs: {
    id: string;
    title: string;
    compliance_id: string;
  }[];
  procedure: {
    id: string;
    docs: {
      en: Array<string>;
      vi: Array<string>;
    };
    name: {
      en: string;
      vi: string;
    };
    summary: {
      en: string;
      vi: string;
    };
    guidance: {
      en: string;
      vi: string;
    };
    policy: {
      id: string;
      name: {
        en: string;
        vi: string;
      };
    };
    tools: Array<{
      name: string,
      url: string,
      description: string,
      id: string
    }>;
  };
}

export function list_trust_center_control(
  workspace_id: string,
  params: {
    domain_id: string | undefined;
    status: string | undefined;
    ref_id: string | undefined;
    compliance_id: string | undefined;
    policy_id: string | undefined;
  } & IPaginateParams
) {
  return request.get<never, IPaginatedResponse<ITrustCenterControlItem>>(
    `${endpoint.WORKSPACES}/${workspace_id}/trust_center/controls`,
    { params }
  );
}

export function update_trust_center_control(
  workspace_id: string,
  control_id: number,
  data: {
    status: string;
  }
) {
  return request.put<never, { success: boolean }>(
    `${endpoint.WORKSPACES}/${workspace_id}/trust_center/controls/${control_id}`,
    data
  );
}

export function retrieve_control_verification_status(workspace_id: string) {
  return request.get<never, { verified: boolean }>(
    `${endpoint.WORKSPACES}/${workspace_id}/trust_center/controls/verification`
  );
}
