import { ReactComponent as CodeSSlashLine } from "#src/assets/images/icons/code-s-slash-line.svg";
import EditorButton from "../EditorButton";
import { COMMENT_EDITOR_TEXTAREA_ID } from "../constants";

const CodeButton = ({ disabled }: { disabled?: boolean }) => {
  return (
    <EditorButton
      onClick={() => {
        const editor = document.getElementById(
          COMMENT_EDITOR_TEXTAREA_ID
        ) as HTMLTextAreaElement;
        const selectionStart = editor.selectionStart;
        const selectionEnd = editor.selectionEnd;
        const selectionText = editor.value.substring(
          selectionStart,
          selectionEnd
        );
        const startLine =
          editor.value.substring(0, selectionStart).split("\n").length - 1;
        const endLine =
          editor.value.substring(0, selectionEnd - 1).split("\n").length - 1;

        const prefix = startLine === endLine ? "`" : "\n```\n";
        const suffix = startLine === endLine ? "`" : "\n```\n";

        editor.setRangeText(
          prefix + selectionText + suffix,
          selectionStart,
          selectionEnd
        );
        editor.setSelectionRange(
          selectionStart + prefix.length,
          selectionEnd + prefix.length
        );
      }}
      disabled={disabled}
    >
      <CodeSSlashLine />
    </EditorButton>
  );
};

export default CodeButton;
