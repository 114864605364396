// Libraries
import { Checkbox } from "@lockerpm/design";
import { useEffect, useRef, useState } from "react";
import i18next from "i18next";

// Resources
import { ReactComponent as ArrowExpandDownLine } from "#src/assets/images/icons/arrow-expand-down-line.svg";

// Components
import { Button } from "#src/components/common/system/Button";

// API-related
import type { IPolicyInfoItem } from "#src/services/devices/policies";

const PolicySelectItem = ({
  data,
  isSelected,
  onClickCheckbox,
}: {
  data: IPolicyInfoItem;
  isSelected: boolean;
  onClickCheckbox: () => void;
}) => {
  const locale = i18next.language;

  const descriptionRef = useRef<HTMLDivElement>(null);
  const [expandable, setExpandable] = useState<boolean>(false);
  const [expanded, setExpanded] = useState<boolean>(false);

  useEffect(() => {
    if (!descriptionRef.current) {
      return;
    }

    if (
      descriptionRef.current.scrollHeight >
        descriptionRef.current.clientHeight ||
      expanded
    ) {
      setExpandable(true);
    } else {
      setExpandable(false);
    }
  }, [data, expanded]);

  return (
    <div
      key={`addPolicies-${data.id}`}
      className={`flex flex-col gap-4 p-6 w-full border-b border-x first:border-t border-light-grey cursor-pointer transition-colors ${
        isSelected ? "" : "text-medium-grey"
      }`}
      onClick={onClickCheckbox}
    >
      <div className="flex items-start justify-between w-full gap-6">
        <span className="font-medium-16">
          {locale === "vi" ? data.name.vi : data.name.en}
        </span>
        <Checkbox checked={isSelected} />
      </div>
      <div className="flex flex-col gap-4">
        <p
          ref={descriptionRef}
          className={`font-regular-14-body transition-all ${
            expanded ? "" : "line-clamp-3"
          }`}
        >
          {locale === "vi" ? data.description.vi : data.description.en}
        </p>
        {expandable ? (
          <div className="flex justify-end">
            <Button
              variant="text"
              onClick={(e) => {
                e.stopPropagation();
                setExpanded((prev) => !prev);
              }}
            >
              {expanded ? "Show less" : "Show more"}
              <ArrowExpandDownLine
                className={`transition-transform ${
                  expanded ? "rotate-180" : ""
                }`}
              />
            </Button>
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default PolicySelectItem;
