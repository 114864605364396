// General
import constants from "#src/config/constants";
import urls from "#src/config/urls";

// Components
import { createToast } from "#src/components/common/system/toasts";

// Children
import {
  assessmentTypeItems,
  visibilityItems,
} from "#src/config/filterConstants";
import vulnerabilitiesServices from "#src/services/vulnerabilities";
import {
  severityItems,
  vulnerabilityStatusItems,
} from "#src/config/filter/vulnerability";

export const autoScanReporter = {
  email: "cystack",
  full_name: "CyStack WebScan",
  username: "cystack",
  avatar: urls.IMAGE_LOGO_SERVER_URL.replace(":address", "cystack.net"),
};

export const dataLeakReporter = {
  email: "cystack",
  full_name: "CyStack Data Leak Detector",
  username: "cystack",
  avatar: urls.IMAGE_LOGO_SERVER_URL.replace(":address", "cystack.net"),
};

/** This only is an attempt to reduce bad requests amount, not really do much for security.
 * AFAIK URLSearchParams can be considered as a safe sink already.
 */
export const sanitizeVulnSearchParams: (
  searchParams: URLSearchParams
) => Parameters<typeof vulnerabilitiesServices.list_vulnerabilities>[1] = (
  searchParams: URLSearchParams
) => {
  const defaultParams: Parameters<
    typeof vulnerabilitiesServices.list_vulnerabilities
  >[1] = {
    from: undefined,
    to: undefined,
    updated_time_from: undefined,
    updated_time_to: undefined,
    assessment_type: undefined,
    program: undefined,
    rewarded: undefined,
    pentest: undefined,
    visibility: undefined,
    filter_status: undefined,
    filter_sub_status: undefined,
    filter_severity: undefined,
    archived: undefined,
    scan_history_id: undefined,
    sort: undefined,
    q: undefined,
    page: undefined,
    size: constants.DEFAULT_PAGE_SIZE,
  };

  for (const [key, value] of searchParams) {
    if (!Object.keys(defaultParams).includes(key)) {
      continue;
    }
    // make sure these variables are numbers
    if (
      (key === "from" ||
        key === "to" ||
        key === "updated_time_from" ||
        key === "updated_time_to" ||
        key === "page" ||
        key === "size") &&
      !isNaN(+value)
    ) {
      defaultParams[key] = +value;
      continue;
    }

    // these should also be a number, but we send it as string? might need to change it
    if (key === "scan_history_id" && !isNaN(+value)) {
      defaultParams[key] = value;
      continue;
    }

    if (
      (key === "rewarded" || key === "archived") &&
      (value === "0" || value === "1")
    ) {
      defaultParams[key] = value;
      continue;
    }

    const normalisedValue = value.toLowerCase();

    if (key === "filter_status") {
      if (
        vulnerabilityStatusItems
          .map((item) => item.value)
          .includes(normalisedValue)
      ) {
        defaultParams[key] = normalisedValue;
        continue;
      } else {
        createToast({
          type: "warning",
          message: "filter_status param is invalid",
        });
      }
    }

    if (key === "filter_sub_status") {
      defaultParams[key] = normalisedValue
        .split(",")
        .filter((v) =>
          vulnerabilityStatusItems
            .map((item) => item.children)
            .flat()
            .map((item) => item.value)
            .includes(v)
        )
        .join(",");
      continue;
    }

    if (key === "assessment_type") {
      if (
        assessmentTypeItems.map((item) => item.value).includes(normalisedValue)
      ) {
        defaultParams[key] = normalisedValue;
        continue;
      } else {
        createToast({
          type: "warning",
          message: "assessment_type param is invalid",
        });
      }
    }

    if (key === "visibility") {
      if (visibilityItems.map((item) => item.value).includes(normalisedValue)) {
        defaultParams[key] = normalisedValue;
        continue;
      } else {
        createToast({
          type: "warning",
          message: "visibility param is invalid",
        });
      }
    }

    if (key === "filter_severity") {
      // This param is in uppercase
      if (
        severityItems
          .map((item) => item.value)
          .includes(normalisedValue.toUpperCase())
      ) {
        defaultParams[key] = normalisedValue.toUpperCase();
        continue;
      } else {
        createToast({
          type: "warning",
          message: "filter_severity param is invalid",
        });
      }
    }

    // these variables accept strings
    if (
      key === "program" ||
      key === "pentest" ||
      key === "sort" ||
      key === "q"
    ) {
      defaultParams[key] = normalisedValue;
      continue;
    }
  }

  if (!defaultParams.filter_status && !defaultParams.filter_sub_status) {
    defaultParams.filter_status = "open";
  }

  if (!defaultParams.assessment_type && defaultParams.scan_history_id) {
    defaultParams.assessment_type = "auto_scan";
  }

  // TODO: default assessment_type when program/rewarded is declared

  return defaultParams;
};
