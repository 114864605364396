import { type ChangeEventHandler, type FocusEventHandler } from "react";
import { useTranslation } from "react-i18next";
import { twMerge } from "tailwind-merge";
import { ReactComponent as InformationLine } from "#src/assets/images/icons/information-line.svg";
import { Input } from "#src/components/common/system/Input";

interface IInputFieldProps {
  title: string;
  required?: boolean;
  disabled?: boolean;
  value: string;
  onChangeValue: (value: string) => void;
  placeholder?: string;
  error?: string;
  onChangeError?: (value: string | undefined) => void;
  pattern?: RegExp;
  patternFailedString?: string;
  className?: string;
}

export const InputField = ({
  title,
  required,
  disabled = false,
  value,
  onChangeValue,
  placeholder,
  error,
  onChangeError,
  pattern,
  patternFailedString,
  className,
}: IInputFieldProps) => {
  const { t } = useTranslation("common", { keyPrefix: "input" });

  const onChange: ChangeEventHandler<HTMLInputElement> = (e) => {
    onChangeValue(e.target.value);
    if (error && onChangeError) {
      onChangeError(undefined);
    }
  };

  const onBlur: FocusEventHandler<HTMLInputElement> = (e) => {
    if (e.target.value) {
      if (pattern && onChangeError) {
        if (!pattern.test(e.target.value)) {
          if (patternFailedString) {
            onChangeError(patternFailedString);
          } else {
            onChangeError(t("error.invalid"));
          }
        }
      }
    } else {
      if (required && onChangeError) {
        onChangeError(t("error.required"));
      }
    }
  };

  // Not all error state should be shown. For example: when input is disabled, we should hide errors.
  const displayError: boolean = Boolean(error && !disabled);

  return (
    <div className={twMerge("flex flex-col gap-3", className)}>
      <h4>
        {required ? <span className="text-primary pr-0.5">*</span> : null}
        {title}
      </h4>
      <Input
        variant={displayError ? "warning" : "primary"}
        placeholder={placeholder}
        disabled={disabled}
        value={value}
        onChange={onChange}
        onBlur={onBlur}
      />
      {displayError ? (
        <span className="font-medium-14-forced text-warning flex items-center gap-1">
          <InformationLine className="h-5 w-5" />
          {error}
        </span>
      ) : null}
    </div>
  );
};
