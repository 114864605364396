import { LANGUAGE_LIST } from "#src/config/languages";
import i18next from "i18next";
import Cookies from "js-cookie";

function updateLanguage(lang: string) {
  Cookies.set("language", lang);
  i18next.changeLanguage(lang);
}

function getLanguage(): string {
  try {
    const lang = Cookies.get("language") ?? "";

    return LANGUAGE_LIST.map((l) => l.key).includes(lang) ? lang : "vi";
  } catch (_) {
    return "vi";
  }
}

const systemService = {
  updateLanguage,
  getLanguage,
};

export default systemService;
