// Libraries
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { useMemo, useState } from "react";

// Resources
import { ReactComponent as SealWarning } from "#src/assets/images/icons/seal-warning.svg";

// Components
import { ContentSection, StickySection } from "#src/layouts/content";
import NoResultsState from "#src/components/common/states/NoResultsState";
import useFetch from "#src/hooks/useFetch";

// API-related
import trustCenterServices from "#src/services/trustCenter";

// Children
import TrustCenterComplianceTable from "#src/components/trustCenter/compliance/ComplianceTable";
import TrustCenterVerifyInfoModal from "#src/components/trustCenter/VerifyInfoModal";

const TrustCenterCompliance = () => {
  const { t } = useTranslation("trustCenter", { keyPrefix: "compliance" });

  const { workspace: workspaceId } = useParams<"workspace">();

  const [openInfoModal, setOpenInfoModal] = useState<boolean>(false);

  if (!workspaceId || workspaceId === "null") {
    return <NoResultsState />;
  }

  const workspaceParam = useMemo(() => [workspaceId], [workspaceId]);

  const { result: verificationStatus, isLoading: isLoadingVerification } =
    useFetch(
      trustCenterServices.retrieve_compliance_verification_status,
      workspaceParam
    );

  return (
    <>
      <StickySection>
        <h1>{t("title")}</h1>
        {isLoadingVerification ||
        verificationStatus === null ||
        verificationStatus.verified ? null : (
          <button
            className="p-3 rounded-full font-medium-16 border border-red bg-red bg-opacity-5 text-red flex items-center gap-3"
            onClick={() => {
              setOpenInfoModal(true);
            }}
          >
            {t("unverifiedInformation")}
            <SealWarning className="h-5 w-5" />
          </button>
        )}
      </StickySection>
      <ContentSection>
        <TrustCenterComplianceTable workspaceId={workspaceId} />
      </ContentSection>
      <TrustCenterVerifyInfoModal
        workspaceId={workspaceId}
        open={openInfoModal}
        onClose={() => {
          setOpenInfoModal(false);
        }}
      />
    </>
  );
};

export default TrustCenterCompliance;
