import type { IChartPlotProps } from "#src/@types/chart";
import { forwardRef, type HTMLAttributes } from "react";
import { twMerge } from "tailwind-merge";

interface IExperimentalPercentStackedBarChartProps
  extends HTMLAttributes<HTMLDivElement> {
  data: IChartPlotProps[];
}

/** Horizontal Percent Stacked Bar Chart */
const ExperimentalPercentStackedBarChart = forwardRef<
  HTMLDivElement,
  IExperimentalPercentStackedBarChartProps
>(({ data, ...props }, ref) => {
  const totalValue = data.reduce((prev, cur) => {
    return prev + cur.value;
  }, 0);

  return (
    <div
      ref={ref}
      {...props}
      className={twMerge(
        "flex h-5 rounded-[0.25rem] overflow-hidden text-white font-medium-14-forced text-center",
        props.className
      )}
    >
      {data.map((item) => {
        const percent = (item.value / totalValue) * 100;

        return (
          <div
            key={item.name}
            style={{ backgroundColor: item.fill, width: percent + "%" }}
            className="h-full"
          >
            {percent >= 10 ? percent.toFixed(0) + "%" : null}
          </div>
        );
      })}
    </div>
  );
});

ExperimentalPercentStackedBarChart.displayName =
  "ExperimentalPercentStackedBarChart";

export default ExperimentalPercentStackedBarChart;
