import { useTranslation } from "react-i18next";
import { BugBountyProgramTypeEnum } from "../enum";

interface IProgramTypeItemProps {
  type: (typeof BugBountyProgramTypeEnum)[keyof typeof BugBountyProgramTypeEnum];
  title: string;
  description: string;
  disabled: boolean;
}

interface IAddBugBountyProgramTypeProps {
  programType: string;
  onChangeProgramType: (value: string) => void;
  disabled?: boolean;
}

const AddBugBountyProgramType = ({
  programType,
  onChangeProgramType,
  disabled = false,
}: IAddBugBountyProgramTypeProps) => {
  const { t } = useTranslation("bugBounty", {
    keyPrefix: "page.addBugBounty.programType",
  });

  const ProgramTypeItem = ({
    type,
    title,
    description,
    disabled,
  }: IProgramTypeItemProps) => {
    return !disabled || programType === type ? (
      <label
        className={
          "p-6 flex-1 flex flex-col gap-4 bg-bright-grey" +
          (disabled ? "" : " hover:cursor-pointer")
        }
      >
        <div className="flex gap-3 font-medium-18">
          <input
            type="radio"
            className="hover:cursor-pointer"
            checked={programType === type}
            onChange={() => {
              onChangeProgramType(type);
            }}
            disabled={disabled}
          />
          {title}
        </div>
        <span className="text-hard-grey">{description}</span>
      </label>
    ) : null;
  };

  return (
    <div className="flex flex-col gap-4">
      <h2>
        <span className="text-primary">* </span>
        {t("title")}
      </h2>
      <div className="flex gap-6">
        <ProgramTypeItem
          type={BugBountyProgramTypeEnum.PUBLIC}
          title={t("public.title")}
          description={t("public.description")}
          disabled={disabled}
        />
        <ProgramTypeItem
          type={BugBountyProgramTypeEnum.SEMI_PRIVATE}
          title={t("semiPrivate.title")}
          description={t("semiPrivate.description")}
          disabled={disabled}
        />
        <ProgramTypeItem
          type={BugBountyProgramTypeEnum.PRIVATE}
          title={t("private.title")}
          description={t("private.description")}
          disabled={disabled}
        />
      </div>
    </div>
  );
};

export default AddBugBountyProgramType;
