/** READING ARTICLES:
 * https://stackoverflow.com/questions/60581285/execcommand-is-now-obsolete-whats-the-alternative
 * https://developer.mozilla.org/en-US/docs/Web/API/Document/execCommand
 * tl;dr: browsers don't really support editing rich texts, so a lot of problems in this file are just not available to be fixed at the moment. Last update: 06/04/2024.
 * Avoiding using rich text editor libraries can be justified by the state of maintenance of those libraries. Most of them are no longer being maintained anymore, and they're likely to be incompatible/deprecated with current browsers' standards.
 */

import HeadingButton from "./Tools/Heading";
import BoldButton from "./Tools/Bold";
import ItalicButton from "./Tools/Italic";
import QuoteButton from "./Tools/Quote";
import CodeButton from "./Tools/Code";
import LinkButton from "./Tools/Link";
import ImageButton from "./Tools/Image";
import UnorderedListButton from "./Tools/UnorderedList";
import OrderedListButton from "./Tools/OrderedList";

const EditorTools = ({ mode }: { mode: "write" | "preview" }) => {
  return (
    <div className="flex items-center gap-1 text-hard-grey">
      <HeadingButton disabled={mode === "preview"} />
      <BoldButton disabled={mode === "preview"} />
      <ItalicButton disabled={mode === "preview"} />
      <QuoteButton disabled={mode === "preview"} />
      <CodeButton disabled={mode === "preview"} />
      <LinkButton disabled={mode === "preview"} />
      <ImageButton disabled={mode === "preview"} />
      <UnorderedListButton disabled={mode === "preview"} />
      <OrderedListButton disabled={mode === "preview"} />
    </div>
  );
};

export default EditorTools;
