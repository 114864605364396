// Libraries
import { Drawer, Tabs } from "@lockerpm/design";
import { useState, type Dispatch, type SetStateAction, useEffect } from "react";
import { useTranslation } from "react-i18next";

// Resources
import { ReactComponent as CloseLine } from "#src/assets/images/icons/close-line.svg";

// General
import type { IDrawerAdapterProps } from "#src/@types/common";

// Components
import { Button, IconButton } from "#src/components/common/system/Button";
import TabButtonLabel from "#src/components/common/helper/antdProps/Tabs/TabButtonLabel";
import { apiErrorHandler } from "#src/utils/apiErrorHandler";

// API-related
import tagsServices from "#src/services/tags";
import devicesServices from "#src/services/devices";

// Children
import AddControlNetworkTab from "./Tabs/NetworkTab";
import AddControlIpAddressTab from "./Tabs/IpAddressTab";
import AddControlPortTab from "./Tabs/PortTab";
import AddControlRestrictionTab from "./Tabs/RestrictionTab";
import { AddControlTabEnum, ControlTableTabEnum } from "../enums";

interface IAddControlDrawer extends IDrawerAdapterProps {
  activeDrawerTab: string;
  setActiveDrawerTab: Dispatch<SetStateAction<string>>;
  setActiveTab: Dispatch<SetStateAction<string>>;
}

const AddControlDrawer = ({
  open,
  onClose,
  workspaceId,
  onRefresh,
  activeDrawerTab,
  setActiveDrawerTab,
  setActiveTab,
}: IAddControlDrawer) => {
  const { t } = useTranslation("devices", {
    keyPrefix: "control.drawers.addNewControl",
  });

  const controlActionItems = [
    {
      key: "controlAction-block",
      value: "block",
      label: t("field.action.block"),
    },
    {
      key: "controlAction-allow",
      value: "allow",
      label: t("field.action.allow"),
    },
  ];

  const controlSourceItems = [
    {
      key: "controlSource-inbound",
      value: "inbound",
      label: t("field.source.inbound"),
    },
    {
      key: "controlSource-outbound",
      value: "outbound",
      label: t("field.source.outbound"),
    },
  ];

  const [domainAddress, setDomainAddress] = useState<string>("");
  const [ipAddress, setIpAddress] = useState<string>("");
  const [portString, setPortString] = useState<string>("");
  const [selectedSoftware, setSelectedSoftware] = useState<{
    key: string;
    value: string;
    label: string;
  } | null>(null);
  const [controlAction, setControlAction] = useState<{
    key: string;
    value: string;
    label: string;
  }>(controlActionItems[0]);
  const [controlSource, setControlSource] = useState<{
    key: string;
    value: string;
    label: string;
  }>(controlSourceItems[0]);
  const [selectedHosts, setSelectedHosts] = useState<
    { key: string; id: string; name: string; label: string }[]
  >([]);
  const [tagList, setTagList] = useState<{ name: string }[]>([]);
  const [note, setNote] = useState<string>("");

  const [hostIdCounter, setHostIdCounter] = useState(0);

  const [tagItems, setTagItems] = useState<
    { key: string; value: string; label: string }[]
  >([]);

  const onChangeDomainAddress = (value: string) => {
    setDomainAddress(value);
  };

  const onChangeIpAddress = (value: string) => {
    setIpAddress(value);
  };

  const onChangePortString = (value: string) => {
    setPortString(value);
  };

  const onChangeSoftware = (value: {
    key: string;
    value: string;
    label: string;
  }) => {
    setSelectedSoftware(value);
  };

  const onSelectControlAction = (key: string) => {
    const selectedAction = controlActionItems.find((item) => item.key === key);
    if (selectedAction) {
      setControlAction(selectedAction);
    }
  };

  const onSelectControlSource = (key: string) => {
    const selectedSource = controlSourceItems.find((item) => item.key === key);
    if (selectedSource) {
      setControlSource(selectedSource);
    }
  };

  const onAddDefaultHost = () => {
    setHostIdCounter((prev) => prev + 1);
    setSelectedHosts((prev) => [
      ...prev,
      {
        key: (hostIdCounter + 1).toString(),
        id: "",
        name: "",
        label: "",
      },
    ]);
  };

  const onChangeHost = (newHost: {
    key: string;
    id: string;
    name: string;
    label: string;
  }) => {
    setSelectedHosts((prev) => {
      const changingHost = prev.findIndex((item) => item.key === newHost.key);
      let newList = prev;
      newList[changingHost] = newHost;
      return [...newList];
    });
  };

  const onRemoveHost = (removedHost: {
    key: string;
    id: string;
    name: string;
    label: string;
  }) => {
    setSelectedHosts((prev) => {
      return prev.filter((host) => host.key !== removedHost.key);
    });
  };

  const onChangeTags = (tags: { name: string }[]) => {
    setTagList(tags);
  };

  const onChangeNote = (value: string) => {
    setNote(value);
  };

  const onChangeTab = (key: string) => {
    setActiveDrawerTab(key);
    onCleanup();
  };

  const tabList = [
    {
      key: AddControlTabEnum.NETWORK,
      label: <TabButtonLabel name={t("tab.network.title")} />,
      children: (
        <AddControlNetworkTab
          workspaceId={workspaceId}
          controlActionItems={controlActionItems}
          controlSourceItems={controlSourceItems}
          domainAddress={domainAddress}
          controlAction={controlAction}
          controlSource={controlSource}
          selectedHosts={selectedHosts}
          tagList={tagList}
          note={note}
          onChangeDomainAddress={onChangeDomainAddress}
          onSelectControlAction={onSelectControlAction}
          onSelectControlSource={onSelectControlSource}
          onChangeHost={onChangeHost}
          onRemoveHost={onRemoveHost}
          onAddDefaultHost={onAddDefaultHost}
          onChangeTags={onChangeTags}
          onChangeNote={onChangeNote}
        />
      ),
    },
    {
      key: AddControlTabEnum.IP_ADDRESS,
      label: <TabButtonLabel name={t("tab.ipAddress.title")} />,
      children: (
        <AddControlIpAddressTab
          workspaceId={workspaceId}
          controlActionItems={controlActionItems}
          controlSourceItems={controlSourceItems}
          ipAddress={ipAddress}
          controlAction={controlAction}
          controlSource={controlSource}
          selectedHosts={selectedHosts}
          tagList={tagList}
          note={note}
          onChangeIpAddress={onChangeIpAddress}
          onSelectControlAction={onSelectControlAction}
          onSelectControlSource={onSelectControlSource}
          onChangeHost={onChangeHost}
          onRemoveHost={onRemoveHost}
          onAddDefaultHost={onAddDefaultHost}
          onChangeTags={onChangeTags}
          onChangeNote={onChangeNote}
        />
      ),
    },
    {
      key: AddControlTabEnum.PORT,
      label: <TabButtonLabel name={t("tab.ports.title")} />,
      children: (
        <AddControlPortTab
          workspaceId={workspaceId}
          controlActionItems={controlActionItems}
          controlSourceItems={controlSourceItems}
          portString={portString}
          controlAction={controlAction}
          controlSource={controlSource}
          selectedHosts={selectedHosts}
          tagList={tagList}
          note={note}
          onChangePortString={onChangePortString}
          onSelectControlAction={onSelectControlAction}
          onSelectControlSource={onSelectControlSource}
          onChangeHost={onChangeHost}
          onRemoveHost={onRemoveHost}
          onAddDefaultHost={onAddDefaultHost}
          onChangeTags={onChangeTags}
          onChangeNote={onChangeNote}
        />
      ),
    },
    {
      key: AddControlTabEnum.RESTRICTION,
      label: <TabButtonLabel name={t("tab.restriction.title")} />,
      children: (
        <AddControlRestrictionTab
          workspaceId={workspaceId}
          // controlActionItems={controlActionItems}
          selectedSoftware={selectedSoftware}
          // controlAction={controlAction}
          selectedHosts={selectedHosts}
          tagList={tagList}
          note={note}
          onChangeSoftware={onChangeSoftware}
          // onSelectControlAction={onSelectControlAction}
          onChangeHost={onChangeHost}
          onRemoveHost={onRemoveHost}
          onAddDefaultHost={onAddDefaultHost}
          onChangeTags={onChangeTags}
          onChangeNote={onChangeNote}
        />
      ),
    },
  ];

  const onAddControlNetwork = () => {
    devicesServices
      .create_control(workspaceId, {
        group: "network",
        source: controlSource.value,
        action:
          controlAction.value === "block"
            ? "block_network"
            : controlAction.value === "allow"
            ? "allow_network"
            : "",
        description: note,
        matches: [domainAddress],
        hosts_tags: [
          ...selectedHosts.map((host) => ({
            id: host.id,
            type: "host",
          })),
          ...tagList
            .map((tag) => {
              const tagId = tagItems.find(
                (item) => item.label === tag.name
              )?.value;
              return {
                id: typeof tagId === "string" ? tagId : "-1",
                type: "tag",
              };
            })
            .filter((tag) => tag.id !== "-1"),
        ],
      })
      .then((response) => {
        if (response.id) {
          setActiveTab(ControlTableTabEnum.NETWORK);
          onCloseAndCleanup();
          onRefresh();
        }
      })
      .catch((error) => {
        apiErrorHandler(error, {
          toastMessage: t("notification.createControl.fail"),
        });
      });
  };

  const onAddControlIpAddress = () => {
    devicesServices
      .create_control(workspaceId, {
        group: "network",
        source: controlSource.value,
        action:
          controlAction.value === "block"
            ? "block_network_ip"
            : controlAction.value === "allow"
            ? "allow_network_ip"
            : "",
        description: note,
        matches: [ipAddress],
        hosts_tags: [
          ...selectedHosts.map((host) => ({
            id: host.id,
            type: "host",
          })),
          ...tagList
            .map((tag) => {
              const tagId = tagItems.find(
                (item) => item.label === tag.name
              )?.value;
              return {
                id: typeof tagId === "string" ? tagId : "-1",
                type: "tag",
              };
            })
            .filter((tag) => tag.id !== "-1"),
        ],
      })
      .then((response) => {
        if (response.id) {
          setActiveTab(ControlTableTabEnum.IP_ADDRESS);
          onCloseAndCleanup();
          onRefresh();
        }
      })
      .catch((error) => {
        apiErrorHandler(error, {
          toastMessage: t("notification.createControl.fail"),
        });
      });
  };

  const onAddControlPort = () => {
    devicesServices
      .create_control(workspaceId, {
        group: "network",
        source: controlSource.value,
        action:
          controlAction.value === "block"
            ? "block_network_port"
            : controlAction.value === "allow"
            ? "allow_network_port"
            : "",
        description: note,
        matches: [portString],
        hosts_tags: [
          ...selectedHosts.map((host) => ({
            id: host.id,
            type: "host",
          })),
          ...tagList
            .map((tag) => {
              const tagId = tagItems.find(
                (item) => item.label === tag.name
              )?.value;
              return {
                id: typeof tagId === "string" ? tagId : "-1",
                type: "tag",
              };
            })
            .filter((tag) => tag.id !== "-1"),
        ],
      })
      .then((response) => {
        if (response.id) {
          setActiveTab(ControlTableTabEnum.PORT);
          onCloseAndCleanup();
          onRefresh();
        }
      })
      .catch((error) => {
        apiErrorHandler(error, {
          toastMessage: t("notification.createControl.fail"),
        });
      });
  };

  const onAddControlRestriction = () => {
    if (selectedSoftware) {
      devicesServices
        .create_control(workspaceId, {
          group: "file",
          // action:
          //   controlAction.value === "block"
          //     ? "block_file"
          //     : controlAction.value === "allow"
          //     ? "allow_file"
          //     : "",
          action: "block_file",
          description: note,
          matches: [selectedSoftware.value],
          hosts_tags: [
            ...selectedHosts.map((host) => ({
              id: host.id,
              type: "host",
            })),
            ...tagList
              .map((tag) => {
                const tagId = tagItems.find(
                  (item) => item.label === tag.name
                )?.value;
                return {
                  id: typeof tagId === "string" ? tagId : "-1",
                  type: "tag",
                };
              })
              .filter((tag) => tag.id !== "-1"),
          ],
        })
        .then((response) => {
          if (response.id) {
            setActiveTab(ControlTableTabEnum.RESTRICTION);
            onCloseAndCleanup();
            onRefresh();
          }
        })
        .catch((error) => {
          apiErrorHandler(error, {
            toastMessage: t("notification.createControl.fail"),
          });
        });
    }
  };

  const onCleanup = () => {
    setDomainAddress("");
    setIpAddress("");
    setPortString("");
    setSelectedSoftware(null);
    setControlAction(controlActionItems[0]);
    setControlSource(controlSourceItems[0]);
    setSelectedHosts([]);
    setTagList([]);
    setNote("");
    setHostIdCounter(0);
  };

  const onCloseAndCleanup = () => {
    onClose();
    onCleanup();
  };

  useEffect(() => {
    let ignore = false;

    if (!ignore) {
      tagsServices
        .list_workspace_tags_shortly(workspaceId)
        .then((response) => {
          setTagItems(
            response.map((item) => ({
              key: item.id.toString(),
              value: item.id.toString(),
              label: item.name,
            }))
          );
        })
        .catch((error) => {
          apiErrorHandler(error, {
            toastMessage: t("notification.loadTagList.fail"),
          });
        });
    }

    return () => {
      ignore = true;
    };
  }, [workspaceId, t]);

  return (
    <Drawer
      open={open}
      onClose={onCloseAndCleanup}
      closeIcon={null}
      title={<h2>{t("title")}</h2>}
      extra={
        <IconButton variant="label-grey" size={9} onClick={onCloseAndCleanup}>
          <CloseLine />
        </IconButton>
      }
      footer={
        <div className="flex flex-col gap-3">
          <Button
            className="w-full"
            size="large"
            onClick={
              activeDrawerTab === AddControlTabEnum.NETWORK
                ? onAddControlNetwork
                : activeDrawerTab === AddControlTabEnum.IP_ADDRESS
                ? onAddControlIpAddress
                : activeDrawerTab === AddControlTabEnum.PORT
                ? onAddControlPort
                : activeDrawerTab === AddControlTabEnum.RESTRICTION
                ? onAddControlRestriction
                : () => {}
            }
            disabled={
              ![
                AddControlTabEnum.NETWORK,
                AddControlTabEnum.IP_ADDRESS,
                AddControlTabEnum.PORT,
                AddControlTabEnum.RESTRICTION,
              ].includes(activeDrawerTab)
            }
          >
            {activeDrawerTab === AddControlTabEnum.NETWORK
              ? t("button.addNewNetworkControl")
              : activeDrawerTab === AddControlTabEnum.IP_ADDRESS
              ? t("button.addNewIpControl")
              : activeDrawerTab === AddControlTabEnum.PORT
              ? t("button.addNewPortControl")
              : activeDrawerTab === AddControlTabEnum.RESTRICTION
              ? t("button.addNewRestrictionControl")
              : t("button.addNewControl")}
          </Button>
          <Button
            variant="secondary"
            className="w-full"
            size="large"
            onClick={onCloseAndCleanup}
          >
            {t("button.cancel")}
          </Button>
        </div>
      }
    >
      <div className="p-6">
        <Tabs
          items={tabList}
          activeKey={activeDrawerTab}
          onChange={onChangeTab}
          destroyInactiveTabPane
        />
      </div>
    </Drawer>
  );
};

export default AddControlDrawer;
