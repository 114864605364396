import type { Params, RouteObject } from "react-router-dom";
import i18next from "i18next";
import { helperPathname, pathname } from "#src/config/pathname";

import AssetsDomains from "#src/pages/assets/domains";
import AssetsDomainsDetails from "#src/pages/assets/domains/details";
import AssetsIpAddress from "#src/pages/assets/ipAddress";
import AssetsIpAddressDetails from "#src/pages/assets/ipAddress/details";
import AssetsCertificates from "#src/pages/assets/certificates";
import AssetsCertificatesDetails from "#src/pages/assets/certificates/details";

const breadcrumbTFunc = i18next.getFixedT(null, "general", "breadcrumb");

export const assetRoute: RouteObject = {
  path: helperPathname.ASSETS,
  handle: {
    crumb: () => ({
      getText: () => breadcrumbTFunc("asset.label"),
      path: pathname.DOMAINS,
    }),
  },
  children: [
    {
      path: pathname.DOMAINS,
      handle: {
        crumb: () => ({
          getText: () => breadcrumbTFunc("asset.domain.label"),
          path: pathname.DOMAINS,
        }),
      },
      children: [
        {
          index: true,
          element: <AssetsDomains />,
        },
        {
          path: pathname.DOMAIN_DETAILS,
          handle: {
            crumb: (params: Readonly<Params<string>>) => ({
              getText: () =>
                Object.hasOwn(params, "asset") ? params["asset"] : "---",
              path: pathname.DOMAIN_DETAILS,
            }),
          },
          element: <AssetsDomainsDetails />,
        },
      ],
    },
    {
      path: pathname.IP_ADDRESS,
      handle: {
        crumb: () => ({
          getText: () => breadcrumbTFunc("asset.ipAddress.label"),
          path: pathname.IP_ADDRESS,
        }),
      },
      children: [
        {
          index: true,
          element: <AssetsIpAddress />,
        },
        {
          path: pathname.IP_ADDRESS_DETAILS,
          handle: {
            crumb: (params: Readonly<Params<string>>) => ({
              getText: () =>
                Object.hasOwn(params, "asset") ? params["asset"] : "---",
              path: pathname.IP_ADDRESS_DETAILS,
            }),
          },
          element: <AssetsIpAddressDetails />,
        },
      ],
    },
    {
      path: pathname.CERTIFICATES,
      handle: {
        crumb: () => ({
          getText: () => breadcrumbTFunc("asset.certificates.label"),
          path: pathname.CERTIFICATES,
        }),
      },
      children: [
        {
          index: true,
          element: <AssetsCertificates />,
        },
        {
          path: pathname.CERTIFICATE_DETAILS,
          handle: {
            crumb: (params: Readonly<Params<string>>) => ({
              getText: () =>
                Object.hasOwn(params, "asset") ? params["asset"] : "---",
              path: pathname.CERTIFICATE_DETAILS,
            }),
          },
          element: <AssetsCertificatesDetails />,
        },
      ],
    },
  ],
};
