import endpoint from "#src/config/endpoint";
import request from "#src/utils/request";

export interface IScanSubdomainItem {
  address: string;
  last_found: number;
  ips: string[];
}

export function get_scan_subdomains(
  workspace_id: string,
  scan_id: number,
  params: {
    domain_id: number | undefined;
  }
) {
  return request.get<never, IScanSubdomainItem[]>(
    `${endpoint.WORKSPACES}/${workspace_id}/scans/${scan_id}/subdomains`,
    { params }
  );
}
