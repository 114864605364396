import { ReactComponent as ArrowDownSLine } from "#src/assets/images/icons/arrow-down-s-line.svg";
import { useTranslation } from "react-i18next";
import { Checkbox, Dropdown } from "@lockerpm/design";
import DropdownItemLabel from "#src/components/common/helper/antdProps/Dropdown/DropdownItemLabel";

interface ISelectAllItemsDropdownProps {
  selectedCount: number;
  totalCount: number;
  onSelectAll: () => void;
  onDeselectAll: () => void;
}

const SelectAllItemsDropdown = ({
  selectedCount,
  totalCount,
  onSelectAll,
  onDeselectAll,
}: ISelectAllItemsDropdownProps) => {
  const { t } = useTranslation("common", {
    keyPrefix: "filter.selectAll",
  });

  /** A list of items for the "select item" menu */
  const selectingMenuItems = [
    {
      key: "all",
      label: (
        <DropdownItemLabel selected={selectedCount === totalCount}>
          {t("all")}
        </DropdownItemLabel>
      ),
    },
    {
      key: "none",
      label: (
        <DropdownItemLabel selected={selectedCount === 0}>
          {t("none")}
        </DropdownItemLabel>
      ),
    },
  ];

  return (
    <Dropdown
      menu={{
        items: selectingMenuItems,
        onClick: ({ key }) => {
          if (key === "all") {
            onSelectAll();
            return;
          }
          if (key === "none") {
            onDeselectAll();
            return;
          }
        },
      }}
      trigger={["click"]}
    >
      <button className="flex gap-2 items-center px-4 py-3 bg-bright-grey rounded-md">
        <Checkbox
          checked={selectedCount >= totalCount && totalCount > 0}
          indeterminate={selectedCount > 0 && selectedCount < totalCount}
          onClick={(e) => {
            e.stopPropagation();
            if (selectedCount > 0) {
              onDeselectAll();
            } else {
              onSelectAll();
            }
          }}
        />
        <ArrowDownSLine className="text-hard-grey" />
        {selectedCount > 0 ? (
          <p className="font-bold-16 text-primary">{selectedCount}</p>
        ) : null}
      </button>
    </Dropdown>
  );
};

export default SelectAllItemsDropdown;
