// Libraries
import { Dropdown } from "@lockerpm/design";
import { useEffect, useMemo, useState, type ReactNode } from "react";
import { UseFormReturn } from "react-hook-form";
import { useTranslation } from "react-i18next";

// Resources
import { ReactComponent as ArrowDownSLine } from "#src/assets/images/icons/arrow-down-s-line.svg";
import { ReactComponent as ProfileLine } from "#src/assets/images/icons/profile-line.svg";
import { ReactComponent as Asterisk } from "#src/assets/images/icons/asterisk.svg";

// Components
import DropdownItemLabel from "#src/components/common/helper/antdProps/Dropdown/DropdownItemLabel";
import DropdownTriggerButton from "#src/components/common/helper/antdProps/Dropdown/DropdownTriggerButton";
import { Input, TextArea } from "#src/components/common/system/Input";

// API-related
import resourceServices from "#src/services/resource";

// Children
import { CardHeader } from "#src/pages/workspace/details";

export interface IWorkspaceFormBasic {
  name: string;
  email: string;
  short_name: string;
  phone: string;
  vat_tax_code: string;
  address: string;
  website: string;
  description: string;
  country_code: string;
  country_phone_code?: string;
}

interface IBasicInformationProps {
  isEditing: boolean;
  methods: UseFormReturn<IWorkspaceFormBasic>;
}
interface ICountrySelect {
  key: string;
  value: string;
  label: ReactNode;
}

const WorkspaceBasicInformation = ({
  isEditing = false,
  methods,
}: IBasicInformationProps) => {
  const { t } = useTranslation("workspace", { keyPrefix: "detail" });
  const { register, setValue, watch, getValues } = methods;

  const [countries, setCountries] = useState<ICountrySelect[]>([]);
  const countryCode = watch("country_code");

  useEffect(() => {
    const fetchCountry = async () => {
      const response = await resourceServices.list_countries();
      setCountries(() => {
        return response.map((item) => ({
          key: item.country_code,
          value: item.country_phone_code,
          label: item.country_phone_code,
        }));
      });
      if (!getValues("country_code")) {
        setValue("country_code", "VN");
      }
    };

    fetchCountry();
  }, [countryCode, getValues, setValue]);

  // TODO: remove this
  useEffect(() => {
    if (countryCode) {
      const phoneCode = countries.find((item) => item.key === countryCode);
      if (phoneCode) {
        setValue("country_phone_code", phoneCode.value);
      }
    }
  }, [countryCode, countries, setValue]);

  const currentFlag = useMemo(() => {
    return countries.find((item) => item.key === countryCode);
  }, [countries, countryCode]);

  return (
    <div className="rounded-md border border-solid border-light-grey">
      <CardHeader icon={<ProfileLine />} title={t("basicInformation.title")} />
      <div className="p-6">
        <div className="grid grid-cols-2 gap-6">
          <div className="col-span-1 space-y-4">
            <div className="form-item space-y-3">
              <span className="font-medium-14-forced uppercase text-severity-none flex items-center">
                <Asterisk className="-mt-2" />
                {t("basicInformation.form.companyName")}
              </span>
              <Input
                className="font-regular-16"
                placeholder={t("basicInformation.form.placeholderCompanyName")}
                {...register("name")}
                disabled={!isEditing}
              />
            </div>
            <div className="form-item space-y-3">
              <span className="font-medium-14-forced uppercase text-severity-none">
                {t("basicInformation.form.shortName")}
              </span>
              <Input
                className="font-regular-16"
                placeholder={t("basicInformation.form.placeholderShortName")}
                {...register("short_name")}
                disabled={!isEditing}
              />
            </div>
            <div className="form-item space-y-3">
              <span className="font-medium-14-forced uppercase text-severity-none">
                {t("basicInformation.form.vatTaxCode")}
              </span>
              <Input
                className="font-regular-16"
                placeholder={t("basicInformation.form.placeholderVatTaxCode")}
                {...register("vat_tax_code")}
                disabled={!isEditing}
              />
            </div>
            <div className="form-item space-y-3">
              <span className="font-medium-14-forced uppercase text-severity-none flex items-center">
                <Asterisk className="-mt-2" />
                {t("basicInformation.form.homepage")}
              </span>
              <Input
                className="font-regular-16"
                placeholder={t("basicInformation.form.placeholderHomepage")}
                {...register("website")}
                disabled={!isEditing}
              />
            </div>
          </div>
          <div className="col-span-1 space-y-4">
            <div className="form-item space-y-3">
              <span className="font-medium-14-forced uppercase text-severity-none">
                {t("basicInformation.form.email")}
              </span>
              <Input
                className="font-regular-16"
                placeholder={t("basicInformation.form.placeholderEmail")}
                {...register("email")}
                disabled={!isEditing}
              />
            </div>
            <div className="form-item space-y-3">
              <span className="font-medium-14-forced uppercase text-severity-none">
                {t("basicInformation.form.phone")}
              </span>
              <div className="flex gap-2">
                <Dropdown
                  menu={{
                    items: countries.map((country) => ({
                      key: country.key,
                      value: country.value,
                      label: (
                        <DropdownItemLabel
                          selected={country.key === countryCode}
                          syncWidthId="countryCode-dropdownTrigger"
                        >
                          <img
                            src={`/country-flag/${country.key.toLowerCase()}.svg`}
                            className="w-[1.125rem] h-[1.125rem] object-cover rounded-[3px]"
                          />
                          {country.label}
                        </DropdownItemLabel>
                      ),
                    })),
                    onClick: ({ key }) => {
                      const clickedItem = countries.find(
                        (item) => item.key === key
                      );
                      if (clickedItem) {
                        setValue("country_code", clickedItem.key);
                      }
                    },
                  }}
                  trigger={["click"]}
                  disabled={!isEditing}
                >
                  <DropdownTriggerButton
                    className="bg-bright-blue p-3 min-w-[7.5rem]"
                    id="countryCode-dropdownTrigger"
                  >
                    {currentFlag ? (
                      <>
                        <img
                          src={`/country-flag/${currentFlag.key.toLowerCase()}.svg`}
                          className="w-[1.125rem] h-[1.125rem] object-cover rounded-[3px]"
                        />
                        {currentFlag.label}
                      </>
                    ) : null}
                    <ArrowDownSLine className="min-w-[1rem] w-4 h-4" />
                  </DropdownTriggerButton>
                </Dropdown>
                <Input
                  className="font-regular-16"
                  placeholder={t("basicInformation.form.placeholderPhone")}
                  {...register("phone")}
                  disabled={!isEditing}
                />
              </div>
            </div>
            <div className="form-item space-y-3">
              <span className="font-medium-14-forced uppercase text-severity-none">
                {t("basicInformation.form.address")}
              </span>
              <Input
                className="font-regular-16"
                placeholder={t("basicInformation.form.placeholderAddress")}
                {...register("address")}
                disabled={!isEditing}
              />
            </div>
            <div className="form-item space-y-3">
              <span className="font-medium-14-forced uppercase text-severity-none">
                {t("basicInformation.form.description")}
              </span>
              <TextArea
                rows={4}
                className="font-regular-16"
                placeholder={t("basicInformation.form.placeholderDescription")}
                {...register("description")}
                disabled={!isEditing}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WorkspaceBasicInformation;
