import type { IPaginateParams, IPaginatedResponse } from "#src/@types/api";
import endpoint from "#src/config/endpoint";
import request from "#src/utils/request";

// This is exactly equal to IAssetPortItem, but I'll keep it separate just in case
export interface IScanPortItem {
  port: number;
  protocol: string;
  service: string;
  product: string;
  version: string;
  cpe: string;
  last_found: number;
}

export function list_scan_ports(
  workspace_id: string,
  scan_id: number,
  params: {
    protocol: string | undefined;
    domain_id: number | undefined;
  } & IPaginateParams
) {
  return request.get<never, IPaginatedResponse<IScanPortItem>>(
    `${endpoint.WORKSPACES}/${workspace_id}/scans/${scan_id}/open_ports`,
    { params }
  );
}
