// Libraries
import { Switch } from "@lockerpm/design";
import { useTranslation } from "react-i18next";
import { twMerge } from "tailwind-merge";
import {
  forwardRef,
  type HTMLAttributes,
  useCallback,
  useEffect,
  useImperativeHandle,
  useState,
} from "react";
import { generatePath, useNavigate } from "react-router-dom";

// Resources
import { ReactComponent as ArrowDownSLine } from "#src/assets/images/icons/arrow-down-s-line.svg";
import { ReactComponent as ArrowUpSLine } from "#src/assets/images/icons/arrow-up-s-line.svg";

// General
import { pathname } from "#src/config/pathname";

// Components
import { Input } from "#src/components/common/system/Input";
import SeveritySelect from "#src/components/common/SeveritySelect";
import { toPascalCase } from "#src/utils/common";

// API-related
import bugBountyServices, {
  type IBugBountyDetails,
} from "#src/services/bugBounty";

// TODO: might want to make this a common component
/** Input's arrow button for increasing and decreasing number value. */
const ArrowButton = ({
  type,
  onClick,
  disabled,
}: {
  type: "INCREASE" | "DECREASE";
  onClick: () => void;
  disabled?: boolean;
}) => {
  return (
    <button
      className="bg-bright-grey text-hard-grey h-9 w-9 min-h-[2.25rem] min-w-[2.25rem] rounded-md"
      onClick={onClick}
      disabled={disabled}
    >
      {type === "DECREASE" ? (
        <ArrowDownSLine height={"1.25rem"} width={"1.25rem"} />
      ) : type === "INCREASE" ? (
        <ArrowUpSLine height={"1.25rem"} width={"1.25rem"} />
      ) : null}
    </button>
  );
};

// TODO: consider infuse this into the Row component below
const RowWrapper = forwardRef<HTMLDivElement, HTMLAttributes<HTMLDivElement>>(
  (props, ref) => {
    return (
      <div
        ref={ref}
        {...props}
        className={twMerge(
          "grid grid-cols-subgrid col-span-full items-center px-6 h-[4.5rem]",
          props.className
        )}
      ></div>
    );
  }
);

// TODO: might want to move these into enum file
type IRequirementInputTypeEnum = "INTEGER" | "PERCENTAGE" | "SEVERITY" | "NULL";

const requirementInputType: { [R: string]: IRequirementInputTypeEnum } = {
  id_verification: "NULL",
  nda_signing: "NULL",
  submission_count: "INTEGER",
  accuracy: "PERCENTAGE",
  minimum_priority: "SEVERITY",
  verified_countries: "NULL",
};

interface IBugBountyRequirementRowProps {
  title: string;
  type: IRequirementInputTypeEnum;
  enable: boolean;
  value: string;
  onChangeEnable: (value: boolean) => void;
  onChangeValue: (value: string) => void;
  isEditing: boolean;
}

// TODO: might want to move this into another file
const BugBountyRequirementRow = ({
  title,
  type,
  enable,
  value,
  onChangeEnable,
  onChangeValue,
  isEditing,
}: IBugBountyRequirementRowProps) => {
  return (
    <RowWrapper>
      <div className="px-3">{title}</div>
      {type === "INTEGER" ? (
        isEditing ? (
          <div className="flex gap-1">
            <ArrowButton
              type="DECREASE"
              onClick={() => {
                const newValue = Number(value) - 1 >= 0 ? Number(value) - 1 : 0;

                onChangeValue(newValue.toString());
              }}
              disabled={!isEditing}
            />
            <Input
              className="text-center px-2 min-h-[2.25rem] h-9"
              value={value}
              type="text"
              inputMode="numeric"
              onChange={(e) => {
                if (
                  Number.isInteger(Number(e.target.value)) &&
                  !e.target.value.includes(".")
                ) {
                  onChangeValue(e.target.value);
                }
              }}
            />
            <ArrowButton
              type="INCREASE"
              onClick={() => {
                const newValue =
                  Number(value) + 1 <= 100 ? Number(value) + 1 : 100;

                onChangeValue(newValue.toString());
              }}
              disabled={!isEditing}
            />
          </div>
        ) : (
          <div className="px-3">{value}</div>
        )
      ) : type === "PERCENTAGE" ? (
        isEditing ? (
          <div className="flex gap-1">
            <ArrowButton
              type="DECREASE"
              onClick={() => {
                const newValue = Number(value) - 1 >= 0 ? Number(value) - 1 : 0;

                onChangeValue(newValue.toString());
              }}
              disabled={!isEditing}
            />
            <Input
              className="text-center px-2 min-h-[2.25rem] h-9"
              value={value}
              type="text"
              inputMode="numeric"
              onChange={(e) => {
                if (
                  Number.isFinite(Number(e.target.value)) &&
                  Number(e.target.value) <= 100
                ) {
                  onChangeValue(e.target.value);
                }
              }}
            />
            <ArrowButton
              type="INCREASE"
              onClick={() => {
                const newValue =
                  Number(value) + 1 <= 100 ? Number(value) + 1 : 100;

                onChangeValue(newValue.toString());
              }}
              disabled={!isEditing}
            />
          </div>
        ) : (
          <div className="px-3">{value}</div>
        )
      ) : type === "SEVERITY" ? (
        <div>
          <SeveritySelect
            severityValue={value}
            updateData={(severity) => {
              if (severity) {
                onChangeValue(severity);
              }
            }}
          />
        </div>
      ) : (
        <div></div>
      )}
      <div className="px-3">
        <Switch
          checked={enable}
          onClick={() => {
            onChangeEnable(!enable);
          }}
          disabled={!isEditing}
        />
      </div>
    </RowWrapper>
  );
};

RowWrapper.displayName = "RequirementsRowWrapper";

interface IBugBountyRequirementInput {
  id: string;
  enable: boolean;
  value: string | any[];
}

interface IBugBountyDetailsRequirementsRef {
  onSaveChanges: () => void;
}

interface IBugBountyDetailsRequirementsProps {
  workspaceId: string;
  bugBountyAlias: string;
  isEditing: boolean;
  onRefresh: () => void;
}

const BugBountyDetailsRequirements = forwardRef<
  IBugBountyDetailsRequirementsRef,
  IBugBountyDetailsRequirementsProps
>(({ workspaceId, bugBountyAlias, isEditing, onRefresh }, ref) => {
  const { t } = useTranslation("bugBounty", {
    keyPrefix: "page.bugBountyDetails.tab.requirements",
  });

  const headers = [
    t("headers.requirement"),
    t("headers.value"),
    t("headers.onOff"),
  ];

  const navigate = useNavigate();

  const [requirements, setRequirements] = useState<
    IBugBountyRequirementInput[]
  >([]);
  const [originalRequirements, setOriginalRequirements] = useState<
    typeof requirements
  >([]);
  const [allowToSee, setAllowToSee] = useState<boolean>(false);
  const [originalAllowToSee, setOriginalAllowToSee] = useState<boolean>(false);

  const fetchRequirements = useCallback(() => {
    bugBountyServices
      .list_program_requirements(workspaceId, bugBountyAlias)
      .then((response) => {
        setRequirements(
          response.map((requirement) => ({
            id: requirement.requirement_id,
            enable: requirement.turn_on,
            value: requirement.value,
          }))
        );
        setOriginalRequirements(
          response.map((requirement) => ({
            id: requirement.requirement_id,
            enable: requirement.turn_on,
            value: requirement.value,
          }))
        );
      });
  }, [workspaceId, bugBountyAlias]);

  const fetchDetails = useCallback(() => {
    bugBountyServices
      .retrieve_program_details(workspaceId, bugBountyAlias)
      .then((response) => {
        if (Object.keys(response).includes("redirect_alias")) {
          navigate(
            generatePath(pathname.BUG_BOUNTY_DETAILS, {
              workspace: workspaceId,
              bugbounty: (response as { redirect_alias: string })
                .redirect_alias,
            }),
            { replace: true }
          );
        } else {
          const assertedResponse = response as IBugBountyDetails;
          setAllowToSee(assertedResponse.allow_to_see_brief);
          setOriginalAllowToSee(assertedResponse.allow_to_see_brief);
        }
      });
  }, [workspaceId, bugBountyAlias, navigate]);

  const onSaveChanges = useCallback(async () => {
    const checkNeedUpdateRequirement = (
      r: (typeof requirements)[number],
      o: (typeof requirements)[number]
    ): boolean => {
      if (r.enable !== o.enable) return true;

      if (
        Array.isArray(r.value) &&
        Array.isArray(o.value) &&
        r.value.some((rValue, rIndex) => rValue !== o.value[rIndex])
      )
        return true;

      if (
        typeof r.value === "string" &&
        typeof o.value === "string" &&
        r.value !== o.value
      )
        return true;

      return false;
    };

    // TODO: rewrite this so that we only need to refresh once
    if (allowToSee !== originalAllowToSee) {
      bugBountyServices
        .update_allow_to_see_program(workspaceId, bugBountyAlias, {
          allow: allowToSee,
        })
        .then(() => {
          fetchDetails();
          onRefresh();
        });
    }

    // TODO: check what's changed and put those things. Then refresh.
    Promise.allSettled(
      requirements
        .filter((requirement) => {
          const original = originalRequirements.find(
            (r) => r.id === requirement.id
          );

          if (original === undefined) return null;

          if (checkNeedUpdateRequirement(requirement, original)) {
            return true;
          }
          return false;
        })
        .map((requirement) => {
          return bugBountyServices.update_program_requirement(
            workspaceId,
            bugBountyAlias,
            requirement.id,
            { turn_on: requirement.enable, value: requirement.value }
          );
        })
    ).then((responses) => {
      if (responses.length > 0) {
        fetchRequirements();
        onRefresh();
      }
    });
    // TODO: catch
  }, [
    workspaceId,
    bugBountyAlias,
    requirements,
    originalRequirements,
    allowToSee,
    originalAllowToSee,
    onRefresh,
    fetchRequirements,
    fetchDetails,
  ]);

  const onRevertChanges = useCallback(() => {
    setAllowToSee(originalAllowToSee);
    setRequirements(originalRequirements);
  }, [originalAllowToSee, originalRequirements]);

  // WARNING: This is using an escape hatch. Not the ideal way to do things.
  useImperativeHandle(
    ref,
    () => {
      return {
        onSaveChanges,
        onRevertChanges,
      };
    },
    [onSaveChanges, onRevertChanges]
  );

  useEffect(() => {
    let ignore = false;

    if (!ignore) {
      fetchRequirements();
      fetchDetails();
    }

    return () => {
      ignore = true;
    };
  }, [fetchRequirements, fetchDetails]);

  // TODO: wire this with API
  return (
    <section className="flex flex-col gap-6 pt-6 w-2/3">
      <div className="flex flex-col gap-4">
        <h2>{t("title")}</h2>
        <div className="flex items-center gap-3 text-hard-grey">
          <Switch
            checked={allowToSee}
            onClick={() => setAllowToSee((prev) => !prev)}
            disabled={!isEditing}
          />
          {t("allowAnyResearcher")}
        </div>
      </div>
      <div className="grid grid-cols-[3fr_2fr_8rem] gap-3">
        <RowWrapper className="bg-bright-grey text-hard-grey">
          {headers.map((header, index) => (
            <div
              key={`bugBountyDetails-requirements-header${index}`}
              className="px-3"
            >
              {header}
            </div>
          ))}
        </RowWrapper>
        {requirements
          .filter((r) => Object.keys(requirementInputType).includes(r.id))
          .sort(
            (a, b) =>
              Object.keys(requirementInputType).findIndex((r) => r === a.id) -
              Object.keys(requirementInputType).findIndex((r) => r === b.id)
          )
          .map((requirement) => (
            <BugBountyRequirementRow
              key={`bugbounty-requirement-${requirement.id}`}
              title={toPascalCase(requirement.id.replaceAll("_", " "))}
              type={requirementInputType[requirement.id]}
              enable={requirement.enable}
              value={Array.isArray(requirement.value) ? "" : requirement.value}
              onChangeEnable={(value) => {
                setRequirements((prev) =>
                  prev.map((r) =>
                    r.id === requirement.id ? { ...r, enable: value } : r
                  )
                );
              }}
              onChangeValue={(value) => {
                setRequirements((prev) =>
                  prev.map((r) =>
                    r.id === requirement.id ? { ...r, value } : r
                  )
                );
              }}
              isEditing={isEditing}
            />
          ))}
      </div>
    </section>
  );
});

BugBountyDetailsRequirements.displayName = "BugBountyDetailsRequirements";

export default BugBountyDetailsRequirements;
