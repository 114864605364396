// Libraries
import { useTranslation } from "react-i18next";
import { Dropdown } from "@lockerpm/design";
import type { ItemType } from "@lockerpm/design/es/menu/hooks/useItems";
import dayjs from "dayjs";

// Resources
import { ReactComponent as MoreLine } from "#src/assets/images/icons/more-line.svg";
import { ReactComponent as ArrowDownSLine } from "#src/assets/images/icons/arrow-down-s-line.svg";
import { ReactComponent as TimeLine } from "#src/assets/images/icons/time-line.svg";

// Components
import { IconButton } from "#src/components/common/system/Button";
import { ObjectImage } from "#src/components/common/system/Object";
import { toPascalCase } from "#src/utils/common";
import { RoleLabel } from "#src/components/common/labels";
import DropdownItemLabel from "#src/components/common/helper/antdProps/Dropdown/DropdownItemLabel";
import DropdownTriggerButton from "#src/components/common/helper/antdProps/Dropdown/DropdownTriggerButton";

// API-related
import type { IUserInfo } from "#src/services/auth";
import type { IPentestProjectMember } from "#src/services/pentest/members";
import { pentestMemberRoleItems } from "#src/config/filter/pentest";

interface IMemberItemProps {
  currentUser: IUserInfo | null;
  member: IPentestProjectMember;
  actionList: ItemType[];
  onChangeMemberRole: (key: string, item: IPentestProjectMember) => void;
  onClickActionItem: (key: string, item: IPentestProjectMember) => void;
}

const MemberItem = ({
  currentUser,
  member,
  actionList,
  onChangeMemberRole,
  onClickActionItem,
}: IMemberItemProps) => {
  const { t } = useTranslation("pentest", {
    keyPrefix: "page.pentestDetails.tab.members",
  });

  return (
    <div className="border border-grey rounded-md p-6 flex justify-between gap-2">
      <div className="flex items-center gap-2">
        <ObjectImage
          data={member.user?.avatar}
          className="h-11 w-11 min-w-[2.75rem] rounded-full"
        />
        <div className="flex flex-col">
          <div className="font-medium-16 flex gap-2 items-center">
            {member.user ? member.user.full_name : member.email}
            <div className="flex gap-1">
              {member.role === "owner" ? (
                <RoleLabel
                  name={t("label.projectOwner")}
                  color={"bg-hard-grey"}
                />
              ) : null}
              {member.role === "admin" ? (
                <RoleLabel name={t("label.admin")} color={"bg-support-green"} />
              ) : null}
              {member.role === "developer" ? (
                <RoleLabel
                  name={t("label.developer")}
                  color={"bg-support-blue"}
                />
              ) : null}
              {member.default_assign ? (
                <RoleLabel
                  name={t("label.autoAssign")}
                  color={"bg-yellow text-black"}
                />
              ) : null}
            </div>
          </div>
          <span className="font-regular-14 text-primary">
            {"@" + (member.user ? member.user.username : member.email)}
          </span>
        </div>
      </div>
      <div className="flex flex-col gap-2 items-end">
        <div className="flex gap-1">
          {member.email === currentUser?.email ||
          member.user?.email === currentUser?.email ? (
            <div className="h-11" />
          ) : (
            <Dropdown
              menu={{
                items: pentestMemberRoleItems.map((item) => ({
                  key: item.key,
                  label: (
                    <DropdownItemLabel selected={item.value === member.role}>
                      {item.getLabel()}
                    </DropdownItemLabel>
                  ),
                })),
                onClick: ({ key, domEvent }) => {
                  domEvent.preventDefault();
                  domEvent.stopPropagation();
                  onChangeMemberRole(key, member);
                },
              }}
              trigger={["click"]}
            >
              <DropdownTriggerButton id="dropdown-frequency">
                {toPascalCase(member.role)}
                <ArrowDownSLine />
              </DropdownTriggerButton>
            </Dropdown>
          )}
          <Dropdown
            menu={{
              items: actionList,
              onClick: ({ key, domEvent }) => {
                domEvent.preventDefault();
                domEvent.stopPropagation();
                onClickActionItem(key, member);
              },
            }}
            trigger={["click"]}
            disabled={actionList.length === 0}
          >
            <IconButton
              variant="ghost"
              size={9}
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
              }}
            >
              <MoreLine />
            </IconButton>
          </Dropdown>
        </div>
        <div className="flex gap-1 items-center font-regular-12 text-hard-grey">
          <TimeLine height={"0.875rem"} width={"0.875rem"} />
          {t("joined", { timestamp: dayjs.unix(member.access_time).fromNow() })}
        </div>
      </div>
    </div>
  );
};

export default MemberItem;
