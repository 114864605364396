import type { ReactNode } from "react";

/** Used for grey notes which provide some instructions like "E-contract will be signed with DocuSign.". */
export const NoteWrapper = ({ children }: { children: ReactNode }) => {
  return (
    <div className="mt-6 p-6 font-regular-16-body text-hard-grey bg-bright-grey-subline rounded-md">
      {children}
    </div>
  );
};
