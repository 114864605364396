import { ReactComponent as ArrowUpSLine } from "#src/assets/images/icons/arrow-up-s-line.svg";
import { ReactComponent as ArrowDownSLine } from "#src/assets/images/icons/arrow-down-s-line.svg";

interface IVoteButtonProps {
  userVote: boolean | null;
  voteCount: number;
  onUpvote: () => void;
  onDownvote: () => void;
  onUnvote?: () => void;
}

/** Upvote/Downvote button with number of vote.
 * @param userVote current user's vote: they can upvote, downvote or just don't vote
 * @param voteCount total vote count of all system users
 * @param onUpvote
 * @param onDownvote
 * @param onUnvote
 */
export const VoteButtons = ({
  userVote,
  voteCount,
  onUpvote,
  onDownvote,
  onUnvote,
}: IVoteButtonProps) => {
  return (
    <div className="flex flex-col items-center gap-2">
      <button
        className={`h-8 w-8 rounded-full ${
          userVote === true ? "bg-primary" : "bg-bright-blue"
        } flex items-center justify-center`}
        onClick={() => {
          if (userVote === true) {
            if (onUnvote) onUnvote();
          } else {
            onUpvote();
          }
        }}
      >
        <ArrowUpSLine
          className={userVote === true ? "fill-white" : "fill-hard-grey"}
        />
      </button>
      <b>{voteCount}</b>
      <button
        className={`h-8 w-8 rounded-full ${
          userVote === false ? "bg-primary" : "bg-bright-blue"
        } flex items-center justify-center`}
        onClick={() => {
          if (userVote === false) {
            if (onUnvote) onUnvote();
          } else {
            onDownvote();
          }
        }}
      >
        <ArrowDownSLine
          className={userVote === false ? "fill-white" : "fill-hard-grey"}
        />
      </button>
    </div>
  );
};
