// Libraries
import { useTranslation } from "react-i18next";
import { useState } from "react";

// Components
import LoadingState from "#src/components/common/system/LoadingState";
import { NoVulnerabilitiesState } from "#src/components/common/states";
import TableHeader, {
  type ITableHeaderPropItem,
} from "#src/components/common/Table/TableHeader";

// API-related
import {
  type ILeakedDataCookieDetails,
  type ILeakedDataItem,
} from "#src/services/dataLeak";

// Children
import DataLeakCookieTableRow from "./TableRow";
import RawCookieModal from "./RawCookieModal";

interface IDataLeakCookieTableProps {
  workspaceId: string;
  dataLeakList: ILeakedDataItem<ILeakedDataCookieDetails>[] | null;
  isLoading: boolean;
}

const DataLeakCookieTable = ({
  workspaceId,
  dataLeakList,
  isLoading,
}: IDataLeakCookieTableProps) => {
  const { t } = useTranslation("dataLeakDetection", {
    keyPrefix: "tabs.cookie",
  });

  const headers: ITableHeaderPropItem[] = [
    { name: t("headers.domain"), align: "left" },
    { name: t("headers.includeSubdomains"), align: "center" },
    { name: t("headers.path"), align: "left" },
    { name: t("headers.secured"), align: "center" },
    { name: t("headers.expiry"), align: "left" },
    { name: t("headers.name"), align: "left" },
    { name: t("headers.value"), align: "left" },
    { name: "", align: "left" },
  ];

  const [viewingCookie, setViewingCookie] = useState<string | null>(null);

  const onCloseRawCookieModal = () => {
    setViewingCookie(null);
  };

  const onClickViewRaw = (value: typeof viewingCookie) => {
    setViewingCookie(value);
  };

  return (
    <div className="overflow-x-auto">
      {dataLeakList === null || isLoading ? (
        <LoadingState />
      ) : dataLeakList.length === 0 ? (
        <NoVulnerabilitiesState />
      ) : (
        <div className="grid grid-cols-8">
          <TableHeader title="dataleak-Cookie-headers" headerProps={headers} />
          {dataLeakList.map((item) => (
            <DataLeakCookieTableRow
              key={`tdataleak-Cookie-tr${item.id}`}
              workspaceId={workspaceId}
              data={item}
              onClickViewRaw={onClickViewRaw}
            />
          ))}
        </div>
      )}
      <RawCookieModal
        open={viewingCookie !== null}
        onClose={onCloseRawCookieModal}
        rawCookie={viewingCookie ?? ""}
      />
    </div>
  );
};

export default DataLeakCookieTable;
