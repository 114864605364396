import { type ReactNode, useState } from "react";
import { twMerge } from "tailwind-merge";

interface IAccordionProps {
  label?: ReactNode;
  children?: ReactNode;
  expanded?: boolean;
  wrapperClassName?: string;
}

/** Accordion item, aka. smooth collapsible container.
 * @param expanded - Should be a controlled state, used to control if the accordion is opened or not.
 * @param label - The button that will trigger expansion & collapsion. Also is the display when the accordion is collapsed.
 * @param children - Contents inside the accordion that will be hidden when collapsed
 * @todo support built-in active state (open icon, ...)
 * @see https://www.patterns.dev/react/compound-pattern
 */
export const Accordion = ({
  label,
  children,
  expanded,
  wrapperClassName,
}: IAccordionProps) => {
  // Default state as fallback if `expanded` is not provided
  const [defaultExpanded, setDefaultExpanded] = useState<boolean>(true);

  const calcExpanded = expanded !== undefined ? expanded : defaultExpanded;

  return (
    <div
      className={twMerge(
        wrapperClassName,
        `grid ${
          calcExpanded
            ? "grid-rows-[min-content_1fr]"
            : "grid-rows-[min-content_0fr]"
        } transition-all duration-300 ease-out`
      )}
    >
      <div
        onClick={() => {
          setDefaultExpanded((prev) => !prev);
        }}
      >
        {label}
      </div>
      <div
        className={`overflow-hidden duration-300 ${
          calcExpanded ? "" : "opacity-0"
        }`}
      >
        {children}
      </div>
    </div>
  );
};
