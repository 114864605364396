// Libraries
import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

// Resources
import { ReactComponent as AddLine } from "#src/assets/images/icons/add-line.svg";

// General
import { useAppSelector } from "#src/store/hooks";
import constants from "#src/config/constants";

// Components
import { ContentSection, StickySection } from "#src/layouts/content";
import { Button } from "#src/components/common/system/Button";
import { apiErrorHandler } from "#src/utils/apiErrorHandler";

// API-related
import workspaceServices from "#src/services/workspace";
import { WorkspaceMemberRoleValue } from "#src/config/filter/workspace/value";

// Children
import MemberTable from "#src/components/workspace/member/MemberTable";

const WorkspaceMembersPage = () => {
  const { t } = useTranslation("workspace", {
    keyPrefix: "member",
  });
  const { workspace: workspaceId } = useParams<"workspace">();

  const currentUser = useAppSelector((state) => state.auth.userInfo);

  const [openAddMemberDrawer, setOpenAddMemberDrawer] =
    useState<boolean>(false);

  const [currentUserRole, setCurrentUserRole] = useState<string | null>(null);

  useEffect(() => {
    const fetchMemberList = () => {
      if (workspaceId) {
        workspaceServices
          .list_members(workspaceId, {
            roles: undefined,
            q: undefined,
            page: undefined,
            size: constants.DEFAULT_PAGE_SIZE,
          })
          .then((response) => {
            const findRole = response.results.find(
              (member) =>
                member.user && member.user.email === currentUser?.email
            )?.role;
            if (findRole) {
              setCurrentUserRole(findRole);
            }
          })
          .catch((error) => {
            apiErrorHandler(error, {
              toastMessage: t("notification.fetchUserRole.fail.message"),
            });
          });
      }
    };
    fetchMemberList();
  }, [workspaceId, currentUser, t]);

  return workspaceId ? (
    <>
      <StickySection>
        <h1>{t("title")}</h1>
        <Button
          size="large"
          disabled={
            currentUserRole !== WorkspaceMemberRoleValue.OWNER &&
            currentUserRole !== WorkspaceMemberRoleValue.ADMIN
          }
          onClick={() => {
            setOpenAddMemberDrawer(true);
          }}
        >
          <AddLine />
          {t("button.newMember")}
        </Button>
      </StickySection>
      <ContentSection>
        <MemberTable
          workspaceId={workspaceId}
          currentUserRole={currentUserRole}
          openAddMemberDrawer={openAddMemberDrawer}
          setOpenAddMemberDrawer={setOpenAddMemberDrawer}
        />
      </ContentSection>
    </>
  ) : null;
};

export default WorkspaceMembersPage;
