export enum WorkspaceMemberRoleValue {
  OWNER = "owner",
  ADMIN = "admin",
  MEMBER = "member",
}

export enum ServiceStatusValue {
  ACTIVE = "active",
  EXPIRING = "expiring",
  EXPIRED = "expired",
}

export enum PaymentMethodValue {
  BANKING = "banking",
  CARD = "card",
}

export enum PaymentStatusValue {
  PAID = "paid",
  PROCESSING = "processing",
}

export enum ContractStatusValue {
  NOT_SIGNED = "not_signed",
  SIGNED = "signed",
}

export enum ContractTypeValue {
  ESIGN = "esign",
  DIRECT = "direct",
}
