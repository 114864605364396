export const MsspPentestDetailTabEnum = {
  ORGANIZATION: "MSSP_PENTEST_DETAIL_TAB_ORGANIZATION",
  SCOPE: "MSSP_PENTEST_DETAIL_TAB_SCOPE",
  POLICY: "MSSP_PENTEST_DETAIL_TAB_POLICY",
  UPDATES: "MSSP_PENTEST_DETAIL_TAB_UPDATES",
  MEMBERS: "MSSP_PENTEST_DETAIL_TAB_MEMBERS",
};

export const MsspPentestMemberActionEnum = {
  DELETE_MEMBER: "MSSP_PENTEST_MEMBER_ACTION_ENUM_DELETE_MEMBER",
};
