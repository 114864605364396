import axios from "axios";
import attachmentsServices from "#src/services/vulnerabilities/attachments";

export enum FileUploadActionValue {
  WORKSPACE_LOGO = "workspace_logo",
  PROGRAM_LOGO = "program_logo",
  PENTEST_PROJECT_LOGO = "pentest_project_logo",
  // TARGET & PENTEST_TARGET & REPORT_ATTACHMENT are not used
  TARGET = "targets",
  PENTEST_TARGET = "pentest_targets",
  REPORT_ATTACHMENT = "report_attachments",
  COMMENT_ATTACHMENT = "comment_attachments",
  // IDENTITY & NDA_DOCUMENT are not used
  IDENTITY = "user_identity",
  NDA_DOCUMENT = "nda_document",
  TRUST_CENTER_RESOURCE = "trust_center_resource",
  TRUST_CENTER_OG_LOGO = "trust_center_og_logo"
}

export const uploadFile = async (
  file: File,
  action: FileUploadActionValue,
  params?: {
    // For PENTEST_PROJECT_LOGO & PROGRAM_LOGO
    alias?: string;
    // For WORKSPACE_LOGO
    workspace_id?: string;
    // For COMMENT_ATTACHMENT
    vuln_id?: string;
  }
) => {
  if (action === FileUploadActionValue.COMMENT_ATTACHMENT) {
    if (!params?.vuln_id) {
      throw Error("Upload COMMENT_ATTACHMENT failed. Missing `alias`.");
    }
  }
  if (action === FileUploadActionValue.PROGRAM_LOGO) {
    if (!params?.alias) {
      throw Error("Upload PROGRAM_LOGO failed. Missing `alias`.");
    }
  }
  if (action === FileUploadActionValue.WORKSPACE_LOGO) {
    if (!params?.workspace_id) {
      throw Error("Upload WORKSPACE_LOGO failed. Missing `workspace_id`.");
    }
  }
  return attachmentsServices
    .generate_form_upload(file.name, action, params)
    .then(async (generateFormResponse) => {
      return axios.put(generateFormResponse.url, file).then(() => {
        return attachmentsServices.sign_attachment(
          generateFormResponse.url,
          action
        );
      });
    });
};
