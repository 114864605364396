// Libraries
import { ReactNode, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { Tabs } from "@lockerpm/design";

// General
import type { IFilterItem } from "#src/@types/common";

// Components
import LoadingState from "#src/components/common/system/LoadingState";
import NoResultsState from "#src/components/common/states/NoResultsState";
import TabButtonLabel from "#src/components/common/helper/antdProps/Tabs/TabButtonLabel";
import useFetchNoPaging from "#src/hooks/useFetchNoPaging";
import { apiErrorHandler } from "#src/utils/apiErrorHandler";

// API-related
import type { IControlItem } from "#src/services/devices/control";
import devicesServices from "#src/services/devices";

// Children
import ControlNetworkFilter from "./Filter";
import ControlNetworkTableGrid from "./Table";
import {
  controlIpActionItems,
  controlNetworkActionItems,
  controlPortActionItems,
} from "#src/config/filterConstants";
import AddControlDrawer from "../AddControlDrawer";
import { AddControlTabEnum, ControlTableTabEnum } from "../enums";
import EditControlNetworkDrawer from "../EditControlDrawers/Network";
import EditControlPortDrawer from "../EditControlDrawers/Port";
import EditControlRestrictionDrawer from "../EditControlDrawers/Restriction";

interface IControlNetworkProps {
  workspaceId: string;
  openAddControlDrawer: boolean;
  onCloseAddControlDrawer: () => void;
}

const ControlTable = ({
  workspaceId,
  openAddControlDrawer,
  onCloseAddControlDrawer,
}: IControlNetworkProps) => {
  const { t } = useTranslation("devices", { keyPrefix: "control" });

  const [selectedControl, setSelectedControl] = useState<IControlItem[]>([]);

  const [activeTab, setActiveTab] = useState<string>(
    ControlTableTabEnum.NETWORK
  );
  const [activeDrawerTab, setActiveDrawerTab] = useState<string>(
    AddControlTabEnum.NETWORK
  );

  const [searchKeyword, setSearchKeyword] = useState<string>("");

  const [controlAction, setControlAction] = useState<IFilterItem | null>(null);
  const [host, setHost] = useState<IFilterItem | null>(null);
  const [tag, setTag] = useState<IFilterItem | null>(null);

  const [editingItemId, setEditingItemId] = useState<string | null>(null);
  const [openEditNetworkDrawer, setOpenEditNetworkDrawer] =
    useState<boolean>(false);
  const [openEditIpDrawer, setOpenEditIpDrawer] = useState<boolean>(false);
  const [openEditPortDrawer, setOpenEditPortDrawer] = useState<boolean>(false);
  const [openEditRestrictionDrawer, setOpenEditRestrictionDrawer] =
    useState<boolean>(false);

  const tabList: { key: string; label: ReactNode }[] = workspaceId
    ? [
        {
          key: ControlTableTabEnum.NETWORK,
          label: <TabButtonLabel name={t("tab.network.title")} />,
        },
        {
          key: ControlTableTabEnum.IP_ADDRESS,
          label: <TabButtonLabel name={t("tab.ipAddress.title")} />,
        },
        {
          key: ControlTableTabEnum.PORT,
          label: <TabButtonLabel name={t("tab.ports.title")} />,
        },
        {
          key: ControlTableTabEnum.RESTRICTION,
          label: <TabButtonLabel name={t("tab.restriction.title")} />,
        },
      ]
    : [];

  const onChangeTab = (key: string) => {
    setActiveTab(key);
    if (key === ControlTableTabEnum.NETWORK) {
      setActiveDrawerTab(AddControlTabEnum.NETWORK);
      if (controlAction?.value?.includes("allow")) {
        // this is allow_network
        setControlAction(controlNetworkActionItems[0]);
      }
      if (controlAction?.value?.includes("block")) {
        // this is block_network
        setControlAction(controlNetworkActionItems[1]);
      }
    }
    if (key === ControlTableTabEnum.IP_ADDRESS) {
      setActiveDrawerTab(AddControlTabEnum.IP_ADDRESS);
      if (controlAction?.value?.includes("allow")) {
        // this is allow_network_ip
        setControlAction(controlIpActionItems[0]);
      }
      if (controlAction?.value?.includes("block")) {
        // this is block_network_ip
        setControlAction(controlIpActionItems[1]);
      }
    }
    if (key === ControlTableTabEnum.PORT) {
      setActiveDrawerTab(AddControlTabEnum.PORT);
      if (controlAction?.value?.includes("allow")) {
        // this is allow_network_port
        setControlAction(controlPortActionItems[0]);
      }
      if (controlAction?.value?.includes("block")) {
        // this is block_network_port
        setControlAction(controlPortActionItems[1]);
      }
    }
    if (key === ControlTableTabEnum.RESTRICTION) {
      setActiveDrawerTab(AddControlTabEnum.RESTRICTION);
      setControlAction(null);
    }
  };

  const onOpenEditNetworkDrawer = (itemId: string) => {
    setEditingItemId(itemId);
    setOpenEditNetworkDrawer(true);
  };

  const onCloseEditNetworkDrawer = () => {
    setEditingItemId(null);
    setOpenEditNetworkDrawer(false);
  };

  const onOpenEditIpDrawer = (itemId: string) => {
    setEditingItemId(itemId);
    setOpenEditIpDrawer(true);
  };

  const onCloseEditIpDrawer = () => {
    setEditingItemId(null);
    setOpenEditIpDrawer(false);
  };

  const onOpenEditPortDrawer = (itemId: string) => {
    setEditingItemId(itemId);
    setOpenEditPortDrawer(true);
  };

  const onCloseEditPortDrawer = () => {
    setEditingItemId(null);
    setOpenEditPortDrawer(false);
  };

  const onOpenEditRestrictionDrawer = (itemId: string) => {
    setEditingItemId(itemId);
    setOpenEditRestrictionDrawer(true);
  };

  const onCloseEditRestrictionDrawer = () => {
    setEditingItemId(null);
    setOpenEditRestrictionDrawer(false);
  };

  const fetchParams: Parameters<typeof devicesServices.list_controls_shortly> =
    useMemo(
      () => [
        workspaceId,
        {
          group:
            activeTab === ControlTableTabEnum.NETWORK ||
            activeTab === ControlTableTabEnum.IP_ADDRESS ||
            activeTab === ControlTableTabEnum.PORT
              ? "network"
              : activeTab === ControlTableTabEnum.RESTRICTION
              ? "file"
              : undefined,
          action: controlAction
            ? controlAction.value
            : activeTab === ControlTableTabEnum.NETWORK
            ? "block_network,allow_network"
            : activeTab === ControlTableTabEnum.IP_ADDRESS
            ? "block_network_ip,allow_network_ip"
            : activeTab === ControlTableTabEnum.PORT
            ? "block_network_port,allow_network_port"
            : undefined,
          host_ids: host && host.value ? host.value : undefined,
          tag_ids: tag && tag.value ? tag.value : undefined,
        },
      ],
      [workspaceId, activeTab, controlAction, host, tag]
    );

  const onChangeSearchKeyword = (keyword: string) => {
    setSearchKeyword(keyword);
  };

  const onChangeSelectedControl = (selected: IControlItem) => {
    if (selectedControl.map((item) => item.id).includes(selected.id)) {
      setSelectedControl((prev) => [
        ...prev.filter((item) => item.id !== selected.id),
      ]);
    } else {
      setSelectedControl((prev) => [...prev, selected]);
    }
  };

  const onSelectAllControl = () => {
    if (controlList) {
      setSelectedControl(controlList);
    }
  };

  const onDeselectAllControl = () => {
    setSelectedControl([]);
  };

  const onDeleteSelectedControls = () => {
    // This has no API, we have to delete every single controls
    Promise.allSettled(
      selectedControl.map((scan) => {
        return devicesServices.delete_control(workspaceId, scan.id);
      })
    )
      .then(() => {
        setSelectedControl([]);
        updateControlList();
      })
      .catch((error) => {
        apiErrorHandler(error, {
          toastMessage: t("notification.deleteControl.fail"),
        });
      });
  };

  const onChangeControlActionSelection = (selected: typeof controlAction) => {
    setControlAction(selected);
  };

  const onChangeHostSelection = (selected: typeof host) => {
    setHost(selected);
  };

  const onChangeTagSelection = (selected: typeof tag) => {
    setTag(selected);
  };

  const {
    list: controlList,
    isLoading,
    updateData: updateControlList,
  } = useFetchNoPaging(devicesServices.list_controls_shortly, fetchParams);

  return (
    <>
      <Tabs
        items={tabList}
        activeKey={activeTab}
        onChange={onChangeTab}
        destroyInactiveTabPane
      />
      <ControlNetworkFilter
        workspaceId={workspaceId}
        activeTab={activeTab}
        totalCount={controlList?.length ?? 0}
        selectedCount={selectedControl.length}
        searchKeyword={searchKeyword}
        controlAction={controlAction}
        host={host}
        tag={tag}
        onChangeSearchKeyword={onChangeSearchKeyword}
        onChangeHostSelection={onChangeHostSelection}
        onChangeTagSelection={onChangeTagSelection}
        onChangeControlActionSelection={onChangeControlActionSelection}
        onSelectAllControl={onSelectAllControl}
        onDeselectAllControl={onDeselectAllControl}
        onDeleteSelectedControls={onDeleteSelectedControls}
      />
      {controlList === null || isLoading ? (
        <LoadingState />
      ) : controlList.length === 0 ? (
        <NoResultsState />
      ) : (
        <ControlNetworkTableGrid
          workspaceId={workspaceId}
          controlList={controlList}
          selectedControlIds={selectedControl.map((item) => item.id)}
          onChangeSelectedControl={onChangeSelectedControl}
          onOpenEditDrawer={
            activeTab === ControlTableTabEnum.NETWORK
              ? onOpenEditNetworkDrawer
              : activeTab === ControlTableTabEnum.IP_ADDRESS
              ? onOpenEditIpDrawer
              : activeTab === ControlTableTabEnum.PORT
              ? onOpenEditPortDrawer
              : activeTab === ControlTableTabEnum.RESTRICTION
              ? onOpenEditRestrictionDrawer
              : () => {}
          }
          onRefresh={updateControlList}
          disableSort={
            controlAction !== null ||
            host !== null ||
            tag !== null ||
            searchKeyword.length > 0 ||
            isLoading
          }
        />
      )}
      <AddControlDrawer
        open={openAddControlDrawer}
        onClose={onCloseAddControlDrawer}
        workspaceId={workspaceId}
        onRefresh={updateControlList}
        activeDrawerTab={activeDrawerTab}
        setActiveDrawerTab={setActiveDrawerTab}
        setActiveTab={setActiveTab}
      />
      {/* TODO: should try to merge these 3 drawers into 1 */}
      <EditControlNetworkDrawer
        open={openEditNetworkDrawer}
        onClose={onCloseEditNetworkDrawer}
        workspaceId={workspaceId}
        editingItemId={editingItemId}
        onRefresh={updateControlList}
      />
      <EditControlNetworkDrawer
        open={openEditIpDrawer}
        onClose={onCloseEditIpDrawer}
        workspaceId={workspaceId}
        editingItemId={editingItemId}
        onRefresh={updateControlList}
      />
      <EditControlPortDrawer
        open={openEditPortDrawer}
        onClose={onCloseEditPortDrawer}
        workspaceId={workspaceId}
        editingItemId={editingItemId}
        onRefresh={updateControlList}
      />
      <EditControlRestrictionDrawer
        open={openEditRestrictionDrawer}
        onClose={onCloseEditRestrictionDrawer}
        workspaceId={workspaceId}
        editingItemId={editingItemId}
        onRefresh={updateControlList}
      />
    </>
  );
};

export default ControlTable;
