import type { Dispatch, SetStateAction } from "react";
import { useTranslation } from "react-i18next";

interface IModeSwitchButtonProps {
  label: string;
  value: "write" | "preview";
  currentMode: "write" | "preview";
  onClick: () => void;
}

const ModeSwitchButton = ({
  label,
  value,
  currentMode,
  onClick,
}: IModeSwitchButtonProps) => {
  return (
    <button
      className={`px-6 py-3 font-medium-16 transition-all${
        currentMode === value
          ? " bg-dark-blue text-white"
          : " bg-light-grey text-hard-grey"
      }`}
      onClick={onClick}
    >
      {label}
    </button>
  );
};

interface IEditorModeSwitchProps {
  mode: "write" | "preview";
  setMode: Dispatch<SetStateAction<"write" | "preview">>;
}

const EditorModeSwitch = ({ mode, setMode }: IEditorModeSwitchProps) => {
  const { t } = useTranslation("vulnerabilities", {
    keyPrefix: "detailSection.commentEditor.modeSwitch",
  });
  return (
    <div className="flex rounded-md overflow-hidden duration-150">
      <ModeSwitchButton
        label={t("write")}
        value={"write"}
        currentMode={mode}
        onClick={() => {
          if (mode !== "write") {
            setMode("write");
          }
        }}
      />
      <ModeSwitchButton
        label={t("preview")}
        value={"preview"}
        currentMode={mode}
        onClick={() => {
          if (mode !== "preview") {
            setMode("preview");
          }
        }}
      />
    </div>
  );
};

export default EditorModeSwitch;
