import { ReactComponent as AddLine } from "#src/assets/images/icons/add-line.svg";
import { ReactComponent as ProfileLine } from "#src/assets/images/icons/profile-line.svg";
import { Button, IconButton } from "#src/components/common/system/Button";
import DropdownItemLabel from "#src/components/common/helper/antdProps/Dropdown/DropdownItemLabel";
import DropdownTriggerButton from "#src/components/common/helper/antdProps/Dropdown/DropdownTriggerButton";
import { Input } from "#src/components/common/system/Input";
import constants from "#src/config/constants";
import { Dropdown, Select } from "@lockerpm/design";
import { type ReactNode } from "react";
import { useFieldArray, UseFormReturn } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { ReactComponent as ArrowDownSLine } from "#src/assets/images/icons/arrow-down-s-line.svg";
import { ReactComponent as GlobalLine } from "#src/assets/images/icons/global-line.svg";
import { ReactComponent as BehanceLogomark } from "#src/assets/images/logos/socials/behance-logomark.svg";
import { ReactComponent as FacebookLogomark } from "#src/assets/images/logos/socials/facebook-logomark.svg";
import { ReactComponent as InstagramLogomark } from "#src/assets/images/logos/socials/instagram-logomark.svg";
import { ReactComponent as LinkedinLogomark } from "#src/assets/images/logos/socials/linkedin-logomark.svg";
import { ReactComponent as PinterestLogomark } from "#src/assets/images/logos/socials/pinterest-logomark.svg";
import { ReactComponent as RedditLogomark } from "#src/assets/images/logos/socials/reddit-logomark.svg";
import skypeLogomark from "#src/assets/images/logos/socials/skype-logomark.png";
import { ReactComponent as TelegramLogomark } from "#src/assets/images/logos/socials/telegram-logomark.svg";
import { ReactComponent as TumblrLogomark } from "#src/assets/images/logos/socials/tumblr-logomark.svg";
import { ReactComponent as TwitchLogomark } from "#src/assets/images/logos/socials/twitch-logomark.svg";
import { ReactComponent as XLogomark } from "#src/assets/images/logos/socials/x-logomark.svg";
import { ReactComponent as XingLogomark } from "#src/assets/images/logos/socials/xing-logomark.svg";
import { ReactComponent as YahooLogomark } from "#src/assets/images/logos/socials/yahoo-logomark.svg";
import { ReactComponent as YoutubeLogomark } from "#src/assets/images/logos/socials/youtube-logomark.svg";
import { ReactComponent as DeleteBin6Line } from "#src/assets/images/icons/delete-bin-6-line.svg";
import { toPascalCase } from "#src/utils/common";
import { CardHeader } from "#src/pages/workspace/details";

interface ISocialProfile {
  name: string;
  url: string;
}
export interface IWorkspaceAdditionalForm {
  social: ISocialProfile[];
  employee_size: string;
  industry: string;
  technologies: string;
}

const socialLogo: { [Name: string]: ReactNode } = {
  website: <GlobalLine height="1.25rem" width="1.25rem" />,
  twitter: (
    <div className="h-5 w-5 flex items-center justify-center">
      <XLogomark height="1rem" width="1rem" />
    </div>
  ),
  facebook: <FacebookLogomark height="1.25rem" width="1.25rem" />,
  behance: <BehanceLogomark height="1.25rem" width="1.25rem" />,
  instagram: <InstagramLogomark height="1.25rem" width="1.25rem" />,
  linkedin: <LinkedinLogomark height="1.25rem" width="1.25rem" />,
  pinterest: <PinterestLogomark height="1.25rem" width="1.25rem" />,
  reddit: <RedditLogomark height="1.25rem" width="1.25rem" />,
  skype: (
    <div className="h-5 w-5 flex items-center justify-center">
      <img
        src={skypeLogomark}
        className="object-contain rounded-[3px] h-4 w-4"
      />
    </div>
  ),
  telegram: <TelegramLogomark height="1.25rem" width="1.25rem" />,
  tumblr: <TumblrLogomark height="1.25rem" width="1.25rem" />,
  twitch: <TwitchLogomark height="1.25rem" width="1.25rem" />,
  yahoo: <YahooLogomark height="1.25rem" width="1.25rem" />,
  youtube: <YoutubeLogomark height="1.25rem" width="1.25rem" />,
  xing: (
    <div className="h-5 w-5 flex items-center justify-center">
      <XingLogomark height="1rem" width="1rem" />
    </div>
  ),
};

interface IAdditionalInformationProps {
  isEditing: boolean;
  methods: UseFormReturn<IWorkspaceAdditionalForm>;
}

const WorkspaceAdditionalInformation = ({
  isEditing = false,
  methods,
}: IAdditionalInformationProps) => {
  const { t } = useTranslation("workspace", { keyPrefix: "detail" });
  const { register, getValues, control, setValue } = methods;
  const { fields, append, remove, update } = useFieldArray({
    control,
    name: "social",
  });

  const onAddNewSocialProfile = () => {
    const newSocialProfile = socialItems.filter(
      (item) => !fields.map((sp) => sp.name).includes(item.value)
    )[0];
    append({
      name: newSocialProfile.value,
      url: "",
    });
  };

  const handleRemoveSocial = (index: number) => {
    remove(index);
  };

  const handleUpdateSocial = (index: number, social: { value: string }) => {
    update(index, {
      name: social.value,
      url: getValues("social")[index].url,
    });
  };

  const socialItems = constants.AVAILABLE_SOCIAL_NAME.map((item) => ({
    key: `SOCIAL_${item.toUpperCase()}`,
    value: item,
    label: (
      <DropdownItemLabel
        selected={fields.map((profile) => profile.name).includes(item)}
      >
        {socialLogo[item]}
        {toPascalCase(item)}
      </DropdownItemLabel>
    ),
  }));

  const employeeSizes = t("employeeSizes", { returnObjects: true });

  return (
    <div className="rounded-md border border-solid border-light-grey">
      <CardHeader
        icon={<ProfileLine />}
        title={t("additionalInformation.title")}
      />
      <div className="grid grid-cols-2 gap-6 p-6">
        <div className="col-span-1 space-y-4">
          <div className="space-y-3">
            <span className="font-medium-14-forced uppercase text-severity-none">
              {t("additionalInformation.form.social")}
            </span>
            {fields.map((socialItem, index) => (
              <div
                key={`social-${socialItem.name}-${index}`}
                className="flex gap-2"
              >
                <Dropdown
                  menu={{
                    items: socialItems.filter(
                      (item) =>
                        !fields.map((sp) => sp.name).includes(item.value) ||
                        item.value === socialItem.name
                    ),
                    onClick: ({ key }) => {
                      const clickedItem = socialItems.find(
                        (item) => item.key === key
                      );
                      if (clickedItem) {
                        handleUpdateSocial(index, clickedItem);
                      }
                    },
                  }}
                  trigger={["click"]}
                  disabled={!isEditing}
                >
                  <DropdownTriggerButton className="bg-bright-blue w-40">
                    <div className="flex items-center gap-2">
                      {socialLogo[socialItem.name]}
                      {toPascalCase(socialItem.name)}
                    </div>
                    <ArrowDownSLine />
                  </DropdownTriggerButton>
                </Dropdown>
                <Input
                  className="flex-1 min-w-[8rem]"
                  {...register(`social.${index}.url`)}
                  disabled={!isEditing}
                />
                {isEditing ? (
                  <IconButton
                    variant="warning"
                    disabled={!isEditing}
                    onClick={() => handleRemoveSocial(index)}
                  >
                    <DeleteBin6Line />
                  </IconButton>
                ) : null}
              </div>
            ))}
          </div>
          {isEditing ? (
            <Button
              variant="text"
              className="w-full"
              type="button"
              onClick={onAddNewSocialProfile}
              disabled={!isEditing}
            >
              <AddLine />
              {t("button.addMoreSocialProfile")}
            </Button>
          ) : null}
        </div>
        <div className="col-span-1 space-y-4">
          <div className="form-item space-y-3">
            <span className="font-medium-14-forced uppercase text-severity-none">
              {t("additionalInformation.form.industry")}
            </span>
            <Input
              className="font-regular-16"
              placeholder={t("additionalInformation.form.placholderIndustry")}
              {...register("industry")}
              disabled={!isEditing}
            />
          </div>
          <div className="form-item space-y-3">
            <span className="font-medium-14-forced uppercase text-severity-none">
              {t("additionalInformation.form.technology")}
            </span>
            <Input
              className="font-regular-16"
              placeholder={t(
                "additionalInformation.form.placeholderTechnology"
              )}
              {...register("technologies")}
              disabled={!isEditing}
            />
          </div>
          <div className="form-item space-y-3">
            <span className="font-medium-14-forced uppercase text-severity-none">
              {t("additionalInformation.form.employeeSize")}
            </span>
            <Select
              onChange={(value) => {
                setValue("employee_size", value);
              }}
              placeholder={t(
                "additionalInformation.form.placeholderEmployeeSize"
              )}
              disabled={!isEditing}
              defaultValue={getValues("employee_size")}
              className="select-dropdown w-full"
              size="large"
              options={employeeSizes}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default WorkspaceAdditionalInformation;
