// Libraries
import { useTranslation } from "react-i18next";
import i18next from "i18next";
import dayjs from "dayjs";

// Resources
import { ReactComponent as EditLine } from "#src/assets/images/icons/edit-line.svg";
import { ReactComponent as DeleteBin6Line } from "#src/assets/images/icons/delete-bin-6-line.svg";

// General
import type { MultilingualText } from "#src/@types/common";

// Components
import { IconButton } from "#src/components/common/system/Button";
import { TableDataCell, TableHeader } from "#src/components/common/Table";
import { TableRowWrapperDiv } from "#src/components/common/Table/TableRowWrapper";
import type { ITableHeaderPropItem } from "#src/components/common/Table/TableHeader";

/**
 * @param price currently only use VND
 * @param cycle a T function from i18next
 */
interface IProductRecheckItem {
  id: string;
  service: MultilingualText;
  product: MultilingualText;
  plan: MultilingualText;
  cycle: () => string;
  price: number;
  onClickEdit: () => void;
  nextBillingTime?: number;
}

interface IPaymentFillFormRecheckTableProps {
  products: IProductRecheckItem[];
}

/** A table shows all products in current cart that will be paid for in this payment.
 * This component's props will only include UI related things, and not include logics.
 */
const PaymentFillFormRecheckTable = ({
  products,
}: IPaymentFillFormRecheckTableProps) => {
  const { t } = useTranslation("trustCenter", {
    keyPrefix: "registerDomain.fillForm.yourCart",
  });

  const locale = i18next.language;

  const headers: ITableHeaderPropItem[] = [
    { name: t("headers.service"), align: "left" },
    { name: t("headers.product"), align: "left" },
    { name: t("headers.plan"), align: "left" },
    { name: t("headers.cycle"), align: "left" },
    { name: t("headers.price"), align: "left" },
    { name: t("headers.nextBillingTime"), align: "left" },
    { name: "", align: "left" },
  ];

  return (
    <div className="flex flex-col gap-6">
      <h2>{t("title")}</h2>
      <div className="grid grid-cols-[4fr_6fr_4fr_3fr_4fr_6fr_8rem]">
        <TableHeader title="payment-productRecheck" headerProps={headers} />
        {products.map((product) => (
          <TableRowWrapperDiv key={`tpayment/productRecheck-tr${product.id}`}>
            <TableDataCell>
              {locale === "vi" ? product.service.vi : product.service.en}
            </TableDataCell>
            <TableDataCell>
              {locale === "vi" ? product.product.vi : product.product.en}
            </TableDataCell>
            <TableDataCell>
              {locale === "vi" ? product.plan.vi : product.plan.en}
            </TableDataCell>
            <TableDataCell>{product.cycle()}</TableDataCell>
            <TableDataCell>{product.price.toLocaleString()} VND</TableDataCell>
            <TableDataCell>
              {product.nextBillingTime
                ? dayjs.unix(product.nextBillingTime).format("DD.MM.YYYY")
                : null}
            </TableDataCell>
            <TableDataCell>
              <IconButton
                variant="secondary"
                size={9}
                onClick={product.onClickEdit}
              >
                <EditLine className="h-5 w-5" />
              </IconButton>
              <IconButton variant="warning" size={9} disabled>
                <DeleteBin6Line className="h-5 w-5" />
              </IconButton>
            </TableDataCell>
          </TableRowWrapperDiv>
        ))}
      </div>
    </div>
  );
};

export default PaymentFillFormRecheckTable;
