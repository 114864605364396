// Libraries
import { Dropdown, Typography } from "@lockerpm/design";
import type { ItemType } from "@lockerpm/design/es/menu/hooks/useItems";
import dayjs from "dayjs";
import { useTranslation } from "react-i18next";
import { createSearchParams } from "react-router-dom";
import { generatePath, useNavigate } from "react-router-dom";

// Resources
import { ReactComponent as MoreLine } from "#src/assets/images/icons/more-line.svg";
import { ReactComponent as QrScanLine } from "#src/assets/images/icons/qr-scan-line.svg";
import { ReactComponent as StopCircleLine } from "#src/assets/images/icons/stop-circle-line.svg";
import { ReactComponent as DeleteBin6Line } from "#src/assets/images/icons/delete-bin-6-line.svg";

// General
import global from "#src/config/global";
import { pathname } from "#src/config/pathname";

// Components
import { IconButton } from "#src/components/common/system/Button";
import {
  TableActionLabel,
  TableCheckbox,
  TableDataCell,
  TableHeader,
  TableRowWrapper,
} from "#src/components/common/Table";
import { toPascalCase } from "#src/utils/common";
import type { ITableHeaderPropItem } from "#src/components/common/Table/TableHeader";
import { createToast } from "#src/components/common/system/toasts";
import { apiErrorHandler } from "#src/utils/apiErrorHandler";

// API-related
import scansServices, { type IScanItem } from "#src/services/scans";

// Children
import { LinearGaugeChart } from "./LinearGaugeChart";
import { getScheduleSummary } from "../../utils";
import { ScanDetailsTabEnum } from "../../details/enums";
import { scanStatusItems } from "#src/config/filter/scan";
import { ScanStatusEnum } from "#src/config/filter/scan/enum";

const MarkedStatusAndProgress = ({
  status,
  progress,
}: {
  status: string;
  progress: number;
}) => {
  const statusText = toPascalCase(status);
  const colorByStatus: { [status: string]: string } = {
    pending: "bg-sky",
    running: "bg-blue",
    stopping: "bg-yellow",
    stopped: "bg-red",
    completed: "bg-green",
  };
  return (
    <div className="flex items-center gap-2 whitespace-nowrap">
      <div className={`${colorByStatus[status]} h-2.5 w-2.5 rounded-full`} />
      {statusText + (status === "running" ? " " + progress + "%" : "")}
    </div>
  );
};

interface IScansTableRowProps {
  workspaceId: string;
  data: IScanItem;
  actionList: ItemType[];
  onClickActionItems: (key: string, item: IScanItem) => void;
  selectedScanIds: number[];
  onChangeSelectedScans: (selected: IScanItem) => void;
}

/** Row template for AllScans table view, since this depends on each table and cannot be built into a common template
 * @param data - data of the row, represent a scan
 * @param actionList - list of actions that shows when the dropdown at the rightmost of the row is opened
 * @param onClickActionItems - a function to process actions from actionList
 * @param selectedScanIds - a list of ids of items that has its checkbox checked
 * @param onChangeSelectedScans - control the selectedScanId list
 */
const ScanTableRow = ({
  workspaceId,
  data,
  actionList,
  onClickActionItems,
  selectedScanIds,
  onChangeSelectedScans,
}: IScansTableRowProps) => {
  const navigate = useNavigate();

  const summary = getScheduleSummary(
    data.scan_profile.schedule,
    data.scan_profile.duration_type,
    data.scan_profile.repeat_number,
    data.scan_profile.activated_time
  );

  return (
    <TableRowWrapper to={`details/${data.id}`}>
      <TableDataCell className="p-0 justify-center">
        <TableCheckbox
          checked={selectedScanIds.includes(data.id)}
          onClick={() => {
            onChangeSelectedScans(data);
          }}
        />
      </TableDataCell>
      {/* If the scan id is shown, there's something wrong with the data :D since there should be 1 (and only 1) asset per scan */}
      <TableDataCell>
        <span className="min-h-10 flex items-center">
          {data.scan_profile.assets.length > 0
            ? data.scan_profile.assets[0].name !==
              data.scan_profile.assets[0].address
              ? data.scan_profile.assets[0].name +
                " (" +
                data.scan_profile.assets[0].address +
                ")"
              : data.scan_profile.assets[0].address
            : "scan #" + data.id}
        </span>
      </TableDataCell>
      <TableDataCell>
        <LinearGaugeChart
          id={data.id}
          vulnBySeverity={data.vulnerabilities.by_severity}
          onClickSeries={(severity: string) => {
            navigate({
              pathname: generatePath(pathname.SCAN_DETAILS, {
                workspace: workspaceId,
                scan: data.id,
              }),
              search: createSearchParams({
                tab: ScanDetailsTabEnum.VULNERABILITIES,
                severity,
              }).toString(),
            });
          }}
        />
      </TableDataCell>
      <TableDataCell>
        <MarkedStatusAndProgress
          status={data.status}
          progress={data.progress || 0}
        />
      </TableDataCell>
      <TableDataCell>
        <Typography.Text ellipsis={{ tooltip: summary }}>
          {summary}
        </Typography.Text>
      </TableDataCell>
      <TableDataCell>
        {dayjs.unix(data.created_time).format("DD.MM.YYYY")}
      </TableDataCell>
      <TableDataCell className="p-0 justify-center">
        {actionList.length ? (
          <Dropdown
            menu={{
              items: actionList,
              onClick: ({ key, domEvent }) => {
                domEvent.preventDefault();
                domEvent.stopPropagation();
                onClickActionItems(key, data);
              },
            }}
            trigger={["click"]}
            disabled={selectedScanIds.length > 0}
          >
            <IconButton
              variant="ghost"
              size={9}
              onClick={(e) => {
                e.preventDefault();
              }}
              disabled={selectedScanIds.length > 0}
            >
              <MoreLine />
            </IconButton>
          </Dropdown>
        ) : (
          <div className="h-9 w-9" />
        )}
      </TableDataCell>
    </TableRowWrapper>
  );
};

interface IScanTableProps {
  workspaceId: string;
  scans: IScanItem[];
  selectedScanIds: number[];
  onChangeSelectedScans: (selected: IScanItem) => void;
  refreshScanList: () => void;
}

const ScanTableGrid = ({
  workspaceId,
  scans,
  selectedScanIds,
  refreshScanList,
  onChangeSelectedScans,
}: IScanTableProps) => {
  const { t } = useTranslation("scans", { keyPrefix: "page.allScans" });

  /** Scans Table's header list */
  const headers: ITableHeaderPropItem[] = [
    { name: "", align: "left" },
    { name: t("headers.scannedDomain"), align: "left" },
    { name: t("headers.vulnerabilities"), align: "left" },
    { name: t("headers.status"), align: "left" },
    { name: t("headers.scheduleType"), align: "left" },
    { name: t("headers.startTime"), align: "left" },
    { name: t("headers.actions"), align: "center" },
  ];

  const actionList = [
    {
      key: "re-scan",
      label: (
        <TableActionLabel icon={<QrScanLine />} text={t("actions.reScan")} />
      ),
    },
    {
      key: "stop-scan",
      label: (
        <TableActionLabel icon={<StopCircleLine />} text={t("actions.stop")} />
      ),
    },
    {
      key: "delete-scan",
      label: (
        <TableActionLabel
          icon={<DeleteBin6Line />}
          text={t("actions.delete")}
          warning
        />
      ),
    },
  ];

  const onReScan = (scanItem: IScanItem) => {
    scansServices
      .create_new_scan(workspaceId, {
        domains: scanItem.scan_profile.assets.map((domain) => ({
          domain_id: +domain.asset_id,
          include_subdomains: domain.include_subdomains,
        })),
        schedule: scanItem.scan_profile.schedule,
        activated_time: scanItem.scan_profile.activated_time || dayjs().unix(),
        timezone: scanItem.scan_profile.timezone,
        duration_type: scanItem.scan_profile.duration_type,
        repeat_number: scanItem.scan_profile.repeat_number,
        days: scanItem.scan_profile.days,
      })
      .then((response) => {
        if (response.success) {
          refreshScanList();
        }
      })
      .catch((error) => {
        apiErrorHandler(error, { toastMessage: t("notification.rescan.fail") });
      });
  };

  const onDeleteScan = (scanId: number) => {
    scansServices
      .delete_scan(workspaceId, scanId)
      .then(() => {
        refreshScanList();
        createToast({
          type: "success",
          message: t("notification.delete.success"),
        });
      })
      .catch((error) => {
        apiErrorHandler(error, { toastMessage: t("notification.delete.fail") });
      });
  };

  const onStopScan = (scanId: number) => {
    scansServices
      .stop_scan(workspaceId, scanId)
      .then((response) => {
        if (response.success) {
          refreshScanList();
          createToast({
            type: "success",
            message: t("notification.stop.success"),
          });
        }
      })
      .catch((error) => {
        apiErrorHandler(error, { toastMessage: t("notification.stop.fail") });
      });
  };

  const onClickActionItems = (key: string, item: IScanItem) => {
    switch (key) {
      case "re-scan":
        onReScan(item);
        return;
      case "stop-scan":
        onStopScan(item.id);
        return;
      case "delete-scan":
        global.confirm(() => onDeleteScan(item.id));
        return;
    }
  };

  const getFilteredActionList = (item: IScanItem) => {
    return actionList.filter((action) => {
      // pending case: 0 action
      if (
        item.status.toLowerCase() ===
        scanStatusItems.find((item) => item.key === ScanStatusEnum.PENDING)
          ?.value
      ) {
        return false;
      }
      // running case: 1 action (stop)
      if (
        item.status.toLowerCase() ===
        scanStatusItems.find((item) => item.key === ScanStatusEnum.RUNNING)
          ?.value
      ) {
        return ["stop-scan"].includes(action.key);
      }
      // completed case: normally 1 action (delete)
      if (
        item.status.toLowerCase() ===
        scanStatusItems.find((item) => item.key === ScanStatusEnum.COMPLETED)
          ?.value
      ) {
        // compalted, on demand scans case: 2 actions (rescan & delete)
        if (!item.scan_profile.schedule) {
          return ["re-scan", "delete-scan"].includes(action.key);
        }
        return ["delete-scan"].includes(action.key);
      }
      // stopping case: 0 actions
      if (
        item.status.toLowerCase() ===
        scanStatusItems.find((item) => item.key === ScanStatusEnum.STOPPING)
          ?.value
      ) {
        return false;
      }
      // stopped case: 2 action (rescan & delete)
      if (
        item.status.toLowerCase() ===
        scanStatusItems.find((item) => item.key === ScanStatusEnum.STOPPED)
          ?.value
      ) {
        return ["re-scan", "delete-scan"].includes(action.key);
      }
      return false;
    });
  };

  return (
    <div className="grid grid-cols-[2.75rem_minmax(4rem,_2fr)_5fr_1fr_7.5rem_1fr_1fr]">
      <TableHeader title="allScans" headerProps={headers} />
      {scans.map((item) => (
        <ScanTableRow
          key={`tscans/allScans-tr${item.id}`}
          workspaceId={workspaceId}
          data={item}
          actionList={getFilteredActionList(item)}
          onClickActionItems={onClickActionItems}
          selectedScanIds={selectedScanIds}
          onChangeSelectedScans={onChangeSelectedScans}
        />
      ))}
    </div>
  );
};

export default ScanTableGrid;
