const colorByStatus = {
  new: "bg-status-new",
  triaged: "bg-status-triaged",
  resolved: "bg-status-resolved",
  unresolved: "bg-status-unresolved",
  duplicate: "bg-status-duplicate",
  out_of_scope: "bg-status-rejected",
  not_reproducible: "bg-status-rejected",
  wont_fix: "bg-status-rejected",
  not_applicable: "bg-status-rejected",
  spam: "bg-status-rejected",
} satisfies Record<string, string>;

const statusTypeguard = (arg: unknown): arg is keyof typeof colorByStatus => {
  return typeof arg === "string" && arg in colorByStatus;
};

export const getTwColorByStatus = (status: string) => {
  if (statusTypeguard(status)) {
    return colorByStatus[status];
  }

  console.log("Wrong substatus! " + status);
  return colorByStatus.spam;
};
