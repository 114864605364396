// Libraries
import { Collapse, Dropdown } from "@lockerpm/design";
import { useTranslation } from "react-i18next";
import dayjs from "dayjs";
import { useState, type ReactNode, useEffect, type RefObject } from "react";
import { calculateBaseScore } from "@neuralegion/cvss";

// Resources
import { ReactComponent as GiftLine } from "#src/assets/images/icons/gift-line.svg";
import { ReactComponent as ArrowDownSLine } from "#src/assets/images/icons/arrow-down-s-line.svg";
import { ReactComponent as UserLine } from "#src/assets/images/icons/user-line.svg";

// General
import constants from "#src/config/constants";

// Components
import { Button } from "#src/components/common/system/Button";
import { SubStatusSelect } from "#src/components/common/SubStatusSelect";
import { toPascalCase } from "#src/utils/common";
import SeveritySelect from "#src/components/common/SeveritySelect";
import DropdownItemLabel from "#src/components/common/helper/antdProps/Dropdown/DropdownItemLabel";
import { CollapsePanelHeading3 } from "#src/components/common/helper/antdProps/Collapse/PanelHeader";
import { ObjectImage } from "#src/components/common/system/Object";
import { createToast } from "#src/components/common/system/toasts";
import { apiErrorHandler } from "#src/utils/apiErrorHandler";

// API-related
import vulnerabilitiesServices, {
  type IAssigneeResponse,
  type IResourceVulnCategory,
  type IVulnerabilityDetail,
} from "#src/services/vulnerabilities";
import resourceServices from "#src/services/resource";
import workspaceServices, {
  type IWorkspaceMember,
} from "#src/services/workspace";

// Children
import AssigneeInput from "./AssigneeInput";
import CvssModal from "./CvssModal";
import VulnerabilityPopover from "./VulnerabilityPopover";

const InfoItem = ({ title, value }: { title: string; value: ReactNode }) => {
  return (
    <div className="flex gap-1">
      <span className="w-2/5 text-hard-grey flex items-center">{title}</span>
      <span className="w-3/5 flex items-center">{value}</span>
    </div>
  );
};

const ReporterItem = ({
  name,
  avatar,
}: {
  name: string | undefined;
  avatar: ReactNode;
}) => {
  return (
    <div className="flex gap-1 items-center">
      <span className="flex items-center p-0.5">{avatar}</span>
      <span className="text-primary">{name}</span>
    </div>
  );
};

interface IVulnerabilityInfoSectionProps {
  workspaceId: string;
  containerRef: RefObject<HTMLDivElement>;
  vulnDetail: IVulnerabilityDetail;
  refreshVulnerabilityDetail: () => void;
  refreshVulnerabilityList: () => void;
}

const VulnerabilityInfoSection = ({
  workspaceId,
  containerRef,
  vulnDetail,
  refreshVulnerabilityDetail,
  refreshVulnerabilityList,
}: IVulnerabilityInfoSectionProps) => {
  const { t } = useTranslation("vulnerabilities", { keyPrefix: "infoSection" });

  const [openVulnVariantPopover, setOpenVulnVariantPopover] =
    useState<boolean>(false);

  const [userList, setUserList] = useState<IWorkspaceMember[]>([]);
  const [resourceVulnList, setResourceVulnList] = useState<
    IResourceVulnCategory[]
  >([]);

  // This state represents the state of the selector, not the actual selected value
  const [selectedVulnVariant, setSelectedVulnVariant] = useState<{
    category: IResourceVulnCategory;
    subcategory?: IResourceVulnCategory["subcategory"][number];
    variant?: IResourceVulnCategory["subcategory"][number]["variants"][number];
  } | null>(null);

  const [openCvssModal, setOpenCvssModal] = useState<boolean>(false);

  const [assignee, setAssignee] = useState<Pick<
    IAssigneeResponse,
    "id" | "full_name" | "avatar"
  > | null>(null);

  const onChangeSubStatus = (newSubStatus: string) => {
    vulnerabilitiesServices
      .update_vulnerability(workspaceId, vulnDetail.id, {
        sub_status: newSubStatus,
      })
      .then(() => {
        refreshVulnerabilityDetail();
        refreshVulnerabilityList();
        createToast({
          type: "success",
          message: t("notification.changeVulnerabilitySubstatus.success"),
        });
      })
      .catch((error) => {
        apiErrorHandler(error, {
          toastMessage: t("notification.changeVulnerabilitySubstatus.fail"),
        });
      });
  };

  const onChangeAssignee = (newAssigneeId: string | null) => {
    if (newAssigneeId) {
      const optimisticAssignee = userList.find(
        (user) => user.id.toString() === newAssigneeId
      );
      if (optimisticAssignee) {
        setAssignee({
          id: optimisticAssignee.id,
          full_name: optimisticAssignee.user
            ? optimisticAssignee.user.full_name
            : optimisticAssignee.email
            ? optimisticAssignee.email
            : "---",
          avatar: optimisticAssignee.user ? optimisticAssignee.user.avatar : "",
        });
      }

      vulnerabilitiesServices
        .set_vulnerability_assignee(workspaceId, vulnDetail.id, newAssigneeId)
        .then((successResponse) => {
          if (successResponse.success) {
            vulnerabilitiesServices
              .get_vulnerability_assignee(workspaceId, vulnDetail.id)
              .then((response) => {
                if (
                  response.id !== optimisticAssignee?.id ||
                  response.full_name !==
                    (optimisticAssignee.user
                      ? optimisticAssignee.user.full_name
                      : optimisticAssignee.email
                      ? optimisticAssignee.email
                      : "---") ||
                  response.avatar !==
                    (optimisticAssignee.user
                      ? optimisticAssignee.user.avatar
                      : "")
                ) {
                  setAssignee(response);
                }
              });
          }
          createToast({
            type: "success",
            message: t("notification.changeAssignee.success"),
          });
        })
        .catch((error) => {
          apiErrorHandler(error, {
            toastMessage: t("notification.changeAssignee.fail"),
          });
        });
      return;
    }
    setAssignee(null);
  };

  const onChangeVisibility = (newVisibility: string) => {
    vulnerabilitiesServices
      .update_vulnerability(workspaceId, vulnDetail.id, {
        visibility: newVisibility,
      })
      .then(() => {
        refreshVulnerabilityDetail();
        refreshVulnerabilityList();
        createToast({
          type: "success",
          message: t("notification.changeVisibility.success"),
        });
      })
      .catch((error) => {
        apiErrorHandler(error, {
          toastMessage: t("notification.changeVisibility.fail"),
        });
      });
  };

  const onChangeVulnerabilityVariant = (
    category: string,
    subcategory?: string,
    variant?: string
  ) => {
    vulnerabilitiesServices
      .update_vulnerability(workspaceId, vulnDetail.id, {
        category,
        vuln_name: subcategory,
        vuln_variant: variant,
      })
      .then(() => {
        refreshVulnerabilityDetail();
        refreshVulnerabilityList();
        createToast({
          type: "success",
          message: t("notification.changeVisibility.success"),
        });
      })
      .catch((error) => {
        apiErrorHandler(error, {
          toastMessage: t("notification.changeVisibility.fail"),
        });
      });
  };

  const onChangeSeverity = (newSeverity: string) => {
    vulnerabilitiesServices
      .update_vulnerability(workspaceId, vulnDetail.id, {
        severity: newSeverity.toUpperCase(),
        cvss_v3: "",
      })
      .then(() => {
        refreshVulnerabilityDetail();
        refreshVulnerabilityList();
        createToast({
          type: "success",
          message: t("notification.changeVulnerabilitySeverity.success"),
        });
      })
      .catch((error) => {
        apiErrorHandler(error, {
          toastMessage: t("notification.changeVulnerabilitySeverity.fail"),
        });
      });
  };

  const onChangeCvss = (newCvss: {
    vectorString: string;
    score: number;
    severity: string;
  }) => {
    vulnerabilitiesServices
      .update_vulnerability(workspaceId, vulnDetail.id, {
        severity: newCvss.severity.toUpperCase(),
        cvss_v3: newCvss.vectorString.toUpperCase(),
      })
      .then(() => {
        refreshVulnerabilityDetail();
        refreshVulnerabilityList();
        createToast({
          type: "success",
          message: t("notification.changeCvss.success"),
        });
      })
      .catch((error) => {
        apiErrorHandler(error, {
          toastMessage: t("notification.changeCvss.fail"),
        });
      });
  };

  // Calculate the position of this element, so that we can fix its height by screen size
  const calculatedTop: number = containerRef.current
    ? containerRef.current?.getBoundingClientRect().top +
      parseFloat(window.getComputedStyle(containerRef.current).paddingTop)
    : // 180 is just a fallback value that looks close to the actual calculated value
      180;

  useEffect(() => {
    let ignore = false;

    if (!ignore) {
      try {
        workspaceServices
          .list_members(workspaceId, {
            roles: undefined,
            q: undefined,
            page: undefined,
            size: constants.DEFAULT_PAGE_SIZE,
          })
          .then((response) => {
            setUserList(response.results);
          });
        vulnerabilitiesServices
          .get_vulnerability_assignee(workspaceId, vulnDetail.id)
          .then((response) => {
            // Server can return an empty object, that's annoying but we have to deal with it
            if (response.id) {
              setAssignee(response);
            }
          });
        resourceServices.list_vuln_variants().then((response) => {
          setResourceVulnList(response.category);
        });
      } catch (error) {
        apiErrorHandler(error);
      }
    }
  }, [workspaceId, vulnDetail.id]);

  return (
    <div
      style={{
        maxHeight: `calc(100vh - ${calculatedTop}px)`,
      }}
      className="flex-1 max-w-[32rem] pb-1.5 pr-4 h-fit bg-white rounded-md overflow-x-hidden overflow-y-auto"
    >
      <div className="flex gap-3 p-4 rounded-n-md border-b border-light-grey">
        <SubStatusSelect
          subStatusValue={vulnDetail.sub_status}
          updateData={onChangeSubStatus}
        />
        <Button variant="secondary" size="large" disabled>
          <GiftLine />
          {t("button.addReward")}
        </Button>
      </div>
      <Collapse
        ghost
        defaultActiveKey={[
          "program_details",
          "submission",
          "vulnerability_details",
        ]}
        expandIcon={({ isActive }) =>
          isActive ? (
            <ArrowDownSLine className="rotate-180 duration-300 ease-out" />
          ) : (
            <ArrowDownSLine className="duration-300 ease-out" />
          )
        }
        expandIconPosition="end"
      >
        <Collapse.Panel
          key={"program_details"}
          header={<CollapsePanelHeading3 title={t("programDetails.title")} />}
        >
          <div className="px-6 py-4 flex flex-col gap-6">
            <InfoItem
              title={t("programDetails.programType")}
              value={
                vulnDetail.vuln_source === "bugbounty" ||
                vulnDetail.vuln_source === "pentest"
                  ? vulnDetail.type
                  : vulnDetail.vuln_source === "auto_scan"
                  ? t("autoScan")
                  : vulnDetail.vuln_source === "data_leak"
                  ? t("dataLeak")
                  : "---"
              }
            />
            {vulnDetail.vuln_source === "bugbounty" ||
            vulnDetail.vuln_source === "pentest" ? (
              <InfoItem
                title={t("programDetails.programName")}
                value={
                  <span className="text-primary">{vulnDetail.program}</span>
                }
              />
            ) : vulnDetail.vuln_source === "data_leak" ? (
              <InfoItem
                title={t("programDetails.dataLeakId")}
                value={
                  <span className="text-primary">
                    {vulnDetail.data_leak_id}
                  </span>
                }
              />
            ) : null}
          </div>
        </Collapse.Panel>
        <Collapse.Panel
          key={"submission"}
          header={<CollapsePanelHeading3 title={t("submission.title")} />}
        >
          <div className="px-6 py-4 flex flex-col gap-6">
            <InfoItem title={t("submission.id")} value={`#${vulnDetail.id}`} />
            <InfoItem
              title={t("submission.internalId")}
              value={
                vulnDetail.internal_id ? `#${vulnDetail.internal_id}` : "---"
              }
            />
            <InfoItem
              title={t("submission.submittedBy")}
              value={
                <ReporterItem
                  name={vulnDetail.reporter?.full_name}
                  avatar={
                    <ObjectImage data={vulnDetail.reporter?.avatar}>
                      <div className="w-full h-full flex items-center justify-center bg-light-grey rounded-full">
                        <UserLine className="h-5 w-5 fill-medium-grey" />
                      </div>
                    </ObjectImage>
                  }
                />
              }
            />
            <InfoItem
              title={t("submission.submittedAt")}
              value={dayjs
                .unix(vulnDetail.created_time)
                .format("HH:mm DD.MM.YYYY")}
            />
            <InfoItem
              title={t("submission.assignee")}
              value={
                <AssigneeInput
                  assignee={assignee}
                  userList={userList}
                  onChangeAssignee={onChangeAssignee}
                />
              }
            />
            <InfoItem
              title={t("submission.visibility")}
              value={
                vulnDetail.vuln_source === "bugbounty" ? (
                  <Dropdown
                    menu={{
                      items: ["Public", "Private"].map((item) => ({
                        key: item.toLowerCase(),
                        label: (
                          <DropdownItemLabel
                            selected={vulnDetail.visibility === item}
                          >
                            {item}
                          </DropdownItemLabel>
                        ),
                      })),
                      onClick: ({ key }) => {
                        onChangeVisibility(key);
                      },
                    }}
                    trigger={["click"]}
                  >
                    <button className="flex justify-between items-center p-3 w-full rounded-md bg-bright-grey text-hard-grey">
                      {toPascalCase(vulnDetail.visibility)}
                      <div className="w-4 h-4">
                        <ArrowDownSLine className="w-4 h-4" />
                      </div>
                    </button>
                  </Dropdown>
                ) : (
                  toPascalCase(vulnDetail.visibility)
                )
              }
            />
            <InfoItem
              title={t("submission.kudosPoints")}
              value={vulnDetail.point_reward.point}
            />
            <InfoItem
              title={t("submission.reward")}
              // TODO: Maybe add currency here
              value={vulnDetail.money_rewarded}
            />
            <InfoItem
              title={t("submission.reference")}
              // TODO: Maybe this should link to the duplicated vuln
              value={
                vulnDetail.duplicated_report
                  ? vulnDetail.duplicated_report
                  : "---"
              }
            />
          </div>
        </Collapse.Panel>
        <Collapse.Panel
          key={"vulnerability_details"}
          header={
            <CollapsePanelHeading3 title={t("vulnerabilityDetails.title")} />
          }
        >
          <div className="px-6 py-4 flex flex-col gap-6">
            <InfoItem
              title={t("vulnerabilityDetails.target")}
              value={vulnDetail.target}
            />
            <InfoItem
              title={t("vulnerabilityDetails.vulnerability")}
              value={
                <VulnerabilityPopover
                  open={openVulnVariantPopover}
                  onChangeOpen={(value) => {
                    setOpenVulnVariantPopover(value);
                  }}
                  value={
                    vulnDetail.category
                      ? vulnDetail.category +
                        (vulnDetail.vuln_name
                          ? " > " +
                            vulnDetail.vuln_name +
                            (vulnDetail.vuln_variant
                              ? " > " + vulnDetail.vuln_name
                              : "")
                          : "")
                      : "---"
                  }
                  resourceVulnList={resourceVulnList}
                  selectedVulnVariant={selectedVulnVariant}
                  onChangeVulnVariant={(value) => {
                    setSelectedVulnVariant(value);
                    onChangeVulnerabilityVariant(
                      value.category.name,
                      value.subcategory?.name,
                      value.variant?.name
                    );
                    setOpenVulnVariantPopover(false);
                  }}
                />
              }
            />

            <InfoItem
              title={t("vulnerabilityDetails.severity")}
              value={
                <SeveritySelect
                  severityValue={vulnDetail.severity}
                  updateData={onChangeSeverity}
                />
              }
            />
            <InfoItem
              title={t("vulnerabilityDetails.cvss3")}
              value={
                <button
                  className="flex justify-between items-center p-3 w-full rounded-md bg-bright-grey text-hard-grey"
                  onClick={() => {
                    setOpenCvssModal(true);
                  }}
                >
                  <span className="overflow-hidden whitespace-nowrap text-ellipsis">
                    {vulnDetail.cvss_v3
                      ? calculateBaseScore(vulnDetail.cvss_v3)
                      : "---"}
                  </span>
                  <div className="w-4 h-4">
                    <ArrowDownSLine className="w-4 h-4" />
                  </div>
                </button>
              }
            />
          </div>
        </Collapse.Panel>
      </Collapse>
      {/* Since ANTD did not destroy this modal on close as it promises to, we will have to do it ourselves here, although it will result in flashy effect, not smooth closing animation. */}
      {openCvssModal ? (
        <CvssModal
          open={openCvssModal}
          setOpen={setOpenCvssModal}
          cvssInit={vulnDetail.cvss_v3}
          onApplyCvss={onChangeCvss}
        />
      ) : null}
    </div>
  );
};

export default VulnerabilityInfoSection;
