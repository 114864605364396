// Libraries
import { useTranslation } from "react-i18next";
import { generatePath, useNavigate, useParams } from "react-router-dom";
import {
  type ReactNode,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import { Tabs } from "@lockerpm/design";

// Resources
import { ReactComponent as CheckLine } from "#src/assets/images/icons/check-line.svg";
import { ReactComponent as CloseLine } from "#src/assets/images/icons/close-line.svg";
import { ReactComponent as LockLine } from "#src/assets/images/icons/lock-line.svg";
import { ReactComponent as CloseCircleLine } from "#src/assets/images/icons/close-circle-line.svg";
import { ReactComponent as EditLine } from "#src/assets/images/icons/edit-line.svg";
import { ReactComponent as ArrowGoBackLine } from "#src/assets/images/icons/arrow-go-back-line.svg";
import { ReactComponent as SaveLine } from "#src/assets/images/icons/save-line.svg";

// General
import global from "#src/config/global";
import { pathname } from "#src/config/pathname";

// Components
import { ContentSection, StickySection } from "#src/layouts/content";
import TabButtonLabel from "#src/components/common/helper/antdProps/Tabs/TabButtonLabel";
import { toPascalCase } from "#src/utils/common";
import { Button } from "#src/components/common/system/Button";
import { createToast } from "#src/components/common/system/toasts";
import { apiErrorHandler } from "#src/utils/apiErrorHandler";

// API-related
import msspBugBountyServices, {
  type IMsspBugBountyDetails,
} from "#src/services/mssp/bugBounty";
import type { IBugBountyProgramBriefStates } from "#src/components/bug-bounty/details/ProgramBriefTab";
import {
  BugBountyDetailsTabEnum,
  BugBountyProgramTypeEnum,
} from "#src/components/bug-bounty/enum";
import { bugBountyRewardTypeItems } from "#src/components/bug-bounty/AddBugBounty/RewardRange";
import { BugBountyStatusResponseValue } from "#src/config/filter/bugbounty/value";

// Children
import MsspBugBountyDetailsProgramBrief from "#src/components/mssp/bug-bounty/details/ProgramBriefTab";
import MsspBugBountyDetailsScope from "#src/components/mssp/bug-bounty/details/ScopeTab";
import MsspBugBountyDetailsPolicy from "#src/components/mssp/bug-bounty/details/PolicyTab";
import MsspBugBountyDetailsMembers from "#src/components/mssp/bug-bounty/details/MembersTab";
import MsspBugBountyDetailsOrganization from "#src/components/mssp/bug-bounty/details/OrganizationTab";
import MsspBugBountyDetailsIntegration from "#src/components/mssp/bug-bounty/details/IntegrationTab";
import MsspBugBountyDetailsRequirements from "#src/components/mssp/bug-bounty/details/RequirementsTab";

const MsspBugBountyDetails = () => {
  const { t } = useTranslation("bugBounty", {
    keyPrefix: "page.bugBountyDetails",
  });
  const { workspace: workspaceId, bugbounty: bugBountyAlias } = useParams<
    "workspace" | "bugbounty"
  >();

  const navigate = useNavigate();

  const briefRef = useRef<{ onSaveChanges: () => void }>(null);
  const requirementsRef = useRef<{
    onSaveChanges: () => void;
    onRevertChanges: () => void;
  }>(null);

  const [activeTab, setActiveTab] = useState<string>(
    BugBountyDetailsTabEnum.ORGANIZATION
  );

  const [bugBountyDetails, setBugBountyDetails] =
    useState<IMsspBugBountyDetails | null>(null);
  const [programBriefStates, setProgramBriefStates] =
    useState<IBugBountyProgramBriefStates>({
      programType: BugBountyProgramTypeEnum.PUBLIC,
      briefName: "",
      alias: "",
      description: "",
      currency: "VND",
      rewardRange: [],
      status: "",
      color: "",
      avatarUrl: null,
    });

  const [isEditing, setEditing] = useState<boolean>(false);

  const onChangeTab = (key: string) => {
    if (isEditing) {
      // TODO: customize this into a better common modal
      global.confirm(() => {
        setActiveTab(key);
        setEditing(false);
      });
    } else {
      setActiveTab(key);
    }
  };

  const getProgramBriefStatesFromDetails = (
    details: IMsspBugBountyDetails
  ): IBugBountyProgramBriefStates => {
    return {
      programType: Object.keys(BugBountyProgramTypeEnum).includes(
        details.type.toUpperCase()
      )
        ? BugBountyProgramTypeEnum[
            details.type.toUpperCase() as keyof typeof BugBountyProgramTypeEnum
          ]
        : BugBountyProgramTypeEnum.PUBLIC,
      briefName: details.org.name,
      alias: details.alias,
      description: details.org.description.en,
      currency: details.currency.toUpperCase() === "VND" ? "VND" : "USD",
      rewardRange: details.reward
        // sort by point_value
        .sort((a, b) => a.point_value - b.point_value)
        .map((range) => ({
          type: bugBountyRewardTypeItems.find(
            (item) => item.value === range.type
          ) || {
            key: range.type,
            value: range.type,
            getLabel: () => toPascalCase(range.type),
          },
          from: range.range_from,
          to: range.range_to,
          severity: range.severity.toUpperCase(),
          point: range.point_value,
        })),
      status: details.status,
      color: details.org.color,
      avatarUrl: details.org.logo,
    };
  };

  const onAcceptRequest = () => {
    if (workspaceId && bugBountyAlias && bugBountyDetails) {
      msspBugBountyServices
        .update_mssp_program_details(workspaceId, bugBountyAlias, {
          type: bugBountyDetails.type,
          metadata: bugBountyDetails.metadata,
          status: BugBountyStatusResponseValue.OPEN,
        })
        .then((response) => {
          if (response.success) {
            fetchBugBountyDetails();
            createToast({
              type: "success",
              message: t("notification.acceptRequest.success.title"),
            });
          }
        })
        .catch((error) => {
          apiErrorHandler(error, {
            toastMessage: t("notification.acceptRequest.fail"),
          });
        });
    }
  };

  const onRejectRequest = () => {
    if (workspaceId && bugBountyAlias && bugBountyDetails) {
      msspBugBountyServices
        .update_mssp_program_details(workspaceId, bugBountyAlias, {
          type: bugBountyDetails.type,
          metadata: bugBountyDetails.metadata,
          status: BugBountyStatusResponseValue.DRAFT,
        })
        .then((response) => {
          if (response.success) {
            // Navigate back to the list. Rejected request should not appear here anymore.
            navigate(
              generatePath(pathname.MSSP_BUG_BOUNTY_PROGRAMS, {
                workspace: workspaceId,
              })
            );
          }
        })
        .catch((error) => {
          apiErrorHandler(error, {
            toastMessage: t("notification.rejectRequest.fail"),
          });
        });
    }
  };

  const onLockBugBountyProgram = () => {
    if (workspaceId && bugBountyAlias && bugBountyDetails) {
      msspBugBountyServices
        .update_mssp_program_details(workspaceId, bugBountyAlias, {
          type: bugBountyDetails.type,
          metadata: bugBountyDetails.metadata,
          status: BugBountyStatusResponseValue.LOCKED,
        })
        .then((response) => {
          if (response.success) {
            fetchBugBountyDetails();
            createToast({
              type: "success",
              message: t("notification.lockProgram.success.title"),
            });
          }
        })
        .catch((error) => {
          apiErrorHandler(error, {
            toastMessage: t("notification.lockProgram.fail"),
          });
        });
    }
  };

  const onCloseBugBountyProgram = () => {
    if (workspaceId && bugBountyAlias && bugBountyDetails) {
      msspBugBountyServices
        .update_mssp_program_details(workspaceId, bugBountyAlias, {
          type: bugBountyDetails.type,
          metadata: bugBountyDetails.metadata,
          status: BugBountyStatusResponseValue.CLOSED,
        })
        .then((response) => {
          if (response.success) {
            fetchBugBountyDetails();
            createToast({
              type: "success",
              message: t("notification.closeProgram.success.title"),
            });
          }
        })
        .catch((error) => {
          apiErrorHandler(error, {
            toastMessage: t("notification.closeProgram.fail"),
          });
        });
    }
  };

  const onUnlockBugBountyProgram = () => {
    if (workspaceId && bugBountyAlias && bugBountyDetails) {
      msspBugBountyServices
        .update_mssp_program_details(workspaceId, bugBountyAlias, {
          type: bugBountyDetails.type,
          metadata: bugBountyDetails.metadata,
          status: BugBountyStatusResponseValue.OPEN,
        })
        .then((response) => {
          if (response.success) {
            fetchBugBountyDetails();
            createToast({
              type: "success",
              message: t("notification.unlockProgram.success.title"),
            });
          }
        })
        .catch((error) => {
          apiErrorHandler(error, {
            toastMessage: t("notification.unlockProgram.fail"),
          });
        });
    }
  };

  const fetchBugBountyDetails = useCallback(() => {
    if (workspaceId && bugBountyAlias) {
      msspBugBountyServices
        .retrieve_mssp_program_details(workspaceId, bugBountyAlias)
        .then((response) => {
          setBugBountyDetails(response);
          setProgramBriefStates(getProgramBriefStatesFromDetails(response));
        })
        .catch((error) => {
          apiErrorHandler(error, {
            toastMessage: t("notification.loadBugBounty.fail"),
          });
        });
    }
  }, [workspaceId, bugBountyAlias, t]);

  useEffect(() => {
    let ignore = false;

    if (!ignore) {
      fetchBugBountyDetails();
    }

    return () => {
      ignore = true;
    };
  }, [fetchBugBountyDetails]);

  const tabList: { key: string; label: ReactNode; children: ReactNode }[] =
    bugBountyDetails && workspaceId && bugBountyAlias
      ? [
          {
            key: BugBountyDetailsTabEnum.ORGANIZATION,
            label: <TabButtonLabel name={t("tab.organization.title")} />,
            children: (
              <MsspBugBountyDetailsOrganization
                workspaceId={workspaceId}
                bugBountyAlias={bugBountyAlias}
              />
            ),
          },
          {
            key: BugBountyDetailsTabEnum.PROGRAM_BRIEF,
            label: <TabButtonLabel name={t("tab.programBrief.title")} />,
            children: (
              <MsspBugBountyDetailsProgramBrief
                workspaceId={workspaceId}
                bugBountyAlias={bugBountyAlias}
                bugBountyDetails={bugBountyDetails}
                programBriefStates={programBriefStates}
                setProgramBriefStates={setProgramBriefStates}
                isEditing={isEditing}
                onRefresh={fetchBugBountyDetails}
              />
            ),
          },
          {
            key: BugBountyDetailsTabEnum.SCOPE,
            label: <TabButtonLabel name={t("tab.scope.title")} />,
            children: (
              <MsspBugBountyDetailsScope bugBountyDetails={bugBountyDetails} />
            ),
          },
          {
            key: BugBountyDetailsTabEnum.POLICY,
            label: <TabButtonLabel name={t("tab.policy.title")} />,
            children: (
              <MsspBugBountyDetailsPolicy bugBountyDetails={bugBountyDetails} />
            ),
          },
          {
            key: BugBountyDetailsTabEnum.REQUIREMENTS,
            label: <TabButtonLabel name={t("tab.requirements.title")} />,
            children: (
              <MsspBugBountyDetailsRequirements
                ref={requirementsRef}
                workspaceId={workspaceId}
                bugBountyAlias={bugBountyAlias}
                isEditing={isEditing}
                onRefresh={fetchBugBountyDetails}
              />
            ),
          },
          {
            key: BugBountyDetailsTabEnum.MEMBERS,
            label: <TabButtonLabel name={t("tab.members.title")} />,
            children: (
              <MsspBugBountyDetailsMembers
                workspaceId={workspaceId}
                bugBountyAlias={bugBountyAlias}
              />
            ),
          },
          {
            key: BugBountyDetailsTabEnum.INTEGRATIONS,
            label: <TabButtonLabel name={t("tab.integrations.title")} />,
            children: (
              <MsspBugBountyDetailsIntegration
                workspaceId={workspaceId}
                bugBountyAlias={bugBountyAlias}
              />
            ),
          },
        ]
      : [];

  return workspaceId && bugBountyAlias && bugBountyDetails ? (
    <>
      <StickySection>
        <h1 className="break-all">
          {bugBountyDetails.org.name
            ? bugBountyDetails.org.name
            : bugBountyDetails.alias}
        </h1>
        <div className="flex flex-col items-end gap-1">
          <div className="flex gap-1">
            {bugBountyDetails.status ===
            BugBountyStatusResponseValue.REQUESTED ? (
              <>
                <Button
                  variant="secondary"
                  size="large"
                  onClick={onAcceptRequest}
                >
                  <CheckLine height={"1.25rem"} width={"1.25rem"} />
                  {t("button.accept")}
                </Button>
                <Button
                  variant="warning"
                  size="large"
                  onClick={onRejectRequest}
                >
                  <CloseLine height={"1.25rem"} width={"1.25rem"} />
                  {t("button.reject")}
                </Button>
              </>
            ) : null}
            {bugBountyDetails.status === BugBountyStatusResponseValue.OPEN ? (
              <>
                <Button
                  variant="secondary"
                  size="large"
                  onClick={onLockBugBountyProgram}
                >
                  <LockLine height={"1.25rem"} width={"1.25rem"} />
                  {t("button.lock")}
                </Button>
                <Button
                  variant="warning"
                  size="large"
                  onClick={onCloseBugBountyProgram}
                >
                  <CloseCircleLine height={"1.25rem"} width={"1.25rem"} />
                  {t("button.close")}
                </Button>
              </>
            ) : null}
            {bugBountyDetails.status === BugBountyStatusResponseValue.LOCKED ? (
              <>
                <Button
                  variant="secondary"
                  size="large"
                  onClick={onUnlockBugBountyProgram}
                >
                  {t("button.unlock")}
                </Button>
                <Button
                  variant="warning"
                  size="large"
                  onClick={onCloseBugBountyProgram}
                >
                  <CloseCircleLine height={"1.25rem"} width={"1.25rem"} />
                  {t("button.close")}
                </Button>
              </>
            ) : null}
            {/* Status closed has no buttons */}
          </div>
          {[
            BugBountyDetailsTabEnum.PROGRAM_BRIEF,
            BugBountyDetailsTabEnum.REQUIREMENTS,
          ].includes(activeTab) ? (
            isEditing ? (
              <div className="flex gap-1">
                <Button
                  variant="secondary"
                  size="large"
                  onClick={() => {
                    if (activeTab === BugBountyDetailsTabEnum.PROGRAM_BRIEF) {
                      setProgramBriefStates(
                        getProgramBriefStatesFromDetails(bugBountyDetails)
                      );
                    }
                    if (activeTab === BugBountyDetailsTabEnum.REQUIREMENTS) {
                      requirementsRef.current?.onRevertChanges();
                    }
                    setEditing(false);
                  }}
                >
                  <ArrowGoBackLine height={"1.25rem"} width={"1.25rem"} />
                  {t("button.revertAllChanges")}
                </Button>
                <Button
                  size="large"
                  onClick={() => {
                    if (activeTab === BugBountyDetailsTabEnum.PROGRAM_BRIEF) {
                      briefRef.current?.onSaveChanges();
                    }
                    if (activeTab === BugBountyDetailsTabEnum.REQUIREMENTS) {
                      requirementsRef.current?.onSaveChanges();
                    }
                    setEditing(false);
                  }}
                >
                  <SaveLine height={"1.25rem"} width={"1.25rem"} />
                  {t("button.saveChanges")}
                </Button>
              </div>
            ) : (
              <Button
                size="large"
                onClick={() => {
                  setEditing(true);
                }}
              >
                <EditLine height={"1.25rem"} width={"1.25rem"} />
                {t("button.updateProgram")}
              </Button>
            )
          ) : null}
        </div>
      </StickySection>
      <ContentSection>
        <Tabs
          items={tabList}
          activeKey={activeTab}
          onChange={onChangeTab}
          destroyInactiveTabPane
        />
      </ContentSection>
    </>
  ) : null;
};

export default MsspBugBountyDetails;
