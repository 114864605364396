interface ICollapsePanelHeadingProps {
  active?: boolean;
  title: string;
}

interface ICollapsePanelHeading3Props extends ICollapsePanelHeadingProps {
  instruction?: string;
}

export const CollapsePanelHeading3 = ({
  active = true,
  title,
  instruction,
}: ICollapsePanelHeading3Props) => {
  return (
    <div className="flex flex-col gap-4">
      <h3
        className={`${
          active === true ? "text-dark-blue" : "text-medium-grey"
        } overflow-hidden whitespace-nowrap text-ellipsis transition duration-300`}
      >
        {title}
      </h3>
      {instruction ? (
        <p className="font-regular-14 text-hard-grey">{instruction}</p>
      ) : null}
    </div>
  );
};

export const CollapsePanelHeading4 = ({
  active,
  title,
}: ICollapsePanelHeadingProps) => {
  return (
    <h4
      className={`${
        active === undefined || active === true
          ? "text-dark-blue"
          : "text-medium-grey"
      } overflow-hidden whitespace-nowrap text-ellipsis transition duration-300`}
    >
      {title}
    </h4>
  );
};
