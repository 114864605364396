// Libraries
import { Dropdown, Typography } from "@lockerpm/design";
import type { ItemType } from "@lockerpm/design/es/menu/hooks/useItems";
import { useTranslation } from "react-i18next";
import { useState } from "react";
import i18next from "i18next";

// Resources
import { ReactComponent as MoreLine } from "#src/assets/images/icons/more-line.svg";
import { ReactComponent as EditLine } from "#src/assets/images/icons/edit-line.svg";
import { ReactComponent as DeleteBin6Line } from "#src/assets/images/icons/delete-bin-6-line.svg";

// Components
import {
  TableActionLabel,
  TableCheckbox,
  TableDataCell,
  TableHeader,
  TableRowWrapper,
} from "#src/components/common/Table";
import LoadingState from "#src/components/common/system/LoadingState";
import NoResultsState from "#src/components/common/states/NoResultsState";
import type { ITableHeaderPropItem } from "#src/components/common/Table/TableHeader";
import { IconButton } from "#src/components/common/system/Button";
import { RoundedTag } from "#src/components/common/Tags";
import { createToast } from "#src/components/common/system/toasts";
import { apiErrorHandler } from "#src/utils/apiErrorHandler";

// API-related
import type { IPolicyItem } from "#src/services/devices/policies";
import devicesServices from "#src/services/devices";

// Children
import EditPolicyDrawer from "../drawers/EditPolicyDrawer";

interface IPolicyTableRowProps {
  data: IPolicyItem;
  actionList: ItemType[];
  onClickActionItems: (key: string, item: IPolicyItem) => void;
  selectedPolicyIds: number[];
  onChangeSelectedPolicies: (selected: IPolicyItem) => void;
}

const PolicyTableRow = ({
  data,
  actionList,
  onClickActionItems,
  selectedPolicyIds,
  onChangeSelectedPolicies,
}: IPolicyTableRowProps) => {
  const locale = i18next.language;

  const hosts = data.hosts_tags.filter((item) => item.type === "host");
  const tags = data.hosts_tags.filter((item) => item.type === "tag");

  return (
    <TableRowWrapper to={`${data.id}`}>
      <TableDataCell className="p-0 justify-center">
        <TableCheckbox
          checked={selectedPolicyIds.includes(data.id)}
          onClick={() => {
            onChangeSelectedPolicies(data);
          }}
        />
      </TableDataCell>
      <TableDataCell>
        {locale === "vi" ? data.policy.name.vi : data.policy.name.en}
      </TableDataCell>
      <TableDataCell>
        <div className="flex flex-col gap-3">
          {hosts.length ? (
            <Typography.Text
              ellipsis={{
                tooltip: hosts.map((host) => host.name).join(", "),
              }}
            >
              {hosts.map((host) => host.name).join(", ")}
            </Typography.Text>
          ) : null}
          {tags.length ? (
            <div className="flex flex-wrap gap-1">
              {tags.map((tag) => (
                <RoundedTag
                  key={`policy-table-row${data.id}-tag-${tag.id}`}
                  name={tag.name}
                />
              ))}
            </div>
          ) : null}
        </div>
      </TableDataCell>
      <TableDataCell className="p-0 justify-center">
        <Dropdown
          menu={{
            items: actionList,
            onClick: ({ key, domEvent }) => {
              domEvent.preventDefault();
              domEvent.stopPropagation();
              onClickActionItems(key, data);
            },
          }}
          trigger={["click"]}
          disabled={selectedPolicyIds.length > 0}
        >
          <IconButton
            variant="ghost"
            size={9}
            onClick={(e) => {
              e.preventDefault();
            }}
            disabled={selectedPolicyIds.length > 0}
          >
            <MoreLine />
          </IconButton>
        </Dropdown>
      </TableDataCell>
    </TableRowWrapper>
  );
};

interface IPolicyTableGridProps {
  workspaceId: string;
  isLoading: boolean;
  policyList: IPolicyItem[] | null;
  selectedPolicyIds: number[];
  onChangeSelectedPolicies: (selected: IPolicyItem) => void;
  refreshPolicyList: () => void;
}

const PolicyTableGrid = ({
  workspaceId,
  isLoading,
  policyList,
  selectedPolicyIds,
  onChangeSelectedPolicies,
  refreshPolicyList,
}: IPolicyTableGridProps) => {
  const { t } = useTranslation("devices", { keyPrefix: "policies" });

  const [openEditDrawer, setOpenEditDrawer] = useState<boolean>(false);
  const [editingItemId, setEditingItemId] = useState<number | null>(null);

  const headers: ITableHeaderPropItem[] = [
    { name: "", align: "left" },
    { name: t("headers.name"), align: "left" },
    { name: t("headers.appliesTo"), align: "left" },
    // {name: t("headers.policyCategory"), align:"left"},
    // { name: t("headers.enabled"), align: "center" },
    { name: t("headers.actions"), align: "center" },
  ];

  const actionList = [
    {
      key: "edit",
      label: <TableActionLabel icon={<EditLine />} text={t("actions.edit")} />,
    },
    {
      key: "delete",
      label: (
        <TableActionLabel
          icon={<DeleteBin6Line />}
          text={t("actions.delete")}
          warning
        />
      ),
    },
  ];

  const onClickActionItems = (key: string, item: IPolicyItem) => {
    switch (key) {
      case "edit":
        setEditingItemId(item.id);
        setOpenEditDrawer(true);
        return;
      case "delete":
        onDisablePolicy(item);
        return;
    }
  };

  const onDisablePolicy = (item: IPolicyItem) => {
    devicesServices
      .delete_workspace_policy(workspaceId, item.id)
      .then(() => {
        refreshPolicyList();
        createToast({
          type: "success",
          message: t("notification.updatePolicy.success"),
        });
      })
      .catch((error) => {
        apiErrorHandler(error, {
          toastMessage: t("notification.updatePolicy.fail"),
        });
      });
  };

  return (
    <>
      {isLoading || policyList === null ? (
        <LoadingState />
      ) : policyList.length === 0 ? (
        <NoResultsState />
      ) : (
        <div className="grid grid-cols-[2.75rem_4fr_4fr_6rem]">
          <TableHeader title="devices/hosts" headerProps={headers} />
          {policyList.map((policy) => (
            <PolicyTableRow
              key={`tdevices/policies-tr-${policy.id}`}
              data={policy}
              actionList={actionList}
              onClickActionItems={onClickActionItems}
              selectedPolicyIds={selectedPolicyIds}
              onChangeSelectedPolicies={onChangeSelectedPolicies}
            />
          ))}
        </div>
      )}
      <EditPolicyDrawer
        selectedItemId={editingItemId}
        open={openEditDrawer}
        onClose={() => {
          setOpenEditDrawer(false);
          setEditingItemId(null);
        }}
        workspaceId={workspaceId}
        onRefresh={refreshPolicyList}
      />
    </>
  );
};

export default PolicyTableGrid;
