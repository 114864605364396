// Libraries
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";

// Resources
import { ReactComponent as AddLine } from "#src/assets/images/icons/add-line.svg";
import { ReactComponent as GitRepositoryLine } from "#src/assets/images/icons/git-repository-line.svg";

// Components
import { Button } from "#src/components/common/system/Button";
import { ContentSection, StickySection } from "#src/layouts/content";

// Children
import IpAddressTable from "#src/components/assets/IpAddressTable";

const AssetsIpAddress = () => {
  const { t } = useTranslation("assets", {
    keyPrefix: "ipAddress.page.ipAddress",
  });

  const { workspace: workspaceId } = useParams<"workspace">();

  const [openAddIpDrawer, setOpenAddIpDrawer] = useState<boolean>(false);

  return workspaceId ? (
    <>
      <StickySection>
        <div className="flex flex-col gap-4">
          <h1>{t("title")}</h1>
          <div className="flex gap-2">
            <GitRepositoryLine
              width="1.25rem"
              height="1.25rem"
              className="fill-primary"
            />
            <a>{t("link.learnMore")}</a>
          </div>
        </div>
        <Button
          size="large"
          onClick={() => setOpenAddIpDrawer((prev) => !prev)}
        >
          <AddLine />
          {t("button.newIpAddress")}
        </Button>
      </StickySection>
      <ContentSection>
        <IpAddressTable
          workspaceId={workspaceId}
          openAddIpAddressDrawer={openAddIpDrawer}
          setOpenAddIpAddressDrawer={setOpenAddIpDrawer}
        />
      </ContentSection>
    </>
  ) : null;
};

export default AssetsIpAddress;
