// Libraries
import { useTranslation } from "react-i18next";

// Resources
import { ReactComponent as DeleteBin6Line } from "#src/assets/images/icons/delete-bin-6-line.svg";

// General
import type { IFilterItem } from "#src/@types/common";
import global from "#src/config/global";

// Components
import { IconButton } from "#src/components/common/system/Button";
import {
  FilterDropdown,
  SearchBox,
  SelectAllItemsDropdown,
} from "#src/components/common/Table/Filter";

// API-related
import {
  assetVulnerableStatusItems,
  verifiedOwnershipItems,
} from "#src/config/filter/asset";

interface IIpAddressFilterProps {
  vulnerable: IFilterItem | null;
  verifiedOwnership: IFilterItem | null;
  searchKeyword: string;
  totalCount: number;
  selectedCount: number;
  onChangeVulnerableSelection: (selected: IFilterItem | null) => void;
  onChangeVerifiedOwnershipSelection: (selected: IFilterItem | null) => void;
  onChangeSearchKeyword: (keyword: string) => void;
  onSelectAllIpAddresses: () => void;
  onDeselectAllIpAddresses: () => void;
  onDeleteSelectedIpAddresses: () => void;
}

const IpAddressFilter = ({
  vulnerable,
  verifiedOwnership,
  searchKeyword,
  totalCount,
  selectedCount,
  onChangeVulnerableSelection,
  onChangeVerifiedOwnershipSelection,
  onChangeSearchKeyword,
  onSelectAllIpAddresses,
  onDeselectAllIpAddresses,
  onDeleteSelectedIpAddresses,
}: IIpAddressFilterProps) => {
  const { t } = useTranslation("assets", {
    keyPrefix: "ipAddress.page.ipAddress.filter",
  });

  return (
    <div className="flex justify-between gap-1">
      <div className="flex gap-1 whitespace-nowrap flex-wrap">
        <SelectAllItemsDropdown
          selectedCount={selectedCount}
          totalCount={totalCount}
          onSelectAll={onSelectAllIpAddresses}
          onDeselectAll={onDeselectAllIpAddresses}
        />
        <IconButton
          variant="ghost"
          disabled={selectedCount === 0}
          onClick={() => {
            global.confirm(() => onDeleteSelectedIpAddresses());
          }}
        >
          <DeleteBin6Line />
        </IconButton>
        <FilterDropdown
          label={t("vulnerable.label")}
          selected={vulnerable}
          options={assetVulnerableStatusItems}
          onChangeSelection={onChangeVulnerableSelection}
        />
        <FilterDropdown
          label={t("verifyOwnership.label")}
          selected={verifiedOwnership}
          options={verifiedOwnershipItems}
          onChangeSelection={onChangeVerifiedOwnershipSelection}
        />
      </div>

      <SearchBox
        searchKeyword={searchKeyword}
        onChangeSearchKeyword={onChangeSearchKeyword}
      />
    </div>
  );
};

export default IpAddressFilter;
