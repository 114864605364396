import type { ReactNode } from "react";
import type { IFilterItem } from "#src/@types/common";
import { ReactComponent as LogoWindows10 } from "#src/assets/images/logos/logo-windows-10.svg";
import { ReactComponent as LogoApple } from "#src/assets/images/logos/logo-apple.svg";
import { ReactComponent as LogoUbuntu } from "#src/assets/images/logos/logo-ubuntu.svg";
import { ReactComponent as ImageCircleFill } from "#src/assets/images/icons/image-circle-fill.svg";

const iconByOs: { [os: string]: ReactNode } = {
  windows: <LogoWindows10 className="h-6 w-6" />,
  darwin: <LogoApple className="h-6 w-6" />,
  ubuntu: <LogoUbuntu className="h-6 w-6" />,
};

interface IDropdownItemLabelProps {
  item: IFilterItem;
  selected: IFilterItem | null;
}

const OsItemLabel = ({ item, selected }: IDropdownItemLabelProps) => {
  return (
    <span
      className={`flex items-center gap-3 font-medium-16 p-3 min-w-[10rem] ${
        selected?.key && item.key === selected.key
          ? "text-hard-grey"
          : "text-medium-grey"
      } text-center`}
    >
      {item.value ? (
        iconByOs[item.value]
      ) : item.value === undefined ? null : (
        <ImageCircleFill className="h-6 w-6" />
      )}
      {item.getLabel()}
    </span>
  );
};

export default OsItemLabel;
