// Libraries
import { Checkbox, Modal, Tabs } from "@lockerpm/design";
import { type Dispatch, type SetStateAction, useState } from "react";
import { useTranslation } from "react-i18next";
import { generatePath, useNavigate } from "react-router-dom";
import i18next from "i18next";

// General
import { pathname } from "#src/config/pathname";

// Components
import { Button } from "#src/components/common/system/Button";
import TabButtonLabel from "#src/components/common/helper/antdProps/Tabs/TabButtonLabel";
import NoResultsState from "#src/components/common/states/NoResultsState";

// API-related
import type { IPolicyInfoItem } from "#src/services/devices/policies";
import devicesServices from "#src/services/devices";

const PolicyTab = ({
  policies,
  notToAddIds,
  setNotToAddIds,
}: {
  policies: IPolicyInfoItem[];
  notToAddIds: number[];
  setNotToAddIds: Dispatch<SetStateAction<number[]>>;
}) => {
  const locale = i18next.language;

  return (
    <div className="flex flex-col gap-1 h-[40vh] overflow-auto pt-4">
      {policies.length ? (
        policies.map((policy) => (
          <div
            key={`addPolicies-confirmModal-${policy.id}`}
            className="px-6 py-4 border border-light-grey rounded-md font-medium-16 flex justify-between items-start gap-4"
          >
            {locale === "vi" ? policy.name.vi : policy.name.en}
            <Checkbox
              checked={!notToAddIds.includes(policy.id)}
              onClick={() => {
                if (notToAddIds.includes(policy.id)) {
                  setNotToAddIds((prev) =>
                    prev.filter((item) => item !== policy.id)
                  );
                } else {
                  setNotToAddIds((prev) => [...prev, policy.id]);
                }
              }}
            />
          </div>
        ))
      ) : (
        <NoResultsState />
      )}
    </div>
  );
};

interface IConfirmAddingPoliciesModalProps {
  open: boolean;
  onClose: () => void;
  selectedPolicies: IPolicyInfoItem[];
  workspaceId: string;
  onRefresh: () => void;
}

const ConfirmAddingPoliciesModal = ({
  open,
  onClose,
  selectedPolicies,
  workspaceId,
  onRefresh,
}: IConfirmAddingPoliciesModalProps) => {
  const { t } = useTranslation("devices", {
    keyPrefix: "policies.addPage.confirmModal",
  });

  const navigate = useNavigate();

  const AddPolicyModalTabEnum = {
    WINDOWS: "ADD_POLICY_MODAL_TAB_WINDOWS",
    MAC: "ADD_POLICY_MODAL_TAB_MAC",
    LINUX: "ADD_POLICY_MODAL_TAB_LINUX",
  } as const;

  // stores ids of selected policies that is being unchecked
  const [notToAddIds, setNotToAddIds] = useState<number[]>([]);
  const [activeTab, setActiveTab] = useState<string>(
    AddPolicyModalTabEnum.WINDOWS
  );

  const actualPolicies = selectedPolicies.filter(
    (policy) => !notToAddIds.includes(policy.id)
  );

  const onCloseAndCleanup = () => {
    setNotToAddIds([]);
    onClose();
  };

  const onClickAdd = () => {
    if (workspaceId) {
      Promise.allSettled(
        selectedPolicies.map((policy) => {
          return devicesServices.create_workspace_policy(workspaceId, {
            policy_id: policy.id,
            hosts_tags: [],
          });
        })
      ).then(() => {
        onRefresh();
        onCloseAndCleanup();
        navigate(generatePath(pathname.POLICIES, { workspace: workspaceId }));
      });
    }
  };

  const tabList = [
    {
      key: AddPolicyModalTabEnum.WINDOWS,
      label: <TabButtonLabel name={"windows"} />,
      children: (
        <PolicyTab
          policies={selectedPolicies.filter(
            (policy) => policy.platform === "windows"
          )}
          notToAddIds={notToAddIds}
          setNotToAddIds={setNotToAddIds}
        />
      ),
    },
    {
      key: AddPolicyModalTabEnum.MAC,
      label: <TabButtonLabel name={"mac"} />,
      children: (
        <PolicyTab
          policies={selectedPolicies.filter(
            (policy) => policy.platform === "darwin"
          )}
          notToAddIds={notToAddIds}
          setNotToAddIds={setNotToAddIds}
        />
      ),
    },
    {
      key: AddPolicyModalTabEnum.LINUX,
      label: <TabButtonLabel name={"linux"} />,
      children: (
        <PolicyTab
          policies={selectedPolicies.filter(
            (policy) => policy.platform === "linux"
          )}
          notToAddIds={notToAddIds}
          setNotToAddIds={setNotToAddIds}
        />
      ),
    },
  ];

  return (
    <Modal
      open={open}
      onCancel={onCloseAndCleanup}
      title={
        <h2>
          <span className="text-primary">{actualPolicies.length}</span>
          {t("title", { count: actualPolicies.length })}
        </h2>
      }
      centered
      footer={null}
      width="44rem"
    >
      <div className="flex flex-col gap-6">
        <p className="font-regular-14">{t("description")}</p>
        <Tabs
          items={tabList}
          activeKey={activeTab}
          onChange={(key) => {
            setActiveTab(key);
          }}
        />
        <div className="flex justify-end">
          <div className="flex gap-1">
            <Button variant="secondary" onClick={onCloseAndCleanup}>
              {t("button.cancel")}
            </Button>
            <Button onClick={onClickAdd}>{t("button.add")}</Button>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default ConfirmAddingPoliciesModal;
