// Libraries
import type { ReactNode } from "react";

// Common components
import type { IAssetDetailItem } from "#src/services/assets";

// Children
import OverviewCard from "./OverviewCard";
import PortsCard from "./PortsCard";
import SubdomainsCard from "./SubdomainsCard";
import VulnerabilitiesCard from "./VulnerabilitiesCard";
import CertificatesCard from "./CertificatesCard";
import { AssetDetailTabEnum } from "../enums";

interface IOverviewTabCardLayoutProps {
  children: ReactNode;
  horizontal?: boolean;
  stretch?: boolean;
}

export const OverviewTabCardLayout = ({
  children,
  horizontal = false,
  stretch = false,
}: IOverviewTabCardLayoutProps) => {
  return (
    <div
      className={`flex ${horizontal ? "flex-row" : "flex-col"} ${
        stretch ? "flex-1" : "w-fit"
      } bg-bright-grey rounded-md p-6 min-w-[20rem]`}
    >
      {children}
    </div>
  );
};

interface IAssetsDetailsOverviewProps {
  assetDetails: IAssetDetailItem;
  onChangeTab: (key: string) => void;
}

const AssetsDetailsOverview = ({
  assetDetails,
  onChangeTab,
}: IAssetsDetailsOverviewProps) => {
  return (
    <section className="flex flex-col gap-6 pt-6">
      <div className="flex gap-6 w-full flex-wrap">
        <OverviewTabCardLayout stretch>
          <OverviewCard assetDetails={assetDetails} />
        </OverviewTabCardLayout>
        <OverviewTabCardLayout stretch>
          <PortsCard
            portDetails={assetDetails.ports}
            onChangeTab={onChangeTab}
          />
        </OverviewTabCardLayout>
        {assetDetails.is_subdomain || !assetDetails.detail.is_domain ? null : (
          <OverviewTabCardLayout stretch>
            <SubdomainsCard
              subdomainDetails={assetDetails.subdomains}
              onChangeTab={onChangeTab}
            />
          </OverviewTabCardLayout>
        )}
      </div>
      <OverviewTabCardLayout horizontal stretch>
        <VulnerabilitiesCard
          vulnData={{
            count: assetDetails.vulnerabilities.count,
            topVulns: assetDetails.vulnerabilities.list.map((item) => ({
              id: item.id.toString(),
              name: item.title,
              severity: item.severity,
            })),
            byStatus: assetDetails.vulnerabilities.by_sub_status,
            bySeverity: assetDetails.vulnerabilities.by_severity,
          }}
          onClickSeeAll={() => {
            onChangeTab(AssetDetailTabEnum.VULNERABILITIES);
          }}
        />
      </OverviewTabCardLayout>
      {assetDetails.certificates ? (
        <OverviewTabCardLayout>
          <CertificatesCard
            address={assetDetails.detail.address}
            certificateDetails={assetDetails.certificates}
            onChangeTab={onChangeTab}
          />
        </OverviewTabCardLayout>
      ) : null}
    </section>
  );
};

export default AssetsDetailsOverview;
