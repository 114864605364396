import type { IChartPlotProps } from "#src/@types/chart";

interface IChartLegendsProps {
  data: IChartPlotProps[];
  keyTemplate: string;
  showPercentage?: boolean;
}

const ChartLegends = ({
  data,
  keyTemplate,
  showPercentage = false,
}: IChartLegendsProps) => {
  const totalCount = data.reduce((prev, cur) => {
    return prev + cur.value;
  }, 0);

  return (
    <div className="flex flex-col gap-3">
      {data.map((item) => (
        <div
          key={
            keyTemplate
              ? `${keyTemplate}-legend${item.id}`
              : `untitled-legend${item.id}`
          }
          className="flex items-center gap-2"
        >
          <div
            style={{ backgroundColor: item.fill }}
            className="rounded-full w-2.5 h-2.5"
          />
          <span className="font-regular-14 text-hard-grey flex gap-0.5">
            {showPercentage && totalCount > 0 ? (
              <span className="font-bold-14">
                {((item.value / totalCount) * 100).toFixed(0)}%
              </span>
            ) : null}
            {item.name}
          </span>
        </div>
      ))}
    </div>
  );
};

export default ChartLegends;
