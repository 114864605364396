// Libraries
import { useState } from "react";
import { Drawer } from "@lockerpm/design";
import { AxiosError } from "axios";
import { useTranslation } from "react-i18next";
import { z } from "zod";

// Resources
import { ReactComponent as CloseLine } from "#src/assets/images/icons/close-line.svg";

// General
import type { IDrawerAdapterProps } from "#src/@types/common";
import patterns from "#src/config/patterns";

// Components
import { Button, IconButton } from "#src/components/common/system/Button";
import { createToast } from "#src/components/common/system/toasts";
import { InputField } from "#src/components/common/helper/wrapper/InputField";

// API-related
import assetsServices from "#src/services/assets";
import { apiErrorHandler, parseBadRequest } from "#src/utils/apiErrorHandler";

// Children
import VerifyOwnershipDrawer from "#src/components/assets/drawers/VerifyOwnershipDrawer";

interface IAddIpAddressDrawerProps extends IDrawerAdapterProps {}

/** Drawer for adding IP address
 * @param open - state of the drawer
 * @param workspaceId
 * @param onClose
 * @param onRefresh - a callback function that update data on display outside this drawer, usually called after data changed.
 */
const AddIpAddressDrawer = ({
  open,
  workspaceId,
  onClose,
  onRefresh,
}: IAddIpAddressDrawerProps) => {
  const { t } = useTranslation("assets", {
    keyPrefix: "ipAddress.drawer.addIpAddress",
  });

  const [openVerifyDrawer, setOpenVerifyDrawer] = useState<boolean>(false);
  // Controls IP address input
  const [ipAddress, setIpAddress] = useState<string>("");
  const [ipName, setIpName] = useState<string>("");
  const [assetId, setIpAddressId] = useState<number | null>(null);
  const [errorObj, setErrorObj] = useState<{
    address: string | undefined;
  }>({ address: undefined });

  const [pending, setPending] = useState<boolean>(false);

  const onCloseDrawerAndCleanup = () => {
    onClose();
    setIpAddress("");
    setIpName("");
    setIpAddressId(null);
    setErrorObj({ address: undefined });
  };

  const addNewIpAddress = () => {
    setPending(true);
    assetsServices
      .create_asset(workspaceId, {
        name: ipName ? ipName : ipAddress,
        address: ipAddress,
        description: "",
      })
      .then(() => {
        onCloseDrawerAndCleanup();
        onRefresh();
        createToast({
          type: "success",
          message: t("notification.justAdd"),
        });
      })
      .catch((error: unknown) => {
        if (
          error instanceof AxiosError &&
          error.response &&
          error.response.status === 400
        ) {
          const getErrorData = parseBadRequest(
            error.response.data,
            z.object({
              address: z.optional(z.array(z.string())),
            })
          );
          setErrorObj((prev) => ({
            ...prev,
            address: getErrorData.details.address
              ? getErrorData.details.address[0]
              : undefined,
          }));
        } else {
          apiErrorHandler(error);
        }
      })
      .finally(() => {
        setPending(false);
      });
  };

  return (
    <>
      {/* First Drawer */}
      <Drawer
        open={open}
        onClose={onCloseDrawerAndCleanup}
        closable={false}
        title={<h2>{t("title")}</h2>}
        extra={
          <IconButton
            variant="label-grey"
            size={9}
            onClick={onCloseDrawerAndCleanup}
          >
            <CloseLine />
          </IconButton>
        }
        footer={
          <div className="flex flex-col gap-3">
            <Button
              className="w-full"
              size="large"
              disabled={!ipAddress || !patterns.IP.test(ipAddress)}
              pending={pending}
              onClick={addNewIpAddress}
            >
              {t("button.addAndVerify")}
            </Button>
            <Button
              variant="secondary"
              className="w-full"
              size="large"
              disabled={!ipAddress || !patterns.IP.test(ipAddress)}
              pending={pending}
              onClick={addNewIpAddress}
            >
              {t("button.justAdd")}
            </Button>
          </div>
        }
      >
        <div className="flex flex-col gap-6 p-6">
          <InputField
            title={t("field.newIpAddress.label")}
            placeholder={t("field.newIpAddress.placeholder")}
            value={ipAddress}
            onChangeValue={(value) => {
              setIpAddress(value);
            }}
            error={errorObj.address}
            onChangeError={(value) => {
              setErrorObj((prev) => ({ ...prev, address: value }));
            }}
            pattern={patterns.IP}
            patternFailedString={t("field.newIpAddress.error.invalid")}
          />
          <InputField
            title={t("field.newIpName.label")}
            placeholder={t("field.newIpName.placeholder")}
            value={ipName}
            onChangeValue={(value) => {
              setIpName(value);
            }}
          />
        </div>
      </Drawer>

      <VerifyOwnershipDrawer
        open={openVerifyDrawer}
        onClose={() => {
          setOpenVerifyDrawer(false);
          onCloseDrawerAndCleanup();
        }}
        workspaceId={workspaceId}
        onRefresh={() => {}}
        assetName={ipAddress}
        assetId={assetId}
      />
    </>
  );
};

export default AddIpAddressDrawer;
