// Libraries
import { useTranslation } from "react-i18next";

// Components
import LoadingState from "#src/components/common/system/LoadingState";
import NoResultsState from "#src/components/common/states/NoResultsState";
import { TableHeader } from "#src/components/common/Table";
import type { ITableHeaderPropItem } from "#src/components/common/Table/TableHeader";
import type {ICertificateItemTableData} from "#src/services/assets/certificates";

import CertificateTableRow from "./TableRow";

interface ICertificateTableProps {
  certificateList: ICertificateItemTableData[] | null;
  isLoading: boolean;
  onViewLinkedDomain: (value:ICertificateItemTableData) => void
}

const CertificateTableGrid = ({
  certificateList,
  isLoading,
  onViewLinkedDomain
}: ICertificateTableProps) => {
  const { t } = useTranslation("assets", { keyPrefix: "certificates.page.certificates" });

  const headers: ITableHeaderPropItem[] = [
    { name: t("headers.name"), align: "left" },
    { name: t("headers.issuer"), align: "left" },
    { name: t("headers.expiration"), align: "center" },
    { name: t("headers.algorithm"), align: "left" },
    { name: t("headers.key_size"), align: "left" },
    { name: t("headers.last_found"), align: "left" },
    { name: t("headers.domains"), align: "left" },
    { name: '', align: "left" },
  ];


  return (
    <>
      {isLoading || certificateList === null ? (
        <LoadingState />
      ) : certificateList.length === 0 ? (
        <NoResultsState />
      ) : (
        <div className="grid grid-cols-[2fr_2fr_2fr_1fr_1fr_1fr_1fr_1fr]">
          <TableHeader title="assets/domains" headerProps={headers} />
          {certificateList.map((item) => (
            <CertificateTableRow
              key={`assets/certificates-tr${item.id}`}
              data={item}
              onViewLinkedDomain={onViewLinkedDomain}
            />
          ))}
        </div>
      )}
    </>
  );
};

export default CertificateTableGrid;
