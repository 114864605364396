// Libraries
import {
  createSearchParams,
  Navigate,
  Outlet,
  useParams,
} from "react-router-dom";
import { useEffect } from "react";

// General
import { pathname } from "#src/config/pathname";
import { selectWorkspace } from "#src/store/slices/workspace";
import { useAppDispatch, useAppSelector } from "#src/store/hooks";

// Components
import { apiErrorHandler } from "#src/utils/apiErrorHandler";

// API-related
import authServices from "#src/services/auth";
import workspaceServices from "#src/services/workspace";

// Children
import LayoutHeader from "./header";
import LayoutSidebar from "./sidebar";

/** Main layout covers all authenticated pages. */
const MainLayout = () => {
  const authToken = authServices.access_token();

  const dispatch = useAppDispatch();
  const selectedWorkspace = useAppSelector(
    (state) => state.workspace.selectedWorkspace
  );
  const { workspace: workspaceId } = useParams<{ workspace: string }>();

  useEffect(() => {
    if (!authToken) return;
    if (workspaceId && workspaceId !== selectedWorkspace) {
      dispatch(selectWorkspace(workspaceId));
    } else {
      if (!selectedWorkspace) {
        workspaceServices
          .list_workspaces()
          .then((response) => {
            if (response && response.length >= 1) {
              dispatch(selectWorkspace(response[0].id));
            }
          })
          .catch(apiErrorHandler);
      }
    }
  }, [authToken, selectedWorkspace, workspaceId, dispatch]);

  return authToken ? (
    // TODO: *in a far future?* responsive layout
    <div className="h-screen w-screen overflow-hidden flex justify-between">
      {/* Stacking context inside this (below) div: LayoutSidebar = 1, div = 0 */}
      <LayoutSidebar />
      <div className="flex flex-col flex-1 items-center lg:w-[calc(100%_-_20rem)] w-[calc(100%_-_16.75rem)]">
        <LayoutHeader />
        {/* DEPRECATED: we no longer use PageContent, might want to remove the comment below */}
        {/* This has position relative to keep the sticky header on PageContent */}
        <div
          // Users will mainly scroll this container, so we give it an id to control the scroll actions.
          id="layout-content"
          style={{ scrollbarGutter: "stable" }}
          className="relative h-full w-full overflow-y-auto flex flex-col items-center"
        >
          <Outlet />
        </div>
      </div>
    </div>
  ) : (
    <Navigate
      to={{
        pathname: pathname.LOGIN,
        search: createSearchParams({
          return_url: window.location.pathname + window.location.search,
        }).toString(),
      }}
    />
  );
};

export default MainLayout;
