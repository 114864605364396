// Libraries
import type { ReactNode } from "react";
import { useTranslation } from "react-i18next";

// Resources
import { ReactComponent as ArrowRightUpLine } from "#src/assets/images/icons/arrow-right-up-line.svg";

// Components
import { Button } from "#src/components/common/system/Button";
import NoResultsState from "#src/components/common/states/NoResultsState";

// API-related
import type { IAssetDetailItem } from "#src/services/assets";

// Children
import { AssetDetailTabEnum } from "../enums";

const PortsCardHeader = ({ children }: { children: ReactNode }) => {
  return <div className="text-hard-grey h-8">{children}</div>;
};

const PortsCardHeaderRow = () => {
  const { t } = useTranslation("assets", {
    keyPrefix: "domains.page.details.tab.overview.card.ports.headers",
  });
  return (
    <>
      <PortsCardHeader>{t("port")}</PortsCardHeader>
      <PortsCardHeader>{t("protocol")}</PortsCardHeader>
      <PortsCardHeader>{t("service")}</PortsCardHeader>
      <PortsCardHeader>{t("product")}</PortsCardHeader>
    </>
  );
};

const PortsCardRow = ({
  portDetail,
}: {
  portDetail: IAssetDetailItem["ports"]["list"][number];
}) => {
  return (
    <div className="contents text-dark-blue align-middle">
      <div className="opacity-40 h-8 flex items-center">
        {portDetail.port ? portDetail.port : "--"}
      </div>
      <div className="flex items-center">
        {portDetail.protocol.toUpperCase()}
      </div>
      <div className="flex items-center">
        <p className="overflow-hidden text-ellipsis">
          {portDetail.service.toUpperCase()}
        </p>
      </div>
      <div className="flex items-center">
        <p className="overflow-hidden text-ellipsis">
          {portDetail.product ? portDetail.product : "--"}
        </p>
      </div>
    </div>
  );
};

const AssetsDetailsOverviewPorts = ({
  portDetails,
  onChangeTab,
}: {
  portDetails: IAssetDetailItem["ports"];
  onChangeTab: (key: string) => void;
}) => {
  const { t } = useTranslation("assets", {
    keyPrefix: "domains.page.details.tab.overview.card.ports",
  });

  return (
    <>
      <div className="flex gap-3 h-9 mb-5 items-center">
        <div className="flex justify-center items-center h-8 w-8 bg-label-blue rounded-md">
          <span className="font-bold-16 text-primary">{portDetails.count}</span>
        </div>
        <h2>{t("title")}</h2>
      </div>
      {portDetails.count ? (
        <>
          <div className="grid grid-cols-4 gap-4 text-hard-grey">
            <PortsCardHeaderRow />
            {portDetails.list.slice(0, 5).map((port) => (
              <PortsCardRow
                key={`PortCard_row-${port.port}`}
                portDetail={port}
              />
            ))}
          </div>
          <div className="mt-6 font-sm text-primary w-full flex justify-center items-center gap-2">
            <Button
              variant="text"
              size="medium"
              onClick={() => {
                onChangeTab(AssetDetailTabEnum.PORTS);
              }}
            >
              {t("button.viewAll")}
              <ArrowRightUpLine className="h-4 w-4" />
            </Button>
          </div>
        </>
      ) : (
        <NoResultsState />
      )}
    </>
  );
};

export default AssetsDetailsOverviewPorts;
