import type { IPaginateParams, IPaginatedResponse } from "#src/@types/api";
import endpoint from "#src/config/endpoint";
import request from "#src/utils/request";

export interface IScanScheduleItem {
  id: number;
  created_time: number;
  updated_time: number | null;
  activated: boolean;
  activated_time: number | null;
  schedule: boolean;
  timezone: string;
  timezone_value: number;
  repeat_number: number;
  duration_type: string;
  days: string[];
  domains: {
    id: number;
    name: string;
    address: string;
    include_subdomains: boolean;
  }[];
}

export function list_schedules(
  workspace_id: string,
  params: {
    // Should only be 0 | 1
    enabled: string | undefined;
    domain_id: string | undefined;
  } & IPaginateParams
) {
  return request.get<never, IPaginatedResponse<IScanScheduleItem>>(
    `${endpoint.WORKSPACES}/${workspace_id}/scans/schedules`,
    { params }
  );
}

export function get_detail_schedule(workspace_id: string, schedule_id: number) {
  return request.get<never, IScanScheduleItem>(
    `${endpoint.WORKSPACES}/${workspace_id}/scans/schedules/${schedule_id}`
  );
}

export function update_schedule(
  workspace_id: string,
  schedule_id: number,
  data: Partial<IScanScheduleItem> &
    Pick<
      IScanScheduleItem,
      "activated_time" | "schedule" | "timezone" | "days" | "duration_type"
    >
) {
  return request.put<never, IScanScheduleItem>(
    `${endpoint.WORKSPACES}/${workspace_id}/scans/schedules/${schedule_id}`,
    data
  );
}

export function delete_schedule(workspace_id: string, schedule_id: number) {
  return request.delete<never, { success: boolean }>(
    `${endpoint.WORKSPACES}/${workspace_id}/scans/schedules/${schedule_id}`
  );
}

export function delete_multiple_schedules(
  workspace_id: string,
  schedule_ids: number[]
) {
  return request.post<never, { success: boolean }>(
    `${endpoint.WORKSPACES}/${workspace_id}/scans/schedules/multiple_delete`,
    { ids: schedule_ids }
  );
}
