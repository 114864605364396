// import type { IPentestProjectOverview } from "#src/services/pentest";
import { useTranslation } from "react-i18next";

// interface IPentestDetailsOverviewTablesAndGraphsProps {
//   overviewDetails: IPentestProjectOverview;
// }

const PentestDetailsOverviewTablesAndGraphs = () => {
  const { t } = useTranslation("pentest", {
    keyPrefix: "page.pentestDetails.tab.overview",
  });

  return (
    <div className="grid grid-cols-3 gap-6">
      <div className="border border-grey rounded-md p-6 gap-6 col-span-2">
        <div className="flex flex-col gap-4">
          <h2>{t("vulnByTime.title")}</h2>
          <span className="text-hard-grey">{t("vulnByTime.info")}</span>
        </div>
      </div>
      <div className="border border-grey rounded-md p-6 gap-6">
        <div className="flex flex-col gap-4">
          <h2>{t("submissionStatus.title")}</h2>
          <span className="text-hard-grey">{t("submissionStatus.info")}</span>
        </div>
      </div>
      <div className="p-6 gap-6 col-span-2">
        <div className="flex flex-col gap-4">
          <h2>{t("assetsBreakdown.title")}</h2>
          <span className="text-hard-grey">{t("assetsBreakdown.info")}</span>
        </div>
      </div>
      <div className="border border-grey rounded-md p-6 gap-6"></div>
      <div className="p-6 gap-6 col-span-2">
        <div className="flex flex-col gap-4">
          <h2>{t("submissionSeverity.title")}</h2>
          <span className="text-hard-grey">{t("submissionSeverity.info")}</span>
        </div>
      </div>
      <div className="border border-grey rounded-md p-6 gap-6">
        <div className="flex flex-col gap-4">
          <h2>{t("submissionStatus.title")}</h2>
          <span className="text-hard-grey">{t("submissionStatus.info")}</span>
        </div>
      </div>
    </div>
  );
};

export default PentestDetailsOverviewTablesAndGraphs;
