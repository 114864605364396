// Libraries
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { generatePath, useNavigate } from "react-router-dom";
import { Dropdown, Modal } from "@lockerpm/design";
import { z } from "zod";
import i18next from "i18next";
import { AxiosError } from "axios";

// Resources
import { ReactComponent as ArrowDownSLine } from "#src/assets/images/icons/arrow-down-s-line.svg";

// General
import { pathname } from "#src/config/pathname";
import { updatePlan } from "#src/store/slices/trustCenter";
import { useAppDispatch } from "#src/store/hooks";
import patterns from "#src/config/patterns";

// Components
import { Button } from "#src/components/common/system/Button";
import DropdownItemLabel from "#src/components/common/helper/antdProps/Dropdown/DropdownItemLabel";
import DropdownTriggerButton from "#src/components/common/helper/antdProps/Dropdown/DropdownTriggerButton";
import { InputFieldWrapper } from "#src/components/common/composed/divWrappers/InputFieldWrapper";
import { InputField } from "#src/components/common/helper/wrapper/InputField";

// API-related
import trustCenterServices from "#src/services/trustCenter";
import { apiErrorHandler, parseBadRequest } from "#src/utils/apiErrorHandler";
import workspaceServices from "#src/services/workspace";

// Children
import {
  type ContactFormSelection,
  jobTitleList,
  pronounList,
  employeeSizeList,
} from "./contactDetailFields";
import ContactSuccessModal from "./SuccessModal";

interface ITrustCenterOnboardContactFormProps {
  workspaceId: string;
  open: boolean;
  onClose: () => void;
}

const TrustCenterOnboardContactForm = ({
  workspaceId,
  open,
  onClose,
}: ITrustCenterOnboardContactFormProps) => {
  const { t } = useTranslation("trustCenter", {
    keyPrefix: "onboard.contactModal",
  });

  const dispatch = useAppDispatch();

  const navigate = useNavigate();

  const [pronoun, setPronoun] = useState<ContactFormSelection>(pronounList[0]);
  const [fullName, setFullName] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const [phoneNumber, setPhoneNumber] = useState<string>("");
  const [organizationName, setCompanyName] = useState<string>("");
  const [jobTitle, setJobTitle] = useState<ContactFormSelection>(
    jobTitleList[0]
  );
  const [industry, setIndustry] = useState<string>("");
  const [employeeSize, setEmployeeSize] = useState<ContactFormSelection>(
    employeeSizeList[0]
  );

  const [errorObj, setErrorObj] = useState<{
    fullname: string | undefined;
    email: string | undefined;
    phone_number: string | undefined;
    organization_name: string | undefined;
    industry: string | undefined;
  }>({
    fullname: undefined,
    email: undefined,
    phone_number: undefined,
    organization_name: undefined,
    industry: undefined,
  });

  const [openSuccessModal, setOpenSuccessModal] = useState<boolean>(false);

  const requestEnterprisePlan = () => {
    trustCenterServices
      .request_trust_center_plan(workspaceId, {
        product_plan_id: "tc_enterprise",
        gender: pronoun.value,
        fullname: fullName,
        email: email,
        phone_number: phoneNumber,
        job_title: jobTitle.value,
        organization_name: organizationName,
        industry: industry,
        employee_size: employeeSize.value,
      })
      .then((response) => {
        dispatch(updatePlan(response.product_plan_id));
        setOpenSuccessModal(true);
        onClose();
      })
      .catch((error) => {
        if (
          error instanceof AxiosError &&
          error.response &&
          error.response.status === 400
        ) {
          const getErrorData = parseBadRequest(
            error.response.data,
            z.object({
              fullname: z.optional(z.array(z.string())),
              email: z.optional(z.array(z.string())),
              phone_number: z.optional(z.array(z.string())),
              organization_name: z.optional(z.array(z.string())),
              industry: z.optional(z.array(z.string())),
            })
          );
          setErrorObj((prev) => ({
            ...prev,
            fullname: getErrorData.details.fullname
              ? getErrorData.details.fullname[0]
              : undefined,
            email: getErrorData.details.email
              ? getErrorData.details.email[0]
              : undefined,
            phone_number: getErrorData.details.phone_number
              ? getErrorData.details.phone_number[0]
              : undefined,
            organization_name: getErrorData.details.organization_name
              ? getErrorData.details.organization_name[0]
              : undefined,
            industry: getErrorData.details.industry
              ? getErrorData.details.industry[0]
              : undefined,
          }));
        } else {
          apiErrorHandler(error);
        }
      });
  };

  const onCloseSuccessModal = () => {
    setOpenSuccessModal(false);
    navigate(
      generatePath(pathname.TRUST_CENTER_POLICY, { workspace: workspaceId })
    );
  };

  useEffect(() => {
    workspaceServices
      .retrieve_workspace(workspaceId)
      .then((response) => {
        setEmail(response.email);
        setPhoneNumber(response.phone);
        setCompanyName(response.name);
        setIndustry(response.industry);
        const getEmployeeSizeItem = employeeSizeList.find(
          (item) => item.value === response.employee_size
        );
        if (getEmployeeSizeItem) setEmployeeSize(getEmployeeSizeItem);
      })
      .catch(apiErrorHandler);
  }, [workspaceId]);

  return (
    <>
      <Modal
        open={open}
        onCancel={onClose}
        title={<h2>{t("title")}</h2>}
        centered
        footer={null}
        width="44rem"
      >
        <div className="flex flex-col gap-6">
          <p className="font-regular-14 text-hard-grey">{t("instruction")}</p>
          <div className="grid grid-cols-2 gap-x-6 gap-y-3">
            <InputFieldWrapper
              title={t("pronoun.label")}
              requiredMark
              input={
                <Dropdown
                  menu={{
                    items: pronounList.map((p) => ({
                      key: p.key,
                      label: (
                        <DropdownItemLabel
                          selected={pronoun.key === p.key}
                          syncWidthId="pronoun-dropdown"
                        >
                          {p.getLabel()}
                        </DropdownItemLabel>
                      ),
                      onClick: ({ key }) => {
                        const clickedItem = pronounList.find(
                          (p) => p.key === key
                        );
                        if (clickedItem) {
                          setPronoun(clickedItem);
                        }
                      },
                    })),
                  }}
                  trigger={["click"]}
                >
                  <DropdownTriggerButton id="pronoun-dropdown">
                    {pronoun.getLabel()}
                    <ArrowDownSLine
                      width={"1rem"}
                      height={"1rem"}
                      className="fill-hard-grey"
                    />
                  </DropdownTriggerButton>
                </Dropdown>
              }
            />
            <InputField
              title={t("fullName.label")}
              required
              placeholder={t("fullName.placeholder")}
              value={fullName}
              onChangeValue={(value) => {
                setFullName(value);
              }}
              error={errorObj.fullname}
              onChangeError={(value) => {
                setErrorObj((prev) => ({ ...prev, fullname: value }));
              }}
            />
            <InputField
              title={t("email.label")}
              required
              placeholder={t("email.placeholder")}
              value={email}
              onChangeValue={(value) => {
                setEmail(value);
              }}
              error={errorObj.email}
              onChangeError={(value) => {
                setErrorObj((prev) => ({ ...prev, email: value }));
              }}
              pattern={patterns.EMAIL}
              patternFailedString={i18next.t("common:validateWarning.email")}
            />
            <InputField
              title={t("phoneNumber.label")}
              required
              placeholder={t("phoneNumber.placeholder")}
              value={phoneNumber}
              onChangeValue={(value) => {
                setPhoneNumber(value);
              }}
              error={errorObj.phone_number}
              onChangeError={(value) => {
                setErrorObj((prev) => ({ ...prev, phone_number: value }));
              }}
              pattern={patterns.PHONE}
              patternFailedString={i18next.t("common:validateWarning.phone")}
            />
            <InputField
              title={t("organizationName.label")}
              required
              placeholder={t("organizationName.placeholder")}
              value={organizationName}
              onChangeValue={(value) => {
                setCompanyName(value);
              }}
              error={errorObj.organization_name}
              onChangeError={(value) => {
                setErrorObj((prev) => ({ ...prev, organization_name: value }));
              }}
            />
            <InputFieldWrapper
              title={t("position.label")}
              requiredMark
              input={
                <Dropdown
                  menu={{
                    items: jobTitleList.map((p) => ({
                      key: p.key,
                      label: (
                        <DropdownItemLabel
                          selected={jobTitle.key === p.key}
                          syncWidthId="position-dropdown"
                        >
                          {p.getLabel()}
                        </DropdownItemLabel>
                      ),
                      onClick: ({ key }) => {
                        const clickedItem = jobTitleList.find(
                          (p) => p.key === key
                        );
                        if (clickedItem) {
                          setJobTitle(clickedItem);
                        }
                      },
                    })),
                  }}
                  trigger={["click"]}
                >
                  <DropdownTriggerButton id="position-dropdown">
                    {jobTitle.getLabel()}
                    <ArrowDownSLine
                      width={"1rem"}
                      height={"1rem"}
                      className="fill-hard-grey"
                    />
                  </DropdownTriggerButton>
                </Dropdown>
              }
            />
            <InputField
              title={t("industry.label")}
              required
              placeholder={t("industry.placeholder")}
              value={industry}
              onChangeValue={(value) => {
                setIndustry(value);
              }}
              error={errorObj.industry}
              onChangeError={(value) => {
                setErrorObj((prev) => ({ ...prev, industry: value }));
              }}
            />
            <InputFieldWrapper
              title={t("employeeSize.label")}
              requiredMark
              input={
                <Dropdown
                  menu={{
                    items: employeeSizeList.map((p) => ({
                      key: p.key,
                      label: (
                        <DropdownItemLabel
                          selected={employeeSize.key === p.key}
                          syncWidthId="employeeSize-dropdown"
                        >
                          {p.getLabel()}
                        </DropdownItemLabel>
                      ),
                      onClick: ({ key }) => {
                        const clickedItem = employeeSizeList.find(
                          (p) => p.key === key
                        );
                        if (clickedItem) {
                          setEmployeeSize(clickedItem);
                        }
                      },
                    })),
                  }}
                  trigger={["click"]}
                >
                  <DropdownTriggerButton id="employeeSize-dropdown">
                    {employeeSize.getLabel()}
                    <ArrowDownSLine
                      width={"1rem"}
                      height={"1rem"}
                      className="fill-hard-grey"
                    />
                  </DropdownTriggerButton>
                </Dropdown>
              }
            />
          </div>
          <div className="flex justify-end">
            <Button
              onClick={requestEnterprisePlan}
              disabled={
                fullName.length === 0 ||
                email.length === 0 ||
                phoneNumber.length === 0 ||
                organizationName.length === 0 ||
                industry.length === 0
              }
            >
              {t("button.sendRequest")}
            </Button>
          </div>
        </div>
      </Modal>
      <ContactSuccessModal
        open={openSuccessModal}
        onClose={onCloseSuccessModal}
      />
    </>
  );
};

export default TrustCenterOnboardContactForm;
