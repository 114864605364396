// Libraries
import { useState, type Dispatch, type SetStateAction } from "react";

// Components
import LoadingState from "#src/components/common/system/LoadingState";

// API-related
import type { IProductPlan } from "#src/services/resource";
import paymentService, { type IPaymentForm } from "#src/services/payment";

// Children
import PaymentFillFormRecheckTable from "#src/components/payment/FillForm/RecheckTable";
import PaymentFillFormCustomerInfo from "#src/components/payment/FillForm/CustomerInfo";
import PaymentMethod from "./PaymentMethod";
import PaymentFillFormSummary from "#src/components/payment/FillForm/Summary";
import { expandPlanDuration } from "#src/utils/apiHelper";
import type { ContactFormSelection } from "../../onboard/ContactForm/contactDetailFields";
import ReselectPlanModal from "./ReselectPlanModal";

interface ITrustCenterRegisterDomainFillFormProps {
  productPlanInfo: IProductPlan | null;
  selectedPlan: IProductPlan["recurring_prices"][number] | null;
  pricingInfo: Awaited<
    ReturnType<typeof paymentService.calculate_pricing>
  > | null;
  paymentForm: IPaymentForm;
  setPaymentForm: Dispatch<SetStateAction<IPaymentForm>>;
  jobTitle: ContactFormSelection;
  setJobTitle: Dispatch<SetStateAction<ContactFormSelection>>;
  defaultPaymentSettings: Awaited<
    ReturnType<typeof paymentService.retrieve_workspace_payment_settings>
  > | null;
  onEditPlan: (value: IProductPlan["recurring_prices"][number]) => void;
  oldPlanDuration?: string;
  onApplyPromocode: (value: string) => Promise<boolean>;
  onRemovePromocode: (removeId: string) => void;
  errorObj: {
    full_name: string | undefined;
    phone_number: string | undefined;
    email: string | undefined;
  };
  setErrorObj: Dispatch<
    SetStateAction<{
      full_name: string | undefined;
      phone_number: string | undefined;
      email: string | undefined;
    }>
  >;
}

const TrustCenterRegisterDomainFillForm = ({
  productPlanInfo,
  selectedPlan,
  pricingInfo,
  paymentForm,
  setPaymentForm,
  jobTitle,
  setJobTitle,
  defaultPaymentSettings,
  onEditPlan,
  oldPlanDuration,
  onApplyPromocode,
  onRemovePromocode,
  errorObj,
  setErrorObj,
}: ITrustCenterRegisterDomainFillFormProps) => {
  const onChangeCustomer = (value: (typeof paymentForm)["customer"]) => {
    setPaymentForm((prev) => ({
      ...prev,
      customer: value,
    }));
  };

  const [openEditModal, setOpenEditModal] = useState<boolean>(false);
  const [tempSelectedPlan, setTempSelectedPlan] = useState<
    IProductPlan["recurring_prices"][number] | null
  >(null);

  const onChangeOpen = (value: boolean) => {
    if (value) {
      setTempSelectedPlan(selectedPlan);
    }
    setOpenEditModal(value);
  };

  const expandedSelectedPlan = selectedPlan
    ? expandPlanDuration(selectedPlan)
    : undefined;

  return expandedSelectedPlan === undefined ? (
    // Make typescript happy, it should never reach this place.
    <div>you must select a plan first</div>
  ) : (
    <div className="flex flex-col gap-10">
      {productPlanInfo ? (
        <PaymentFillFormRecheckTable
          products={[
            {
              id: productPlanInfo.id,
              service: productPlanInfo.product.name,
              product: productPlanInfo.product.category.name,
              plan: productPlanInfo.name,
              cycle: expandedSelectedPlan.getSpan,
              price: expandedSelectedPlan.vnd,
              onClickEdit: () => {
                onChangeOpen(true);
              },
              nextBillingTime: pricingInfo?.product_plans.find(
                (plan) => plan.id === productPlanInfo.id
              )?.next_billing_time,
            },
          ]}
        />
      ) : (
        <LoadingState />
      )}
      <div className="grid grid-cols-[3fr_2fr] gap-6">
        <div className="flex flex-col gap-6">
          <PaymentFillFormCustomerInfo
            customer={paymentForm.customer}
            onChangeCustomer={onChangeCustomer}
            jobTitle={jobTitle}
            setJobTitle={setJobTitle}
            errorObj={errorObj}
            setErrorObj={setErrorObj}
          />
          <PaymentMethod
            selectedPlan={expandedSelectedPlan}
            pricingInfo={pricingInfo}
            paymentForm={paymentForm}
            setPaymentForm={setPaymentForm}
            defaultPaymentSettings={defaultPaymentSettings}
          />
        </div>
        <PaymentFillFormSummary
          pricingInfo={pricingInfo}
          onApplyPromocode={onApplyPromocode}
          onRemovePromocode={onRemovePromocode}
        />
      </div>
      <ReselectPlanModal
        open={openEditModal}
        onChangeOpen={onChangeOpen}
        productPlanInfo={productPlanInfo}
        onSubmit={onEditPlan}
        tempSelectedPlan={tempSelectedPlan}
        setTempSelectedPlan={setTempSelectedPlan}
        oldPlanDuration={oldPlanDuration}
      />
    </div>
  );
};

export default TrustCenterRegisterDomainFillForm;
