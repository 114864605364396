// Libraries
import {
  type Dispatch,
  type SetStateAction,
  useEffect,
  useState,
  useMemo,
} from "react";
import { useTranslation } from "react-i18next";

// General
import constants from "#src/config/constants";
import type { IFilterItem } from "#src/@types/common";

// Components
import PaginationBar from "#src/components/common/helper/PaginationBar";
import LoadingState from "#src/components/common/system/LoadingState";
import NoResultsState from "#src/components/common/states/NoResultsState";
import useFetchPaginated from "#src/hooks/useFetchPaginated";
import { createToast } from "#src/components/common/system/toasts";
import { apiErrorHandler } from "#src/utils/apiErrorHandler";

// Children
import ScanFilter from "./Filter";
import ScanTableGrid from "./Table";
import scansServices from "#src/services/scans";
import assetsServices from "#src/services/assets";
import type { IScanScheduleItem } from "#src/services/scans/schedules";
import EditScheduleDrawer from "../EditScheduleDrawer";
import AddScanDrawer from "#src/components/scans/all-scans/AddScanDrawer";

interface IScheduleTableProps {
  workspaceId: string;
  openAddScanDrawer: boolean;
  setOpenAddScanDrawer: Dispatch<SetStateAction<boolean>>;
}

const ScheduleTable = ({
  workspaceId,
  openAddScanDrawer,
  setOpenAddScanDrawer,
}: IScheduleTableProps) => {
  const { t } = useTranslation("scanSchedules");

  // Domain list to support the filter's domain choices
  const [domainList, setDomainList] = useState<IFilterItem[]>([]);

  const [selectedPage, setSelectedPage] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(constants.DEFAULT_PAGE_SIZE);

  const [domains, setDomains] = useState<IFilterItem | null>(null);
  const [enableStatus, setEnableStatus] = useState<IFilterItem | null>(null);
  const [selectedSchedules, setSelectedSchedules] = useState<
    IScanScheduleItem[]
  >([]);
  const [searchKeyword, setSearchKeyword] = useState<string>("");

  const [openEditScheduleDrawer, setOpenEditScheduleDrawer] =
    useState<boolean>(false);
  const [editingSchedule, setEditingSchedule] =
    useState<IScanScheduleItem | null>(null);

  const fetchParams = useMemo<Parameters<typeof scansServices.list_schedules>>(
    () => [
      workspaceId,
      {
        domain_id: domains?.value,
        enabled: enableStatus?.value,
        q: searchKeyword,
        page: selectedPage,
        size: pageSize,
      },
    ],
    [workspaceId, domains, enableStatus, searchKeyword, selectedPage, pageSize]
  );

  const onChangeDomainsSelection = (selected: typeof domains) => {
    setDomains(selected);
    setSelectedPage(1);
  };

  const onChangeEnableStatusSelection = (selected: typeof enableStatus) => {
    setEnableStatus(selected);
    setSelectedPage(1);
  };

  const onChangeSearchKeyword = (keyword: string) => {
    setSearchKeyword(keyword);
    setSelectedPage(1);
  };

  const onChangePage = (selectedPage: number) => {
    setSelectedPage(selectedPage);
    setSelectedSchedules([]);
  };

  const onChangePageSize = (size: number) => {
    setPageSize(size);
    setSelectedSchedules([]);
    setSelectedPage(1);
  };

  const onChangeSelectedSchedules = (selected: IScanScheduleItem) => {
    if (selectedSchedules.map((item) => item.id).includes(selected.id)) {
      setSelectedSchedules((prev) => [
        ...prev.filter((item) => item.id !== selected.id),
      ]);
    } else {
      setSelectedSchedules((prev) => [...prev, selected]);
    }
  };

  const onChangeEditingSchedule = (selected: IScanScheduleItem) => {
    setEditingSchedule(selected);
    setOpenEditScheduleDrawer(true);
  };

  const onSelectAllSchedules = () => {
    if (scheduleList) {
      setSelectedSchedules(scheduleList);
    }
  };

  const onDeselectAllSchedules = () => {
    setSelectedSchedules([]);
  };

  const onDeleteSelectedSchedules = () => {
    scansServices
      .delete_multiple_schedules(
        workspaceId,
        selectedSchedules.map((schedule) => schedule.id)
      )
      .then((response) => {
        if (response.success) {
          updateScheduleList();
          setSelectedSchedules([]);
          createToast({
            type: "success",
            message: t("notification.deleteMultipleSchedules.success"),
          });
        }
      })
      .catch((error) => {
        apiErrorHandler(error, {
          toastMessage: t("notification.deleteMultipleSchedules.fail"),
        });
      });
  };

  const onEnableAllSchedules = () => {
    Promise.allSettled(
      selectedSchedules
        .filter((schedule) => !schedule.activated)
        .map((schedule) =>
          scansServices.update_schedule(workspaceId, schedule.id, {
            activated: true,
            activated_time: schedule.activated_time || schedule.created_time,
            schedule: schedule.schedule,
            timezone: schedule.timezone,
            days: schedule.days,
            duration_type: schedule.duration_type,
          })
        )
    )
      .then(() => {
        updateScheduleList();
        setSelectedSchedules([]);
        createToast({
          type: "success",
          message: t("notification.enableMultipleSchedules.success"),
        });
      })
      .catch((error) => {
        apiErrorHandler(error, {
          toastMessage: t("notification.enableMultipleSchedules.fail"),
        });
      });
  };

  const onDisableAllSchedules = () => {
    Promise.allSettled(
      selectedSchedules
        .filter((schedule) => schedule.activated)
        .map((schedule) =>
          scansServices.update_schedule(workspaceId, schedule.id, {
            activated: false,
            activated_time: schedule.activated_time || schedule.created_time,
            schedule: schedule.schedule,
            timezone: schedule.timezone,
            days: schedule.days,
            duration_type: schedule.duration_type,
          })
        )
    )
      .then(() => {
        updateScheduleList();
        setSelectedSchedules([]);
        createToast({
          type: "success",
          message: t("notification.disableMultipleSchedules.success"),
        });
      })
      .catch((error) => {
        apiErrorHandler(error, {
          toastMessage: t("notification.disableMultipleSchedules.fail"),
        });
      });
  };

  // Fetch domain item list for the first time
  useEffect(() => {
    let ignore = false;

    if (!ignore) {
      assetsServices
        .list_domains_shortly(workspaceId, {
          from: undefined,
          to: undefined,
          verification: 1,
          vulnerable: undefined,
          q: undefined,
        })
        .then((response) => {
          setDomainList([
            ...response.map((item) => ({
              key: item.id.toString(),
              value: item.id.toString(),
              getLabel: () => item.detail.address.toString(),
            })),
            {
              key: "all",
              value: undefined,
              getLabel: () => t("filter.domains.all"),
            },
          ]);
        })
        .catch((error) => {
          apiErrorHandler(error, {
            toastMessage: t("notification.loadDomainList.fail"),
          });
        });
    }

    return () => {
      ignore = true;
    };
  }, [workspaceId, t]);

  const {
    list: scheduleList,
    count: scheduleCount,
    isLoading,
    updateData: updateScheduleList,
  } = useFetchPaginated(scansServices.list_schedules, fetchParams);

  return (
    <>
      <ScanFilter
        domains={domains}
        enableStatus={enableStatus}
        searchKeyword={searchKeyword}
        totalCount={scheduleList ? scheduleList.length : 0}
        selectedCount={selectedSchedules.length}
        domainItems={domainList}
        onChangeDomainsSelection={onChangeDomainsSelection}
        onChangeEnableStatusSelection={onChangeEnableStatusSelection}
        onChangeSearchKeyword={onChangeSearchKeyword}
        onSelectAllSchedules={onSelectAllSchedules}
        onDeselectAllSchedules={onDeselectAllSchedules}
        onDeleteSelectedSchedules={onDeleteSelectedSchedules}
        onEnableSelectedSchedules={onEnableAllSchedules}
        onDisableSelectedSchedules={onDisableAllSchedules}
      />
      {isLoading || scheduleList === null ? (
        <LoadingState />
      ) : scheduleCount === 0 ? (
        <NoResultsState />
      ) : (
        <ScanTableGrid
          workspaceId={workspaceId}
          scheduleList={scheduleList}
          selectedScheduleIds={selectedSchedules.map((item) => item.id)}
          onChangeSelectedSchedules={onChangeSelectedSchedules}
          onChangeEditingSchedule={onChangeEditingSchedule}
          refreshScheduleList={updateScheduleList}
        />
      )}
      <PaginationBar
        numOfResult={scheduleCount}
        selectedPage={selectedPage}
        pageSize={pageSize}
        onChangePage={onChangePage}
        onChangePageSize={onChangePageSize}
      />
      <AddScanDrawer
        open={openAddScanDrawer}
        onClose={() => {
          setOpenAddScanDrawer(false);
        }}
        workspaceId={workspaceId}
        onRefresh={updateScheduleList}
      />
      {editingSchedule ? (
        <EditScheduleDrawer
          open={openEditScheduleDrawer}
          onClose={() => {
            setOpenEditScheduleDrawer(false);
            setEditingSchedule(null);
          }}
          workspaceId={workspaceId}
          onRefresh={updateScheduleList}
          selectedSchedule={editingSchedule}
        />
      ) : null}
    </>
  );
};

export default ScheduleTable;
