// Libraries
import dayjs from "dayjs";
import type { ItemType } from "@lockerpm/design/es/menu/hooks/useItems";
import { Dropdown } from "@lockerpm/design";

// Resources
import { ReactComponent as MoreLine } from "#src/assets/images/icons/more-line.svg";

// General
import urls from "#src/config/urls";

// Components
import { ObjectImage } from "#src/components/common/system/Object";
import { TableCheckbox, TableDataCell } from "#src/components/common/Table";
import { TableRowWrapperAnchor } from "#src/components/common/Table/TableRowWrapper";
import { RoundedSeeMoreTags, RoundedTag } from "#src/components/common/Tags";
import { IconButton } from "#src/components/common/system/Button";

// API-related
import type { IAssetSubdomainItem } from "#src/services/assets/subdomains";

interface IAssetsSubdomainsTableRowProps {
  data: IAssetSubdomainItem;
  actionList: ItemType[];
  onClickActionItems: (key: string, item: IAssetSubdomainItem) => void;
  selectedSubdomainIds: number[];
  onChangeSelectedSubdomains: (selected: IAssetSubdomainItem) => void;
  refreshTabState: () => void;
}

/** Row template for Assets/Details Subdomain tab table view, since this depends on each table and cannot be built into a common template
 * @param data - data of the row, represent a subdomain from selected domain
 * @param actionList - list of actions that shows when the dropdown at the rightmost of the row is opened
 * @param onClickActionItems - a function to process actions from actionList
 * @param selectedSubdomainIds - a list of ids of items that has its checkbox checked
 * @param onChangeSelectedSubdomains - control the selectedSubdomainId list
 * @param refreshTabState - since subdomains don't have sub-of-sub domains, so when we navigate to a subdomain (clicking a row), we'll have to refresh to "overview" tab
 */
const SubdomainTableRow = ({
  data,
  actionList,
  onClickActionItems,
  selectedSubdomainIds,
  onChangeSelectedSubdomains,
  refreshTabState,
}: IAssetsSubdomainsTableRowProps) => {
  return (
    <TableRowWrapperAnchor
      to={`./../${data.id}`}
      onClick={() => {
        refreshTabState();
      }}
    >
      <TableDataCell className="p-0 justify-center">
        <TableCheckbox
          checked={selectedSubdomainIds.includes(data.id)}
          onClick={() => {
            onChangeSelectedSubdomains(data);
          }}
        />
      </TableDataCell>
      <TableDataCell>
        <ObjectImage
          data={
            urls.IMAGE_LOGO_SERVER_URL.replace(":address", data.address) +
            "?size=36&v=1"
          }
          className="h-6 w-6 min-w-[1.5rem]"
        />
        {data.address}
      </TableDataCell>
      <TableDataCell>{data.scans}</TableDataCell>
      <TableDataCell>{data.vulnerabilities}</TableDataCell>
      <TableDataCell>
        {data.last_found
          ? dayjs.unix(data.last_found).format("HH:mm DD.MM.YYYY")
          : "---"}
      </TableDataCell>
      <TableDataCell>
        {data.tags.length > 0 ? <RoundedTag name={data.tags[0].name} /> : null}
        {data.tags.length > 1 ? (
          <RoundedSeeMoreTags tagList={data.tags.slice(1)} />
        ) : null}
      </TableDataCell>
      <TableDataCell className="p-0 justify-center">
        <Dropdown
          menu={{
            items: actionList,
            onClick: ({ key, domEvent }) => {
              domEvent.preventDefault();
              domEvent.stopPropagation();
              onClickActionItems(key, data);
            },
          }}
          trigger={["click"]}
        >
          <IconButton
            variant="ghost"
            size={9}
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
            }}
          >
            <MoreLine />
          </IconButton>
        </Dropdown>
      </TableDataCell>
    </TableRowWrapperAnchor>
  );
};

export default SubdomainTableRow;
