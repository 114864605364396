// Libraries
import { useTranslation } from "react-i18next";

// Resources
import { ReactComponent as NoVulnerabilitiesIllustration } from "#src/assets/images/illustrations/no-vulnerabilities.svg";

/** @deprecated Please use EmptyState. */
const NoVulnerabilitiesState = () => {
  const { t } = useTranslation("common", {
    keyPrefix: "state.noVulnerabilities",
  });
  return (
    <div className="flex justify-center items-center py-12">
      <div className="flex flex-col items-center">
        <NoVulnerabilitiesIllustration />
        <h4>{t("noVulnerabilitiesFound")}</h4>
      </div>
    </div>
  );
};

export default NoVulnerabilitiesState;
