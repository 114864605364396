import { Tooltip } from "@lockerpm/design";
import { type HTMLAttributes, useRef, useState } from "react";
import { twMerge } from "tailwind-merge";

interface IOverflowEllipsisProps extends HTMLAttributes<HTMLSpanElement> {}

/** If this tag is overflowed, Tooltip will be shown */
export const OverflowEllipsis = (props: IOverflowEllipsisProps) => {
  const spanRef = useRef<HTMLSpanElement>(null);
  const [openTooltip, setOpenTooltip] = useState<boolean>(false);

  const overflowed =
    spanRef.current &&
    (spanRef.current.offsetHeight < spanRef.current.scrollHeight ||
      spanRef.current.offsetWidth < spanRef.current.scrollWidth);

  return (
    <Tooltip
      title={props.children}
      placement="top"
      open={openTooltip}
      onOpenChange={(open) => {
        setOpenTooltip(overflowed ? open : false);
      }}
    >
      <span
        ref={spanRef}
        {...props}
        className={twMerge(
          "overflow-hidden whitespace-nowrap text-ellipsis",
          props.className
        )}
      ></span>
    </Tooltip>
  );
};
