// Libraries
import { useTranslation } from "react-i18next";

// Resources
import { ReactComponent as GitRepositoryLine } from "#src/assets/images/icons/git-repository-line.svg";

// Components
import { ContentSection, StickySection } from "#src/layouts/content";

// Children
import PricingPlan from "#src/components/workspace/billing/upgrade/trustCenter/PricingPlan";

const WorkspaceBillingUpgradeTrustCenter = () => {
  const { t } = useTranslation("workspace", {
    keyPrefix: "billing.upgrade.trustCenter",
  });

  return (
    <>
      <StickySection className="py-10 items-center max-w-[96rem]">
        <div className="flex flex-col gap-3">
          <h1>{t("title")}</h1>
          <p className="font-regular-18-body text-hard-grey">
            {t("description")}
          </p>
          <div className="flex gap-2">
            <GitRepositoryLine
              width="1.25rem"
              height="1.25rem"
              className="fill-primary"
            />
            <a>{t("link.learnMore")}</a>
          </div>
        </div>
      </StickySection>
      <ContentSection className="max-w-[96rem]">
        <PricingPlan />
      </ContentSection>
    </>
  );
};

export default WorkspaceBillingUpgradeTrustCenter;
