import { createSlice, type PayloadAction } from "@reduxjs/toolkit";
import type { TrustCenterPlanValue } from "#src/config/filter/trustCenter/value";

interface ITrustCenterState {
  plan: TrustCenterPlanValue | null | undefined;
}

const initialState: ITrustCenterState = { plan: undefined };

const trustCenterSlice = createSlice({
  name: "trustCenter",
  initialState,
  reducers: {
    updatePlan: (state, action: PayloadAction<typeof initialState.plan>) => ({
      ...state,
      plan: action.payload,
    }),
  },
});

export const { updatePlan } = trustCenterSlice.actions;

export const trustCenterReducer = trustCenterSlice.reducer;
