// Libraries
import dayjs from "dayjs";
import { useTranslation } from "react-i18next";

// Components
import { TableDataCell, TableHeader } from "#src/components/common/Table";
import NoResultsState from "#src/components/common/states/NoResultsState";
import { TableRowWrapperDiv } from "#src/components/common/Table/TableRowWrapper";
import type { ITableHeaderPropItem } from "#src/components/common/Table/TableHeader";

// API-related
import type { IAssetPortItem } from "#src/services/assets/ports";

/** Row template for Assets/Details Port tab table view, since this depends on each table and cannot be built into a common template
 * @param data - data of the row, represent a port from selected asset
 */
const PortTableRow = ({ data }: { data: IAssetPortItem }) => {
  return (
    <TableRowWrapperDiv>
      <TableDataCell>{data.port ? data.port : "--"}</TableDataCell>
      <TableDataCell>{data.protocol.toUpperCase()}</TableDataCell>
      <TableDataCell>{data.service.toUpperCase()}</TableDataCell>
      <TableDataCell>{data.product}</TableDataCell>
      <TableDataCell>{data.version}</TableDataCell>
      <TableDataCell>
        <div style={{ wordBreak: "break-word" }}>{data.cpe}</div>
      </TableDataCell>
      <TableDataCell>
        {dayjs.unix(data.last_found).format("HH:mm DD.MM.YYYY")}
      </TableDataCell>
    </TableRowWrapperDiv>
  );
};

interface IPortTableProps {
  ports: IAssetPortItem[];
}

const PortTableGrid = ({ ports }: IPortTableProps) => {
  const { t } = useTranslation("assets", {
    keyPrefix: "domains.page.details.tab.ports",
  });
  /** Asset Detail Port Table's header list */
  const headers: ITableHeaderPropItem[] = [
    { name: t("headers.port"), align: "left" },
    { name: t("headers.protocol"), align: "left" },
    { name: t("headers.service"), align: "left" },
    { name: t("headers.product"), align: "left" },
    { name: t("headers.version"), align: "left" },
    { name: t("headers.cpe"), align: "left" },
    { name: t("headers.createdDate"), align: "left" },
  ];

  return ports.length === 0 ? (
    <NoResultsState />
  ) : (
    <div className="grid grid-cols-[1fr_1fr_1fr_1fr_2fr_3fr_2fr]">
      <TableHeader title="assets/details-ports" headerProps={headers} />
      {ports.map((item) => (
        <PortTableRow
          key={`tassets/details-ports-tr${item.port}`}
          data={item}
        />
      ))}
    </div>
  );
};

export default PortTableGrid;
