// Libraries
import { useMemo, useState } from "react";

// General
import type { IFilterItem } from "#src/@types/common";

// Components
import useFetchNoPaging from "#src/hooks/useFetchNoPaging";

// API-related
import assetsServices, { type IAssetDetailItem } from "#src/services/assets";

// Children
import PortFilter from "./Filter";
import PortTableGrid from "./Table";

interface IDetailsPortsTabProps {
  portDetailsInit: IAssetDetailItem["ports"];
  workspaceId: string;
  assetId: number;
}

const AssetsDetailsPorts = ({
  portDetailsInit,
  workspaceId,
  assetId,
}: IDetailsPortsTabProps) => {
  // const [selectedPage, setSelectedPage] = useState<number>(1);
  // const [pageSize, setPageSize] = useState<number>(constants.DEFAULT_PAGE_SIZE);

  const [protocol, setProtocol] = useState<IFilterItem | null>(null);
  const [searchKeyword, setSearchKeyword] = useState<string>("");
  const [from, setFrom] = useState<number | null>(null);
  const [to, setTo] = useState<number | null>(null);

  const fetchParams = useMemo<
    Parameters<typeof assetsServices.list_asset_ports>
  >(
    () => [
      workspaceId,
      assetId,
      {
        from: from ? from : undefined,
        to: to ? to : undefined,
        protocol: protocol?.value,
        q: searchKeyword,
        page: undefined,
        size: undefined,
      },
    ],
    [workspaceId, assetId, from, to, protocol, searchKeyword]
  );

  const onChangeTimeConditionValue = (
    condition: string,
    from: number | null,
    to: number | null
  ) => {
    if (condition) {
      setFrom(from);
      setTo(to);
    } else {
      setFrom(null);
      setTo(null);
    }
    // setSelectedPage(1);
  };

  const onChangeProtocolSelection = (selected: typeof protocol) => {
    setProtocol(selected);
    // setSelectedPage(1);
  };

  const onChangeSearchKeyword = (keyword: string) => {
    setSearchKeyword(keyword);
    // setSelectedPage(1);
  };

  // const onChangePage = (page: number) => {
  //   setSelectedPage(page);
  // };

  // const onChangePageSize = (size: number) => {
  //   setPageSize(size);
  //   setSelectedPage(1);
  // };

  const { list: portList } = useFetchNoPaging(
    assetsServices.list_asset_ports,
    fetchParams
  );

  return (
    <section className="flex flex-col gap-6 pt-6">
      <PortFilter
        searchKeyword={searchKeyword}
        protocol={protocol}
        onChangeTimeConditionValue={onChangeTimeConditionValue}
        onChangeProtocolSelection={onChangeProtocolSelection}
        onChangeSearchKeyword={onChangeSearchKeyword}
      />
      <PortTableGrid
        ports={
          portList?.length
            ? portList
            : portDetailsInit.list.map((p) => ({ ...p, cpe: "" }))
        }
      />
      {/* <PaginationBar
        numOfResult={portDetailsInit.count}
        selectedPage={selectedPage}
        pageSize={pageSize}
        onChangePage={onChangePage}
        onChangePageSize={onChangePageSize}
      /> */}
    </section>
  );
};

export default AssetsDetailsPorts;
