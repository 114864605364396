export enum AssetVulnerableStatusEnum {
  YES = "VULNERABLE_ENUM_YES",
  NO = "VULNERABLE_ENUM_NO",
  ALL = "VULNERABLE_ENUM_ALL",
}

export enum VerifiedOwnershipEnum {
  YES = "VERIFIED_OWNERSHIP_ENUM_YES",
  NO = "VERIFIED_OWNERSHIP_ENUM_NO",
  ALL = "VERIFIED_OWNERSHIP_ENUM_ALL",
}

export enum ProtocolEnum {
  TCP = "PROTOCOL_ENUM_TCP",
  UDP = "PROTOCOL_ENUM_UDP",
  ALL = "PROTOCOL_ENUM_ALL",
}

export enum Expiration {
  EXPIRED_IN_90_DAYS = 'in_90_days',
  EXPIRED_IN_60_DAYS = 'in_60_days',
  EXPIRED_IN_30_DAYS = 'in_30_days',
  EXPIRED = 'expired',
  EXPIRE_MORE_90_DAYS = 'more_90_days',
}
