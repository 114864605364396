export const BugBountyManagementModeEnum = {
  BY_PROVIDER: "BUG_BOUNTY_MANAGEMENT_ENUM_BY_PROVIDER",
  SELF_MANAGED: "BUG_BOUNTY_MANAGEMENT_ENUM_SELF_MANAGED",
};

export const BugBountyProgramTypeEnum = {
  PUBLIC: "BUG_BOUNTY_PROGRAM_TYPE_ENUM_PUBLIC",
  SEMI_PRIVATE: "BUG_BOUNTY_PROGRAM_TYPE_ENUM_SEMI_PRIVATE",
  PRIVATE: "BUG_BOUNTY_PROGRAM_TYPE_ENUM_PRIVATE",
};

export const BugBountyProgramTypeValueEnum = {
  [BugBountyProgramTypeEnum.PUBLIC]: "public",
  [BugBountyProgramTypeEnum.SEMI_PRIVATE]: "semi_private",
  [BugBountyProgramTypeEnum.PRIVATE]: "private",
};

export const BugBountyDetailsTabEnum = {
  PROGRAM_BRIEF: "BUG_BOUNTY_DETAILS_TAB_ENUM_PROGRAM_BRIEF",
  SCOPE: "BUG_BOUNTY_DETAILS_TAB_ENUM_SCOPE",
  POLICY: "BUG_BOUNTY_DETAILS_TAB_ENUM_POLICY",
  REQUIREMENTS: "BUG_BOUNTY_DETAILS_TAB_ENUM_REQUIREMENTS",
  MSSP: "BUG_BOUNTY_DETAILS_TAB_ENUM_MSSP",
  MEMBERS: "BUG_BOUNTY_DETAILS_TAB_ENUM_MEMBERS",
  ACCESS_REQUESTS: "BUG_BOUNTY_DETAILS_TAB_ENUM_ACCESS_REQUESTS",
  INTEGRATIONS: "BUG_BOUNTY_DETAILS_TAB_ENUM_INTEGRATIONS",
  BLOCKING: "BUG_BOUNTY_DETAILS_TAB_ENUM_BLOCKING",
  ORGANIZATION: "BUG_BOUNTY_DETAILS_TAB_ENUM_ORGANIZATION",
};

export const ProgramMemberActionEnum = {
  AUTO_ASSIGN_SUBMISSION: "PROGRAM_MEMBER_ACTION_ENUM_AUTO_ASSIGN_SUBMISSION",
  DELETE_MEMBER: "PROGRAM_MEMBER_ACTION_ENUM_DELETE_MEMBER",
};
