// Libraries
import { useTranslation } from "react-i18next";

// Resources
import { ReactComponent as LogoWindows10 } from "#src/assets/images/logos/logo-windows-10.svg";
import { ReactComponent as LogoApple } from "#src/assets/images/logos/logo-apple.svg";
import { ReactComponent as LogoUbuntu } from "#src/assets/images/logos/logo-ubuntu.svg";

// Components
import OverviewMainCard from "#src/components/common/OverviewMainCard";
import OverviewSubCard from "#src/components/common/OverviewSubCard";

// API-related
import type { IDeviceHostStatistics } from "#src/services/devices";
import { PolicyComplianceValue } from "#src/config/filter/device/value";

const HostStatistics = ({
  statistics,
}: {
  statistics: IDeviceHostStatistics | null;
}) => {
  const { t } = useTranslation("devices", {
    keyPrefix: "hosts.page.hosts.statistics",
  });

  return (
    <div className="flex gap-6 w-full">
      <div className="flex gap-6 flex-[3]">
        <OverviewMainCard
          label={t("totalHosts")}
          value={statistics?.total}
          variant={"blue"}
        />
        <OverviewSubCard
          items={[
            {
              label: (
                <span className="flex items-center gap-2">
                  <span className="w-2.5 h-2.5 bg-warning-safe rounded-full" />
                  {t("hostStatus.online")}
                </span>
              ),
              value: statistics
                ? statistics.status.find((item) => item.status === "online")
                    ?.count || 0
                : undefined,
            },
            {
              label: (
                <span className="flex items-center gap-2">
                  <span className="w-2.5 h-2.5 bg-warning rounded-full" />
                  {t("hostStatus.offline")}
                </span>
              ),
              value: statistics
                ? statistics.status.find((item) => item.status === "offline")
                    ?.count || 0
                : undefined,
            },
          ]}
        />
        <OverviewSubCard
          items={[
            {
              label: (
                <span className="text-support-seafoam">
                  {t("policyCompliance.passed")}
                </span>
              ),
              value: statistics
                ? statistics.policy_compliance.find(
                    (item) =>
                      item.policy_compliance === PolicyComplianceValue.PASSED
                  )?.count || 0
                : undefined,
            },
            {
              label: (
                <span className="text-warning-red-danger">
                  {t("policyCompliance.failed")}
                </span>
              ),
              value: statistics
                ? statistics.policy_compliance.find(
                    (item) =>
                      item.policy_compliance === PolicyComplianceValue.FAILED
                  )?.count || 0
                : undefined,
            },
          ]}
        />
      </div>
      <div className="flex-1 flex flex-col p-6 gap-x-10 gap-y-3 border border-grey rounded-md whitespace-pre-line">
        <div className="flex justify-between items-center gap-4 h-7">
          <span className="font-medium-20">{t("operatingSystem")}</span>
          <span className="font-bold-24 tabular-nums">
            {statistics
              ? statistics.os.reduce((prev, cur) => prev + cur.count, 0)
              : null}
          </span>
        </div>
        <div className="flex gap-3">
          <div className="flex justify-between items-center gap-2 h-7">
            <LogoWindows10 />
            <span className="font-medium-16 text-hard-grey">
              {statistics
                ? statistics.os.find((item) =>
                    item.os.toLowerCase().includes("windows")
                  )?.count || 0
                : null}
            </span>
          </div>
          <div className="flex justify-between items-center gap-2 h-7">
            <LogoApple />
            <span className="font-medium-16 text-hard-grey">
              {statistics
                ? statistics.os.find((item) =>
                    item.os.toLowerCase().includes("darwin")
                  )?.count || 0
                : null}
            </span>
          </div>
          <div className="flex justify-between items-center gap-2 h-7">
            <LogoUbuntu />
            <span className="font-medium-16 text-hard-grey">
              {statistics
                ? statistics.os.find((item) =>
                    item.os.toLowerCase().includes("ubuntu")
                  )?.count || 0
                : null}
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HostStatistics;
