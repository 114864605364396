import {
  type AnchorHTMLAttributes,
  forwardRef,
  type ForwardedRef,
  type HTMLAttributes,
} from "react";
import { Link, LinkProps, type To } from "react-router-dom";
import { twMerge } from "tailwind-merge";

interface ITableRowWrapperProps
  // TODO: make this type more legit
  extends HTMLAttributes<HTMLDivElement | HTMLAnchorElement> {
  to?: To;
}

const tailwindString =
  "grid grid-cols-subgrid col-span-full relative bg-white text-current hover:bg-bright-blue hover:text-current transition duration-150 ease-out group no-underline";

/** @deprecated Should specific whether you want a div or a link for this wrapper.
 * TODO: change these into the new ones.
 */
const TableRowWrapper = forwardRef<
  HTMLDivElement | HTMLAnchorElement,
  ITableRowWrapperProps
>(({ to, ...props }, ref) => {
  return to ? (
    <Link
      to={to}
      ref={ref as ForwardedRef<HTMLAnchorElement>}
      {...props}
      className={twMerge(tailwindString, props.className)}
    />
  ) : (
    <div
      ref={ref as ForwardedRef<HTMLDivElement>}
      {...props}
      className={twMerge(tailwindString, props.className)}
    />
  );
});

TableRowWrapper.displayName = "TableRowWrapper";

/** Table row wrapper, when we don't need to support the click linking to other things. */
export const TableRowWrapperDiv = forwardRef<
  HTMLDivElement,
  HTMLAttributes<HTMLDivElement>
>((props, ref) => {
  return (
    <div
      ref={ref as ForwardedRef<HTMLDivElement>}
      {...props}
      className={twMerge(tailwindString, props.className)}
    />
  );
});

TableRowWrapperDiv.displayName = "TableRowWrapperAnchor";

/** Table row wrapper with a link. */
export const TableRowWrapperAnchor = forwardRef<
  HTMLAnchorElement,
  LinkProps & AnchorHTMLAttributes<HTMLAnchorElement>
>((props, ref) => {
  return (
    <Link
      ref={ref as ForwardedRef<HTMLAnchorElement>}
      {...props}
      className={twMerge(tailwindString, props.className)}
    />
  );
});

TableRowWrapperAnchor.displayName = "TableRowWrapperAnchor";

export default TableRowWrapper;
