import request from "../utils/request";
import endpoint from "../config/endpoint";
import type { IResourceVulnCategory } from "./vulnerabilities";
import type { IPolicyInfoItem } from "./devices/policies";
import type { IPaginateParams, IPaginatedResponse } from "#src/@types/api";
import type { MultilingualText } from "#src/@types/common";

interface ICountry {
  country_code: string;
  country_name?: string;
  country_phone_code: string;
  nationality?: string;
}
function list_countries() {
  return request.get<never, ICountry[]>(`${endpoint.BASE}/resources/countries`);
}

interface IResourceVulnCategoryResponse {
  category: IResourceVulnCategory[];
}

function list_vuln_variants() {
  return request.get<never, IResourceVulnCategoryResponse>(
    `${endpoint.RESOURCES}/vulns`
  );
}

function list_endpoint_policies(
  params: {
    exclude_enabled_workspace_id: string | undefined;
    platform: string | undefined;
  } & IPaginateParams
) {
  return request.get<never, IPaginatedResponse<IPolicyInfoItem>>(
    `${endpoint.BASE}/resources/policies`,
    {
      params,
    }
  );
}

interface IMsspItem {
  id: string;
  name: string;
  created_time: number;
  description: MultilingualText;
  logo: string | null;
  color: string;
}

function list_available_mssp() {
  return request.get<never, IMsspItem[]>(`${endpoint.BASE}/resources/mssp`);
}

export interface IIntegrationItem {
  name: string;
  alias: string;
  description: MultilingualText;
  logo: string;
}

function list_integrations() {
  return request.get<never, IIntegrationItem[]>(
    `${endpoint.RESOURCES}/integrations`
  );
}

export interface ITrustCenterSupportedCompliance {
  id: string;
  name: string;
  logo: string;
}

function list_trust_center_supported_compliance() {
  return request.get<never, ITrustCenterSupportedCompliance[]>(
    `${endpoint.BASE}/resources/compliance`
  );
}

export interface ITrustCenterControlPolicy {
  id: string;
  domain_id: string;
  name: MultilingualText;
}

function list_trust_center_control_policies() {
  return request.get<never, ITrustCenterControlPolicy[]>(
    `${endpoint.BASE}/resources/trust_center/policies`
  );
}

export interface ITrustCenterControlDomain {
  id: string;
  name: MultilingualText;
}

function list_trust_center_control_domains() {
  return request.get<never, ITrustCenterControlDomain[]>(
    `${endpoint.BASE}/resources/trust_center/domains`
  );
}

export interface IThirdPartyService {
  id: number;
  name: string;
  logo: string;
  url: string;
  category: MultilingualText | null;
}

function list_third_party_services(params: IPaginateParams) {
  return request.get<never, IPaginatedResponse<IThirdPartyService>>(
    `${endpoint.BASE}/resources/third_party/service`,
    { params }
  );
}

export interface IProductPlan {
  id: string;
  name: MultilingualText;
  recurring_prices: {
    duration: string;
    vnd: number;
    usd: number;
    monthly_vnd: number;
    monthly_usd: number;
  }[];
  product: {
    id: string;
    name: MultilingualText;
    description: MultilingualText;
    category: {
      id: string;
      name: MultilingualText;
    };
  };
}

function list_product_plans({ productId }: { productId: string }) {
  return request.get<never, IProductPlan[]>(
    `${endpoint.BASE}/resources/products/${productId}/plans`
  );
}

const resourceServices = {
  list_countries,
  list_vuln_variants,
  list_endpoint_policies,
  list_available_mssp,
  list_integrations,
  list_trust_center_supported_compliance,
  list_trust_center_control_policies,
  list_trust_center_control_domains,
  list_third_party_services,
  list_product_plans,
};

export default resourceServices;
