export enum SeverityEnum {
  CRITICAL = "SEVERITY_ENUM_CRITICAL",
  HIGH = "SEVERITY_ENUM_HIGH",
  MEDIUM = "SEVERITY_ENUM_MEDIUM",
  LOW = "SEVERITY_ENUM_LOW",
  INFORMATION = "SEVERITY_ENUM_INFORMATION",
  NONE = "SEVERITY_ENUM_NONE",
  ALL = "SEVERITY_ENUM_ALL",
}

export enum VulnerabilityStatusEnum {
  OPEN = "VULNERABILITY_STATUS_ENUM_OPEN",
  ACCEPTED = "VULNERABILITY_STATUS_ENUM_ACCEPTED",
  REJECTED = "VULNERABILITY_STATUS_ENUM_REJECTED",
  NEW = "VULNERABILITY_STATUS_ENUM_NEW",
  TRIAGED = "VULNERABILITY_STATUS_ENUM_TRIAGED",
  RESOLVED = "VULNERABILITY_STATUS_ENUM_RESOLVED",
  UNRESOLVED = "VULNERABILITY_STATUS_ENUM_UNRESOLVED",
  DUPLICATE = "VULNERABILITY_STATUS_ENUM_DUPLICATE",
  OUT_OF_SCOPE = "VULNERABILITY_STATUS_ENUM_OUT_OF_SCOPE",
  NOT_REPRODUCIBLE = "VULNERABILITY_STATUS_ENUM_NOT_REPRODUCIBLE",
  WONT_FIX = "VULNERABILITY_STATUS_ENUM_WONT_FIX",
  NOT_APPLICABLE = "VULNERABILITY_STATUS_ENUM_NOT_APPLICABLE",
  SPAM = "VULNERABILITY_STATUS_ENUM_SPAM",
}

export enum cvssScoreEnum {
  "0.0" = "CVSS_ENUM_0.0",
  "0.1-3.9" = "CVSS_ENUM_0.1-3.9",
  "4.0-6.9" = "CVSS_ENUM_4.0-6.9",
  "7.0-8.9" = "CVSS_ENUM_7.0-8.9",
  "9.0-10.0" = "CVSS_ENUM_9.0-10.0",
  ALL = "CVSS_ENUM_ALL",
}
