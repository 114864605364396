import type { ReactNode } from "react";
import { twMerge } from "tailwind-merge";

/** Label for statuses. Usually included an icon and a title. Color string should have a pair of background color and stroke color.
 * @param name
 * @param color - this should be a valid className, preferably Tailwind's color-ralated
 * @param icon - a React component, preferably an SVG Element
 */
const StatusLabel = ({
  name,
  color,
  icon,
}: {
  name: string;
  color: string;
  icon: ReactNode;
}) => {
  return (
    <span
      className={twMerge(
        "px-4 py-2 rounded-md font-medium-14 flex items-center gap-2 bg-button-disabled text-hard-grey",
        color
      )}
    >
      {icon}
      {name}
    </span>
  );
};

export default StatusLabel;
