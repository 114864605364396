// Libraries
import { useTranslation } from "react-i18next";
import { Dropdown } from "@lockerpm/design";

// Resources
import { ReactComponent as AddLine } from "#src/assets/images/icons/add-line.svg";
import { ReactComponent as EditLine } from "#src/assets/images/icons/edit-line.svg";
import { ReactComponent as CloseLine } from "#src/assets/images/icons/close-line.svg";
import { ReactComponent as CheckLine } from "#src/assets/images/icons/check-line.svg";
import { ReactComponent as ArrowDownSLine } from "#src/assets/images/icons/arrow-down-s-line.svg";
import { ReactComponent as InformationLine } from "#src/assets/images/icons/information-line.svg";

// General
import type { IFilterItem } from "#src/@types/common";

// Components
import { Button, IconButton } from "#src/components/common/system/Button";
import { Input } from "#src/components/common/system/Input";
import DropdownItemLabel from "#src/components/common/helper/antdProps/Dropdown/DropdownItemLabel";
import DropdownTriggerButton from "#src/components/common/helper/antdProps/Dropdown/DropdownTriggerButton";

// Children
import {
  pentestScopeScopeItems,
  pentestScopeTypeItems,
} from "#src/config/filter/pentest";

export interface IPentestScopeInputItem {
  id: string;
  assetType: IFilterItem;
  assets: string;
  scope: IFilterItem;
  editing: boolean;
}

interface IAddPentestScopeProps {
  scope: IPentestScopeInputItem[];
  invalidAssets: boolean;
  activateEditScope: (id: number) => void;
  deactivateEditScope: (id: number) => void;
  removeScope: (id: number) => void;
  addScope: () => void;
  onChangeScopeAssetType: (id: number, key: string) => void;
  onChangeScopeAssets: (id: number, value: string) => void;
  onChangeScopeScope: (id: number, key: string) => void;
}

const AddPentestScope = ({
  scope,
  invalidAssets,
  activateEditScope,
  deactivateEditScope,
  removeScope,
  addScope,
  onChangeScopeAssetType,
  onChangeScopeAssets,
  onChangeScopeScope,
}: IAddPentestScopeProps) => {
  const { t } = useTranslation("pentest", {
    keyPrefix: "page.addPentest.scope",
  });

  return (
    <div className="flex flex-col gap-6">
      <div className="flex flex-col gap-4">
        <h2>{t("title")}</h2>
        <span className="text-hard-grey">{t("instruction")}</span>
      </div>
      <div className="grid grid-cols-[6rem_1fr_1fr_1fr_8rem] gap-y-6">
        <div className="grid grid-cols-subgrid col-span-full gap-x-6 bg-bright-grey text-hard-grey py-5 px-9">
          <div>#</div>
          <div>{t("header.assetType")}</div>
          <div>{t("header.assets")}</div>
          <div>{t("header.scope")}</div>
          <div></div>
        </div>
        {scope.map((scopeItem, scopeItemId) => (
          <div
            key={`scope-${scopeItemId}`}
            className="grid grid-cols-subgrid col-span-full gap-x-6 px-9 h-11"
          >
            <div className="flex items-center">{scopeItemId + 1}</div>
            <div className="flex items-center">
              {scopeItem.editing ? (
                <Dropdown
                  menu={{
                    items: pentestScopeTypeItems.map((item) => ({
                      key: item.key,
                      label: (
                        <DropdownItemLabel
                          syncWidthId="pentest-scope-type-dropdown"
                          selected={scopeItem.assetType.key === item.key}
                        >
                          {item.getLabel()}
                        </DropdownItemLabel>
                      ),
                    })),
                    onClick: ({ key }) => {
                      onChangeScopeAssetType(scopeItemId, key);
                    },
                  }}
                  trigger={["click"]}
                >
                  <DropdownTriggerButton
                    id="pentest-scope-type-dropdown"
                    className="w-full"
                  >
                    {scopeItem.assetType.getLabel()}
                    <ArrowDownSLine />
                  </DropdownTriggerButton>
                </Dropdown>
              ) : (
                scopeItem.assetType.getLabel()
              )}
            </div>
            <div className="flex items-center">
              {scopeItem.editing ? (
                <Input
                  value={scopeItem.assets}
                  onChange={(e) => {
                    onChangeScopeAssets(scopeItemId, e.currentTarget.value);
                  }}
                />
              ) : (
                scopeItem.assets
              )}
            </div>
            <div className="flex items-center">
              {scopeItem.editing ? (
                <Dropdown
                  menu={{
                    items: pentestScopeScopeItems.map((item) => ({
                      key: item.key,
                      label: (
                        <DropdownItemLabel
                          syncWidthId="pentest-scope-scope-dropdown"
                          selected={scopeItem.scope.key === item.key}
                        >
                          {item.getLabel()}
                        </DropdownItemLabel>
                      ),
                    })),
                    onClick: ({ key }) => {
                      onChangeScopeScope(scopeItemId, key);
                    },
                  }}
                  trigger={["click"]}
                >
                  <DropdownTriggerButton
                    id="pentest-scope-scope-dropdown"
                    className="w-full"
                  >
                    {scopeItem.scope.getLabel()}
                    <ArrowDownSLine />
                  </DropdownTriggerButton>
                </Dropdown>
              ) : (
                scopeItem.scope.getLabel()
              )}
            </div>
            <div className="flex items-center gap-1">
              {scopeItem.editing ? (
                <IconButton
                  variant={
                    !scopeItem.assets && invalidAssets ? "warning" : "secondary"
                  }
                  size={9}
                  onClick={() => {
                    deactivateEditScope(scopeItemId);
                  }}
                >
                  <CheckLine className="h-5 w-5" />
                </IconButton>
              ) : (
                <IconButton
                  variant="secondary"
                  size={9}
                  onClick={() => {
                    activateEditScope(scopeItemId);
                  }}
                >
                  <EditLine className="h-5 w-5" />
                </IconButton>
              )}
              <IconButton
                variant="warning"
                size={9}
                onClick={() => {
                  removeScope(scopeItemId);
                }}
              >
                <CloseLine className="h-5 w-5" />
              </IconButton>
            </div>
          </div>
        ))}
      </div>
      {scope.some((s) => !s.assets) && invalidAssets ? (
        <div className="text-warning font-regular-14 flex items-center gap-1">
          <InformationLine className="h-5 w-5" />
          {"Invalid asset name"}
        </div>
      ) : null}
      <div className="flex justify-center">
        <Button variant="text" onClick={addScope}>
          <AddLine />
          {t("button.addScope")}
        </Button>
      </div>
    </div>
  );
};

export default AddPentestScope;
