import { forwardRef, type ObjectHTMLAttributes } from "react";
import { twMerge } from "tailwind-merge";
import { ReactComponent as ImageCircleFill } from "#src/assets/images/icons/image-circle-fill.svg";

interface IObjectImageProps
  extends Omit<ObjectHTMLAttributes<HTMLObjectElement>, "data"> {
  // Accepts null data, since both null & undefined will throw into fallback (children nodes)
  data?: HTMLObjectElement["data"] | null;
}

/** Extends a HTML Object tag since it can display multiple types of media with fallback. Named it ObjectImage to avoid confusion with the Object interface of javascript.
 * @param data If using a dynamic string, remember to add a key since this is a React Component.
 */
export const ObjectImage = forwardRef<HTMLObjectElement, IObjectImageProps>(
  ({ ...props }, ref) => {
    if (!props.data) {
      return props.children ? (
        props.children
      ) : (
        <div className={twMerge("h-7 w-7 object-cover", props.className)}>
          <ImageCircleFill className="h-full w-full fill-medium-grey" />
        </div>
      );
    }

    return (
      <object
        type="image/png"
        ref={ref}
        {...props}
        data={props.data}
        className={twMerge(
          "flex justify-center items-center h-7 w-7 object-cover",
          props.className
        )}
      >
        {props.children ? (
          props.children
        ) : (
          <ImageCircleFill className="h-full w-full fill-medium-grey" />
        )}
      </object>
    );
  }
);

ObjectImage.displayName = "Image";
