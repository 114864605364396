// Libraries
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { useState } from "react";

// Resources
import { ReactComponent as AddLine } from "#src/assets/images/icons/add-line.svg";

// Components
import { Button } from "#src/components/common/system/Button";
import { ContentSection, StickySection } from "#src/layouts/content";
import NoResultsState from "#src/components/common/states/NoResultsState";

// Children
import ScanTable from "#src/components/scans/all-scans/ScanTable";

const AllScansDashboard = () => {
  const { t } = useTranslation("scans", { keyPrefix: "page.allScans" });
  const { workspace: workspaceId } = useParams<"workspace">();

  const [openAddScanDrawer, setOpenAddScanDrawer] = useState<boolean>(false);

  if (!workspaceId || workspaceId === "null") {
    return <NoResultsState />;
  }

  return (
    <>
      <StickySection>
        <div className="flex flex-col">
          <h1>{t("title")}</h1>
        </div>
        <Button
          size="large"
          onClick={() => setOpenAddScanDrawer((prev) => !prev)}
        >
          <AddLine />
          {t("button.newScan")}
        </Button>
      </StickySection>
      <ContentSection>
        <ScanTable
          workspaceId={workspaceId}
          openAddScanDrawer={openAddScanDrawer}
          setOpenAddScanDrawer={setOpenAddScanDrawer}
        />
      </ContentSection>
    </>
  );
};

export default AllScansDashboard;
