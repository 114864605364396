// Libraries
import { useMemo, useState } from "react";

// General
import constants from "#src/config/constants";
import type { IFilterItem } from "#src/@types/common";

// Components
import useFetchPaginated from "#src/hooks/useFetchPaginated";
import PaginationBar from "#src/components/common/helper/PaginationBar";

// API-related
import trustCenterServices from "#src/services/trustCenter";

// Children
import TrustCenterComplianceFilter from "./Filter";
import TrustCenterComplianceTableGrid from "./Table";

interface ITrustCenterComplianceTableProps {
  workspaceId: string;
}

const TrustCenterComplianceTable = ({
  workspaceId,
}: ITrustCenterComplianceTableProps) => {
  const [complianceStatus, setComplianceStatus] = useState<IFilterItem | null>(
    null
  );
  const [selectedPage, setSelectedPage] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(constants.DEFAULT_PAGE_SIZE);
  const [searchKeyword, setSearchKeyword] = useState<string>("");

  const onChangeComplianceStatusSelection = (
    selected: typeof complianceStatus
  ) => {
    setComplianceStatus(selected);
    setSelectedPage(1);
  };

  const onChangePage = (page: number) => {
    setSelectedPage(page);
  };

  const onChangePageSize = (size: number) => {
    setPageSize(size);
    setSelectedPage(1);
  };

  const onChangeSearchKeyword = (keyword: string) => {
    setSearchKeyword(keyword);
  };

  const fetchParams = useMemo<
    Parameters<typeof trustCenterServices.list_trust_center_compliance>
  >(
    () => [
      workspaceId,
      {
        status: complianceStatus?.value,
        q: searchKeyword,
        page: selectedPage,
        size: pageSize,
      },
    ],
    [workspaceId, complianceStatus, searchKeyword, selectedPage, pageSize]
  );

  const {
    list: complianceList,
    count: complianceCount,
    isLoading,
    updateData: updateComplianceList,
  } = useFetchPaginated(
    trustCenterServices.list_trust_center_compliance,
    fetchParams
  );

  return (
    <>
      <TrustCenterComplianceFilter
        complianceStatus={complianceStatus}
        searchKeyword={searchKeyword}
        onChangeComplianceStatusSelection={onChangeComplianceStatusSelection}
        onChangeSearchKeyword={onChangeSearchKeyword}
      />
      <TrustCenterComplianceTableGrid
        workspaceId={workspaceId}
        complianceList={complianceList}
        isLoading={isLoading}
        updateComplianceList={updateComplianceList}
      />
      <PaginationBar
        numOfResult={complianceCount}
        selectedPage={selectedPage}
        pageSize={pageSize}
        onChangePage={onChangePage}
        onChangePageSize={onChangePageSize}
      />
    </>
  );
};

export default TrustCenterComplianceTable;
