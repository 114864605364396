import type { IWorkspaceItem } from "#src/services/workspace";
import { type PayloadAction, createSlice } from "@reduxjs/toolkit";
import Cookies from "js-cookie";

interface IWorkspaceState {
  workspaces: IWorkspaceItem[];
  // TODO: save the whole workspace instead of just the id would be better
  selectedWorkspace: string | null;
  // WARNING: this is currently unused, and not ready to be used
  currentWorkspace: IWorkspaceItem | null;
}

const initialState: IWorkspaceState = {
  workspaces: [],
  selectedWorkspace: null,
  currentWorkspace: null,
};

const workspaceSlice = createSlice({
  name: "workspace",
  initialState,
  reducers: {
    updateWorkspaceList: (
      state,
      action: PayloadAction<IWorkspaceState["workspaces"]>
    ) => ({
      ...state,
      workspaces: action.payload,
    }),
    selectWorkspace: (
      state,
      action: PayloadAction<IWorkspaceState["selectedWorkspace"]>
    ) => {
      if (action.payload) {
        Cookies.set("workspace", action.payload, {
          sameSite: "Lax",
        });
      }
      return {
        ...state,
        selectedWorkspace: action.payload,
      };
    },
    // TODO: this is not exported. Add this.
    updateCurrentWorkspace: (
      state,
      action: PayloadAction<IWorkspaceState["currentWorkspace"]>
    ) => {
      if (action.payload) {
        Cookies.set("workspace", action.payload.id, {
          sameSite: "Lax",
        });
      }
      return {
        ...state,
        currentWorkspace: action.payload,
      };
    },
  },
});

export const { updateWorkspaceList, selectWorkspace } = workspaceSlice.actions;

export default workspaceSlice.reducer;
