// Libraries
import { useState } from "react";
import { useTranslation } from "react-i18next";

// Resources
import { ReactComponent as CloseLine } from "#src/assets/images/icons/close-line.svg";

// Components
import { IconButton } from "#src/components/common/system/Button";
import { Input } from "#src/components/common/system/Input";
import { createToast } from "#src/components/common/system/toasts";

interface IKeywordItemProps {
  keyword: string;
  disabled: boolean;
  onRemoveKeyword: (keyword: string) => void;
  onChangeKeyword: (oldKeyword: string, newKeyword: string) => void;
}

const KeywordItem = ({
  keyword,
  disabled,
  onRemoveKeyword,
  onChangeKeyword,
}: IKeywordItemProps) => {
  const { t } = useTranslation("dataLeakDetection", {
    keyPrefix: "drawer.editKeywords.keywordItem",
  });

  const [openEditor, setOpenEditor] = useState<boolean>(keyword === "");
  const [editedKeyword, setEditedKeyword] = useState<string>(keyword);
  const [warning, setWarning] = useState<boolean>(false);

  return (
    <div className="flex gap-1">
      {openEditor ? (
        <form
          className="flex gap-1 w-full"
          onBlur={() => {
            if (editedKeyword.length >= 5) {
              setOpenEditor(false);
              onChangeKeyword(keyword, editedKeyword);
            } else {
              createToast({
                type: "warning",
                message: t("notification.invalidKeywordLength.message"),
                detail: t("notification.invalidKeywordLength.description"),
              });
              setWarning(true);
            }
          }}
          onSubmit={(e) => {
            e.preventDefault();
            onChangeKeyword(keyword, editedKeyword);
            setOpenEditor(false);
          }}
        >
          <Input
            autoFocus
            placeholder={t("input.placeholder")}
            value={editedKeyword}
            onChange={(e) => {
              setEditedKeyword(e.target.value);
              if (warning) setWarning(false);
            }}
            variant={warning ? "warning" : "primary"}
          />
        </form>
      ) : (
        <span
          className={`px-3 border-2 border-transparent bg-bright-grey rounded-md h-11 w-full ${
            disabled ? "" : "cursor-pointer"
          } flex items-center`}
          onClick={() => {
            if (!disabled) setOpenEditor(true);
          }}
        >
          {keyword}
        </span>
      )}
      <IconButton
        variant="warning"
        onClick={() => {
          onRemoveKeyword(keyword);
        }}
      >
        <CloseLine />
      </IconButton>
    </div>
  );
};

export default KeywordItem;
