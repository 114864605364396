// Libraries
import { useEffect, useMemo, useState } from "react";

// General
import constants from "#src/config/constants";

// Components
import useFetchPaginated from "#src/hooks/useFetchPaginated";

// API-related
import trustCenterServices from "#src/services/trustCenter";
import { apiErrorHandler } from "#src/utils/apiErrorHandler";

// Children
import TrustCenterDocumentTableGrid from "./Table";
import AddDocumentModal from "../AddDocumentModal";

interface ITrustCenterDocumentTableProps {
  workspaceId: string;
  openAddDrawer: boolean;
  onCloseAddDrawer: () => void;
}

const TrustCenterDocumentTable = ({
  workspaceId,
  openAddDrawer,
  onCloseAddDrawer,
}: ITrustCenterDocumentTableProps) => {
  const [fileSettings, setFileSettings] =
    useState<
      Awaited<
        ReturnType<
          typeof trustCenterServices.retrieve_trust_center_file_settings
        >
      >
    >();
  const fetchParams = useMemo<
    Parameters<typeof trustCenterServices.list_trust_center_document>
  >(
    () => [
      workspaceId,
      { q: undefined, page: 1, size: constants.DEFAULT_PAGE_SIZE },
    ],
    [workspaceId]
  );

  const {
    list: documentList,
    isLoading,
    updateData: updateDocumentList,
  } = useFetchPaginated(
    trustCenterServices.list_trust_center_document,
    fetchParams
  );

  useEffect(() => {
    trustCenterServices
      .retrieve_trust_center_file_settings(workspaceId)
      .then((response) => {
        setFileSettings(response);
      })
      .catch(apiErrorHandler);
  }, [workspaceId]);

  return (
    <>
      <TrustCenterDocumentTableGrid
        workspaceId={workspaceId}
        documentList={documentList}
        isLoading={isLoading}
        onRefresh={updateDocumentList}
        maxFileSize={fileSettings ? fileSettings.file.max_file_size : null}
      />
      <AddDocumentModal
        workspaceId={workspaceId}
        open={openAddDrawer}
        onClose={onCloseAddDrawer}
        onRefresh={updateDocumentList}
        maxFileSize={fileSettings ? fileSettings.file.max_file_size : null}
      />
    </>
  );
};

export default TrustCenterDocumentTable;
