import endpoint from "#src/config/endpoint";
import request from "#src/utils/request";

export interface IBugBountyProgramMssp {
  id: string;
  name: string;
  type: string;
  created_time: number;
  updated_time: null | number;
  description_vi: string;
  description_en: string;
  description: {
    en: string;
    vi: string;
  };
  logo: string | null;
  color: string;
  social: { name: string; url: string }[];
  technologies: string[];
  email: string | null;
  locked: boolean;
}

export function retrieve_program_mssp(
  workspace_id: string,
  program_alias: string
) {
  return request.get<never, { mssp: IBugBountyProgramMssp | null }>(
    `${endpoint.WORKSPACES}/${workspace_id}/programs/${program_alias}/mssp`
  );
}

export function update_program_mssp(
  workspace_id: string,
  program_alias: string,
  data: {
    mssp_id: string | null;
  }
) {
  return request.put<never, { success: boolean }>(
    `${endpoint.WORKSPACES}/${workspace_id}/programs/${program_alias}/mssp`,
    data
  );
}
