import type { IPaginateParams, IPaginatedResponse } from "#src/@types/api";
import endpoint from "#src/config/endpoint";
import request from "#src/utils/request";

export interface IScannedDomainItem {
  asset: {
    id: number;
    name: "khaitqhust.github.io";
    address: string;
  };
  progress: number;
  status: string;
  scan_history: {
    id: number;
  };
  vulnerabilities: {
    number_issues: number;
    by_severity: { [severity: string]: number | undefined };
    by_asset: { [assetId: number]: { [severity: string]: number | undefined } };
    by_status: { [status: string]: number | undefined };
  };
}

export function list_scanned_domains(
  workspace_id: string,
  scan_id: number,
  params: {
    domain_id: number | undefined;
  } & IPaginateParams
) {
  return request.get<never, IPaginatedResponse<IScannedDomainItem>>(
    `${endpoint.WORKSPACES}/${workspace_id}/scans/${scan_id}/scanned_domains`,
    { params }
  );
}
