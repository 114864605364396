// Libraries
import { Tabs } from "@lockerpm/design";
import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

// General
import constants from "#src/config/constants";
import type { IFilterItem } from "#src/@types/common";

// Components
import TabButtonLabel from "#src/components/common/helper/antdProps/Tabs/TabButtonLabel";
import PaginationBar from "#src/components/common/helper/PaginationBar";
import useFetchPaginated from "#src/hooks/useFetchPaginated";
import { createToast } from "#src/components/common/system/toasts";
import { apiErrorHandler } from "#src/utils/apiErrorHandler";

// API-related
import type { IPolicyItem } from "#src/services/devices/policies";
import devicesServices from "#src/services/devices";

// Children
import PolicyFilter from "./Filter";
import PolicyTableGrid from "./Table";
import { PolicyTabValueEnum } from "../utils";

interface IPolicyTableProps {
  workspaceId: string;
}

const PolicyTable = ({ workspaceId }: IPolicyTableProps) => {
  const { t } = useTranslation("devices", { keyPrefix: "policies" });

  const [activeTab, setActiveTab] = useState<string>(
    PolicyTabValueEnum.WINDOWS
  );
  const [host, setHost] = useState<IFilterItem | null>(null);
  const [tag, setTag] = useState<IFilterItem | null>(null);

  const [searchKeyword, setSearchKeyword] = useState<string>("");
  const [selectedPage, setSelectedPage] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(constants.DEFAULT_PAGE_SIZE);

  const [selectedPolicies, setSelectedPolicies] = useState<IPolicyItem[]>([]);

  const tabList = [
    {
      key: PolicyTabValueEnum.WINDOWS,
      label: <TabButtonLabel name={t("tabs.windows.label")} />,
    },
    {
      key: PolicyTabValueEnum.MAC,
      label: <TabButtonLabel name={t("tabs.mac.label")} />,
    },
    {
      key: PolicyTabValueEnum.LINUX,
      label: <TabButtonLabel name={t("tabs.linux.label")} />,
    },
  ];

  const fetchParams = useMemo<
    Parameters<typeof devicesServices.list_workspace_policies>
  >(
    () => [
      workspaceId,
      {
        platform: activeTab,
        host_ids: host && host.value ? host.value : undefined,
        tag_ids: tag && tag.value ? tag.value : undefined,
        q: searchKeyword,
        page: selectedPage,
        size: pageSize,
      },
    ],
    [workspaceId, activeTab, host, tag, searchKeyword, selectedPage, pageSize]
  );

  const {
    list: policyList,
    count: policyCount,
    isLoading,
    updateData: updatePolicy,
  } = useFetchPaginated(devicesServices.list_workspace_policies, fetchParams);

  const onChangePlatformTab = (selected: typeof activeTab) => {
    setActiveTab(selected);
  };

  const onChangeHostSelection = (selected: typeof host) => {
    setHost(selected);
    setSelectedPage(1);
  };

  const onChangeTagSelection = (selected: typeof tag) => {
    setTag(selected);
    setSelectedPage(1);
  };

  const onChangeSearchKeyword = (keyword: string) => {
    setSearchKeyword(keyword);
    setSelectedPage(1);
  };

  const onChangeSelectedPolicies = (selected: IPolicyItem) => {
    if (selectedPolicies.map((item) => item.id).includes(selected.id)) {
      setSelectedPolicies((prev) => [
        ...prev.filter((item) => item.id !== selected.id),
      ]);
    } else {
      setSelectedPolicies((prev) => [...prev, selected]);
    }
  };

  const onChangePage = (selectedPage: number) => {
    setSelectedPage(selectedPage);
    setSelectedPolicies([]);
  };

  const onChangePageSize = (size: number) => {
    setPageSize(size);
    setSelectedPolicies([]);
    setSelectedPage(1);
  };

  const onSelectAllPolicies = () => {
    if (policyList) {
      setSelectedPolicies(policyList);
    }
  };

  const onDeselectAllPolicies = () => {
    setSelectedPolicies([]);
  };

  const onDisableSelectedPolicies = () => {
    devicesServices
      .delete_multiple_workspace_policy(
        workspaceId,
        selectedPolicies.map((policy) => policy.id)
      )
      .then(() => {
        updatePolicy();
        setSelectedPolicies([]);
        createToast({
          type: "success",
          message: t("notification.disableMultiplePolicies.success"),
        });
      })
      .catch((error) => {
        apiErrorHandler(error, {
          toastMessage: t("notification.disableMultiplePolicies.fail"),
        });
      });
  };

  return (
    <>
      <Tabs
        items={tabList}
        activeKey={activeTab}
        onChange={onChangePlatformTab}
      />
      <PolicyFilter
        workspaceId={workspaceId}
        totalCount={policyList ? policyList.length : 0}
        selectedCount={selectedPolicies.length}
        host={host}
        tag={tag}
        searchKeyword={searchKeyword}
        onChangeHostSelection={onChangeHostSelection}
        onChangeTagSelection={onChangeTagSelection}
        onChangeSearchKeyword={onChangeSearchKeyword}
        onSelectAllPolicies={onSelectAllPolicies}
        onDeselectAllPolicies={onDeselectAllPolicies}
        onDisableSelectedPolicies={onDisableSelectedPolicies}
      />
      <PolicyTableGrid
        workspaceId={workspaceId}
        isLoading={isLoading}
        policyList={policyList}
        selectedPolicyIds={selectedPolicies.map((policy) => policy.id)}
        onChangeSelectedPolicies={onChangeSelectedPolicies}
        refreshPolicyList={updatePolicy}
      />
      <PaginationBar
        numOfResult={policyCount}
        selectedPage={selectedPage}
        pageSize={pageSize}
        onChangePage={onChangePage}
        onChangePageSize={onChangePageSize}
      />
    </>
  );
};

export default PolicyTable;
