// Libraries
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Dropdown } from "@lockerpm/design";
import dayjs from "dayjs";

// Resources
import { ReactComponent as MoreLine } from "#src/assets/images/icons/more-line.svg";
import { ReactComponent as CheckboxCircleLine } from "#src/assets/images/icons/checkbox-circle-line.svg";
import { ReactComponent as CloseCircleLine } from "#src/assets/images/icons/close-circle-line.svg";

// Components
import useFetchPaginated from "#src/hooks/useFetchPaginated";
import LoadingState from "#src/components/common/system/LoadingState";
import NoResultsState from "#src/components/common/states/NoResultsState";
import { ObjectImage } from "#src/components/common/system/Object";
import {
  TableActionLabel,
  TableDataCell,
  TableRowWrapper,
  TableHeader,
} from "#src/components/common/Table";
import { type ITableHeaderPropItem } from "#src/components/common/Table/TableHeader";
import { IconButton } from "#src/components/common/system/Button";
import { createToast } from "#src/components/common/system/toasts";
import { apiErrorHandler } from "#src/utils/apiErrorHandler";

// API-related
import bugBountyServices from "#src/services/bugBounty";
import type { IJoinRequest } from "#src/services/bugBounty/accessRequest";

interface IBugBountyDetailsAccessRequestProps {
  workspaceId: string;
  bugBountyAlias: string;
}

const BugBountyDetailsAccessRequest = ({
  workspaceId,
  bugBountyAlias,
}: IBugBountyDetailsAccessRequestProps) => {
  const { t } = useTranslation("bugBounty", {
    keyPrefix: "page.bugBountyDetails.tab.accessRequests",
  });

  const JoinRequestActionEnum = {
    ACCEPT: "JOIN_REQUEST_ACTION_ENUM_ACCEPT",
    REJECT: "JOIN_REQUEST_ACTION_ENUM_REJECT",
  };

  const actionList = [
    {
      key: JoinRequestActionEnum.ACCEPT,
      label: (
        <TableActionLabel
          icon={<CheckboxCircleLine />}
          text={t("actions.accept")}
        />
      ),
    },
    {
      key: JoinRequestActionEnum.REJECT,
      label: (
        <TableActionLabel
          icon={<CloseCircleLine />}
          text={t("actions.reject")}
          warning
        />
      ),
    },
  ];

  const headers: ITableHeaderPropItem[] = [
    { name: t("headers.member"), align: "left" },
    { name: t("headers.email"), align: "left" },
    { name: t("headers.requestedTime"), align: "left" },
    { name: t("headers.actions"), align: "center" },
  ];

  const onAcceptJoinRequest = (request: IJoinRequest) => {
    bugBountyServices
      .accept_join_request(workspaceId, bugBountyAlias, request.id.toString())
      .then(() => {
        createToast({
          type: "success",
          message: t("notification.acceptRequest.success.title"),
          detail: t("notification.acceptRequest.success.message", {
            username: request.user ? request.user.username : request.id,
          }),
        });
        updateRequestList();
      })
      .catch((error) => {
        apiErrorHandler(error, {
          toastMessage: t("notification.acceptRequest.fail"),
        });
      });
  };

  const onRejectJoinRequest = (request: IJoinRequest) => {
    bugBountyServices
      .reject_join_request(workspaceId, bugBountyAlias, request.id.toString())
      .then(() => {
        createToast({
          type: "success",
          message: t("notification.rejectRequest.success.title"),
          detail: t("notification.rejectRequest.success.message"),
        });
        updateRequestList();
      })
      .catch((error) => {
        apiErrorHandler(error, {
          toastMessage: t("notification.rejectRequest.fail"),
        });
      });
  };

  const onClickActionItem = (key: string, request: IJoinRequest) => {
    switch (key) {
      case JoinRequestActionEnum.ACCEPT:
        onAcceptJoinRequest(request);
        return;
      case JoinRequestActionEnum.REJECT:
        onRejectJoinRequest(request);
        return;
    }
  };

  const fetchParams = useMemo<
    Parameters<typeof bugBountyServices.list_join_requests>
  >(
    () => [
      workspaceId,
      bugBountyAlias,
      {
        page: undefined,
        size: undefined,
        q: undefined,
      },
    ],
    [workspaceId, bugBountyAlias]
  );

  const {
    list: requestList,
    count: requestCount,
    updateData: updateRequestList,
    isLoading,
  } = useFetchPaginated(bugBountyServices.list_join_requests, fetchParams);

  return (
    <section className="pt-6 flex flex-col gap-6">
      <h2>{t("title")}</h2>
      {requestList === null || isLoading ? (
        <LoadingState />
      ) : requestCount === 0 ? (
        <NoResultsState />
      ) : (
        <div className="grid grid-cols-[1fr_1fr_1fr_8rem]">
          <TableHeader title="bugBounty-joinRequest" headerProps={headers} />
          {requestList.map((request) => (
            <TableRowWrapper key={`bugBounty-joinRequest-${request.id}`}>
              <TableDataCell className="gap-3">
                <ObjectImage
                  data={request.user?.avatar}
                  className="h-8 w-8 min-w-[2rem] rounded-full"
                />
                {request.user?.username}
              </TableDataCell>
              <TableDataCell>{request.user?.email}</TableDataCell>
              <TableDataCell>
                {dayjs.unix(request.access_time).format("HH:mm DD.MM.YYYY")}
              </TableDataCell>
              <TableDataCell className="p-0 justify-center">
                <Dropdown
                  menu={{
                    items: actionList,
                    onClick: ({ key, domEvent }) => {
                      domEvent.preventDefault();
                      domEvent.stopPropagation();
                      onClickActionItem(key, request);
                    },
                  }}
                  trigger={["click"]}
                >
                  <IconButton
                    variant="ghost"
                    size={9}
                    onClick={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                    }}
                  >
                    <MoreLine />
                  </IconButton>
                </Dropdown>
              </TableDataCell>
            </TableRowWrapper>
          ))}
        </div>
      )}
    </section>
  );
};

export default BugBountyDetailsAccessRequest;
