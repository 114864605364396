// Libraries
import i18next from "i18next";
import { useState } from "react";
import { matchPath, useMatches } from "react-router-dom";
import Cookies from "js-cookie";

// Resources
import { ReactComponent as QuestionLine } from "#src/assets/images/icons/question-line.svg";
import { ReactComponent as Notification2Line } from "#src/assets/images/icons/notification-2-line.svg";
import { ReactComponent as ArrowGoBackLine } from "#src/assets/images/icons/arrow-go-back-line.svg";
import { ReactComponent as LogOutBoxLine } from "#src/assets/images/icons/logout-box-line.svg";

// General
import urls from "#src/config/urls";

// API-related
import authServices from "#src/services/auth";

// Children
import LayoutHeaderBreadcrumb from "./Breadcrumb";
import SelectLanguageModal from "./SelectLanguageModal";
import SidebarHeader from "../sidebar/Header";
import { noSidebarPaths } from "../pathConfig";

const LayoutHeader = () => {
  const pathMatches = useMatches();

  const noSidebarMode = pathMatches.some((match) =>
    noSidebarPaths.some((path) => matchPath(path, match.pathname))
  );

  const [openLanguageModal, setOpenLanguageModal] = useState<boolean>(false);

  return (
    <>
      <div
        className={`flex-1 flex justify-center h-16 w-full border-b ${
          noSidebarMode
            ? "bg-dark-blue border-grey-blue pr-16"
            : "border-light-grey"
        }`}
      >
        <div
          className={`flex items-center justify-between w-full ${
            !noSidebarMode ? "px-16" : ""
          }`}
        >
          {noSidebarMode ? (
            <div className="flex">
              <SidebarHeader />
              <button
                className="min-w-[4rem] px-6 w-16 h-16 border border-t-0 border-grey-blue flex items-center"
                onClick={() => {
                  window.history.back();
                }}
              >
                <ArrowGoBackLine className="fill-white" />
              </button>
            </div>
          ) : (
            <LayoutHeaderBreadcrumb />
          )}
          <div className="flex">
            <button
              className={`w-16 h-16 flex justify-center items-center ${
                noSidebarMode ? "text-grey-blue" : "text-button-disabled "
              }`}
              disabled
            >
              <Notification2Line />
            </button>
            <button
              className={`w-16 h-16 flex justify-center items-center ${
                noSidebarMode ? "text-grey-blue" : "text-button-disabled "
              }`}
              disabled
            >
              <QuestionLine />
            </button>
            <button
              className={`w-16 h-16 ${
                noSidebarMode ? "text-white" : "text-hard-grey"
              }`}
              onClick={() => {
                setOpenLanguageModal(true);
              }}
            >
              {i18next.language.toUpperCase()}
            </button>
            <button
              className={`w-16 h-16 flex justify-center items-center ${
                noSidebarMode ? "text-white" : "text-hard-grey"
              }`}
              onClick={() => {
                authServices.userLogout().then(() => {
                  Cookies.remove("access_token");
                });
                // TODO: add a catch here, even though it's funny to fail to log out
                window.location.assign(`${urls.ID_CYSTACK_URL}/login/explore`);
              }}
            >
              <LogOutBoxLine />
            </button>
          </div>
        </div>
      </div>
      <SelectLanguageModal
        open={openLanguageModal}
        setOpen={setOpenLanguageModal}
      />
    </>
  );
};

export default LayoutHeader;
