export enum MemberRoleEnum {
  MEMBER = "MEMBER_ROLE_ENUM_MEMBER",
  ADMIN = "MEMBER_ROLE_ENUM_ADMIN",
  OWNER = "MEMBER_ROLE_ENUM_OWNER",
}

export enum ServiceStatusEnum {
  ACTIVE = "SERVICE_STATUS_ENUM_ACTIVE",
  EXPIRING = "SERVICE_STATUS_ENUM_EXPIRING",
  EXPIRED = "SERVICE_STATUS_ENUM_EXPIRED",
  ALL = "SERVICE_STATUS_ENUM_ALL",
}

export enum PaymentMethodEnum {
  BANKING = "PAYMENT_METHOD_ENUM_BANKING",
  CARD = "PAYMENT_METHOD_ENUM_CARD",
  ALL = "PAYMENT_METHOD_ENUM_ALL",
}

export enum PaymentStatusEnum {
  PAID = "PAYMENT_STATUS_ENUM_PAID",
  PROCESSING = "PAYMENT_STATUS_ENUM_PROCESSING",
  ALL = "PAYMENT_STATUS_ENUM_ALL",
}

export enum ContractStatusEnum {
  NOT_SIGNED = "CONTRACT_STATUS_ENUM_NOT_SIGNED",
  SIGNED = "CONTRACT_STATUS_ENUM_SIGNED",
  ALL = "CONTRACT_STATUS_ENUM_ALL",
}

export enum ContractTypeEnum {
  ESIGN = "CONTRACT_TYPE_ENUM_ESIGN",
  DIRECT = "CONTRACT_TYPE_ENUM_DIRECT",
  ALL = "CONTRACT_TYPE_ENUM_ALL",
}
