// Libraries
import { useState } from "react";
import { useTranslation } from "react-i18next";

// Resources
import { ReactComponent as ArrowExpandDownLine } from "#src/assets/images/icons/arrow-expand-down-line.svg";

// Components
import { Button } from "#src/components/common/system/Button";

// API-related
import type { ICertificateItem } from "#src/services/assets/certificates";

// Children
import CertificateExpirationCard from "./ExpirationCard";

const CertificatePath = ({
  certificate,
}: {
  certificate: ICertificateItem;
}) => {
  const { t } = useTranslation("assets", {
    keyPrefix: "domains.page.details.tab.certificates",
  });

  const [shownPathCount, setShownPathCount] = useState<number>(4);

  return (
    <div className="flex flex-col gap-6">
      <h2>{t("path")}</h2>
      <div className="flex flex-col gap-4">
        <CertificateExpirationCard
          expireNotAfter={certificate.not_after}
          issuerName={certificate.issuer.name}
          name={certificate.common_names[0]}
        />
        {certificate.parents.slice(0, shownPathCount).map((item, index) => {
          return (
            <div
              key={`certificateExpirationCard-${item.fingerprint}`}
              className="relative"
              style={{ marginLeft: `${(index + 1) * 3}rem` }}
            >
              <div className="absolute h-[calc(50%_+_1rem)] w-[1rem] -left-[1rem] -top-4 border-b-2 border-l-2 border-solid border-grey rounded-bl-md">
                <div className="absolute h-2 w-2 bg-grey rotate-45 -left-[calc(0.25rem_+_1px)] -top-[calc(0.25rem)]" />
                <div className="absolute h-2 w-2 bg-grey rotate-45 -right-[calc(0.25rem)] -bottom-[calc(0.25rem_+_1px)]" />
              </div>
              <CertificateExpirationCard
                expireNotAfter={item.not_after}
                issuerName={item.issuer.issuer}
                name={certificate.common_names[0]}
              />
            </div>
          );
        })}
        {shownPathCount < certificate.parents.length ? (
          <Button
            variant="text"
            onClick={() => {
              setShownPathCount((prev) => prev + 5);
            }}
          >
            {t("button.viewMore")}
            <ArrowExpandDownLine />
          </Button>
        ) : null}
      </div>
    </div>
  );
};

export default CertificatePath;
