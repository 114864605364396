// Libraries
import { useTranslation } from "react-i18next";
import { useRef, useState } from "react";

// Components
import { RoundedTag } from "#src/components/common/Tags";

// API-related
import { TagTypeValue } from "#src/config/filter/tag/value";

// Children
import { TagInput } from "./TagInput";

interface ITagEditorProps {
  workspaceId: string;
  tagList: { name: string }[];
  onChangeTags: (tags: { name: string }[]) => void;
  originalTagList?: { name: string }[];
  withInstruction?: boolean;
  allowCreateTag?: boolean;
  type: TagTypeValue;
}

/** A Tag Editor, included an input box (TagInput component), an instruction text, and 2 footer buttons
 * @param workspaceId
 * @param tagList - list of tags that has been added to this editor
 * @param onChangeTags
 * @param withInstruction - show the instruction text.
 * @param allowCreateTag - allow user to create new tags. If this is false, user can only choose tags that already exist.
 */
export const TagEditor = ({
  workspaceId,
  tagList,
  onChangeTags,
  originalTagList,
  withInstruction = false,
  allowCreateTag = false,
  type,
}: ITagEditorProps) => {
  const { t } = useTranslation("common", {
    keyPrefix: "tagEditor",
  });

  const inputRef = useRef<HTMLInputElement>(null);
  const [open, setOpen] = useState<boolean>(false);

  const onRemoveTag = (tag: { name: string }) => {
    onChangeTags(tagList.filter((item) => item.name !== tag.name));
  };

  return (
    <>
      {withInstruction ? (
        <span className="font-regular-14 text-hard-grey">
          {t("instruction")}
        </span>
      ) : null}
      <div
        className={`flex flex-col gap-3 p-3 bg-bright-grey rounded-md outline-2 ${
          open
            ? "outline outline-primary"
            : "outline-transparent hover:outline hover:outline-light-grey"
        } transition-all`}
        onClick={(e) => {
          e.stopPropagation();
          setOpen(true);
          if (inputRef.current) inputRef.current.focus();
        }}
        onMouseDown={(e) => {
          e.preventDefault();
        }}
      >
        {tagList.length > 0 ? (
          <div className="flex gap-1 flex-wrap">
            {tagList.map((tag, index) => (
              <RoundedTag
                key={`edit-tags_tag-${tag.name}-{${index}}`}
                style={
                  originalTagList?.length
                    ? originalTagList.map((t) => t.name).includes(tag.name)
                      ? "outlined"
                      : "filled"
                    : "outlined"
                }
                name={tag.name}
                onClose={() => {
                  onRemoveTag(tag);
                }}
              />
            ))}
          </div>
        ) : null}
        {open ? (
          <TagInput
            open={open}
            onChangeOpen={(value) => {
              setOpen(value);
            }}
            inputRef={inputRef}
            workspaceId={workspaceId}
            tagList={tagList}
            allowCreateTag={allowCreateTag}
            type={type}
            onChangeTags={onChangeTags}
          />
        ) : tagList.length > 0 ? null : (
          <span className="text-medium-grey">{t("searchForTags")}</span>
        )}
      </div>
    </>
  );
};
