import type { Dispatch, SetStateAction } from "react";
import CvssSelectionButton from "./CvssSelectionButton";

const CvssMetric = ({
  label,
  items,
  selected,
  setSelected,
}: {
  label: string;
  items: { value: string; label: string }[];
  selected: string;
  setSelected: Dispatch<SetStateAction<string>>;
}) => {
  return (
    <div className="w-full flex flex-col gap-3">
      <h4>{label}</h4>
      <div
        className="grid rounded-md"
        style={{
          gridTemplateColumns: `repeat(${items.length}, minmax(0, 1fr))`,
        }}
      >
        {items.map((item) => (
          <CvssSelectionButton
            key={`cvss_attack-vector_${item.value}`}
            label={item.label}
            selected={item.value === selected}
            onClick={() => {
              setSelected(item.value);
            }}
          />
        ))}
      </div>
    </div>
  );
};

export default CvssMetric;
