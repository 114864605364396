// Libraries
import { useTranslation } from "react-i18next";
import { generatePath, useNavigate } from "react-router-dom";

// Resources
import { ReactComponent as SuccessIllustration } from "#src/assets/images/illustrations/success.svg";

// General
import { pathname } from "#src/config/pathname";

// Components
import { Button } from "#src/components/common/system/Button";
import { Modal } from "#src/components/common/system/Modal";

interface PaymentSuccessModalProps {
  workspaceId: string;
  open: boolean;
  onChangeOpen: (value: boolean) => void;
}

const PaymentSuccessModal = ({
  workspaceId,
  open,
  onChangeOpen,
}: PaymentSuccessModalProps) => {
  const { t } = useTranslation("trustCenter", {
    keyPrefix: "registerDomain.payment.successModal",
  });

  const navigate = useNavigate();

  return (
    <Modal open={open} onChangeOpen={onChangeOpen}>
      <div className="flex flex-col gap-6 items-center">
        <div className="flex flex-col gap-3 items-center text-center">
          <SuccessIllustration />
          <h2>{t("paymentSuccess")}</h2>
          <span className="font-regular-16-body">{t("instruction")}</span>
        </div>
        <Button
          size="large"
          className="w-fit"
          onClick={() => {
            navigate(
              generatePath(pathname.TRUST_CENTER_CONFIG, {
                workspace: workspaceId,
              })
            );
          }}
        >
          {t("button.backToConfig")}
        </Button>
      </div>
    </Modal>
  );
};

export default PaymentSuccessModal;
