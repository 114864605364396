// Libraries
import { generatePath, useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Dropdown, Tooltip } from "@lockerpm/design";
import { useMemo, useState } from "react";
import { marked } from "marked";
import i18next from "i18next";

// Resources
import { ReactComponent as SaveLine } from "#src/assets/images/icons/save-line.svg";
import { ReactComponent as CornerLeftUpLine } from "#src/assets/images/icons/corner-left-up-line.svg";
import { ReactComponent as PlusIcon } from "#src/assets/images/icons/plus.svg";
import { ReactComponent as CloseLine } from "#src/assets/images/icons/close-line.svg";
import { ReactComponent as AddLine } from "#src/assets/images/icons/add-line.svg";
import usFlag from "#src/assets/images/flags/us.png";
import vnFlag from "#src/assets/images/flags/vn.png";

// General
import { pathname } from "#src/config/pathname";

// Components
import NoResultsState from "#src/components/common/states/NoResultsState";
import { ContentSection, StickySection } from "#src/layouts/content";
import { Button } from "#src/components/common/system/Button";
import { InputField } from "#src/components/common/helper/wrapper/InputField";
import MarkdownEditor from "#src/components/common/MarkdownEditor";
import DropdownItemLabel from "#src/components/common/helper/antdProps/Dropdown/DropdownItemLabel";
import { ObjectImage } from "#src/components/common/system/Object";
import useFetchNoPaging from "#src/hooks/useFetchNoPaging";
import { apiErrorHandler } from "#src/utils/apiErrorHandler";
import { Flag } from "#src/components/common/Flag";
import { simpleTokenParser } from "#src/utils/common";
import { useCompanyShortName } from "#src/hooks/useCompanyShortName";

// API-related
import trustCenterServices from "#src/services/trustCenter";
import resourceServices, {
  type ITrustCenterSupportedCompliance,
} from "#src/services/resource";
import { TrustCenterPolicyStatusValue } from "#src/config/filter/trustCenter/value";

const AddTrustCenterPolicy = () => {
  const { t } = useTranslation("trustCenter", {
    keyPrefix: "policy.addPolicy",
  });

  const locale = i18next.language;

  const navigate = useNavigate();

  const { workspace: workspaceId } = useParams<"workspace">();

  const [selectedStandards, setSelectedStandards] = useState<
    ITrustCenterSupportedCompliance[]
  >([]);
  const [policyDetail, setPolicyDetail] = useState<
    { lang: string; name: string; description: string }[]
  >([{ lang: locale, name: "", description: "" }]);

  const [pending, setPending] = useState<boolean>(false);

  if (!workspaceId || workspaceId === "null") {
    return <NoResultsState />;
  }

  const onCreatePolicy = (policyStatus: TrustCenterPolicyStatusValue) => {
    setPending(true);
    trustCenterServices
      .create_trust_center_policy(workspaceId, {
        compliance: selectedStandards.map((standard) => standard.id),
        details: policyDetail,
        status: policyStatus,
      })
      .then(() => {
        navigate(
          generatePath(pathname.TRUST_CENTER_POLICY, { workspace: workspaceId })
        );
      })
      .catch((error) => {
        apiErrorHandler(error, {
          toastMessage: t("notification.createPolicy.fail"),
        });
      })
      .finally(() => {
        setPending(false);
      });
  };

  const onClickAddAnotherLang = () => {
    if (policyDetail.every((item) => item.lang !== "en")) {
      setPolicyDetail((prev) => [
        ...prev,
        { lang: "en", name: "", description: "" },
      ]);
      return;
    }
    if (policyDetail.every((item) => item.lang !== "vi")) {
      setPolicyDetail((prev) => [
        ...prev,
        { lang: "vi", name: "", description: "" },
      ]);
      return;
    }
  };

  const uselessMemo = useMemo(() => [], []);

  const { list: supportedComplianceList } = useFetchNoPaging(
    resourceServices.list_trust_center_supported_compliance,
    uselessMemo
  );

  const companyShortName = useCompanyShortName(workspaceId);

  return (
    <>
      <StickySection>
        <h1>{t("title")}</h1>
        <div className="flex gap-1">
          <Button
            size="large"
            variant="secondary"
            onClick={() => {
              navigate(
                generatePath(pathname.TRUST_CENTER_POLICY, {
                  workspace: workspaceId,
                })
              );
            }}
          >
            {t("button.cancel")}
          </Button>
          <Tooltip
            title={
              policyDetail.some((item) => item.name.length === 0) ? (
                <div className="font-medium-12 whitespace-nowrap">
                  {t("button.disabledTooltip")}
                </div>
              ) : null
            }
            placement="top"
          >
            {/* Antd just can't detect custom elements, so we have to add a div here. */}
            <div>
              <Button
                size="large"
                variant="secondary"
                disabled={policyDetail.some((item) => item.name.length === 0)}
                pending={pending}
                onClick={() => {
                  onCreatePolicy(TrustCenterPolicyStatusValue.DRAFT);
                }}
              >
                <SaveLine />
                {t("button.saveAsDraft")}
              </Button>
            </div>
          </Tooltip>
          <Tooltip
            title={
              policyDetail.some((item) => item.name.length === 0) ? (
                <div className="font-medium-12 whitespace-nowrap">
                  {t("button.disabledTooltip")}
                </div>
              ) : null
            }
            placement="top"
          >
            <div>
              <Button
                size="large"
                disabled={policyDetail.some((item) => item.name.length === 0)}
                pending={pending}
                onClick={() => {
                  onCreatePolicy(TrustCenterPolicyStatusValue.PUBLISHED);
                }}
              >
                <CornerLeftUpLine />
                {t("button.publishNow")}
              </Button>
            </div>
          </Tooltip>
        </div>
      </StickySection>
      <ContentSection className="gap-12">
        <div className="flex flex-col gap-6">
          <h2>{t("standard.title")}</h2>
          <div className="flex flex-wrap gap-2">
            {selectedStandards.map((standard) => (
              <div
                key={`trustCenter-addPolicy-standard-${standard.id}`}
                className="rounded-full px-3 py-2 border border-grey bg-bright-grey-subline text-hard-grey w-fit flex items-center gap-3"
              >
                <ObjectImage
                  data={standard.logo}
                  className="h-6 w-6 min-w-[1.5rem] rounded-md"
                />
                {standard.name}
                <CloseLine
                  className="h-5 w-5 fill-hard-grey"
                  onClick={() => {
                    setSelectedStandards((prev) =>
                      prev.filter((s) => s.id !== standard.id)
                    );
                  }}
                />
              </div>
            ))}
            <Dropdown
              menu={{
                items: supportedComplianceList
                  ? supportedComplianceList
                      .filter(
                        (compliance) =>
                          !selectedStandards
                            .map((standard) => standard.id)
                            .includes(compliance.id)
                      )
                      .map((compliance) => ({
                        key: compliance.id,
                        label: (
                          <DropdownItemLabel selected={false}>
                            <ObjectImage
                              data={compliance.logo}
                              className="h-6 w-6 min-w-[1.5rem] rounded-md"
                            />
                            {compliance.name}
                          </DropdownItemLabel>
                        ),
                      }))
                  : [],
                onClick: ({ key }) => {
                  const findStandard = supportedComplianceList?.find(
                    (compliance) => compliance.id === key
                  );
                  if (findStandard) {
                    setSelectedStandards((prev) => [...prev, findStandard]);
                  }
                },
              }}
              trigger={["click"]}
            >
              <button className="rounded-full px-3 py-2 border border-grey bg-bright-grey-subline text-hard-grey w-fit flex items-center gap-3">
                <div className="h-6 w-6 flex items-center justify-center">
                  <PlusIcon className="h-5 w-5 fill-hard-grey" />
                </div>
                {t("standard.addStandard")}
              </button>
            </Dropdown>
          </div>
        </div>
        <div className="flex flex-col gap-8">
          <div className="flex flex-col gap-6">
            <h2>{t("policy.title")}</h2>
            {policyDetail.map((policyLangItem) => {
              return (
                <div
                  className="flex flex-col gap-6"
                  key={`policyByLanguage-${policyLangItem.lang}`}
                >
                  {/* h-6 to fit the close icon so when adding new language this div's height won't change. */}
                  <div className="flex justify-between items-center h-6">
                    <p className="text-hard-grey flex gap-2">
                      {policyLangItem.lang === "en" ? (
                        <>
                          <Flag pngProp={usFlag} />
                          {t("policy.language.english")}
                        </>
                      ) : policyLangItem.lang === "vi" ? (
                        <>
                          <Flag pngProp={vnFlag} />
                          {t("policy.language.vietnamese")}
                        </>
                      ) : null}
                    </p>
                    {policyDetail.length > 1 ? (
                      <button
                        className="h-6 w-6 text-hard-grey"
                        onClick={() => {
                          setPolicyDetail((prev) =>
                            prev.filter(
                              (item) => item.lang !== policyLangItem.lang
                            )
                          );
                        }}
                      >
                        <CloseLine />
                      </button>
                    ) : null}
                  </div>
                  <div className="flex flex-col gap-6 border border-light-grey p-6 rounded-md">
                    <InputField
                      title={t("policy.name.title")}
                      className="w-1/2"
                      required
                      placeholder={t("policy.name.placeholder")}
                      value={policyLangItem.name}
                      onChangeValue={(value) => {
                        setPolicyDetail((prev) =>
                          prev.map((item) =>
                            item.lang === policyLangItem.lang
                              ? { ...item, name: value }
                              : item
                          )
                        );
                      }}
                    />
                    <div className="flex flex-col gap-3">
                      <h4>{t("policy.description.title")}</h4>
                      <MarkdownEditor
                        value={policyLangItem.description}
                        placeholder={t("policy.description.placeholder")}
                        onChange={(e) => {
                          setPolicyDetail((prev) =>
                            prev.map((item) =>
                              item.lang === policyLangItem.lang
                                ? { ...item, description: e.target.value }
                                : item
                            )
                          );
                        }}
                        parser={(value) =>
                          marked.parse(
                            companyShortName
                              ? simpleTokenParser(value, { companyShortName })
                              : value
                          ) as string
                        }
                        noBox
                      />
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
          {/* If every languages are used, hide the button. */}
          {["en", "vi"].every((lang) =>
            policyDetail.some((item) => item.lang === lang)
          ) ? null : (
            <Button variant="text" size="large" onClick={onClickAddAnotherLang}>
              <AddLine />
              {t("policy.button.addAnotherLanguage")}
            </Button>
          )}
        </div>
      </ContentSection>
    </>
  );
};

export default AddTrustCenterPolicy;
