import type { IPaginatedResponse, IPaginateParams } from "#src/@types/api";
import endpoint from "#src/config/endpoint";
import request from "#src/utils/request";

export interface IMsspBugBountyProgramMember {
  id: number;
  access_time: number;
  is_primary: boolean;
  default_assign: boolean;
  email: any | null;
  role: string;
  is_public: boolean;
  identity_status: string;
  nda_status: string;
  contract_status: string;
  user?: {
    email: string;
    username: string;
    full_name: string;
    avatar: string;
  };
}

export function list_program_mssp_members(
  workspace_id: string,
  program_alias: string,
  params: IPaginateParams
) {
  return request.get<never, IPaginatedResponse<IMsspBugBountyProgramMember>>(
    `${endpoint.BASE}/mssp/${workspace_id}/programs/${program_alias}/members`,
    { params }
  );
}

export interface IMsspBugBountyProgramWorkspaceMember {
  id: number;
  access_time: number;
  email: any | null;
  role: string;
  is_public: boolean;
  identity_status: string;
  nda_status: string;
  contract_status: string;
  user?: {
    email: string;
    username: string;
    full_name: string;
    avatar: string;
  };
}

export function list_program_workspace_members(
  workspace_id: string,
  program_alias: string,
  params: IPaginateParams
) {
  return request.get<
    never,
    IPaginatedResponse<IMsspBugBountyProgramWorkspaceMember>
  >(
    `${endpoint.BASE}/mssp/${workspace_id}/programs/${program_alias}/members/workspace`,
    { params }
  );
}

export function create_mssp_program_member(
  workspace_id: string,
  program_alias: string,
  data: { members: { email: string; role: string }[] }
) {
  return request.post<never, { success: boolean }>(
    `${endpoint.BASE}/mssp/${workspace_id}/programs/${program_alias}/members`,
    data
  );
}

export function update_mssp_program_member(
  workspace_id: string,
  program_alias: string,
  member_id: string,
  data: { role?: string; default_assign?: boolean }
) {
  return request.put<never, { success: boolean }>(
    `${endpoint.BASE}/mssp/${workspace_id}/programs/${program_alias}/members/${member_id}`,
    data
  );
}

export function delete_mssp_program_member(
  workspace_id: string,
  program_alias: string,
  member_id: string
) {
  return request.delete(
    `${endpoint.BASE}/mssp/${workspace_id}/programs/${program_alias}/members/${member_id}`
  );
}
