import type { IAssetDetailItem } from "#src/services/assets";
import { useTranslation } from "react-i18next";
import dayjs from "dayjs";

const TextItemLabel = ({
  name,
  value,
}: {
  name: string;
  value: number | string;
}) => {
  return (
    <div className="text-hard-grey whitespace-nowrap">
      {name}
      <span className="text-dark-blue ml-2 font-medium-16">{value}</span>
    </div>
  );
};

interface IAssetDetailBasicInfoSectionProps {
  assetDetails: IAssetDetailItem;
}

const AssetDetailBasicInfoSection = ({
  assetDetails,
}: IAssetDetailBasicInfoSectionProps) => {
  const { t } = useTranslation("assets", {
    keyPrefix: "domains.page.details.statistics",
  });

  return (
    <section className="p-6 border border-grey rounded-md flex flex-wrap gap-x-10 gap-y-3">
      <TextItemLabel name={t("scans")} value={assetDetails.scans} />
      {assetDetails.is_subdomain || !assetDetails.detail.is_domain ? null : (
        <TextItemLabel
          name={t("subdomains")}
          value={assetDetails.subdomains.count}
        />
      )}
      <TextItemLabel name={t("ports")} value={assetDetails.ports.count} />
      <TextItemLabel
        name={t("vulnerabilities")}
        value={assetDetails.vulnerabilities.count}
      />
      <TextItemLabel
        name={t("createdDate")}
        value={dayjs.unix(assetDetails.created_time).format("HH:mm DD.MM.YYYY")}
      />
      <TextItemLabel
        name={t("lastScan")}
        value={
          assetDetails.last_scan_time
            ? dayjs.unix(assetDetails.last_scan_time).format("HH:mm DD.MM.YYYY")
            : "---"
        }
      />
    </section>
  );
};

export default AssetDetailBasicInfoSection;
