// Libraries
import { useState } from "react";
import { Drawer } from "@lockerpm/design";
import { AxiosError } from "axios";
import { useTranslation } from "react-i18next";
import i18next from "i18next";
import { z } from "zod";

// Resources
import { ReactComponent as CloseLine } from "#src/assets/images/icons/close-line.svg";
import { ReactComponent as InformationLine } from "#src/assets/images/icons/information-line.svg";

// General
import type { IDrawerAdapterProps } from "#src/@types/common";
import patterns from "#src/config/patterns";

// Components
import { Button, IconButton } from "#src/components/common/system/Button";
import { Input } from "#src/components/common/system/Input";
import { createToast } from "#src/components/common/system/toasts";

// API-related
import assetsServices from "#src/services/assets";
import { apiErrorHandler, parseBadRequest } from "#src/utils/apiErrorHandler";

interface IAddSubdomainDrawerProps extends IDrawerAdapterProps {
  domainId: number;
  domainAddress: string;
}

const AddSubdomainDrawer = ({
  open,
  onClose,
  workspaceId,
  onRefresh,
  domainId,
  domainAddress,
}: IAddSubdomainDrawerProps) => {
  const { t } = useTranslation("assets", {
    keyPrefix: "domains.drawer.addSubdomain",
  });

  // Controls subdomain address input
  const [subdomainAddress, setSubdomainAddress] = useState<string>("");
  const [errorObj, setErrorObj] = useState<{ address: string | undefined }>({
    address: undefined,
  });

  const [pending, setPending] = useState<boolean>(false);

  const onCloseDrawerAndCleanup = () => {
    onClose();
    setSubdomainAddress("");
    setErrorObj({
      address: undefined,
    });
  };

  const addNewSubdomain = () => {
    setPending(true);
    assetsServices
      .create_subdomains(domainId, workspaceId, [
        {
          address: subdomainAddress + "." + domainAddress,
        },
      ])
      .then((response) => {
        if (response.success) {
          onCloseDrawerAndCleanup();
          onRefresh();
          createToast({
            type: "success",
            message: t("notification.addSubdomain"),
          });
        }
      })
      .catch((error: unknown) => {
        if (
          error instanceof AxiosError &&
          error.response &&
          error.response.status === 400
        ) {
          const getErrorData = parseBadRequest(
            error.response.data,
            z.object({
              subdomains: z.optional(
                z.array(z.object({ address: z.optional(z.array(z.string())) }))
              ),
            })
          );
          setErrorObj((prev) => ({
            ...prev,
            address:
              getErrorData.details.subdomains &&
              getErrorData.details.subdomains[0].address
                ? getErrorData.details.subdomains[0].address[0]
                : undefined,
          }));
        } else {
          apiErrorHandler(error);
        }
      })
      .finally(() => {
        setPending(false);
      });
  };

  return (
    <>
      <Drawer
        open={open}
        onClose={onCloseDrawerAndCleanup}
        closable={false}
        title={<h2>{t("title")}</h2>}
        extra={
          <IconButton
            variant="label-grey"
            size={9}
            onClick={onCloseDrawerAndCleanup}
          >
            <CloseLine />
          </IconButton>
        }
        footer={
          <div className="flex flex-col gap-3">
            <Button
              className="w-full"
              size="large"
              pending={pending}
              onClick={addNewSubdomain}
            >
              {t("button.addSubdomain")}
            </Button>
          </div>
        }
      >
        {/* This mimics InputField component, but doesn't use the thing, since we need to modify some UI. */}
        <div className="flex flex-col gap-3 p-6">
          <h4>{t("field.newSubdomain.label")}</h4>
          <div className="flex items-center w-full justify-between gap-1">
            <Input
              variant={errorObj.address ? "warning" : "primary"}
              placeholder={t("field.newSubdomain.placeholder")}
              value={subdomainAddress}
              onChange={(e) => {
                setSubdomainAddress(e.currentTarget.value);
                if (errorObj.address) {
                  setErrorObj((prev) => ({ ...prev, address: undefined }));
                }
              }}
              onBlur={(e) => {
                if (e.target.value) {
                  if (
                    !patterns.DOMAIN.test(e.target.value + "." + domainAddress)
                  ) {
                    setErrorObj((prev) => ({
                      ...prev,
                      address: t("field.newSubdomain.error.invalid"),
                    }));
                  }
                } else {
                  setErrorObj((prev) => ({
                    ...prev,
                    address: i18next.t("common:input.error.required"),
                  }));
                }
              }}
            />
            <p>.{domainAddress}</p>
          </div>
          {errorObj.address ? (
            <span className="font-medium-14-forced text-warning flex items-center gap-1">
              <InformationLine className="h-5 w-5" />
              {errorObj.address}
            </span>
          ) : null}
        </div>
      </Drawer>
    </>
  );
};

export default AddSubdomainDrawer;
