import dayjs from 'dayjs';
import { useTranslation } from "react-i18next";
import { ReactComponent as TimeLine } from "#src/assets/images/icons/time-line.svg";

const Expiration = ({ timestamp, className, isShowIcon = true }: {
  timestamp: number,
  className: string,
  isShowIcon?: boolean
}) => {
  const { t } = useTranslation("assets", {
    keyPrefix: "certificates.page.certificates.timeline_expiring",
  });
  const currentTime = dayjs();
  const diffInDays = currentTime.diff(dayjs(timestamp * 1000), 'day');
  const diffInMonths = currentTime.diff(dayjs(timestamp * 1000), 'month');
  
  let resultText = "";
  let textColor = "";

  if (currentTime.isAfter(dayjs(timestamp * 1000))) {
    resultText = t("expired");
    textColor = "#AC0C0A";
  } else if (diffInDays <= 30) {
    resultText = t("in_days", { day: 30 });
    textColor = "#DA6F1C";
  } else if (diffInMonths < 2) {
    resultText = t("in_months", { month: 2 });
    textColor = "#D8A912";
  } else if (diffInMonths < 3) {
    resultText = t("in_months", { month: 3 });
    textColor = "#4A9005"; 
  } else {
    resultText = t("more_than_months", { month: 3 });
    textColor = "#7FBAF6"; 
  }

  return (
    <div
      style={{
        color: textColor
      }}
      className={`${className}`}
    >
      {isShowIcon ? <TimeLine className='w-4 h-4 flex-shrink-0' /> : null}
      {resultText}
    </div>
  );
};

export default Expiration;