import { ButtonHTMLAttributes, forwardRef } from "react";
import { twMerge } from "tailwind-merge";
import Loader from "./Loader";

// TODO: consider changing this to enum. This is a huge change since it will change every buttons.
type ButtonVariant = "primary" | "secondary" | "warning" | "text" | "danger";

const buttonEssentials =
  "relative flex items-center justify-center rounded-md ease-out duration-300 hover:duration-150 disabled:transition-none cursor-pointer whitespace-nowrap";

const variants: { [Variant in ButtonVariant | IconButtonVariant]: string } = {
  primary:
    "text-white bg-primary hover:bg-button-hover disabled:bg-button-disabled disabled:text-medium-grey",
  secondary:
    "text-primary bg-button-secondary border border-primary hover:bg-button-secondary-hover hover:text-button-hover hover:border-button-hover disabled:bg-button-disabled disabled:text-medium-grey disabled:border-none",
  warning:
    "text-warning bg-button-warning-bg border border-warning hover:bg-button-warning-hover disabled:bg-button-disabled disabled:text-medium-grey disabled:border-none",
  text: "text-primary hover:text-button-hover disabled:text-button-disabled",
  "label-blue": "text-dark-blue bg-bright-blue",
  "label-grey": "text-hard-grey bg-bright-grey",
  ghost:
    "text-hard-grey bg-white hover:bg-bright-blue disabled:text-button-disabled disabled:bg-inherit",
  danger:
    "text-white bg-warning disabled:bg-button-disabled disabled:text-medium-grey",
};

const sizes: { [Size in "medium" | "large"]: string } = {
  medium: "h-[2.125rem] px-6 gap-2 font-medium-14",
  large: "h-11 px-6 gap-3 font-medium-16",
};

interface IButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  variant?: ButtonVariant;
  size?: "medium" | "large";
  pending?: boolean;
}

export const Button = forwardRef<HTMLButtonElement, IButtonProps>(
  ({ variant = "primary", size = "medium", pending, ...props }, ref) => {
    return (
      <button
        ref={ref}
        {...props}
        className={twMerge(
          buttonEssentials,
          variants[variant],
          sizes[size],
          props.className
        )}
        disabled={pending || props.disabled}
      >
        {pending ? (
          <>
            <span className="invisible">{props.children}</span>
            <span className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2">
              <Loader
                baseSize={
                  size === "medium" ? 0.875 : size === "large" ? 1 : 0.875
                }
              />
            </span>
          </>
        ) : (
          props.children
        )}
      </button>
    );
  }
);
Button.displayName = "Button";

export type IconButtonVariant =
  | "primary"
  | "secondary"
  | "warning"
  | "text"
  | "label-blue"
  | "label-grey"
  | "ghost";

interface IIconButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  variant?: IconButtonVariant;
  size?: number;
}

/** Button styled for 1 icon inside only.
 * @param variant - the color scheme for the button.
 * @param size - one unit equals to 0.25rem. Default value is 11 (2.75rem).
 */
export const IconButton = forwardRef<HTMLButtonElement, IIconButtonProps>(
  ({ variant = "primary", size = 11, ...props }, ref) => {
    return (
      <button
        ref={ref}
        {...props}
        className={twMerge(
          buttonEssentials,
          variants[variant],
          props.className
        )}
        style={{
          height: size * 0.25 + "rem",
          width: size * 0.25 + "rem",
          minWidth: size * 0.25 + "rem",
        }}
      />
    );
  }
);
IconButton.displayName = "IconButton";
