// Libraries
import { useTranslation } from "react-i18next";
import { generatePath, Link, useParams } from "react-router-dom";

// Resources
import { ReactComponent as AddLine } from "#src/assets/images/icons/add-line.svg";

// General
import { pathname } from "#src/config/pathname";

// Components
import { Button } from "#src/components/common/system/Button";
import { ContentSection, StickySection } from "#src/layouts/content";
import NoResultsState from "#src/components/common/states/NoResultsState";
import { useCurrentWorkspaceRole } from "#src/hooks/useCurrentWorkspaceRole";

// Children
import TrustCenterFaqTable from "#src/components/trustCenter/faq/FaqTable";
import { WorkspaceMemberRoleValue } from "#src/config/filter/workspace/value";

const TrustCenterFaq = () => {
  const { t } = useTranslation("trustCenter", { keyPrefix: "faq" });

  const { workspace: workspaceId } = useParams<"workspace">();

  if (!workspaceId || workspaceId === "null") {
    return <NoResultsState />;
  }

  const workspaceRole = useCurrentWorkspaceRole(workspaceId);

  return (
    <>
      <StickySection>
        <h1>{t("title")}</h1>
        {workspaceRole === WorkspaceMemberRoleValue.ADMIN ||
        workspaceRole === WorkspaceMemberRoleValue.OWNER ? (
          <Link
            to={generatePath(pathname.ADD_TRUST_CENTER_FAQ, {
              workspace: workspaceId,
            })}
          >
            <Button size="large">
              <AddLine />
              {t("button.newFaq")}
            </Button>
          </Link>
        ) : null}
      </StickySection>
      <ContentSection>
        <TrustCenterFaqTable workspaceId={workspaceId} />
      </ContentSection>
    </>
  );
};

export default TrustCenterFaq;
