// Libraries
import { useState, type Dispatch, type SetStateAction } from "react";
import { Trans, useTranslation } from "react-i18next";

// Resources
import { ReactComponent as EditLine } from "#src/assets/images/icons/edit-line.svg";
import { ReactComponent as Window2Line } from "#src/assets/images/icons/window-2-line.svg";
import { ReactComponent as Asterisk } from "#src/assets/images/icons/asterisk.svg";

// General
import urls from "#src/config/urls";

// Components
import { Input } from "#src/components/common/system/Input";
import { Button } from "#src/components/common/system/Button";

// API-related
import type { ITrustCenterConfigDetails } from "#src/services/trustCenter/configuration";
import trustCenterServices from "#src/services/trustCenter";
import { apiErrorHandler } from "#src/utils/apiErrorHandler";

interface ITrustCenterConfigWebsiteGoogleTagProps {
  workspaceId: string;
  originalSettings: ITrustCenterConfigDetails;
  onRefresh: () => void;
  analytic: { custom_ga_tag_id: string };
  setAnalytic: Dispatch<SetStateAction<{ custom_ga_tag_id: string }>>;
}

const TrustCenterConfigWebsiteGoogleTag = ({
  workspaceId,
  originalSettings,
  onRefresh,
  analytic,
  setAnalytic,
}: ITrustCenterConfigWebsiteGoogleTagProps) => {
  const { t } = useTranslation("trustCenter", {
    keyPrefix: "configuration.tab.website",
  });

  const googleTagIdDefault = "G-XXXXXXXXXX";

  const [isEditingGoogleTagId, setIsEditingGoogleTagId] =
    useState<boolean>(false);

  const [pending, setPending] = useState<boolean>(false);

  const onCancelEditGoogleTagId = () => {
    setAnalytic({
      custom_ga_tag_id: originalSettings.analytic.custom_ga_tag_id || "",
    });
    setIsEditingGoogleTagId(false);
  };

  const onSaveEditGoogleTagId = () => {
    setPending(true);
    trustCenterServices
      .update_trust_center_config_analytic(workspaceId, {
        custom_ga_tag_id: analytic.custom_ga_tag_id,
      })
      .then(() => {
        setIsEditingGoogleTagId(false);
        onRefresh();
      })
      .catch((error) => {
        apiErrorHandler(error, {
          toastMessage: t("notification.changeTrustCenterAnalytic.fail"),
        });
      })
      .finally(() => {
        setPending(false);
      });
  };

  return (
    <div className="flex flex-col border border-light-grey rounded-md">
      <div className="p-6 flex justify-between rounded-md bg-bright-grey">
        <div className="flex items-center gap-3">
          <Window2Line className="h-7 w-7" />
          <h2>{t("googleTag.title")}</h2>
        </div>
      </div>
      <div className="flex flex-col gap-6 p-6">
        <div className="grid grid-cols-2 gap-x-[4.5rem]">
          <div className="flex flex-col gap-3">
            <span className="font-medium-14-forced uppercase text-severity-none flex items-center">
              <Asterisk className="-mt-2 mr-[0,125rem]" />
              {t("googleTag.tagId")}
            </span>
            <Input
              className="font-regular-16 w-full"
              placeholder={"G-XXXXXXXXXX"}
              value={analytic.custom_ga_tag_id}
              disabled={!isEditingGoogleTagId}
              onChange={(e) =>
                setAnalytic({ custom_ga_tag_id: e.target.value })
              }
            />
          </div>
          <div className="flex justify-end gap-2">
            {isEditingGoogleTagId ? (
              <>
                <Button variant="secondary" onClick={onCancelEditGoogleTagId}>
                  {t("googleTag.button.cancel")}
                </Button>
                <Button
                  variant="primary"
                  onClick={onSaveEditGoogleTagId}
                  disabled={!analytic.custom_ga_tag_id}
                  pending={pending}
                >
                  {t("googleTag.button.save")}
                </Button>
              </>
            ) : (
              <Button
                variant="secondary"
                onClick={() => setIsEditingGoogleTagId(true)}
              >
                <EditLine />
                {t("googleTag.button.edit")}
              </Button>
            )}
          </div>
        </div>
        <p className="font-regular-16-body text-hard-grey">
          {t("googleTag.description")}
        </p>
        <div className="px-6 py-3 bg-bright-blue rounded-md font-regular-14-body flex flex-col text-primary font-courier">
          <div className="flex">
            <span>{"<!-- Global tag (gtag.js) --"}</span>
            <p className="font-regular-14-body">{">"}</p>
          </div>
          <span>
            {`<script async src="https://www.googletagmanager.com/gtag/js?id=${
              analytic.custom_ga_tag_id || googleTagIdDefault
            }"></script>`}
          </span>
          <span>{"<script>"}</span>
          <div className="ml-11 flex flex-col">
            <span>{"window.dataLayer = window.dataLayer || [];"}</span>
            <span>{"function gtag(){dataLayer.push(arguments);}"}</span>
            <span>{"gtag('js', new Date());"}</span>
            <span>
              {`gtag('config', '${
                analytic.custom_ga_tag_id || googleTagIdDefault
              }')`}
            </span>
          </div>
          <span>{"</script>"}</span>
        </div>
        <p className="font-regular-16-body">
          <Trans
            t={t}
            i18nKey={"googleTag.note"}
            components={{
              a: (
                <a
                  href={urls.GOOGLE_TAG_ID_SUPPORT_URL}
                  target="_blank"
                  rel="noreferrer"
                />
              ),
            }}
          />
        </p>
      </div>
    </div>
  );
};

export default TrustCenterConfigWebsiteGoogleTag;
