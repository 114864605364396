import { DatePicker, Popover, Radio, TimePicker } from "@lockerpm/design";
import { ReactComponent as ArrowDownSLine } from "#src/assets/images/icons/arrow-down-s-line.svg";
// import { ReactComponent as TimerLine } from "#src/assets/images/icons/timer-line.svg";
// import { ReactComponent as CalendarLine } from "#src/assets/images/icons/calendar-line.svg";
import { useState } from "react";
import type { Dayjs } from "dayjs";
import { useTranslation } from "react-i18next";

interface IStartTimePopover {
  startTime: Dayjs;
  onChangeStartTime: (newStartTime: Dayjs) => void;
}

const StartTimePopover = ({
  startTime,
  onChangeStartTime,
}: IStartTimePopover) => {
  const { t } = useTranslation("common", { keyPrefix: "timePopover" });
  const [timezone, setTimezone] = useState<string>("GMT+7");

  const onChangeTime = (newTime: Dayjs | null) => {
    if (newTime) {
      onChangeStartTime(
        startTime
          .hour(newTime.hour())
          .minute(newTime.minute())
          .second(newTime.second())
      );
    }
  };

  const onChangeDate = (newDate: Dayjs | null) => {
    if (newDate) {
      onChangeStartTime(
        startTime
          .date(newDate.date())
          .month(newDate.month())
          .year(newDate.year())
      );
    }
  };

  return (
    <Popover
      content={
        <div className="p-1 flex flex-col gap-4">
          <h4>{t("selectStartTime")}</h4>
          <div className="flex flex-col gap-3">
            {/* TODO: Definitely come back to this and create a custom TimePicker */}
            <TimePicker value={startTime} onChange={onChangeTime} />

            {/* <button className="flex justify-between items-center p-3 rounded-md bg-bright-grey text-hard-grey">
              <span>17:29</span>
              <TimerLine width={"1rem"} height={"1rem"} />
            </button> */}
            <div className="flex gap-4 text-hard-grey font-regular-14">
              {t("timezone")}:
              <Radio.Group
                value={timezone}
                onChange={(e) => setTimezone(e.target.value)}
              >
                <Radio value="GMT+7">GMT+7</Radio>
                <Radio value="UTC">UTC</Radio>
              </Radio.Group>
            </div>
          </div>
          {/* TODO: Definitely come back to this and create a custom TimePicker */}
          <DatePicker value={startTime} onChange={onChangeDate} />
          {/* <button className="flex justify-between items-center p-3 rounded-md bg-bright-grey text-hard-grey">
            <span>20.02.2024</span>
            <CalendarLine width={"1rem"} height={"1rem"} />
          </button> */}
        </div>
      }
      // open={open}
      // onOpenChange={(state) => {
      //   setOpen(state);
      // }}
      placement="topRight"
      trigger="click"
    >
      <button className="flex items-center justify-between px-4 py-3 bg-bright-grey text-hard-grey rounded-md">
        {startTime.format("HH:mm:ss DD.MM.YYYY")}
        <ArrowDownSLine />
      </button>
    </Popover>
  );
};

export default StartTimePopover;
