import type { IFilterItem } from "#src/@types/common";
import i18next from "i18next";
import { PolicyComplianceEnum } from "./enum";
import { PolicyComplianceValue } from "./value";

export const policyComplianceItems: IFilterItem[] = [
  {
    key: PolicyComplianceEnum.PASSED,
    value: PolicyComplianceValue.PASSED,
    getLabel: () => i18next.t("filter.policyCompliance.passed"),
  },
  {
    key: PolicyComplianceEnum.FAILED,
    value: PolicyComplianceValue.FAILED,
    getLabel: () => i18next.t("filter.policyCompliance.failed"),
  },
  {
    key: PolicyComplianceEnum.ALL,
    value: undefined,
    getLabel: () => i18next.t("filter.all"),
  },
];
