import { ReactComponent as HeadingIcon } from "#src/assets/images/icons/heading.svg";
import EditorButton from "../EditorButton";
import { COMMENT_EDITOR_TEXTAREA_ID } from "../constants";

const HeadingButton = ({ disabled }: { disabled?: boolean }) => {
  return (
    <EditorButton
      onClick={() => {
        const editor = document.getElementById(
          COMMENT_EDITOR_TEXTAREA_ID
        ) as HTMLTextAreaElement;
        const selectionStart = editor.selectionStart;
        const selectionEnd = editor.selectionEnd;
        const selectionText = editor.value.substring(
          selectionStart,
          selectionEnd
        );

        const prefix = "\n### ";
        const suffix = "\n";

        if (selectionStart >= selectionEnd) {
          const splittedValue = editor.value.split("\n");
          const currentLine =
            editor.value.substring(0, selectionStart).split("\n").length - 1;
          const startOfCurrentLine = splittedValue
            .slice(0, currentLine)
            .reduce((prev, cur) => {
              return prev + cur.length + 1;
            }, 0);
          const endOfCurrentLine =
            startOfCurrentLine + splittedValue[currentLine].length;

          editor.setRangeText(
            prefix + splittedValue[currentLine] + suffix,
            startOfCurrentLine,
            endOfCurrentLine
          );
          editor.setSelectionRange(
            selectionStart + prefix.length,
            selectionEnd + prefix.length
          );
        } else {
          editor.setRangeText(
            prefix + selectionText + suffix,
            selectionStart,
            selectionEnd
          );
          editor.setSelectionRange(
            selectionStart + prefix.length,
            selectionEnd + prefix.length
          );
        }
      }}
      disabled={disabled}
    >
      <HeadingIcon />
    </EditorButton>
  );
};

export default HeadingButton;
