import type { IFilterItem } from "#src/@types/common";
import i18next from "i18next";
import {
  MsspPentestMemberRoleEnum,
  PentestMemberRoleEnum,
  PentestScopeScopeEnum,
  PentestScopeTypeEnum,
  PentestStatusEnum,
} from "./enum";
import {
  MsspPentestMemberRoleResponseValue,
  PentestMemberRoleResponseValue,
  PentestScopeScopeResponseValue,
  PentestScopeTypeResponseValue,
  PentestStatusResponseValue,
} from "./value";

export const pentestStatusItems: IFilterItem[] = [
  {
    key: PentestStatusEnum.DRAFT,
    value: PentestStatusResponseValue.DRAFT,
    getLabel: () => i18next.t("filter.pentestStatus.draft"),
  },
  {
    key: PentestStatusEnum.REQUESTED,
    value: PentestStatusResponseValue.REQUESTED,
    getLabel: () => i18next.t("filter.pentestStatus.requested"),
  },
  {
    key: PentestStatusEnum.EXECUTION,
    value: PentestStatusResponseValue.EXECUTION,
    getLabel: () => i18next.t("filter.pentestStatus.execution"),
  },
  {
    key: PentestStatusEnum.CLOSURE,
    value: PentestStatusResponseValue.CLOSURE,
    getLabel: () => i18next.t("filter.pentestStatus.closure"),
  },
  {
    key: PentestStatusEnum.PAUSE,
    value: PentestStatusResponseValue.PAUSE,
    getLabel: () => i18next.t("filter.pentestStatus.pause"),
  },
  {
    key: PentestStatusEnum.ALL,
    value: undefined,
    getLabel: () => i18next.t("filter.all"),
  },
];

export const pentestScopeTypeItems: IFilterItem[] = [
  {
    key: PentestScopeTypeEnum.DOMAIN,
    value: PentestScopeTypeResponseValue.DESKTOP,
    getLabel: () => i18next.t("filter.pentestScopeType.domain"),
  },
  {
    key: PentestScopeTypeEnum.SUBDOMAIN,
    value: PentestScopeTypeResponseValue.SUBDOMAIN,
    getLabel: () => i18next.t("filter.pentestScopeType.subdomain"),
  },
  {
    key: PentestScopeTypeEnum.MOBILE,
    value: PentestScopeTypeResponseValue.MOBILE,
    getLabel: () => i18next.t("filter.pentestScopeType.mobile"),
  },
  {
    key: PentestScopeTypeEnum.DESKTOP,
    value: PentestScopeTypeResponseValue.DESKTOP,
    getLabel: () => i18next.t("filter.pentestScopeType.desktop"),
  },
  {
    key: PentestScopeTypeEnum.SOURCE_CODE,
    value: PentestScopeTypeResponseValue.SOURCE_CODE,
    getLabel: () => i18next.t("filter.pentestScopeType.sourceCode"),
  },
  {
    key: PentestScopeTypeEnum.SMART_CONTRACT,
    value: PentestScopeTypeResponseValue.SMART_CONTRACT,
    getLabel: () => i18next.t("filter.pentestScopeType.smartContract"),
  },
];

export const pentestScopeScopeItems: IFilterItem[] = [
  {
    key: PentestScopeScopeEnum.IN_SCOPE,
    value: PentestScopeScopeResponseValue.IN_SCOPE,
    getLabel: () => i18next.t("filter.pentestScopeScope.inScope"),
  },
  {
    key: PentestScopeScopeEnum.OUT_OF_SCOPE,
    value: PentestScopeScopeResponseValue.OUT_OF_SCOPE,
    getLabel: () => i18next.t("filter.pentestScopeScope.outOfScope"),
  },
];

export const pentestMemberRoleItems: IFilterItem[] = [
  {
    key: PentestMemberRoleEnum.PROJECT_OWNER,
    value: PentestMemberRoleResponseValue.PROJECT_OWNER,
    getLabel: () => i18next.t("filter.pentestMemberRole.owner"),
  },
  {
    key: PentestMemberRoleEnum.ADMIN,
    value: PentestMemberRoleResponseValue.ADMIN,
    getLabel: () => i18next.t("filter.pentestMemberRole.admin"),
  },
  {
    key: PentestMemberRoleEnum.DEVELOPER,
    value: PentestMemberRoleResponseValue.DEVELOPER,
    getLabel: () => i18next.t("filter.pentestMemberRole.developer"),
  },
];

export const msspPentestMemberRoleItems = [
  {
    key: MsspPentestMemberRoleEnum.SECURITY_MANAGER,
    value: MsspPentestMemberRoleResponseValue.SECURITY_MANAGER,
    getLabel: () => i18next.t("filter.msspPentestMemberRole.securityManager"),
  },
  {
    key: MsspPentestMemberRoleEnum.PENTESTER,
    value: MsspPentestMemberRoleResponseValue.PENTESTER,
    getLabel: () => i18next.t("filter.msspPentestMemberRole.pentester"),
  },
];
