import type { IFilterItem, IFilterItemWithChildren } from "#src/@types/common";
import i18next from "i18next";
import { cvssScoreEnum, SeverityEnum, VulnerabilityStatusEnum } from "./enum";
import {
  SeverityResponseValue,
  VulnerabilityStatusResponseValue,
} from "./value";

/** Filter by vulnerability's severity */
export const severityItems: IFilterItem[] = [
  {
    key: SeverityEnum.CRITICAL,
    value: SeverityResponseValue.CRITICAL,
    getLabel: () => i18next.t("severity.critical"),
  },
  {
    key: SeverityEnum.HIGH,
    value: SeverityResponseValue.HIGH,
    getLabel: () => i18next.t("severity.high"),
  },
  {
    key: SeverityEnum.MEDIUM,
    value: SeverityResponseValue.MEDIUM,
    getLabel: () => i18next.t("severity.medium"),
  },
  {
    key: SeverityEnum.LOW,
    value: SeverityResponseValue.LOW,
    getLabel: () => i18next.t("severity.low"),
  },
  {
    key: SeverityEnum.INFORMATION,
    value: SeverityResponseValue.INFORMATION,
    getLabel: () => i18next.t("severity.information"),
  },
  {
    key: SeverityEnum.NONE,
    value: SeverityResponseValue.NONE,
    getLabel: () => i18next.t("severity.none"),
  },
  {
    key: SeverityEnum.ALL,
    value: undefined,
    getLabel: () => i18next.t("filter.all"),
  },
];

/** Filter vulnerabilities by status */
export const vulnerabilityStatusItems: IFilterItemWithChildren[] = [
  {
    key: VulnerabilityStatusEnum.OPEN,
    value: VulnerabilityStatusResponseValue.OPEN,
    getLabel: () => i18next.t("filter.vulnerabilityStatus.open"),
    type: "group",
    children: [
      {
        key: VulnerabilityStatusEnum.NEW,
        value: VulnerabilityStatusResponseValue.NEW,
        getLabel: () => i18next.t("filter.vulnerabilityStatus.new"),
      },
      {
        key: VulnerabilityStatusEnum.TRIAGED,
        value: VulnerabilityStatusResponseValue.TRIAGED,
        getLabel: () => i18next.t("filter.vulnerabilityStatus.triaged"),
      },
    ],
  },
  {
    key: VulnerabilityStatusEnum.ACCEPTED,
    value: VulnerabilityStatusResponseValue.ACCEPTED,
    getLabel: () => i18next.t("filter.vulnerabilityStatus.accepted"),
    type: "group",
    children: [
      {
        key: VulnerabilityStatusEnum.RESOLVED,
        value: VulnerabilityStatusResponseValue.RESOLVED,
        getLabel: () => i18next.t("filter.vulnerabilityStatus.resolved"),
      },
      {
        key: VulnerabilityStatusEnum.UNRESOLVED,
        value: VulnerabilityStatusResponseValue.UNRESOLVED,
        getLabel: () => i18next.t("filter.vulnerabilityStatus.unresolved"),
      },
      {
        key: VulnerabilityStatusEnum.DUPLICATE,
        value: VulnerabilityStatusResponseValue.DUPLICATE,
        getLabel: () => i18next.t("filter.vulnerabilityStatus.duplicate"),
      },
    ],
  },
  {
    key: VulnerabilityStatusEnum.REJECTED,
    value: VulnerabilityStatusResponseValue.REJECTED,
    getLabel: () => i18next.t("filter.vulnerabilityStatus.rejected"),
    type: "group",
    children: [
      {
        key: VulnerabilityStatusEnum.OUT_OF_SCOPE,
        value: VulnerabilityStatusResponseValue.OUT_OF_SCOPE,
        getLabel: () => i18next.t("filter.vulnerabilityStatus.outOfScope"),
      },
      {
        key: VulnerabilityStatusEnum.NOT_REPRODUCIBLE,
        value: VulnerabilityStatusResponseValue.NOT_REPRODUCIBLE,
        getLabel: () => i18next.t("filter.vulnerabilityStatus.notReproducible"),
      },
      {
        key: VulnerabilityStatusEnum.WONT_FIX,
        value: VulnerabilityStatusResponseValue.WONT_FIX,
        getLabel: () => i18next.t("filter.vulnerabilityStatus.wontFix"),
      },
      {
        key: VulnerabilityStatusEnum.NOT_APPLICABLE,
        value: VulnerabilityStatusResponseValue.NOT_APPLICABLE,
        getLabel: () => i18next.t("filter.vulnerabilityStatus.notApplicable"),
      },
      {
        key: VulnerabilityStatusEnum.SPAM,
        value: VulnerabilityStatusResponseValue.SPAM,
        getLabel: () => i18next.t("filter.vulnerabilityStatus.spam"),
      },
    ],
  },
];

/** Filter by CVSS Score */
export const cvssScoreItems: IFilterItem[] = [
  { key: cvssScoreEnum["0.0"], value: "0.0", getLabel: () => "0.0" },
  {
    key: cvssScoreEnum["0.1-3.9"],
    value: "0.1-3.9",
    getLabel: () => "0.1-3.9",
  },
  {
    key: cvssScoreEnum["4.0-6.9"],
    value: "4.0-6.9",
    getLabel: () => "4.0-6.9",
  },
  {
    key: cvssScoreEnum["7.0-8.9"],
    value: "7.0-8.9",
    getLabel: () => "7.0-8.9",
  },
  {
    key: cvssScoreEnum["9.0-10.0"],
    value: "9.0-10.0",
    getLabel: () => "9.0-10.0",
  },
  {
    key: cvssScoreEnum.ALL,
    value: undefined,
    getLabel: () => i18next.t("filter.all"),
  },
];
