import { useTranslation } from "react-i18next";
import { ReactComponent as Attachment2 } from "#src/assets/images/icons/attachment-2.svg";

interface IEditorFileUploaderProps {
  attachments: string[];
  onUploadAttachments: (file: File[]) => void;
}

const EditorFileUploader = ({
  attachments,
  onUploadAttachments,
}: IEditorFileUploaderProps) => {
  const { t } = useTranslation("vulnerabilities", {
    keyPrefix: "detailSection.commentEditor.button.fileUpload",
  });
  return (
    <label className="flex-1 flex flex-wrap gap-x-1.5 items-center justify-center text-primary border border-dashed border-primary rounded-md cursor-pointer">
      <input
        id="file_uploader"
        type="file"
        className="hidden"
        accept="image/*, video/*"
        onChange={(e) => {
          if (e.currentTarget.files) {
            onUploadAttachments(Array.from(e.currentTarget.files));
          }
        }}
        multiple
      />
      <Attachment2 width="1rem" height="1rem" />
      <span className="font-regular-14">
        {t("selectFilesFromYourComputer")}
      </span>
      <span className="font-regular-14 text-hard-grey">{t("maxLimit")}</span>
      {/* TODO: preview instead of plain text. This put the comment button out of bound */}
      {attachments.map((attachment) => (
        <div key={`attachment-${attachment}`}>{attachment}</div>
      ))}
    </label>
  );
};

export default EditorFileUploader;
