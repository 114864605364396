import { useEffect, useState } from "react";
import { apiErrorHandler } from "#src/utils/apiErrorHandler";
import trustCenterServices from "#src/services/trustCenter";

export const useCompanyShortName = (workspaceId: string | undefined) => {
  const [shortName, setShortName] = useState<string>();

  useEffect(() => {
    if (workspaceId) {
      trustCenterServices
        .retrieve_trust_center_config(workspaceId)
        .then((response) => {
          if (response.profile.short_name) {
            setShortName(response.profile.short_name);
          } else if (response.profile.name) {
            setShortName(response.profile.name);
          }
        })
        .catch((error) => {
          // No i18n since this should not be something related to user flow.
          // TODO: change this into alert or different toast or something
          apiErrorHandler(error, {
            toastMessage: "cannot access trust center config",
          });
        });
    }
  }, [workspaceId]);

  return shortName;
};
