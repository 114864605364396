// Libraries
import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

// Resources
import { ReactComponent as ErrorWarningLine } from "#src/assets/images/icons/error-warning-line.svg";

// General
import constants from "#src/config/constants";
import type { IFilterItem } from "#src/@types/common";

// Components
import PaginationBar from "#src/components/common/helper/PaginationBar";
import useFetchPaginated from "#src/hooks/useFetchPaginated";

// API-related
import devicesServices from "#src/services/devices";

// Children
import HostPoliciesTableGrid from "./Table";
import HostPoliciesFilter from "./Filter";

interface IHostDetailsPoliciesProps {
  workspaceId: string;
  hostId: number;
  failingCount: number;
}

const HostDetailsPolicies = ({
  workspaceId,
  hostId,
  failingCount,
}: IHostDetailsPoliciesProps) => {
  const { t } = useTranslation("devices", {
    keyPrefix: "hosts.page.details.tab.policies",
  });

  const [policyCompliance, setPolicyCompliance] = useState<IFilterItem | null>(
    null
  );

  const [searchKeyword, setSearchKeyword] = useState<string>("");
  const [selectedPage, setSelectedPage] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(constants.DEFAULT_PAGE_SIZE);

  const fetchParams = useMemo<
    Parameters<typeof devicesServices.list_host_policies>
  >(
    () => [
      workspaceId,
      hostId,
      {
        policy_compliance_status: policyCompliance?.value,
        q: searchKeyword,
        page: selectedPage,
        size: pageSize,
      },
    ],
    [
      workspaceId,
      hostId,
      policyCompliance,
      searchKeyword,
      selectedPage,
      pageSize,
    ]
  );

  const onChangePolicyComplianceSelection = (
    selected: typeof policyCompliance
  ) => {
    setPolicyCompliance(selected);
    setSelectedPage(1);
  };

  const onChangeSearchKeyword = (keyword: string) => {
    setSearchKeyword(keyword);
    setSelectedPage(1);
  };

  const onChangePage = (selectedPage: number) => {
    setSelectedPage(selectedPage);
  };

  const onChangePageSize = (size: number) => {
    setPageSize(size);
    setSelectedPage(1);
  };

  const {
    list: hostPolicyList,
    count: hostPolicyCount,
    isLoading,
  } = useFetchPaginated(devicesServices.list_host_policies, fetchParams);

  return (
    <div className="flex flex-col gap-6 pt-6">
      <h2>{t("title")}</h2>
      {failingCount ? (
        <div className="w-full bg-bright-grey rounded-md p-6 flex gap-3">
          <ErrorWarningLine
            height={"1.5rem"}
            width={"1.5rem"}
            className="text-hard-grey"
          />
          <div className="flex flex-col gap-3">
            <p className="text-hard-grey font-medium-18">
              {t("thisDeviceIsFailing", { count: failingCount })}
            </p>
            <p>{t("clickBelow")}</p>
          </div>
        </div>
      ) : null}
      <HostPoliciesFilter
        policyCompliance={policyCompliance}
        searchKeyword={searchKeyword}
        onChangePolicyComplianceSelection={onChangePolicyComplianceSelection}
        onChangeSearchKeyword={onChangeSearchKeyword}
      />
      <HostPoliciesTableGrid
        hostPolicyList={hostPolicyList}
        isLoading={isLoading}
      />
      <PaginationBar
        numOfResult={hostPolicyCount}
        selectedPage={selectedPage}
        pageSize={pageSize}
        onChangePage={onChangePage}
        onChangePageSize={onChangePageSize}
      />
    </div>
  );
};

export default HostDetailsPolicies;
