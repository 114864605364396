import { ReactNode } from "react";

interface IEmptyStateProps {
  illustration: ReactNode;
  text: string;
}

const EmptyState = ({ illustration, text }: IEmptyStateProps) => {
  return (
    <div className="flex justify-center items-center py-12">
      <div className="flex flex-col items-center">
        {illustration}
        <h4 className="whitespace-pre-line text-center">{text}</h4>
      </div>
    </div>
  );
};

export default EmptyState;
