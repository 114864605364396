import { PentestStatusResponseValue } from "#src/config/filter/pentest/value";

const colorByPentestStatus: { [S: string]: string } = {
  [PentestStatusResponseValue.DRAFT]: "bg-silver",
  [PentestStatusResponseValue.REQUESTED]: "bg-blue",
  [PentestStatusResponseValue.EXECUTION]: "bg-green",
  [PentestStatusResponseValue.CLOSURE]: "bg-yellow",
  [PentestStatusResponseValue.PAUSE]: "bg-red",
};

export const getColorByPentestStatus = (status: string) => {
  if (Object.keys(colorByPentestStatus).includes(status)) {
    return colorByPentestStatus[status];
  }
  return "bg-silver";
};
