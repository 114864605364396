import type { IPaginateParams, IPaginatedResponse } from "#src/@types/api";
import endpoint from "#src/config/endpoint";
import request from "#src/utils/request";
import {
  get_asset_certificate,
  get_certificate_statistics,
  list_certificates,
  type ICertificateBaseItem,
} from "./certificates";
import { type IAssetPortItem, list_asset_ports } from "./ports";
import {
  type IAssetSubdomainItem,
  list_asset_subdomains,
  create_subdomains,
  update_subdomain,
  delete_subdomain,
  delete_multiple_subdomains,
} from "./subdomains";
import {
  type IAssetVulnerabilityItem,
  list_asset_vulnerabilities,
  update_vulnerability,
  update_status_multiple_vulnerabilities,
} from "./vulnerabilities";

// If an API returns an object, its type will be applied with an interface named with "Response" suffix
// If an API returns an array, its type will just be an array of object typed with its domain interface

export interface IAssetItem {
  id: number;
  name: string;
  description: string;
  created_time: number;
  updated_time: number | null;
  last_scan_time: number | null;
  locked: boolean;
  is_subdomain: boolean;
  private_host: boolean;
  verification: boolean;
  asset_type: string;
  detail: {
    address: string;
    ips: string[];
    path: string;
    web_scheme: string;
    web_address: string;
    is_domain: boolean;
  };
  workspace: {
    id: string;
    name: string;
  };
  scans: number;
  subdomains: number;
  vulnerabilities: number;
  tags: { name: string }[];
}

interface IListAssetParams {
  is_domain: 0 | 1 | undefined;
  from: number | undefined;
  to: number | undefined;
  // Should only be 0 | 1
  vulnerable: string | undefined;
  // Should only be 0 | 1
  verification: string | undefined;
}

function list_domains(
  workspace_id: string,
  params: IListAssetParams & IPaginateParams
) {
  return request.get<never, IPaginatedResponse<IAssetItem>>(
    `${endpoint.WORKSPACES}/${workspace_id}/assets?asset_type=domain`,
    { params }
  );
}

function list_domains_shortly(
  workspace_id: string,
  params: {
    from: number | undefined;
    to: number | undefined;
    vulnerable: 0 | 1 | undefined;
    verification: 0 | 1 | undefined;
    q: string | undefined;
  }
) {
  return request.get<never, IAssetItem[]>(
    `${endpoint.WORKSPACES}/${workspace_id}/assets?asset_type=domain&paging=0&shortly=1`,
    { params }
  );
}

export type IAssetDetailItem = Omit<
  IAssetItem,
  "subdomains" | "vulnerabilities"
> & {
  subdomains: {
    count: number;
    list: Pick<IAssetSubdomainItem, "id" | "address" | "ips" | "last_found">[];
  };
  vulnerabilities: {
    count: number;
    list: IAssetVulnerabilityItem[];
    by_sub_status: Record<string, number>;
    by_severity: Record<string, number>;
  };
  ports: {
    count: number;
    list: Pick<
      IAssetPortItem,
      "last_found" | "port" | "protocol" | "service" | "version" | "product"
    >[];
  };
  certificates: ICertificateBaseItem | null;
  created_by: {
    email: string;
    username: string;
    full_name: string;
    avatar: string;
  } | null;
  authentication_method: "basic" | "cookie" | null;
  authentication: {
    cookie: string | null;
    username: string | null;
    password: string | null;
  } | null;
  custom_headers: { name: string; value: string }[] | null;
  use_cystack_user_agent: boolean;
};

function retrieve_asset(workspace_id: string, domain_id: number) {
  return request.get<never, IAssetDetailItem>(
    `${endpoint.WORKSPACES}/${workspace_id}/assets/${domain_id}`
  );
}

function create_asset(
  workspace_id: string,
  data: {
    name: string;
    address: string;
    description: string;
  }
) {
  return request.post<never, { id: number }>(
    `${endpoint.WORKSPACES}/${workspace_id}/assets`,
    data
  );
}

function update_asset(
  id: number,
  workspace_id: string,
  data: Partial<Omit<IAssetDetailItem, "tags"> & { tags: string[] }>
) {
  return request.put<never, { success: boolean }>(
    `${endpoint.WORKSPACES}/${workspace_id}/assets/${id}`,
    data
  );
}

function delete_asset(id: number, workspace_id: string) {
  return request.delete(`${endpoint.WORKSPACES}/${workspace_id}/assets/${id}`);
}

function delete_multiple_assets(asset_ids: number[], workspace_id: string) {
  return request.post(`${endpoint.WORKSPACES}/${workspace_id}/assets/delete`, {
    asset_ids,
  });
}

export interface IDomainOwnershipItem {
  name: string;
  key: string;
  value: string;
  description: string;
}

function list_domain_ownerships(id: number, workspace_id: string) {
  return request.get<never, IDomainOwnershipItem[]>(
    `${endpoint.WORKSPACES}/${workspace_id}/assets/${id}/verification`
  );
}

function verify_domain(id: number, workspace_id: string) {
  return request.post<never, { code: string; message: string }>(
    `${endpoint.WORKSPACES}/${workspace_id}/assets/${id}/verification`,
    { id }
  );
}

export interface IAssetStatistics {
  total: number;
  vulnerable_domains: number;
  root_domains: number;
  sub_domains: number;
  auto_discover: number;
  unverified_domains: number;
}

function get_asset_statistics(workspace_id: string, params: IListAssetParams) {
  return request.get<never, IAssetStatistics>(
    `${endpoint.WORKSPACES}/${workspace_id}/assets/statistics`,
    { params }
  );
}

export interface IParamsListDomainByCertificate {
  asset_type: string,
  shortly: number,
  cert_id: string | number
}
function get_domain_by_certificate(workspace_id: string, params: IParamsListDomainByCertificate) {
  return request.get<never, IPaginatedResponse<IAssetItem>>(
    `${endpoint.WORKSPACES}/${workspace_id}/assets`,
    { params }
  );
}

const assetsServices = {
  list_domains,
  list_domains_shortly,
  retrieve_asset,
  create_asset,
  update_asset,
  delete_asset,
  delete_multiple_assets,
  list_domain_ownerships,
  verify_domain,
  get_asset_statistics,
  get_domain_by_certificate,

  list_asset_subdomains,
  create_subdomains,
  update_subdomain,
  delete_subdomain,
  delete_multiple_subdomains,

  list_asset_vulnerabilities,
  update_vulnerability,
  update_status_multiple_vulnerabilities,

  list_asset_ports,

  get_asset_certificate,
  list_certificates,
  get_certificate_statistics
};

export default assetsServices;
