import type { IPaginateParams, IPaginatedResponse } from "#src/@types/api";
import endpoint from "#src/config/endpoint";
import request from "#src/utils/request";

export interface ILeakedDataAccountDetails {
  url?: string | null;
  password?: string | null;
  username?: string | null;
}

export interface ILeakedDataCookieDetails {
  raw?: string | null;
  name?: string | null;
  path?: string | null;
  value?: string | null;
  domain?: string | null;
  expiry?: number | null;
  secure?: boolean | null;
  include_subdomains?: boolean | null;
}

export interface ILeakedDataEmailDetails {
  email?: string | null;
}

export interface ILeakedDataSourceCodeDetails {
  path?: string | null;
  repo?: string | null;
  commit?: string | null;
  keyword?: string | null;
}

/** T: put typeof data's `details` object here */
export interface ILeakedDataItem<T> {
  id: number;
  type: string;
  created_time: number;
  updated_time: number;
  status: string;
  sub_status: string;
  // This (matches) looks like it's also deprecated
  matches: [
    {
      preview: string;
      line_number?: number;
      // DEPRECATED: will be removed soon
      offset_and_lengths?: Array<[number, number]>;
    }
  ];
  details: T;
  source: string[];
  referer: any | null;
  severity: string | null;
  vuln_id: number;
  keyword: string;
}

function list_data_leak(
  workspace_id: string,
  params: {
    from: number | undefined;
    to: number | undefined;
    filter_status: string | undefined;
    filter_sub_status: string | undefined;
    filter_severity: string | undefined;
    filter_type: string | undefined;
    filter_keyword_ids: string | undefined;
  } & IPaginateParams
) {
  return request.get<never, IPaginatedResponse<ILeakedDataItem<unknown>>>(
    `${endpoint.WORKSPACES}/${workspace_id}/data_leaks`,
    { params }
  );
}

function update_leaked_data<T>(
  workspace_id: string,
  leaked_data_id: number,
  data: Partial<ILeakedDataItem<T>>
) {
  return request.put<never, { success: boolean }>(
    `${endpoint.WORKSPACES}/${workspace_id}/data_leaks/${leaked_data_id}`,
    data
  );
}

export interface IDataLeakStatistics {
  email: number;
  source_code: number;
  api_key: number;
  credential: number;
  payment: number;
  raw: number;
  customer_data: number;
  account: number;
  cookie: number;
  total: number;
}

function statistics_data_leak(
  workspace_id: string,
  params: Parameters<typeof list_data_leak>[1]
) {
  return request.get<never, IDataLeakStatistics>(
    `${endpoint.WORKSPACES}/${workspace_id}/data_leaks/statistics`,
    { params }
  );
}

export interface IKeywordItem {
  id: number;
  created_time: number;
  keyword: string;
  updated_time: number | null;
}

function list_keywords(workspace_id: string, params: IPaginateParams) {
  return request.get<never, IPaginatedResponse<IKeywordItem>>(
    `${endpoint.WORKSPACES}/${workspace_id}/data_leaks/keywords`,
    { params }
  );
}

function list_keywords_shortly(workspace_id: string) {
  return request.get<never, IKeywordItem[]>(
    `${endpoint.WORKSPACES}/${workspace_id}/data_leaks/keywords`,
    { params: { paging: 0 } }
  );
}

function add_multiple_keywords(
  workspace_id: string,
  data: { keywords: string[] }
) {
  return request.post(
    `${endpoint.WORKSPACES}/${workspace_id}/data_leaks/keywords/multiple`,
    { ...data }
  );
}

function remove_multiple_keywords(
  workspace_id: string,
  data: { ids: number[] }
) {
  return request.post(
    `${endpoint.WORKSPACES}/${workspace_id}/data_leaks/keywords/delete_multiple`,
    { ...data }
  );
}

export default {
  list_data_leak,
  update_leaked_data,
  statistics_data_leak,
  list_keywords,
  list_keywords_shortly,
  add_multiple_keywords,
  remove_multiple_keywords,
};
