// Libraries
import { useTranslation } from "react-i18next";

// Children
import type { IPentestProjectDetails } from "#src/services/pentest";
import { toPascalCase } from "#src/utils/common";

interface IMsspPentestDetailsScopeTableProps {
  scope: IPentestProjectDetails["targets"][number][];
}

const MsspPentestDetailsScopeTable = ({
  scope,
}: IMsspPentestDetailsScopeTableProps) => {
  const { t } = useTranslation("pentest", {
    keyPrefix: "page.addPentest.scope",
  });

  return (
    <>
      <div className="grid grid-cols-[6rem_1fr_1fr_1fr] gap-y-6">
        <div className="grid grid-cols-subgrid col-span-full gap-x-6 bg-bright-grey text-hard-grey py-5 px-9">
          <div>#</div>
          <div>{t("header.assetType")}</div>
          <div>{t("header.assets")}</div>
          <div>{t("header.scope")}</div>
          <div></div>
        </div>
        {scope.map((scopeItem, scopeItemId) => (
          <div
            key={`scope-${scopeItem.id}`}
            className="grid grid-cols-subgrid col-span-full gap-x-6 px-9 h-11"
          >
            <div className="flex items-center">{scopeItemId + 1}</div>
            <div className="flex items-center">
              {toPascalCase(scopeItem.type)}
            </div>
            <div className="flex items-center">{scopeItem.name}</div>
            <div className="flex items-center">
              {toPascalCase(scopeItem.scope.replaceAll("_", " "))}
            </div>
          </div>
        ))}
      </div>
    </>
  );
};

export default MsspPentestDetailsScopeTable;
