// Libraries
import { useMemo, useState } from "react";

// General
import type { IFilterItem } from "#src/@types/common";

// Components
import useFetchNoPaging from "#src/hooks/useFetchNoPaging";

// API-related
import paymentService from "#src/services/payment";
import type { IWorkspaceItem } from "#src/services/workspace";

// Children
import BillingServiceFilter from "./Filter";
import BillingServiceTable from "./Table";

interface IBillingServiceTabProps {
  workspaceId: string;
  currentWorkspace: IWorkspaceItem | null;
}

const BillingServiceTab = ({
  workspaceId,
  currentWorkspace,
}: IBillingServiceTabProps) => {
  const [status, setStatus] = useState<IFilterItem | null>(null);
  const [paymentMethod, setPaymentMethod] = useState<IFilterItem | null>(null);
  const [searchKeyword, setSearchKeyword] = useState<string>("");

  const fetchParams = useMemo<
    Parameters<typeof paymentService.list_workspace_payment_plans>
  >(
    () => [
      workspaceId,
      {
        status: status?.value,
        payment_method: paymentMethod?.value,
        q: searchKeyword,
      },
    ],
    [workspaceId, status, paymentMethod, searchKeyword]
  );

  const onChangeStatusSelection = (selected: typeof status) => {
    setStatus(selected);
  };

  const onChangePaymentMethodSelection = (selected: typeof paymentMethod) => {
    setPaymentMethod(selected);
  };

  const onChangeSearchKeyword = (keyword: string) => {
    setSearchKeyword(keyword);
  };

  const { list: serviceList, isLoading } = useFetchNoPaging(
    paymentService.list_workspace_payment_plans,
    fetchParams
  );

  return (
    <section className="flex flex-col gap-6 pt-6">
      <BillingServiceFilter
        status={status}
        paymentMethod={paymentMethod}
        searchKeyword={searchKeyword}
        onChangeStatusSelection={onChangeStatusSelection}
        onChangePaymentMethodSelection={onChangePaymentMethodSelection}
        onChangeSearchKeyword={onChangeSearchKeyword}
      />
      <BillingServiceTable
        workspaceId={workspaceId}
        serviceList={serviceList}
        isLoading={isLoading}
        currentWorkspace={currentWorkspace}
      />
    </section>
  );
};

export default BillingServiceTab;
