import { type ReactNode, useLayoutEffect, useState } from "react";
import { twMerge } from "tailwind-merge";

interface IDropdownItemLabel {
  className?: string;
  children: ReactNode;
  // TODO: this should be optional
  selected: boolean;
  syncWidthId?: string;
}

const DropdownItemLabel = ({
  children,
  className,
  selected = false,
  syncWidthId,
}: IDropdownItemLabel) => {
  const [labelWidth, setLabelWidth] = useState<number | undefined>();

  useLayoutEffect(() => {
    const triggerElement = syncWidthId
      ? document.getElementById(syncWidthId)
      : undefined;

    if (triggerElement) {
      setLabelWidth(triggerElement.offsetWidth);
    }
  }, [syncWidthId]);

  return (
    <div
      style={syncWidthId && labelWidth ? { width: labelWidth } : undefined}
      className={twMerge(
        `flex p-3 gap-3 items-center font-medium-16 min-w-[8rem] overflow-hidden text-ellipsis`,
        selected ? "" : "text-medium-grey",
        className
      )}
    >
      {children}
    </div>
  );
};

export default DropdownItemLabel;
