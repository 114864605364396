// Libraries
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { Link } from "react-router-dom";

// Resources
import { ReactComponent as AddLine } from "#src/assets/images/icons/add-line.svg";

// Components
import { Button } from "#src/components/common/system/Button";
import { ContentSection, StickySection } from "#src/layouts/content";

// Children
import PolicyTable from "#src/components/devices/policies/PolicyTable";

const DevicePolicies = () => {
  const { t } = useTranslation("devices", { keyPrefix: "policies" });
  const { workspace: workspaceId } = useParams<"workspace">();

  return workspaceId ? (
    <>
      <StickySection>
        <h1>{t("title")}</h1>
        <Link to="add-policies">
          <Button size="large">
            <AddLine />
            {t("button.newPolicy")}
          </Button>
        </Link>
      </StickySection>
      <ContentSection>
        <PolicyTable workspaceId={workspaceId} />
      </ContentSection>
    </>
  ) : null;
};

export default DevicePolicies;
