import type { IFilterItem } from "#src/@types/common";
import i18next, { t } from "i18next";
import { ProtocolResponseValue } from "./value";
import {
  AssetVulnerableStatusEnum,
  Expiration,
  ProtocolEnum,
  VerifiedOwnershipEnum,
} from "./enum";
import { ReactComponent as TimeLine } from "#src/assets/images/icons/time-line.svg";

/** Filter by whether this item is marked as vulnerable or not */
export const assetVulnerableStatusItems: IFilterItem[] = [
  {
    key: AssetVulnerableStatusEnum.YES,
    value: "1",
    getLabel: () => i18next.t("filter.yes"),
  },
  {
    key: AssetVulnerableStatusEnum.NO,
    value: "0",
    getLabel: () => i18next.t("filter.no"),
  },
  {
    key: AssetVulnerableStatusEnum.ALL,
    value: undefined,
    getLabel: () => i18next.t("filter.all"),
  },
];

/** Filter by whether this item is verified with ownership */
export const verifiedOwnershipItems: IFilterItem[] = [
  {
    key: VerifiedOwnershipEnum.YES,
    value: "1",
    getLabel: () => i18next.t("filter.yes"),
  },
  {
    key: VerifiedOwnershipEnum.NO,
    value: "0",
    getLabel: () => i18next.t("filter.no"),
  },
  {
    key: VerifiedOwnershipEnum.ALL,
    value: undefined,
    getLabel: () => i18next.t("filter.all"),
  },
];

/** Filter by protocol of this item */
export const protocolItems: IFilterItem[] = [
  {
    key: ProtocolEnum.TCP,
    value: ProtocolResponseValue.TCP,
    getLabel: () => "TCP",
  },
  {
    key: ProtocolEnum.UDP,
    value: ProtocolResponseValue.UDP,
    getLabel: () => "UDP",
  },
  {
    key: ProtocolEnum.ALL,
    value: undefined,
    getLabel: () => i18next.t("filter.all"),
  },
];

export const expirationItems: IFilterItem[] = [
  {
    key: Expiration.EXPIRED,
    value: Expiration.EXPIRED,
    getLabel() {
      return (
        <span className="font-medium-14 text-warning-dark-red">{t("assets:certificates.page.certificates.timeline_expiring.expired")}</span>
      )
    },
  },
  {
    key: Expiration.EXPIRED_IN_30_DAYS,
    value: Expiration.EXPIRED_IN_30_DAYS,
    getLabel() {
      return (
        <div className="flex items-center gap-2 text-policy-expire-in30days">
          <TimeLine className="w-4 h-4" />
          <span className="font-medium-14">{t("assets:certificates.page.certificates.timeline_expiring.in_days", {day: 30})}</span>
        </div>
      )
    },
  },
  {
    key: Expiration.EXPIRED_IN_60_DAYS,
    value: Expiration.EXPIRED_IN_60_DAYS,
    getLabel() {
      return (
        <div className="flex items-center gap-2 text-policy-expire-in2months">
          <TimeLine className="w-4 h-4" />
          <span className="font-medium-14">{t("assets:certificates.page.certificates.timeline_expiring.in_months", {month: 2})}</span>
        </div>
      )
    },
  },
  {
    key: Expiration.EXPIRED_IN_90_DAYS,
    value: Expiration.EXPIRED_IN_90_DAYS,
    getLabel() {
      return (
        <div className="flex items-center gap-2 text-support-green">
          <TimeLine className="w-4 h-4" />
          <span className="font-medium-14">{t("assets:certificates.page.certificates.timeline_expiring.in_months", {month: 3})}</span>
        </div>
      )
    },
  },
  {
    key: Expiration.EXPIRE_MORE_90_DAYS,
    value: Expiration.EXPIRE_MORE_90_DAYS,
    getLabel() {
      return (
        <div className="flex items-center gap-2 text-blue">
          <TimeLine className="w-4 h-4" />
          <span className="font-medium-14">{t("assets:certificates.page.certificates.timeline_expiring.more_than_months", {month: 3})}</span>
        </div>
      )
    },
  },
  {
    key: ProtocolEnum.ALL,
    value: undefined,
    getLabel: () => i18next.t("filter.all"),
  },
];

export const algorithmOptions: IFilterItem[] = [
  {
    key: 'SHA1withRSA',
    value: 'SHA1withRSA',
    getLabel: () => 'SHA1withRSA'
  },
  {
    key: 'SHA256withRSA',
    value: 'SHA256withRSA',
    getLabel: () => 'SHA256withRSA'
  },
  {
    key: 'SHA384withRSA',
    value: 'SHA384withRSA',
    getLabel: () => 'SHA384withRSA'
  },
  {
    key: 'SHA256withECDSA',
    value: 'SHA256withECDSA',
    getLabel: () => 'SHA256withECDSA'
  },
  {
    key: 'SHA384withECDSA',
    value: 'SHA384withECDSA',
    getLabel: () => 'SHA384withECDSA'
  },
];

export const keySizeOptions: IFilterItem[] = [
  {
    key: '256',
    value: '256',
    getLabel: () => '256'
  },
  {
    key: '384',
    value: '384',
    getLabel: () => '384'
  },
  {
    key: '1024',
    value: '1024',
    getLabel: () => '1024'
  },
  {
    key: '2048',
    value: '2048',
    getLabel: () => '2048'
  },
  {
    key: '3072',
    value: '3072',
    getLabel: () => '3072'
  },
  {
    key: '4096',
    value: '4096',
    getLabel: () => '4096'
  },
];

