// Libraries
import { Modal } from "@lockerpm/design";
import type { Dispatch, SetStateAction } from "react";
import { useTranslation } from "react-i18next";

// Components
import { Button } from "#src/components/common/system/Button";
import { Input } from "#src/components/common/system/Input";

interface IEditDomainModalProps {
  open: boolean;
  onClose: () => void;
  onClickNext: (newDomain: string | null) => void;
  domain: string;
  setDomain: Dispatch<SetStateAction<string>>;
  pending: boolean;
}

const EditDomainModal = ({
  open,
  onClose,
  onClickNext,
  domain,
  setDomain,
  pending,
}: IEditDomainModalProps) => {
  const { t } = useTranslation("trustCenter", {
    keyPrefix: "configuration.editDomainModal",
  });

  const onCloseAndCleanup = () => {
    onClose();
    setDomain("");
  };

  return (
    <Modal
      open={open}
      onCancel={onCloseAndCleanup}
      title={<h2>{t("title")}</h2>}
      centered
      footer={null}
      width="44rem"
    >
      <div className="flex flex-col gap-6">
        <div className="font-regular-14 text-hard-grey">{t("instruction")}</div>
        <div className="flex flex-col gap-3">
          <h4>{t("field.yourDomain.title")}</h4>
          <Input
            value={domain}
            onChange={(e) => {
              setDomain(e.target.value);
            }}
            placeholder={t("field.yourDomain.placeholder")}
          />
        </div>
        <div className="flex justify-end gap-1">
          <Button
            variant="secondary"
            onClick={() => {
              onCloseAndCleanup();
            }}
          >
            {t("button.cancel")}
          </Button>
          <Button
            pending={pending}
            onClick={() => {
              onClickNext(domain);
            }}
          >
            {t("button.next")}
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default EditDomainModal;
