import { useEffect, useRef } from "react";

/**
 * A hook that let us use setInterval in React.
 * @param callback
 * @param delay - time interval, pass `null` to pause interval.
 * @see https://overreacted.io/making-setinterval-declarative-with-react-hooks/ */
export const useInterval = (callback: Function, delay: number | null) => {
  const savedCallback = useRef<Function>();

  useEffect(() => {
    savedCallback.current = callback;
  });

  useEffect(() => {
    function tick() {
      if (savedCallback.current) {
        savedCallback.current();
      }
    }

    if (delay !== null) {
      const id = setInterval(tick, delay);
      return () => clearInterval(id);
    }
  }, [delay]);
};
