import { useCallback, useEffect, useState } from "react";
import { useDebouncedCallback } from "use-debounce";
import constants from "#src/config/constants";
import { apiErrorHandler } from "#src/utils/apiErrorHandler";

// TODO: maybe we should not use this at all? Try loader and useFetcher
/**
 * Fetching & updating data from a fetch and an array of parameters
 * @param fetch the fetch function used to fetch data
 * @param arg
 */
function useFetchNoPaging<T>(
  fetch: (...arg: unknown[]) => Promise<T[]>,
  arg: Parameters<typeof fetch>
): { list: T[] | null; isLoading: boolean; updateData: () => void } {
  const [list, setList] = useState<T[] | null>(null);
  const [isLoading, setLoading] = useState<boolean>(false);

  const fetchData = useCallback(
    (
      fetch: (...arg: unknown[]) => Promise<T[]>,
      ...arg: Parameters<typeof fetch>
    ) => {
      setLoading(true);
      fetch(...arg)
        .then((response) => {
          setList(response);
          setLoading(false);
        })
        .catch(apiErrorHandler);
    },
    []
  );

  const debouncedFetchData = useDebouncedCallback(
    fetchData,
    constants.DEBOUNCE_TIME
  );

  useEffect(() => {
    debouncedFetchData(fetch, ...arg);
  }, [debouncedFetchData, fetch, arg]);

  return {
    list,
    isLoading,
    updateData: () => {
      fetchData(fetch, ...arg);
    },
  };
}

export default useFetchNoPaging;
