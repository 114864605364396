import type { IPaginateParams, IPaginatedResponse } from "#src/@types/api";
import endpoint from "#src/config/endpoint";
import request from "#src/utils/request";
import type { IAssetDetailItem } from ".";

export interface IAssetSubdomainItem {
  id: number;
  last_found: number | null;
  address: string;
  ips: string[];
  tags: { id: number; name: string }[];
  scans: number;
  vulnerabilities: number;
}

export function list_asset_subdomains(
  workspace_id: string,
  domain_id: number,
  params: {
    from: number | undefined;
    to: number | undefined;
    // Should only be 0 | 1
    vulnerable: string | undefined;
  } & IPaginateParams
) {
  return request.get<never, IPaginatedResponse<IAssetSubdomainItem>>(
    `${endpoint.WORKSPACES}/${workspace_id}/assets/${domain_id}/subdomains`,
    { params }
  );
}

export function create_subdomains(
  domain_id: number,
  workspace_id: string,
  subdomains: ({ address: string } & Partial<IAssetSubdomainItem>)[]
) {
  return request.post<never, { success: boolean }>(
    `${endpoint.WORKSPACES}/${workspace_id}/assets/${domain_id}/subdomains`,
    { subdomains }
  );
}

export function update_subdomain(
  workspace_id: string,
  domain_id: number,
  subdomain_id: number,
  data: Partial<Omit<IAssetDetailItem, "tags"> & { tags: string[] }>
) {
  return request.put(
    `${endpoint.WORKSPACES}/${workspace_id}/assets/${domain_id}/subdomains/${subdomain_id}`,
    data
  );
}

export function delete_subdomain(
  domain_id: number,
  workspace_id: string,
  subdomain_id: number
) {
  return request.delete(
    `${endpoint.WORKSPACES}/${workspace_id}/assets/${domain_id}/subdomains/${subdomain_id}`
  );
}

export function delete_multiple_subdomains(
  domain_id: number,
  workspace_id: string,
  ids: number[]
) {
  return request.post(
    `${endpoint.WORKSPACES}/${workspace_id}/assets/${domain_id}/subdomains/delete`,
    { ids }
  );
}
