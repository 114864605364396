import { useTranslation } from "react-i18next";
import { ReactComponent as FileCopyLine } from "#src/assets/images/icons/file-copy-line.svg";
import { IconButton } from "./system/Button";
import { createToast } from "./system/toasts";

const TooltipInnerWithCopy = ({ value }: { value: string }) => {
  const { t } = useTranslation("common", { keyPrefix: "copyToClipboard" });
  // copied from CopyButton
  const onClickCopy = () => {
    if (value) {
      navigator.clipboard.writeText(value);
      createToast({
        type: "info",
        message: t("copied.message"),
        detail: value,
      });
    } else {
      createToast({
        type: "info",
        message: t("cannotCopy.message"),
        detail: t("cannotCopy.description"),
      });
    }
  };

  return (
    <div className="flex items-center gap-1.5">
      {value}
      <IconButton
        size={7}
        className="bg-opacity-[48%] bg-dark-blue hover:bg-dark-blue"
        onClick={(e) => {
          e.preventDefault();
          onClickCopy();
        }}
      >
        <FileCopyLine height={"1rem"} width={"1rem"} />
      </IconButton>
    </div>
  );
};

export default TooltipInnerWithCopy;
