import { type ReactNode, useLayoutEffect, useState } from "react";

interface ISuggestionItem {
  children: ReactNode;
  syncWidthId?: string;
}

/** This is just a Dropdown Item, but meant for suggestion instead of selection */
const SuggestionItem = ({ children, syncWidthId }: ISuggestionItem) => {
  const [labelWidth, setLabelWidth] = useState<number | undefined>();

  useLayoutEffect(() => {
    const triggerElement = syncWidthId
      ? document.getElementById(syncWidthId)
      : undefined;

    if (triggerElement) {
      setLabelWidth(triggerElement.offsetWidth);
    }
  }, [syncWidthId]);

  return (
    <div
      style={syncWidthId && labelWidth ? { width: labelWidth } : undefined}
      className="flex px-6 py-2 gap-3 bg-grey-blue text-white items-center font-regular-12 min-w-[8rem] overflow-hidden text-ellipsis"
    >
      {children}
    </div>
  );
};

export default SuggestionItem;
