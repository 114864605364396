// Libraries
import { Dropdown, Tooltip } from "@lockerpm/design";
import type { ItemType } from "@lockerpm/design/es/menu/hooks/useItems";
import { useTranslation } from "react-i18next";
import dayjs from "dayjs";

// Resources
import { ReactComponent as MoreLine } from "#src/assets/images/icons/more-line.svg";
import { ReactComponent as ErrorWarningFill } from "#src/assets/images/icons/error-warning-fill.svg";

// General
import urls from "#src/config/urls";

// Components
import { RoundedTag, RoundedSeeMoreTags } from "#src/components/common/Tags";
import { ObjectImage } from "#src/components/common/system/Object";
import { TableCheckbox, TableDataCell } from "#src/components/common/Table";
import { TableRowWrapperAnchor } from "#src/components/common/Table/TableRowWrapper";
import { IconButton } from "#src/components/common/system/Button";

// API-related
import { type IAssetItem } from "#src/services/assets";

interface IAssetsIpAddressesTableRowProps {
  data: IAssetItem;
  actionList: ItemType[];
  onClickActionItems: (key: string, item: IAssetItem) => void;
  selectedIpAddressIds: number[];
  onChangeSelectedIpAddresses: (selected: IAssetItem) => void;
}

/** Row template for Assets/IpAddresses table view, since this depends on each table and cannot be built into a common template
 * @param data - data of the row, represent an IP address
 * @param actionList - list of actions that shows when the dropdown at the rightmost of the row is opened
 * @param onClickActionItems - a function to process actions from actionList
 * @param selectedIpAddressIds - a list of ids of items that has its checkbox checked
 * @param onChangeSelectedIpAddresses - control the selectedIpAddressIds list
 */
const IpAddressTableRow = ({
  data,
  actionList,
  onClickActionItems,
  selectedIpAddressIds,
  onChangeSelectedIpAddresses,
}: IAssetsIpAddressesTableRowProps) => {
  const { t } = useTranslation("assets", {
    keyPrefix: "ipAddress.page.ipAddress",
  });

  return (
    <TableRowWrapperAnchor to={`${data.id}`}>
      <TableDataCell className="p-0 justify-center">
        <TableCheckbox
          checked={selectedIpAddressIds.includes(data.id)}
          onClick={() => {
            onChangeSelectedIpAddresses(data);
          }}
        />
      </TableDataCell>
      <TableDataCell>
        <ObjectImage
          data={
            urls.IMAGE_LOGO_SERVER_URL.replace(
              ":address",
              data.detail.address
            ) + "?size=36&v=1"
          }
          className="h-6 w-6 min-w-[1.5rem]"
        />
        {data.name.length > 0 && data.name !== data.detail.address
          ? data.name + " (" + data.detail.address + ")"
          : data.detail.address}
        {data.verification ? null : (
          <Tooltip title={t("unverifiedTooltip")}>
            <div className="w-5 h-5">
              <ErrorWarningFill className="fill-fire" />
            </div>
          </Tooltip>
        )}
      </TableDataCell>
      <TableDataCell>{data.scans}</TableDataCell>
      <TableDataCell>{data.vulnerabilities}</TableDataCell>
      <TableDataCell>
        {data.last_scan_time
          ? dayjs.unix(data.last_scan_time).format("HH:mm DD.MM.YYYY")
          : "---"}
      </TableDataCell>
      <TableDataCell>
        {data.tags.length > 0 ? <RoundedTag {...data.tags[0]} /> : null}
        {data.tags.length > 1 ? (
          <RoundedSeeMoreTags tagList={data.tags.slice(1)} />
        ) : null}
      </TableDataCell>
      <TableDataCell className="p-0 justify-center">
        <Dropdown
          menu={{
            items: actionList,
            onClick: ({ key, domEvent }) => {
              domEvent.preventDefault();
              domEvent.stopPropagation();
              onClickActionItems(key, data);
            },
          }}
          trigger={["click"]}
          disabled={selectedIpAddressIds.length > 0}
        >
          <IconButton
            variant="ghost"
            size={9}
            onClick={(e) => {
              e.preventDefault();
            }}
            disabled={selectedIpAddressIds.length > 0}
          >
            <MoreLine />
          </IconButton>
        </Dropdown>
      </TableDataCell>
    </TableRowWrapperAnchor>
  );
};

export default IpAddressTableRow;
