// Libraries
import { useTranslation } from "react-i18next";
import { useLayoutEffect, useState } from "react";
import { generatePath, useNavigate, useParams } from "react-router-dom";

// Resources
import { ReactComponent as SaveLine } from "#src/assets/images/icons/save-line.svg";
import { ReactComponent as CornerDownRightLine } from "#src/assets/images/icons/corner-down-right-line.svg";

// General
import { pathname } from "#src/config/pathname";

// Components
import { Button } from "#src/components/common/system/Button";
import { ContentSection, StickySection } from "#src/layouts/content";
import MarkdownEditor from "#src/components/common/MarkdownEditor";
import { Input } from "#src/components/common/system/Input";
import { generateKeyString } from "#src/utils/common";

// API-related
import pentestServices from "#src/services/pentest";
import {
  pentestScopeScopeItems,
  pentestScopeTypeItems,
} from "#src/config/filter/pentest";

// Children
import AddPentestMssp from "#src/components/pentest/AddPentest/Mssp";
import AddPentestScope, {
  type IPentestScopeInputItem,
} from "#src/components/pentest/AddPentest/Scope";
import AddPentestColorAndLogo from "#src/components/pentest/AddPentest/ColorAndLogo";

const AddPentest = () => {
  const { t } = useTranslation("pentest", { keyPrefix: "page.addPentest" });

  const { workspace: workspaceId } = useParams<"workspace">();
  const navigate = useNavigate();

  const [name, setName] = useState<string>("");
  const [scope, setScope] = useState<IPentestScopeInputItem[]>([]);
  const [policy, setPolicy] = useState<string>("");
  // Magic string is just default color. Not anything particular
  const [color, setColor] = useState<string>("#e7e8ea");
  const [avatarUrl, setAvatarUrl] = useState<string | null>(null);

  // Check if there are any scopes with empty asset names
  const [invalidAssets, setInvalidAssets] = useState<boolean>(false);

  const onChangeColor = (value: string) => {
    setColor(value);
  };

  const onChangeAvatarUrl = (value: string) => {
    setAvatarUrl(value);
  };

  const deactivateEditScope = (id: number) => {
    if (scope.some((item, index) => !item.assets && index === id)) {
      setInvalidAssets(true);
      return;
    }

    setScope((prev) => {
      const newArr = prev.map((item, index) =>
        index === id ? { ...item, editing: false } : item
      );

      return newArr;
    });
  };

  const activateEditScope = (id: number) => {
    setScope((prev) => {
      const newArr = prev.map((item, index) =>
        index === id ? { ...item, editing: true } : item
      );

      return newArr;
    });
  };

  const addScope = () => {
    setScope((prev) => [
      ...prev,
      {
        id: generateKeyString(),
        assetType: pentestScopeTypeItems[0],
        assets: "",
        scope: pentestScopeScopeItems[0],
        editing: true,
      },
    ]);
  };

  const removeScope = (id: number) => {
    if (scope.some((item, index) => item.assets || index === id)) {
      setInvalidAssets(false);
    }

    setScope((prev) => [...prev.slice(0, id), ...prev.slice(id + 1)]);
  };

  const onChangeScopeAssetType = (id: number, key: string) => {
    const selected = pentestScopeTypeItems.find((item) => item.key === key);

    if (selected) {
      setScope((prev) =>
        prev.map((item, index) =>
          index === id ? { ...item, assetType: selected } : item
        )
      );
    }
  };

  const onChangeScopeAssets = (id: number, value: string) => {
    if (invalidAssets) {
      setInvalidAssets(false);
    }

    setScope((prev) =>
      prev.map((item, index) =>
        index === id ? { ...item, assets: value } : item
      )
    );
  };

  const onChangeScopeScope = (id: number, key: string) => {
    const selected = pentestScopeScopeItems.find((item) => item.key === key);

    if (selected) {
      setScope((prev) =>
        prev.map((item, index) =>
          index === id ? { ...item, scope: selected } : item
        )
      );
    }
  };

  const createProjectAsDraft = () => {
    if (!workspaceId) return;

    // If you change this, you should probably change requestPentest too
    pentestServices
      .create_pentest_project(workspaceId, {
        name: name,
        color: color,
        targets: scope
          // Just in case someone make stupid changes. Do not remove this without checking the note 3 lines below and make sure Typescript is working properly.
          .filter(
            (scopeItem) =>
              scopeItem.assetType.value !== undefined &&
              scopeItem.scope.value !== undefined
          )
          .map((scopeItem) => ({
            name: scopeItem.assets,
            // These ("type" & "scope") should never be "undefined" as we have filtered it 3 lines above, so we can safely assert type.
            type: scopeItem.assetType.value as string,
            scope: scopeItem.scope.value as string,
            link_download: "",
          })),
        metadata: { en: policy, vi: policy },
        // TODO: fetch mssp from resource and use that id instead of this hard fixed
        mssp_id: "bshp7g",
        logo: avatarUrl ? avatarUrl : undefined,
      })
      .then(() => {
        navigate({
          pathname: generatePath(pathname.PENTEST, {
            workspace: workspaceId,
          }),
        });
      });
  };

  const requestPentest = () => {
    if (!workspaceId) return;

    // If you change this, you should probably change createProjectAsDraft too
    pentestServices
      .create_pentest_project(workspaceId, {
        name: name,
        color: color,
        targets: scope
          // Just in case someone make stupid changes. Do not remove this without checking the note 3 lines below and make sure Typescript is working properly.
          .filter(
            (scopeItem) =>
              scopeItem.assetType.value !== undefined &&
              scopeItem.scope.value !== undefined
          )
          .map((scopeItem) => ({
            name: scopeItem.assets,
            // These ("type" & "scope") should never be "undefined" as we have filtered it 3 lines above, so we can safely assert type.
            type: scopeItem.assetType.value as string,
            scope: scopeItem.scope.value as string,
            link_download: "",
          })),
        metadata: { en: policy, vi: policy },
        // TODO: fetch mssp from resource and use that id instead of this hard fixed
        mssp_id: "bshp7g",
        logo: avatarUrl ? avatarUrl : undefined,
      })
      .then((response) => {
        pentestServices
          .submit_pentest_project(workspaceId, response.alias)
          .then(() => {
            navigate({
              pathname: generatePath(pathname.PENTEST, {
                workspace: workspaceId,
              }),
            });
          });
      });
  };

  const [headerHeight, setHeaderHeight] = useState<number>(0);

  useLayoutEffect(() => {
    const stickySection = document.getElementById("STICKY_SECTION");
    if (stickySection) {
      const computedStyle = getComputedStyle(stickySection);
      setHeaderHeight(
        stickySection.getBoundingClientRect().height +
          parseInt(computedStyle.marginBottom)
      );
    }
  }, []);

  return (
    <>
      <StickySection>
        <h1>{t("title")}</h1>
        <div className="flex gap-1">
          <Button
            size="large"
            variant="secondary"
            onClick={createProjectAsDraft}
            disabled={!name || scope.some((item) => !item.assets)}
          >
            <SaveLine className="h-5 w-5" />
            {t("button.saveAsDraft")}
          </Button>
          <Button
            size="large"
            onClick={requestPentest}
            disabled={!name || scope.some((item) => !item.assets)}
          >
            <CornerDownRightLine className="h-5 w-5" />
            {t("button.requestPentest")}
          </Button>
        </div>
      </StickySection>
      <ContentSection className="grid grid-cols-3">
        <div className="flex flex-col gap-12 col-span-2">
          <div className="flex flex-col gap-4 w-1/2 min-w-[24rem]">
            <h2>{t("projectName.title")}</h2>
            <Input
              value={name}
              onChange={(e) => {
                setName(e.currentTarget.value);
              }}
              placeholder={t("projectName.placeholder")}
            />
          </div>
          <AddPentestMssp />
          <AddPentestScope
            scope={scope}
            invalidAssets={invalidAssets}
            activateEditScope={activateEditScope}
            deactivateEditScope={deactivateEditScope}
            addScope={addScope}
            removeScope={removeScope}
            onChangeScopeAssetType={onChangeScopeAssetType}
            onChangeScopeAssets={onChangeScopeAssets}
            onChangeScopeScope={onChangeScopeScope}
          />
          <div className="flex flex-col gap-4">
            <h2>{t("policy.title")}</h2>
            <span className="text-hard-grey">{t("policy.instruction")}</span>
            <MarkdownEditor
              className="font-regular-14-body bg-bright-blue"
              value={policy}
              onChange={(e) => {
                setPolicy(e.target.value);
              }}
            />
          </div>
        </div>
        <div
          style={headerHeight ? { top: headerHeight } : undefined}
          className="h-fit flex flex-col sticky"
        >
          <AddPentestColorAndLogo
            projectName={name}
            color={color}
            avatarUrl={avatarUrl}
            onChangeColor={onChangeColor}
            onChangeAvatarUrl={onChangeAvatarUrl}
          />
        </div>
      </ContentSection>
    </>
  );
};

export default AddPentest;
