// Libraries
import { useTranslation } from "react-i18next";
import { Tabs } from "@lockerpm/design";
import { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";

// Resources

// Components
import { ContentSection, StickySection } from "#src/layouts/content";
import TabButtonLabel from "#src/components/common/helper/antdProps/Tabs/TabButtonLabel";
import LoadingState from "#src/components/common/system/LoadingState";
import NoResultsState from "#src/components/common/states/NoResultsState";

// API-related
import trustCenterServices from "#src/services/trustCenter";

// Children
import TrustCenterConfigWebsite from "#src/components/trustCenter/config/WebsiteTab";
import TrustCenterConfigProfile from "#src/components/trustCenter/config/ProfileTab";
import TrustCenterConfigSecurityProfile from "#src/components/trustCenter/config/ProfileTab/Security";
import type { ITrustCenterConfigDetails } from "#src/services/trustCenter/configuration";

const TrustCenterConfig = () => {
  const { t } = useTranslation("trustCenter", { keyPrefix: "configuration" });

  const { workspace: workspaceId } = useParams<"workspace">();

  enum TrustCenterSettingEnum {
    WEBSITE = "TRUST_CENTER_SETTING_ENUM_WEBSITE",
    PROFILE = "TRUST_CENTER_SETTING_ENUM_PROFILE",
  }

  const [activeTab, setActiveTab] = useState<TrustCenterSettingEnum>(
    TrustCenterSettingEnum.WEBSITE
  );

  const [originalSettings, setOriginalSettings] =
    useState<ITrustCenterConfigDetails | null>(null);
  const [access, setAccess] = useState<{ isPublic: boolean; alias: string }>({
    isPublic: false,
    alias: "",
  });
  const [analytic, setAnalytic] = useState<{ custom_ga_tag_id: string }>({
    custom_ga_tag_id: "",
  });
  const [domain, setDomain] = useState<string>("");
  const [isLoadingProfile, setLoadingProfile] = useState<boolean>(false);

  const tabList = [
    {
      key: TrustCenterSettingEnum.WEBSITE,
      label: <TabButtonLabel name={t("tab.website.title")} />,
    },
    {
      key: TrustCenterSettingEnum.PROFILE,
      label: <TabButtonLabel name={t("tab.profile.title")} />,
    },
  ];

  const onChangeTab = (key: TrustCenterSettingEnum) => {
    setActiveTab(key);
  };

  const fetchConfigs = useCallback(() => {
    if (workspaceId) {
      trustCenterServices
        .retrieve_trust_center_config(workspaceId)
        .then((response) => {
          setOriginalSettings(response);
          setAccess({
            isPublic: response.access.is_public,
            alias: response.access.alias,
          });
          setAnalytic({
            custom_ga_tag_id: response.analytic.custom_ga_tag_id || "",
          });
          setDomain(response.domain ?? "");
        });
    }
  }, [workspaceId]);

  if (!workspaceId || workspaceId === "null") {
    return <NoResultsState />;
  }

  useEffect(() => {
    setLoadingProfile(true);
    fetchConfigs();
    setLoadingProfile(false);
  }, [fetchConfigs]);

  return (
    <>
      <StickySection className="flex-col">
        <h1>{t("title")}</h1>
        <div className="flex justify-between w-full">
          <Tabs items={tabList} activeKey={activeTab} onChange={onChangeTab} />
        </div>
      </StickySection>
      <ContentSection>
        {originalSettings === null || isLoadingProfile ? (
          <LoadingState />
        ) : (
          <>
            {activeTab === TrustCenterSettingEnum.WEBSITE ? (
              <TrustCenterConfigWebsite
                workspaceId={workspaceId}
                originalSettings={originalSettings}
                onRefresh={() => {
                  fetchConfigs();
                }}
                access={access}
                setAccess={setAccess}
                domain={domain}
                setDomain={setDomain}
                analytic={analytic}
                setAnalytic={setAnalytic}
              />
            ) : null}
            {activeTab === TrustCenterSettingEnum.PROFILE ? (
              <>
                <TrustCenterConfigProfile
                  workspaceId={workspaceId}
                  originalSettings={originalSettings}
                  setOriginalSettings={setOriginalSettings}
                />
                <TrustCenterConfigSecurityProfile
                  workspaceId={workspaceId}
                  originalSettings={originalSettings}
                  setOriginalSettings={setOriginalSettings}
                />
              </>
            ) : null}
          </>
        )}
      </ContentSection>
    </>
  );
};

export default TrustCenterConfig;
