// Libraries
import { useTranslation } from "react-i18next";

// Components
import LoadingState from "#src/components/common/system/LoadingState";
import { expandPlanDuration } from "#src/utils/apiHelper";

// API-related
import type { IProductPlan } from "#src/services/resource";

// Children
import PlanItem from "./PlanItem";
import OldPlanItem from "./OldPlanItem";

interface ITrustCenterCustomDomainChoosePlanProps {
  planList: IProductPlan["recurring_prices"] | null;
  isLoading: boolean;
  selectedPlan: IProductPlan["recurring_prices"][number] | null;
  onChangeSelectedPlan: (
    value: IProductPlan["recurring_prices"][number]
  ) => void;
  oldPlanDuration?: string;
}

const TrustCenterCustomDomainChoosePlan = ({
  planList,
  isLoading,
  selectedPlan,
  onChangeSelectedPlan,
  oldPlanDuration,
}: ITrustCenterCustomDomainChoosePlanProps) => {
  const { t } = useTranslation("trustCenter", {
    keyPrefix: "registerDomain.chooseAPlan",
  });

  return (
    <div className="flex flex-col gap-6">
      <div className="flex flex-col gap-4">
        <h2>{t("title")}</h2>
        <p className="text-hard-grey">{t("description")}</p>
      </div>
      {isLoading || planList === null ? (
        <LoadingState />
      ) : (
        <div className="p-6 flex gap-6">
          {planList
            .map(expandPlanDuration)
            .filter(<T,>(p: T): p is Exclude<T, undefined> => p !== undefined)
            .map((plan) =>
              oldPlanDuration === plan.duration ? (
                <OldPlanItem
                  key={`productPlan-choosePlan-${plan.duration}`}
                  getName={plan.getTitle}
                  price={plan.monthly_vnd}
                />
              ) : (
                <PlanItem
                  key={`productPlan-choosePlan-${plan.duration}`}
                  getName={plan.getTitle}
                  price={plan.monthly_vnd}
                  getTag={plan.getDecorator}
                  selected={selectedPlan?.duration === plan.duration}
                  onClickItem={() => {
                    onChangeSelectedPlan(plan);
                  }}
                />
              )
            )}
        </div>
      )}
    </div>
  );
};

export default TrustCenterCustomDomainChoosePlan;
