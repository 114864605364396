// Libraries
import { type Dispatch, type SetStateAction, useState } from "react";
import { useTranslation } from "react-i18next";

// Resources
import { ReactComponent as LoginBoxLine } from "#src/assets/images/icons/login-box-line.svg";
import { ReactComponent as LinkIcon } from "#src/assets/images/icons/link.svg";
import { ReactComponent as EditLine } from "#src/assets/images/icons/edit-line.svg";

// General
import constants from "#src/config/constants";

// Components
import { Button } from "#src/components/common/system/Button";

// API-related
import type { ITrustCenterConfigDetails } from "#src/services/trustCenter/configuration";

// Children
import EditPathDrawer from "./EditPathDrawer";

interface ITrustCenterConfigWebsiteAccessProps {
  workspaceId: string;
  originalSettings: ITrustCenterConfigDetails;
  onRefresh: () => void;
  access: { isPublic: boolean; alias: string };
  setAccess: Dispatch<SetStateAction<{ isPublic: boolean; alias: string }>>;
}

const TrustCenterConfigWebsiteAccess = ({
  workspaceId,
  originalSettings,
  onRefresh,
  access,
  setAccess,
}: ITrustCenterConfigWebsiteAccessProps) => {
  const { t } = useTranslation("trustCenter", {
    keyPrefix: "configuration.tab.website.accessPermission",
  });

  const [openPathEditDrawer, setOpenPathEditDrawer] = useState<boolean>(false);

  return (
    <>
      <div className="flex flex-col border border-light-grey rounded-md">
        <div className="p-6 flex justify-between rounded-md bg-bright-grey">
          <div className="flex items-center gap-3">
            <LoginBoxLine className="h-7 w-7" />
            <h2>{t("title")}</h2>
          </div>
        </div>
        <div className="flex flex-col gap-6 p-6">
          {/* <div className="flex flex-col gap-3">
      <div className="flex justify-between items-center gap-6">
      <span className="font-medium-18">
      {t(.publicAccess.label")}
      </span>
      <Switch
      checked={originalSettings.access.is_public}
          onClick={onChangePublicState}
          />
      </div>
      <span className="font-regular-16-body text-hard-grey">
      {t(.publicAccess.info")}
      </span>
      </div> */}
          <div className="flex flex-wrap items-center justify-between gap-3">
            <div className="flex items-center gap-3">
              <span className="font-medium-18">{t("link") + ":"}</span>
              <div className="flex gap-2 text-primary">
                <LinkIcon className="h-5 w-5" />
                <a
                  href={
                    constants.TRUST_CENTER_URL_BASE +
                    originalSettings.access.alias
                  }
                  target="_blank"
                  rel="noreferrer"
                >
                  {constants.TRUST_CENTER_URL_BASE}
                  {originalSettings.access.alias}
                </a>
              </div>
            </div>
            <Button
              variant="secondary"
              onClick={() => {
                setOpenPathEditDrawer(true);
              }}
            >
              <EditLine />
              {t("button.edit")}
            </Button>
          </div>
        </div>
      </div>
      <EditPathDrawer
        workspaceId={workspaceId}
        open={openPathEditDrawer}
        onClose={() => {
          setOpenPathEditDrawer(false);
        }}
        onRefresh={onRefresh}
        access={access}
        setAccess={setAccess}
        originalSettings={originalSettings}
      />
    </>
  );
};

export default TrustCenterConfigWebsiteAccess;
