import request from "../utils/request";
import endpoint from "../config/endpoint";
import type { IPaginateParams, IPaginatedResponse } from "#src/@types/api";

export interface IWorkspaceItem {
  id: string;
  name: string;
  type: string;
  created_time: number;
  updated_time: null | number;
  description_vi: string;
  description_en: string;
  description: {
    en: string;
    vi: string;
  };
  logo: string | null;
  color: string;
  social: { name: string; url: string }[];
  technologies: string[];
  email: string;
  website: string;
  phone: string;
  address: string;
  locked: boolean;
  role: string;
  vat_tax_code: string;
  short_name: string;
  employee_size: string;
  industry: string;
  country_code: string;
}

function list_workspaces() {
  return request.get<never, IWorkspaceItem[]>(endpoint.WORKSPACES, {
    params: { paging: 0, strictly: 1 },
  });
}

function retrieve_workspace(id: string) {
  return request.get<never, IWorkspaceItem>(`${endpoint.WORKSPACES}/${id}`);
}

function create_workspace(params: {
  name: string;
  description_en: string;
  description_vn: string;
  website: string;
  address: string;
  phone: string;
  email: string;
  logo: string;
}) {
  return request.post<never, { id: string }>(endpoint.WORKSPACES, params);
}

function update_workspace(
  id: string,
  data: Partial<Omit<IWorkspaceItem, "logo">>
) {
  return request.put<never, { id: string }>(
    `${endpoint.WORKSPACES}/${id}`,
    data
  );
}

function update_workspace_logo(id: string, logoUrl: string) {
  return request.put<never, { id: string }>(
    `${endpoint.WORKSPACES}/${id}/logo`,
    { logo: logoUrl }
  );
}

function delete_workspace(id: string) {
  return request.delete<never, { id: string }>(`${endpoint.WORKSPACES}/${id}`);
}

// function access_logs(id: any, params = {}) {
//   return request({
//     url: endpoint.WORKSPACE_ACCESS_LOGS.replace(":id", id),
//     method: "get",
//     params,
//   });
// }

export interface IWorkspaceMember {
  id: number;
  access_time: number;
  role: "member" | string;
  is_default: boolean;
  is_primary: boolean;
  email: string | null;
  // We can add some non-user members, so this "user" field is optional
  user?: {
    email: string;
    username: string;
    full_name: string;
    avatar: string;
  };
}

function list_members(
  workspace_id: string,
  params: {
    roles: string | undefined;
  } & IPaginateParams
) {
  return request.get<never, IPaginatedResponse<IWorkspaceMember>>(
    `${endpoint.WORKSPACES}/${workspace_id}/members`,
    { params }
  );
}

function add_member(
  workspace_id: string,
  members: { email: string; role: string }[]
) {
  return request.post<never, { success: true }>(
    `${endpoint.WORKSPACES}/${workspace_id}/members`,
    {
      members,
    }
  );
}

function remove_member(workspace_id: string, member_id: number) {
  return request.delete<never, { role: string }>(
    `${endpoint.WORKSPACES}/${workspace_id}/members/${member_id}`
  );
}

function update_member(
  workspace_id: string,
  member_id: number,
  data: { role?: string }
) {
  return request.put<never, { success: boolean }>(
    `${endpoint.WORKSPACES}/${workspace_id}/members/${member_id}`,
    data
  );
}

// function reinvite(id: any, memberId: any) {
//   return request({
//     url: endpoint.WORKSPACE_MEMBER_REINVITE.replace(":id", id).replace(
//       ":member_id",
//       memberId
//     ),
//     method: "post",
//   });
// }

const workspaceServices = {
  list_workspaces,
  retrieve_workspace,
  create_workspace,
  update_workspace,
  update_workspace_logo,
  delete_workspace,
  // access_logs,
  list_members,
  add_member,
  remove_member,
  update_member,
  // reinvite,
};

export default workspaceServices;
