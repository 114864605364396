import type { ReactNode } from "react";

interface IOverviewSubCardProps {
  items: {
    label: ReactNode;
    value: number | undefined;
  }[];
}

/** A card with low contrast border, no background color, auto-stretching horizontally and split the list of stats into pairs
 * @param items - list of stats. The list will be automatically divided
 */
export const OverviewSubCard = ({ items }: IOverviewSubCardProps) => {
  const itemsIntoPairs = items.flatMap((_, index, itemArr) =>
    index % 2 ? [] : [itemArr.slice(index, index + 2)]
  );
  return (
    <div className="flex-1 flex flex-col p-6 gap-x-10 gap-y-2 border border-grey rounded-md whitespace-pre-line">
      {itemsIntoPairs.map((pair, index) =>
        pair.map((item, itemId) => (
          <div
            className="flex items-center justify-between gap-4"
            key={`overview-sub_${index}-${itemId}`}
          >
            <span className="font-medium-18 overflow-hidden whitespace-nowrap text-ellipsis">
              {item.label}
            </span>
            <span className="font-bold-24 tabular-nums">{item.value}</span>
          </div>
        ))
      )}
    </div>
  );
};

export default OverviewSubCard;
