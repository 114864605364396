import { type HTMLAttributes, useLayoutEffect, useRef, useState } from "react";
import { twMerge } from "tailwind-merge";
import { generateKeyString } from "#src/utils/common";

interface IProgressBarProps extends HTMLAttributes<HTMLDivElement> {
  progress: number;
  bgColor?: string;
  progressColor?: string;
}

const ProgressBar = ({
  progress,
  bgColor,
  progressColor,
  ...props
}: IProgressBarProps) => {
  const chartId = props.id ? props.id : generateKeyString();

  const ref = useRef<HTMLDivElement>(null);
  const [barWidth, setBarWidth] = useState<number>(0);

  useLayoutEffect(() => {
    // TODO: Add debounce
    const measureBarWidth = () => {
      setBarWidth(ref.current?.getBoundingClientRect().width || 0);
    };

    measureBarWidth();
    window.addEventListener("resize", measureBarWidth);

    return () => {
      window.removeEventListener("resize", measureBarWidth);
    };
  }, []);

  const progressWidth = (progress * barWidth) / 100;

  return (
    <div
      ref={ref}
      id={`progressBar-${chartId}`}
      {...props}
      className={twMerge(
        `w-full h-full flex rounded-[0.25rem] bg-bright-blue`,
        bgColor,
        props.className
      )}
    >
      {
        progress ? (
          <div
            style={{ width: progressWidth }}
            className={twMerge(
              `flex items-center justify-center font-medium-14-forced bg-blue text-white rounded-[0.25rem]`,
              progressColor
            )}
            key={`progressBar-${chartId}`}
          >
            {/* 21 is 1.5rem */}
            {progressWidth > 21 ? `${progress.toFixed(0)}%` : null}
          </div>
        ) : null
        // <div className="flex justify-center font-medium-14-forced bg-transparent text-white w-full">
        //   0
        // </div>
      }
    </div>
  );
};

export default ProgressBar;
