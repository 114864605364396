import type { ReactNode } from "react";

interface IBillingOverviewCardProps {
  title: ReactNode;
  content?: ReactNode;
  value: string;
  bgTwColor: string;
  markerTwColor: string;
}

const BillingOverviewCard = ({
  title,
  content,
  value,
  bgTwColor,
  markerTwColor,
}: IBillingOverviewCardProps) => {
  return (
    <div
      className={`${bgTwColor} min-w-[17rem] w-1/3 flex justify-between gap-3 px-6 rounded-md whitespace-pre-line`}
    >
      <div className="py-5 flex flex-col">
        <span className="font-medium-20 h-[2.375rem] flex items-center">
          {title}
        </span>
        {content}
      </div>
      <span className="relative h-full">
        {/* Marker. This should be placed on top of the value */}
        <span
          className={`absolute -right-1 w-[calc(100%_+_0.5rem)] ${markerTwColor} h-1.5 rounded-b-full`}
        />
        <span className="relative top-5 font-bold-32 tracking-tight tabular-nums">
          {value}
        </span>
      </span>
    </div>
  );
};

export default BillingOverviewCard;
