// Libraries
import { useMemo, useState } from "react";

// General
import type { IFilterItem } from "#src/@types/common";
import constants from "#src/config/constants";

// Components
import useFetchPaginated from "#src/hooks/useFetchPaginated";
import PaginationBar from "#src/components/common/helper/PaginationBar";

// API-related
import trustCenterServices from "#src/services/trustCenter";

// Children
import TrustCenterPolicyFilter from "./Filter";
import TrustCenterPolicyTableGrid from "./Table";

interface ITrustCenterPolicyTableProps {
  workspaceId: string;
}

const TrustCenterPolicyTable = ({
  workspaceId,
}: ITrustCenterPolicyTableProps) => {
  const [compliance, setCompliance] = useState<IFilterItem | null>(null);
  const [status, setStatus] = useState<IFilterItem | null>(null);
  const [from, setFrom] = useState<number | null>(null);
  const [to, setTo] = useState<number | null>(null);
  const [selectedPage, setSelectedPage] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(constants.DEFAULT_PAGE_SIZE);
  const [searchKeyword, setSearchKeyword] = useState<string>("");

  const onChangeComplianceSelection = (selected: typeof compliance) => {
    setCompliance(selected);
    setSelectedPage(1);
  };

  const onChangeStatusSelection = (selected: typeof status) => {
    setStatus(selected);
    setSelectedPage(1);
  };

  const onChangeTimeConditionValue = (
    condition: string,
    from: number | null,
    to: number | null
  ) => {
    if (condition) {
      setFrom(from);
      setTo(to);
    } else {
      setFrom(null);
      setTo(null);
    }
    setSelectedPage(1);
  };

  const onChangePage = (page: number) => {
    setSelectedPage(page);
  };

  const onChangePageSize = (size: number) => {
    setPageSize(size);
    setSelectedPage(1);
  };

  const onChangeSearchKeyword = (keyword: string) => {
    setSearchKeyword(keyword);
    setSelectedPage(1);
  };

  const fetchParams = useMemo<
    Parameters<typeof trustCenterServices.list_trust_center_policy>
  >(
    () => [
      workspaceId,
      {
        published_time_from: from ?? undefined,
        published_time_to: to ?? undefined,
        compliance_id: compliance?.value,
        status: status?.value,
        q: searchKeyword,
        page: selectedPage,
        size: pageSize,
      },
    ],
    [
      workspaceId,
      from,
      to,
      compliance,
      status,
      searchKeyword,
      selectedPage,
      pageSize,
    ]
  );

  const {
    list: policyList,
    count: policyCount,
    isLoading,
    updateData: updatePolicyList,
  } = useFetchPaginated(
    trustCenterServices.list_trust_center_policy,
    fetchParams
  );

  return (
    <>
      <TrustCenterPolicyFilter
        compliance={compliance}
        status={status}
        searchKeyword={searchKeyword}
        onChangeComplianceSelection={onChangeComplianceSelection}
        onChangeStatusSelection={onChangeStatusSelection}
        onChangeTimeConditionValue={onChangeTimeConditionValue}
        onChangeSearchKeyword={onChangeSearchKeyword}
      />
      <TrustCenterPolicyTableGrid
        workspaceId={workspaceId}
        isLoading={isLoading}
        policyList={policyList}
        onRefresh={updatePolicyList}
      />
      <PaginationBar
        numOfResult={policyCount}
        selectedPage={selectedPage}
        pageSize={pageSize}
        onChangePage={onChangePage}
        onChangePageSize={onChangePageSize}
      />
    </>
  );
};

export default TrustCenterPolicyTable;
