// Libraries
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";

// Resources
import { ReactComponent as LinkIcon } from "#src/assets/images/icons/link.svg";
import { ReactComponent as ImageCircleFill } from "#src/assets/images/icons/image-circle-fill.svg";

// Components
import LoadingState from "#src/components/common/system/LoadingState";
import { ObjectImage } from "#src/components/common/system/Object";

// API-related
import msspBugBountyServices from "#src/services/mssp/bugBounty";

// Children
import type { IBugBountyDetails } from "#src/services/bugBounty";

interface IMsspBugBountyDetailsOrganizationProps {
  workspaceId: string;
  bugBountyAlias: string;
}

const MsspBugBountyDetailsOrganization = ({
  workspaceId,
  bugBountyAlias,
}: IMsspBugBountyDetailsOrganizationProps) => {
  const { t } = useTranslation("bugBounty", {
    keyPrefix: "page.bugBountyDetails.tab.mssp",
  });

  const [organization, setOrganization] = useState<
    IBugBountyDetails["org"] | null
  >(null);
  const [isLoading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      const response =
        await msspBugBountyServices.retrieve_mssp_program_organization(
          workspaceId,
          bugBountyAlias
        );

      setOrganization(response);
      return;
    };
    fetchData();
    setLoading(false);
  }, [workspaceId, bugBountyAlias]);

  return (
    <section className="flex flex-col gap-6 pt-6">
      <h2>{t("basicInformation.title")}</h2>
      {organization === null || isLoading ? (
        <LoadingState />
      ) : (
        <div className="bg-bright-grey rounded-md py-6 flex">
          <div className="flex-1 p-6 flex flex-col gap-6">
            <div className="flex flex-col gap-2">
              <div className="h-[10.875rem] relative">
                <div
                  className="h-28 rounded-md"
                  style={{ backgroundColor: organization.color }}
                />
                <div className="p-4 absolute bottom-0 left-1/2 -translate-x-1/2">
                  <div className="border-[3px] border-white rounded-md bg-bright-grey h-28 w-28 flex items-center justify-center">
                    <ObjectImage
                      data={organization.logo}
                      className="h-[5.5rem] w-[5.5rem]"
                    >
                      <ImageCircleFill className="h-[2.75rem] w-[2.75rem] fill-medium-grey" />
                    </ObjectImage>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="flex-1 p-6 flex flex-col gap-6">
            <div className="flex flex-col gap-3">
              <span className="text-hard-grey">
                {t("basicInformation.companyName")}
              </span>
              <span className="font-medium-16">
                {organization.name ? organization.name : "N/A"}
              </span>
            </div>
            {/* <div className="flex flex-col gap-3">
              <span className="text-hard-grey">
                {t("basicInformation.websiteUrl")}
              </span>
              <span className="flex items-center gap-2 text-primary">
                <LinkIcon height={"1.25rem"} width={"1.25rem"} />
                https://@cystack.net/
              </span>
            </div>
            <div className="flex flex-col gap-3">
              <span className="text-hard-grey">
                {t("basicInformation.contactUs")}
              </span>
              <span className="flex items-center gap-2 text-primary">
                <LinkIcon height={"1.25rem"} width={"1.25rem"} />
                https://@cystack.net/contact
              </span>
            </div> */}
            <div className="flex flex-col gap-3">
              <span className="text-hard-grey">
                {t("basicInformation.companyOwnerEmail")}
              </span>
              {organization.email ? (
                <span className="flex items-center gap-2 text-primary">
                  <LinkIcon height={"1.25rem"} width={"1.25rem"} />
                  {organization.email}
                </span>
              ) : (
                <span className="font-medium-16">N/A</span>
              )}
            </div>
          </div>
          <div className="flex-1 p-6 flex flex-col gap-6">
            <div className="flex flex-col gap-3">
              <span className="text-hard-grey">
                {t("basicInformation.description")}
              </span>
              <span className="font-medium-16">
                {organization.description.en
                  ? organization.description.en
                  : "N/A"}
              </span>
            </div>
            {/* {organization.technologies.length === 0 ? null : (
              <div className="flex flex-col gap-3">
                <span className="text-hard-grey">
                  {t("basicInformation.technologies")}
                </span>
                <div className="flex gap-1">
                  {organization.technologies.map((technology) => (
                    <RoundedTag
                      key={`bugBounty-mssp-technology-${technology}`}
                      name={technology}
                      legendId={getTagColor(technology)}
                    />
                  ))}
                </div>
              </div>
            )} */}
          </div>
        </div>
      )}
    </section>
  );
};

export default MsspBugBountyDetailsOrganization;
