// Libraries
import { useState } from "react";
import { useTranslation } from "react-i18next";

// Resources
import { ReactComponent as WarningDiamond } from "#src/assets/images/icons/warning-diamond.svg";

// Components
import { Button } from "#src/components/common/system/Button";
import { apiErrorHandler } from "#src/utils/apiErrorHandler";

// API-related
import trustCenterServices from "#src/services/trustCenter";
import type { ITrustCenterConfigDetails } from "#src/services/trustCenter/configuration";

// Children
import DeactivateModal from "./modals/DeactivateModal";

interface ITrustCenterConfigWebsiteDangerZoneProps {
  workspaceId: string;
  originalSettings: ITrustCenterConfigDetails;
}

const TrustCenterConfigWebsiteDangerZone = ({
  workspaceId,
  originalSettings,
}: ITrustCenterConfigWebsiteDangerZoneProps) => {
  const { t } = useTranslation("trustCenter", {
    keyPrefix: "configuration.tab.website",
  });

  const [openDeactivateModal, setOpenDeactivateModal] =
    useState<boolean>(false);

  const onClickDeactivateTrustCenter = () => {
    trustCenterServices
      .update_trust_center_config_activate(workspaceId, {
        // TODO: this should be deactivate only
        is_activate: !originalSettings.is_activate,
      })
      .then(() => {
        // TODO: throw user to the "landing" page and disable access to other pages
      })
      .catch((error) => {
        apiErrorHandler(error, {
          toastMessage: t("notification.activateTrustCenter.fail"),
        });
      });
  };

  return (
    <>
      <div className="flex flex-col border border-warning-red-40% rounded-md">
        <div className="p-6 flex justify-between rounded-md bg-warning-red-5%">
          <div className="flex items-center gap-3">
            <WarningDiamond className="h-7 w-7" />
            <h2>{t("dangerZone.title")}</h2>
          </div>
          <Button
            variant="danger"
            onClick={() => {
              setOpenDeactivateModal(true);
            }}
          >
            {t("dangerZone.button.deactivate")}
          </Button>
        </div>
        <div className="flex flex-col gap-3 p-6">
          <span className="font-medium-18">
            {t("dangerZone.webTrustCenter.label")}
          </span>
          <span className="font-regular-16-body text-hard-grey">
            {t("dangerZone.webTrustCenter.info")}
          </span>
        </div>
      </div>
      <DeactivateModal
        open={openDeactivateModal}
        onChangeOpen={(value) => {
          setOpenDeactivateModal(value);
        }}
        onOk={onClickDeactivateTrustCenter}
        workspaceId={workspaceId}
      />
    </>
  );
};

export default TrustCenterConfigWebsiteDangerZone;
