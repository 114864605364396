// Libraries
import { type Dispatch, type SetStateAction } from "react";
import { useTranslation } from "react-i18next";
import { Dropdown } from "@lockerpm/design";
import i18next from "i18next";

// Resources
import { ReactComponent as ArrowDownSLine } from "#src/assets/images/icons/arrow-down-s-line.svg";
import { ReactComponent as EditLine } from "#src/assets/images/icons/edit-line.svg";

// General
import patterns from "#src/config/patterns";

// Components
import { InputFieldWrapper } from "#src/components/common/composed/divWrappers/InputFieldWrapper";
import DropdownItemLabel from "#src/components/common/helper/antdProps/Dropdown/DropdownItemLabel";
import DropdownTriggerButton from "#src/components/common/helper/antdProps/Dropdown/DropdownTriggerButton";
import { InputField } from "#src/components/common/helper/wrapper/InputField";
import { Button, IconButton } from "#src/components/common/system/Button";

// Children
import {
  ContactFormSelection,
  jobTitleList,
} from "#src/components/trustCenter/onboard/ContactForm/contactDetailFields";
import type { IUpdatePaymentSettingsErrorObj } from "./helper";

interface IBillingConfigCustomerProps {
  customer: {
    fullName: string;
    phoneNumber: string;
    jobTitle: string;
    email: string;
  };
  setCustomer: Dispatch<
    SetStateAction<{
      fullName: string;
      phoneNumber: string;
      jobTitle: string;
      email: string;
    }>
  >;
  jobTitle: ContactFormSelection;
  setJobTitle: Dispatch<SetStateAction<ContactFormSelection>>;
  errorObj: IUpdatePaymentSettingsErrorObj;
  setErrorObj: Dispatch<SetStateAction<IUpdatePaymentSettingsErrorObj>>;
  enableFields: boolean;
  onClickEdit: () => void;
  onSave: () => void;
  onCancel: () => void;
}

const BillingConfigCustomer = ({
  customer,
  setCustomer,
  jobTitle,
  setJobTitle,
  errorObj,
  setErrorObj,
  enableFields,
  onClickEdit,
  onSave,
  onCancel,
}: IBillingConfigCustomerProps) => {
  const { t } = useTranslation("workspace", {
    keyPrefix: "billing.tab.config.customerInfo",
  });

  return (
    <div className="flex flex-col gap-6">
      <div className="flex flex-col gap-3">
        <h2 className="flex justify-between items-center">
          {t("title")}
          {enableFields ? (
            <div className="flex gap-1 h-9">
              <Button variant="secondary" onClick={onCancel}>
                {t("button.cancel")}
              </Button>
              <Button
                disabled={
                  !(
                    customer.fullName.length > 0 &&
                    customer.phoneNumber.length > 0 &&
                    customer.email.length > 0 &&
                    patterns.PHONE.test(customer.phoneNumber) &&
                    patterns.EMAIL.test(customer.email)
                  )
                }
                onClick={onSave}
              >
                {t("button.save")}
              </Button>
            </div>
          ) : (
            <IconButton variant="secondary" size={9} onClick={onClickEdit}>
              <EditLine className="h-5 w-5 min-w-5" />
            </IconButton>
          )}
        </h2>
        <span className="text-hard-grey font-regular-16-body">{t("info")}</span>
      </div>
      <div className="grid grid-cols-2 px-6 py-3 gap-x-6 gap-y-3">
        <InputField
          title={t("fullName.label")}
          required
          disabled={!enableFields}
          placeholder={t("fullName.placeholder")}
          value={customer.fullName}
          onChangeValue={(value) => {
            setCustomer((prev) => ({
              ...prev,
              fullName: value,
            }));
          }}
          error={errorObj.customer_full_name}
          onChangeError={(value) => {
            setErrorObj((prev) => ({ ...prev, customer_full_name: value }));
          }}
        />
        <InputField
          title={t("phoneNumber.label")}
          required
          disabled={!enableFields}
          placeholder={t("phoneNumber.placeholder")}
          value={customer.phoneNumber}
          onChangeValue={(value) => {
            setCustomer((prev) => ({
              ...prev,
              phoneNumber: value,
            }));
          }}
          error={errorObj.customer_phone_number}
          onChangeError={(value) => {
            setErrorObj((prev) => ({ ...prev, customer_phone_number: value }));
          }}
          pattern={patterns.PHONE}
          patternFailedString={i18next.t("common:validateWarning.phone")}
        />
        <InputFieldWrapper
          title={t("jobTitle.label")}
          requiredMark
          input={
            <Dropdown
              menu={{
                items: jobTitleList.map((p) => ({
                  key: p.key,
                  label: (
                    <DropdownItemLabel
                      selected={jobTitle.key === p.key}
                      syncWidthId="jobTitle-dropdown"
                    >
                      {p.getLabel()}
                    </DropdownItemLabel>
                  ),
                  onClick: ({ key }) => {
                    const clickedItem = jobTitleList.find((p) => p.key === key);
                    if (clickedItem) {
                      setJobTitle(clickedItem);
                      setCustomer((prev) => ({
                        ...prev,
                        jobTitle: clickedItem.value,
                      }));
                    }
                  },
                })),
              }}
              trigger={["click"]}
              disabled={!enableFields}
            >
              <DropdownTriggerButton id="jobTitle-dropdown">
                {jobTitle.getLabel()}
                {enableFields ? (
                  <ArrowDownSLine
                    width={"1rem"}
                    height={"1rem"}
                    className="fill-hard-grey"
                  />
                ) : null}
              </DropdownTriggerButton>
            </Dropdown>
          }
          error={errorObj.customer_job_title}
        />
        <InputField
          title={t("emailAddress.label")}
          required
          disabled={!enableFields}
          placeholder={t("emailAddress.placeholder")}
          value={customer.email}
          onChangeValue={(value) => {
            setCustomer((prev) => ({
              ...prev,
              email: value,
            }));
          }}
          error={errorObj.customer_email}
          onChangeError={(value) => {
            setErrorObj((prev) => ({ ...prev, customer_email: value }));
          }}
          pattern={patterns.EMAIL}
          patternFailedString={i18next.t("common:validateWarning.email")}
        />
      </div>
    </div>
  );
};

export default BillingConfigCustomer;
