import type { IPaginatedResponse, IPaginateParams } from "#src/@types/api";
import endpoint from "#src/config/endpoint";
import request from "#src/utils/request";

export interface IJoinRequest {
  id: number;
  access_time: number;
  identity_status: string;
  nda_status: string;
  contract_status: string;
  user: null | {
    email: string;
    username: string;
    full_name: string;
    avatar: string;
  };
}

export function list_join_requests(
  workspace_id: string,
  program_alias: string,
  params: IPaginateParams
) {
  return request.get<never, IPaginatedResponse<IJoinRequest>>(
    `${endpoint.WORKSPACES}/${workspace_id}/programs/${program_alias}/members/join`,
    { params }
  );
}

export function accept_join_request(
  workspace_id: string,
  program_alias: string,
  join_request_id: string
) {
  return request.put<never, any>(
    `${endpoint.WORKSPACES}/${workspace_id}/programs/${program_alias}/members/join/${join_request_id}`
  );
}

export function reject_join_request(
  workspace_id: string,
  program_alias: string,
  join_request_id: string
) {
  return request.delete(
    `${endpoint.WORKSPACES}/${workspace_id}/programs/${program_alias}/members/join/${join_request_id}`
  );
}
