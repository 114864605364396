import { forwardRef, type HTMLAttributes } from "react";
import { twMerge } from "tailwind-merge";

/* A layout that extends `section` Element. */
const StickySection = forwardRef<HTMLElement, HTMLAttributes<HTMLElement>>(
  (props, ref) => {
    return (
      <section
        {...props}
        ref={ref}
        id={props.id ? props.id : "STICKY_SECTION"}
        className={twMerge(
          "sticky top-0 z-[1] flex gap-3 px-16 py-4 mb-2 bg-white justify-between items-start w-full",
          props.className
        )}
      >
        {props.children}
      </section>
    );
  }
);

StickySection.displayName = "StickySection";

export default StickySection;
