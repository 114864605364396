import { getTwColorByStatus } from "#src/components/common/variants/byStatus";
import { Checkbox } from "@lockerpm/design";
import { cloneElement, type ReactElement, type ReactNode } from "react";

interface IGroupTitleLabelProps {
  label: ReactNode;
  checked: boolean;
  indeterminate: boolean;
  onChangeCheckbox: () => void;
}

export const GroupTitleLabel = ({
  label,
  checked,
  indeterminate,
  onChangeCheckbox,
}: IGroupTitleLabelProps) => {
  return (
    <div
      className="flex items-center gap-2 px-3 py-1 text-hard-grey w-[16rem]"
      onClick={(e) => {
        e.stopPropagation();
        onChangeCheckbox();
      }}
    >
      <Checkbox checked={checked} indeterminate={indeterminate} />
      {typeof label === "string" ? label.toUpperCase() : label}
    </div>
  );
};

interface IGroupItemLabelProps {
  label: ReactNode;
  value: string | undefined;
  checked: boolean;
  onChangeCheckbox: () => void;
}

export const GroupItemLabel = ({
  label,
  value,
  checked,
  onChangeCheckbox,
}: IGroupItemLabelProps) => {
  return (
    <div
      className="flex items-center pl-8 py-1 gap-2 text-hard-grey w-[16rem]"
      onClick={(e) => {
        e.stopPropagation();
        onChangeCheckbox();
      }}
    >
      <Checkbox checked={checked} />
      <span className="flex items-center gap-1">
        <div
          className={`${
            value ? getTwColorByStatus(value) : ""
          } h-2 w-2 rounded-full`}
        />
        {label}
      </span>
    </div>
  );
};

export const statusDropdownRender = (menu: ReactNode) => {
  return cloneElement(menu as ReactElement, {
    style: {
      paddingTop: "0.75rem",
      display: "flex",
      flexDirection: "column",
      gap: "1.5rem",
    },
  });
};
