// Libraries
import { useCallback, useEffect, useLayoutEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Dropdown } from "@lockerpm/design";
import { useDebouncedCallback } from "use-debounce";

// Resources
import { ReactComponent as CloseLine } from "#src/assets/images/icons/close-line.svg";
import { ReactComponent as ArrowDownSLine } from "#src/assets/images/icons/arrow-down-s-line.svg";

// General
import constants from "#src/config/constants";

// Components
import DropdownItemLabel from "#src/components/common/helper/antdProps/Dropdown/DropdownItemLabel";
import { apiErrorHandler } from "#src/utils/apiErrorHandler";

// API-related
import devicesServices from "#src/services/devices";

interface ISelectItem {
  key: string;
  value: string;
  label: string;
}

interface ISoftwareInputProps {
  workspaceId: string;
  selectedSoftware: ISelectItem | null;
  onChangeSoftware: (software: ISelectItem) => void;
}

const SoftwareInput = ({
  workspaceId,
  selectedSoftware,
  onChangeSoftware,
}: ISoftwareInputProps) => {
  const { t } = useTranslation("devices", {
    keyPrefix: "control.drawers.addNewControl",
  });

  const [softwareList, setSoftwareList] = useState<ISelectItem[]>([]);
  const [softwareCount, setSoftwareCount] = useState<number>(0);

  const [loadedPages, setLoadedPages] = useState<number>(0);
  const [isLoading, setLoading] = useState<boolean>(false);
  const [searchKeyword, setSearchKeyword] = useState<string>("");

  const [open, setOpen] = useState<boolean>(false);

  const fetchWorkspaceSoftwares = useCallback(
    (keyword: string | null, page?: number) => {
      setLoading(true);
      if (workspaceId) {
        devicesServices
          .list_workspace_softwares(workspaceId, {
            q: keyword ? keyword : undefined,
            page: page ? page : undefined,
            size: constants.DEFAULT_PAGE_SIZE,
          })
          .then((response) => {
            if (page) {
              setSoftwareList((prev) => [
                ...prev,
                ...response.results.map((software) => ({
                  key: software.id.toString(),
                  value: software.name,
                  label:
                    software.name +
                    (software.version ? " - " + software.version : ""),
                })),
              ]);
            } else {
              setSoftwareList(
                response.results.map((software) => ({
                  key: software.id.toString(),
                  value: software.name,
                  label:
                    software.name +
                    (software.version ? " - " + software.version : ""),
                }))
              );
            }
            setSoftwareCount(response.count);
            setLoadedPages((prev) => prev + 1);
            setLoading(false);
          })
          .catch((error) => {
            apiErrorHandler(error, {
              toastMessage: t("notification.loadSoftwareList.fail"),
            });
          });
      }
    },
    [workspaceId, t]
  );

  const debouncedFetchWorkspaceSoftwares = useDebouncedCallback(
    fetchWorkspaceSoftwares,
    constants.DEBOUNCE_TIME
  );

  const onChangeKeyword = (keyword: string) => {
    setSearchKeyword(keyword);
    debouncedFetchWorkspaceSoftwares(keyword);
  };

  const onScrollEvent = useDebouncedCallback((e: Event) => {
    const target = e.target as HTMLUListElement;

    if (
      target &&
      target.clientHeight + target.scrollTop > target.scrollHeight - 10 &&
      !isLoading &&
      softwareCount &&
      softwareCount > loadedPages * constants.DEFAULT_PAGE_SIZE
    ) {
      setLoading(true);
      fetchWorkspaceSoftwares(searchKeyword, loadedPages + 1);
    }
  });

  const onChooseSoftware = (key: string) => {
    const chosenSoftware = softwareList.find(
      (software) => software.key === key
    );

    if (chosenSoftware) {
      onChangeSoftware({
        key: chosenSoftware.key,
        value: chosenSoftware.value,
        label: chosenSoftware.label,
      });
    }
  };

  // WARNING: This is a bad way to do onScroll event, but we don't have any choices since AntD Dropdown doesn't expose onScroll event and the component did not even show up until the open state has changed AND React has rerendered once more.
  // --- This would definitely hurt performance ---
  useLayoutEffect(() => {
    const menuList = document.getElementsByClassName("ant-dropdown-menu");
    if (menuList.length > 0) {
      const menu = menuList.item(0);

      if (open) {
        if (menu !== null) {
          menu.addEventListener("scroll", onScrollEvent);
        }
      } else {
        if (menu !== null) {
          menu.removeEventListener("scroll", onScrollEvent);
        }
      }
    }
  }, [open, onScrollEvent]);

  useEffect(() => {
    let ignore = false;

    if (!ignore) {
      fetchWorkspaceSoftwares(null);
    }

    return () => {
      ignore = true;
    };
  }, [fetchWorkspaceSoftwares]);

  return (
    <div className="flex gap-1">
      <Dropdown
        open={open}
        onOpenChange={(currentOpen) => {
          setOpen(currentOpen);
        }}
        menu={{
          items: softwareList.map((software) => ({
            key: software.key,
            label: (
              <DropdownItemLabel
                selected={software.key === selectedSoftware?.key}
              >
                {software.label}
              </DropdownItemLabel>
            ),
          })),
          onClick: ({ key }) => {
            onChooseSoftware(key);
            setOpen(false);
          },
        }}
        trigger={["click"]}
      >
        <div
          className={`flex px-4 py-2 text-left items-center justify-between ${
            open ? "outline outline-2 outline-primary" : "bg-bright-grey"
          } rounded-md w-full`}
        >
          <div className="flex items-center gap-2">
            {open ? (
              <input
                className="h-7 w-full outline-none caret-primary bg-transparent"
                autoFocus
                value={searchKeyword}
                onClick={(e) => {
                  e.stopPropagation();
                }}
                onChange={(e) => {
                  onChangeKeyword(e.currentTarget.value);
                }}
              />
            ) : (
              <span
                className={`h-7 flex items-center ${
                  selectedSoftware?.label ? "text-primary" : "text-medium-grey"
                }`}
              >
                {selectedSoftware?.label
                  ? selectedSoftware.label
                  : t("field.software.placeholder")}
              </span>
            )}
          </div>
          {open ? (
            <button
              className="flex items-center"
              onClick={() => {
                setOpen(false);
              }}
            >
              <CloseLine className="fill-hard-grey" />
            </button>
          ) : (
            <div className="flex items-center">
              <ArrowDownSLine className="fill-hard-grey" />
            </div>
          )}
        </div>
      </Dropdown>
    </div>
  );
};

export default SoftwareInput;
