// Libraries
import dayjs from "dayjs";
import { Typography } from "@lockerpm/design";
import { useTranslation } from "react-i18next";

// Components
import type { ITableHeaderPropItem } from "#src/components/common/Table/TableHeader";
import LoadingState from "#src/components/common/system/LoadingState";
import NoResultsState from "#src/components/common/states/NoResultsState";
import {
  TableDataCell,
  TableHeader,
  TableRowWrapper,
} from "#src/components/common/Table";

// API-related
import type { IAssetPortItem } from "#src/services/assets/ports";

/** Row template for Assets/Domains/Details Port tab table view, since this depends on each table and cannot be built into a common template
 * @param data - data of the row, represent a port from selected domain
 */
const PortTableRow = ({ data }: { data: IAssetPortItem }) => {
  return (
    <TableRowWrapper>
      <TableDataCell>{data.port}</TableDataCell>
      <TableDataCell>{data.protocol.toUpperCase()}</TableDataCell>
      <TableDataCell>{data.service.toUpperCase()}</TableDataCell>
      <TableDataCell>{data.product}</TableDataCell>
      <TableDataCell>
        <Typography.Text
          ellipsis={{
            tooltip: data.version,
          }}
        >
          {data.version}
        </Typography.Text>
      </TableDataCell>
      <TableDataCell>
        <Typography.Text
          ellipsis={{
            tooltip: data.cpe,
          }}
        >
          {data.cpe}
        </Typography.Text>
      </TableDataCell>
      <TableDataCell>
        {dayjs.unix(data.last_found).format("HH:mm DD.MM.YYYY")}
      </TableDataCell>
    </TableRowWrapper>
  );
};

interface IPortTableProps {
  portList: IAssetPortItem[] | null;
  isLoading: boolean;
}

const PortTableGrid = ({ portList, isLoading }: IPortTableProps) => {
  const { t } = useTranslation("assets", {
    keyPrefix: "domains.page.details.tab.ports",
  });

  /** Asset Domain Detail Port Table's header list */
  const headers: ITableHeaderPropItem[] = [
    { name: t("headers.port"), align: "left" },
    { name: t("headers.protocol"), align: "left" },
    { name: t("headers.service"), align: "left" },
    { name: t("headers.product"), align: "left" },
    { name: t("headers.version"), align: "left" },
    { name: t("headers.cpe"), align: "left" },
    { name: t("headers.createdDate"), align: "left" },
  ];

  return isLoading || portList === null ? (
    <LoadingState />
  ) : portList.length === 0 ? (
    <NoResultsState />
  ) : (
    <div className="grid grid-cols-7">
      <TableHeader title="assets/domains/details-ports" headerProps={headers} />
      {portList.map((item) => (
        <PortTableRow
          key={`tassets/domains/details-ports-tr${item.port}`}
          data={item}
        />
      ))}
    </div>
  );
};

export default PortTableGrid;
