import type { IPaginateParams, IPaginatedResponse } from "#src/@types/api";
import endpoint from "#src/config/endpoint";
import request from "#src/utils/request";
import {
  create_mssp_pentest_project_member,
  delete_mssp_pentest_project_member,
  list_pentest_project_workspace_members,
  list_pentest_project_mssp_members,
  update_mssp_pentest_project_member,
} from "./members";

export interface IMsspPentestProject {
  alias: string;
  status: string;
  in_review: boolean;
  type: string;
  created_time: number;
  updated_time: number | null;
  org: {
    alias: string;
    name: string;
    email: string;
    address: string;
    phone: string;
    color: string;
    description: {
      en: string;
      vi: string;
    };
    logo: string | null;
    social: any;
  };
  mssp: {
    id: string;
    name: string;
  } | null;
  vulnerabilities: number;
}

export interface IMsspPentestProjectDetails extends IMsspPentestProject {
  requested_time: number | null;
  metadata: { vi: string; en: string };
  targets: {
    id: number;
    name: string;
    type: string;
    scope: string;
    link_download: string;
  }[];
}

function list_mssp_pentest_projects(
  workspace_id: string,
  params: {
    from: number | undefined;
    to: number | undefined;
    status: string | undefined;
  } & IPaginateParams
) {
  return request.get<never, IPaginatedResponse<IMsspPentestProject>>(
    `${endpoint.BASE}/mssp/${workspace_id}/pentest_projects`,
    { params }
  );
}

function retrieve_mssp_pentest_project_details(
  workspace_id: string,
  pentest_alias: string
) {
  return request.get<never, IMsspPentestProjectDetails>(
    `${endpoint.BASE}/mssp/${workspace_id}/pentest_projects/${pentest_alias}`
  );
}

interface IMsspPentestProjectPostData {
  alias?: string;
  status?: string;
  name: string;
  description?: {
    en: string;
    vi: string;
  };
  email?: string;
  phone?: string;
  address?: string;
  logo?: string;
  color?: string;
  targets?: {
    name: string;
    type: string;
    scope: string;
    link_download: string;
  }[];
  metadata: { en: string; vi: string };
  mssp_id: string;
}

function update_mssp_pentest_project(
  workspace_id: string,
  pentest_alias: string,
  data: Partial<Omit<IMsspPentestProjectPostData, "alias">>
) {
  return request.put<never, { success: boolean }>(
    `${endpoint.BASE}/mssp/${workspace_id}/pentest_projects/${pentest_alias}`,
    data
  );
}

interface IMsspPentestHistoryItem {
  id: number;
  created_time: number;
  description: {
    en: string;
    vi: string;
  };
  editor: string;
}

function list_mssp_pentest_project_history(
  workspace_id: string,
  pentest_alias: string,
  // This API doesn't accept page "size", which is annoying but ok
  params: { page: number | undefined }
) {
  return request.get<never, IPaginatedResponse<IMsspPentestHistoryItem>>(
    `${endpoint.BASE}/mssp/${workspace_id}/pentest_projects/${pentest_alias}/history`,
    { params }
  );
}

export default {
  list_mssp_pentest_projects,
  retrieve_mssp_pentest_project_details,
  update_mssp_pentest_project,

  list_mssp_pentest_project_history,

  list_pentest_project_workspace_members,
  list_pentest_project_mssp_members,
  create_mssp_pentest_project_member,
  update_mssp_pentest_project_member,
  delete_mssp_pentest_project_member,
};
