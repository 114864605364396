import { forwardRef, type TextareaHTMLAttributes, useState } from "react";
import DOMPurify from "dompurify";
import { marked } from "marked";
import { twMerge } from "tailwind-merge";
import { generateKeyString } from "#src/utils/common";
import EditorTools from "./Tools";
import EditorModeSwitch from "./ModeSwitch";

interface IMarkdownEditorProps
  extends TextareaHTMLAttributes<HTMLTextAreaElement> {
  defaultMode?: "write" | "preview";
  noBox?: boolean;
  parser?: (value: string) => string | Node;
}

/** Markdown Editor implements on a TextArea
 * @param defaultMode initial value for edit mode.
 * @param noBox an option to remove outermost border and paddings
 * @todo add error state?
 */
const MarkdownEditor = forwardRef<HTMLTextAreaElement, IMarkdownEditorProps>(
  ({ defaultMode, noBox = false, parser, ...props }, ref) => {
    const [mode, setMode] = useState<"write" | "preview">(
      defaultMode ? defaultMode : "write"
    );

    const inputUuid = props.id ?? `MARKDOWN_EDITOR_${generateKeyString()}`;

    return (
      <div
        className={`w-full flex flex-col gap-3  ${
          noBox ? "" : "p-6 rounded-md outline outline-1 outline-light-grey"
        }`}
      >
        <div className="flex justify-between flex-wrap gap-3">
          <EditorTools mode={mode} inputUuid={inputUuid} />
          <EditorModeSwitch mode={mode} setMode={setMode} />
        </div>
        {mode === "write" ? (
          <textarea
            ref={ref}
            {...props}
            id={inputUuid}
            className={twMerge(
              `w-full min-h-[12rem] p-3 rounded-md bg-bright-grey outline outline-2 outline-transparent hover:outline-light-grey focus:outline-primary duration-150`,
              props.className
            )}
          />
        ) : (
          <div
            className={twMerge(
              "markdown-body w-full min-h-[12rem] p-3 overflow-x-auto",
              props.className
            )}
            dangerouslySetInnerHTML={
              props.value
                ? {
                    __html: DOMPurify.sanitize(
                      parser
                        ? parser(props.value.toString())
                        : (marked.parse(props.value.toString()) as string)
                    ),
                  }
                : undefined
            }
          />
        )}
      </div>
    );
  }
);

MarkdownEditor.displayName = "MarkdownEditor";

export default MarkdownEditor;
