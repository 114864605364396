export enum ScanStatusEnum {
  PENDING = "SCAN_STATUS_PENDING",
  RUNNING = "SCAN_STATUS_RUNNING",
  COMPLETED = "SCAN_STATUS_COMPLETED",
  STOPPING = "SCAN_STATUS_STOPPING",
  STOPPED = "SCAN_STATUS_STOPPED",
  ALL = "SCAN_STATUS_ALL",
}

export enum ScanScheduleTypeEnum {
  ON_DEMAND = "SCAN_SCHEDULE_TYPE_ON_DEMAND",
  SCHEDULE = "SCAN_SCHEDULE_TYPE_SCHEDULE",
  ALL = "SCAN_SCHEDULE_TYPE_ALL",
}

export enum ScheduleEnableStatusEnum {
  ENABLED = "SCHEDULE_ENABLE_STATUS_ENUM_ENABLED",
  DISABLED = "SCHEDULE_ENABLE_STATUS_ENUM_DISABLED",
  ALL = "SCHEDULE_ENABLE_STATUS_ENUM_ALL",
}

export enum ScanFrequencyEnum {
  ONCE = "FREQUENCY_ENUM_ONCE",
  DAILY = "FREQUENCY_ENUM_DAILY",
  WEEKLY = "FREQUENCY_ENUM_WEEKLY",
  MONTHLY = "FREQUENCY_ENUM_MONTHLY",
}
