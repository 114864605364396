import type { ReactNode } from "react";

export interface IFormProgressStep {
  title: string;
  element: ReactNode;
}

interface ITabProgressProps {
  steps: IFormProgressStep[];
  progress: number;
}

// TODO: This is super bad for performance. Just one change on "steps" array will make the whole component rerender. Find a way to solve it.
/** A component that looks like tab, but represent the progress of a form.
 * @param steps - each step should have a label and an element contain its content
 * @param progress - number of steps that has been completed.
 */
const TabProgress = ({ steps, progress }: ITabProgressProps) => {
  return steps.length ? (
    <div className="flex flex-col gap-12">
      <div className="w-full flex gap-3">
        {steps.map((step, stepIndex) => (
          <div
            key={`tabProgress-title-${step.title}`}
            className="flex-1 flex flex-col items-center gap-4"
          >
            <span className="font-medium-18">{step.title}</span>
            <div
              className={`h-1 w-full rounded-full ${
                stepIndex <= progress ? "bg-primary" : "bg-button-disabled"
              } transition-all duration-600`}
            />
          </div>
        ))}
      </div>
      {steps[progress].element}
    </div>
  ) : null;
};

export default TabProgress;
