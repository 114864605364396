// Libraries
import { useMemo, useState } from "react";

// General
import type { IFilterItem } from "#src/@types/common";

// Components
import useFetchPaginated from "#src/hooks/useFetchPaginated";

// API-related
import paymentService from "#src/services/payment";

// Children
import BillingOrderFilter from "./Filter";
import BillingOrderTable from "./Table";

interface IBillingOrderTabProps {
  workspaceId: string;
  status: IFilterItem | null;
  onChangeStatusSelection: (selected: IFilterItem | null) => void;
}

const BillingOrderTab = ({
  workspaceId,
  status,
  onChangeStatusSelection,
}: IBillingOrderTabProps) => {
  const [searchKeyword, setSearchKeyword] = useState<string>("");

  const fetchParams = useMemo<Parameters<typeof paymentService.list_payments>>(
    () => [
      workspaceId,
      {
        status: status?.value,
        q: searchKeyword,
      },
    ],
    [workspaceId, status, searchKeyword]
  );

  const onChangeSearchKeyword = (keyword: string) => {
    setSearchKeyword(keyword);
  };

  const { list: orderList, isLoading } = useFetchPaginated(
    paymentService.list_payments,
    fetchParams
  );

  return (
    <section className="flex flex-col gap-6 pt-6">
      <BillingOrderFilter
        status={status}
        searchKeyword={searchKeyword}
        onChangeStatusSelection={onChangeStatusSelection}
        onChangeSearchKeyword={onChangeSearchKeyword}
      />
      <BillingOrderTable orderList={orderList} isLoading={isLoading} />
    </section>
  );
};

export default BillingOrderTab;
