// Libraries
import { useEffect, useState } from "react";

// Components
import LoadingState from "#src/components/common/system/LoadingState";
import NoResultsState from "#src/components/common/states/NoResultsState";
import { apiErrorHandler } from "#src/utils/apiErrorHandler";

// API-related
import scansServices from "#src/services/scans";
import type { ICertificateItem } from "#src/services/assets/certificates";

// Children
import CertificateExpirationCard from "#src/components/assets/details/Tabs/CertificatesTab/ExpirationCard";
import CertificatePath from "#src/components/assets/details/Tabs/CertificatesTab/Path";
import CertificateRaw from "#src/components/assets/details/Tabs/CertificatesTab/Raw";
import CertificateDetails from "#src/components/assets/details/Tabs/CertificatesTab/Details";
import CertificateEndpoints from "#src/components/assets/details/Tabs/CertificatesTab/Endpoints";

interface IScanDetailsCertificateProps {
  workspaceId: string;
  scanId: number;
  domainId: number;
}

const ScanDetailsCertificate = ({
  workspaceId,
  scanId,
  domainId,
}: IScanDetailsCertificateProps) => {
  const [certificate, setCertificate] = useState<ICertificateItem | null>(null);
  const [isLoading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    let ignore = false;
    setLoading(true);

    if (!ignore) {
      scansServices
        .get_scan_certificate(workspaceId, scanId, { domain_id: domainId })
        .then((response) => {
          if (Object.keys(response).length > 0) {
            setCertificate(response as ICertificateItem);
          }
          setLoading(false);
        })
        .catch(apiErrorHandler);
    }

    return () => {
      ignore = true;
    };
  }, [workspaceId, scanId, domainId]);

  return certificate === null ? (
    isLoading ? (
      <LoadingState />
    ) : (
      <NoResultsState />
    )
  ) : (
    <section className="flex flex-col gap-12 pt-6">
      <CertificateExpirationCard
        expireNotAfter={certificate.not_after}
        issuerName={certificate.issuer.name}
        name={certificate.common_names[0]}
      />
      <CertificateDetails certificate={certificate} />
      <CertificatePath certificate={certificate} />
      <CertificateRaw certificate={certificate} />
      <CertificateEndpoints endpoints={certificate.endpoints} />
    </section>
  );
};

export default ScanDetailsCertificate;
