import type { IPaginateParams, IPaginatedResponse } from "#src/@types/api";
import type { MultilingualText } from "#src/@types/common";
import endpoint from "#src/config/endpoint";
import request from "#src/utils/request";

export interface ITrustCenterThirdPartyItem {
  id: string;
  logo: string;
  name: string;
  url: string;
  description: null | string;
  created_time: number;
  published_time: null | number;
  category: MultilingualText | null;
}

export function list_trust_center_third_party(
  workspace_id: string,
  params: IPaginateParams
) {
  return request.get<never, IPaginatedResponse<ITrustCenterThirdPartyItem>>(
    `${endpoint.WORKSPACES}/${workspace_id}/trust_center/third_party`,
    {
      params,
    }
  );
}

export function retrieve_trust_center_third_party(
  workspace_id: string,
  third_party_id: string
) {
  return request.get<never, ITrustCenterThirdPartyItem>(
    `${endpoint.WORKSPACES}/${workspace_id}/trust_center/third_party/${third_party_id}`
  );
}

export function create_trust_center_third_party(
  workspace_id: string,
  data: {
    service_id: number;
  }
) {
  return request.post<never, { id: string }>(
    `${endpoint.WORKSPACES}/${workspace_id}/trust_center/third_party`,
    data
  );
}

export function create_multiple_trust_center_third_party(
  workspace_id: string,
  data: {
    service_id: number;
  }[]
) {
  return request.post<never, { success: boolean }>(
    `${endpoint.WORKSPACES}/${workspace_id}/trust_center/third_party/create_multiple`,
    data
  );
}

export function delete_trust_center_third_party(
  workspace_id: string,
  third_party_id: string
) {
  return request.delete(
    `${endpoint.WORKSPACES}/${workspace_id}/trust_center/third_party/${third_party_id}`
  );
}
