export enum TrustCenterFaqActionEnum {
  EDIT = "TRUST_CENTER_FAQ_ACTION_ENUM_EDIT",
  DELETE = "TRUST_CENTER_FAQ_ACTION_ENUM_DELETE",
  PUBLISH = "TRUST_CENTER_FAQ_ACTION_ENUM_PUBLISH",
  DRAFT = "TRUST_CENTER_FAQ_ACTION_ENUM_DRAFT",
}

export enum TrustCenterFaqStatusValue {
  DRAFT = "draft",
  PUBLISHED = "published",
}
