import type { IPaginateParams, IPaginatedResponse } from "#src/@types/api";
import endpoint from "#src/config/endpoint";
import request from "#src/utils/request";

export interface ITrustCenterDocumentItem {
  id: string;
  name: string;
  status: string;
  url: null | string;
  category: {
    name: string;
    id: number;
  };
  is_public: boolean;
  created_time: number;
  published_time: null | number;
  updated_time: null | number;
}

export function list_trust_center_document(
  workspace_id: string,
  params: IPaginateParams
) {
  return request.get<never, IPaginatedResponse<ITrustCenterDocumentItem>>(
    `${endpoint.WORKSPACES}/${workspace_id}/trust_center/resources`,
    { params }
  );
}

export function retrieve_trust_center_document(
  workspace_id: string,
  document_id: string
) {
  return request.get<never, ITrustCenterDocumentItem>(
    `${endpoint.WORKSPACES}/${workspace_id}/trust_center/resources/${document_id}`
  );
}

export function create_trust_center_document(
  workspace_id: string,
  data: {
    name: string;
    url?: string;
    status?: string;
  }
) {
  return request.post<never, { id: string }>(
    `${endpoint.WORKSPACES}/${workspace_id}/trust_center/resources`,
    data
  );
}

export function update_trust_center_document(
  workspace_id: string,
  document_id: string,
  data: {
    name: string;
    url?: string;
    status?: string;
  }
) {
  return request.put<never, { id: string }>(
    `${endpoint.WORKSPACES}/${workspace_id}/trust_center/resources/${document_id}`,
    data
  );
}

export function delete_trust_center_document(
  workspace_id: string,
  document_id: string
) {
  return request.delete(
    `${endpoint.WORKSPACES}/${workspace_id}/trust_center/resources/${document_id}`
  );
}
