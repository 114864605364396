import { useTranslation } from "react-i18next";

interface IOldPlanItemProps {
  getName: () => string;
  price: number;
}

/** Use on product plan select. Layouts can be different but this block seems to be consistent. */
const OldPlanItem = ({ getName, price }: IOldPlanItemProps) => {
  const { t } = useTranslation("trustCenter", {
    keyPrefix: "registerDomain.chooseAPlan",
  });

  return (
    <div className="px-6 py-4 border rounded-md flex flex-col gap-3 flex-1 relative transition-all duration-150 bg-light-grey select-none">
      <span>{getName()}</span>
      <span className="font-medium-20">
        <span className="font-bold-20">{price.toLocaleString()}</span>{" "}
        {t("currency")}/ {t("month")}
      </span>
      <span className="absolute right-0 top-0 bg-dark-blue text-white font-bold-16 px-1.5 py-1 rounded-md flex items-center gap-0.5">
        {t("currentPlan")}
      </span>
    </div>
  );
};

export default OldPlanItem;
