// Libraries
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { AxiosError } from "axios";
import { z } from "zod";

// Components
import { apiErrorHandler, parseBadRequest } from "#src/utils/apiErrorHandler";
import { createToast } from "#src/components/common/system/toasts";

// API-related
import paymentService from "#src/services/payment";
import { ContractTypeValue } from "#src/config/filter/workspace/value";

// Children
import BillingConfigCustomer from "./Customer";
import BillingConfigInvoice from "./Invoice";
import {
  ContactFormSelection,
  jobTitleList,
} from "#src/components/trustCenter/onboard/ContactForm/contactDetailFields";
import type { IUpdatePaymentSettingsErrorObj } from "./helper";

interface IBillingConfigTabProps {
  workspaceId: string;
}

const BillingConfigTab = ({ workspaceId }: IBillingConfigTabProps) => {
  const { t } = useTranslation("workspace", {
    keyPrefix: "billing.tab.config",
  });

  const [originalForm, setOriginalForm] = useState<Awaited<
    ReturnType<typeof paymentService.retrieve_workspace_payment_settings>
  > | null>(null);
  const [customer, setCustomer] = useState<{
    fullName: string;
    phoneNumber: string;
    jobTitle: string;
    email: string;
  }>({ fullName: "", phoneNumber: "", jobTitle: "", email: "" });
  const [jobTitle, setJobTitle] = useState<ContactFormSelection>(
    jobTitleList[0]
  );
  const [enableCustomerFields, setEnableCustomerFields] =
    useState<boolean>(false);

  const [vatInvoice, setVatInvoice] = useState<{
    name: string;
    taxCode: string;
    identityCard: string;
    address: string;
    type: "enterprise" | "personal";
  }>({
    name: "",
    taxCode: "",
    identityCard: "",
    address: "",
    type: "enterprise",
  });
  const [enableInvoiceFields, setEnableInvoiceFields] =
    useState<boolean>(false);

  const [errorObj, setErrorObj] = useState<IUpdatePaymentSettingsErrorObj>({
    customer_full_name: undefined,
    customer_phone_number: undefined,
    customer_job_title: undefined,
    customer_email: undefined,
    vat_name: undefined,
    vat_tax_code: undefined,
    vat_address: undefined,
    vat_identity_card: undefined,
  });

  const onSaveCustomerInfo = () => {
    onSaveSettings().then((response) => {
      if (response) {
        setEnableCustomerFields(false);
        createToast({
          type: "success",
          message: t("notification.updateInfo.success.title"),
          detail: t("notification.updateInfo.success.detail"),
        });
      } else {
        createToast({
          type: "error",
          message: t("notification.updateInfo.fail.title"),
        });
      }
    });
  };

  const onCancelEditCustomerInfo = () => {
    setEnableCustomerFields(false);
    setErrorObj((prev) => ({
      ...prev,
      customer_full_name: undefined,
      customer_phone_number: undefined,
      customer_job_title: undefined,
      customer_email: undefined,
    }));
    if (originalForm) {
      setCustomer({
        fullName: originalForm.customer_full_name,
        phoneNumber: originalForm.customer_phone_number,
        jobTitle: originalForm.customer_job_title,
        email: originalForm.customer_email,
      });
    }
  };

  const onSaveInvoiceInfo = () => {
    onSaveSettings().then((response) => {
      if (response) {
        setEnableInvoiceFields(false);
        createToast({
          type: "success",
          message: t("notification.updateInfo.success.title"),
          detail: t("notification.updateInfo.success.detail"),
        });
      } else {
        createToast({
          type: "error",
          message: t("notification.updateInfo.fail.title"),
        });
      }
    });
  };

  const onCancelEditInvoiceInfo = () => {
    setEnableInvoiceFields(false);
    setErrorObj((prev) => ({
      ...prev,
      vat_name: undefined,
      vat_tax_code: undefined,
      vat_address: undefined,
      vat_identity_card: undefined,
    }));
    if (originalForm) {
      setVatInvoice({
        name: originalForm.vat_name ?? "",
        taxCode: originalForm.vat_tax_code ?? "",
        identityCard: originalForm.vat_identity_card ?? "",
        address: originalForm.vat_address ?? "",
        type: originalForm.vat_type,
      });
    }
  };

  const onSaveSettings = async () => {
    return await paymentService
      .update_workspace_payment_settings(workspaceId, {
        customer_full_name: customer.fullName,
        customer_phone_number: customer.phoneNumber,
        customer_job_title: customer.jobTitle,
        customer_email: customer.email,
        contract_type: ContractTypeValue.DIRECT,
        vat_name: vatInvoice.name,
        vat_tax_code: vatInvoice.taxCode,
        vat_identity_card: vatInvoice.identityCard,
        vat_address: vatInvoice.address,
        vat_type: vatInvoice.type,
      })
      .then((response) => {
        setOriginalForm(response);
        return true;
      })
      .catch((error) => {
        if (
          error instanceof AxiosError &&
          error.response &&
          error.response.status === 400
        ) {
          const getErrorData = parseBadRequest(
            error.response.data,
            z.object({
              customer_full_name: z.optional(z.array(z.string())),
              customer_phone_number: z.optional(z.array(z.string())),
              customer_job_title: z.optional(z.array(z.string())),
              customer_email: z.optional(z.array(z.string())),
              vat_name: z.optional(z.array(z.string())),
              vat_tax_code: z.optional(z.array(z.string())),
              vat_identity_card: z.optional(z.array(z.string())),
              vat_address: z.optional(z.array(z.string())),
              vat_type: z.optional(z.array(z.string())),
            })
          );
          setErrorObj((prev) => ({
            ...prev,
            customer_full_name: getErrorData.details.customer_full_name
              ? getErrorData.details.customer_full_name[0]
              : undefined,
            customer_phone_number: getErrorData.details.customer_phone_number
              ? getErrorData.details.customer_phone_number[0]
              : undefined,
            customer_job_title: getErrorData.details.customer_job_title
              ? getErrorData.details.customer_job_title[0]
              : undefined,
            customer_email: getErrorData.details.customer_email
              ? getErrorData.details.customer_email[0]
              : undefined,
            vat_name: getErrorData.details.vat_name
              ? getErrorData.details.vat_name[0]
              : undefined,
            vat_tax_code: getErrorData.details.vat_tax_code
              ? getErrorData.details.vat_tax_code[0]
              : undefined,
            vat_identity_card: getErrorData.details.vat_identity_card
              ? getErrorData.details.vat_identity_card[0]
              : undefined,
            vat_address: getErrorData.details.vat_address
              ? getErrorData.details.vat_address[0]
              : undefined,
            vat_type: getErrorData.details.vat_type
              ? getErrorData.details.vat_type[0]
              : undefined,
          }));
        } else {
          apiErrorHandler(error);
        }
      });
  };

  useEffect(() => {
    paymentService
      .retrieve_workspace_payment_settings(workspaceId)
      .then((response) => {
        setCustomer({
          fullName: response.customer_full_name,
          phoneNumber: response.customer_phone_number,
          jobTitle: response.customer_job_title,
          email: response.customer_email,
        });
        const findJob = jobTitleList.find(
          (job) => job.value === response.customer_job_title
        );
        if (findJob) {
          setJobTitle(findJob);
        }
        setVatInvoice({
          name: response.vat_name ?? "",
          taxCode: response.vat_tax_code ?? "",
          identityCard: response.vat_identity_card ?? "",
          address: response.vat_address ?? "",
          type: response.vat_type,
        });
        setOriginalForm(response);
      })
      .catch(apiErrorHandler);
  }, [workspaceId]);

  return (
    <div className="mt-6 w-2/3">
      <BillingConfigCustomer
        customer={customer}
        setCustomer={setCustomer}
        jobTitle={jobTitle}
        setJobTitle={setJobTitle}
        errorObj={errorObj}
        setErrorObj={setErrorObj}
        enableFields={enableCustomerFields}
        onClickEdit={() => {
          setEnableCustomerFields(true);
        }}
        onSave={onSaveCustomerInfo}
        onCancel={onCancelEditCustomerInfo}
      />
      <BillingConfigInvoice
        vatInvoice={vatInvoice}
        setVatInvoice={setVatInvoice}
        errorObj={errorObj}
        setErrorObj={setErrorObj}
        enableFields={enableInvoiceFields}
        onClickEdit={() => {
          setEnableInvoiceFields(true);
        }}
        onSave={onSaveInvoiceInfo}
        onCancel={onCancelEditInvoiceInfo}
      />
      {/* TODO: card payment */}
    </div>
  );
};

export default BillingConfigTab;
