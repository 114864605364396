import { useTranslation } from "react-i18next";
import type { IFilterItem } from "#src/@types/common";
import { FilterDropdown, SearchBox } from "#src/components/common/Table/Filter";
import { softwareVulnerableItems } from "#src/config/filterConstants";

interface IHostSoftwaresFilterProps {
  vulnerable: IFilterItem | null;
  searchKeyword: string;
  onChangeVulnerableSelection: (selected: IFilterItem | null) => void;
  onChangeSearchKeyword: (keyword: string) => void;
}

const HostSoftwaresFilter = ({
  vulnerable,
  searchKeyword,
  onChangeVulnerableSelection,
  onChangeSearchKeyword,
}: IHostSoftwaresFilterProps) => {
  const { t } = useTranslation("devices", {
    keyPrefix: "hosts.page.details.tab.softwares.filter",
  });

  return (
    <div className="flex justify-between gap-1">
      <div className="flex gap-1 max-w-[100%_-_15rem] whitespace-nowrap flex-wrap">
        <FilterDropdown
          label={t("vulnerable.label")}
          selected={vulnerable}
          options={softwareVulnerableItems}
          onChangeSelection={onChangeVulnerableSelection}
        />
      </div>

      <SearchBox
        searchKeyword={searchKeyword}
        onChangeSearchKeyword={onChangeSearchKeyword}
      />
    </div>
  );
};

export default HostSoftwaresFilter;
