// Libraries
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { generatePath, useNavigate, useSearchParams } from "react-router-dom";
import { z } from "zod";
import i18next from "i18next";
import { AxiosError } from "axios";

// Resources
import { ReactComponent as AddLine } from "#src/assets/images/icons/add-line.svg";
import { ReactComponent as BuildingLine } from "#src/assets/images/icons/building-line.svg";

// General
import urls from "#src/config/urls";
import { useAppDispatch } from "#src/store/hooks";
import { selectWorkspace } from "#src/store/slices/workspace";
import { pathname } from "#src/config/pathname";
import patterns from "#src/config/patterns";

// Components
import { Button } from "#src/components/common/system/Button";
import { InputField } from "#src/components/common/helper/wrapper/InputField";
import { ObjectImage } from "#src/components/common/system/Object";

// API-related
import workspaceServices from "#src/services/workspace";
import { apiErrorHandler, parseBadRequest } from "#src/utils/apiErrorHandler";

const CreateWorkspace = () => {
  const { t } = useTranslation("workspace", {
    keyPrefix: "newWorkspace",
  });

  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const [companyName, setCompanyName] = useState<string>("");
  const [website, setWebsite] = useState<string>("https://");
  const [email, setEmail] = useState<string>("");
  const [address, setAddress] = useState<string>("");
  const [phone, setPhone] = useState<string>("");

  const [pending, setPending] = useState<boolean>(false);

  const [errorObj, setErrorObj] = useState<{
    name: string | undefined;
    website: string | undefined;
    email: string | undefined;
    address: string | undefined;
    phone: string | undefined;
  }>({
    name: undefined,
    website: undefined,
    email: undefined,
    address: undefined,
    phone: undefined,
  });

  const tempLogoSrc = urls.IMAGE_LOGO_SERVER_URL.replace(
    "size=35",
    "size=224"
  ).replace(":address", website);

  const createNewWorkspace = () => {
    setPending(true);
    workspaceServices
      .create_workspace({
        name: companyName,
        description_en: "",
        description_vn: "",
        website,
        address,
        phone,
        email,
        logo: tempLogoSrc,
      })
      .then((response) => {
        dispatch(selectWorkspace(response.id));
        const returnUrl = searchParams.get("returnUrl");
        if (returnUrl) {
          if (
            (returnUrl.match(/:/) || []).length === 1 &&
            returnUrl.includes(":workspace")
          ) {
            navigate(generatePath(returnUrl, { workspace: response.id }));
          } else {
            navigate(
              generatePath(pathname.SOLUTIONS, {
                workspace: response.id,
              })
            );
          }
        } else {
          navigate(
            generatePath(pathname.SOLUTIONS, {
              workspace: response.id,
            })
          );
        }
      })
      .catch((error) => {
        if (
          error instanceof AxiosError &&
          error.response &&
          error.response.status === 400
        ) {
          const getErrorData = parseBadRequest(
            error.response.data,
            z.object({
              name: z.optional(z.array(z.string())),
              website: z.optional(z.array(z.string())),
            })
          );
          setErrorObj((prev) => ({
            ...prev,
            name: getErrorData.details.name
              ? getErrorData.details.name[0]
              : undefined,
            website: getErrorData.details.website
              ? getErrorData.details.website[0]
              : undefined,
          }));
        } else {
          apiErrorHandler(error);
        }
      })
      .finally(() => {
        setPending(false);
      });
  };

  return (
    <div className="w-screen h-screen flex flex-col justify-center items-center gap-3">
      <div className="flex flex-col gap-3 items-center">
        <div className="h-36 w-36 p-4">
          <ObjectImage
            data={tempLogoSrc}
            key={tempLogoSrc}
            className="h-28 w-28 outline outline-3 outline-light-grey rounded-md"
          >
            <div className="h-28 w-28 bg-bright-grey rounded-md flex items-center justify-center">
              <BuildingLine className="h-18 w-18 fill-medium-grey" />
            </div>
          </ObjectImage>
        </div>
        <span className="font-medium-18 text-primary min-h-[1.375rem]">
          {companyName}
        </span>
        <p className="text-hard-grey min-h-[1rem]"></p>
      </div>
      <div className="p-6 flex flex-col gap-6">
        <h2>{t("title")}</h2>
        <div className="flex gap-12 lg:gap-[4.5rem]">
          <div className="flex flex-col gap-6 w-[20rem] lg:w-[26.25rem]">
            <InputField
              title={t("field.companyName.title")}
              required
              placeholder={t("field.companyName.placeholder")}
              value={companyName}
              onChangeValue={(value) => {
                setCompanyName(value);
              }}
              error={errorObj.name}
              onChangeError={(value) => {
                setErrorObj((prev) => ({
                  ...prev,
                  name: value,
                }));
              }}
            />
            <InputField
              title={t("field.website.title")}
              required
              placeholder={t("field.website.placeholder")}
              value={website}
              onChangeValue={(value) => {
                setWebsite(value);
              }}
              error={errorObj.website}
              onChangeError={(value) => {
                setErrorObj((prev) => ({
                  ...prev,
                  website: value,
                }));
              }}
            />
          </div>
          <div className="flex flex-col gap-6 w-[20rem] lg:w-[26.25rem]">
            <InputField
              title={t("field.email.title")}
              placeholder={t("field.email.placeholder")}
              value={email}
              onChangeValue={(value) => {
                setEmail(value);
              }}
              error={errorObj.email}
              onChangeError={(value) => {
                setErrorObj((prev) => ({
                  ...prev,
                  email: value,
                }));
              }}
              pattern={patterns.EMAIL}
              patternFailedString={i18next.t("common:validateWarning.email")}
            />
            <InputField
              title={t("field.address.title")}
              placeholder={t("field.address.placeholder")}
              value={address}
              onChangeValue={(value) => {
                setAddress(value);
              }}
              error={errorObj.address}
              onChangeError={(value) => {
                setErrorObj((prev) => ({
                  ...prev,
                  address: value,
                }));
              }}
            />
            <InputField
              title={t("field.phone.title")}
              placeholder={t("field.phone.placeholder")}
              value={phone}
              onChangeValue={(value) => {
                setPhone(value);
              }}
              error={errorObj.phone}
              onChangeError={(value) => {
                setErrorObj((prev) => ({
                  ...prev,
                  phone: value,
                }));
              }}
              pattern={patterns.PHONE}
              patternFailedString={i18next.t("common:validateWarning.phone")}
            />
          </div>
        </div>
        <Button
          className="w-full"
          size="large"
          onClick={createNewWorkspace}
          disabled={
            pending ||
            !(
              companyName.length > 0 &&
              website.length > 0 &&
              (patterns.EMAIL.test(email) || email.length === 0) &&
              (patterns.PHONE.test(phone) || phone.length === 0)
            )
          }
        >
          <AddLine />
          {t("button.initiateWorkspace")}
        </Button>
      </div>
    </div>
  );
};

export default CreateWorkspace;
