// Libraries
import { useTranslation } from "react-i18next";
import { type ReactNode, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import { Tabs } from "@lockerpm/design";

// Resources
import { ReactComponent as AddLine } from "#src/assets/images/icons/add-line.svg";

// General
import constants from "#src/config/constants";

// Components
import { ContentSection, StickySection } from "#src/layouts/content";
import { Button } from "#src/components/common/system/Button";
import LoadingState from "#src/components/common/system/LoadingState";
import NoResultsState from "#src/components/common/states/NoResultsState";
import TabButtonLabel from "#src/components/common/helper/antdProps/Tabs/TabButtonLabel";
import { SearchBox } from "#src/components/common/Table/Filter";
import PaginationBar from "#src/components/common/helper/PaginationBar";
import useFetchPaginated from "#src/hooks/useFetchPaginated";

// API-related
import type { IPolicyInfoItem } from "#src/services/devices/policies";
import resourceServices from "#src/services/resource";

// Children
import PolicySelectItem from "#src/components/devices/policies/addPolicies/PolicySelectItem";
import ConfirmAddingPoliciesModal from "#src/components/devices/policies/addPolicies/ConfirmModal";
import { PolicyTabValueEnum } from "#src/components/devices/policies/utils";

const DevicePolicyAddPolicy = () => {
  const { t } = useTranslation("devices", { keyPrefix: "policies.addPage" });

  const { workspace: workspaceId } = useParams<"workspace">();

  const tabList = [
    {
      key: PolicyTabValueEnum.WINDOWS,
      value: "windows",
      label: <TabButtonLabel name={t("tabs.windows.label")} />,
    },
    {
      key: PolicyTabValueEnum.MAC,
      value: "darwin",
      label: <TabButtonLabel name={t("tabs.mac.label")} />,
    },
    {
      key: PolicyTabValueEnum.LINUX,
      value: "linux",
      label: <TabButtonLabel name={t("tabs.linux.label")} />,
    },
  ];

  const [selectedPolicies, setSelectedPolicies] = useState<IPolicyInfoItem[]>(
    []
  );
  const [activeTab, setActiveTab] = useState<{
    key: string;
    value: string;
    label: ReactNode;
  }>(tabList[0]);
  const [searchKeyword, setSearchKeyword] = useState<string>("");

  const [openConfirmModal, setOpenConfirmModal] = useState<boolean>(false);

  const [selectedPage, setSelectedPage] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(constants.DEFAULT_PAGE_SIZE);

  const fetchParams: Parameters<
    typeof resourceServices.list_endpoint_policies
  > = useMemo(
    () => [
      {
        exclude_enabled_workspace_id: workspaceId,
        platform: activeTab.value,
        q: searchKeyword,
        page: selectedPage,
        size: pageSize,
      },
    ],
    [workspaceId, activeTab, searchKeyword, selectedPage, pageSize]
  );

  const onChangeStatusTab = (selected: typeof activeTab) => {
    setActiveTab(selected);
  };

  const onChangeSearchKeyword = (keyword: typeof searchKeyword) => {
    setSearchKeyword(keyword);
  };

  const onChangePage = (page: number) => {
    setSelectedPage(page);
  };

  const onChangePageSize = (size: number) => {
    setPageSize(size);
    setSelectedPage(1);
  };

  const {
    list: policyList,
    count: policyCount,
    isLoading,
    updateData: updatePolicyList,
  } = useFetchPaginated(resourceServices.list_endpoint_policies, fetchParams);

  return workspaceId ? (
    <>
      <StickySection>
        <h1>{t("title")}</h1>
        <Button
          size="large"
          onClick={() => {
            setOpenConfirmModal(true);
          }}
          disabled={selectedPolicies.length === 0}
        >
          <AddLine />
          {t("button.addPolicies", { count: selectedPolicies.length })}
        </Button>
        <ConfirmAddingPoliciesModal
          open={openConfirmModal}
          onClose={() => {
            setOpenConfirmModal(false);
          }}
          selectedPolicies={selectedPolicies}
          workspaceId={workspaceId}
          onRefresh={() => {
            setSelectedPolicies([]);
            updatePolicyList();
          }}
        />
      </StickySection>
      <ContentSection>
        <div className="flex justify-between items-center">
          <Tabs
            items={tabList}
            activeKey={activeTab.key}
            onChange={(key) => {
              const selectedTab = tabList.find((item) => item.value === key);
              if (selectedTab) {
                onChangeStatusTab(selectedTab);
              }
            }}
          />
          <SearchBox
            searchKeyword={searchKeyword}
            onChangeSearchKeyword={onChangeSearchKeyword}
          />
        </div>
        {policyList === null || isLoading ? (
          <LoadingState />
        ) : policyList.length === 0 ? (
          <NoResultsState />
        ) : (
          <div className="grid grid-cols-[2fr_1fr] gap-6">
            <div className="flex flex-col">
              {policyList.map((item) => (
                <PolicySelectItem
                  key={`addPolicies-${item.id}`}
                  data={item}
                  isSelected={selectedPolicies
                    .map((policy) => policy.id)
                    .includes(item.id)}
                  onClickCheckbox={() => {
                    if (
                      selectedPolicies
                        .map((policy) => policy.id)
                        .includes(item.id)
                    ) {
                      setSelectedPolicies((prev) =>
                        prev.filter((p) => p.id !== item.id)
                      );
                    } else {
                      setSelectedPolicies((prev) => [...prev, item]);
                    }
                  }}
                />
              ))}
            </div>
            <PaginationBar
              numOfResult={policyCount}
              selectedPage={selectedPage}
              pageSize={pageSize}
              onChangePage={onChangePage}
              onChangePageSize={onChangePageSize}
            />
          </div>
        )}
      </ContentSection>
    </>
  ) : null;
};

export default DevicePolicyAddPolicy;
