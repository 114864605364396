import type { IFilterItem } from "#src/@types/common";
import i18next from "i18next";
import {
  ScanFrequencyEnum,
  ScanScheduleTypeEnum,
  ScanStatusEnum,
  ScheduleEnableStatusEnum,
} from "./enum";
import { ScanFrequencyResponseValue, ScanStatusResponseValue } from "./value";

/** Filter scan by status */
export const scanStatusItems: IFilterItem[] = [
  {
    key: ScanStatusEnum.PENDING,
    value: ScanStatusResponseValue.PENDING,
    getLabel: () => i18next.t("filter.scanStatus.pending"),
  },
  {
    key: ScanStatusEnum.RUNNING,
    value: ScanStatusResponseValue.RUNNING,
    getLabel: () => i18next.t("filter.scanStatus.running"),
  },
  {
    key: ScanStatusEnum.COMPLETED,
    value: ScanStatusResponseValue.COMPLETED,
    getLabel: () => i18next.t("filter.scanStatus.completed"),
  },
  {
    key: ScanStatusEnum.STOPPING,
    value: ScanStatusResponseValue.STOPPING,
    getLabel: () => i18next.t("filter.scanStatus.stopping"),
  },
  {
    key: ScanStatusEnum.STOPPED,
    value: ScanStatusResponseValue.STOPPED,
    getLabel: () => i18next.t("filter.scanStatus.stopped"),
  },
  {
    key: ScanStatusEnum.ALL,
    value: undefined,
    getLabel: () => i18next.t("filter.scanStatus.all"),
  },
];

/** Filter scan by schedule type */
export const scanScheduleTypeItems: IFilterItem[] = [
  {
    key: ScanScheduleTypeEnum.ON_DEMAND,
    value: "0",
    getLabel: () => i18next.t("filter.scanScheduleType.onDemand"),
  },
  {
    key: ScanScheduleTypeEnum.SCHEDULE,
    value: "1",
    getLabel: () => i18next.t("filter.scanScheduleType.schedule"),
  },
  {
    key: ScanScheduleTypeEnum.ALL,
    value: undefined,
    getLabel: () => i18next.t("filter.scanScheduleType.all"),
  },
];

/** Filter scan by schedule status */
export const scheduleEnableStatusItems: IFilterItem[] = [
  {
    key: ScheduleEnableStatusEnum.ENABLED,
    value: "1",
    getLabel: () => i18next.t("filter.scheduleEnableStatus.enabled"),
  },
  {
    key: ScheduleEnableStatusEnum.DISABLED,
    value: "0",
    getLabel: () => i18next.t("filter.scheduleEnableStatus.disabled"),
  },
  {
    key: ScheduleEnableStatusEnum.ALL,
    value: undefined,
    getLabel: () => i18next.t("filter.scheduleEnableStatus.all"),
  },
];

export const scanFrequencyItems: IFilterItem[] = [
  {
    key: ScanFrequencyEnum.ONCE,
    value: ScanFrequencyResponseValue.ONCE,
    getLabel: () => i18next.t("filter.scanFrequency.once"),
  },
  {
    key: ScanFrequencyEnum.DAILY,
    value: ScanFrequencyResponseValue.DAILY,
    getLabel: () => i18next.t("filter.scanFrequency.daily"),
  },
  {
    key: ScanFrequencyEnum.WEEKLY,
    value: ScanFrequencyResponseValue.WEEKLY,
    getLabel: () => i18next.t("filter.scanFrequency.weekly"),
  },
  {
    key: ScanFrequencyEnum.MONTHLY,
    value: ScanFrequencyResponseValue.MONTHLY,
    getLabel: () => i18next.t("filter.scanFrequency.monthly"),
  },
];
