// Libraries
import type { ReactNode } from "react";
import { Dropdown } from "@lockerpm/design";
import { useTranslation } from "react-i18next";
import i18next from "i18next";

// Resources
import { ReactComponent as CheckboxCircleLine } from "#src/assets/images/icons/checkbox-circle-line.svg";
import { ReactComponent as InformationLine } from "#src/assets/images/icons/information-line.svg";
import { ReactComponent as CloseCircleLine } from "#src/assets/images/icons/close-circle-line.svg";
import { ReactComponent as ArrowDownSLine } from "#src/assets/images/icons/arrow-down-s-line.svg";

// Components
import { ObjectImage } from "#src/components/common/system/Object";
import TableDataCell from "#src/components/common/Table/TableDataCell";
import { TableRowWrapperAnchor } from "#src/components/common/Table/TableRowWrapper";
import DropdownItemLabel from "#src/components/common/helper/antdProps/Dropdown/DropdownItemLabel";
import { apiErrorHandler } from "#src/utils/apiErrorHandler";

// API-related
import type { ITrustCenterComplianceItem } from "#src/services/trustCenter/compliance";
import { ComplianceStatusValue } from "#src/config/filter/trustCenter/value";
import { complianceStatusItems } from "#src/config/filter/trustCenter";
import trustCenterServices from "#src/services/trustCenter";
import { WorkspaceMemberRoleValue } from "#src/config/filter/workspace/value";

const getColorByComplianceStatus: {
  [K: string]: string;
} = {
  [ComplianceStatusValue.NON_COMPLIANCE]:
    "border-red text-red bg-red bg-opacity-10",
  [ComplianceStatusValue.COMPLIANCE]:
    "border-support-seafoam text-support-seafoam bg-support-seafoam bg-opacity-10",
  [ComplianceStatusValue.UNKNOWN]:
    "border-severity-none text-severity-none bg-severity-none-bg",
};

const getIconByComplianceStatus: { [K: string]: ReactNode } = {
  [ComplianceStatusValue.NON_COMPLIANCE]: (
    <CloseCircleLine className="h-5 w-5 min-h-[1.25rem] min-w-[1.25rem]" />
  ),
  [ComplianceStatusValue.COMPLIANCE]: (
    <CheckboxCircleLine className="h-5 w-5 min-h-[1.25rem] min-w-[1.25rem]" />
  ),
  [ComplianceStatusValue.UNKNOWN]: (
    <InformationLine className="h-5 w-5 min-h-[1.25rem] min-w-[1.25rem]" />
  ),
};

interface ITrustCenterComplianceTableItemProps {
  workspaceId: string;
  data: ITrustCenterComplianceItem;
  workspaceRole: string | undefined;
  updateComplianceList: () => void;
}

const TrustCenterComplianceTableItem = ({
  workspaceId,
  data,
  workspaceRole,
  updateComplianceList,
}: ITrustCenterComplianceTableItemProps) => {
  const { t } = useTranslation("trustCenter", { keyPrefix: "compliance" });

  const locale = i18next.language;

  const currentStatus = complianceStatusItems.find(
    (item) => item.value === data.status
  );

  const onChangeStatus = (status: string) => {
    trustCenterServices
      .update_trust_center_compliance(workspaceId, data.id, {
        status,
      })
      .then((response) => {
        if (response.success) {
          updateComplianceList();
        }
      })
      .catch((error) => {
        apiErrorHandler(error, {
          toastMessage: t("notification.changeStatus.fail"),
        });
      });
  };

  return (
    <TableRowWrapperAnchor to={data.compliance.url} target="_blank">
      <TableDataCell className="px-9 py-5 gap-4">
        <ObjectImage
          data={data.compliance.logo}
          className="h-[3.75rem] w-[3.75rem] min-w-[3.75rem] rounded-md"
        />
        <div className="flex flex-col gap-1.5">
          <span className="font-bold-16">{data.compliance.name}</span>
          <span className="font-regular-14-body text-hard-grey">
            {locale === "vi"
              ? data.compliance.description.vi
              : data.compliance.description.en}
          </span>
        </div>
      </TableDataCell>
      <TableDataCell className="px-9 py-5">
        {workspaceRole === WorkspaceMemberRoleValue.OWNER ||
        workspaceRole === WorkspaceMemberRoleValue.ADMIN ? (
          <Dropdown
            menu={{
              items: complianceStatusItems
                .filter((item) => item.value)
                .map((item) => ({
                  key: item.key,
                  label: (
                    <DropdownItemLabel
                      selected={item.value === data.status}
                      syncWidthId="compliance_status-dropdown"
                    >
                      {item.getLabel()}
                    </DropdownItemLabel>
                  ),
                  onClick: () => {
                    // In reality item.value should never be false. This is just to make typescript happy.
                    if (item.value) {
                      onChangeStatus(item.value);
                    }
                  },
                })),
            }}
            trigger={["click"]}
          >
            <div
              id="compliance_status-dropdown"
              className={
                "p-3 flex items-center justify-between gap-3 font-medium-16 rounded-md w-full cursor-pointer " +
                getColorByComplianceStatus[data.status]
              }
              onClick={(e) => {
                e.preventDefault();
              }}
            >
              <div className="flex items-center gap-3">
                {getIconByComplianceStatus[data.status]}
                {currentStatus?.getLabel()}
              </div>
              <ArrowDownSLine className="h-5 w-5 min-h-[1.25rem] min-w-[1.25rem]" />
            </div>
          </Dropdown>
        ) : (
          <div
            className={
              "p-3 flex items-center justify-between gap-3 font-medium-16 rounded-md w-full " +
              getColorByComplianceStatus[data.status]
            }
          >
            <div className="flex items-center gap-3">
              {getIconByComplianceStatus[data.status]}
              {currentStatus?.getLabel()}
            </div>
          </div>
        )}
      </TableDataCell>
    </TableRowWrapperAnchor>
  );
};

export default TrustCenterComplianceTableItem;
