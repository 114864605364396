import { Button } from "#src/components/common/system/Button";
import { ReactComponent as ArrowExpandDownLine } from "#src/assets/images/icons/arrow-expand-down-line.svg";
import {useMemo, useState} from 'react';
import { useTranslation } from "react-i18next";

interface IContentItem {
  [key: string]: string | number;
  count: number;
}

const CardStatistics = ({
  title,
  content,
  keyItem,
}: {
  title: string;
  content: IContentItem[] | undefined;
  keyItem: string;
}) => {
  const { t } = useTranslation('common');
  const [isShowMore, setIsShowMore] = useState<boolean>(false);
  
  const finalContent = useMemo(() => {
    if (!!content && content.length <= 2) {
      return content
    }
    return isShowMore ? content : content?.slice(0, 2)
  }, [isShowMore, content])
  return (
    <div className="rounded-md">
      <div className="py-3 px-6 bg-bright-grey-subline text-dark-blue font-medium-20">
        {title}
      </div>
      <div className="px-6 py-3 bg-bright-grey">
        <ul className="m-0 p-0 space-y-3">
          {finalContent && finalContent.length ? finalContent.map((item, index) => (
            <li key={index} className="flex items-center justify-between gap-6">
              <span className="text-hard-grey font-medium-18">{item[keyItem]}</span>
              <span className="text-dark-blue font-bold-24">{item.count}</span>
            </li>
          )) : null}
        </ul>
        {!!content && content.length > 2 ?
          <div className="flex justify-center">
          <Button onClick={() => setIsShowMore(!isShowMore)} className="flex items-center gap-1 bg-transparent" variant="text">
            <span>{isShowMore ? t("view_less") : t("view_all")}</span>
            <ArrowExpandDownLine className={`${isShowMore ? 'rotate-180' : ''} w-4 h-4`} />
          </Button>
        </div>
        : null}
      </div>
    </div>
  );
}

export default CardStatistics;