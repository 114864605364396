import { ToastState } from "./observer";
import { ToastSlice } from "./ToastSlice";
import { useToastList } from "./useToastList";

/** Where we store the newly cooked toasts. When a toast is created, it will appear here. */
const Toaster = () => {
  const toasts = useToastList();

  return (
    <div className="flex flex-col">
      {toasts.map((toast) => (
        <ToastSlice key={`toast-${toast.id}`} toast={toast}></ToastSlice>
      ))}
    </div>
  );
};

// Redeclare so that we can export it from here.
const createToast = ToastState.createToast;

export { Toaster, createToast };
