import { forwardRef, type HTMLAttributes } from "react";
import { twMerge } from "tailwind-merge";

interface ITableHeaderCellProps extends HTMLAttributes<HTMLDivElement> {
  align?: "left" | "center" | "right";
}

const TableHeaderCell = forwardRef<HTMLDivElement, ITableHeaderCellProps>(
  ({ align = "left", ...props }, ref) => {
    const tailwindAlign =
      align === "left"
        ? "justify-start text-left"
        : align === "right"
        ? "justify-end text-right"
        : "justify-center text-center";

    return (
      <div
        {...props}
        ref={ref}
        className={twMerge(
          `bg-neutral-100 text-slate-500 py-5 px-4 flex items-center ${tailwindAlign}`,
          props.className
        )}
      >
        {props.children}
      </div>
    );
  }
);

TableHeaderCell.displayName = "TableHeaderCell";

export interface ITableHeaderPropItem {
  name: string;
  align: "left" | "center" | "right";
}

/** Same as name
 * @param title - table title, used for React's keys
 * @param headerProps - list of header props, which will be displayed inside this header component
 * @param cellClassName - custom classname for header cells. Might need to be refactored.
 */
const TableHeader = ({
  title,
  headerProps,
  cellClassName,
}: {
  title: string;
  headerProps: ITableHeaderPropItem[];
  cellClassName?: string;
}) => {
  return (
    <>
      {headerProps.map((header, index) => {
        return (
          <TableHeaderCell
            key={`t${title}-th${header.name}id${index}`}
            align={header.align}
            className={cellClassName ? cellClassName : undefined}
          >
            {header.name}
          </TableHeaderCell>
        );
      })}
    </>
  );
};

export default TableHeader;
