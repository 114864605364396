// Libraries
import { useTranslation } from "react-i18next";

// Resources
import { ReactComponent as EyeLine } from "#src/assets/images/icons/eye-line.svg";
import { ReactComponent as EditLine } from "#src/assets/images/icons/edit-line.svg";

// General
import type { IFilterItem } from "#src/@types/common";

// Components
import { Button, IconButton } from "#src/components/common/system/Button";

// API-related
import type { IKeywordItem } from "#src/services/dataLeak";

export const KeywordLabel = ({
  value,
  selected = false,
  onClick,
}: {
  value: string;
  selected?: boolean;
  onClick: () => void;
}) => {
  return (
    <button
      className={`flex gap-2 font-medium-18 ${
        selected ? "" : "text-hard-grey"
      }`}
      onClick={onClick}
    >
      <span className="text-primary">#</span>
      {value}
    </button>
  );
};

interface IDataLeakKeywordBarProps {
  keywordList: IKeywordItem[] | null;
  selectedKeyword: IFilterItem | null;
  onChangeSelectedKeyword: (selected: IFilterItem | null) => void;
  onOpenEditKeywordDrawer: () => void;
  onOpenViewKeywordDrawer: () => void;
}

const DataLeakKeywordBar = ({
  keywordList,
  selectedKeyword,
  onChangeSelectedKeyword,
  onOpenEditKeywordDrawer,
  onOpenViewKeywordDrawer,
}: IDataLeakKeywordBarProps) => {
  const { t } = useTranslation("dataLeakDetection");

  return (
    <div className="flex items-center justify-between gap-6 border border-grey rounded-md px-6 py-3">
      <div className="flex gap-6 overflow-hidden">
        {keywordList
          ? keywordList.map((item) => (
              <KeywordLabel
                key={`dataleak-keywordbar-keyword${item.id}`}
                value={item.keyword}
                selected={item.id.toString() === selectedKeyword?.key}
                onClick={() => {
                  if (item.id.toString() === selectedKeyword?.key) {
                    onChangeSelectedKeyword(null);
                  } else {
                    onChangeSelectedKeyword({
                      key: item.id.toString(),
                      value: item.id.toString(),
                      getLabel: () => item.keyword,
                    });
                  }
                }}
              />
            ))
          : null}
      </div>
      <div className="flex gap-1">
        <Button
          size="large"
          variant="secondary"
          onClick={onOpenViewKeywordDrawer}
        >
          <EyeLine className="h-5 w-5" />
          {t("button.showAll")}
        </Button>
        <IconButton variant="secondary" onClick={onOpenEditKeywordDrawer}>
          <EditLine className="h-5 w-5" />
        </IconButton>
      </div>
    </div>
  );
};

export default DataLeakKeywordBar;
