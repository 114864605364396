// Libraries
import { useTranslation } from "react-i18next";
import { useState } from "react";
import {
  DndContext,
  type DragStartEvent,
  type DragEndEvent,
  DragOverlay,
  KeyboardSensor,
  PointerSensor,
  closestCenter,
  useSensor,
  useSensors,
} from "@dnd-kit/core";
import {
  SortableContext,
  arrayMove,
  sortableKeyboardCoordinates,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import { restrictToVerticalAxis } from "@dnd-kit/modifiers";

// Resources
import { ReactComponent as EditLine } from "#src/assets/images/icons/edit-line.svg";
import { ReactComponent as DeleteBin6Line } from "#src/assets/images/icons/delete-bin-6-line.svg";

// General
import global from "#src/config/global";

// Components
import { TableActionLabel, TableHeader } from "#src/components/common/Table";
import type { ITableHeaderPropItem } from "#src/components/common/Table/TableHeader";
import { createToast } from "#src/components/common/system/toasts";
import { apiErrorHandler } from "#src/utils/apiErrorHandler";

// API-related
import type { IControlItem } from "#src/services/devices/control";
import devicesServices from "#src/services/devices";

// Children
import { ControlTableRow, SortableControlTableRow } from "./TableRow";

interface IControlTableGridProps {
  workspaceId: string;
  controlList: IControlItem[];
  onChangeSelectedControl: (selected: IControlItem) => void;
  selectedControlIds: string[];
  onOpenEditDrawer: (itemId: string) => void;
  onRefresh: () => void;
  disableSort: boolean;
}

const ControlTableGrid = ({
  workspaceId,
  controlList,
  onChangeSelectedControl,
  selectedControlIds,
  onOpenEditDrawer,
  onRefresh,
  disableSort,
}: IControlTableGridProps) => {
  const { t } = useTranslation("devices", { keyPrefix: "control" });

  const headers: ITableHeaderPropItem[] = [
    { name: "", align: "left" },
    { name: "", align: "left" },
    { name: t("headers.controlAction"), align: "left" },
    { name: t("headers.targets"), align: "left" },
    { name: t("headers.source"), align: "left" },
    { name: t("headers.appliesTo"), align: "left" },
    { name: t("headers.notes"), align: "left" },
    { name: t("headers.actions"), align: "center" },
  ];

  const [optimisticControlList, setOptimisticControlList] =
    useState(controlList);
  const [reordering, setReordering] = useState<boolean>(false);

  // TODO: useEffect to sync optimistic state with API returned state -> write custom hook to manage optimistic

  const actionList = [
    {
      key: "edit-control",
      label: <TableActionLabel icon={<EditLine />} text={t("actions.edit")} />,
    },
    {
      key: "delete-control",
      label: (
        <TableActionLabel
          icon={<DeleteBin6Line />}
          text={t("actions.delete")}
          warning
        />
      ),
    },
  ];

  const onDeleteControl = (itemId: string) => {
    devicesServices
      .delete_control(workspaceId, itemId)
      .then(() => {
        onRefresh();
      })
      .catch((error) => {
        apiErrorHandler(error, {
          toastMessage: t("notification.deleteControl.fail"),
        });
      });
  };

  const onClickActionItems = (key: string, item: IControlItem) => {
    switch (key) {
      case "edit-control":
        onOpenEditDrawer(item.id);
        return;
      case "delete-control":
        global.confirm(() => {
          onDeleteControl(item.id);
        });
        return;
    }
  };

  const [activeControl, setActiveControl] = useState<IControlItem | null>(null);
  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  );

  function handleDragStart(event: DragStartEvent) {
    const { active } = event;

    if (active) {
      setActiveControl(
        optimisticControlList.find((item) => item.id === active.id) ?? null
      );
    }
  }

  function handleDragEnd(event: DragEndEvent) {
    const { active, over } = event;

    if (active.id !== over?.id) {
      setReordering(true);
      const oldIndex = active
        ? optimisticControlList.findIndex((item) => item.id === active.id)
        : -1;
      const newIndex = over
        ? optimisticControlList.findIndex((item) => item.id === over.id)
        : -1;

      setOptimisticControlList((items) => {
        return arrayMove(items, oldIndex, newIndex);
      });

      // We have to send 2 ids to the server: "id" from the item that is dragged, and "swap_id" from the item that is right above the dragged one after dragging is completed
      const draggedId = optimisticControlList[oldIndex].id;
      const draggedSwapId =
        oldIndex < newIndex
          ? optimisticControlList[newIndex].id
          : optimisticControlList[newIndex - 1]?.id ?? null;

      createToast({
        type: "info",
        message: t("notification.changePriority.start.title"),
        detail: t("notification.changePriority.start.message"),
      });

      devicesServices
        .change_control_priority(workspaceId, {
          id: draggedId,
          swap_id: draggedSwapId,
        })
        .then(() => {
          // TODO: optimistic UI
          onRefresh();
          setReordering(false);
          createToast({
            type: "success",
            message: t("notification.changePriority.success.title"),
          });
        })
        .catch(() => {
          createToast({
            type: "error",
            message: t("notification.changePriority.fail.title"),
            detail: t("notification.changePriority.fail.message"),
          });
        });
    }
  }

  return (
    <div className="grid grid-cols-[2.75rem_4rem_3fr_3fr_4fr_4fr_4fr_2fr]">
      <TableHeader title="devices/control/network" headerProps={headers} />
      <DndContext
        sensors={sensors}
        collisionDetection={closestCenter}
        onDragStart={handleDragStart}
        onDragEnd={handleDragEnd}
      >
        <SortableContext
          items={optimisticControlList.map((item) => item.id)}
          strategy={verticalListSortingStrategy}
        >
          {optimisticControlList.map((control) => (
            <SortableControlTableRow
              key={control.id}
              data={control}
              actionList={actionList}
              onClickActionItems={onClickActionItems}
              selectedControlIds={selectedControlIds}
              onChangeSelectedControl={onChangeSelectedControl}
              disableSort={disableSort || reordering}
            />
          ))}
        </SortableContext>
        <DragOverlay modifiers={[restrictToVerticalAxis]}>
          {activeControl ? (
            <div className="grid grid-cols-[2.75rem_4rem_3fr_3fr_4fr_4fr_4fr_2fr] gap-y-4 bg-bright-blue">
              {/* This is only used for display purpose, no actions should be passed */}
              <ControlTableRow
                data={activeControl}
                actionList={[]}
                onClickActionItems={() => {}}
                selectedControlIds={selectedControlIds}
                onChangeSelectedControl={() => {}}
                disableSort={true}
              />
            </div>
          ) : null}
        </DragOverlay>
      </DndContext>
    </div>
  );
};

export default ControlTableGrid;
