import endpoint from "#src/config/endpoint";
import request from "#src/utils/request";

export interface ITrustCenterCurrentPlan {
  product_plan_id: null;
  product_id: "trust_center";
}

export function retrieve_trust_center_plan(workspace_id: string) {
  return request.get<never, ITrustCenterCurrentPlan>(
    `${endpoint.WORKSPACES}/${workspace_id}/trust_center/plan`
  );
}

interface ITrustCenterPlanRequestEnterprise {
  product_plan_id: "tc_enterprise";
  gender: string;
  fullname: string;
  phone_number: string;
  // TODO: should be an enum
  job_title: string;
  organization_name: string;
  industry: string;
  // TODO: should be an enum
  employee_size: string;
  email: string;
}

type ITrustCenterPlanRequest =
  | { product_plan_id: "tc_free" }
  | ITrustCenterPlanRequestEnterprise;

export function request_trust_center_plan(
  workspace_id: string,
  data: ITrustCenterPlanRequest
) {
  return request.post<never, ITrustCenterCurrentPlan>(
    `${endpoint.WORKSPACES}/${workspace_id}/trust_center/plan`,
    data
  );
}
