import { useTranslation } from "react-i18next";
import type { ReactNode } from "react";
import type { IFilterItem } from "#src/@types/common";
import { SeverityLabel } from "#src/components/common/labels";

export const SeverityDropdownRender = (menu: ReactNode) => (
  <div className="p-3 w-fit bg-white rounded shadow-[0_6px_16px_0_rgba(0,_0,_0,_0.08),0_3px_6px_-4px_rgba(0,_0,_0,_0.12),0_9px_28px_8px_rgba(0,_0,_0,_0.05)]">
    {menu}
  </div>
);

export const SeverityDropdownItemLabel = ({ item }: { item: IFilterItem }) => {
  const { t } = useTranslation();

  return (
    <div className={`flex justify-center items-center py-0.5 h-10`}>
      {item.key.includes("ALL") ? (
        <span className="text-hard-grey font-medium-16">{t("filter.all")}</span>
      ) : (
        <SeverityLabel severity={item.value || ""} />
      )}
    </div>
  );
};
