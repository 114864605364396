import { Modal } from "@lockerpm/design";
// import { useNavigate, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import i18next from "i18next";
import dayjs from "dayjs";
import viDayjsLocale from "dayjs/locale/vi";

import { ExclamationCircleOutlined } from "@ant-design/icons";

import "./assets/css/index.scss";

// import systemServices from "./services/system";
// import authServices from "./services/auth";
// import workspaceServices from "#src/services/workspace";

// import i18n from "./config/i18n";
import global from "./config/global";
import "./config/marked";

// import pages from "./pages";
// import { updateUserInfo } from "./store/slices/auth";
// import { useAppDispatch, useAppSelector } from "./store/hooks";
// import { selectWorkspace, updateWorkspaceList } from "./store/slices/workspace";

// import Cookies from "js-cookie";
// import pathname from "./config/pathname";
import DOMPurify from "dompurify";
import { Router } from "#src/config/router";
import { Toaster } from "#src/components/common/system/toasts";

// Every links on markdown will be opened in new tab, since we use DOMPurify to purge malicious attributes, including "target"
DOMPurify.addHook("afterSanitizeAttributes", (node) => {
  // Typeguard to narrow Node into Element.
  function nodeIsElement(node: Node): node is Element {
    // MDN reference: https://developer.mozilla.org/en-US/docs/Web/API/Node/nodeType
    return node.nodeType === 1;
  }

  if (nodeIsElement(node)) {
    node.setAttribute("target", "_blank");
  }
});

// Preload Vietnamese for dayjs. English has been loaded by default.
dayjs.locale(viDayjsLocale, {}, true);

// DEPRECATED: we are moving into new chart system. Also we have new default font. Remove this when Apex chart is no longer used.
// @ts-ignore
// window.Apex.chart = {
//   fontFamily:
//     '-apple-system, BlinkMacSystemFont, "Segoe UI", "Noto Sans", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji"',
// };

window.cspfVersion = "1.5.4";

const App = () => {
  // const navigate = useNavigate();
  // const dispatch = useAppDispatch();
  const { t } = useTranslation();
  // const location = useLocation();

  const locale = i18next.language;
  dayjs.locale(locale);

  global.confirm = (handleOK = () => {}) =>
    Modal.confirm({
      title: t("common.confirm"),
      icon: <ExclamationCircleOutlined />,
      content: t("common.delete_question"),
      okText: t("button.delete"),
      cancelText: t("button.cancel"),
      okButtonProps: {
        danger: true,
      },
      onOk: handleOK,
      centered: true,
    });

  // const userInfo = useAppSelector((state) => state.auth.userInfo);

  // useEffect(() => {
  //   if (currentPage && !currentPage.noAuthRequired) {
  //     const accessToken = authServices.access_token();
  //     if (!accessToken) {
  //       authServices.redirect_login();
  //     } else {
  //       initData();
  //     }
  //   }
  // }, []);

  return (
    <>
      {/* We need this high z-index to put the toaster on top of Antd's components, since Antd set their z-index stupidly high. */}
      <div className="absolute z-[1010] top-6 right-6 max-h-[calc(100vh_-_2rem)] overflow-hidden">
        <Toaster />
      </div>
      <Router />
    </>
  );
};

export default App;
