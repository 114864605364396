export const SeverityResponseValue = {
  CRITICAL: "CRITICAL",
  HIGH: "HIGH",
  MEDIUM: "MEDIUM",
  LOW: "LOW",
  INFORMATION: "INFORMATION",
  NONE: "NONE",
};

export const VulnerabilityStatusResponseValue = {
  OPEN: "open",
  ACCEPTED: "accepted",
  REJECTED: "rejected",
  NEW: "new",
  TRIAGED: "triaged",
  RESOLVED: "resolved",
  UNRESOLVED: "unresolved",
  DUPLICATE: "duplicate",
  OUT_OF_SCOPE: "out_of_scope",
  NOT_REPRODUCIBLE: "not_reproducible",
  WONT_FIX: "wont_fix",
  NOT_APPLICABLE: "not_applicable",
  SPAM: "spam",
};
