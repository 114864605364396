// Libraries
import { useTranslation } from "react-i18next";
import { Dropdown } from "@lockerpm/design";

// Resources
import { ReactComponent as EditLine } from "#src/assets/images/icons/edit-line.svg";

// General
import type { IFilterItem } from "#src/@types/common";

// Components
import FilterDropdown from "#src/components/common/Table/Filter/FilterDropdown";
import { getTwColorByStatus } from "#src/components/common/variants/byStatus";
import {
  SeverityDropdownItemLabel,
  SeverityDropdownRender,
} from "#src/components/common/composed/SeverityDropdownHelper";
import {
  SearchBox,
  SelectAllItemsDropdown,
} from "#src/components/common/Table/Filter";
import VulnerabilityStatusFilterDropdown from "#src/components/common/Table/Filter/StatusFilterDropdown";
import { IconButton } from "#src/components/common/system/Button";

// API-related
import {
  cvssScoreItems,
  severityItems,
  vulnerabilityStatusItems,
} from "#src/config/filter/vulnerability";

interface IVulnerabilityFilterProps {
  severity: IFilterItem | null;
  cvssScore: IFilterItem | null;
  status: IFilterItem[] | null;
  searchKeyword: string;
  totalCount: number;
  selectedCount: number;
  onChangeSeveritySelection: (selected: IFilterItem | null) => void;
  onChangeCvssScoreSelection: (selected: IFilterItem | null) => void;
  onChangeStatusSelection: (selected: IFilterItem[] | null) => void;
  onChangeSearchKeyword: (keyword: string) => void;
  onSelectAllVulnerabilities: () => void;
  onDeselectAllVulnerabilities: () => void;
  onEditSelectedVulnerabilites: (status: IFilterItem) => void;
}

const VulnerabilityFilter = ({
  severity,
  cvssScore,
  status,
  searchKeyword,
  totalCount,
  selectedCount,
  onChangeSeveritySelection,
  onChangeCvssScoreSelection,
  onChangeStatusSelection,
  onChangeSearchKeyword,
  onSelectAllVulnerabilities,
  onDeselectAllVulnerabilities,
  onEditSelectedVulnerabilites,
}: IVulnerabilityFilterProps) => {
  const { t } = useTranslation("assets", {
    keyPrefix: "domains.page.details.tab.vulnerabilities.filter",
  });

  return (
    <div className="flex justify-between gap-1">
      <div className="flex gap-1 max-w-[100%_-_15rem] whitespace-nowrap flex-wrap">
        <SelectAllItemsDropdown
          selectedCount={selectedCount}
          totalCount={totalCount}
          onSelectAll={onSelectAllVulnerabilities}
          onDeselectAll={onDeselectAllVulnerabilities}
        />
        <Dropdown
          menu={{
            items: vulnerabilityStatusItems.map((item) => ({
              key: item.key,
              value: item.value,
              type: item.type,
              label: (
                <div className="px-3 py-2 bg-bright-grey text-medium-grey font-medium-14 w-[13.5rem]">
                  {item.getLabel()}
                </div>
              ),
              children: item.children.map((childItem) => ({
                ...childItem,
                label: (
                  <div className="flex items-center gap-2 p-3 text-medium-grey font-medium-16 w-[13.5rem]">
                    <div
                      className={`${
                        childItem.value
                          ? getTwColorByStatus(childItem.value)
                          : ""
                      } h-2 w-2 rounded-full`}
                    />
                    {childItem.getLabel()}
                  </div>
                ),
              })),
            })),
            onClick: (obj) => {
              const newSelected = vulnerabilityStatusItems
                .map((item) => item.children)
                .flat()
                .find((item) => item.key === obj.key);
              if (newSelected) onEditSelectedVulnerabilites(newSelected);
            },
          }}
          trigger={["click"]}
          disabled={selectedCount === 0}
        >
          <IconButton variant="ghost">
            <EditLine />
          </IconButton>
        </Dropdown>

        {/* TODO: Replace this with SeverityDropdown */}
        <FilterDropdown
          label={t("severity.label")}
          selected={severity}
          options={severityItems}
          CustomLabel={SeverityDropdownItemLabel}
          DropdownRender={SeverityDropdownRender}
          onChangeSelection={onChangeSeveritySelection}
        />
        <FilterDropdown
          label={t("cvssScore.label")}
          selected={cvssScore}
          options={cvssScoreItems}
          onChangeSelection={onChangeCvssScoreSelection}
        />
        <VulnerabilityStatusFilterDropdown
          label={t("status.label")}
          selected={status}
          options={vulnerabilityStatusItems}
          onChangeSelection={onChangeStatusSelection}
        />
      </div>

      <SearchBox
        searchKeyword={searchKeyword}
        onChangeSearchKeyword={onChangeSearchKeyword}
      />
    </div>
  );
};

export default VulnerabilityFilter;
