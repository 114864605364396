export enum PentestStatusEnum {
  DRAFT = "PENTEST_STATUS_ENUM_DRAFT",
  REQUESTED = "PENTEST_STATUS_ENUM_REQUESTED",
  EXECUTION = "PENTEST_STATUS_ENUM_EXECUTION",
  CLOSURE = "PENTEST_STATUS_ENUM_CLOSURE",
  PAUSE = "PENTEST_STATUS_ENUM_PAUSE",
  ALL = "PENTEST_STATUS_ENUM_ALL",
}

export enum PentestScopeTypeEnum {
  DOMAIN = "PENTEST_SCOPE_TYPE_ENUM_DOMAIN",
  SUBDOMAIN = "PENTEST_SCOPE_TYPE_ENUM_SUBDOMAIN",
  MOBILE = "PENTEST_SCOPE_TYPE_ENUM_MOBILE",
  DESKTOP = "PENTEST_SCOPE_TYPE_ENUM_DESKTOP",
  SOURCE_CODE = "PENTEST_SCOPE_TYPE_ENUM_SOURCE_CODE",
  SMART_CONTRACT = "PENTEST_SCOPE_TYPE_ENUM_SMART_CONTRACT",
}

export enum PentestScopeScopeEnum {
  IN_SCOPE = "PENTEST_SCOPE_SCOPE_ENUM_IN_SCOPE",
  OUT_OF_SCOPE = "PENTEST_SCOPE_SCOPE_ENUM_OUT_OF_SCOPE",
}

export enum PentestMemberRoleEnum {
  WORKSPACE_OWNER = "PENTEST_MEMBER_ROLE_ENUM_WORKSPACE_OWNER",
  PROJECT_OWNER = "PENTEST_MEMBER_ROLE_ENUM_PROJECT_OWNER",
  ADMIN = "PENTEST_MEMBER_ROLE_ENUM_ADMIN",
  DEVELOPER = "PENTEST_MEMBER_ROLE_ENUM_DEVELOPER",
}

export enum MsspPentestMemberRoleEnum {
  SECURITY_MANAGER = "PENTEST_MEMBER_ROLE_ENUM_SECURITY_MANAGER",
  PENTESTER = "PENTEST_MEMBER_ROLE_ENUM_PENTESTER",
}
