// Libraries
import { useTranslation } from "react-i18next";
import { Tabs } from "@lockerpm/design";
import { useMemo, useState } from "react";
import { useParams } from "react-router-dom";

// General
import constants from "#src/config/constants";
import type { IFilterItem } from "#src/@types/common";

// Components
import { ContentSection, StickySection } from "#src/layouts/content";
import TabButtonLabel from "#src/components/common/helper/antdProps/Tabs/TabButtonLabel";
import PaginationBar from "#src/components/common/helper/PaginationBar";
import useFetchPaginated from "#src/hooks/useFetchPaginated";
import useFetch from "#src/hooks/useFetch";

// Children
import dataLeakServices, {
  type ILeakedDataEmailDetails,
  type ILeakedDataSourceCodeDetails,
  type ILeakedDataAccountDetails,
  type ILeakedDataCookieDetails,
  type ILeakedDataItem,
} from "#src/services/dataLeak";
import DataLeakKeywordBar from "#src/components/data-leak/KeywordBar";
import DataLeakFilter from "#src/components/data-leak/Filter";
import EditKeywordsDrawer from "#src/components/data-leak/EditKeywordsDrawer";
import ViewKeywordDrawer from "#src/components/data-leak/ViewKeywordsDrawer";
import DataLeakAccountTable from "#src/components/data-leak/Tabs/Account";
import DataLeakCookieTable from "#src/components/data-leak/Tabs/Cookie";
import DataLeakEmailTable from "#src/components/data-leak/Tabs/Email";
import DataLeakSourceCodeTable from "#src/components/data-leak/Tabs/SourceCode";
import { vulnerabilityStatusItems } from "#src/config/filter/vulnerability";

const TotalLeaksFound = () => {
  const { t } = useTranslation("dataLeakDetection");

  const { workspace: workspaceId } = useParams<"workspace">();

  const DataLeakTabEnum = {
    ACCOUNT: "DATALEAK_TAB_ENUM_ACCOUNT",
    COOKIE: "DATALEAK_TAB_ENUM_COOKIE",
    EMAIL: "DATALEAK_TAB_ENUM_EMAIL",
    SOURCE_CODE: "DATALEAK_TAB_ENUM_SOURCE_CODE",
  };

  const dataLeakTabItems: IFilterItem[] = [
    {
      key: DataLeakTabEnum.ACCOUNT,
      value: "account",
      getLabel: () => t("tabs.account.label"),
    },
    {
      key: DataLeakTabEnum.COOKIE,
      value: "cookie",
      getLabel: () => t("tabs.cookie.label"),
    },
    {
      key: DataLeakTabEnum.EMAIL,
      value: "email",
      getLabel: () => t("tabs.email.label"),
    },
    {
      key: DataLeakTabEnum.SOURCE_CODE,
      value: "source_code",
      getLabel: () => t("tabs.sourceCode.label"),
    },
  ];

  const [activeTab, setActiveTab] = useState<IFilterItem>(dataLeakTabItems[0]);
  const [selectedPage, setSelectedPage] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(constants.DEFAULT_PAGE_SIZE);
  const [severity, setSeverity] = useState<IFilterItem | null>(null);
  const [status, setStatus] = useState<IFilterItem[] | null>(null);
  const [selectedKeyword, setSelectedKeyword] = useState<IFilterItem | null>(
    null
  );
  const [searchKeyword, setSearchKeyword] = useState<string>("");
  const [from, setFrom] = useState<number | null>(null);
  const [to, setTo] = useState<number | null>(null);

  const [openEditKeywordDrawer, setOpenEditKeywordDrawer] =
    useState<boolean>(false);
  const [openViewKeywordDrawer, setOpenViewKeywordDrawer] =
    useState<boolean>(false);

  const fetchParams = useMemo<
    Parameters<typeof dataLeakServices.list_data_leak>
  >(
    () => [
      workspaceId ?? "",
      {
        from: from ? from : undefined,
        to: to ? to : undefined,
        filter_type: activeTab.value,
        filter_severity: severity?.value,
        filter_status: undefined,
        filter_sub_status:
          status && status.length > 0
            ? status.map((item) => item.value || "").join(",")
            : undefined,
        filter_keyword_ids: selectedKeyword?.value,
        q: searchKeyword,
        page: selectedPage,
        size: pageSize,
      },
    ],
    [
      workspaceId,
      activeTab,
      from,
      to,
      severity,
      status,
      selectedKeyword,
      searchKeyword,
      selectedPage,
      pageSize,
    ]
  );

  const statisticParams = useMemo<
    Parameters<typeof dataLeakServices.statistics_data_leak>
  >(
    () => [
      workspaceId ?? "",
      {
        from: from ? from : undefined,
        to: to ? to : undefined,
        filter_type: undefined,
        filter_severity: severity?.value,
        filter_status: undefined,
        filter_sub_status:
          status && status.length > 0
            ? status.map((item) => item.value || "").join(",")
            : undefined,
        filter_keyword_ids: selectedKeyword?.value,
        q: searchKeyword,
        page: selectedPage,
        size: pageSize,
      },
    ],
    [
      workspaceId,
      from,
      to,
      severity,
      status,
      selectedKeyword,
      searchKeyword,
      selectedPage,
      pageSize,
    ]
  );

  const onChangeTimeConditionValue = (
    condition: string,
    from: number,
    to: number
  ) => {
    if (condition) {
      setFrom(from);
      setTo(to);
    } else {
      setFrom(null);
      setTo(null);
    }
    setSelectedPage(1);
  };

  const onChangeSeveritySelection = (selected: typeof severity) => {
    setSeverity(selected);
    setSelectedPage(1);
  };

  const onChangeStatusSelection = (
    selected: IFilterItem[] | IFilterItem | null
  ) => {
    if (selected !== null && !Array.isArray(selected)) {
      if (typeof selected.value !== "string") {
        selected = vulnerabilityStatusItems.map((item) => item.children).flat();
      } else {
        selected = [selected];
      }
    }
    setStatus(selected);
    setSelectedPage(1);
  };

  const onChangeSelectedKeywordSelection = (
    selected: typeof selectedKeyword
  ) => {
    setSelectedKeyword(selected);
    setSelectedPage(1);
  };

  const onChangeSearchKeyword = (keyword: string) => {
    setSearchKeyword(keyword);
    setSelectedPage(1);
  };

  const onChangePage = (selectedPage: number) => {
    setSelectedPage(selectedPage);
  };

  const onChangePageSize = (size: number) => {
    setPageSize(size);
    setSelectedPage(1);
  };

  const onChangeTab = (key: typeof activeTab) => {
    setActiveTab(key);
  };

  const { result: dataLeakStatistics, updateData: updateDataLeakStatistics } =
    useFetch(dataLeakServices.statistics_data_leak, statisticParams);

  const {
    list: dataLeakList,
    count: dataLeakCount,
    isLoading,
    updateData: updateDataLeakList,
  } = useFetchPaginated(dataLeakServices.list_data_leak, fetchParams);

  const tabList = workspaceId
    ? [
        {
          key: DataLeakTabEnum.ACCOUNT,
          label: (
            <TabButtonLabel
              name={t("tabs.account.label")}
              count={dataLeakStatistics?.account}
            />
          ),
        },
        {
          key: DataLeakTabEnum.COOKIE,
          label: (
            <TabButtonLabel
              name={t("tabs.cookie.label")}
              count={dataLeakStatistics?.cookie}
            />
          ),
        },
        {
          key: DataLeakTabEnum.EMAIL,
          label: (
            <TabButtonLabel
              name={t("tabs.email.label")}
              count={dataLeakStatistics?.email}
            />
          ),
        },
        {
          key: DataLeakTabEnum.SOURCE_CODE,
          label: (
            <TabButtonLabel
              name={t("tabs.sourceCode.label")}
              count={dataLeakStatistics?.source_code}
            />
          ),
        },
      ]
    : [];

  const fetchKeywordParams = useMemo(() => [workspaceId, {}], [workspaceId]);
  const { list: keywordBarList, updateData: updateKeywordBar } =
    useFetchPaginated(dataLeakServices.list_keywords, fetchKeywordParams);

  return workspaceId ? (
    <>
      <StickySection>
        <div className="flex flex-col gap-6 flex-1 w-full">
          <h1>{t("title")}</h1>
          <DataLeakKeywordBar
            keywordList={keywordBarList}
            selectedKeyword={selectedKeyword}
            onChangeSelectedKeyword={onChangeSelectedKeywordSelection}
            onOpenEditKeywordDrawer={() => {
              setOpenEditKeywordDrawer(true);
            }}
            onOpenViewKeywordDrawer={() => {
              setOpenViewKeywordDrawer(true);
            }}
          />
        </div>
      </StickySection>
      <ContentSection>
        <Tabs
          items={tabList}
          activeKey={activeTab.key}
          onChange={(key) => {
            const selected = dataLeakTabItems.find((item) => item.key === key);
            if (selected) {
              onChangeTab(selected);
            }
          }}
          destroyInactiveTabPane
        />
        <DataLeakFilter
          workspaceId={workspaceId}
          severity={severity}
          status={status}
          selectedKeyword={selectedKeyword}
          searchKeyword={searchKeyword}
          onChangeTimeConditionValue={onChangeTimeConditionValue}
          onChangeSeveritySelection={onChangeSeveritySelection}
          onChangeStatusSelection={onChangeStatusSelection}
          onChangeSelectedKeywordSelection={onChangeSelectedKeywordSelection}
          onChangeSearchKeyword={onChangeSearchKeyword}
        />
        {activeTab.key === DataLeakTabEnum.ACCOUNT ? (
          <DataLeakAccountTable
            workspaceId={workspaceId}
            dataLeakList={
              dataLeakList as ILeakedDataItem<ILeakedDataAccountDetails>[]
            }
            isLoading={
              isLoading ||
              (dataLeakList
                ? dataLeakList.some((item) => item.type !== "account")
                : false)
            }
            updateDataLeakList={updateDataLeakList}
          />
        ) : null}
        {activeTab.key === DataLeakTabEnum.COOKIE ? (
          <DataLeakCookieTable
            workspaceId={workspaceId}
            dataLeakList={
              dataLeakList as ILeakedDataItem<ILeakedDataCookieDetails>[]
            }
            isLoading={
              isLoading ||
              (dataLeakList
                ? dataLeakList.some((item) => item.type !== "cookie")
                : false)
            }
          />
        ) : null}
        {activeTab.key === DataLeakTabEnum.EMAIL ? (
          <DataLeakEmailTable
            workspaceId={workspaceId}
            dataLeakList={
              dataLeakList as ILeakedDataItem<ILeakedDataEmailDetails>[]
            }
            isLoading={
              isLoading ||
              (dataLeakList
                ? dataLeakList.some((item) => item.type !== "email")
                : false)
            }
            updateDataLeakList={updateDataLeakList}
          />
        ) : null}
        {activeTab.key === DataLeakTabEnum.SOURCE_CODE ? (
          <DataLeakSourceCodeTable
            workspaceId={workspaceId}
            dataLeakList={
              dataLeakList as ILeakedDataItem<ILeakedDataSourceCodeDetails>[]
            }
            isLoading={
              isLoading ||
              (dataLeakList
                ? dataLeakList.some((item) => item.type !== "source_code")
                : false)
            }
            updateDataLeakList={updateDataLeakList}
          />
        ) : null}
        <PaginationBar
          numOfResult={dataLeakCount}
          selectedPage={selectedPage}
          pageSize={pageSize}
          onChangePage={onChangePage}
          onChangePageSize={onChangePageSize}
        />
      </ContentSection>
      <EditKeywordsDrawer
        open={openEditKeywordDrawer}
        onClose={() => {
          setOpenEditKeywordDrawer(false);
        }}
        workspaceId={workspaceId}
        onRefresh={() => {
          updateDataLeakList();
          updateDataLeakStatistics();
          updateKeywordBar();
        }}
      />
      <ViewKeywordDrawer
        open={openViewKeywordDrawer}
        onClose={() => {
          setOpenViewKeywordDrawer(false);
        }}
        workspaceId={workspaceId}
        onRefresh={() => {}}
        onOpenEditDrawer={() => {
          setOpenEditKeywordDrawer(true);
        }}
        onChangeSelectedKeyword={onChangeSelectedKeywordSelection}
      />
    </>
  ) : null;
};

export default TotalLeaksFound;
