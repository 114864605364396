import type { IPaginateParams, IPaginatedResponse } from "#src/@types/api";
import endpoint from "#src/config/endpoint";
import request from "#src/utils/request";
import {
  accept_join_request,
  list_join_requests,
  reject_join_request,
} from "./accessRequest";
import {
  blacklist_researcher,
  list_program_blocked_researchers,
  unblock_researcher,
} from "./blacklist";
import {
  create_program_integration,
  list_program_integrations,
  retrieve_program_integration_details,
  update_program_integration,
} from "./integration";
import {
  create_program_member,
  delete_program_member,
  list_program_members,
  list_program_mssp_members,
  update_program_member,
} from "./members";
import { retrieve_program_mssp, update_program_mssp } from "./mssp";
import {
  list_program_requirements,
  update_allow_to_see_program,
  update_program_requirement,
} from "./requirements";

export interface IBugBountyProgram {
  alias: string;
  org_alias: string;
  in_review: boolean;
  status: string;
  type: string;
  created_time: number;
  updated_time: number | null;
  last_update: number | null;
  currency: string;
  mode: string;
  is_wh_managed: boolean;
  nda: boolean;
  program_plan: string;
  mssp: { id: string; name: string } | null;
  role: string | null;
  org: {
    alias: string;
    name: string;
    email: string;
    address: string;
    phone: string;
    color: string;
    description: {
      en: string;
      vi: string;
    };
    logo: string;
    social: { name?: string; url?: string };
  };
}

function list_programs(workspace_id: string, params: any & IPaginateParams) {
  return request.get<never, IPaginatedResponse<IBugBountyProgram>>(
    `${endpoint.WORKSPACES}/${workspace_id}/programs`,
    { params }
  );
}

export interface IBugBountyDetails
  extends Omit<IBugBountyProgram, "program_plan"> {
  metadata: {
    vi: string;
    en: string;
  };
  non_vat: boolean;
  is_subscribe: boolean;
  is_blocked: boolean;
  requirements: boolean;
  allow_to_see_brief: boolean;
  program_plan: {
    name: string;
    remain_support: number;
  };
  is_eligible: boolean;
  can_see_brief: boolean;
  // DEPRECATED: duplicated with allow_to_see_brief
  allow_see_brief: boolean;
  target: {
    id: number;
    name: string;
    type: string;
    scope: string;
    link_download: string;
    asset_id: number | null;
  }[];
  reward: {
    type: string;
    range_from: number;
    range_to: number;
    severity: string;
    point_value: number;
  }[];
}

function retrieve_program_details(workspace_id: string, program_alias: string) {
  return request.get<never, IBugBountyDetails | { redirect_alias: string }>(
    `${endpoint.WORKSPACES}/${workspace_id}/programs/${program_alias}`
  );
}

interface IBugBountyPostData {
  alias: string;
  name: string;
  description?: {
    en: string;
    vi: string;
  };
  logo?: string;
  color?: string;
  type: string;
  target: {
    name: string;
    type: string;
    scope: string;
    link_download: string;
    file?: string;
  }[];
  reward?: {
    type: string;
    range_from: number;
    range_to: number;
    severity: string;
  }[];
  metadata: {
    en: string;
    vi: string;
  };
  currency?: string;
  mssp_id?: string | null;
  // These fields are not currently needed
  // email?: string;
  // phone?: string;
  // address?: string;
  // mode?: string;
}

function create_program(workspace_id: string, data: IBugBountyPostData) {
  return request.post<never, { alias: string }>(
    `${endpoint.WORKSPACES}/${workspace_id}/programs`,
    data
  );
}

function submit_program(workspace_id: string, program_alias: string) {
  return request.post<never, { alias: string }>(
    `${endpoint.WORKSPACES}/${workspace_id}/programs/${program_alias}/submit`
  );
}

function update_program(
  workspace_id: string,
  program_alias: string,
  data: Partial<IBugBountyPostData>
) {
  return request.put<never, { success: boolean }>(
    `${endpoint.WORKSPACES}/${workspace_id}/programs/${program_alias}`,
    data
  );
}

function delete_program(workspace_id: string, program_alias: string) {
  return request.delete<never>(
    `${endpoint.WORKSPACES}/${workspace_id}/programs/${program_alias}`
  );
}

export default {
  list_programs,
  retrieve_program_details,
  create_program,
  submit_program,
  update_program,
  delete_program,

  list_program_requirements,
  update_program_requirement,
  update_allow_to_see_program,

  list_program_members,
  list_program_mssp_members,
  create_program_member,
  update_program_member,
  delete_program_member,

  list_join_requests,
  accept_join_request,
  reject_join_request,

  list_program_integrations,
  create_program_integration,
  retrieve_program_integration_details,
  update_program_integration,

  list_program_blocked_researchers,
  blacklist_researcher,
  unblock_researcher,

  retrieve_program_mssp,
  update_program_mssp,
};
