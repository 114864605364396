// Libraries
import { Drawer } from "@lockerpm/design";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

// Resources
import { ReactComponent as CloseLine } from "#src/assets/images/icons/close-line.svg";

// General
import type { IDrawerAdapterProps } from "#src/@types/common";

// Components
import { Button, IconButton } from "#src/components/common/system/Button";
import { Input } from "#src/components/common/system/Input";
import CopyToClipboardButton from "#src/components/common/CopyToClipboardButton";
import { createToast } from "#src/components/common/system/toasts";
import { apiErrorHandler } from "#src/utils/apiErrorHandler";

// API-related
import assetsServices, {
  type IDomainOwnershipItem,
} from "#src/services/assets";

interface IVerifyOwnershipDrawerProps extends IDrawerAdapterProps {
  assetName: string | null;
  assetId: number | null;
}

const VerifyOwnershipDrawer = ({
  open,
  onClose,
  workspaceId,
  assetName,
  assetId,
}: IVerifyOwnershipDrawerProps) => {
  const { t } = useTranslation("assets", {
    keyPrefix: "domains.drawer.verifyOwnership",
  });

  const [assetOwnership, setAssetOwnership] =
    useState<IDomainOwnershipItem | null>(null);

  const [pending, setPending] = useState<boolean>(false);

  const verifyOwnership = () => {
    if (assetId) {
      setPending(true);
      assetsServices
        .verify_domain(assetId, workspaceId)
        .then(() => {
          onCloseDrawerAndCleanup();
          createToast({
            type: "success",
            message: t("notification.verifyAsset.success"),
          });
        })
        .catch((error) => {
          apiErrorHandler(error, {
            toastMessage: t("notification.verifyAsset.fail"),
          });
        })
        .finally(() => {
          setPending(false);
        });
    }
  };

  const onCloseDrawerAndCleanup = () => {
    // setAssetOwnership(null);
    onClose();
  };

  useEffect(() => {
    const getAssetOwnerships = () => {
      if (assetId)
        assetsServices
          .list_domain_ownerships(assetId, workspaceId)
          .then((response) => {
            // currently there's only 1 way to verify ownership, so we only have 1 item
            setAssetOwnership(response[0]);
          })
          .catch(apiErrorHandler);
    };
    getAssetOwnerships();
  }, [assetId, workspaceId]);

  return (
    <Drawer
      open={open}
      onClose={onCloseDrawerAndCleanup}
      closable={false}
      title={
        <h2>
          {t("title")} <span className="text-primary">{assetName}</span>
        </h2>
      }
      extra={
        <IconButton
          variant="label-grey"
          size={9}
          onClick={onCloseDrawerAndCleanup}
        >
          <CloseLine />
        </IconButton>
      }
      footer={
        <div className="flex flex-col gap-3">
          <Button
            className="w-full"
            size="large"
            pending={pending}
            onClick={() => {
              verifyOwnership();
            }}
          >
            {t("button.verifyOwnership")}
          </Button>
          <Button
            variant="secondary"
            className="w-full"
            size="large"
            onClick={onCloseDrawerAndCleanup}
          >
            {t("button.cancel")}
          </Button>
        </div>
      }
    >
      <div className="flex flex-col gap-6 p-6">
        <div className="flex flex-col gap-2">
          <h4>{t("field.host.label")}</h4>
          <div className="flex gap-1">
            <Input
              className="w-full"
              value={assetOwnership ? assetOwnership.key : ""}
              disabled
            />
            <CopyToClipboardButton value={assetOwnership?.key} />
          </div>
        </div>
        <div className="flex flex-col gap-2">
          <h4>{t("field.value.label")}</h4>
          <div className="flex gap-1">
            <Input
              className="w-full"
              value={assetOwnership ? assetOwnership.value : ""}
              disabled
            />
            <CopyToClipboardButton value={assetOwnership?.value} />
          </div>
        </div>
        <p className="font-regular-14 text-hard-grey whitespace-pre-line">
          {t("instruction.text")}
        </p>
        <a
          href="https://docs.cystack.net/vi/guide/websites/verify.html#xac-minh-bang-meta-tag"
          target="_blank"
          rel="noreferrer"
        >
          {t("instruction.link")}
        </a>
      </div>
    </Drawer>
  );
};

export default VerifyOwnershipDrawer;
