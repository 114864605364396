import endpoint from "#src/config/endpoint";
import request from "#src/utils/request";

export interface ITrustCenterConfigDetails {
  access: {
    is_public: boolean;
    alias: string;
  };
  analytic: {
    custom_ga_tag_id: string;
  };
  profile: {
    name: string;
    short_name: string;
    email: string;
    website: string;
    phone: string;
    description: {
      vi: string;
      en: string;
    };
    og_image: any | null;
    og_title: any | null;
    og_description: string | null;
    og_logo: any | null;
  };
  file: { max_total_file_size: number; max_file_size: number };
  domain: string | null;
  domain_plan: any | null;
  domain_verification: {
    value: string;
    host: string;
    type: string;
    TTL: number;
  }[];
  is_activate: boolean;
  domain_status:
    | "not_verified"
    | "processing"
    | "activating"
    | "activated"
    | "deactivated"
    | "expired";
}

export function retrieve_trust_center_config(workspace_id: string) {
  return request.get<never, ITrustCenterConfigDetails>(
    `${endpoint.WORKSPACES}/${workspace_id}/trust_center/setting`
  );
}

export function retrieve_trust_center_file_settings(workspace_id: string) {
  return request.get<
    never,
    { file: { max_total_file_size: number; max_file_size: number } }
  >(`${endpoint.WORKSPACES}/${workspace_id}/trust_center/setting/file`);
}

export function update_trust_center_config_access(
  workspace_id: string,
  data: {
    is_public: boolean;
    alias: string;
  }
) {
  return request.put<never, ITrustCenterConfigDetails>(
    `${endpoint.WORKSPACES}/${workspace_id}/trust_center/setting/access`,
    data
  );
}

export function update_trust_center_config_domain(
  workspace_id: string,
  data: {
    domain: string | null;
  }
) {
  return request.put<never, ITrustCenterConfigDetails>(
    `${endpoint.WORKSPACES}/${workspace_id}/trust_center/setting/domain`,
    data
  );
}

export function update_trust_center_config_profile(
  workspace_id: string,
  data: {
    name: string;
    short_name: string;
    email: string;
    website: string;
    phone: string;
    description: {
      vi: string;
      en: string;
    };
    og_title: null | any;
    og_description: null | any;
    og_logo: null | any;
  }
) {
  return request.put<never, ITrustCenterConfigDetails>(
    `${endpoint.WORKSPACES}/${workspace_id}/trust_center/setting/profile`,
    data
  );
}

export function update_trust_center_config_activate(
  workspace_id: string,
  data: {
    is_activate: boolean;
  }
) {
  return request.put<never, ITrustCenterConfigDetails>(
    `${endpoint.WORKSPACES}/${workspace_id}/trust_center/setting/activate`,
    data
  );
}

export function update_trust_center_config_analytic(
  workspace_id: string,
  data: {
    custom_ga_tag_id: string;
  }
) {
  return request.put<never, ITrustCenterConfigDetails>(
    `${endpoint.WORKSPACES}/${workspace_id}/trust_center/setting/analytic`,
    data
  );
}
