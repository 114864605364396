// Libraries
import { generatePath, useNavigate, useParams } from "react-router-dom";
import { Tooltip } from "@lockerpm/design";
import { useTranslation } from "react-i18next";
import { createSearchParams } from "react-router-dom";

// Resources
import { ReactComponent as ArrowRightUpLine } from "#src/assets/images/icons/arrow-right-up-line.svg";
import { ReactComponent as CheckboxCircleLine } from "#src/assets/images/icons/checkbox-circle-line.svg";
import { ReactComponent as CloseCircleLine } from "#src/assets/images/icons/close-circle-line.svg";

// General
import { pathname } from "#src/config/pathname";

// Components
import { Button } from "#src/components/common/system/Button";
import {
  TableDataCell,
  TableHeader,
  TableRowWrapper,
} from "#src/components/common/Table";
import type { ITableHeaderPropItem } from "#src/components/common/Table/TableHeader";
import LoadingState from "#src/components/common/system/LoadingState";
import NoResultsState from "#src/components/common/states/NoResultsState";
import { StatusLabel } from "#src/components/common/labels";

// APi-related
import type { IPolicyDetailItem } from "#src/services/devices/policies";
import { PolicyComplianceValue } from "#src/config/filter/device/value";

interface IHostPoliciesTableRowProps {
  data: IPolicyDetailItem;
}

const HostPoliciesTableRow = ({ data }: IHostPoliciesTableRowProps) => {
  const { t } = useTranslation("devices", {
    keyPrefix: "hosts.page.details.tab.policies",
  });

  const params = useParams<"workspace">();
  const navigate = useNavigate();

  const onClickViewAllHost = () => {
    navigate(
      {
        pathname: generatePath(pathname.HOSTS, params),
        search: createSearchParams({
          policy_compliance_id: data.id.toString(),
        }).toString(),
      },
      { state: { policy_compliance_name: data.name } }
    );
  };

  return (
    <TableRowWrapper>
      <TableDataCell>{data.name}</TableDataCell>
      <TableDataCell>
        {data.response === PolicyComplianceValue.PASSED ? (
          <StatusLabel
            // TODO: might want to use i18n
            name={"Passed"}
            color="bg-support-seafoam-pastel text-support-seafoam"
            icon={<CheckboxCircleLine className="h-4 w-4 min-w-[1rem]" />}
          />
        ) : data.response === PolicyComplianceValue.FAILED ? (
          <StatusLabel
            // TODO: might want to use i18n
            name={"Failed"}
            color="text-warning-red-danger bg-warning-red-10%"
            icon={<CloseCircleLine className="h-4 w-4 min-w-[1rem]" />}
          />
        ) : null}
      </TableDataCell>
      <TableDataCell>
        <Tooltip title={t("button.viewAllHosts.tooltip")}>
          <Button
            variant="text"
            onClick={() => {
              onClickViewAllHost();
            }}
          >
            {t("button.viewAllHosts.label")}
            <ArrowRightUpLine />
          </Button>
        </Tooltip>
      </TableDataCell>
    </TableRowWrapper>
  );
};

interface IHostSoftwaresTableGridProps {
  hostPolicyList: IPolicyDetailItem[] | null;
  isLoading: boolean;
}

const HostPoliciesTableGrid = ({
  hostPolicyList,
  isLoading,
}: IHostSoftwaresTableGridProps) => {
  const { t } = useTranslation("devices", {
    keyPrefix: "hosts.page.details.tab.policies",
  });

  const headers: ITableHeaderPropItem[] = [
    { name: t("headers.name"), align: "left" },
    { name: t("headers.policyCompliance"), align: "left" },
    { name: "", align: "left" },
  ];

  return isLoading || hostPolicyList === null ? (
    <LoadingState />
  ) : hostPolicyList.length === 0 ? (
    <NoResultsState />
  ) : (
    <div className="grid grid-cols-[5fr_2fr_2fr]">
      <TableHeader title="devices/hosts/policies" headerProps={headers} />
      {hostPolicyList.map((policy) => (
        <HostPoliciesTableRow
          key={`tdevices/hostPolicies-tr-${policy.id}`}
          data={policy}
        />
      ))}
    </div>
  );
};

export default HostPoliciesTableGrid;
