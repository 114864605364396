// Libraries
import { useTranslation } from "react-i18next";

// Components
import type { ITableHeaderPropItem } from "#src/components/common/Table/TableHeader";
import LoadingState from "#src/components/common/system/LoadingState";
import NoResultsState from "#src/components/common/states/NoResultsState";
import TableHeader from "#src/components/common/Table/TableHeader";

// API-related
import paymentService from "#src/services/payment";

// Children
import BillingContractTableRow from "./TableRow";

interface IBillingContractTableProps {
  contractList:
    | Awaited<ReturnType<typeof paymentService.list_contracts>>["results"]
    | null;
  isLoading: boolean;
}

const BillingContractTable = ({
  contractList,
  isLoading,
}: IBillingContractTableProps) => {
  const { t } = useTranslation("workspace", {
    keyPrefix: "billing.tab.contract",
  });

  const headers: ITableHeaderPropItem[] = [
    { name: t("headers.contractId"), align: "left" },
    { name: t("headers.paymentId"), align: "left" },
    { name: t("headers.serviceName"), align: "left" },
    { name: t("headers.method"), align: "left" },
    { name: t("headers.signedDate"), align: "left" },
    { name: t("headers.status"), align: "left" },
  ];

  return isLoading || contractList === null ? (
    <LoadingState />
  ) : contractList.length === 0 ? (
    <NoResultsState />
  ) : (
    <div className="grid grid-cols-[1fr_1fr_1fr_1fr_1fr_minmax(8rem,_1fr)]">
      <TableHeader title="workspace/billing/contract" headerProps={headers} />
      {contractList.map((item) => (
        <BillingContractTableRow
          key={`workspace/billing/contract-tr${item.id}`}
          data={item}
        />
      ))}
    </div>
  );
};

export default BillingContractTable;
