export const PentestStatusResponseValue = {
  DRAFT: "draft",
  REQUESTED: "requested",
  EXECUTION: "execution",
  CLOSURE: "closure",
  PAUSE: "pause",
};

export const PentestScopeTypeResponseValue = {
  DOMAIN: "domain",
  SUBDOMAIN: "subdomain",
  MOBILE: "mobile",
  DESKTOP: "desktop",
  SOURCE_CODE: "source_code",
  SMART_CONTRACT: "smart_contract",
};

export const PentestScopeScopeResponseValue = {
  IN_SCOPE: "in_scope",
  OUT_OF_SCOPE: "out_of_scope",
};

export const PentestMemberRoleResponseValue = {
  PROJECT_OWNER: "owner",
  ADMIN: "admin",
  DEVELOPER: "developer",
};

export const MsspPentestMemberRoleResponseValue = {
  SECURITY_MANAGER: "security_manager",
  PENTESTER: "pentester",
};
