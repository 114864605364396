import scansServices from "#src/services/scans";
import ScannedAssetsTableGrid from "./Table";
import { useMemo, useState } from "react";
import useFetchPaginated from "#src/hooks/useFetchPaginated";
import constants from "#src/config/constants";
import PaginationBar from "#src/components/common/helper/PaginationBar";

interface IScanDetailsScannedAssetsProps {
  workspaceId: string;
  scanId: number;
  onChangeTab: (key: string) => void;
}

const ScanDetailsScannedAssets = ({
  workspaceId,
  scanId,
  onChangeTab,
}: IScanDetailsScannedAssetsProps) => {
  const [selectedPage, setSelectedPage] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(constants.DEFAULT_PAGE_SIZE);

  const fetchParams = useMemo<
    Parameters<typeof scansServices.list_scanned_domains>
  >(
    () => [
      workspaceId,
      scanId,
      {
        domain_id: undefined,
        q: undefined,
        page: selectedPage,
        size: pageSize,
      },
    ],
    [workspaceId, scanId, selectedPage, pageSize]
  );

  const onChangePage = (page: number) => {
    setSelectedPage(page);
  };

  const onChangePageSize = (size: number) => {
    setPageSize(size);
    setSelectedPage(1);
  };

  const {
    list: scannedAssetList,
    count: scannedAssetCount,
    isLoading,
  } = useFetchPaginated(scansServices.list_scanned_domains, fetchParams);

  return (
    <section className="flex flex-col gap-6 pt-6">
      <ScannedAssetsTableGrid
        scannedAssetList={scannedAssetList}
        isLoading={isLoading}
        onChangeTab={onChangeTab}
      />
      <PaginationBar
        numOfResult={scannedAssetCount}
        selectedPage={selectedPage}
        pageSize={pageSize}
        onChangePage={onChangePage}
        onChangePageSize={onChangePageSize}
      />
    </section>
  );
};

export default ScanDetailsScannedAssets;
