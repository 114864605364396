import type { ReactNode } from "react";

/**
 * @param open - state of the drawer
 * @param workspaceId
 * @param onClose
 * @param onRefresh - a callback function that update data on display outside this drawer, usually called after data changed.
 */
export interface IDrawerAdapterProps {
  open: boolean;
  workspaceId: string;
  onClose: () => void;
  onRefresh: () => void;
}

/**
 * @param key string
 * @param value string | undefined
 * @param getLabel () => ReactNode
 */
export interface IFilterItem {
  key: string;
  value: string | undefined;
  getLabel: () => ReactNode;
}

/**
 * @param key string
 * @param value string | undefined
 * @param getLabel () => ReactNode
 * @param type string
 * @param children IFilterItem[]
 */
export interface IFilterItemWithChildren {
  key: string;
  value: string | undefined;
  getLabel: () => ReactNode;
  type: string;
  // For now this should only be 2 layers, which means there are not children of children
  children: IFilterItem[];
}

export interface MultilingualText {
  en: string;
  vi: string;
}

export enum ESolutionKeys {
  SECRETS_MANAGER = "secrets_manager",
  PASSWORD_MANAGER = "password_manager",
  ENDPOINT = "endpoint",
  DATA_LEAK = "data_leak",
  VULN_SCAN = "vuln_scan",
  TRUST_CENTER = "trust_center",
  PENETRATION_TESTING = "penetration_testing",
  RED_TEAMING = "red_teaming",
  CLOUD_SECURITY_AUDIT = "cloud_security_audit",
  SOURCE_CODE_AUDIT = "source_code_audit",
  BLOCKCHAIN_PROTOCOL_AUDIT = "blockchain_protocol_audit",
  WHITEHUB_BUG_BOUNTY = "whitehub_bug_bounty",
  SECURITY_COMPLIANCE = "security_compliance",
  SECURITY_OPERATIONS_CENTER = "security_operations_center",
  VULNERABILITY_MANAGEMENT = "vulnerability_management",
}

export enum EToolKeys {
  CYSTACK_ASSETS = "cystack_assets",
  CYSTACK_ENDPOINT = "cystack_endpoint",
  CYSTACK_DATA_LEAK_DETECTION = "cystack_data_leak_detection",
  CYSTACK_VULNSCAN = "cystack_vulnscan",
  WHITEHUB_BUG_BOUNTY = "whitehub",
  CYSTACK_TRUST_CENTER = "cystack_trust_center",
  CYSTACK_PLATFORM = "cystack_platform",
  LOCKER_PASSWORD = "locker_password",
  LOCKER_SECRET = "locker_secret",
  SAFE_CHAIN = "safe_chain",
}
