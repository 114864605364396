interface ITabButtonLabelProps {
  name: string;
  count?: number;
}

/** Display for Tab Buttons
 * @param name - label name, usually the name of the tab
 * @param count - number of items in the tab
 */
const TabButtonLabel = ({ name, count }: ITabButtonLabelProps) => {
  return (
    <div className="flex font-medium-16-uppercase">
      <span className="h-11 min-w-[5rem] px-4 flex justify-center items-center">
        {name}
        {typeof count === "number" ? " (" + count + ")" : null}
      </span>
    </div>
  );
};

export default TabButtonLabel;
