import i18next from "i18next";
import type { IFilterItem } from "#src/@types/common";
import { ComplianceStatusEnum, TrustCenterPolicyStatusEnum } from "./enum";
import { ComplianceStatusValue, TrustCenterPolicyStatusValue } from "./value";

export const complianceStatusItems: IFilterItem[] = [
  {
    key: ComplianceStatusEnum.COMPLIANCE,
    value: ComplianceStatusValue.COMPLIANCE,
    getLabel: () => i18next.t("filter.complianceStatus.compliance"),
  },
  {
    key: ComplianceStatusEnum.UNKNOWN,
    value: ComplianceStatusValue.UNKNOWN,
    getLabel: () => i18next.t("filter.complianceStatus.unknown"),
  },
  {
    key: ComplianceStatusEnum.NON_COMPLIANCE,
    value: ComplianceStatusValue.NON_COMPLIANCE,
    getLabel: () => i18next.t("filter.complianceStatus.nonCompliance"),
  },
  {
    key: ComplianceStatusEnum.ALL,
    value: undefined,
    getLabel: () => i18next.t("filter.all"),
  },
];

export const trustCenterPolicyStatusItems: IFilterItem[] = [
  {
    key: TrustCenterPolicyStatusEnum.DRAFT,
    value: TrustCenterPolicyStatusValue.DRAFT,
    getLabel: () => i18next.t("filter.trustCenterPolicyStatus.draft"),
  },
  {
    key: TrustCenterPolicyStatusEnum.PUBLISHED,
    value: TrustCenterPolicyStatusValue.PUBLISHED,
    getLabel: () => i18next.t("filter.trustCenterPolicyStatus.published"),
  },
  {
    key: TrustCenterPolicyStatusEnum.ALL,
    value: undefined,
    getLabel: () => i18next.t("filter.all"),
  },
];
