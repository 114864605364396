import type { IPaginateParams, IPaginatedResponse } from "#src/@types/api";
import type { TrustCenterFaqStatusValue } from "#src/components/trustCenter/faq/enum";
import endpoint from "#src/config/endpoint";
import request from "#src/utils/request";

export interface ITrustCenterFaqItem {
  id: string;
  status: TrustCenterFaqStatusValue;
  created_time: number;
  published_time: number | null;
  question: Record<string, string>;
  answer: Record<string, string>;
  languages: string[];
  details: { lang: string; question: string; answer: string }[];
}

export function list_trust_center_faq(
  workspace_id: string,
  params: IPaginateParams
) {
  return request.get<never, IPaginatedResponse<ITrustCenterFaqItem>>(
    `${endpoint.WORKSPACES}/${workspace_id}/trust_center/faq`,
    { params }
  );
}

export function list_trust_center_faq_no_paging(
  workspace_id: string,
  params: { q?: string | undefined }
) {
  return request.get<never, ITrustCenterFaqItem[]>(
    `${endpoint.WORKSPACES}/${workspace_id}/trust_center/faq`,
    { params: { ...params, paging: 0 } }
  );
}

export function retrieve_trust_center_faq(
  workspace_id: string,
  faq_id: string
) {
  return request.get<never, ITrustCenterFaqItem>(
    `${endpoint.WORKSPACES}/${workspace_id}/trust_center/faq/${faq_id}`
  );
}

export function create_trust_center_faq(
  workspace_id: string,
  data: {
    details: { lang: string; question: string; answer: string }[];
    status: TrustCenterFaqStatusValue;
  }
) {
  return request.post<never, { id: string }>(
    `${endpoint.WORKSPACES}/${workspace_id}/trust_center/faq`,
    data
  );
}

export function update_trust_center_faq(
  workspace_id: string,
  faq_id: string,
  data: {
    details: { lang: string; question: string; answer: string }[];
    status: TrustCenterFaqStatusValue;
  }
) {
  return request.put<never, { id: string }>(
    `${endpoint.WORKSPACES}/${workspace_id}/trust_center/faq/${faq_id}`,
    data
  );
}

export function delete_trust_center_faq(workspace_id: string, faq_id: string) {
  return request.delete(
    `${endpoint.WORKSPACES}/${workspace_id}/trust_center/faq/${faq_id}`
  );
}
