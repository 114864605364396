// Libraries
import { Dropdown } from "@lockerpm/design";
import { useState } from "react";
import { useTranslation } from "react-i18next";

// Resources
import { ReactComponent as InformationLine } from "#src/assets/images/icons/information-line.svg";
import { ReactComponent as ArrowDownSLine } from "#src/assets/images/icons/arrow-down-s-line.svg";

// Components
import DropdownItemLabel from "#src/components/common/helper/antdProps/Dropdown/DropdownItemLabel";
import { Modal } from "#src/components/common/system/Modal";

// API-related
import type { ICertificateItem } from "#src/services/assets/certificates";

// Children
import CertificateCipherSuites from "./CipherSuites";
import CertificateHandshakeSimulation from "./HandshakeSimulation";
import CertificateHttpRequests from "./HttpRequests";

const CertificateEndpoints = ({
  endpoints,
}: {
  endpoints: ICertificateItem["endpoints"];
}) => {
  const { t } = useTranslation("assets", {
    keyPrefix: "domains.page.details.tab.certificates.endpoints",
  });

  const [selectedEndpoint, setSelectedEndpoint] = useState<
    ICertificateItem["endpoints"][number] | null
  >(endpoints.length > 0 ? endpoints[0] : null);
  const [openOverviewModal, setOpenOverviewModal] = useState<boolean>(false);

  const overviewList: { grade: string; description: string }[] = [
    { grade: "A", description: t("overviewDescription.A") },
    { grade: "B", description: t("overviewDescription.B") },
    { grade: "C", description: t("overviewDescription.C") },
    { grade: "D", description: t("overviewDescription.D") },
    { grade: "E", description: t("overviewDescription.E") },
    { grade: "F", description: t("overviewDescription.F") },
    { grade: "T", description: t("overviewDescription.T") },
  ];

  const gradeColor: { [grade: string]: string } = {
    A: "bg-green",
    B: "bg-yellow",
    C: "bg-yellow",
    D: "bg-fire",
    E: "bg-fire",
    F: "bg-red",
    T: "bg-black text-white",
  };

  const getEndpointGradeColor = (grade: string) =>
    Object.keys(gradeColor).includes(grade)
      ? gradeColor[grade]
      : "bg-black text-white";

  return endpoints.length === 0 || selectedEndpoint === null ? null : (
    <>
      <div className="flex flex-col gap-1">
        <div className="flex gap-1">
          <Dropdown
            menu={{
              items: endpoints.map((endpoint) => ({
                key: endpoint.ipAddress,
                label: (
                  <DropdownItemLabel
                    selected={endpoint.ipAddress === selectedEndpoint.ipAddress}
                    syncWidthId="endpoint-dropdown"
                  >
                    {t("endpoint") + " " + endpoint.ipAddress}
                  </DropdownItemLabel>
                ),
                onClick: ({ key }) => {
                  const clickedItem = endpoints.find(
                    (endpoint) => endpoint.ipAddress === key
                  );
                  if (clickedItem) {
                    setSelectedEndpoint(clickedItem);
                  }
                },
              })),
            }}
            trigger={["click"]}
          >
            <div
              id="endpoint-dropdown"
              className="flex-1 font-bold-24 px-6 py-4 bg-label-blue rounded-md flex items-center justify-between"
            >
              {t("endpoint") + " " + selectedEndpoint.ipAddress}
              <ArrowDownSLine />
            </div>
          </Dropdown>
          <button
            className={`flex gap-3 font-medium-16 px-6 py-4 items-center rounded-md ${getEndpointGradeColor(
              selectedEndpoint.grade
            )}`}
            onClick={() => {
              setOpenOverviewModal(true);
            }}
          >
            <InformationLine />
            {t("overallGrade")}
            <span className="font-bold-24">{selectedEndpoint.grade}</span>
          </button>
        </div>
        <div className="flex flex-col gap-6 px-6 py-10 bg-bright-grey border border-light-grey rounded-md">
          <CertificateCipherSuites endpointDetails={selectedEndpoint.details} />
          <CertificateHandshakeSimulation
            endpointDetails={selectedEndpoint.details}
          />
          <CertificateHttpRequests endpointDetails={selectedEndpoint.details} />
        </div>
      </div>
      <Modal
        open={openOverviewModal}
        onChangeOpen={(value) => {
          setOpenOverviewModal(value);
        }}
        className="w-[46rem]"
      >
        <h2>{t("overviewDescription.title")}</h2>
        {overviewList.map((item) => (
          <div
            key={`endpoint-overviewDescription-${item.grade}`}
            className="flex items-center gap-6"
          >
            <div
              className={`h-9 w-9 min-w-[2.25rem] flex items-center justify-center rounded-md ${getEndpointGradeColor(
                item.grade
              )}`}
            >
              {item.grade}
            </div>
            <p className="font-regular-16-body">{item.description}</p>
          </div>
        ))}
      </Modal>
    </>
  );
};

export default CertificateEndpoints;
